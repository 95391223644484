/* eslint-disable react-hooks/exhaustive-deps */
import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  FormLabel,
  List,
  ListIcon,
  ListItem,
  Progress,
  Text,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useCallback, useEffect, useState } from "react";
import { Temas } from "../../Themes/Temas";
import { HttpCGIGrupoMeta } from "./HttpCGIGrupoMeta";

export type CGIGrupoMetaTypes = {
  titulo: string;
  valorAtual: Number;
  valorMeta: Number;
  icone: string;
  percentualAlcancado: number;
};

/**
 * @Componente CGIGrupoMeta - Componente responsavel  pelo Grupo de Metas
 * @Ref  CGIMeta
 */
export function CGIGrupoMeta(props: { link: string; titulo: string }) {
  const [Dados, setDados] = useState<Array<CGIGrupoMetaTypes>>(
    [] as Array<CGIGrupoMetaTypes>
  );

  const RetornarDados = useCallback(async () => {
    var Dados = await HttpCGIGrupoMeta().RetornarDados(props.link);
    setDados(Dados);
  }, [props.link]);

  useEffect(() => {
    RetornarDados();
  }, []);

  return (
    <Box bg={Temas.Branco} p={2}>
      <FormLabel>{props.titulo}</FormLabel>
      <Divider mt={2} />
      <List>
        {Dados.map((e, index) => {
          return (
            <ListItem key={index}>
              <Flex justifyContent={"space-between"} flexDir={"row"}>
                <Box>
                  <Text>
                    <ListIcon as={CheckIcon} />
                    {e.titulo}
                  </Text>
                </Box>
                <Box>
                  <Text>{e.percentualAlcancado}%</Text>
                </Box>
              </Flex>
              <Box w={"100%"}>
                <Progress borderRadius={"md"} value={e.percentualAlcancado} />
              </Box>
              <Divider mt={2} />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
