import React, { forwardRef, useImperativeHandle } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Box,
  ResponsiveValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../Box/CGIBox";
import { CGIButton } from "../Button/CGIButton";

interface CGIAlertDialogType {
  titulo: string;
  confirmar: Function;
  fechar?: Function;
  children: React.ReactNode;
  ocultarConfirmar?: boolean;
  ocultarCancelar?: boolean;
  keybordDown?: (key: React.KeyboardEvent<HTMLDivElement>) => void;
  size?:
    | ResponsiveValue<
        | "sm"
        | "md"
        | "lg"
        | "xl"
        | "2xl"
        | (string & {})
        | "xs"
        | "3xl"
        | "4xl"
        | "5xl"
        | "6xl"
        | "full"
      >
    | undefined;
}
/**
 * @Componente CGIAlertDialog - Componente Base para Alert Dialog.
 */
export const CGIAlertDialog = forwardRef((props: CGIAlertDialogType, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef() as React.RefObject<any>;
  const form = useFormContext();

  useImperativeHandle(ref, () => ({
    Abrir() {
      onOpen();
    },
    Fechar() {
      onClose();
    },
  }));

  return (
    <AlertDialog
      closeOnEsc={false}
      closeOnOverlayClick={false}
      leastDestructiveRef={cancelRef}
      onClose={() => {
        props.fechar && props.fechar();
        onClose();
      }}
      isOpen={isOpen}
      isCentered
      size={props.size}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{props.titulo}</AlertDialogHeader>
        {props.ocultarCancelar ? (
          <></>
        ) : (
          <AlertDialogCloseButton autoFocus={false} />
        )}
        <AlertDialogBody
          onKeyDown={e => props.keybordDown && props.keybordDown(e)}
        >
          <CGIBox>{props.children}</CGIBox>
        </AlertDialogBody>
        <AlertDialogFooter>
          <CGIButton
            label="Cancelar"
            hidden={props.ocultarCancelar}
            att={{
              ml: 3,
              colorScheme: "red",
            }}
            onClick={() => {
              form.reset();
              onClose();
            }}
          >
            Cancelar
          </CGIButton>
          <CGIButton
            label="Confirmar"
            hidden={props.ocultarConfirmar}
            att={{
              ml: 3,
            }}
            onClick={form.handleSubmit(e => {
              props.confirmar(e);
            })}
          />
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
});
