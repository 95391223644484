import {
  Box,
  Checkbox,
  CheckboxProps,
  Flex,
  Text,
  TextProps,
  Tooltip,
} from "@chakra-ui/react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import React, { CSSProperties, InputHTMLAttributes } from "react";
import { Temas } from "../../Themes/Temas";

interface CGICheckBoxTypes<T extends Record<string, unknown>> {
  id: NestedKey<T>;
  label: string;
  tooltip: string;
  validadores?: RegisterOptions | undefined;
  att?: InputHTMLAttributes<HTMLInputElement>;
  attLabel?: TextProps;
}
/**
 * @Componente CGICheckBox - Check Box padrão do Sistema
 * @Ref  FormContext
 * @Form  Necessita ser Controlado Por Formulário
 */
export function CGICheckBox<T extends Record<string, unknown>>(
  props: CGICheckBoxTypes<T>
) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Box>
      <Controller
        render={({ field }) => {
          return (
            <div onClick={() => field.onChange(!field.value)}>
              <Flex gap={2}>
                <input
                  type="checkbox"
                  checked={field.value ? true : false}
                  value={field.value ? "true" : "false"}
                  onChange={() => field.onChange(!field.value)}
                  {...props.att}
                />
                <Tooltip
                  shouldWrapChildren
                  hasArrow
                  placement="bottom"
                  label={props.tooltip}
                  color={Temas.Branco}
                  style={{}}
                >
                  <Text {...props.attLabel} fontWeight={"semibold"}>
                    {props.label}
                  </Text>
                </Tooltip>
              </Flex>
            </div>
          );
        }}
        rules={props.validadores}
        name={props.id}
        control={control}
      />
      <ErrorMessage
        errors={errors}
        name={props.id}
        render={({ message }) => (
          <span style={{ color: "red", fontSize: 12 }}> * {message}</span>
        )}
      />
    </Box>
  );
}
