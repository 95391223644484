export const ObjProdutosNaoCadastradosPadrao: FormProdutosNãoCadastradosTypes =
  {
    Form: {
      DadosRegistro: {},
    },
  };

export const ObjListarProdutosNaoCadastradosPadrao: ListarProdutosNaoCadastradosTypes =
  {
    Pesquisa: {
      descricao: "",
      identificacaoPedido: "",
      identificacaoOrcamento: "",
      codigoProduto: "",
    },
    Itens: [],
  };

export type FormProdutosNãoCadastradosTypes = {
  Form: {
    DadosRegistro: ProdutosNaoCadastradosTypes;
  };
};

export type ProdutosNaoCadastradosTypes = {};

export type ListarProdutosNaoCadastradosTypes = {
  Pesquisa: ListarPesquisaProdutosNaoCadastradosTypes;
  Itens: ListarProdutosNaoCadastradosItensTypes[];
};

export type ListarPesquisaProdutosNaoCadastradosTypes = {
  descricao: string;
  identificacaoPedido: string;
  identificacaoOrcamento: string;
  codigoProduto: string;
};

export type ListarProdutosNaoCadastradosItensTypes = {
  id: number;
  nomeStatus: string;
  valorInformado: string;
  nomeFornecedor: string;
  nomeColaborador: string;
  descricao: string;
  codigoProduto: string;
  identificacaoPedido: string;
  identificacaoOrcamento: string;
  identificacaoPedidoCompra: string;
};
