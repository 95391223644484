import { Tooltip, Input, Divider, Box } from "@chakra-ui/react";
import { useContext, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import LoadingContext from "../../Context/LoadingContext";
import { Temas } from "../../Themes/Temas";
import { CGIBox } from "../Box/CGIBox";
import { CGIBoxItem } from "../Box/CGIBoxItens";
import { CGIButton } from "../Button/CGIButton";
import { CGISearchAlert } from "./CGISearchAlert";
import { HttpCGISearch } from "./HttpSearch";

export type CGIConsultaRegistro = {
  Id: number;
  CodigoConsulta: string;
  QtdItens: number;
  colunas: Array<{ nomeTabela: string; nomeExibicao: string }>;
  registros: Array<any>;
};

type CGISearchType = {
  link: string;
};

export function CGISearch(props: CGISearchType) {
  const [Pesquisar, setPesquisar] = useState("");
  const [Controller] = useState(() => {
    return props.link.split("/")[1];
  });
  const [link] = useState(() => {
    return props.link;
  });
  const CGISearchAlertRef = useRef() as any;

  const { setLoadingState, Loadingstate } = useContext(LoadingContext);

  const form = useFormContext();
  async function Buscar() {
    setLoadingState(true);

    var Dados = await HttpCGISearch().RetornarDados(
      Pesquisar,
      link,
      Controller
    );

    setLoadingState(false);
    if (Dados.registros.length == 0) {
      setPesquisar("");
    } else if (1 >= Dados.registros.length) {
      form.setValue("id", Dados.registros[0].Id);
    } else {
      CGISearchAlertRef.current.Abrir(Dados.colunas, Dados.registros);
    }
  }
  function GetDadosGrid(Dados: any) {
    form.setValue("id", Dados.Id);
  }
  return (
    <Box ml={2} py={2}>
      <CGIBox>
        <CGISearchAlert RetornarDados={GetDadosGrid} ref={CGISearchAlertRef} />
        <CGIBoxItem Col={6}>
          <Tooltip
            hasArrow
            bg={Temas.Primaria}
            color={Temas.Branco}
            label="Informe pelo menos 2 caracteres para a Pesquisa"
            placement="top-start"
          >
            <Input
              autoFocus={false}
              disabled={Loadingstate}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  if (Pesquisar.length >= 2) Buscar();
                }
              }}
              ml={2}
              variant={"filled"}
              placeholder="Pesquisar"
              size={"sm"}
              onChange={e => setPesquisar(e.target.value)}
            />
          </Tooltip>
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIButton
            att={{
              size: "sm",
            }}
            label="Pesquisar"
            onClick={e => {
              e.preventDefault();
              if (Pesquisar.length >= 2) Buscar();
            }}
          />
        </CGIBoxItem>
      </CGIBox>
      <Divider mt={2} />
    </Box>
  );
}
