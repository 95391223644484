import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIFileInput } from "../../../Components/FileInput/CGIFileInput";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGINumberInput } from "../../../Components/NumberInput/CGINumberInput";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import { CGITextArea } from "../../../Components/TextArea/CGITextArea";
import { CGIHttp } from "../../../Services/Axios";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ResumoSugestaoPedidoCompra } from "../../../Components/Resumo/Resumos/ResumoSugestaoPedidoCompra";
require("highcharts/modules/exporting")(Highcharts);

export function ListarExemplos() {
  const Tabs: CGITabItensTypes[] = [
    {
      Component: <Combos />,
      Titulo: "Combos",
      Tooltip: "Exemplo de combos",
    },
    {
      Component: <ResumoColaborador />,
      Titulo: "Resumo Colaborador",
      Tooltip: "Exemplo de resumo de colaborador",
    },
    {
      Component: <ResumoCtsPagar />,
      Titulo: "Resumo Cts a Pagar",
      Tooltip: "Exemplo de resumo de Cts a Pagar",
    },
    {
      Component: <ResumoPedido />,
      Titulo: "Resumo Pedido",
      Tooltip: "Exemplo de resumo de Pedido",
    },
    {
      Component: <ResumoItensPedido />,
      Titulo: "Resumo Itens Pedido",
      Tooltip: "Exemplo de resumo de Itens de Pedido",
    },
    {
      Component: <ResumoProduto />,
      Titulo: "Resumo Produto",
      Tooltip: "Exemplo de resumo de Produto",
    },
    {
      Component: <ResumoProdutoEstoqueNasEmpresas />,
      Titulo: "Resumo Produto Estoque Nas Empresas",
      Tooltip: "Exemplo de resumo de Produto Estoque Nas Empresas",
    },
    {
      Component: <ResumoProdutoSimples />,
      Titulo: "Resumo Produto Simples",
      Tooltip: "Exemplo de resumo de Produto Simples",
    },
    {
      Component: <ResumoUltimasCompras />,
      Titulo: "Resumo Ultimas Compras",
      Tooltip: "Exemplo de resumo de Ultimas Compras",
    },
    {
      Component: <ResumoSugestaoPedidoCompras />,
      Titulo: "Resumo Sugestão Pedido Compras",
      Tooltip: "Exemplo de resumo de Sugestão Pedido Compras",
    },
    {
      Component: <ResumoGraficos />,
      Titulo: "Grificos",
      Tooltip: "Exemplo de Graficos",
    },
  ];

  return (
    <CGIBox>
      <CGIBoxItem Col={12}>
        <CGITabs Tabs={Tabs} />
      </CGIBoxItem>
    </CGIBox>
  );
}

const Combos = () => {
  const [stringCombos, setStringCombos] = useState<string[]>([]);
  const [link, setLink] = useState<string>("EMPRESA");
  const [multipla, setMultipla] = useState(false);
  const form = useForm();
  useEffect(() => {
    retornarDados();
  }, []);
  const retornarDados = async () => {
    const Dados = await new CGIHttp().conn
      .get("combo/TODOS")
      .then((res) => res.data.data);
    setStringCombos(Dados);
  };

  return (
    <FormProvider {...form}>
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox>
            <CGIBoxItem Col={12}>
              <Text fontWeight={"semibold"}>Pesquisar Combo</Text>
              <Flex gap={2}>
                <Input
                  onChange={(e) => {
                    if (e.target.value === "" || e.target.value === null) {
                      retornarDados();
                      return;
                    }

                    setStringCombos((T) =>
                      T.filter((x) =>
                        x.includes(String(e.target.value).toUpperCase())
                      )
                    );
                  }}
                  placeholder="Pesquisar Combo"
                />
              </Flex>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <div
                style={{
                  maxHeight: "70vh",
                  overflowY: "auto",
                }}
              >
                <UnorderedList gap={2}>
                  {stringCombos.map((item, index) => (
                    <ListItem onClick={() => setLink(item)} key={index}>
                      {item}
                    </ListItem>
                  ))}
                </UnorderedList>
              </div>
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBox>
            <CGIBoxItem Col={12}>
              <CGIComboSimples
                id="comboSimplesId"
                label={link}
                Multipla={multipla}
                Link={link}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIComboAjax
                Multipla={multipla}
                id="FornecedorId"
                label="Fornecedor"
                Link={"fornecedor"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={2}>
              <CGIButton
                label="Tornar Combo Multipla"
                onClick={() => setMultipla((e) => !e)}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIDatePicker id={"datePicker"} label={"Date Picker"} />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIFileInput id={"fileInput"} label={"File Input"} />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGINumberInput id={"numberInput"} label={"Number Input"} />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIInput id={"input"} label={"Input"} placeholder={"Input"} />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGITextArea id={"textArea"} label={"textArea"} />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    </FormProvider>
  );
};
const ResumoColaborador = () => {
  const form = useForm();
  useEffect(() => {
    form.setValue("id", "13707");
  }, [form]);
  return (
    <FormProvider {...form}>
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIResumo Expandir ColaboradorId="id" ResumoColaborador />
        </CGIBoxItem>
      </CGIBox>
      {`
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIResumo Expandir ColaboradorId="id" ResumoColaborador />
          </CGIBoxItem>
        </CGIBox>
      `}
    </FormProvider>
  );
};

const ResumoCtsPagar = () => {
  const form = useForm();
  useEffect(() => {
    form.setValue("id", "13707");
  }, [form]);
  return (
    <FormProvider {...form}>
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIResumo Expandir />
        </CGIBoxItem>
      </CGIBox>
      {`
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIResumo Expandir />
          </CGIBoxItem>
        </CGIBox>
      `}
    </FormProvider>
  );
};

const ResumoItensPedido = () => {
  const form = useForm();
  useEffect(() => {
    form.setValue("id", "19830");
  }, [form]);
  return (
    <FormProvider {...form}>
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIResumo Expandir PedidoItensId="id" ResumoPedidoItens />
        </CGIBoxItem>
      </CGIBox>
      {`
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIResumo Expandir />
          </CGIBoxItem>
        </CGIBox>
      `}
    </FormProvider>
  );
};
const ResumoPedido = () => {
  const form = useForm();
  useEffect(() => {
    form.setValue("id", "19830");
  }, [form]);
  return (
    <FormProvider {...form}>
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIResumo Expandir PedidoId="id" ResumoPedido />
        </CGIBoxItem>
      </CGIBox>
      {`
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIResumo Expandir PedidoId="id" ResumoPedido />
          </CGIBoxItem>
        </CGIBox>
      `}
    </FormProvider>
  );
};

const ResumoProduto = () => {
  const form = useForm();
  useEffect(() => {
    form.setValue("id", "100");
  }, [form]);
  return (
    <FormProvider {...form}>
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIResumo Expandir ProdutoId="id" ResumoProduto />
        </CGIBoxItem>
      </CGIBox>
      {`
         <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIResumo Expandir ProdutoId="id" ResumoProduto />
          </CGIBoxItem>
        </CGIBox>
      `}
    </FormProvider>
  );
};
const ResumoProdutoEstoqueNasEmpresas = () => {
  const form = useForm();
  useEffect(() => {
    form.setValue("id", "100");
  }, [form]);
  return (
    <FormProvider {...form}>
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIResumo
            Expandir
            ProdutoId="id"
            ResumoProduto
            ProdutoEstoqueEmpresaId="id"
            ResumoProdutoEstoqueEmpresa
          />
        </CGIBoxItem>
      </CGIBox>
      {`
       <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIResumo
            Expandir
            ProdutoId="id"
            ResumoProduto
            ProdutoEstoqueEmpresaId="id"
            ResumoProdutoEstoqueEmpresa
          />
        </CGIBoxItem>
      </CGIBox>
      `}
    </FormProvider>
  );
};
const ResumoProdutoSimples = () => {
  const form = useForm();
  useEffect(() => {
    form.setValue("id", "100");
  }, [form]);
  return (
    <FormProvider {...form}>
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIResumo Expandir ProdutoId="id" ResumoProdutoSimples />
        </CGIBoxItem>
      </CGIBox>
      {`
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIResumo Expandir ProdutoId="id" ResumoProdutoSimples />
        </CGIBoxItem>
      </CGIBox>
      `}
    </FormProvider>
  );
};
const ResumoUltimasCompras = () => {
  const form = useForm();
  useEffect(() => {
    form.setValue("id", "13707");
  }, [form]);
  return (
    <FormProvider {...form}>
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIResumo Expandir />
        </CGIBoxItem>
      </CGIBox>
      {`
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIResumo Expandir />
        </CGIBoxItem>
      </CGIBox>
      `}
    </FormProvider>
  );
};
const ResumoSugestaoPedidoCompras = () => {
  const form = useForm();
  useEffect(() => {
    form.setValue("id", "5412");
  }, [form]);
  return (
    <FormProvider {...form}>
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIResumo
            Expandir
            SugestaoPedidoCompraId="id"
            ResumoSugestaoPedidoCompra
          />
        </CGIBoxItem>
      </CGIBox>
      {`
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIResumo Expandir SugestaoPedidoCompraId="id" ResumoColaborador />
          </CGIBoxItem>
        </CGIBox>
      `}
    </FormProvider>
  );
};
const ResumoGraficos = () => {
  const options: Highcharts.Options = {
    title: {
      text: "My chart",
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    series: [
      {
        type: "pie",
        data: [
          {
            name: "Chrome",
            y: 70.67,
            sliced: true,
            selected: true,
          },
          {
            name: "Edge",
            y: 14.77,
          },
        ],
      },
    ],
  };

  return (
    <CGIBox>
      <CGIBoxItem Col={12}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </CGIBoxItem>
    </CGIBox>
  );
};
