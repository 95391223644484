export const ObjAnaliseCreditoPadrao: AnaliseCreditoTypes = {
  Form: {
    clienteId: 0,
    limiteCreditoId: 0,
  },
  Resumos: {
    id: 0,
    totalCredito: 0,
    limiteCreditoId: null,
    limiteCreditoDisponivelVenda: 0,
    limiteCreditoVenda: 0,
    limiteCreditoCompra: 0,
    limiteCreditoDisponivelCompra: 0,
    limiteCreditoUtilizadoVenda: 0,
    ctsReceberAVencer: 0,
    ctsReceberAtraso: 0,
    ctsReceberMaiorAtraso: 0,
    ctsReceberMediaAtraso: 0,
    diasCtsReceberMaiorAtraso: 0,
    maiorCtsReceber: 0,
    qtdCtsReceberEmAtraso: 0,
    qtdRecebimentoAtraso: 0,
    totalRecebimentoNoPrazo: 0,
    valorVendasNoMes: 0,
    valorMedioVendas: 0,
    totalRecebimentoAtraso: 0,
    totalCtsReceberEmATraso: 0,
    qtdRecebimentoNoPrazo: 0,
    qtdCtsReceberAVencer: 0,
    qtdCtsReceberAtrasos: 0,
    valorTotalVendas: 0,
    dataMaiorCtsReceber: null,
    dataUltimoCtsReceber: null,
    ultimoCtsReceber: 0,
    clienteDesde: "",
  },
  Grids: {
    Orcamento: {
      Itens: [],
    },
    ItensOrcamento: {
      Itens: [],
    },
    Pedido: {
      Itens: [],
    },
    ItensPedido: {
      Itens: [],
    },
    NotasFiscais: {
      Itens: [],
    },
    HistoricoFinanceiro: {
      Pesquisa: {
        DataVencimentoInical: "",
        DataVencimentoFinal: "",
      },
      Itens: [],
    },
    ContasReceberEmAtraso: {
      Itens: [],
    },
  },
};

export type AnaliseCreditoTypes = {
  Form: {
    clienteId: number;
    limiteCreditoId: number;
  };
  Resumos: AnaliseCreditoResumoType;
  Grids: {
    Orcamento: {
      Itens: Array<AnaliseCreditoOrcamentoItensType>;
    };
    ItensOrcamento: {
      Itens: Array<AnaliseCreditoItensOrcamentoItensType>;
    };
    Pedido: {
      Itens: Array<AnaliseCreditoPedidoItensType>;
    };
    ItensPedido: {
      Itens: Array<AnaliseCreditoItensPedidoItensType>;
    };
    NotasFiscais: {
      Itens: Array<AnaliseCreditoNotasFiscaisItensType>;
    };
    HistoricoFinanceiro: {
      Pesquisa: {
        DataVencimentoInical: string;
        DataVencimentoFinal: string;
      };
      Itens: Array<AnaliseCreditoHistoricoFinanceiroItensType>;
    };
    ContasReceberEmAtraso: {
      Itens: Array<AnaliseCreditoContasReceberEmAtraso>;
    };
  };
};

export type AnaliseCreditoResumoType = {
  id: number;
  totalCredito: number;
  limiteCreditoId: number | null;
  limiteCreditoDisponivelVenda: number;
  limiteCreditoVenda: number;
  limiteCreditoCompra: number;
  limiteCreditoDisponivelCompra: number;
  limiteCreditoUtilizadoVenda: number;
  ctsReceberAVencer: number;
  ctsReceberAtraso: number;
  ctsReceberMaiorAtraso: number;
  ctsReceberMediaAtraso: number;
  diasCtsReceberMaiorAtraso: number;
  maiorCtsReceber: number;
  qtdCtsReceberEmAtraso: number;
  qtdRecebimentoAtraso: number;
  totalRecebimentoNoPrazo: number;
  valorVendasNoMes: number;
  valorMedioVendas: number;
  totalRecebimentoAtraso: number;
  totalCtsReceberEmATraso: number;
  qtdRecebimentoNoPrazo: number;
  qtdCtsReceberAVencer: number;
  qtdCtsReceberAtrasos: number;
  valorTotalVendas: number;
  dataMaiorCtsReceber: string | null;
  dataUltimoCtsReceber: string | null;
  ultimoCtsReceber: number;
  clienteDesde: string;
};

export type AnaliseCreditoOrcamentoItensType = {
  id: number;
  nomeStatus: string;
  identificacao: string;
  dataCadastro: string;
  nomeVendedor: string;
  qtdItens: number;
  valorTotal: number;
};
export type AnaliseCreditoItensOrcamentoItensType = {
  id: number;
  nomeStatus: string;
  identificacao: string;
  dataCadastro: string;
  codigoProduto: string;
  descricao: string;
  quantidade: number;
  vlrUnitario: string;
  vlrTotal: string;
};
export type AnaliseCreditoPedidoItensType = {
  id: number;
  nomeStatus: string;
  dataCadastro: string;
  nomeTipoPedido: string;
  identificacao: string;
  nomeVendedor: string;
  qtdItens: number;
  nomeNaturezaOperacao: string;
  nomeCondicaoPagamento: string;
  totalPedidoFinal: number;
};
export type AnaliseCreditoItensPedidoItensType = {
  id: number;
  identificacao: string;
  dataCadastro: string;
  codigoProduto: string;
  descricao: number;
  quantidade: number;
  vlrUnitario: string;
  vlrTotal: string;
  nomeStatus: string;
};
export type AnaliseCreditoNotasFiscaisItensType = {
  id: number;
  numeroNF: string;
  serie: string;
  nomeTipo: string;
  pedidoCliente: number;
  nomeColaborador: number;
  nomeVendedor: string;
  nomeNaturezaOperacao: string;
  dataAutorizacao: string;
  valorNF: number;
  nomeStatus: string;
};
export type AnaliseCreditoHistoricoFinanceiroItensType = {
  id: number;
  titulo: string;
  documento: string;
  numero: string;
  nomePortador: string;
  dataVencimento: string;
  dataPagamento: string;
  valorTitulo: number;
  diasAtraso: number;
  nomeStatus: string;
};

export type AnaliseCreditoContasReceberEmAtraso = {
  id: number;
  nomeStatus: string;

  titulo: string;
  documento: string;
  numero: string;
  dataVencimento: string;
  diasAtraso: number;
  multa: number;
  juros: number;
  valorTitulo: number;
  saldoReceber: number;
};
