import { AddIcon } from "@chakra-ui/icons";
import { Box } from "framer-motion";
import { useState, useRef, useCallback, useEffect, useContext } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import {
  MenuGridPopover,
  RenderMenuGrid,
} from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import { HttpOrdemProducao } from "./Http";
//import { CopiarCtsPagar } from "./_CopiarCtsPagar";
import { CGIHistorico } from "../../../Components/Historico/CGIHistorico";
import { FormContext } from "../../../Context/FormConxtext";
import {
  ModalBaseType,
  LegendaTypes,
} from "../../../Types/Modal/ModalBaseType";
import {
  ListarOrdemProducaoItensTypes,
  ListarOrdemProducaoTypes,
  ObjListarOrdemProducaoPadrao,
  OrdemProducaoItensTypes,
} from "../../../Types/Producao/OrdemProducao/OrdemProducao";
import { AlterarOrdemProducao } from "./_AlterarOrdemProducao";
import { VisualizarOrdemProducao } from "./_VisualizarOrdemProducao";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function ListarOrdemProducao() {
  const AlterarOrdemProducaoRef = useRef<ModalBaseType>();
  const VisualizarOrdemProducaoRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarOrdemProducaoTypes>({
    defaultValues: ObjListarOrdemProducaoPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarOrdemProducao = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpOrdemProducao().RetornarOrdemProducao(DadosFiltros);
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarOrdemProducaoItensTypes) => {
      return await HttpOrdemProducao().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpOrdemProducao()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo Ordem de Producao",
      Permissao: "",
      OnClick: () => {
        AlterarOrdemProducaoRef.current?.Abrir();
      },
    },

    // {
    //     Nome: "Importar OrdemProducaos via Arquivo",
    //     Permissao: "",
    //     OnClick: () => {
    //         AlterarOrdemProducaoRef.current.Abrir();
    //     },
    // },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Alterar",
      Permissao: "producao/ordemproducao/alterar",
      OnClick: () => {
        AlterarOrdemProducaoRef.current?.Abrir();
      },
    },
    {
      Nome: "Visualizar",
      Permissao: "producao/ordemproducao/visualizar",
      OnClick: () => {
        VisualizarOrdemProducaoRef.current?.Abrir();
      },
    },
  ];

  const Legendas: LegendaTypes = [
    { nomeStatus: "Incluída", cor: "green" },
    { nomeStatus: "Em Produção", cor: "orange" },
    { nomeStatus: "Concluída", cor: "blue" },
    { nomeStatus: "Excluída", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarOrdemProducaoItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "id",
      Titulo: "OP",
      Tipo: "Numero",
      Ordernar: true,
      att: {
        maxWidth: 80,
      },
    },
    {
      Campo: "dataCadastro",
      Titulo: "Cadastro",
      Tipo: "Data",
    },
    {
      Campo: "dataPrevisao",
      Titulo: "Previsão",
      Tipo: "Data",
    },
    {
      Campo: "id",
      Titulo: "Código do Produto",
      Tipo: "Composta",
      Ordernar: true,
      Composta: [
        {
          titulo: "",
          valor: "codigoProduto",
          exibirLabel: false,
        },
        {
          titulo: "",
          valor: "nomeProduto",
          exibirLabel: false,
          negrito: true,
        },
      ],
    },
    {
      Campo: "nomeLocalizacao",
      Titulo: "Localização",
      Tipo: "Texto",
      Ordernar: true,
    },
    {
      Campo: "identificacaoPedido",
      Titulo: "Pedido",
      Tipo: "Texto",
      Ordernar: true,
    },
    {
      Campo: "qtdParaProducao",
      Titulo: "Qtd. para Produção",
      Tipo: "Numero",
      Ordernar: true,
      att: {
        minWidth: 100,
      },
    },
    {
      Campo: "qtdSaldo",
      Titulo: "Saldo Produção",
      Tipo: "Numero",
      Ordernar: true,
      att: {
        minWidth: 100,
      },
    },
    {
      Campo: "qtdPerda",
      Titulo: "Qtd. Perda",
      Tipo: "Numero",
      Ordernar: true,
      att: {
        minWidth: 100,
      },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarOrdemProducaoItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];

  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Ordem Producao"
          CarregarDados={RetornarOrdemProducao}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarOrdemProducao
          CarregarDados={methods.handleSubmit(RetornarOrdemProducao)}
          ref={AlterarOrdemProducaoRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarOrdemProducao
          CarregarDados={methods.handleSubmit(RetornarOrdemProducao)}
          ref={VisualizarOrdemProducaoRef}
        />
      ),
    },
  ];
  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Filtros de Período" popover="Filtros de Período">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarOrdemProducaoTypes>
                label="Data Cadastro Inicial"
                id="Pesquisa.dataCadastroInicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarOrdemProducaoTypes>
                label="Data Cadastro Final"
                id="Pesquisa.dataCadastroFinal"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarOrdemProducaoTypes>
                label="Data Previsão Inicial"
                id="Pesquisa.dataPrevEntregaInicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarOrdemProducaoTypes>
                label="Data Previsão Final"
                id="Pesquisa.dataPrevEntregaFinal"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarOrdemProducaoTypes>
                label="Data Conclusão Inicial"
                id="Pesquisa.dataConclusaoInicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarOrdemProducaoTypes>
                label="Data Conclusão Final"
                id="Pesquisa.dataConclusaoFinal"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBox spacing="3px">
            <CGIBoxItem Col={12}>
              <CGIComboSimples<ListarOrdemProducaoTypes>
                Multipla
                id={"Pesquisa.listaEmpresasId"}
                label={"Empresa"}
                Link={"empresa"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIComboSimples<ListarOrdemProducaoTypes>
                Multipla
                id={"Pesquisa.listaStatusId"}
                label={"Status"}
                Link={"STATUSORDEMPRODUCAO"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIInput<ListarOrdemProducaoTypes>
                id={"Pesquisa.ordemDeProducao"}
                label={"Ordem de Produção"}
                placeholder={""}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIComboSimples<ListarOrdemProducaoTypes>
                Multipla
                id={"Pesquisa.listaLocalizacaoId"}
                label={"Localização"}
                Link={"LOCALIZACAO-ORDEMPRODUCAO"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIComboAjax<ListarOrdemProducaoTypes>
                Multipla
                id={"Pesquisa.listaProdutosId"}
                label={"Produto"}
                Link={"produto"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarOrdemProducaoTypes>
            Multipla
            id={"Pesquisa.listaGruposId"}
            label={"Grupo"}
            Link={"grupo"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarOrdemProducaoTypes>
            Multipla
            id={"Pesquisa.listaSubGruposId"}
            label={"Sub-Grupo"}
            Link={"subgrupo"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarOrdemProducaoTypes>
            id={"Pesquisa.obs"}
            label={"Observação"}
            placeholder={""}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarOrdemProducaoTypes>
            Multipla
            id={"Pesquisa.listaClassesId"}
            label={"Classe"}
            Link={"classe"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarOrdemProducaoTypes>
            Multipla
            id={"Pesquisa.listaCoresId"}
            label={"Cor"}
            Link={"cor"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarOrdemProducaoTypes>
            id={"Pesquisa.qtdParaProducao"}
            label={"Qtd. para Produção"}
            placeholder={"0"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGICheckBox<ListarOrdemProducaoTypes>
            id={"Pesquisa.ocultarOrdensProdExcluir"}
            label={"Ocultar Ordens Prod. Excluídas"}
            tooltip={""}
            att={{
              style: { marginTop: 30 },
            }}
            attLabel={{
              style: { marginTop: 30 },
            }}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="producao/ordemproducao/listar"
      tipoGET={{
        url: "ORDEMPRODUCAO-LISTAR",
        setRetorno: (e) => {
          methods.setValue("Pesquisa", e);
        },
      }}
      Titulo="Ordem de Produção"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarOrdemProducao)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
}
