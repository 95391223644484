import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarPedidoCompraItensTypes,
  ListarPedidoCompraTypes,
  ObjListarPedidoCompraPadrao,
  PedidoCompraVisualizarTypes,
} from "../../../Types/Almoxarifado/PedidoCompra/PedidoCompraTypes";
import {
  ModalBaseType,
  LegendaTypes,
} from "../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpPedidoCompra } from "./Http";
import { AlterarPedidoCompra } from "./_AlterarPedidoCompra";
import { VisualizarPedidoCompra } from "./_VisualizarPedidoCompra";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIRadioButton } from "../../../Components/RadioButton/CGIRadioButton";
import { CGIHistorico } from "../../../Components/Historico/CGIHistorico";
import { FormContext } from "../../../Context/FormConxtext";
import { CGIVisualizarAnexo } from "../../../Components/Anexo/CGIVisualizarAnexo";

export function ListarPedidoCompra() {
  const VisualizarPedidoCompraRef = useRef<ModalBaseType>();
  const AlterarPedidoCompraRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const CGIHistoricoRef = useRef<ModalBaseType>();
  const CGIAnexoRef = useRef<ModalBaseType>();

  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarPedidoCompraTypes>({
    defaultValues: ObjListarPedidoCompraPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarPedidoCompras = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpPedidoCompra().RetornarPedidosCompra(DadosFiltros);
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarPedidoCompraItensTypes) => {
      return await HttpPedidoCompra().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpPedidoCompra()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo Pedido de Compra",
      Permissao: "almoxarifado/pedidocompra/incluir",
      OnClick: () => {
        AlterarPedidoCompraRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "almoxarifado/pedidocompra/visualizar",
      OnClick: (e: PedidoCompraVisualizarTypes) => {
        VisualizarPedidoCompraRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Aprovar/Reprovar",
      Permissao: "almoxarifado/pedidocompra/visualizar",
      OnClick: (e: PedidoCompraVisualizarTypes) => {
        VisualizarPedidoCompraRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Copiar",
      Permissao: "almoxarifado/pedidocompra/visualizar",
      OnClick: (e: PedidoCompraVisualizarTypes) => {
        VisualizarPedidoCompraRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Anexos",
      Permissao: "sistema/anexo/visualizaranexospedidocompra",
      OnClick: (e: ListarPedidoCompraItensTypes) => {
        CGIAnexoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Alterar",
      Permissao: "almoxarifado/pedidocompra/alterar",
      OnClick: (e: ListarPedidoCompraItensTypes) => {
        AlterarPedidoCompraRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Histórico",
      Permissao: "almoxarifado/pedidocompra/visualizarhistorico",
      OnClick: (e: ListarPedidoCompraItensTypes) => {
        CGIHistoricoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "almoxarifado/pedidocompra/excluir",
      OnClick: (e: ListarPedidoCompraItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas: LegendaTypes = [
    { nomeStatus: "Incluído", cor: "green" },
    { nomeStatus: "Pendente", cor: "Wheat" },
    { nomeStatus: "Aprovada", cor: "LightBlue" },
    { nomeStatus: "Reprovada", cor: "LightGrey" },
    { nomeStatus: "Baixa Parcial", cor: "orange" },
    { nomeStatus: "Baixa Total", cor: "blue" },
    { nomeStatus: "Encerrado", cor: "gray" },
    { nomeStatus: "Excluído", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarPedidoCompraItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "dataCadastro",
      Titulo: "Data",
      Tipo: "Data",
      att: { maxWidth: 110 },
    },
    {
      Campo: "id",
      Titulo: "Apr./Repr.Número/Empresa",
      Tipo: "Composta",
      att: { maxWidth: 210 },
      Composta: [
        {
          titulo: "Número",
          valor: "identificacao",
          negrito: true,
          exibirLabel: true,
        },
        {
          titulo: "Empresa",
          valor: "nomeEmpresa",
          negrito: true,
          exibirLabel: true,
        },
      ],
    },
    {
      Campo: "nomeFornecedor",
      Titulo: "Fornecedor",
      Tipo: "Texto",
      att: { minWidth: 280, wrapText: true },
    },
    {
      Campo: "id",
      Titulo: "Comprador/Cond.Pag.",
      Tipo: "Composta",
      att: { minWidth: 260, wrapText: true },
      Composta: [
        {
          titulo: "",
          valor: "nomeCondicaoPagamento",
          negrito: true,
        },
        {
          titulo: "Comprador",
          valor: "nomeComprador",
          negrito: true,
          exibirLabel: true,
        },
      ],
    },
    {
      Campo: "dataPrevisao",
      Titulo: "Dt. Prev.",
      Tipo: "Data",
      att: { maxWidth: 130 },
    },
    {
      Campo: "qtdItens",
      Titulo: "Qtd. Itens.",
      Tipo: "Numero",
      att: { maxWidth: 90, wrapHeaderText: true },
    },
    {
      Campo: "valorTotal",
      Titulo: "Valor (R$)",
      Tipo: "Moeda",
      att: { maxWidth: 140 },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarPedidoCompraItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir PedidoCompra"
          CarregarDados={RetornarPedidoCompras}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarPedidoCompra
          CarregarDados={methods.handleSubmit(RetornarPedidoCompras)}
          ref={AlterarPedidoCompraRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarPedidoCompra
          CarregarDados={methods.handleSubmit(RetornarPedidoCompras)}
          ref={VisualizarPedidoCompraRef}
        />
      ),
    },
    {
      Componente: (
        <CGIHistorico
          tipo="TimeLine"
          titulo="Histórico do Pedido"
          ref={CGIHistoricoRef}
          link={"almoxarifado/pedidocompra/visualizarhistorico"}
          origem={"PedidoCompra"}
        />
      ),
    },
    {
      Componente: (
        <CGIVisualizarAnexo
          ref={CGIAnexoRef}
          link={"sistema/anexo/visualizaranexospedidocompra"}
          origem={"PEDIDOCOMPRA"}
          titulo={"Anexos Pedido Compra"}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Filtros de Período">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarPedidoCompraTypes>
                id="Pesquisa.dataInicial"
                label="Data Cadastro - Inicial"
                DataInicial="15-dias-antes"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarPedidoCompraTypes>
                id="Pesquisa.dataFinal"
                label="Data Cadastro - Final"
                DataInicial="hoje"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Filtros de Valores">
            <CGIBoxItem Col={6}>
              <CGIInput<ListarPedidoCompraTypes>
                id="Pesquisa.valorDe"
                label="Valor do Pedido - Inicial"
                placeholder="0"
                mascara="Real"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIInput<ListarPedidoCompraTypes>
                id="Pesquisa.valorAte"
                label="Valor do Pedido - Final"
                placeholder="0"
                mascara="Real"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBox>
            <CGIBoxItem Col={6}>
              <CGIComboSimples<ListarPedidoCompraTypes>
                id="Pesquisa.listaEmpresasId"
                label="Empresa"
                Link={"empresa"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIComboSimples<ListarPedidoCompraTypes>
                id="Pesquisa.listaCondicaoPagamentoId"
                label="Condição de Pagamento"
                Link={"condicaodepagamento"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIInput<ListarPedidoCompraTypes>
                id="Pesquisa.identificacao"
                label="Pedido de Compra"
                placeholder={"Pedido de Compra"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIInput<ListarPedidoCompraTypes>
                id="Pesquisa.numeroPedidoCliente"
                label="Pedido do Cliente (OC)"
                placeholder={"Pedido do Cliente (OC)"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIInput<ListarPedidoCompraTypes>
                id="Pesquisa.identificacaoPedidoVenda"
                label="Pedido De Venda"
                placeholder={"Pedido De Venda"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIComboSimples<ListarPedidoCompraTypes>
                id="Pesquisa.listaTipoPedidoCompraId"
                label="Finalidade"
                Link={"tipopedido"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarPedidoCompraTypes>
                id="Pesquisa.listaStatusId"
                label="Status"
                Link={"statuspedidocompra"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={8}>
              <CGIComboSimples<ListarPedidoCompraTypes>
                id="Pesquisa.listaCentroDeCustoId"
                label="Centro de Custo"
                Link={"centrodecusto"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIRadioButton<ListarPedidoCompraTypes>
                id="Pesquisa.tipoPedido"
                label="Tipo de Pedido"
                row={"row"}
                values={[
                  {
                    label: "Ambos",
                    valor: "A",
                  },
                  {
                    label: "Produtos",
                    valor: "P",
                  },
                  {
                    label: "Serviços",
                    valor: "S",
                  },
                ]}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        {/* campos grandes */}
        <CGIBoxItem Col={6}>
          <CGIBox>
            <CGIBoxItem Col={12}>
              <CGIComboSimples<ListarPedidoCompraTypes>
                id="Pesquisa.listaFornecedoresId"
                label="Fornecedor"
                Link={"fornecedor"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIComboAjax<ListarPedidoCompraTypes>
                id="Pesquisa.produtosId"
                label="Produto"
                Link={"produto"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIComboSimples<ListarPedidoCompraTypes>
                id="Pesquisa.listaCompradoresId"
                label="Comprador"
                Link={"funcionario"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIComboSimples<ListarPedidoCompraTypes>
                id="Pesquisa.listaTransportadorasId"
                label="Transportadora"
                Link={"transportadora"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIComboSimples<ListarPedidoCompraTypes>
                id="Pesquisa.listaRedespachosId"
                label="Redespacho"
                Link={"transportadora"}
                Multipla
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="almoxarifado/pedidocompra/listar"
      Titulo="Pedido Compra"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarPedidoCompras)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
