export const ObjRetornoNotaFiscalPadrao: FormRetornoNotaFiscalType = {
  Form: {
    DadosRegistro: {
      id: 0,
      usuarioId: 0,
      dataCadastro: "",
      nFEntradaId: 0,
      nFSaidaId: 0,
      colaboradorId: 0,
      dataLimiteRetorno: "",
      valorNotaFiscal: 0,
      obs: "",
      nFSaidaRetornoId: 0,
      statusId: 0,
      usuarioExclusaoId: 0,
      dataExclusao: "",
    },
  },
};
export type RetornoNotaFiscalTypes = {
  DadosRegistro: {
    id: number;
    usuarioId: number;
    dataCadastro: string | null;
    nFEntradaId: number;
    nFSaidaId: number;
    colaboradorId: number;
    dataLimiteRetorno: string | null;
    valorNotaFiscal: number;
    obs: string;
    nFSaidaRetornoId: number;
    statusId: number;
    usuarioExclusaoId: number;
    dataExclusao: string | null;
  };
};
export type FormRetornoNotaFiscalType = {
  Form: RetornoNotaFiscalTypes;
};

export const ObjListarRetornoNotaFiscalPadrao: ListarRetornoNotaFiscalTypes = {
  Pesquisa: {
    dataCadastroInicial: "",
    dataCadastroFinal: "",
    dataInicialLimiteRetorno: "",
    dataFinalLimiteRetorno: "",
    listaColaboradoresId: "",
    listaStatusId: "",
  },
  Itens: [],
};

export type RetornoNotaFiscalPesquisaGridTypes = {
  ncm?: string;
};

export type ListarRetornoNotaFiscalTypes = {
  Pesquisa: ListarPesquisaRetornoNotaFiscalTypes;
  Itens: ListarRetornoNotaFiscalItensTypes[];
};

export type ListarPesquisaRetornoNotaFiscalTypes = {
  dataCadastroInicial: string;
  dataCadastroFinal: string;
  dataInicialLimiteRetorno: string;
  dataFinalLimiteRetorno: string;
  listaColaboradoresId: string;
  listaStatusId: string;
};

export type ListarRetornoNotaFiscalItensTypes = {
  id: number;
  nFEntradaId: number;
  nFSaidaId: number;
  nomeStatus: string;
  dataCadastro: string;
  dataLimiteRetorno: string | null;
  numeroNFEntrada: number;
  numeroNFSaida: number;
  numeroNFSaidaRetorno: number;
  nomeColaborador: string;
  nomeTipoNotaFiscalEntrada: string;
  valorNotaFiscal: number;
  numeroNF: string;
};
