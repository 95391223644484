import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../../Components/CheckBox/CGICheckBox";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIModal } from "../../../../Components/Modal/CGIModal";
import { ModalBaseType } from "../../../../Types/Modal/ModalBaseType";
import { 
    FormLimiteCreditoTypes,
    LimiteCreditoVisualizarTypes,
    ListarLimiteCreditoItensTypes,
    ListarLimiteCreditoTypes,
    ObjListarLimiteCreditoPadrao,
} from "../../../../Types/Sistema/Classificacao/LimiteCredito/LimiteCreditoTypes";
import { HttpLimiteCredito } from "./Http";

type VisualizarLimiteCreditoType = {
    CarregarDados: Function;
};
export const VisualizarLimiteCredito = forwardRef(
    (props: VisualizarLimiteCreditoType, ref) => {
        // INICIO REF
        useImperativeHandle(ref, () => ({
            Abrir() {
                CGIModalRef.current?.Abrir();
            },
        }));
        // FINAL REF

        const CGIModalRef = useRef<ModalBaseType>(null);
        const { reset } = useFormContext();

        async function CarregarDados(params: FormLimiteCreditoTypes) {
            var data = await HttpLimiteCredito().Visualizar(params.Form.DadosRegistro);
            reset(data.data.data);
        }

        return (
            <CGIModal
                id="sistema/classificacao/visualizar"
                ref={CGIModalRef}
                Titulo="Visualizar Limite de Crédito"
                TelaCheia={"6xl"}
                Confirmar={() => { }}
                Fechar={() => { }}
                ocultarCancelar={true}
                ocultarConfirmar={true}
            >
                <CGIBox>
                    <CGIBoxItem Col={2}>
                        <CGIInput<LimiteCreditoVisualizarTypes>
                            id="id"
                            label="Id"
                            placeholder="Id"
                            att={{ isReadOnly: true }}
                        />
                    </CGIBoxItem>
                    <CGIBoxItem Col={6}>
                        <CGIInput<LimiteCreditoVisualizarTypes>
                            id="nome"
                            label="Nome"
                            placeholder="Nome"
                            att={{ isReadOnly: true }}
                        />
                    </CGIBoxItem>
                    <CGIBoxItem Col={4}>
                        <CGICheckBox<LimiteCreditoVisualizarTypes>
                            id="ativo"
                            label="Ativo"
                            tooltip=""
                            att={{
                                disabled: true, style: {
                                    marginTop: 30,
                                }
                            }}
                            attLabel={{
                                marginTop: 30,
                            }}
                        />
                    </CGIBoxItem>
                </CGIBox>
            </CGIModal>
        );
    }
);
