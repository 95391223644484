import { Toast, useToast } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import {
  useRef,
  useContext,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import LoadingContext from "../../../Context/LoadingContext";
import {
  BloquearClientesEmAtrasoItensDetalhamentoType,
  BloquearClientesEmAtrasoItensPesquisaType,
  FormBloquearClientesEmAtrasoType,
  BloquearClientesEmAtrasoPesquisaType,
  ObjBloquearClientesEmAtrasoPadrao,
} from "../../../Types/Financeiro/ContasReceber/BloquearClientesEmAtrasoTypes";
import { HttpCtsReceber } from "./Http";

type PropsBloquearClientesEmAtrasoType = {
  CarregarDados: Function;
  //RetornarClientesEmAtrasoEspecifico: Function;
};

export const BloquearClientesEmAtraso = forwardRef(
  (props: PropsBloquearClientesEmAtrasoType, ref) => {
    useImperativeHandle(ref, () => ({
      async Abrir(pDados: any) {
        CGIModalRef.current?.Abrir();
      },
    }));

    const form = useFormContext<FormBloquearClientesEmAtrasoType>();
    const { setLoadingState } = useContext(LoadingContext);
    const CGIModalRef = useRef() as any;
    const { ToastSucesso } = useCGIToast();
    const methods = useForm<FormBloquearClientesEmAtrasoType>({
      defaultValues: ObjBloquearClientesEmAtrasoPadrao,
    });

    async function CarregarDados() {
      setLoadingState(true);

      var data = await HttpCtsReceber()
        .RetornarClientesEmAtraso()
        .finally(() => {
          setLoadingState(false);
        });
      methods.setValue("form.itens", data.itens);
    }

    const GridDetalhamentoRef =
      useRef<AgGridReact<FormBloquearClientesEmAtrasoType>>(null);

    const GridPrincipalRef =
      useRef<AgGridReact<FormBloquearClientesEmAtrasoType>>(null);

    const GridItensPesquisa = useWatch({
      control: methods.control,
      name: "form.pesquisa.itens",
    });
    const GridItensPrincipal = useWatch({
      control: methods.control,
      name: "form.itens",
    });

    const RetornarDadosEspecificos = useCallback(
      async (Dados: BloquearClientesEmAtrasoItensPesquisaType) => {
        try {
          setLoadingState(true);
          var data = await HttpCtsReceber().RetornarClientesEmAtrasoEspecifico(
            Dados
          );
          methods.setValue("form.pesquisa.itens", data);
        } catch (e) {
        } finally {
          setLoadingState(false);
        }
      },
      [methods, setLoadingState]
    );

    const Confirmar = async () => {
      setLoadingState(true);
      let Dados = form.getValues("form.itens");

      try {
        await HttpCtsReceber().BloquearClientesEmAtraso(Dados);
        ToastSucesso("Registro Alterado com sucesso", "OK!");

        console.log(Dados);

        props.CarregarDados();
        CGIModalRef.current.Fechar();
      } finally {
        setLoadingState(false);
      }
    };

    const GridPesquisaColunas: Array<
      CGICol<BloquearClientesEmAtrasoItensPesquisaType>
    > = [
      {
        Campo: "selecionado",
        Titulo: "",
        Tipo: "CheckBox",
        att: { maxWidth: 100 },
      },
      {
        Campo: "nomeCliente",
        Titulo: "Nome Cliente",
        Tipo: "Texto",
        att: { maxWidth: 300 },
      },
      {
        Campo: "totalEmAberto",
        Titulo: "Total Em Aberto",
        Tipo: "Numero",
      },
      {
        Campo: "diasMaiorAtraso",
        Titulo: "Dias Maior Atraso",
        Tipo: "Texto",
      },
      {
        Campo: "vencimentoMaisAntigo",
        Titulo: "Vencimento Mais Antigo",
        Tipo: "Data",
      },
    ];
    const GridDetalhamentoColunas: Array<
      CGICol<BloquearClientesEmAtrasoItensDetalhamentoType>
    > = [
      {
        Campo: "id",
        Titulo: "Código",
        Tipo: "Texto",
        ocultar: true,
        att: {
          maxWidth: 100,
        },
      },
      {
        Campo: "documento",
        Titulo: "Código Produto",
        Tipo: "Texto",
      },
      {
        Campo: "obs",
        Titulo: "Obs",
        Tipo: "Texto",
      },
      {
        Campo: "dataVencimento",
        Titulo: "Data Vencimento",
        Tipo: "Data",
      },
      {
        Campo: "diasAtraso",
        Titulo: "Dias de Atraso",
        Tipo: "Texto",
      },
    ];
    const Janelas = [
      {
        Componente: <></>,
      },
    ];

    return (
      <CGIModal
        ref={CGIModalRef}
        id="financeiro/ctsreceber/bloqueiodeclientes"
        Titulo="Bloquear Clientes em Atraso"
        Confirmar={Confirmar}
        ocultarConfirmar={true}
        TelaCheia={"7xl"}
        Fechar={() => {}}
      >
        <FormProvider {...methods}>
          <CGIBox>
            <CGIBoxItem Col={12}>
              <CGIButton
                onClick={form.handleSubmit(CarregarDados)}
                att={{
                  mt: 8,
                  marginLeft: 1415,
                }}
                label="Pesquisar"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIBox>
                <CGIBoxItem Col={12}>
                  <CGIBox>
                    <CGIBoxItem Col={12}>
                      <CGIDataTableSimples
                        ref={GridPrincipalRef}
                        Altura="300px"
                        Colunas={GridPesquisaColunas}
                        Data={GridItensPrincipal}
                        DisableGetId
                        Att={{
                          rowSelection: "multiple",
                          onCellClicked(event) {
                            const selectedRows = event.api.getSelectedRows();
                            RetornarDadosEspecificos(selectedRows[0]);
                          },
                        }}
                      />
                    </CGIBoxItem>
                  </CGIBox>
                </CGIBoxItem>
                <CGIBoxItem Col={12}>
                  <CGIBox titulo="Detalhamento">
                    <CGIBoxItem Col={12}>
                      <CGIDataTableSimples
                        ref={GridDetalhamentoRef}
                        Altura="300px"
                        Colunas={GridDetalhamentoColunas}
                        Data={GridItensPesquisa}
                      />
                    </CGIBoxItem>
                  </CGIBox>
                </CGIBoxItem>
              </CGIBox>
            </CGIBoxItem>

            <CGIBoxItem Col={12}>
              <CGIBoxItem Col={12}>
                <CGIInput<BloquearClientesEmAtrasoPesquisaType>
                  id={"motivo"}
                  label={"Motivo"}
                  placeholder={""}
                  validadores={{ required: true }}
                  att={{ height: 100 }}
                />
              </CGIBoxItem>
            </CGIBoxItem>
          </CGIBox>
        </FormProvider>
      </CGIModal>
    );
    // Final JSX
  }
);
