import React, { createContext } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { ModalBaseType } from "../../Types/Modal/ModalBaseType";
import { CGIModalMenuPesquisa } from "./CGIModalMenuPesquisa";

type CGIMenuPesquisaTypes = {};

const DefaultValue: CGIMenuPesquisaTypes = {};

export const CGIMenuPesquisa =
  createContext<CGIMenuPesquisaTypes>(DefaultValue);

function CGIMenuPesquisaProvider(props: { children: React.ReactNode }) {
  const CGIMenuPesquisaRef = React.useRef<ModalBaseType>();

  const [CGIMenuPesquisaIsOpen, setCGIMenuPesquisaIsOpen] =
    React.useState(false);

  const [CGIMenuPesquisaListarIsOpen, setCGIMenuPesquisaListarIsOpen] =
    React.useState(false);
  useHotkeys(
    "F1",
    e => {
      e.preventDefault();
      if (CGIMenuPesquisaIsOpen) {
        CGIMenuPesquisaRef.current?.Fechar();
      } else {
        CGIMenuPesquisaRef.current?.Abrir();
      }
      setCGIMenuPesquisaIsOpen(!CGIMenuPesquisaIsOpen);
    },
    { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] }
  );

  return (
    <CGIMenuPesquisa.Provider value={{}}>
      {props.children}
      <CGIModalMenuPesquisa ref={CGIMenuPesquisaRef} />
    </CGIMenuPesquisa.Provider>
  );
}

export { CGIMenuPesquisaProvider };
export default CGIMenuPesquisa;
