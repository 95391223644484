import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { RecalcularDescontoPedidoType } from "../../../Types/Vendas/Pedido/PedidoTypes";

type PropsRecalcularDescontoPedidoType = {
  CarregarDados: Function;
};

export const RecalcularDescontoPedido = forwardRef(
  (props: PropsRecalcularDescontoPedidoType, ref) => {
    useImperativeHandle(ref, () => ({
      Abrir(pId: number) {
        form.setValue("id", pId);
        CGIModalRef.current?.Abrir();
      },
    }));
    const form = useFormContext<RecalcularDescontoPedidoType>();
    const CGIModalRef = useRef<ModalBaseType>();

    async function CarregarDados(id: number) {}

    const Confirmar = async (data: any, event: any) => {};

    return (
      <CGIModal
        id="vendas/pedido/recalculardescontopedido"
        ref={CGIModalRef}
        Titulo="Recalcular Desconto do Pedido"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        CarregarDados={CarregarDados}
        Fechar={() => {}}
      >
        <CGIResumo
          Principal="PEDIDO"
          PedidoId="id"
          ResumoPedido
          ResumoPedidoItens
        />
      </CGIModal>
    );
    //FINAL JSX
  }
);
