import { useToast } from "@chakra-ui/react";
import React from "react";

/**
 * @Componente useCGIToast - Hook Toast
 * @Ref  Hook
 */
export function useCGIToast() {
  var toast = useToast();

  function ToastInfo(Descricao: string, Titulo?: string) {
    return toast({
      title: Titulo,
      description: Descricao,
      status: "info",
      duration: 9000,
      isClosable: true,
      position: "top",
    });
  }
  function ToastErro(Descricao: string, Titulo?: string) {
    return toast({
      title: Titulo,
      description: <div dangerouslySetInnerHTML={{ __html: Descricao }} />,
      status: "error",
      duration: 9000,
      isClosable: true,
      position: "top",
    });
  }
  function ToastSucesso(Descricao: string, Titulo?: string) {
    return toast({
      title: Titulo,
      description: Descricao,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  }
  function ToastErrorId(
    Id: string,
    Descricao: string,
    Titulo?: string,
    Render?: string
  ) {
    if (!toast.isActive(Id)) {
      return toast({
        id: Id,
        title: Titulo,
        description: <div dangerouslySetInnerHTML={{ __html: Descricao }} />,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  return { ToastSucesso, ToastErro, ToastInfo, ToastErrorId };
}
