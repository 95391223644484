import React from "react";
import { Button, ButtonProps, Tooltip } from "@chakra-ui/react";
import { ReactElement, useContext } from "react";
import { FiCheck, FiX } from "react-icons/fi";
import LoadingContext from "../../Context/LoadingContext";

interface ButtonType extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  icon?: ReactElement;
  att?: ButtonProps;
  tooltip?: React.ReactNode | string;
}
/**
 * @Componente CGIButtonCancelar - Botão padrão do Sistema para Confirmar
 * @Ref  CGIButton
 */
export function CGIButtonConfirmar(props: ButtonType) {
  const { Loadingstate } = useContext(LoadingContext);

  return (
    <Button
      {...props}
      isLoading={Loadingstate}
      w={240}
      p="6"
      bg="teal.600"
      color="white"
      fontWeight="bold"
      fontSize="xl"
      mt="2"
      _hover={{ bg: "teal.800" }}
      leftIcon={<FiCheck />}
    >
      Confirmar
    </Button>
  );
}
