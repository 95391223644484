import { AxiosInstance } from "axios";
import { CGIHttp } from "../../Services/Axios";
const url = "home";
export function HttpForm() {
  async function DefinirMenuFavorito(dados: any) {
    return await new CGIHttp().conn.post(url + "/definir-menu-favorito", dados);
  }
  return {
    DefinirMenuFavorito,
  };
}
