import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import { CGITextArea } from "../../../Components/TextArea/CGITextArea";
import {
  ConsultarEstoqueProdutoItensTypes,
  ConsultarEstoqueProdutoTypes,
} from "../../../Types/Almoxarifado/MovEstoque/ConsultarEstoqueProdutoType";

import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { HttpMovEstoque } from "./Http";

type PropsConsultarEstoqueProdutoType = {
  CarregarDados: Function;
};

export const ConsultarEstoqueProduto = forwardRef(
  (props: PropsConsultarEstoqueProdutoType, ref) => {
    useImperativeHandle(ref, () => ({
      Abrir(pId: number) {
        form.setValue("Pesquisa.produtoId", pId);
        CGIModalRef.current?.Abrir();
        CarregarDados();
      },
    }));
    const form = useFormContext<ConsultarEstoqueProdutoTypes>();
    const CGIModalRef = useRef<ModalBaseType>();
    const GridItens = useWatch({
      control: form.control,
      name: "Itens",
    });
    const GridColunas: Array<CGICol<ConsultarEstoqueProdutoItensTypes>> = [
      {
        Campo: "nomeEmpresa",
        Titulo: "Empresa",
        Tipo: "Texto",
      },
      {
        Campo: "estoqueAtual",
        Titulo: "Estoque Atual",
        Tipo: "Numero",
        ExibirCoresConformeValor: true,
      },
    ];

    async function CarregarDados() {
      const DadosFiltro = form.getValues("Pesquisa");
      var Dados =
        await HttpMovEstoque().RetornarEstoqueNasEmpresasParaAtualizar(
          DadosFiltro.produtoId
        );
      form.setValue("Itens", Dados);
    }

    const Confirmar = async (data: any, event: any) => {};

    return (
      <CGIModal
        id="almoxarifado/movestoque/consultarestoqueproduto"
        ref={CGIModalRef}
        Titulo="Consultar Estoque Produto"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        ocultarCancelar
        ocultarConfirmar
        CarregarDados={CarregarDados}
        Fechar={() => {}}
      >
        <CGIBox>
          <CGIBoxItem Col={6}>
            <CGIResumo
              Expandir
              ResumoProdutoCodigoEDescricao
              ProdutoId="Pesquisa.produtoId"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIDataTableSimples
              DisableGetId
              Altura="300px"
              Colunas={GridColunas}
              Data={GridItens}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
    //FINAL JSX
  }
);
