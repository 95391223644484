import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../../Components/CheckBox/CGICheckBox";
import { useCGIToast } from "../../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIModal } from "../../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../../Context/LoadingContext";
import {
    FormLimiteCreditoTypes,
    LimiteCreditoVisualizarTypes,
    ListarLimiteCreditoItensTypes,
    ListarLimiteCreditoTypes,
    ObjListarLimiteCreditoPadrao,
} from "../../../../Types/Sistema/Classificacao/LimiteCredito/LimiteCreditoTypes";
import { HttpLimiteCredito } from "./Http";

type AlterarLimiteCreditoType = {
    CarregarDados: Function;
};

export const AlterarLimiteCredito = forwardRef(
    (props: AlterarLimiteCreditoType, ref) => {
        //INICIO REF
        useImperativeHandle(ref, () => ({
            Abrir() {
                CGIModalRef.current.Abrir();
            },
            Alterar(pEmpresa: any) {
                CGIModalRef.current.Abrir();

                HttpLimiteCredito()
                    .RetornarDadosLimiteCredito(pEmpresa.id)
                    .then(e => {
                        form.reset(e.data.data);
                    });
            },
        }));
        //FINAL  REF

        //INICIO  HOOKS
        const CGIModalRef = useRef() as any;
        const form = useFormContext();
        const { ToastErro, ToastSucesso } = useCGIToast();
        const { setLoadingState } = useContext(LoadingContext);

        //FINAL  HOOKS

        //INICIO CONST

        const RenderTabs: Array<CGITabItensTypes> = [
            {
                Titulo: "Dados Limite de Crédito",
                Component: AreaDadosLimiteCredito(),
            },
        ];
        //FINAL  CONST

        //INICO FUNC
        function AreaDadosLimiteCredito() {
            return (
                <CGIBox>
                    <CGIBoxItem Col={6}>
                        <CGIInput<FormLimiteCreditoTypes>
                            id="Form.DadosRegistro.nome"
                            label="Nome"
                            placeholder="Nome Condição de Pagamento"
                            validadores={{
                                required: "Nome obrigatório"
                            }}
                        />
                    </CGIBoxItem>
                    <CGIBoxItem Col={3}>
                        <CGIInput<FormLimiteCreditoTypes>
                            id="Form.DadosRegistro.valor"
                            label="Valor"
                            placeholder="Valor"
                        />
                    </CGIBoxItem>
                    <CGIBoxItem Col={3}>
                        <CGIBoxItem Col={12}>
                            <CGICheckBox<FormLimiteCreditoTypes>
                                id="Form.DadosRegistro.ativo"
                                label="Ativo"
                                tooltip=""
                                att={{
                                    disabled: true, style: {
                                        marginTop: 30,
                                    }
                                }}
                                attLabel={{
                                    marginTop: 30,
                                }}
                            />
                        </CGIBoxItem>
                    </CGIBoxItem>
                </CGIBox>
            );
        }

        const Confirmar = async (data: FormLimiteCreditoTypes, event: any) => {
            setLoadingState(true);

            if (data.Form.DadosRegistro.id == null || data.Form.DadosRegistro.id === undefined || data.Form.DadosRegistro.id === 0) {
                HttpLimiteCredito()
                    .Incluir(data.Form.DadosRegistro)
                    .then((response: any) => {
                        ToastSucesso("Registro gravado com sucesso", "OK!");
                        props.CarregarDados();
                        CGIModalRef.current.Fechar();
                    })
                    .catch(e => {
                        return ToastErro(e);
                    })
                    .finally(() => {
                        setLoadingState(false);
                    });
            } else {
                HttpLimiteCredito()
                    .Alterar(data.Form.DadosRegistro)
                    .then((data: any) => {
                        ToastSucesso("Registro Alterado com sucesso", "OK!");
                        props.CarregarDados();
                        CGIModalRef.current.Fechar();
                    })
                    .catch(e => {
                        return;
                    })
                    .finally(() => {
                        setLoadingState(false);
                    });
            }
        };
        //FINAL FUNC

        //INICO JSX
        return (
            <CGIModal
                id="sistema/classificacao/alterar"
                ref={CGIModalRef}
                Titulo="Limite de Crédito"
                TelaCheia={"6xl"}
                Confirmar={Confirmar}
                Fechar={() => {
                    form.reset({});
                }}
            >
                <CGITabs Tabs={RenderTabs} />
            </CGIModal>
        );
        //FINAL JSX
    }
);
