import { Flex } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import {
  FormOrdemProducaoType,
  ObjPadrao,
  OrdemProducaoItensTypes,
  ObjListarOrdemProducaoPadrao,
} from "../../../Types/Producao/OrdemProducao/OrdemProducao";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { HttpOrdemProducao } from "./Http";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";

export const AlterarOrdemProducao = forwardRef(
  (
    props: {
      CarregarDados: () => void;
    },
    ref
  ) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        form.reset(ObjPadrao);
        CGIModalRef.current?.Abrir();
      },
      Alterar(pOrdemProducaoId: number) {
        CGIModalRef.current?.Abrir();
      },
    }));

    const CGIModalRef = useRef<ModalBaseType>();
    const form = useFormContext<FormOrdemProducaoType>();

    return (
      <CGIModal
        id="sistema/classificacao/alterar"
        ref={CGIModalRef}
        Titulo="Relação Entrega"
        TelaCheia={"6xl"}
        CarregarDados={() => {}}
        Confirmar={(e: any) => {}}
        Fechar={() => {
          form.reset(ObjPadrao);
        }}
      >
        <CGIBoxItem Col={6}>
          <CGIComboAjax<FormOrdemProducaoType>
            id="Form.DadosRegistro.codigoDoProduto"
            label="Produto"
            Link={"produto"}
            validadores={{
              required: "Campo obrigatório",
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIComboSimples<FormOrdemProducaoType>
            id="Form.DadosRegistro.nomeEmpresa"
            label="Empresa"
            Link={"empresa"}
            validadores={{
              required: "Campo obrigatório",
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIDatePicker<FormOrdemProducaoType>
            id="Form.DadosRegistro.dataPrevisaoEntrega"
            label="Previsão de Entrega"
            validadores={{
              required: "Campo obrigatório",
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormOrdemProducaoType>
            id="Form.DadosRegistro.qtdParaProducao"
            label="Quantidade para Produção"
            placeholder="0"
            validadores={{
              required: "Campo obrigatório",
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormOrdemProducaoType>
            id="Form.DadosRegistro.nmrRepeticoes"
            label="Número Repetições"
            placeholder=""
            validadores={{
              required: "Campo obrigatório",
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<FormOrdemProducaoType>
            id="Form.DadosRegistro.localizacaoId"
            label="Localização"
            Link={""}
            validadores={{
              required: "Campo obrigatório",
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIInput<FormOrdemProducaoType>
            id="Form.DadosRegistro.obs"
            label="Observações"
            placeholder=""
            validadores={{
              required: "Campo obrigatório",
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGICheckBox<FormOrdemProducaoType>
            id="Form.DadosRegistro.naoMovimentarEstoque"
            label="Não Movimentar Estoque"
            tooltip=""
            validadores={{
              required: "Campo obrigatório",
            }}
          />
        </CGIBoxItem>
      </CGIModal>
    );
  }
);
