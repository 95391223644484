/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react";
import { UseFormReturn, FieldValues } from "react-hook-form";
import { CGIBox } from "../../Box/CGIBox";
import { CGIBoxItem } from "../../Box/CGIBoxItens";
import { CGICheckBox } from "../../CheckBox/CGICheckBox";
import { CGIDatePicker } from "../../DatePicker/CGIDatePicker";
import { CGIInput } from "../../Input/CGIInput";
import { CGIRadioButton } from "../../RadioButton/CGIRadioButton";
import { HttpCGIResumosComposto } from "../HttpResumo";

function CGIResumoProduto(
  watchProdutoId: string,
  formPrincipal: UseFormReturn<FieldValues, any>,
  formResumo: UseFormReturn<FieldValues, any>
) {
  const Tipo = "Produto";
  const [valueOld, setValueOld] = useState();

  useEffect(() => {
    var value =
      formPrincipal.getValues(watchProdutoId) ||
      formResumo.getValues(watchProdutoId);
    if (value == null || value === undefined || value === "") {
      return;
    } else if (value === 0) {
      return formResumo.reset({});
    }

    if (valueOld === value) {
      return;
    } else {
      setValueOld(value);
    }

    RetornarDados(value);
  }, [formPrincipal.watch(watchProdutoId), formResumo.watch(watchProdutoId)]);

  async function RetornarDados(id: any) {
    var Dados = await HttpCGIResumosComposto().RetornarDados(id, Tipo);
    var key = Object.keys(Dados.registros[0]);
    key.map(e => {
      if (e === "Id" || e === "id") return "";
      var value = Dados.registros[0][e];

      return formResumo.setValue(e, value);
    });
  }
  return (
    <CGIBox spacing="10px">
      <CGIBoxItem Col={2}>
        <CGIInput
          id={"PRODId"}
          label={"Id"}
          placeholder={"Id"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIInput
          id={"PRODCodigoProduto"}
          label={"Código"}
          placeholder={"Código"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIInput
          id={"PRODcodFabricante"}
          label={"Cod. Fabricante"}
          placeholder={"Código Fabricante"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={2}>
        <CGIInput
          id={"PRODDataCadastro"}
          label={"Data Cad."}
          placeholder={"Data Cad."}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={6}>
        <CGIInput
          id={"PRODDescricao"}
          label={"Descrição"}
          placeholder={"Descrição"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={6}>
        <CGIInput
          id={"PRODDescricaoFornecedor"}
          label={"Descrição Fornecedor"}
          placeholder={"Descrição Fornecedor"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"PRODCodigoBarras"}
          label={"Cod. Barras"}
          placeholder={"Cod. Barras"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"PRODUnidade"}
          label={"Unidade"}
          placeholder={"Unidade"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"PRODNomeGrupo"}
          label={"Grupo"}
          placeholder={"Grupo"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"PRODNomeSubGrupo"}
          label={"Sub-Grupo"}
          placeholder={"Sub-Grupo"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"PRODNomeMarca"}
          label={"Marca"}
          placeholder={"Marca"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"PRODEstoqueAtual"}
          label={"Estoque Atual"}
          placeholder={"Estoque Atual"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"PRODEstoqueMinimo"}
          label={"Estoque Mínimo"}
          placeholder={"Estoque Mínimo"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"PRODPercReposicao"}
          label={"% Reposição"}
          placeholder={"% Reposição"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"PRODPrecoCusto"}
          label={"Preço Custo"}
          placeholder={"Preço Custo"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"PRODPrecoVenda"}
          label={"Preço Venda"}
          placeholder={"Preço Venda"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"PRODPesoLiquido"}
          label={"Peso Líquido"}
          placeholder={"Peso Líquido"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"PRODPrecoBruto"}
          label={"Peso Bruto"}
          placeholder={"Peso Bruto"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIInput
          id={"PRODDescricaoAplicacao"}
          label={"Descrição Aplicação"}
          placeholder={"Descrição Aplicação"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIInput
          id={"PRODGrupoTributacao"}
          label={"Grupo Trib."}
          placeholder={"Grupo Tributação"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIInput
          id={"PRODUltimoPedidoCompra"}
          label={"Último Ped. Compra"}
          placeholder={"Último Ped. Compra"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIInput
          id={"PRODUltimaNFEntrada"}
          label={"Última NF. Entrada"}
          placeholder={"Última NF. Entrada"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIInput
          id={"PRODObs"}
          label={"Observação"}
          placeholder={"Observação"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIInput
          id={"PRODObsVenda"}
          label={"Obs. Venda"}
          placeholder={"Obs. Venda"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"PRODPercICMS"}
          label={"% ICMS"}
          placeholder={"% ICMS"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"PRODPercIPI"}
          label={"% IPI"}
          placeholder={"% IPI"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"PRODCst"}
          label={"CST"}
          placeholder={"CST"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"PRODOrigem"}
          label={"Origem"}
          placeholder={"Origem"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
    </CGIBox>
  );
}

export const ResumoProduto = {
  id: "ResumoPedido",
  titulo: "Dados Produto",
  componente: CGIResumoProduto,
};
