export const ObjPadrao: FormOrdemProducaoType = {
  Form: {
    DadosRegistro: {
      empresaId: 0,
      nomeEmpresa: "",
      dataCadastroInicial: "",
      dataCadastroFinal: "",
      dataPrevEntregaInicial: "",
      dataPrevEntregaFinal: "",
      dataConclusaoInicial: "",
      dataConclusaoFinal: "",
      statusId: 0,
      localizacaoId: 0,
      grupoId: 0,
      subGrupoId: 0,
      classeId: 0,
      corId: 0,
      produtoId: 0,
      qtdParaProducao: 0,
      obs: "",
      codigoDoProduto: "",
      descricaoDoProduto: "",
      dataPrevisaoEntrega: 0,
      qtdJaProduzida: 0,
      nmrRepeticoes: 0,
      naoMovimentarEstoque: true,
    },
    Itens: [],
  },
};

export const ObjListarOrdemProducaoPadrao: ListarOrdemProducaoTypes = {
  Pesquisa: {
    identificacaoPedido: "",
    clienteId: 0,
    obs: "",
    statusId: 0,
    dataCadastroInicial: "",
    dataCadastroFinal: "",
    dataPrevEntregaInicial: "",
    dataPrevEntregaFinal: "",
    dataConclusaoInicial: "",
    dataConclusaoFinal: "",
    exibirExcluidos: false,
    entregueComAtraso: false,
    listaProdutosId: "",
    listaGruposId: "",
    listaSubGruposId: "",
    listaStatusId: "",
    listaLocalizacaoId: "",
    listaEmpresasId: "",
    ordemDeProducao: 0,
    listaClassesId: "",
    listaCoresId: "",
    qtdParaProducao: 0,
    ocultarOrdensProdExcluir: true,
    dataCadastro: "",
    dataPrevisao: "",
  },
  Itens: [],
};
export type FormOrdemProducaoType = {
  Form: {
    DadosRegistro: OrdemProducaoTypes;
    Itens: OrdemProducaoItensTypes[];
  };
};
export type OrdemProducaoTypes = {
  empresaId: number;
  nmrRepeticoes: number;
  naoMovimentarEstoque: boolean;
  nomeEmpresa: string;
  dataCadastroInicial: string;
  dataCadastroFinal: string;
  dataPrevEntregaInicial: string;
  dataPrevEntregaFinal: string;
  dataConclusaoInicial: string;
  dataConclusaoFinal: string;
  statusId: number;
  localizacaoId: number;
  grupoId: number;
  subGrupoId: number;
  classeId: number;
  corId: number;
  produtoId: number;
  qtdParaProducao: number;
  obs: string;
  codigoDoProduto: string;
  descricaoDoProduto: string;
  dataPrevisaoEntrega: number;
  qtdJaProduzida: number;
};

export type OrdemProducaoItensTypes = {
  id: number;
  dataCadastro: string;
  dataPrevisao: string;
  ordemProducaoId: number;
  titulo: string;
  obs: string;
  nomeUsuario: string;
  qtdParaProducao: string;
  codigoProducao: string;
  nomeProduto: string;
  nomeLocalizacao: string;
  identificacaoPedido: string;
  nomeCliente: string;
  saldoProducao: string;
  qtdPerda: string;
  qtdJaProduzida: string;
  selecionado: boolean;
  usuarioId: number;
  codigoProduto: string;
  nomeStatus: string;
  descricaoProduto: string;
  nomeProdutoRefugo: string;
};

export type OrdemProducaoPesquisaGridTypes = {
  nomeCliente?: string;
  dataInicial?: string;
  dataFinal?: string;
};

export type ListarOrdemProducaoTypes = {
  Pesquisa: ListarPesquisaOrdemProducaoTypes;
  Itens: ListarOrdemProducaoItensTypes[];
};

export type ListarPesquisaOrdemProducaoTypes = {
  identificacaoPedido: string;
  qtdParaProducao: number;
  ordemDeProducao: number;
  statusId: number;
  clienteId: number;
  dataCadastroInicial: string;
  dataCadastroFinal: string;
  dataCadastro: string;
  dataPrevisao: string;
  dataPrevEntregaInicial: string;
  dataPrevEntregaFinal: string;
  dataConclusaoInicial: string;
  dataConclusaoFinal: string;
  exibirExcluidos: boolean;
  entregueComAtraso: boolean;
  listaProdutosId: string;
  listaGruposId: string;
  listaSubGruposId: string;
  listaStatusId: string;
  listaLocalizacaoId: string;
  listaEmpresasId: string;
  listaClassesId: string;
  obs: string;
  listaCoresId: string;
  ocultarOrdensProdExcluir: boolean;
};

export type ListarOrdemProducaoItensTypes = {
  id: number;
  dataCadastro: string;
  dataPrevisao: string;
  ordemProducaoId: number;
  usuarioId: number;
  titulo: string;
  obs: string;
  nomeUsuario: string;
  qtdParaProducao: string;
  codigoProduto: string;
  descricao: string;
  localizacao: string;
  identificacaoPedido: string;
  nomeCliente: string;
  nomeLocalizacao: string;
  nomeProduto: string;
  saldoProducao: string;
  qtdPerda: string;
  qtdJaProduzida: string;
  selecionado: boolean;
  nomeStatus: string;
  descricaoProduto: string;
  qtdSaldo: number;
};

export type OrdemProducaoVisualizarTypes = {
  id: number;
  nomeUsuario: number;
  dataCadastro: string;
  dataPrevisao: string;
  descricaoProduto: number;
  nomeEmpresa: number;
  obs: string;
  labelCampoRefugo1: number;
  labelCampoRefugo2: number;
  labelCampoRefugo3: number;
  labelCampoRefugo4: number;
  dataPrevEntrega: number;
  qtdParaProducao: number;
  saldoParaProducao: number;
  qtdPerda: number;
  qtdJaProduzida: number;
};
