import { Divider, Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import { useCallback, useEffect } from "react";
import {
  useForm,
  useFormContext,
  UseFormReturn,
  useWatch,
} from "react-hook-form";
import { CGIBox } from "../../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../../Components/Box/CGIBoxItens";
import { AnaliseCreditoTypes } from "../../../../../Types/Financeiro/Colaborador/AnaliseCreditoTypes";

export const AreaResumo = (form: UseFormReturn<AnaliseCreditoTypes, any>) => {
  let resumos = useWatch({
    control: form.control,
    name: "Resumos",
  });
  //let resumos = {} as any;
  const Resumos = useCallback(() => {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>Limite de Crédito Venda </Text>
                <Text>{resumos.limiteCreditoVenda.FormatarReal()}</Text>
              </Flex>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>Limite Utilizado Venda </Text>
                <Text>{resumos.limiteCreditoVenda.FormatarReal()}</Text>
              </Flex>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>Limite Disponível Venda </Text>
                <Text>
                  {resumos.limiteCreditoDisponivelVenda.FormatarReal()}
                </Text>
              </Flex>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>Limite de Crédito Compra </Text>
                <Text>{resumos.limiteCreditoCompra.FormatarReal()}</Text>
              </Flex>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>Limite Utilizado Compra </Text>
                <Text>{resumos.limiteCreditoCompra.FormatarReal()}</Text>
              </Flex>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>Limite Disponível Compra </Text>
                <Text>
                  {resumos.limiteCreditoDisponivelCompra.FormatarReal()}
                </Text>
              </Flex>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>CtsReceber a Vencer </Text>
                <Text>{resumos.ctsReceberAVencer.FormatarReal()}</Text>
              </Flex>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>Valor CtsReceber Vencidos </Text>
                <Text>{resumos.ctsReceberAtraso.FormatarReal()}</Text>
              </Flex>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>Qtd. CtsReceber A Vencer </Text>
                <Text>{resumos.qtdCtsReceberAVencer}</Text>
              </Flex>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>Qtd. CtsReceber Atrasados </Text>
                <Text>{resumos.qtdCtsReceberAtrasos}</Text>
              </Flex>
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBox>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>Cliente Desde </Text>
                <Text>
                  {resumos.clienteDesde &&
                    moment(resumos.clienteDesde).format("DD/MM/YYYY")}
                </Text>
              </Flex>
            </CGIBoxItem>

            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>Média Atraso </Text>
                <Text>{resumos.ctsReceberMediaAtraso.FormatarReal()}</Text>
              </Flex>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>Maior Atraso </Text>
                <Text>{resumos.ctsReceberMaiorAtraso.FormatarReal()}</Text>
              </Flex>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>Maior CtsReceber </Text>
                <Text>{resumos.maiorCtsReceber.FormatarReal()}</Text>
              </Flex>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>Último CtsReceber </Text>
                <Text>{resumos.ultimoCtsReceber.FormatarReal()}</Text>
              </Flex>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>Valor Médio de Vendas </Text>
                <Text>{resumos.valorMedioVendas.FormatarReal()}</Text>
              </Flex>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>Valor Total de Vendas </Text>
                <Text>{resumos.valorTotalVendas.FormatarReal()}</Text>
              </Flex>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>Vendas no Mês </Text>
                <Text>{resumos.valorVendasNoMes.FormatarReal()}</Text>
              </Flex>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>
                  Recebimentos feitos no prazo
                </Text>
                <Text>{resumos.totalRecebimentoNoPrazo.FormatarReal()}</Text>
              </Flex>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>
                  Recebimentos feitos em atraso+-
                </Text>
                <Text>{resumos.totalCtsReceberEmATraso.FormatarReal()}</Text>
              </Flex>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <Divider borderColor={"blackAlpha.500"} />
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"}>
                  Total de recebimentos em atraso
                </Text>
                <Text>{resumos.totalRecebimentoAtraso.FormatarReal()}</Text>
              </Flex>
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    );
  }, [resumos]);
  return <>{Resumos()}</>;
};
