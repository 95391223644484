import { SearchIcon } from "@chakra-ui/icons";
import {
  Flex,
  IconButton,
  Box,
  InputGroup,
  Input,
  InputRightElement,
  AvatarGroup,
  Tooltip,
  Avatar,
  useMediaQuery,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import { TamanhoTelaMobile, Temas } from "../../../Themes/Temas";
import { DadosUsuario } from "../../../Types/Home/Login/LoginType";
import { CGITrocarEmpresa } from "../../AlertDialog/CGITrocarEmpresa";
import { CGIButton } from "../../Button/CGIButton";
import { CGISinoNotificacao } from "../../SinoNotificacao/CGISinoNotificacao";
import { RetornarDadosUsuario } from "../../Util/Permissoes";
import { CGIDrawer } from "../Drawer/CGIDrawer";

export function CGIHeader() {
  const CGIDrawerRef = useRef() as any;
  const CGITrocarEmpresaRef = useRef() as any;
  const [isMobile] = useMediaQuery(TamanhoTelaMobile);

  const [DadosEmpresa] = useState<DadosUsuario>(RetornarDadosUsuario());

  return (
    <Box w={"100%"}>
      <Flex bgColor={Temas.Primaria}>
        <Flex flex={1}>
          <IconButton
            aria-label="Call Segun"
            icon={<FiMenu />}
            fontSize="30px"
            colorScheme="white"
            onClick={() => CGIDrawerRef.current.Abrir()}
          />
        </Flex>
        <Flex gap={8} justifyContent="end" grow={"initial"} flex={1} p="1">
          <CGIButton
            label={DadosEmpresa.nomeEmpresa}
            att={{ colorScheme: "green" }}
            onClick={() => {
              CGITrocarEmpresaRef.current.Abrir();
            }}
          />
          {!isMobile && (
            <>
              <Box>
                <InputGroup>
                  <Input
                    size={"sm"}
                    bgColor={Temas.Branco}
                    placeholder="Pesquisar..."
                  />
                  <InputRightElement
                    children={
                      <SearchIcon pointerEvents={"all"} color="gray.300" />
                    }
                  />
                </InputGroup>
              </Box>
              <Box>
                <AvatarGroup gap={4} px="4">
                  <CGISinoNotificacao />
                  <Tooltip label="Perfil do Usuário">
                    <Link to="/Sistema/Usuario">
                      <Avatar size={"sm"} />
                    </Link>
                  </Tooltip>
                </AvatarGroup>
              </Box>
            </>
          )}
        </Flex>
      </Flex>
      <CGIDrawer ref={CGIDrawerRef} />
      <CGITrocarEmpresa ref={CGITrocarEmpresaRef} />
    </Box>
  );
}
