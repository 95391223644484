import { CGIHttp } from "../../../Services/Axios";
import {
  AlterarPermissoesTelaTypes,
  AlterarPermissoesTelaUSuariosTypes,
  ListarPermissaoItensTypes,
  ListarPermissaoPesquisaTypes,
} from "../../../Types/Sistema/Permissao/PermissaoTypes";
import { ListarProdutoTypes } from "../../../Types/Sistema/Produto/ProdutoTypes";

const url = "/permissao";

export function HttpPermissao() {
  async function RetornarPermissoesTela(link: string) {
    var data = await new CGIHttp().conn.post(
      url + "/visualizar-permissao-tela",
      { link: link }
    );
    return data.data.data as AlterarPermissoesTelaTypes;
  }
  async function RetornarPermissoesTelaUsuarios(
    link: string,
    empresaId: number
  ) {
    var data = await new CGIHttp().conn.post(
      url + "/obter-usuarios-permissao-tela",
      { link: link, empresaId: empresaId }
    );

    return data.data.data as Array<AlterarPermissoesTelaUSuariosTypes>;
  }
  async function AlterarPermissoesTela(Dados: AlterarPermissoesTelaTypes) {
    var data = await new CGIHttp().conn.post(url + "/permissao-tela", Dados);
    return data.data.data as Array<AlterarPermissoesTelaUSuariosTypes>;
  }
  async function RetornarPermissoes(
    Dados: ListarPermissaoPesquisaTypes
  ): Promise<ListarPermissaoItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-permissoes", Dados)
      .then(res => {
        return res.data.data;
      });
  }
  return {
    RetornarPermissoesTela,
    RetornarPermissoesTelaUsuarios,
    AlterarPermissoesTela,
    RetornarPermissoes,
  };
}
