export type FormEquipamentoTypes = {
  Form: {
    DadosRegistro: DadosRegistroTypes;
  };
};

export type DadosRegistroTypes = {
  id: number;
  identificacao: number;
  frota: string;
  tipoEquipamentoId: number | null;
  ativo: boolean;
  fornecedorId: number | null;
  motoristaId: number | null;
  modelo: string;
  empresaId: number | null;
  operadorId: number | null;
  rNTRC: number | null;
  tara: number | null;
  uF: string;
  capacidadeKG: string;
  capacidadeM3: string;
  tipoKM: string;
  valorAquisicao: number;
  valorAtual: number;
  dataAquisicao: string | null;
  tipoUnidadeTranspMDFe: number | null;
  chassi: string;
  renavan: string;
  dataValidadeTacografo: string | null;
  transportadoraId: number | null;
  dataVencimentoIPVA: string | null;
  centroDeCustoId: number | null;
  locadorId: number | null;
  terceiro: boolean;
  locado: boolean;
  apoliceSeguro: string;
  numeroSeguro: string;
  informacoesSeguro: string;
  gerarQRCode: boolean;
  obs: string;
  equipamentoVinculadoId: number | null;
};

export type EquipamentoVisualizarTypes = {
  id: number;
  ativo: boolean;
  identificacao: string;
  frota: string;
  tipoEquipamento: string;
  modelo: string;
  nomeEmpresa: string;
  nomeOperador: string;
  nomeTransportadora: string;
  capacidadeKG: string;
  capacidadeM3: string;
  tara: string;
  tipoKM: string;
  valorAquisicao: number;
  valorAtual: number;
  dataAquisicao: string | null;
  chassi: string;
  renavan: string;
  dataValidadeTacografo: string | null;
  nomeTransporte: string;
  dataVencimentoIPVA: string | null;
  nomeCentroDeCusto: string;
  terceiro: boolean;
  apoliceSeguro: string;
  numeroSeguro: string;
  informacoesSeguro: string;
  obs: string;
};

export const ObjListarEquipamentoPadrao: ListarEquipamentoTypes = {
  Pesquisa: {
    dataInicialVencimentoIPVA: "",
    dataFinalVencimentoIPVA: "",
    identificacao: "",
    frota: "",
    ativoId: 0,
    modelo: "",
    ListaEmpresasId: "",
    locado: 0,
    terceiro: 0,
    ListaTiposEquipamentoId: "",
    ListaLocadoresId: "",
    apoliceSeguro: "",
    obs: "",
    ListaCentrosDeCustoId: "",
    somenteEquipamentosCadastroIncompleto: false,
  },
  Itens: [],
};

export type ListarEquipamentoTypes = {
  Pesquisa: ListarPesquisaEquipamentoTypes;
  Itens: ListarEquipamentoItensTypes[];
};

export type ListarPesquisaEquipamentoTypes = {
  dataInicialVencimentoIPVA: string;
  dataFinalVencimentoIPVA: string;
  identificacao: string;
  frota: string;
  ativoId: number;
  modelo: string;
  ListaEmpresasId: string;
  locado: number;
  terceiro: number;
  ListaTiposEquipamentoId: string;
  ListaLocadoresId: string;
  apoliceSeguro: string;
  obs: string;
  ListaCentrosDeCustoId: string;
  somenteEquipamentosCadastroIncompleto: boolean;
};

export type ListarEquipamentoItensTypes = {
  id: number;
  ativo: boolean;
  identificacao: string;
  frota: string;
  modelo: string;
  terceiro: boolean;
  nomeTipoEquipamento: string;
  nomeOperador: string;
  nomeEmpresa: string;
};
