import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

export function TalkTo() {
  return (
    <TawkMessengerReact
      propertyId="587e493aedbcab0476870503"
      widgetId="default"
    />
  );
}
