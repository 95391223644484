export const ObjTipoProduto: FormTipoProdutoType = {
  Form: {
    DadosRegistro: {
      id: 0,
      nome: "",
      ativo: false,
      grupo: "",
      codigoExterno: "",
      descricaoAuxiliar: "",
      valor: 0,
      CFOPDentroEstado: 0,
      CFOPForaEstado: 0,
    },
  },
};

export type FormTipoProdutoType = {
  Form: {
    DadosRegistro: TipoProdutoTypes;
  };
};

type TipoProdutoTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  grupo: string;
  codigoExterno: string;
  descricaoAuxiliar: string;
  valor: number;
  CFOPForaEstado: number;
  CFOPDentroEstado: number;
};

export const ObjListarTipoProdutoPadrao: ListarTipoProdutoTypes = {
  Pesquisa: {
    titulo: "",
    nome: "",
    ativoId: 0,
  },
  Itens: [],
};

export type ListarTipoProdutoTypes = {
  Pesquisa: ListarPesquisaTipoProdutoTypes;
  Itens: ListarTipoProdutoItensTypes[];
};

export type ListarPesquisaTipoProdutoTypes = {
  titulo: string;
  nome: string;
  ativoId: number;
};

export type ListarTipoProdutoItensTypes = {
  id: number;
  nome: string;
  ativo: boolean;
};
