import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  Input,
} from "@chakra-ui/react";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { FormContext } from "../../Context/FormConxtext";
import {
  GuardarDadosUsuario,
  RetornarDadosUsuario,
} from "../../Services/Util/Util";
import { GraficosDadosUsuarioTypes } from "../../Types/Home/Login/LoginType";
import { ModalBaseType } from "../../Types/Modal/ModalBaseType";
import { CGIAlertDialog } from "../AlertDialog/CGIAlertDialog";
import { CGIBoxItem } from "../Box/CGIBoxItens";

export const CGIAlterarGraficos = forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    Abrir(pDados: GraficosDadosUsuarioTypes[]) {
      //setGraficos(pDados.sort((a, b) => a.sequencia - b.sequencia));
      setGraficos(pDados);
      CGIAlertDialogRef.current?.Abrir();
    },
    Fechar() {
      CGIAlertDialogRef.current?.Fechar();
    },
  }));
  const [Graficos, setGraficos] = useState<Array<GraficosDadosUsuarioTypes>>(
    []
  );
  const CGIAlertDialogRef = useRef<ModalBaseType>(null);
  const AlterarGraficos = (
    texto: string,
    index: number,
    Tipo: "sequencia" | "coluna"
  ) => {
    if (parseInt(texto) > 12 && Tipo === "coluna") {
      texto = "12";
    }
    if (Tipo === "sequencia" && parseInt(texto) > Graficos.length) {
      texto = Graficos.length.toString();
    }
    setGraficos(state => {
      state[index][Tipo] = parseInt(texto);
      return [...state];
    });
  };

  useHotkeys(
    "enter",
    e => {
      e.preventDefault();
      Confirmar();
    },
    { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] }
  );
  const Confirmar = () => {
    var DadosUsuario = RetornarDadosUsuario();
    Graficos.map((e, index) => {
      if (e.coluna === 0) {
        e.coluna = 1;
      }
      if (e.sequencia === 0) {
        e.sequencia = 1;
      }
      setGraficos(state => {
        state[index]["coluna"] = e.coluna;
        return [...state];
      });
      setGraficos(state => {
        state[index]["sequencia"] = e.sequencia;
        return [...state];
      });
    });

    DadosUsuario.graficos = Graficos;
    GuardarDadosUsuario(DadosUsuario);
    props.AttGraficos();
    CGIAlertDialogRef.current?.Fechar();
  };
  return (
    <FormContext>
      <CGIAlertDialog
        size={"4xl"}
        ref={CGIAlertDialogRef}
        titulo={`Alterar Layout de Gráficos`}
        fechar={() => {
          setGraficos([]);
        }}
        confirmar={Confirmar}
      >
        <CGIBoxItem Col={12}>
          <TableContainer>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>TIPO</Th>
                  <Th>Grafico</Th>
                  <Th>Sequência</Th>
                  <Th>Colunas</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Graficos.map((e, index) => {
                  return (
                    <Tr>
                      <Td>{e.tipo}</Td>
                      <Td>{e.titulo}</Td>
                      <Td>
                        <Input
                          type={"number"}
                          value={e.sequencia}
                          onChange={event =>
                            AlterarGraficos(
                              event.target.value,
                              index,
                              "sequencia"
                            )
                          }
                        />
                      </Td>
                      <Td>
                        <Input
                          type={"number"}
                          value={e.coluna}
                          onChange={event =>
                            AlterarGraficos(event.target.value, index, "coluna")
                          }
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </CGIBoxItem>
      </CGIAlertDialog>
    </FormContext>
  );
});
