import { AddIcon, IconProps } from "@chakra-ui/icons";
import {
  Box,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {
  RetornarPemissaoPeloLink,
  RetornarSeUsuarioTemPemissaoPeloLink,
} from "../Util/Permissoes";
import React from "react";
import { Temas } from "../../Themes/Temas";

export interface CGIMenuButtonTypes {
  Icone?: JSX.Element;
  label?: string;
  Acoes: Array<MenuTypes>;
}

export interface MenuTypes {
  Nome: string;
  OnClick: Function;
  Permissao: string;
  SubMenus?: Array<MenuTypes>;
}

export function CGIMenuButton(props: CGIMenuButtonTypes) {
  function RenderItens() {
    return props.Acoes.map((e, index) => {
      var Desabilitado = false;
      var NaoEncontrado = false;
      var TemPermissao = RetornarSeUsuarioTemPemissaoPeloLink(e.Permissao);
      var ExistePermissao = RetornarPemissaoPeloLink(e.Permissao);

      if (!ExistePermissao) {
        NaoEncontrado = true;
      }
      if (!TemPermissao) {
        Desabilitado = true;
      }

      return (
        <Box key={index}>
          <MenuItem isDisabled={Desabilitado} onClick={() => e.OnClick()}>
            <Text color={NaoEncontrado ? "red" : ""} fontWeight={"semibold"}>
              {e.Nome}
            </Text>
          </MenuItem>
          <MenuDivider />
        </Box>
      );
    });
  }

  return (
    <Menu>
      <MenuButton
        px={4}
        py={2}
        borderRadius="md"
        borderWidth="1px"
        _hover={{ bg: Temas.Segundaria, color: Temas.Branco }}
        _expanded={{ bg: Temas.Primaria, color: Temas.Branco }}
      >
        {props.Icone || <AddIcon />}
      </MenuButton>
      <MenuList>{RenderItens()}</MenuList>
    </Menu>
  );
}
