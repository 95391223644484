import React, { useEffect } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { Stimulsoft } from "stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor";
import "stimulsoft-reports-js/Css/stimulsoft.designer.office2013.whiteblue.css";
import "stimulsoft-reports-js/Css/stimulsoft.viewer.office2013.whiteblue.css";

export function VisualizarRelatorioRegistro() {
  let { id, idregistro } = useParams();
  const viewer = new Stimulsoft.Viewer.StiViewer(undefined, "StiViewer", false);
  const report = new Stimulsoft.Report.StiReport();
  useEffect(() => {
    const Render = async () => {
      report.loadFile("/Reports/NovoMRT.mrt");
      var dataSet = new Stimulsoft.System.Data.DataSet("Dados");
      dataSet.readJson({
        Dados: {
          id: id,
          idregistro: idregistro,
        },
      });
      report.regData("Dados", "Dados", dataSet);
      viewer.report = report;

      viewer.renderHtml("viewer");
    };
    Render();
  }, []);

  return <div id="viewer" />;
}
