import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback } from "react";
import { useForm, useWatch, FormProvider } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import {
  LegendaTypes,
  ModalBaseType,
} from "../../../Types/Modal/ModalBaseType";
import {
  ListarConfigComissaoTypes,
  ObjListarConfigComissaoPadrao,
  ListarConfigComissaoGridItensTypes,
} from "../../../Types/Sistema/ConfigComissao/ConfigComissaoTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpTipoNFEntrada } from "../Classificacao/TipoNFEntrada/Http";
import { HttpConfigComissao } from "./Http";
import { AlterarConfigComissao } from "./_AlterarConfigComissao";
import { VisualizarConfigComissao } from "./_VisualizarConfigComissao";

export function ListarConfigComissao() {
  const AlterarConfigComissaoRef = useRef<ModalBaseType>();
  const VisualizarTipoNFEntradaRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarConfigComissaoTypes>({
    defaultValues: ObjListarConfigComissaoPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarConfigComissoes = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpConfigComissao().RetornarConfigComissoes(
        DadosFiltros
      );
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarConfigComissaoGridItensTypes) => {
      return await HttpConfigComissao().RetornarDadosExclusao(data.id);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpTipoNFEntrada()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo Config Comissão",
      Permissao: "sistema/configcfop/incluir",
      OnClick: () => {
        AlterarConfigComissaoRef.current?.Abrir();
      },
    },
  ];
  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "sistema/configcomissao/visualizar",
      OnClick: (e: ListarConfigComissaoGridItensTypes) => {
        VisualizarTipoNFEntradaRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Alterar",
      Permissao: "sistema/configcomissao/alterar",
      OnClick: (e: ListarConfigComissaoGridItensTypes) => {
        AlterarConfigComissaoRef.current?.Alterar(e.id);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/configcomissao/excluir",
      OnClick: (e: ListarConfigComissaoGridItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];
  const Legendas: LegendaTypes = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarConfigComissaoGridItensTypes>> = [
    {
      Campo: "ativo",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: [
        {
          nomeStatus: "true",
          cor: "green",
        },
        {
          nomeStatus: "false",
          cor: "red",
        },
      ],
    },
    {
      Campo: "dataCadastro",
      Titulo: "Cadastro",
      Tipo: "Data",
      att: {
        filter: false,
        maxWidth: 150,
      },
    },
    {
      Campo: "nome",
      Titulo: "Nome",
      Tipo: "Texto",
      att: {
        wrapText: true,
      },
    },
    {
      Campo: "percComissao",
      Titulo: "Comissão %",
      Tipo: "Moeda",
      att: {
        maxWidth: 150,
      },
    },
    {
      Campo: "valorIndividual",
      Titulo: "Vlr. Individual",
      Tipo: "Moeda",
      att: {
        maxWidth: 150,
      },
    },
    {
      Campo: "nomeEmpresa",
      Titulo: "Empresa",
      Tipo: "Texto",
      att: {
        maxWidth: 150,
        wrapText: true,
      },
    },
    {
      Campo: "nomeVendedor",
      Titulo: "Vendedor",
      Tipo: "Texto",
      att: {
        maxWidth: 150,
        wrapText: true,
      },
    },
    {
      Campo: "nomeCliente",
      Titulo: "Cliente",
      Tipo: "Texto",
      att: {
        maxWidth: 150,
        wrapText: true,
      },
    },
    {
      Campo: "nomeProduto",
      Titulo: "Produto",
      Tipo: "Texto",
      att: {
        maxWidth: 150,
        wrapText: true,
      },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarConfigComissaoGridItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];

  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir ConfigComissao"
          CarregarDados={RetornarConfigComissoes}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <VisualizarConfigComissao
          CarregarDados={RetornarConfigComissoes}
          ref={VisualizarTipoNFEntradaRef}
        />
      ),
    },
    {
      Componente: (
        <AlterarConfigComissao
          CarregarDados={methods.handleSubmit(RetornarConfigComissoes)}
          ref={AlterarConfigComissaoRef}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarConfigComissaoTypes>
            label="Nome"
            id="Pesquisa.nome"
            placeholder="Nome"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigComissaoTypes>
            label="Empresa"
            id="Pesquisa.listaEmpresasId"
            Multipla
            Link="empresa"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigComissaoTypes>
            label="Vendedor"
            id="Pesquisa.listaVendedoresId"
            Multipla
            Link="vendedor"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigComissaoTypes>
            label="Ativo"
            id="Pesquisa.ativoId"
            Link="simNaoAmbos"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboAjax<ListarConfigComissaoTypes>
            label="Cliente"
            id="Pesquisa.listaClientesId"
            Multipla
            Link="cliente"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboAjax<ListarConfigComissaoTypes>
            label="Produto"
            id="Pesquisa.listaProdutosId"
            Multipla
            Link="produto"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigComissaoTypes>
            label="Grupo"
            id="Pesquisa.listaGruposId"
            Multipla
            Link="grupo"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigComissaoTypes>
            label="SubGrupo"
            id="Pesquisa.listaSubGruposId"
            Multipla
            Link="subgrupo"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigComissaoTypes>
            label="Classe"
            id="Pesquisa.listaClassesId"
            Multipla
            Link="classe"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigComissaoTypes>
            label="Cidade"
            id="Pesquisa.listaCidadesId"
            Multipla
            Link="cidade"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigComissaoTypes>
            label="Praca"
            id="Pesquisa.listaPracasId"
            Multipla
            Link="praca"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigComissaoTypes>
            label="Rota"
            id="Pesquisa.listaRotasId"
            Multipla
            Link="rota"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigComissaoTypes>
            label="Tipo Cliente"
            id="Pesquisa.listaTipoClientesId"
            Multipla
            Link="tipoCliente"
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="sistema/configcfop/listar"
      Titulo="Config Comissão"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarConfigComissoes)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
