import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../../Components/CheckBox/CGICheckBox";
import { useCGIToast } from "../../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIModal } from "../../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../../Context/LoadingContext";
import { FormMarcaType } from "../../../../Types/Sistema/Classificacao/Marca/MarcaTypes";
import { HttpMarca } from "./Http";

type AlterarMarcaType = {
  CarregarDados: Function;
};

export const AlterarMarca = forwardRef((props: AlterarMarcaType, ref) => {
  //INICIO REF
  useImperativeHandle(ref, () => ({
    Abrir() {
      CGIModalRef.current.Abrir();
    },
    Alterar(pEmpresa: any) {
      CGIModalRef.current.Abrir();

      HttpMarca()
        .RetornarDadosMarca(pEmpresa.id)
        .then((e) => {
          form.reset(e.data.data);
        });
    },
  }));
  //FINAL  REF

  //INICIO  HOOKS
  const CGIModalRef = useRef() as any;
  const form = useFormContext<FormMarcaType>();
  const { ToastErro, ToastSucesso } = useCGIToast();
  const { setLoadingState } = useContext(LoadingContext);

  //FINAL  HOOKS

  //INICIO CONST

  const RenderTabs: Array<CGITabItensTypes> = [
    {
      Titulo: "Marcas",
      Component: AreaDadosMarca(),
    },
  ];
  //FINAL  CONST

  //INICO FUNC
  function AreaDadosMarca() {
    return (
      <CGIBox>
        <CGIBoxItem Col={3}>
          <CGIInput<FormMarcaType>
            id="Form.DadosRegistro.nome"
            label="Marca"
            placeholder=""
            validadores={{
              required: "Nome obrigatório",
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormMarcaType>
            id="Form.DadosRegistro.margemLucroECommerce"
            label="Valor"
            placeholder=""
            validadores={{
              required: "Nome obrigatório",
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGICheckBox<FormMarcaType>
            id="Form.DadosRegistro.ativo"
            label="Ativo"
            tooltip=""
            validadores={{
              required: "Nome obrigatório",
            }}
            att={{
              style: { marginTop: 30 },
            }}
            attLabel={{
              style: { marginTop: 30 },
            }}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  const Confirmar = async (data: FormMarcaType, event: any) => {
    setLoadingState(true);
    let Dados = form.getValues();

    if (
      Dados.Form.DadosRegistro.id == null ||
      Dados.Form.DadosRegistro.id === undefined ||
      Dados.Form.DadosRegistro.id === 0
    ) {
      HttpMarca()
        .Incluir(Dados)
        .then((response: any) => {
          ToastSucesso("Registro gravado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
        })
        .catch((e) => {
          return ToastErro(e);
        })
        .finally(() => {
          setLoadingState(false);
        });
    } else {
      HttpMarca()
        .Alterar(data)
        .then((data: any) => {
          ToastSucesso("Registro Alterado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
        })
        .catch((e) => {
          return;
        })
        .finally(() => {
          setLoadingState(false);
        });
    }
  };
  //FINAL FUNC

  //INICO JSX
  return (
    <CGIModal
      id="sistema/classificacao/alterarmarca"
      ref={CGIModalRef}
      Titulo="Marca"
      TelaCheia={"6xl"}
      Confirmar={Confirmar}
      Fechar={() => {
        form.reset({});
      }}
    >
      <CGITabs Tabs={RenderTabs} />
    </CGIModal>
  );
  //FINAL JSX
});
