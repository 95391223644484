import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarConfigCFOPGridItensTypes,
  ListarConfigCFOPTypes,
  ObjListarConfigCFOPPadrao,
} from "../../../Types/Sistema/ConfigCFOP/ConfigCFOPTypes";
import {
  ModalBaseType,
  LegendaTypes,
} from "../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpConfigCFOP } from "./Http";
import { HttpTipoNFEntrada } from "../../Sistema/Classificacao/TipoNFEntrada/Http";
import { AlterarConfigCFOP } from "./_AlterarConfigCFOP";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";

export function ListarConfigCFOP() {
  const VisualizarConfigCFOPRef = useRef<ModalBaseType>();
  const AlterarConfigCFOPRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarConfigCFOPTypes>({
    defaultValues: ObjListarConfigCFOPPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarConfigCFOPs = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpConfigCFOP().RetornarConfigCFOP(DadosFiltros);
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarConfigCFOPGridItensTypes) => {
      return await HttpConfigCFOP().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpTipoNFEntrada()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo ConfigCFOP",
      Permissao: "sistema/configcfop/incluir",
      OnClick: () => {
        AlterarConfigCFOPRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "sistema/configcfop/visualizar",
      OnClick: (e: ListarConfigCFOPGridItensTypes) => {
        VisualizarConfigCFOPRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Alterar",
      Permissao: "sistema/configcfop/alterar",
      OnClick: (e: ListarConfigCFOPGridItensTypes) => {
        AlterarConfigCFOPRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/configcfop/excluir",
      OnClick: (e: ListarConfigCFOPGridItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas: LegendaTypes = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarConfigCFOPGridItensTypes>> = [
    {
      Campo: "ativo",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: [
        {
          nomeStatus: "true",
          cor: "green",
        },
        {
          nomeStatus: "false",
          cor: "red",
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      ocultar: true,
      att: {
        maxWidth: 80,
      },
    },
    {
      Campo: "id",
      Titulo: "Nome",
      Tipo: "Composta",
      Composta: [
        {
          titulo: "",
          valor: "nome",
        },
        {
          titulo: "",
          valor: "nomeEmpresa",
          negrito: true,
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "CFOP",
      Tipo: "Composta",
      Composta: [
        {
          titulo: "CFOP",
          valor: "cfopId",
          exibirLabel: true,
        },
        {
          titulo: "CST",
          valor: "cstId",
          negrito: true,
          exibirLabel: true,
        },
        {
          titulo: "CFOP Dev",
          valor: "cfopDevolucaoId",
          negrito: true,
          exibirLabel: true,
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "Flags",
      Tipo: "Composta",
      Composta: [
        {
          titulo: "Calcular ICMS",
          valor: "calcularICMS",
          exibirLabel: true,
          FuncCondicional: (e) => (e === true ? "Sim" : "Não"),
          CorCondicional: (e) => (e === true ? "blue" : "red"),
        },
        {
          titulo: "Calcular ICMS ST",
          valor: "calcularICMSST",
          negrito: true,
          exibirLabel: true,
          FuncCondicional: (e) => (e === true ? "Sim" : "Não"),
          CorCondicional: (e) => (e === true ? "blue" : "red"),
        },
        {
          titulo: "Calcular FCP",
          valor: "calcularFCP",
          negrito: true,
          exibirLabel: true,
          FuncCondicional: (e) => (e === true ? "Sim" : "Não"),
          CorCondicional: (e) => (e === true ? "blue" : "red"),
        },
        {
          titulo: "Calcular FCP ST",
          valor: "calcularFCPST",
          negrito: true,
          exibirLabel: true,
          FuncCondicional: (e) => (e === true ? "Sim" : "Não"),
          CorCondicional: (e) => (e === true ? "blue" : "red"),
        },
        {
          titulo: "Calcular FCP ST Ret",
          valor: "calcularFCPST",
          negrito: true,
          exibirLabel: true,
          FuncCondicional: (e) => (e === true ? "Sim" : "Não"),
          CorCondicional: (e) => (e === true ? "blue" : "red"),
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "Flags",
      Tipo: "Composta",
      Composta: [
        {
          titulo: "Calcular IPI",
          valor: "calcularIPI",
          negrito: true,
          exibirLabel: true,
          FuncCondicional: (e) => (e === true ? "Sim" : "Não"),
          CorCondicional: (e) => (e === true ? "blue" : "red"),
        },
        {
          titulo: "Calcular PIS",
          valor: "calcularPIS",
          negrito: true,
          exibirLabel: true,
          FuncCondicional: (e) => (e === true ? "Sim" : "Não"),
          CorCondicional: (e) => (e === true ? "blue" : "red"),
        },
        {
          titulo: "Calcular COFINS",
          valor: "calcularCOFINS",
          negrito: true,
          exibirLabel: true,
          FuncCondicional: (e) => (e === true ? "Sim" : "Não"),
          CorCondicional: (e) => (e === true ? "blue" : "red"),
        },
        {
          titulo: "Alterar Perc. ICMS",
          valor: "alterarPercICMS",
          negrito: true,
          exibirLabel: true,
          FuncCondicional: (e) => (e === true ? "Sim" : "Não"),
          CorCondicional: (e) => (e === true ? "blue" : "red"),
        },
        {
          titulo: "Remover IPI Base ICMS",
          valor: "removerIPIBaseCalculoICMS",
          negrito: true,
          exibirLabel: true,
          FuncCondicional: (e) => (e === true ? "Sim" : "Não"),
          CorCondicional: (e) => (e === true ? "blue" : "red"),
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "Impostos",
      Tipo: "Composta",
      Composta: [
        {
          valor: "percICMS",
          titulo: "% ICMS",
          negrito: true,
          exibirLabel: true,
        },
        {
          valor: "percDiferimentoICMS",
          titulo: "% Dif. ICMS",
          negrito: true,
          exibirLabel: true,
        },
        {
          valor: "percReducaoICMS",
          titulo: "% Redução ICMS",
          negrito: true,
          exibirLabel: true,
        },
        {
          valor: "percDiferimentoFCP",
          titulo: "% Dif. FCP",
          negrito: true,
          exibirLabel: true,
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarConfigCFOPGridItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir ConfigCFOP"
          CarregarDados={RetornarConfigCFOPs}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarConfigCFOP
          CarregarDados={methods.handleSubmit(RetornarConfigCFOPs)}
          ref={AlterarConfigCFOPRef}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigCFOPTypes>
            label="Empresa"
            id="Pesquisa.listaEmpresasId"
            Multipla
            Link="empresa"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigCFOPTypes>
            label="CST"
            id="Pesquisa.listaCSTsId"
            Multipla
            Link="cst-csosn"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigCFOPTypes>
            label="CFOP"
            id="Pesquisa.listaCFOPsId"
            Multipla
            Link="cfop-todos"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigCFOPTypes>
            label="Ativo"
            id="Pesquisa.ativoId"
            Link="simnaoambos"
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="sistema/configcfop/listar"
      Titulo="ConfigCFOP"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarConfigCFOPs)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
