import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarPedidosParaProducaoItensTypes,
  ListarPedidosParaProducaoTypes,
  ObjListarPedidosParaProducaoPadrao,
} from "../../../Types/Vendas/Pedido/PedidosParaProducaoTypes";
import {
  ModalBaseType,
  LegendaTypes,
} from "../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpPedido } from "./Http";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIVisualizarAnexo } from "../../../Components/Anexo/CGIVisualizarAnexo";
import { CGIHistorico } from "../../../Components/Historico/CGIHistorico";
import moment from "moment";

export function ListarPedidosParaProducao() {
  const AlterarPedidosParaProducaoRef = useRef<ModalBaseType>();
  const CGIHistoricoRef = useRef<ModalBaseType>();
  const CGIAnexoRef = useRef<ModalBaseType>();
  const VisualizarPedidosParaProducaoRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarPedidosParaProducaoTypes>({
    defaultValues: ObjListarPedidosParaProducaoPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarPedidosParaProducao = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpPedido().RetornarPedidosParaProducao(DadosFiltros);
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const ListarMenu: Array<MenuTypes> = [];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "sistema/PedidosParaProducao/visualizar",
      OnClick: (e: ListarPedidosParaProducaoItensTypes) => {
        VisualizarPedidosParaProducaoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Lançar Vistos",
      Permissao: "sistema/PedidosParaProducao/alterar",
      OnClick: (e: ListarPedidosParaProducaoItensTypes) => {
        AlterarPedidosParaProducaoRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Enviar pedido para Alocação - Expedição",
      Permissao: "sistema/PedidosParaProducao/alterar",
      OnClick: (e: ListarPedidosParaProducaoItensTypes) => {
        AlterarPedidosParaProducaoRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Anexos",
      Permissao: "sistema/anexo/visualizaranexospedido",
      OnClick: (e: ListarPedidosParaProducaoItensTypes) => {
        CGIAnexoRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Histórico",
      Permissao: "vendas/pedido/visualizarhistorico",
      OnClick: (e: ListarPedidosParaProducaoItensTypes) => {
        CGIHistoricoRef.current?.Alterar(e);
      },
    },
  ];

  const Legendas: LegendaTypes = [
    { nomeStatus: "Aberto", cor: "green" },
    { nomeStatus: "Separado", cor: "lightblue" },
    { nomeStatus: "Conferido", cor: "wheat" },
    { nomeStatus: "Excluído", cor: "red" },
    { nomeStatus: "Faturado", cor: "blue" },
    { nomeStatus: "Entregue", cor: "cadetblue" },
    { nomeStatus: "Romaneio", cor: "gray" },
    { nomeStatus: "Devolução Romaneio", cor: "lightgray" },
  ];

  const GridColunas: Array<CGICol<ListarPedidosParaProducaoItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "identificacao",
      Titulo: "Pedido",
      Tipo: "Texto",
      att: { maxWidth: 150 },
    },
    {
      Campo: "id",
      Titulo: "Dt. Ped.  Prev. Fat",
      Tipo: "Composta",
      att: { maxWidth: 150 },
      Composta: [
        {
          titulo: "",
          valor: "dataCadastro",
          FuncCondicional(e) {
            return moment(e).format("DD/MM/yyyy");
          },
        },
        {
          titulo: "",
          valor: "dataPrevisaoEntrega",
          FuncCondicional(e) {
            if (e === null) return "";
            else return moment(e).format("DD/MM/yyyy");
          },
        },
      ],
    },
    {
      Campo: "nomeCliente",
      Titulo: "Cliente",
      Tipo: "Texto",
    },
    {
      Campo: "nomeVendedor",
      Titulo: "Vendedor",
      Tipo: "Texto",
    },
    {
      Campo: "qtdItens",
      Titulo: "Qtd.",
      Tipo: "Numero",
      att: { maxWidth: 100 },
    },
    {
      Campo: "totalPedido",
      Titulo: "Total Pedido",
      Tipo: "Moeda",
      att: { maxWidth: 130 },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarPedidosParaProducaoItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          titulo: "Pedido",
          valor: "identificacao",
        },
        {
          titulo: "Dt. Ped",
          valor: "dataCadastro",
        },
        {
          titulo: "Cliente",
          valor: "nomeCliente",
        },
        {
          titulo: "Vendedor",
          valor: "nomeVendedor",
        },
        {
          titulo: "Qtd.",
          valor: "qtdItens",
        },
        {
          titulo: "Total Pedido",
          valor: "totalPedido",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIVisualizarAnexo
          ref={CGIAnexoRef}
          link={"sistema/anexo/visualizaranexosnfsaida"}
          origem={"NFSAIDA"}
          titulo={"Anexos NF. Saída"}
        />
      ),
    },
    {
      Componente: (
        <CGIHistorico
          tipo="TimeLine"
          titulo="Histórico de NFSaída"
          ref={CGIHistoricoRef}
          link={"faturamento/nfsaida/historiconfsaida"}
          origem={"nfsaida"}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Filtros de Período">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarPedidosParaProducaoTypes>
                id="Pesquisa.dataInicial"
                label="Data Inclusão - Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarPedidosParaProducaoTypes>
                id="Pesquisa.dataFinal"
                label="Data Inclusão - Final"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBox>
            <CGIBoxItem Col={12}>
              <CGIComboSimples<ListarPedidosParaProducaoTypes>
                id="Pesquisa.listaClientesId"
                label="Cliente"
                Link={"cliente"}
                Multipla
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="vendas/pedido/listarpedidosparaproducao"
      Titulo="Pedidos Para Producao"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarPedidosParaProducao)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
