import React, { createContext, useEffect, useState } from "react";

import { DadosUsuario } from "../Types/Home/Login/LoginType";

type DadosUsuarioTypes = {
  DadosUsuario: DadosUsuario;
  setDadosUsuario: React.Dispatch<React.SetStateAction<DadosUsuario>>;
};

var Obj = {} as DadosUsuario;

const DefaultValue: DadosUsuarioTypes = {
  setDadosUsuario: () => {},
  DadosUsuario: Obj,
};

export const DadosUsuarioContext =
  createContext<DadosUsuarioTypes>(DefaultValue);

function DadosUsuarioProvider(props: { children: React.ReactNode }) {
  const [DadosUsuario, setDadosUsuario] = useState(DefaultValue.DadosUsuario);
  function PegarDados() {
    var Dados = localStorage.getItem("dadosUsuario");
    if (DadosUsuario) {
      if (Dados != null) setDadosUsuario(JSON.parse(Dados));
    } else {
      if (Dados != null) setDadosUsuario(JSON.parse(Dados));
    }
  }

  useEffect(() => {
    PegarDados();
  }, []);

  return (
    <DadosUsuarioContext.Provider value={{ DadosUsuario, setDadosUsuario }}>
      {props.children}
    </DadosUsuarioContext.Provider>
  );
}

export { DadosUsuarioProvider };
export default DadosUsuarioContext;
