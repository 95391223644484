import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tooltip,
  Kbd,
  Badge,
  Center,
} from "@chakra-ui/react";
import { CellComp } from "ag-grid-community";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Temas } from "../../Themes/Temas";
import "./CGITab.css";
type CGITabTypes = {
  Tabs: Array<CGITabItensTypes>;
};
export type CGITabItensTypes = {
  Titulo: string;
  Component: React.ReactNode;
  Tooltip?: string;
  ocultar?: boolean;
};

/**
 * @Componente CGITabs - Componente responsavel  pelas CGITabs
 * @Ref  CGITabs
 */
export const CGITabs = forwardRef((props: CGITabTypes, ref) => {
  const [tabIndex, setTabIndex] = useState(0);
  useImperativeHandle(ref, () => ({
    TabIndex(index: number) {
      setTabIndex(index);
    },
  }));
  return (
    <Tabs
      onChange={e => setTabIndex(e)}
      index={tabIndex}
      size="md"
      variant="enclosed"
    >
      <TabList
        id="tabList"
        wordBreak={"keep-all"}
        flexDir={{ base: "column", md: "row", sm: "row" }}
      >
        {props.Tabs.map((e, index) => (
          <Tooltip
            placement="top"
            isDisabled={e.Tooltip ? false : true}
            key={index}
            hasArrow
            label={
              <Center>
                <Badge>{e.Tooltip}</Badge>
              </Center>
            }
            bgColor={Temas.Primaria}
          >
            <Tab hidden={e.ocultar}>
              <strong>{e.Titulo}</strong>
            </Tab>
          </Tooltip>
        ))}
      </TabList>
      <TabPanels>
        {props.Tabs.map((e, index) => (
          <TabPanel hidden={e.ocultar} key={index}>
            {e.Component}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
});
