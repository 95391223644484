import { useMediaQuery } from "@chakra-ui/react";
import { useState } from "react";
import { CGIBox } from "../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../Components/Box/CGIBoxItens";
import { CGICalendario } from "../../Components/Calendario/CGICalendario";
import { CGIGrafico } from "../../Components/Graficos/CGIGrafico";
import { ResumoGraficos } from "../../Components/Graficos/ResumoGraficos";
import { CGIGrupoMeta } from "../../Components/GrupoMeta/CGIGrupoMeta";
import { CGIGrupoNotificacoes } from "../../Components/GrupoNotificacoes/CGIGrupoNotificacoes";
import { CGIMeta } from "../../Components/Meta/CGIMeta";
import { CGINotificacao } from "../../Components/Notificacao/CGINotificacao";
import { CGITableResumo } from "../../Components/TableResumo/CGITableResumo";
import { RetornarDadosUsuario } from "../../Services/Util/Util";
import { TamanhoTelaMobile } from "../../Themes/Temas";

export function Home() {
  const [isMobile] = useMediaQuery(TamanhoTelaMobile);

  const [Dados] = useState(() => {
    return RetornarDadosUsuario();
  });

  return (
    <>
      <CGIBox>
        <CGIBoxItem Col={12}>
          <ResumoGraficos />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGINotificacao />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIGrupoNotificacoes link="" bgColor={"lightcoral"} color="white" />
        </CGIBoxItem>
        {Dados.metas.map((e, index) => {
          return (
            <CGIBoxItem key={index} Col={3}>
              <CGIMeta link={e} />
            </CGIBoxItem>
          );
        })}
        <CGIBoxItem Col={6}>
          <CGIGrupoMeta
            titulo="Metas Alcançadas"
            link={Dados ? Dados.metas.join("|").toString() : ""}
          />
        </CGIBoxItem>
        {isMobile ? (
          <></>
        ) : (
          Dados.calendarios.map((e, index) => {
            var Dados = e.split("|");
            return (
              <CGIBoxItem key={index} Col={12}>
                <CGICalendario
                  titulo={Dados[0]}
                  link={Dados[1]}
                  local={Dados[2]}
                />
              </CGIBoxItem>
            );
          })
        )}
        {Dados.resumos.map((e, index) => {
          return (
            <CGIBoxItem key={index} Col={12}>
              <CGITableResumo titulo={e} link={e} />
            </CGIBoxItem>
          );
        })}
      </CGIBox>
    </>
  );
}
