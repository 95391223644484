import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIFileInput } from "../../../Components/FileInput/CGIFileInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { FormImportacaoNFSaidaTypes } from "../../../Types/Faturamento/ImportacaoNFSaida/ImportacaoNFSaidaTypes";
import { FormRelacaoEntregaType } from "../../../Types/Faturamento/RelacaoEntrega/RelacaoEntrega";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";

export const AlterarImportacaoNFSaida = forwardRef(
  (
    props: {
      CarregarDados: () => void;
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current?.Abrir();
      },
    }));

    const form = useFormContext<FormImportacaoNFSaidaTypes>();
    const CGIModalRef = useRef<ModalBaseType>();

    return (
      <CGIModal
        id="faturamento/importacaonfsaida/incluir"
        ref={CGIModalRef}
        Titulo="Importação de NF de Saída"
        TelaCheia={"6xl"}
        CarregarDados={() => {}}
        Confirmar={(e: any) => {}}
        Fechar={() => {
          form.reset();
        }}
      >
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIFileInput id={"Anexo"} label="Pesquisar Arquivo" />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGICheckBox<FormImportacaoNFSaidaTypes>
              id={"GerarCtsReceberCasoEstejaPresenteNoXML"}
              label="Gerar Cts. Receber caso esteja presente no XML"
              tooltip="Gerar Cts. Receber caso esteja presente no XML"
            />
            <CGICheckBox<FormImportacaoNFSaidaTypes>
              id={"ImportarXMLComoNFSaida"}
              label="Importar XML como NF de Saída"
              tooltip="Importar XML como NF de Saída"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIComboSimples<FormImportacaoNFSaidaTypes>
              id={"tipoDocumentoId"}
              Link={"tipoDocumento"}
              label={"Tipo Documento"}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
    //FINAL JSX
  }
);
