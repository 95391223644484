import { CGIHttp } from "../../../../Services/Axios";
import {
    ListarPesquisaFormaPagamentoTypes,
    ListarFormaPagamentoItensTypes,
    ListarFormaPagamentoTypes,
    FormFormaPagamentoTypes,
    DadosRegistroTypes,
} from "../../../../Types/Sistema/Classificacao/FormaPagamento/FormaPagamentoTypes";

const url = "/classificacao";

export function HttpFormaPagamento() {
    async function RetornarFormasPagamento(
        DadosFiltros: ListarPesquisaFormaPagamentoTypes
    ){
        return (
            await new CGIHttp().conn.post(
                url + "/obter-formas-pagamento",
                DadosFiltros
            )
        ).data.data as ListarFormaPagamentoItensTypes[]
    }

    async function RetornarDadosFormaPagamento(id: number) {
        return await new CGIHttp().conn.get(
            url + `/alterar/${id}`
        );
    }

    async function Incluir(Dados: DadosRegistroTypes) {
        return await new CGIHttp().conn.post(
            url + "/incluir",
            Dados
        );
    }
    async function Alterar(Dados: DadosRegistroTypes) {
        return await new CGIHttp().conn.post(
            url + "/alterar",
            Dados
        );
    }
    async function Visualizar(Dados: DadosRegistroTypes) {
        return await new CGIHttp().conn.get(
            url + "/visualizar/" + Dados
        );
    }
    async function RetornarDadosExclusao(Dados: DadosRegistroTypes) {
        return await new CGIHttp().conn.get(
            url + "/excluir/" + Dados
        );
    }
    async function Excluir(Dados: any) {
        return await new CGIHttp().conn.post(
            url + "/excluir",
            Dados
        );
    }
    return {
        RetornarFormasPagamento,
        RetornarDadosFormaPagamento,
        RetornarDadosExclusao,
        Incluir,
        Alterar,
        Visualizar,
        Excluir,
    };
}
