import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import { CGITextArea } from "../../../Components/TextArea/CGITextArea";
import {
  AlterarEstoqueNasEmpresasItensTypes,
  AlterarEstoqueNasEmpresasTypes,
} from "../../../Types/Almoxarifado/MovEstoque/AlterarEstoqueNasEmpresasType";

import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { HttpMovEstoque } from "./Http";

type PropsAlterarEstoqueNasEmpresasType = {
  CarregarDados: Function;
};

export const AlterarEstoqueNasEmpresas = forwardRef(
  (props: PropsAlterarEstoqueNasEmpresasType, ref) => {
    useImperativeHandle(ref, () => ({
      Abrir(pId: number) {
        form.setValue("Pesquisa.produtoId", pId);
        CGIModalRef.current?.Abrir();
        CarregarDados();
      },
    }));
    const form = useFormContext<AlterarEstoqueNasEmpresasTypes>();
    const CGIModalRef = useRef<ModalBaseType>();
    const GridItens = useWatch({
      control: form.control,
      name: "Itens",
    });
    const GridColunas: Array<CGICol<AlterarEstoqueNasEmpresasItensTypes>> = [
      {
        Campo: "selecionado",
        Titulo: "",
        Tipo: "CheckBox",
        att: {
          maxWidth: 60,
        },
      },
      {
        Campo: "nomeEmpresa",
        Titulo: "Empresa",
        Tipo: "Texto",
      },
      {
        Campo: "estoqueAtual",
        Titulo: "Estoque Atual",
        Tipo: "Numero",
        ExibirCoresConformeValor: true,
      },
      {
        Campo: "novoEstoque",
        Titulo: "Novo Estoque",
        Tipo: "Numero",
        att: {
          editable: true,
        },
      },
    ];

    async function CarregarDados() {
      const DadosFiltro = form.getValues("Pesquisa");
      var Dados =
        await HttpMovEstoque().RetornarEstoqueNasEmpresasParaAtualizar(
          DadosFiltro.produtoId
        );
      form.setValue("Itens", Dados);
    }

    const Confirmar = async (data: any, event: any) => {};

    return (
      <CGIModal
        id="sistema/produto/alterarestoqueempresas"
        ref={CGIModalRef}
        Titulo="Alterar Preço Nas Empresas"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        CarregarDados={CarregarDados}
        Fechar={() => {}}
      >
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIResumo
              Expandir
              ResumoProdutoSimples
              ProdutoId="Pesquisa.produtoId"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIDataTableSimples
              DisableGetId
              Altura="300px"
              Colunas={GridColunas}
              Data={GridItens}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGITextArea<AlterarEstoqueNasEmpresasTypes>
              id={"Pesquisa.Obs"}
              label={"Observação"}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
    //FINAL JSX
  }
);
