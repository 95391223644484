import axios, { AxiosError, AxiosResponse } from "axios";
import { CGIHttp } from "../../../Services/Axios";
import { DadosLogin, DadosUsuario } from "../../../Types/Home/Login/LoginType";
const baseURL = process.env.REACT_APP_BASEURL;
export function HttpLogin() {
  async function Logar(Dados: DadosLogin) {
    let Obj = await axios.post(baseURL + "/home/login", Dados);

    return Obj.data.data as DadosUsuario;
  }
  async function ObterImagemLogin(nomeDomino: string) {
    let Obj = await axios.post(baseURL + "/home/" + nomeDomino);

    return Obj.data.data as { temDominio: boolean; logo: string };
  }
  return {
    Logar,
    ObterImagemLogin,
  };
}
