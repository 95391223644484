export const ObjTipoCliente: FormTipoClienteType = {
  Form: {
    DadosRegistro: {
      id: 0,
      nome: "",
      ativo: false,
      clienteBloqNaoPodeGravarPedido: false,
      codigoExterno: "",
      descricaoAuxiliar: "",
      nomeStatus: "",
    },
  },
};

export type FormTipoClienteType = {
  Form: {
    DadosRegistro: TipoClienteTypes;
  };
};

type TipoClienteTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  clienteBloqNaoPodeGravarPedido: boolean;
  codigoExterno: string;
  descricaoAuxiliar: string;
  nomeStatus: string;
};

export const ObjListarTipoClientePadrao: ListarTipoClienteTypes = {
  Pesquisa: {
    titulo: "",
    nome: "",
    ativoId: 0,
  },
  Itens: [],
};

export type ListarTipoClienteTypes = {
  Pesquisa: ListarPesquisaTipoClienteTypes;
  Itens: ListarTipoClienteItensTypes[];
};

export type ListarPesquisaTipoClienteTypes = {
  titulo: string;
  nome: string;
  ativoId: number;
};

export type ListarTipoClienteItensTypes = {
  id: number;
  codigo: number;
  nome: string;
  ativo: boolean;
  clienteBloqNaoPodeGravarPedido: boolean;
};
