import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIHistorico } from "../../../Components/Historico/CGIHistorico";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGINumberInput } from "../../../Components/NumberInput/CGINumberInput";
import { CGIRadioButton } from "../../../Components/RadioButton/CGIRadioButton";
import LoadingContext from "../../../Context/LoadingContext";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import {
  ItensPedidoType,
  objPedLoja,
  PedidoLojaType,
} from "../../../Types/Vendas/Pedido/PedidoLojaType";
import { HttpPedido } from "./Http";
import { AlterarPedidoBalcao } from "./PedidoBalcao/_AlterarPedidoBalcao";
import { AlterarNomePedido } from "./_AlterarNomePedido";
import { DefinirTabelaPrecoItensPedido } from "./_DefinirTabelaPreco";
import { DesbloquearPedido } from "./_DesbloquearPedido";
import { ImportarPedido } from "./_ImportarPedido";
import { RecalcularDescontoPedido } from "./_RecalcularDescontoPedido";

export function ListarPedido() {
  //Consts
  const VisualizarPedidoRef = useRef<ModalBaseType>();
  const PedidoHistoricoRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const AlterarPedidoLojaRef = useRef<ModalBaseType>();
  const ImportarPedidoRef = useRef<ModalBaseType>();
  const DesbloquearPedidoRef = useRef<ModalBaseType>(null);
  const RecalcularDescontoPedidoRef = useRef<ModalBaseType>(null);
  const AlterarNomePedidoRef = useRef<ModalBaseType>(null);
  const DefinirTabelaPrecoItensRef = useRef<ModalBaseType>(null);
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<PedidoLojaType>({
    defaultValues: objPedLoja,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  //Consts

  //Menus
  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo Pedido",
      Permissao: "sistema/produto/incluir",
      OnClick: () => {
        AlterarPedidoLojaRef.current?.Abrir();
      },
    },
    {
      Nome: "Importar Pedido",
      Permissao: "vendas/pedido/importararquivo",
      OnClick: () => {
        ImportarPedidoRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "sistema/produto/visualizar",
      OnClick: () => {
        VisualizarPedidoRef.current?.Abrir();
      },
    },
    {
      Nome: "Alterar",
      Permissao: "sistema/produto/alterar",
      OnClick: () => {
        AlterarPedidoLojaRef.current?.Alterar();
      },
    },
    {
      Nome: "Desbloquear",
      Permissao: "vendas/pedido/desbloquear",
      OnClick: (e: any) => {
        DesbloquearPedidoRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Recalcular Desconto",
      Permissao: "vendas/pedido/visualizar",
      OnClick: (e: any) => {
        RecalcularDescontoPedidoRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Alterar Nome",
      Permissao: "vendas/pedido/visualizar",
      OnClick: (e: any) => {
        AlterarNomePedidoRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Definir Tabela de Preço Itens",
      Permissao: "vendas/pedido/visualizar",
      OnClick: (e: any) => {
        DefinirTabelaPrecoItensRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/produto/excluir",
      OnClick: () => {
        CGIModalDeleteRef.current?.Abrir();
      },
    },
    {
      Nome: "Histórico",
      Permissao: "vendas/pedido/visualizarhistorico",
      OnClick: () => {
        PedidoHistoricoRef.current?.Abrir();
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Aberto", cor: "green" },
    { nomeStatus: "Separado", cor: "lightBlue" },
    { nomeStatus: "Conferido", cor: "wheat" },
    { nomeStatus: "Excluído", cor: "red" },
    { nomeStatus: "Faturado", cor: "darkblue" },
    { nomeStatus: "Entregue", cor: "cadetBlue" },
    { nomeStatus: "Romaneio", cor: "gray" },
    { nomeStatus: "Devolução Romaneio", cor: "lightGray" },
  ];

  const GridColunas: Array<CGICol<ItensPedidoType>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "nomeCliente",
      Titulo: "Identificações",
      Tipo: "Composta",
      att: {
        filter: true,
      },
      Composta: [
        {
          titulo: "Identificação",
          valor: "identificacao",
          exibirLabel: true,
        },
        {
          titulo: "Pedido Cliente",
          valor: "pedidoCliente",
          exibirLabel: true,
        },
        {
          titulo: "NF",
          valor: "numeroNF",
          exibirLabel: true,
        },
        {
          titulo: "Status Financeiro",
          valor: "nomeStatusFinanceiroTitulo",
          exibirLabel: true,
        },
      ],
    },
    {
      Campo: "nomeCliente",
      Titulo: "Vendedor Cond. Pagamento",
      Tipo: "Composta",

      Composta: [
        {
          titulo: "Cliente",
          valor: "nomeCliente",
        },
        {
          titulo: "Pedido Impresso",
          valor: "nomePedidoImpresso",
        },
        {
          titulo: "Tem Obs",
          valor: "nomeTemObs",
        },
        {
          titulo: "Tem Anexo",
          valor: "nomeTemAnexo",
        },
      ],
    },
    {
      Campo: "nomeVendedor",
      Titulo: "Vend./Cond. Pag.",
      Tipo: "Composta",
      att: {
        filter: "agTextColumnFilter",
      },
      Composta: [
        {
          titulo: "Vendedor",
          valor: "nomeVendedor",
        },
        {
          titulo: "Transportadora",
          valor: "nomeTransportadora",
        },
        {
          titulo: "Condição de Pagamento",
          valor: "nomeCondicaoPagamento",
        },
      ],
    },
    {
      Campo: "qtdItens",
      Titulo: "Qtd. Itens",
      Tipo: "Texto",
      att: {
        maxWidth: 120,
      },
    },
    {
      Campo: "totalPedido",
      Titulo: "Total Pedido",
      Tipo: "Moeda",
      att: {
        maxWidth: 120,
      },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ItensPedidoType) => MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 80,
        filter: false,
      },
    },
  ];

  const Janelas = [
    {
      Componente: (
        <AlterarPedidoBalcao
          CarregarDados={methods.handleSubmit(RetornarPedidos)}
          ref={AlterarPedidoLojaRef}
        />
      ),
    },
    {
      Componente: (
        <CGIHistorico
          tipo="TimeLine"
          titulo="Histórico do Pedido"
          ref={PedidoHistoricoRef}
          link={"vendas/pedido/visualizarhistorico"}
          origem={"pedido"}
        />
      ),
    },
    {
      Componente: (
        <ImportarPedido
          CarregarDados={methods.handleSubmit(RetornarPedidos)}
          ref={ImportarPedidoRef}
        />
      ),
    },
    {
      Componente: (
        <AlterarNomePedido
          CarregarDados={() => {}}
          ref={AlterarNomePedidoRef}
        />
      ),
    },
    {
      Componente: (
        <DesbloquearPedido
          CarregarDados={() => {}}
          ref={DesbloquearPedidoRef}
        />
      ),
    },
    {
      Componente: (
        <RecalcularDescontoPedido
          CarregarDados={() => {}}
          ref={RecalcularDescontoPedidoRef}
        />
      ),
    },
    {
      Componente: (
        <DefinirTabelaPrecoItensPedido
          CarregarDados={() => {}}
          ref={DefinirTabelaPrecoItensRef}
        />
      ),
    },
  ];
  //Menus
  //Func
  async function RetornarPedidos() {
    setLoadingState(true);
    var DadosFiltro = methods.getValues("Pesquisa");
    var data = await HttpPedido()
      .RetornarPedidos(DadosFiltro)
      .finally(() => {
        setLoadingState(false);
      });
    methods.setValue("Itens", data);
  }

  //Func

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Filtros de Período" popover="Filtros de Período">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<PedidoLojaType>
                id={"Pesquisa.dataInclusaoInicial"}
                label={"Data Inclusão Inicial"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<PedidoLojaType>
                id={"Pesquisa.dataInclusaoFinal"}
                label={"Data Inclusão Final"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<PedidoLojaType>
                id={"Pesquisa.dataBaixaInicial"}
                label={"Data Baixa/Fat. Inicial"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<PedidoLojaType>
                id={"Pesquisa.dataBaixaFinal"}
                label={"Data Baixa/Fat. Final"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<PedidoLojaType>
                id={"Pesquisa.dataPrevisaoEntregaInicial"}
                label={"Data Prev. Entrega Inicial"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<PedidoLojaType>
                id={"Pesquisa.dataPrevisaoEntregaFinal"}
                label={"Data Prev. Entrega Final"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBox spacing="10px">
            <CGIBoxItem Col={12}>
              <CGIComboSimples<PedidoLojaType>
                Multipla
                Link={"empresa"}
                label={"Empresa"}
                id={"Pesquisa.ListaEmpresasId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={8}>
              <CGIComboSimples<PedidoLojaType>
                Multipla
                Link={"naturezadeoperacao"}
                label={"Natureza Operação"}
                id={"Pesquisa.ListaNaturezasOperacaoId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIInput<PedidoLojaType>
                id={"Pesquisa.pedidoCliente"}
                placeholder={"Pedido do Cliente"}
                label={"Pedido do Cliente"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIInput<PedidoLojaType>
                id={"Pesquisa.identificacao"}
                placeholder={"Pedido/ Identificação"}
                label={"Pedido/ Identificação"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={8}>
              <CGIComboSimples<PedidoLojaType>
                Multipla
                Link={"vendedor"}
                label={"Vendedor"}
                id={"Pesquisa.ListaVendedoresId"}
              />
            </CGIBoxItem>

            <CGIBoxItem Col={9}>
              <CGIComboSimples<PedidoLojaType>
                Multipla
                Link={"statuspedido"}
                label={"Status"}
                id={"Pesquisa.ListaStatusId"}
              />
            </CGIBoxItem>

            <CGIBoxItem Col={3}>
              <CGIComboSimples<PedidoLojaType>
                Link={"tipodepedido"}
                label={"Tipo de Pedido"}
                id={"Pesquisa.ListaTiposPedidoId"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIBox titulo="Cliente" popover="Filtros referentes a clientes">
            <CGIBoxItem Col={8}>
              <CGIComboAjax<PedidoLojaType>
                Multipla
                Link={"cliente"}
                label={"Clientes"}
                id={"Pesquisa.ListaClientesId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<PedidoLojaType>
                Link={"tipocliente"}
                label={"Tipo de Cliente"}
                id={"Pesquisa.ListaTiposClienteId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGIComboSimples<PedidoLojaType>
                Link={"rota"}
                label={"Rota"}
                id={"Pesquisa.ListaRotasId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGIComboSimples<PedidoLojaType>
                Link={"praca"}
                label={"Praça"}
                id={"Pesquisa.ListaPracasId"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIBox
            titulo="Transporte"
            popover="Filtros referentes a parte de transporte"
          >
            <CGIBoxItem Col={6}>
              <CGIComboSimples<PedidoLojaType>
                Link={"transportadora"}
                label={"Transportadora"}
                id={"Pesquisa.ListaTransportadorasId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIComboSimples<PedidoLojaType>
                Link={"transportadora"}
                label={"Redespacho"}
                id={"Pesquisa.ListaRedespachosId"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIBox titulo="Produto" popover="Filtros referentes a produtos">
            <CGIBoxItem Col={4}>
              <CGIComboAjax<PedidoLojaType>
                Multipla
                Link={"produto"}
                label={"Produto"}
                id={"Pesquisa.ListaProdutosId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<PedidoLojaType>
                Multipla
                Link={"classe"}
                label={"Classe do Produto"}
                id={"Pesquisa.ListaClassesProdutoId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<PedidoLojaType>
                Multipla
                Link={"subgrupo"}
                label={"Sub-Grupo"}
                id={"Pesquisa.ListaSubGruposId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<PedidoLojaType>
                Multipla
                Link={"subgrupo"}
                label={"Sub-Grupo (Exclusão)"}
                id={"Pesquisa.ListaSubGruposExclusaoId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIInput<PedidoLojaType>
                id={"Pesquisa.codigoProduto"}
                placeholder={"Código do Produto"}
                label={"Código do Produto"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={7}>
          <CGIBox titulo="Locação" popover="Filtros referentes a locação">
            <CGIBoxItem Col={12}>
              <CGIComboSimples<PedidoLojaType>
                Multipla
                Link={"responsavelfinanceiro"}
                label={"Responsável Financeiro"}
                id={"Pesquisa.ListaResponsaveisFinanceirosId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<PedidoLojaType>
                Multipla
                Link={"categoriapedido"}
                label={"Categoria Pedido (Locação)"}
                id={"Pesquisa.ListaCategoriasPedidoLocacaoId"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={5}>
          <CGIBox
            titulo="Pedido Loja"
            popover="Filtros específicos para pedido loja"
          >
            <CGIBoxItem Col={6}>
              <CGICheckBox<PedidoLojaType>
                id={"Pesquisa.ListaTiposPedidoId"}
                label={"Orçamento"}
                tooltip={"Orçamento"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGICheckBox<PedidoLojaType>
                id={"Pesquisa.tipoPedidoPedido"}
                label={"Pedido"}
                tooltip={"Pedido"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGICheckBox<PedidoLojaType>
                id={"Pesquisa.tipoPedidoDevolucao"}
                label={"Devolução"}
                tooltip={"Devolução"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGICheckBox<PedidoLojaType>
                id={"Pesquisa.tipoPedidoPedidoComTroca"}
                label={"Pedido com troca"}
                tooltip={"Pedido com troca"}
              />
            </CGIBoxItem>

            <CGIBoxItem Col={3}>
              <CGIRadioButton<PedidoLojaType>
                id={"Pesquisa.tipoFaturamento"}
                row={"column"}
                label={"Faturamento"}
                values={[
                  {
                    label: "Ambos",
                    valor: "0",
                  },
                  {
                    label: "Romaneio",
                    valor: "1",
                  },
                  {
                    label: "NFe / NFCe",
                    valor: "2",
                  },
                ]}
              />
            </CGIBoxItem>

            <CGIBoxItem Col={7}>
              <CGIComboSimples<PedidoLojaType>
                Multipla
                Link={"formapagamento"}
                label={"Forma Pagamento"}
                id={"Pesquisa.ListaFormasPagamentoId"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIBox titulo="Diversos" popover="Diversos">
            <CGIBoxItem Col={3}>
              <CGIBox>
                <CGIBoxItem Col={12}>
                  <CGICheckBox<PedidoLojaType>
                    id={"Pesquisa.somenteSemTransporte"}
                    label={"Somente S/ Transp."}
                    tooltip={"Somente S/ Transp."}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={12}>
                  <CGICheckBox<PedidoLojaType>
                    id={"Pesquisa.somenteTroca"}
                    label={"Somente Troca"}
                    tooltip={"Somente Troca"}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={12}>
                  <CGICheckBox<PedidoLojaType>
                    id={"Pesquisa.somentePedidoBloqueado"}
                    label={"Somente Ped. Bloq."}
                    tooltip={"Somente Ped. Bloq."}
                  />
                </CGIBoxItem>

                <CGIBoxItem Col={12}>
                  <CGICheckBox<PedidoLojaType>
                    id={"Pesquisa.exibirPedidosExcluidosAutomaticamente"}
                    label={"Exibir Ped. Exc. Automaticamente"}
                    tooltip={"Exibir Ped. Exc. Automaticamente"}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={12}>
                  <CGICheckBox<PedidoLojaType>
                    id={"Pesquisa.ocultarExcluidos"}
                    label={"Ocultar Excluídos"}
                    tooltip={"Ocultar Excluídos"}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={12}>
                  <CGICheckBox<PedidoLojaType>
                    id={"Pesquisa.ocultarExcluidos"}
                    label={"Ocultar Agrupados Automaticamente"}
                    tooltip={"Ocultar Excluídos"}
                  />
                </CGIBoxItem>
              </CGIBox>
            </CGIBoxItem>
            <CGIBoxItem Col={5}>
              <CGIBox titulo="Filtros de valores" popover="Filtros de valores">
                <CGIBoxItem Col={6}>
                  <CGINumberInput<PedidoLojaType>
                    id={"Pesquisa.valorPedidoInicial"}
                    label={"Valor do Pedido Inicial"}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={6}>
                  <CGINumberInput<PedidoLojaType>
                    id={"Pesquisa.valorPedidoFinal"}
                    label={"Valor do Pedido Final"}
                  />
                </CGIBoxItem>
              </CGIBox>
            </CGIBoxItem>

            <CGIBoxItem Col={6}>
              <CGIComboSimples<PedidoLojaType>
                Multipla
                Link={"motivocancelamentopedido"}
                label={"Motivo Cancelamento"}
                id={"Pesquisa.ListaMotivosCancelamentoId"}
              />
            </CGIBoxItem>

            <CGIBoxItem Col={6}>
              <CGIComboSimples<PedidoLojaType>
                Link={"representante"}
                label={"Representante"}
                id={"Pesquisa.ListaRepresentantesId"}
              />
            </CGIBoxItem>

            <CGIBoxItem Col={3}>
              <CGIComboSimples<PedidoLojaType>
                Link={"simnaoambos"}
                label={"Pedido Impresso"}
                id={"Pesquisa.pedidoImpressoId"}
              />
            </CGIBoxItem>

            <CGIBoxItem Col={3}>
              <CGIComboSimples<PedidoLojaType>
                Link={"simnaoambos"}
                label={"Exibir Somente Venda"}
                id={"Pesquisa.exibirSomenteVenda"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="vendas/pedido/listar"
      Titulo="Pedido"
      Janelas={Janelas}
      tipoGET={{
        url: "PEDIDO-LISTAR",
        setRetorno: e => {
          methods.setValue("Pesquisa", e);
        },
      }}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => {
            methods.reset();
          }}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarPedidos)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
          Totalizadores={{
            "Valor Total": "SUM|qtdItens",
            Quantidade: "COUNT|qtdItens",
          }}
        />
      </FormProvider>
    </CGIListar>
  );
}
