import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { CGIBox } from "../../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../../../Components/CheckBox/CGICheckBox";
import { useCGIToast } from "../../../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../../../Components/Input/CGIInput";
import { CGIModal } from "../../../../../Components/Modal/CGIModal";
import { CGINumberInput } from "../../../../../Components/NumberInput/CGINumberInput";
import { CGIResumo } from "../../../../../Components/Resumo/CGIResumo";
import { CGITextArea } from "../../../../../Components/TextArea/CGITextArea";
import LoadingContext from "../../../../../Context/LoadingContext";
import PedLojaContext from "../_AlterarPedidoLoja";

type AlterarGridPedidoLojaType = {};

export const AlterarProdutoGridPedidoLoja = forwardRef(
  (props: AlterarGridPedidoLojaType, ref) => {
    const FormAlterarProdGrid = useForm();
    const { form, GridItensPrincipalRef } = useContext(PedLojaContext);
    const ConfigPedLoja = useWatch({
      control: form.control,
      name: "config",
    });
    useImperativeHandle(ref, () => ({
      Abrir(e: any) {
        CGIModalRef.current.Abrir();
        FormAlterarProdGrid.reset(e);
      },
    }));
    const { ToastErrorId } = useCGIToast();
    const CGIModalRef = useRef() as any;
    const { setLoadingState } = useContext(LoadingContext);

    const Id = FormAlterarProdGrid.watch("id");
    const Qtd = FormAlterarProdGrid.watch("quantidade");
    const vlrDesconto = FormAlterarProdGrid.watch("vlrDesconto");
    const precoVenda = FormAlterarProdGrid.watch("precoVenda");

    const CalcTotal = useMemo(() => {
      if (!Id || !form) {
        return;
      }
      var _Qtd = Qtd ? Qtd : 0;
      var _precoVenda = precoVenda ? precoVenda : 0;
      var _vlrDesconto = vlrDesconto ? vlrDesconto : 0;
      var vlrUnitarioLiquido = _precoVenda - _vlrDesconto;
      var Total = _Qtd * vlrUnitarioLiquido;
      FormAlterarProdGrid.setValue("total", Total);
      FormAlterarProdGrid.setValue("vlrUnitarioLiquido", vlrUnitarioLiquido);
    }, [Id, form, Qtd, precoVenda, vlrDesconto, FormAlterarProdGrid]);

    const Confirmar = async () => {
      const data = FormAlterarProdGrid.getValues();
      GridItensPrincipalRef.current?.api.applyTransaction({
        update: [{ ...data }],
      });
      form?.setValue("DadosProdutoSelecionado.id", data.id);
      CGIModalRef.current.Fechar();
    };

    const ValidarDesconto = useCallback(() => {
      const vlrDesconto = FormAlterarProdGrid.watch("vlrDesconto");
      const precoVenda = FormAlterarProdGrid.watch("precoVenda");
      var percDesconto = (vlrDesconto / precoVenda) * 100;

      if (percDesconto > ConfigPedLoja.descontoMaximoPercentual) {
        ToastErrorId(
          "PercDesconto",
          "Desconto máximo permitido é de " +
            ConfigPedLoja.descontoMaximoPercentual +
            "%"
        );
        FormAlterarProdGrid.setValue("vlrDesconto", 0);
        FormAlterarProdGrid.setFocus("vlrDesconto");
        return;
      }
    }, [ConfigPedLoja, FormAlterarProdGrid, ToastErrorId]);

    return (
      <CGIModal
        IgnorarForm
        id="vendas/pedido/copiar"
        ref={CGIModalRef}
        Titulo=""
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          FormAlterarProdGrid.reset({});
          setLoadingState(false);
        }}
      >
        <FormProvider {...FormAlterarProdGrid}>
          <>{CalcTotal}</>
          <CGIBox>
            <CGIInput
              ocultar
              id={"campanhaVendasId"}
              placeholder={"campanhaVendasId"}
            />
            <CGIInput
              ocultar
              id={"codigoProdutoFabricante"}
              placeholder={"codigoProdutoFabricante"}
            />
            <CGIInput
              ocultar
              id={"gradeProdutoId"}
              placeholder={"gradeProdutoId"}
            />
            <CGIInput ocultar id={"multiplo"} placeholder={"multiplo"} />
            <CGIInput
              ocultar
              id={"nomeCampanhaVendas"}
              placeholder={"nomeCampanhaVendas"}
            />
            <CGIInput ocultar id={"obsVenda"} placeholder={"obsVenda"} />
            <CGIInput
              ocultar
              id={"precoCampanhaVendas"}
              placeholder={"precoCampanhaVendas"}
            />
            <CGIInput
              ocultar
              id={"precoPromocional"}
              placeholder={"precoPromocional"}
            />
            <CGIInput ocultar id={"quantidade"} placeholder={"quantidade"} />
            <CGIInput
              ocultar
              id={"ultimaAlteracaoPrecoProduto"}
              placeholder={"ultimaAlteracaoPrecoProduto"}
            />
            <CGIInput
              ocultar
              id={"unidadesConversao"}
              placeholder={"unidadesConversao"}
            />
            <CGIInput ocultar id={"vlrUnitario"} placeholder={"vlrUnitario"} />
            <CGIBoxItem Col={12}>
              <CGIBox titulo="Dados do Produto" popover="Dados do Produto">
                <CGIBoxItem Col={1}>
                  <CGIInput
                    id={"id"}
                    placeholder={"Id"}
                    label={"Id"}
                    att={{ isDisabled: true }}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={3}>
                  <CGIInput
                    id={"codigoProduto"}
                    placeholder={"codigoProduto"}
                    label={"Código Produto"}
                    att={{ isDisabled: true }}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={6}>
                  <CGIInput
                    id={"descricao"}
                    placeholder={"descricao"}
                    label={"Descrição"}
                    att={{ isDisabled: true }}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={3}>
                  <CGIInput
                    id={"estoque"}
                    placeholder={"estoque"}
                    label={"Estoque"}
                    att={{ isDisabled: true }}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={3}>
                  <CGIInput
                    id={"ncm"}
                    placeholder={"NCM"}
                    label={"NCM"}
                    att={{ isDisabled: true }}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={2}>
                  <CGIInput
                    id={"unidade"}
                    placeholder={"Unidade"}
                    label={"Unidade"}
                    att={{ isDisabled: true }}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={4}>
                  <CGICheckBox
                    id={"ehPrecoCampanhaVendas"}
                    label={"Tem Preço Campanha de Vendas"}
                    tooltip={"Tem Preço Campanha de Vendas"}
                    att={{
                      disabled: true,
                    }}
                  />
                  <CGICheckBox
                    id={"ehPrecoPromocional"}
                    label={"Tem Preço Promocional"}
                    tooltip={"Tem Preço Promocional"}
                    att={{
                      disabled: true,
                    }}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={12}>
                  <CGIResumo
                    ProdutoId="DadosProdutoSelecionado.id"
                    ResumoProduto
                    ResumoProdutoEstoqueEmpresa
                    ProdutoEstoqueEmpresaId="id"
                  />
                </CGIBoxItem>
              </CGIBox>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIBox>
                <CGIBoxItem Col={2}>
                  <CGINumberInput id={"quantidade"} label={"Quantidade"} />
                </CGIBoxItem>
                <CGIBoxItem Col={2}>
                  <CGINumberInput
                    id={"precoVenda"}
                    prefix
                    label={"Pr. Venda"}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={2}>
                  <CGINumberInput
                    id={"vlrDesconto"}
                    prefix
                    label={"Desconto P/Item"}
                    att={{
                      onChange: e => ValidarDesconto(),
                    }}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={2}>
                  <CGINumberInput
                    id={"vlrUnitarioLiquido"}
                    prefix
                    label="Vlr. Uni. Líq."
                    att={{ isDisabled: true }}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={2}>
                  <CGINumberInput
                    id={"total"}
                    prefix
                    label="Total"
                    att={{ isDisabled: true }}
                  />
                </CGIBoxItem>
              </CGIBox>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIBox>
                <CGIBoxItem Col={3}>
                  <CGIInput id={"ncm"} placeholder={"NCM"} label="NCM" />
                </CGIBoxItem>
                <CGIBoxItem Col={3}>
                  <CGIInput
                    id={"unidadeConversao"}
                    placeholder={"Unidade NF"}
                    label="Unidade NF"
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={12}>
                  <CGITextArea
                    id={"obsVenda"}
                    att={{ placeholder: "Obs NF" }}
                    label="Obs NF"
                  />
                </CGIBoxItem>
              </CGIBox>
            </CGIBoxItem>
          </CGIBox>
        </FormProvider>
      </CGIModal>
    );
  }
);
