export const ObjPedidoPendenteFaturamentoPadrao: PedidoPendenteFaturamentoType =
  {
    Pesquisa: {
      listaClientesId: "",
      dataInclusaoInicial: null,
      dataInclusaoFinal: null,
      listaEmpresasId: "",
      listaVendedoresId: "",
      listaTransportadorasId: "",
      somentePedidoBloqueados: false,
      somentePedidoPendenteFaturamento: false,
      TipoData: ""
    },
    Itens: []
  };
export type PedidoPendenteFaturamentoType = {
  Pesquisa: PedidoPendenteFaturamentoPesquisaType;
  Itens: PedidoPendenteFaturamentoItensType[];
};
export type PedidoPendenteFaturamentoPesquisaType = {
  listaClientesId: string;
  dataInclusaoInicial: string | null;
  dataInclusaoFinal: string | null;
  listaEmpresasId: string;
  listaVendedoresId: string;
  listaTransportadorasId: string;
  somentePedidoBloqueados: boolean;
  somentePedidoPendenteFaturamento: boolean;
  TipoData: string;
};

export type PedidoPendenteFaturamentoItensType = {
  id: number;
  identificacaoPedido: string;
  dataPrevisaoEntrega: string;
  dataCadastro: string;
  nomePeriodoFiscal: string;
  colaboradorId: number;
  nomeColaborador: string;
  nomeFantasia: string;
  cpfcnpjColaborador: string;
  nomeCidadeColaborador: string;
  nomeNaturezaOperacao: string;
  nomeCondicaoPagamento: string;
  nomePedido: string;
  nomeVendedor: string;
  pedidoFaturamentoBloqueado: boolean;
  faturamentoBloqueado: boolean;
  bloqueioVendasFixo: boolean;
  dataHoraEnvioFaturamento: null;
  totalPedido: number;
  totalPedidoParaFaturamento: number;
  nomeTransportadora: string;
  obsPedido: string;
  pedidoTemAnexos: boolean;
  pedidoBloqueado: boolean;
  statusSeparacaoPedido: string;
  pedidoCliente: null | string;
  separacaoTotalOuParcial: string;
  nomeUsuario: string;
};
