import { CGIHttp } from "../../../Services/Axios";
import { EmpresaTypes } from "../../../Types/Sistema/Empresa/EmpresaTypes";
import {
  FormUsuarioTypes,
  ListarUsuarioTypes,
  ListarPesquisaUsuarioTypes,
  ListarUsuarioItensTypes,
  DadosRegistroTypes,
} from "../../../Types/Sistema/Usuario/UsuarioTypes";

export function HttpUsuario() {
  const url = "/usuario";

  async function RetornarUsuarios(DadosFiltros: ListarPesquisaUsuarioTypes) {
    return (await (
      await new CGIHttp().conn.post(url + "/obter-usuarios", DadosFiltros)
    ).data.data) as ListarUsuarioItensTypes[];
  }

  async function Incluir(Dados: DadosRegistroTypes) {
    return await new CGIHttp().conn.post(url + `/incluir`, Dados);
  }

  async function Alterar(Dados: DadosRegistroTypes) {
    return await new CGIHttp().conn.post(url + `/alterar/`, Dados);
  }

  async function RetornarEmpresasDoUsuario(UsuarioId: number) {
    return await new CGIHttp().conn.get(url + `/obter-empresas/${UsuarioId}`);
  }
  async function RetornarDadosUsuario(UsuarioId: number) {
    return await (
      await new CGIHttp().conn.get(url + `/alterar/${UsuarioId}`)
    ).data.data;
  }

  async function RetornarResumosDoUsuario(UsuarioId: number) {
    return await new CGIHttp().conn.get(url + `/obter-resumos/${UsuarioId}`);
  }

  async function RetornarMetasDoUsuario(UsuarioId: number) {
    return await new CGIHttp().conn.get(url + `/obter-metas/${UsuarioId}`);
  }

  async function RetornarCalendariosDoUsuario(UsuarioId: number) {
    return await new CGIHttp().conn.get(
      url + `/obter-calendarios/${UsuarioId}`
    );
  }

  async function Visualizar(Dados: DadosRegistroTypes) {
    return await new CGIHttp().conn.get(url + "/visualizar/" + Dados.id);
  }
  async function RetornarDadosExclusao(Dados: DadosRegistroTypes) {
    return await new CGIHttp().conn.get(url + "/excluir/" + Dados.id);
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir", Dados);
  }

  async function TrocarSenhaIndividual(Dados: any) {
    return await new CGIHttp().conn.post(url + "/redefinir-senha/", {
      id: Dados.id,
      obs: Dados.obs,
    });
  }
  async function CopiarPermissao(Dados: any) {
    return await new CGIHttp().conn.post(url + "/copiar-permissao/", Dados);
  }
  async function TrocarSenhaTodos() {
    return await new CGIHttp().conn.post(url + "/redefinir-senha/");
  }
  async function Listar() {
    return await (
      await new CGIHttp().conn.get(url + "/listar")
    ).data.data;
  }
  return {
    RetornarUsuarios,
    RetornarDadosExclusao,
    Incluir,
    Alterar,
    Visualizar,
    RetornarDadosUsuario,
    Excluir,
    RetornarEmpresasDoUsuario,
    RetornarResumosDoUsuario,
    RetornarMetasDoUsuario,
    RetornarCalendariosDoUsuario,
    TrocarSenhaIndividual,
    TrocarSenhaTodos,
    CopiarPermissao,
    Listar,
  };
}
