export const ObjArquivoRetornoPadrao: FormArquivoRetornoType = {
  Form: {
    DadosRegistro: {
      id: 0,
      dataCadastro: "",
      usuarioId: 0,
      convenioPortadorId: 0,
      dataCredito: "",
      dataGeracao: "",
      sequencialArquivo: 0,
      qtdTitulos: 0,
      valorRetorno: 0,
      statusLeitura: "",
      tipoOrigem: "",
      lancTransferencia: false,
      ativo: false,
      fileName: "",
      lancaTransferencia: false,
      valorBaixado: 0,
    },
  },
};

export type ArquivoRetornoTypes = {
  DadosRegistro: {
    id: number;
    dataCadastro: string;
    usuarioId: number;
    convenioPortadorId: number;
    dataCredito: string;
    dataGeracao: string;
    sequencialArquivo: number;
    qtdTitulos: number;
    valorRetorno: number;
    statusLeitura: string;
    tipoOrigem: string;
    lancTransferencia: boolean;
    ativo: boolean;
    fileName: string;
    lancaTransferencia: boolean;
    valorBaixado: number;
  };
};

export type FormArquivoRetornoType = {
  Form: ArquivoRetornoTypes;
};

export const ObjListarArquivoRetornoPadrao: ListarArquivoRetornoTypes = {
  Pesquisa: {
    dataCadastroInicial: "",
    dataCadastroFinal: "",
  },
  Itens: [],
};

export type ArquivoRetornoPesquisaGridTypes = {
  id: number;
  dataCadastro: string;
  valorRetorno: number;
  qtdTitulos: number;
  nomeConvenio: string;
  nomePortador: string;
  nomeStatus: string;
  nomeCondominio: string;
  nomeEmpresa: string;
  numero: string;
};

export type ListarArquivoRetornoTypes = {
  Pesquisa: ListarPesquisaArquivoRetornoTypes;
  Itens: ListarArquivoRetornoItensTypes[];
};

export type ListarPesquisaArquivoRetornoTypes = {
  dataCadastroInicial: string;
  dataCadastroFinal: string;
};

export type ImportarArquivoRetornoTypes = {
  arquivoId: number;
  convenioPortadorId: number;
};

export type IncluirArquivoRetornoItensTypes = {
  id: number;
  dataVencimento: string;
  documento: string;
  nomeCliente: string;
  valor: number;
  selecionado: boolean;
};

export type ListarArquivoRetornoItensTypes = {
  id: number;
  sequencialArquivo: number;
  dataCadastro: string;
  dataCredito: string;
  nomeConvenio: string;
  nomePortador: string;
  nomeStatus: string;
  qtdTitulos: number;
  valorRetorno: number;
  valorBaixado: number;
  portadorId: number;
};

export type VisualizarArquivoRetornoTypes = {
  id: number;
  sequencialArquivo: number;
  dataCadastro: string;
  dataCredito: string;
  nomeConvenio: string;
  nomePortador: string;
  nomeStatus: string;
  qtdTitulos: number;
  valorRetorno: number;
  valorBaixado: number;
  portadorId: number;
  fileName: string;
};
