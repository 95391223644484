import { AgGridReact } from "ag-grid-react";
import { useRef, useContext, useCallback } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { FormContext } from "../../../Context/FormConxtext";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ConsultarProdutoXNFEntradaItensPesquisaType,
  ConsultarProdutoXNFEntradaItensPrincipalType,
  FormConsultarProdutoXNFEntradaType,
  ObjConsultaProdutoXNFEntradaPadrao,
} from "../../../Types/Almoxarifado/NFEntrada/ConsultarProdutoXNFEntrada";
import {
  ListarRelacaoEntregaTypes,
  ObjListarRelacaoEntregaPadrao,
} from "../../../Types/Faturamento/RelacaoEntrega/RelacaoEntrega";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { HttpRelacaoEntrega } from "../../Faturamento/RelacaoEntrega/Http";
import { HttpNFEntrada } from "./Http";

export function ConsultarProdutoXNFEntrada() {
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<FormConsultarProdutoXNFEntradaType>({
    defaultValues: ObjConsultaProdutoXNFEntradaPadrao,
  });

  const GridPesquisaRef =
    useRef<AgGridReact<FormConsultarProdutoXNFEntradaType>>(null);
  const GridPrincipalRef =
    useRef<AgGridReact<FormConsultarProdutoXNFEntradaType>>(null);
  const GridItensPesquisa = useWatch({
    control: methods.control,
    name: "form.pesquisa.itens",
  });
  const GridItensPrincipal = useWatch({
    control: methods.control,
    name: "form.itens",
  });

  const RetornarDadosGridPesquisa = useCallback(async () => {
    try {
      setLoadingState(true);
      methods.setValue("form.pesquisa.itens", []);
      const DadosFiltros = methods.getValues("form.pesquisa");
      var data = await HttpNFEntrada().RetornarProdutosXNFEntrada(DadosFiltros);
      methods.setValue("form.pesquisa.itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosEspecificos = useCallback(
    async (Dados: ConsultarProdutoXNFEntradaItensPesquisaType) => {
      try {
        setLoadingState(true);
        const DadosFiltros = methods.getValues("form.pesquisa");
        var data = await HttpNFEntrada().RetornarProdutosXNFEntradaEspecifico(
          Dados,
          DadosFiltros
        );
        methods.setValue("form.itens", data);
      } catch (e) {
      } finally {
        setLoadingState(false);
      }
    },
    [methods, setLoadingState]
  );
  const MenuGridPesquisa: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "",
      OnClick: (e: ConsultarProdutoXNFEntradaItensPrincipalType) => {
        //AlterarRelacaoEntregaRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Visualizar Histórico",
      Permissao: "",
      OnClick: (e: ConsultarProdutoXNFEntradaItensPrincipalType) => {
        //AlterarRelacaoEntregaRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Visualizar Historico  Preço",
      Permissao: "",
      OnClick: (e: ConsultarProdutoXNFEntradaItensPrincipalType) => {
        //AlterarRelacaoEntregaRef.current?.Alterar(e);
      },
    },
  ];
  const MenuGridPrincipal: Array<MenuTypes> = [
    {
      Nome: "Visualizar NF Entrada",
      Permissao: "",
      OnClick: (e: ConsultarProdutoXNFEntradaItensPrincipalType) => {
        //AlterarRelacaoEntregaRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Histórico NF Entrada",
      Permissao: "",
      OnClick: (e: ConsultarProdutoXNFEntradaItensPrincipalType) => {
        //AlterarRelacaoEntregaRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Visualizar Pedido Entrada",
      Permissao: "",
      OnClick: (e: ConsultarProdutoXNFEntradaItensPrincipalType) => {
        //AlterarRelacaoEntregaRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Histórico Pedido Entrada",
      Permissao: "",
      OnClick: (e: ConsultarProdutoXNFEntradaItensPrincipalType) => {
        //AlterarRelacaoEntregaRef.current?.Alterar(e);
      },
    },
  ];
  const GridPesquisaColunas: Array<
    CGICol<ConsultarProdutoXNFEntradaItensPesquisaType>
  > = [
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      ocultar: true,
      att: {
        maxWidth: 80,
      },
    },
    {
      Campo: "codigoProduto",
      Titulo: "Código Produto",
      Tipo: "Texto",
      att: { maxWidth: 150 },
    },
    {
      Campo: "descricao",
      Titulo: "Descrição",
      Tipo: "Texto",
    },
    {
      Campo: "nomeUnidade",
      Titulo: "Unid.",
      Tipo: "Texto",
      att: { maxWidth: 100 },
    },
    {
      Campo: "nomeFornecedor",
      Titulo: "Fornecedor",
      Tipo: "Texto",
      att: { maxWidth: 700 },
    },
    {
      Campo: "codigoBarras",
      Titulo: "Cod. Barras",
      Tipo: "Texto",
      att: { maxWidth: 150 },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ConsultarProdutoXNFEntradaItensPrincipalType) =>
          MenuGridPopover(e, MenuGridPesquisa),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];
  const GridPrincipalColunas: Array<
    CGICol<ConsultarProdutoXNFEntradaItensPrincipalType>
  > = [
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      ocultar: true,
      att: {
        maxWidth: 80,
      },
    },
    {
      Campo: "codigoProduto",
      Titulo: "Código Produto",
      Tipo: "Texto",
      att: {
        maxWidth: 160,
      },
    },
    {
      Campo: "descricao",
      Titulo: "Descrição",
      Tipo: "Texto",
    },
    {
      Campo: "numeroNF",
      Titulo: "Nº NF",
      Tipo: "Numero",
      att: {
        maxWidth: 100,
      },
    },
    {
      Campo: "cnpjFornecedor",
      Titulo: "CNPJ Fornecedor",
      Tipo: "Texto",
      att: {
        maxWidth: 180,
      },
    },
    {
      Campo: "nomeFornecedor",
      Titulo: "Fornecedor",
      Tipo: "Texto",
      att: {
        minWidth: 400,
      },
    },

    {
      Campo: "quantidade",
      Titulo: "Quant.",
      Tipo: "Numero",
      att: {
        maxWidth: 120,
      },
    },
    {
      Campo: "vlrUnitario",
      Titulo: "Valor Unit.",
      Tipo: "Moeda",
      att: {
        maxWidth: 150,
      },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ConsultarProdutoXNFEntradaItensPrincipalType) =>
          MenuGridPopover(e, MenuGridPrincipal),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];
  const Janelas = [
    {
      Componente: <></>,
    },
  ];

  return (
    <CGIListar
      id="almoxarifado/nfentrada/listarconsultarprodutosxnfentrada"
      Titulo="Produtos X Nota Fiscal de Entrada "
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIBox titulo="Filtros">
              <CGIBoxItem Col={5}>
                <CGIInput<FormConsultarProdutoXNFEntradaType>
                  id={"form.pesquisa.codigoProduto"}
                  placeholder={"Código Produto"}
                  label={"Código Produto"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={5}>
                <CGIComboAjax<FormConsultarProdutoXNFEntradaType>
                  id={"form.pesquisa.listaFornecedoresId"}
                  Link={"fornecedor"}
                  label={"Fornecedor"}
                  Multipla
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGIButton
                  onClick={() => RetornarDadosGridPesquisa()}
                  label="Pesquisar"
                  att={{ mt: 8 }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={12}>
                <CGIDataTableSimples
                  DisableGetId
                  Att={{
                    rowSelection: "single",
                    onSelectionChanged(event) {
                      const selectedRows = event.api.getSelectedRows();
                      RetornarDadosEspecificos(selectedRows[0]);
                    },
                  }}
                  ref={GridPesquisaRef}
                  Altura="300px"
                  Colunas={GridPesquisaColunas}
                  Data={GridItensPesquisa}
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIBox titulo="Produtos">
              <CGIBoxItem Col={12}>
                <CGIDataTableSimples
                  DisableGetId
                  Att={{}}
                  ref={GridPrincipalRef}
                  Altura="300px"
                  Colunas={GridPrincipalColunas}
                  Data={GridItensPrincipal}
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
        </CGIBox>
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
