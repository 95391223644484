import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarConfigServicoItensTypes,
  ListarConfigServicoTypes,
  ObjListarConfigServicoPadrao,
  ConfigServicoVisualizarTypes,
} from "../../../Types/Sistema/ConfigServico/ConfigServicoTypes";
import {
  ModalBaseType,
  LegendaTypes,
} from "../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpConfigServico } from "./Http";
import { AlterarConfigServico } from "./_AlterarConfigServico";
import { VisualizarConfigServico } from "./_VisualizarConfigServico";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";

export function ListarConfigServico() {
  const AlterarConfigServicoRef = useRef<ModalBaseType>();
  const VisualizarConfigServicoRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarConfigServicoTypes>({
    defaultValues: ObjListarConfigServicoPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarConfigServicoes = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpConfigServico().RetornarConfigServicos(DadosFiltros);
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarConfigServicoItensTypes) => {
      return await HttpConfigServico().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpConfigServico()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Nova Config. de Serviço",
      Permissao: "sistema/configservico/incluir",
      OnClick: () => {
        AlterarConfigServicoRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "sistema/configservico/visualizar",
      OnClick: (e: ConfigServicoVisualizarTypes) => {
        VisualizarConfigServicoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Alterar",
      Permissao: "sistema/configservico/alterar",
      OnClick: (e: ListarConfigServicoItensTypes) => {
        AlterarConfigServicoRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/configservico/excluir",
      OnClick: (e: ListarConfigServicoItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas: LegendaTypes = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarConfigServicoItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "nome",
      Titulo: "Nome",
      Tipo: "Texto",
    },
    {
      Campo: "calcularISS",
      Titulo: "ISS",
      Tipo: "CheckBoxDisable",
      att: { maxWidth: 100 },
    },
    {
      Campo: "calcularPIS",
      Titulo: "PIS",
      Tipo: "CheckBoxDisable",
      att: { maxWidth: 100 },
    },
    {
      Campo: "calcularCOFINS",
      Titulo: "COFINS",
      Tipo: "CheckBoxDisable",
      att: { maxWidth: 100 },
    },
    {
      Campo: "codigoCnae",
      Titulo: "CNAE",
      Tipo: "Numero",
      att: { maxWidth: 100 },
    },
    {
      Campo: "ativo",
      Titulo: "Ativo",
      Tipo: "CheckBoxDisable",
      att: { maxWidth: 100 },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarConfigServicoItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          valor: "nomeStatus",
          titulo: "",
        },
        {
          valor: "nome",
          titulo: "Nome",
        },
        {
          valor: "calcularISS",
          titulo: "ISS",
        },
        {
          valor: "calcularPIS",
          titulo: "PIS",
        },
        {
          valor: "calcularCOFINS",
          titulo: "COFINS",
        },
        {
          valor: "codigoCnae",
          titulo: "CNAE",
        },
        {
          valor: "ativo",
          titulo: "Ativo",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir"
          CarregarDados={RetornarConfigServicoes}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarConfigServico
          CarregarDados={methods.handleSubmit(RetornarConfigServicoes)}
          ref={AlterarConfigServicoRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarConfigServico
          CarregarDados={methods.handleSubmit(RetornarConfigServicoes)}
          ref={VisualizarConfigServicoRef}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarConfigServicoTypes>
            id="Pesquisa.listaEmpresasId"
            label="Empresa"
            Link={"empresa"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarConfigServicoTypes>
            id="Pesquisa.nome"
            label="Nome"
            placeholder={"Nome"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarConfigServicoTypes>
            id="Pesquisa.ativoId"
            label="Ativo"
            Link={"simnaoambos"}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="sistema/configservico/listar"
      Titulo="ConfigServico"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarConfigServicoes)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
