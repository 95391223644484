export const ObjPedidoEcommercePadrao: FormPedidoEcommerceType = {
  Form: {
    DadosRegistro: {
      id: 0,
      dataCadastro: "",
      usuarioId: 0,
      dataPedido: "",
      numeroPedido: "",
      obs: "",
      obsInterna: "",
      vendedor: "",
      desconto: 0,
      valorFrete: 0,
      totalProdutos: 0,
      totalVenda: 0,
      situacao: "",
      dataSaida: "",
      loja: "",
      numeroPedidoLoja: "",
      tipoIntegracao: "",
      idCliente: "",
      nome: "",
      cNPJ: "",
      iE: "",
      rG: "",
      endereco: "",
      numeroEnd: "",
      complemento: "",
      cidade: "",
      bairro: "",
      cEP: "",
      estado: "",
      email: "",
      celular: "",
      fone: "",
      clienteId: 0,
      empresaId: 0,
      pedidoId: 0,
      enderecoEntrega: "",
      situacaoId: 0,
      integracaoMarketPlaceId: 0,
      percComissao: 0,
      marketPlaceId: 0,
      valorParticipacaoFrete: 0,
      percParticipacaoFrete: 0,
      valorComissao: 0,
      percComissaoIntegracao: 0,
      valorComissaoIntegracao: 0,
      conferido: false,
      percTaxaPagamento: 0,
      valorTaxaPagamento: 0,
      dataExclusao: "",
      repassePrevisto: 0,
      obsLoja: "",
    },
  },
};
export type PedidoEcommerceTypes = {
  DadosRegistro: {
    id: number;
    dataCadastro: string | null;
    usuarioId: number;
    dataPedido: string | null;
    numeroPedido: string;
    obs: string;
    obsInterna: string;
    vendedor: string;
    desconto: number;
    valorFrete: number;
    totalProdutos: number;
    totalVenda: number;
    situacao: string;
    dataSaida: string | null;
    loja: string;
    numeroPedidoLoja: string;
    tipoIntegracao: string;
    idCliente: string;
    nome: string;
    cNPJ: string;
    iE: string;
    rG: string;
    endereco: string;
    numeroEnd: string;
    complemento: string;
    cidade: string;
    bairro: string;
    cEP: string;
    estado: string;
    email: string;
    celular: string;
    fone: string;
    clienteId: number;
    empresaId: number;
    pedidoId: number;
    enderecoEntrega: string;
    situacaoId: number;
    integracaoMarketPlaceId: number;
    percComissao: number;
    marketPlaceId: number;
    valorParticipacaoFrete: number;
    percParticipacaoFrete: number;
    valorComissao: number;
    percComissaoIntegracao: number;
    valorComissaoIntegracao: number;
    conferido: boolean;
    percTaxaPagamento: number;
    valorTaxaPagamento: number;
    dataExclusao: string | null;
    repassePrevisto: number;
    obsLoja: string;
  };
};
export type FormPedidoEcommerceType = {
  Form: PedidoEcommerceTypes;
};

export const ObjListarPedidoEcommercePadrao: ListarPedidoEcommerceTypes = {
  Pesquisa: {
    dataInicialInclusao: "",
    dataFinalInclusao: "",
    dataInicialPedido: "",
    dataFinalPedido: "",
    dataInicialNotaFiscal: "",
    dataFinalNotaFiscal: "",
    listaClientesId: "",
    listaSituacaoId: "",
    listaTiposIntegracoesId: "",
    numeroPedido: "",
    numeroPedidoLoja: "",
    identificacaoPedido: "",
    listaStatusId: "",
    listaMarketPlacesId: "",
    pedidoSemMarketPlace: false,
    pedidoExcluido: false,
    exibirDevolucao: 0,
  },
  Itens: [],
};

export type PedidoEcommercePesquisaGridTypes = {
  ncm?: string;
};

export type ListarPedidoEcommerceTypes = {
  Pesquisa: ListarPesquisaPedidoEcommerceTypes;
  Itens: ListarPedidoEcommerceItensTypes[];
};

export type ListarPesquisaPedidoEcommerceTypes = {
  dataInicialInclusao: string;
  dataFinalInclusao: string;
  dataInicialPedido: string;
  dataFinalPedido: string;
  dataInicialNotaFiscal: string;
  dataFinalNotaFiscal: string;
  listaClientesId: string;
  listaSituacaoId: string;
  listaTiposIntegracoesId: string;
  numeroPedido: string;
  numeroPedidoLoja: string;
  identificacaoPedido: string;
  listaStatusId: string;
  listaMarketPlacesId: string;
  pedidoSemMarketPlace: boolean;
  pedidoExcluido: boolean;
  exibirDevolucao: number;
};

export type ListarPedidoEcommerceItensTypes = {
  id: number;
  dataCadastro: string;
  clienteId: number;
  pedidoId: number;
  empresaId: number;
  percComissao: number;
  situacaoId: number;
  totalProdutos: number;
  valorFrete: number;
  totalVenda: number;
  identificacaoPedido: string;
  pedidoBloqueado: boolean;
  nomeStatusPedido: string;
  nomeCliente: string;
  faturamentoBloqueado: boolean;
  nomeEmpresa: string;
  nomeSituacao: string;
  nomeMarketPlace: string;
  numeroPedido: string;
  numeroPedidoLoja: string;
  tipoIntegracao: string;
};
