import { CGIHttp } from "../../../../Services/Axios";
import {
  ListarNFEntreguesTypes,
  ListarPesquisaNFEntreguesTypes,
  ListarNFEntreguesItensTypes,
} from "../../../../Types/Faturamento/NFSaida/NF Entregues/NFEntreguesTypes";

export function HttpNFEntregues() {
  const url = "/nfsaida";

  async function RetornarNFEntregues(
    DadosFiltros: ListarPesquisaNFEntreguesTypes
  ) {
    return (await (
      await new CGIHttp().conn.post(url + "/obter-nf-entregues", DadosFiltros)
    ).data.data) as ListarNFEntreguesItensTypes[];
  }

  async function RetornarDadosNFEntregues(id: number) {
    return await new CGIHttp().conn.get(url + `/alterar/${id}`);
  }

  // async function Incluir(Dados: NFEntreguesTypes) {
  //     return await new CGIHttp().conn.post(url + "/incluir", Dados);
  // }

  // async function Alterar(Dados: NFEntreguesTypes) {
  //     return await new CGIHttp().conn.post(url + "/alterar", Dados);
  // }

  async function Visualizar(id: number) {
    return await new CGIHttp().conn.get(url + "/visualizar/" + id);
  }

  // async function RetornarDadosExclusao(DadosNFEntregues: NFEntreguesTypes) {
  //     return (await new CGIHttp().conn
  //         .get(url + "/excluir/" + DadosNFEntregues.dadosRegistro.id)
  //         .then(res => res.data.data)) as DeleteTypes;
  // }

  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir", Dados);
  }

  return {
    RetornarNFEntregues,
    RetornarDadosNFEntregues,
    Visualizar,
    Excluir,
  };
}
