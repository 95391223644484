import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import {
  PortadorTypes,
  PortadorVisualizarTypes,
} from "../../../Types/Sistema/Portador/PortadorTypes";
import { HttpPortador } from "./Http";

type VisualizarPortadorTypes = {
  CarregarDados: Function;
};
export const VisualizarPortador = forwardRef(
  (props: VisualizarPortadorTypes, ref) => {
    useImperativeHandle(ref, () => ({
      Abrir(pPortadorId: number) {
        CGIModalRef.current?.Abrir();
        CarregarDados(pPortadorId);
      },
    }));

    const CGIModalRef = useRef<ModalBaseType>(null);
    const { reset } = useFormContext<PortadorTypes>();

    async function CarregarDados(id: number) {
      var data = await HttpPortador().RetornarDadosVisualizarPortador(id);
      reset(data);
    }

    return (
      <CGIModal
        id="sistema/portador/visualizar"
        ref={CGIModalRef}
        Titulo="Visualizar Portador"
        TelaCheia={"6xl"}
        Confirmar={() => {}}
        Fechar={() => {}}
        CarregarDados={CarregarDados}
        ocultarCancelar={true}
        ocultarConfirmar={true}
      >
        <CGIBox>
          <CGIBoxItem Col={2}>
            <CGIInput<PortadorVisualizarTypes>
              id="ativo"
              label="Id"
              placeholder="Id"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIInput<PortadorVisualizarTypes>
              id="nome"
              label="Nome"
              placeholder="Nome"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<PortadorVisualizarTypes>
              id="nomeTipoPortador"
              label="Tipo"
              placeholder="Tipo"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIInput<PortadorVisualizarTypes>
              id="nomeEmpresa"
              label="Empresa"
              placeholder="Empresa"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIBoxItem Col={4}>
              <CGICheckBox<PortadorVisualizarTypes>
                id="portadorGeral"
                label="Portador Geral"
                tooltip=""
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGICheckBox<PortadorVisualizarTypes>
                id="efetuarLancamento"
                label="Efetuar Lançamento"
                tooltip=""
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGICheckBox<PortadorVisualizarTypes>
                id="ativo"
                label="Ativo"
                tooltip=""
              />
            </CGIBoxItem>
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
  }
);
