import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext } from "react";
import { useForm, useWatch, FormProvider } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGINumberInput } from "../../../Components/NumberInput/CGINumberInput";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarImportacaoNFsaidaItensTypes,
  ListarImportacaoNFSaidaTypes,
  ObjListarImportacaoNFSaidaPadrao,
} from "../../../Types/Faturamento/ImportacaoNFSaida/ImportacaoNFSaidaTypes";
import {
  ListarNFEntreguesTypes,
  ObjListarNFEntreguesPadrao,
} from "../../../Types/Faturamento/NFSaida/NF Entregues/NFEntreguesTypes";
import {
  JanelasType,
  LegendaTypes,
  ModalBaseType,
} from "../../../Types/Modal/ModalBaseType";
import { AlterarImportacaoNFSaida } from "./_AlterarImportacaoNFSaida";
import { DefinirIncluida } from "./DefinirIncluida";
import { HttpImportacaoNFSaida } from "./Http";

export function ListarImportacaoNFSaida() {
  const ImportacaoNFSaidaRef = useRef<ModalBaseType>();
  const DefinirIncluidaImportacaoNFSaidaRef = useRef<ModalBaseType>();

  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarImportacaoNFSaidaTypes>({
    defaultValues: ObjListarImportacaoNFSaidaPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  //Final Hooks

  //------------------------

  //Inicio Consts
  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Importacao NF Saida",
      Permissao: "faturamento/importacaonfsaida/incluir",
      OnClick: () => {
        ImportacaoNFSaidaRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Excluir",
      Permissao: "",
      OnClick: (e: any) => {
        CGIModalDeleteRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Definir NF Incluida",
      Permissao: "",
      OnClick: (e: any) => {
        DefinirIncluidaImportacaoNFSaidaRef.current?.Abrir(e.id);
      },
    },
  ];

  const Legendas: LegendaTypes = [
    { nomeStatus: "Incluída", cor: "green" },
    { nomeStatus: "MDFe", cor: "blue" },
    { nomeStatus: "Excluída", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarImportacaoNFsaidaItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "nomeEmpresa",
      Titulo: "Empresa",
      Tipo: "Texto",
      att: {
        maxWidth: 150,
      },
    },
    {
      Campo: "nomeEmitente",
      Titulo: "Emitente",
      Tipo: "Texto",
    },
    {
      Campo: "nomeDestinatario",
      Titulo: "Destinatário",
      Tipo: "Texto",
    },
    {
      Campo: "chave",
      Titulo: "Chave",
      Tipo: "Texto",
    },
    {
      Campo: "numeroNF",
      Titulo: "Número NF",
      Tipo: "Texto",
      att: {
        maxWidth: 140,
      },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: any) => MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];
  const Janelas: JanelasType = [
    {
      Componente: <DefinirIncluida ref={DefinirIncluidaImportacaoNFSaidaRef} />,
    },
    {
      Componente: (
        <AlterarImportacaoNFSaida
          CarregarDados={RetornarImportacaoNFSaida}
          ref={ImportacaoNFSaidaRef}
        />
      ),
    },
  ];

  async function RetornarImportacaoNFSaida() {
    setLoadingState(true);
    var DadosFiltro = methods.getValues("Pesquisa");
    var data = await HttpImportacaoNFSaida()
      .RetornarImportacoesNFSaida(DadosFiltro)
      .finally(() => {
        setLoadingState(false);
      });
    methods.setValue("Itens", data);
  }

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={3}>
          <CGINumberInput<ListarImportacaoNFSaidaTypes>
            id={"Pesquisa.codigoNF"}
            label={"Código NF"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGINumberInput<ListarImportacaoNFSaidaTypes>
            id={"Pesquisa.numeroNF"}
            label={"Número NF"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGINumberInput<ListarImportacaoNFSaidaTypes>
            id={"Pesquisa.valorNF"}
            label={"Valor NF"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarImportacaoNFSaidaTypes>
            id={"Pesquisa.nomeEmitente"}
            label={"Emitente"}
            placeholder={"Emitente"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarImportacaoNFSaidaTypes>
            id={"Pesquisa.nomeDestinatario"}
            label={"Destinatário"}
            placeholder={"Destinatário"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIDatePicker<ListarImportacaoNFSaidaTypes>
            id={"Pesquisa.dataImportacaoInicial"}
            label={"Data Importação - De"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIDatePicker<ListarImportacaoNFSaidaTypes>
            id={"Pesquisa.dataImportacaoFinal"}
            label={"Data Importação - Até"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarImportacaoNFSaidaTypes>
            id={"Pesquisa.listaStatusId"}
            Multipla
            label={"Status"}
            Link={""}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarImportacaoNFSaidaTypes>
            id={"Pesquisa.gerouCtsReceberId"}
            label={"Gerou CTs Receber"}
            Link={"simnaoambos"}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="faturamento/importacaonfsaida/listar"
      Titulo="Importação NF Saida"
      Janelas={Janelas}
      tipoGET={{
        url: "IMPORTACAONFSAIDA-LISTAR",
        setRetorno: (e) => {
          methods.setValue("Pesquisa", e);
        },
      }}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarImportacaoNFSaida)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
