import { CGIHttp } from "../../../Services/Axios";
import {
  ItensPedidoType,
  ListarPedidoType,
  PedidoLojaType,
} from "../../../Types/Vendas/Pedido/PedidoLojaType";
import { VisualizarPedidoPorProdutoPesquisaTypes } from "../../../Types/Vendas/Pedido/VisualizarPedidoPorProduto";
import {
  PedidoPendenteFaturamentoItensType,
  PedidoPendenteFaturamentoPesquisaType,
} from "../../../Types/Vendas/Pedido/PedidoPendenteFaturamentoType";
import {
  ListarAlocacaoPedidoItensTypes,
  ListarAlocacaoPedidoPesquisaTypes,
} from "../../../Types/Vendas/Pedido/AlocarPedidoType";
import {
  ListarPedidoSeparacaoItensTypes,
  ListarPesquisaPedidoSeparacaoTypes,
} from "../../../Types/Vendas/Pedido/PedidoSeparacaoTypes";
import {
  ListarPedidosParaProducaoItensTypes,
  ListarPedidosParaProducaoTypes,
  ListarPesquisaPedidosParaProducaoTypes,
} from "../../../Types/Vendas/Pedido/PedidosParaProducaoTypes";

export function HttpPedido() {
  const url = "/pedido";
  async function PesquisarItensParaPedido(Pesquisa: string) {
    return await new CGIHttp().conn
      .post("produto/obter-produtos-para-pedido", {
        buscaProduto: Pesquisa,
      })
      .then((e) => e.data.data as Array<ItensPedidoType>);
  }
  async function RetornarPedidos(DadosFiltros: ListarPedidoType) {
    return (await (
      await new CGIHttp().conn.post(url + "/obter-pedidos", DadosFiltros)
    ).data.data) as ItensPedidoType[];
  }

  async function Listar() {
    return await (
      await new CGIHttp().conn.get(url + "/listar")
    ).data.data;
  }
  async function ListarSeparacao() {
    return await (
      await new CGIHttp().conn.get(url + "/listarseparacao")
    ).data.data;
  }
  async function RetornarDadosPedido(id: number) {
    return await new CGIHttp().conn.get(url + `/alterar/${id}`);
  }

  async function IncluirGET() {
    return (await (
      await new CGIHttp().conn.get(url + "/incluir")
    ).data.data) as PedidoLojaType;
  }
  async function Incluir(DadosPedido: PedidoLojaType) {
    return await new CGIHttp().conn.post(url + "/incluir", DadosPedido);
  }
  async function Alterar(DadosPedido: PedidoLojaType) {
    return await new CGIHttp().conn.post(url + "/alterar", DadosPedido);
  }
  async function Visualizar(id: number) {
    return await new CGIHttp().conn.get(url + "/visualizar/" + id);
  }
  async function RetornarDadosExclusao(DadosPedido: PedidoLojaType) {
    return await new CGIHttp().conn.get(
      url + "/excluir/" + DadosPedido.DadosRegistro.id
    );
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir", Dados);
  }
  async function RetornarDadosFormaPagamento(formaPagamentoId: number) {
    return await new CGIHttp().conn
      .get("classificacao/retornar-dados-forma-pagamento/" + formaPagamentoId)
      .then((e) => e.data.data);
  }
  async function RetornarDadosProdutos(produtoId: number) {
    return await new CGIHttp().conn
      .get("produto/retornar-dados-produto/" + produtoId)
      .then((e) => e.data.data);
  }
  async function RetornarDadosProdutosParaPedidoBalcao<T>(produtoId: number) {
    return (await new CGIHttp().conn
      .get("/produto/obter-dados-produtos-para-pedidobalcao/" + produtoId)
      .then((e) => e.data.data)) as T;
  }
  async function RetornarDadosColaborador(colaboradorId: number) {
    return await new CGIHttp().conn
      .get(
        "colaborador/retornar-dados-colaborador-para-pedido/" + colaboradorId
      )
      .then((e) => e.data.data);
  }
  async function RetornarPedidoProProduto(
    Dados: VisualizarPedidoPorProdutoPesquisaTypes
  ) {
    return await (
      await new CGIHttp().conn.post(url + "/obter-pedidos-por-produto", Dados)
    ).data.data;
  }
  async function RetornarPedidoPendenteFaturamento(
    Dados: PedidoPendenteFaturamentoPesquisaType
  ): Promise<PedidoPendenteFaturamentoItensType[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-pedidos-pendentes-faturamento/", { itens: 1 })
      .then((e) => e.data.data);
  }
  async function RetornarAlocacaoPedidos(
    Dados: ListarAlocacaoPedidoPesquisaTypes
  ): Promise<ListarAlocacaoPedidoItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-pedidos-alocacao/", Dados)
      .then((e) => e.data.data);
  }

  async function RetornarPedidosSeparacao(
    Dados: ListarPesquisaPedidoSeparacaoTypes
  ): Promise<ListarPedidoSeparacaoItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-pedidos-separacao/", Dados)
      .then((e) => e.data.data);
  }

  async function RetornarPedidosParaProducao(
    DadosFiltros: ListarPesquisaPedidosParaProducaoTypes
  ): Promise<ListarPedidosParaProducaoItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(
        url + "/obter-pedidos-para-producao/",
        DadosFiltros
      )
    ).data.data;
  }

  return {
    RetornarPedidos,
    RetornarDadosPedido,
    RetornarDadosExclusao,
    Incluir,
    Alterar,
    Visualizar,
    Excluir,
    PesquisarItensParaPedido,
    RetornarDadosProdutos,
    RetornarDadosFormaPagamento,
    RetornarDadosColaborador,
    Listar,
    IncluirGET,
    RetornarPedidoProProduto,
    RetornarPedidoPendenteFaturamento,
    RetornarAlocacaoPedidos,
    RetornarDadosProdutosParaPedidoBalcao,
    ListarSeparacao,
    RetornarPedidosSeparacao,
    RetornarPedidosParaProducao,
  };
}
