/* eslint-disable no-extend-native */
interface Number {
  FormatarReal: () => string;
}
Number.prototype.FormatarReal = function () {
  return Number(this).toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
};
interface String {
  FormatarData: () => Date | null;
}

String.prototype.FormatarData = function () {
  var str = String(this);
  var DT = str.split("/");
  if (DT.length === 3) {
    var D = DT[2] + "-" + DT[1] + "-" + DT[0];
    return new Date(D);
  } else {
    return null;
  }
};
