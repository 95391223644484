import { AddIcon } from "@chakra-ui/icons";
import { Tag, TagLabel } from "@chakra-ui/react";
import { useContext } from "react";
import { useForm, useWatch, FormProvider } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import LoadingContext from "../../../Context/LoadingContext";
import { JanelasType, LegendaTypes } from "../../../Types/Modal/ModalBaseType";
import {
  ListarAlocacaoPedidoItensTypes,
  ListarAlocacaoPedidoTypes,
  ObjListarAlocacaoPadrao,
} from "../../../Types/Vendas/Pedido/AlocarPedidoType";
import {
  PedidoLojaType,
  objPedLoja,
  ItensPedidoType,
} from "../../../Types/Vendas/Pedido/PedidoLojaType";
import { HttpPedido } from "./Http";

export function ListarAlocacaoPedido() {
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarAlocacaoPedidoTypes>({
    defaultValues: ObjListarAlocacaoPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  //Consts

  //Menus
  const ListarMenu: Array<MenuTypes> = [];

  const MenuGrid: Array<MenuTypes> = [];

  const Legendas: LegendaTypes = [
    { nomeStatus: "100 %", cor: "green" },
    { nomeStatus: "> 80 %", cor: "blue" },
    { nomeStatus: "> 50 % ", cor: "brown" },
    { nomeStatus: "> 30 %", cor: "orange" },
    { nomeStatus: "> 10 %", cor: "crimson" },
    { nomeStatus: "< 10 %", cor: "red" },
    { nomeStatus: "0 %", cor: "black" },
  ];

  const GridColunas: Array<CGICol<ListarAlocacaoPedidoItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "id",
      Titulo: "#",
      Tipo: "Texto",
      att: {
        minWidth: 100,
      },
    },
    {
      Campo: "identificacaoPedido",
      Titulo: "Pedido",
      Tipo: "Texto",
      att: {
        minWidth: 120,
      },
    },
    {
      Campo: "nomeCliente",
      Titulo: "Cliente",
      Tipo: "Texto",
      att: {
        minWidth: 380,
      },
    },
    {
      Campo: "nomeVendedor",
      Titulo: "Vendedor",
      Tipo: "Texto",
      att: {
        minWidth: 220,
      },
    },
    {
      Campo: "qtdItens",
      Titulo: "Qtd. Itens",
      Tipo: "Texto",
    },
    {
      Campo: "totalParaFaturamento",
      Titulo: "Total p/ Fat.",
      Tipo: "Texto",
    },
    {
      Campo: "totalPercAlocQtd",
      Titulo: "% Aloc.",
      Tipo: "Percentual",
      att: {
        cellStyle: {
          backgroundColor: "#FFFFE0",
          fontWeight: "bold",
        },
      },
    },
    {
      Campo: "totalPercAlocValor",
      Titulo: "% Aloc.",
      Tipo: "Percentual",
      att: {
        cellStyle: {
          backgroundColor: "#E0FFFF",
          fontWeight: "bold",
        },
      },
    },
    {
      Campo: "totalPedido",
      Titulo: "Total Pedido",
      Tipo: "Moeda",
    },
    {
      Campo: "totalPercAlocQtdEstoque",
      Titulo: "% Est.",
      Tipo: "Percentual",
      att: {
        cellStyle: {
          backgroundColor: "#FFE4B6",
          fontWeight: "bold",
        },
      },
    },
  ];

  async function RetornarPedidos() {
    setLoadingState(true);
    var DadosFiltro = methods.getValues("Pesquisa");
    var data = await HttpPedido()
      .RetornarAlocacaoPedidos(DadosFiltro)
      .finally(() => {
        setLoadingState(false);
      });
    methods.setValue("Itens", data);
  }

  //Func

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Filtros Por Data">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarAlocacaoPedidoTypes>
                id={"Pesquisa.dataInicial"}
                label={"Data Inclusão Inicial"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarAlocacaoPedidoTypes>
                id={"Pesquisa.dataFinal"}
                label={"Data Inclusão Final"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBox>
            <CGIBoxItem Col={6}>
              <CGIComboSimples<ListarAlocacaoPedidoTypes>
                id={"Pesquisa.empresaId"}
                label={"Empresa"}
                Link={"empresa"}
                validadores={{
                  required: "Campo Obrigatório",
                }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIComboSimples<ListarAlocacaoPedidoTypes>
                id={"Pesquisa.listaNaturezasOperacaoId"}
                label={"Natureza de Operação"}
                Link={"empresa"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGICheckBox<ListarAlocacaoPedidoTypes>
            id={"Pesquisa.somentePedidosComAlocacao"}
            label={"Somente Pedidos com Alocação"}
            tooltip={"Somente Pedidos com Alocação"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGICheckBox<ListarAlocacaoPedidoTypes>
            id={"Pesquisa.somentePedidosBloqueados"}
            label={"Somente Pedidos Bloqueados"}
            tooltip={"Somente Pedidos Bloqueados"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarAlocacaoPedidoTypes>
            id={"Pesquisa.pedidoCliente"}
            label={"Pedido Cliente"}
            placeholder={"Pedido Cliente"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboAjax<ListarAlocacaoPedidoTypes>
            id={"Pesquisa.listaClientesId"}
            label={"Cliente"}
            Link={"cliente"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarAlocacaoPedidoTypes>
            id={"Pesquisa.listaVendedoresId"}
            label={"Vendedor"}
            Link={"vendedor"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarAlocacaoPedidoTypes>
            id={"Pesquisa.grupoColaborador"}
            label={"Grupo Colaborador"}
            placeholder={"Grupo Colaborador"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarAlocacaoPedidoTypes>
            id={"Pesquisa.grupoColaboradorExclusao"}
            label={"Grupo Colaborador (Exclusão)"}
            placeholder={"Grupo Colaborador (Exclusão)"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarAlocacaoPedidoTypes>
            id={"Pesquisa.codigoProduto"}
            label={"Código Produto"}
            placeholder={"Código Produto"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarAlocacaoPedidoTypes>
            id={"Pesquisa.listaClassesId"}
            label={"Classe"}
            Link={"classe"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarAlocacaoPedidoTypes>
            id={"Pesquisa.pedidosNovosSaldosId"}
            label={"Classe"}
            Link={"classe"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarAlocacaoPedidoTypes>
            id={"Pesquisa.listaCondicoesPagamentoId"}
            label={"Condição Pagamento"}
            Link={"condicaodepagamento"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarAlocacaoPedidoTypes>
            id={"Pesquisa.situacaoEstoquePedidoId"}
            label={"Situação Estoque Pedido"}
            Link={"situacaoestoquepedido"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarAlocacaoPedidoTypes>
            id={"Pesquisa.identificacao"}
            label={"Identificação"}
            placeholder={"Código Produto"}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="vendas/pedido/listaralocacao"
      Titulo="Alocação de Pedidos"
      tipoGET={{
        url: "PEDIDO-LISTARALOCACAO",
        setRetorno: (e) => {
          methods.setValue("Pesquisa", e);
        },
      }}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => {
            methods.reset();
          }}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarPedidos)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
}
