import { CGIHttp } from "../../../Services/Axios";
import {
  FormAlocacaoType,
  ListarPesquisaAlocacaoTypes,
  ListarAlocacaoItensTypes,
  AlocacaoPesquisaGridTypes,
  AlocacaoTypes,
} from "../../../Types/Almoxarifado/Alocacao/AlocacaoTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpAlocacao() {
  const url = "/Alocacao";

  async function RetornarDadosGridPesquisa(
    dados: AlocacaoPesquisaGridTypes
  ): Promise<ListarAlocacaoItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-alocacao", dados)
      .then((e) => e.data.data);
  }

  async function RetornarAlocacoes(
    DadosFiltros: ListarPesquisaAlocacaoTypes
  ): Promise<ListarAlocacaoItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(url + "/obter-alocacao", DadosFiltros)
    ).data.data;
  }

  async function RetornarDadosAlocacao(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + `/alterar/${id}`)
    ).data.data) as AlocacaoTypes;
  }

  async function RetornarDadosExclusao(Dados: ListarAlocacaoItensTypes) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + Dados)
      .then((res) => res.data.data)) as DeleteTypes;
  }
  async function RetornarDadosVisualizarAlocacao(
    DadosAlocacao: FormAlocacaoType
  ) {
    return (await (
      await new CGIHttp().conn.get(url + "/visualizar/" + DadosAlocacao.Form)
    ).data.data) as AlocacaoTypes;
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir/" + Dados.id);
  }
  async function Alterar(DadosAlocacao: FormAlocacaoType) {
    return await new CGIHttp().conn.post(url + "/alterar", DadosAlocacao.Form);
  }
  async function Visualizar(DadosAlocacao: FormAlocacaoType) {
    return await new CGIHttp().conn.post(
      url + "/visualizar",
      DadosAlocacao.Form
    );
  }
  async function Incluir(DadosAlocacao: FormAlocacaoType) {
    return await new CGIHttp().conn.post(url + "/incluir", DadosAlocacao.Form);
  }
  return {
    RetornarAlocacoes,
    RetornarDadosGridPesquisa,
    RetornarDadosExclusao,
    RetornarDadosVisualizarAlocacao,
    RetornarDadosAlocacao,
    Visualizar,
    Excluir,
    Alterar,
    Incluir,
  };
}
