export const ObjPedidoBalcaoPadrao: FormPedidoBalcaotypes = {
  produtoId: 0,
  tipoDesconto: "",
  produtoSelecionadoId: 0,
  ProdutoSelecionado: {
    id: 0,
    produtoId: 0,
    codigoProduto: "",
    descricao: "",
    qtd: 0,
    vlrDesconto: 0,
    vlrUnitario: 0,
    vlrTotal: 0,
    descontoMaximo: 0,
    precoVenda: 0,
    vlrMinimo: 0,
    nomeUnidade: null,
    selecionado: false,
    itemValido: false,
  },
  Form: {
    empresaId: 0,
    clienteId: 0,
    vendedorId: 0,
    romaneioOuFiscal: "",
    nFeOuNFCe: "",
    totalDesconto: 0,
    totalPedido: 0,
    config: {
      configBalcao: {
        pagamentoDinheiro: false,
        pagamentoDebito: false,
        pagamentoCredito: false,
        pagamentoDebitoOnLine: false,
        pagamentoCrediario: false,
        pagamentoPix: false,
      },
      permitirInformarDataPedido: false,
      permitirUsuarioAlterarVlrUnitario: false,
      qtdCasasDecimaisQtd: 0,
      qtdCasasDecimaisVlrUnitario: 0,
      imprimirPedidoRomaneioAposInclusao: false,
      imprimirComprovanteDoCreditoNoFinalDoPedido: false,
      faturarOuBaixarPedidoAoIncluir: false,
      bloquearInclusaoDeUmItemMaisDeUmaVez: false,
      especie: "",
      marca: "",
      indicadorPresencaId: 0,
      tipoFreteId: 0,
      tipoEntregaId: 0,
      numeroMaximoEntregas: 0,
      valorMinimoFaturamento: 0,
      bloquearItensNegativos: false,
      bloquearItensNegativosApenasServidor: false,
      obrigarTipoDePedido: false,
      descontoAte05: false,
      descontoAte10: false,
      descontoAte20: false,
      descontoAte30: false,
      descontoAte40: false,
      descontoAte50: false,
      permitirQualquerDesconto: false,
      descontoMaximoPercentual: 0,
    },
    itens: [],
    itensValidos: [],
  },
};
export type FormPedidoBalcaotypes = {
  produtoId: number;
  tipoDesconto: string;
  produtoSelecionadoId: number;
  ProdutoSelecionado: PedidoBalcaoGridItemType;
  Form: PedidoBalcaoType;
};
export type PedidoBalcaoPesquisaType = {};

export type PedidoBalcaoType = {
  empresaId: number;
  clienteId: number;
  vendedorId: number;
  romaneioOuFiscal: string;
  nFeOuNFCe: string;
  totalDesconto: number;
  totalPedido: number;
  config: PedidoBalcaoConfig;
  itens: PedidoBalcaoGridItemType[];
  itensValidos: PedidoBalcaoGridItemType[];
};

export type PedidoBalcaoGridItemType = {
  id: number;
  produtoId: number;
  codigoProduto: string;
  descricao: string;
  qtd: number;
  vlrDesconto: number;
  vlrUnitario: number;
  vlrTotal: number;
  descontoMaximo: number;
  precoVenda: number;
  vlrMinimo: number;
  nomeUnidade: null;
  selecionado: boolean;
  itemValido: boolean;
};

export type PedidoBalcaoConfig = {
  configBalcao: PedidoBalcaoConfigBalcao;
  permitirInformarDataPedido: boolean;
  permitirUsuarioAlterarVlrUnitario: boolean;
  qtdCasasDecimaisQtd: number;
  qtdCasasDecimaisVlrUnitario: number;
  imprimirPedidoRomaneioAposInclusao: boolean;
  imprimirComprovanteDoCreditoNoFinalDoPedido: boolean;
  faturarOuBaixarPedidoAoIncluir: boolean;
  bloquearInclusaoDeUmItemMaisDeUmaVez: boolean;
  especie: string;
  marca: string;
  indicadorPresencaId: number;
  tipoFreteId: number;
  tipoEntregaId: number;
  numeroMaximoEntregas: number;
  valorMinimoFaturamento: number;
  bloquearItensNegativos: boolean;
  bloquearItensNegativosApenasServidor: boolean;
  obrigarTipoDePedido: boolean;
  descontoAte05: boolean;
  descontoAte10: boolean;
  descontoAte20: boolean;
  descontoAte30: boolean;
  descontoAte40: boolean;
  descontoAte50: boolean;
  permitirQualquerDesconto: boolean;
  descontoMaximoPercentual: number;
};

export type PedidoBalcaoConfigBalcao = {
  pagamentoDinheiro: boolean;
  pagamentoDebito: boolean;
  pagamentoCredito: boolean;
  pagamentoDebitoOnLine: boolean;
  pagamentoCrediario: boolean;
  pagamentoPix: boolean;
};
