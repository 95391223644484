/* eslint-disable react-hooks/exhaustive-deps */
import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
  useContext,
} from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../Box/CGIBox";
import { CGIBoxItem } from "../Box/CGIBoxItens";
import { CGIButton } from "../Button/CGIButton";
import { CGIComboSimples } from "../Combos/CGIComboSimples";
import { CGIDataTableSimples } from "../DataTable/CGIDataTableSimples";
import { CGICol } from "../DataTable/DataTable/DataTableTypes";
import { useCGIToast } from "../Hook/useCGIToast";
import { CGIModal } from "../Modal/CGIModal";
import LoadingContext from "../../Context/LoadingContext";
import { AlterarPermissoesTelaTypes } from "./PermissaoTypes";
import { HttpPermissao } from "./Http";
import React from "react";

export const AlterarPermissao = forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    async Abrir(e: string) {
      var data = await HttpPermissao().RetornarPermissoesTela(e);
      setDados({ ...data, usuarios: [] });
      CGIModalRef.current.Abrir();
    },
  }));
  const CGIModalRef = useRef() as any;
  const form = useFormContext();
  const { setLoadingState } = useContext(LoadingContext);
  const { ToastErro, ToastSucesso } = useCGIToast();

  useEffect(() => {
    setDados({ ...Dados, usuarios: [] });
  }, [form.watch("empresaId")]);

  const [Dados, setDados] = useState<AlterarPermissoesTelaTypes>(
    {} as AlterarPermissoesTelaTypes
  );
  const GridColunas: Array<CGICol<any>> = [
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        hide: true,
      },
    },
    {
      Campo: "nomeUsuario",
      Titulo: "Usuário",
      Tipo: "Texto",
    },
    {
      Campo: "selecionado",
      Titulo: "Sel.",
      Tipo: "CheckBox",
    },
  ];

  async function CarregarDados(e: any) {
    setLoadingState(true);
    var DadosUsuarios = await HttpPermissao()
      .RetornarPermissoesTelaUsuarios(Dados.link, e.empresaId)
      .finally(() => {
        setLoadingState(false);
      });
    setDados({ ...Dados, usuarios: DadosUsuarios, empresaId: e.empresaId });
  }

  async function Confirmar(e: any) {
    setLoadingState(true);
    await HttpPermissao()
      .AlterarPermissoesTela(Dados)
      .then(() => {
        CGIModalRef.current.Fechar();
        ToastSucesso("Permissões Alterardas Com Sucesso", "Permissões");
      })
      .catch(() => {
        return;
      })
      .finally(() => {
        setLoadingState(false);
      });
  }

  return (
    <CGIModal
      OcultarPermissoes
      id="sistema/permissao/alterarpermissaotela"
      ref={CGIModalRef}
      Titulo={`Alterar Permissão da Tela (${Dados.nome})`}
      TelaCheia={"3xl"}
      Confirmar={form.handleSubmit(Confirmar)}
      Fechar={() => {
        form.reset();
      }}
    >
      <CGIBox>
        <CGIBoxItem Col={9}>
          <CGIComboSimples
            validadores={{ required: true }}
            Link="empresa"
            id="empresaId"
            label="Empresas"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIButton
            label="Pesquisar"
            att={{
              colorScheme: "blue",
              mt: 8,
            }}
            onClick={form.handleSubmit(CarregarDados)}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIDataTableSimples
            Altura="50vh"
            Colunas={GridColunas}
            Data={Dados.usuarios}
          />
        </CGIBoxItem>
      </CGIBox>
    </CGIModal>
  );
});
