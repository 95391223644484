import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../Context/LoadingContext";
import {
  FormArquivoRemessaType,
  ArquivoRemessaTypes,
  AlterarArquivoRemessaItensTypes,
  AlterarArquivoRemessaItensExcluidosTypes,
} from "../../../Types/Financeiro/ArquivoRemessa/ArquivoRemessaTypes";
import { HttpArquivoRemessa } from "./Http";

type AlterarArquivoRemessaType = {
  CarregarDados: Function;
};

export const AlterarArquivoRemessa = forwardRef(
  (props: AlterarArquivoRemessaType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current.Abrir();
      },
      async Alterar(pArquivoRemessa: any) {
        CGIModalRef.current.Abrir();
        console.log(pArquivoRemessa);
        await HttpArquivoRemessa()
          .RetornarDadosArquivoRemessa(pArquivoRemessa.id)
          .then((e) => {
            console.log(e);
            form.reset(e);
          });
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<ArquivoRemessaTypes>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    // const GridExcluido = useWatch({
    //   control: form.control,
    //   name: "GridPesquisa.Itens",
    // });

    // const GridPrincipal = useWatch({
    //   control: form.control,
    //   name: "Form.Itens",
    // });

    const GridColunas: Array<CGICol<AlterarArquivoRemessaItensTypes>> = [
      {
        Campo: "selecionado",
        Titulo: "",
        Tipo: "CheckBox",
        att: { maxWidth: 80 },
      },
      {
        Campo: "dataVencimento",
        Titulo: "Data Venc.",
        Tipo: "Data",
        att: { maxWidth: 110 },
      },
      {
        Campo: "documento",
        Titulo: "Documento",
        Tipo: "Texto",
        att: { maxWidth: 150 },
      },
      {
        Campo: "nomeCliente",
        Titulo: "Cliente",
        Tipo: "Texto",
        att: { maxWidth: 130 },
      },
      {
        Campo: "valor",
        Titulo: "Valor(R$)",
        Tipo: "Moeda",
        att: { maxWidth: 130 },
      },
      // {
      //   Campo: "id",
      //   Titulo: "",
      //   Tipo: "Menu",
      //   att: {
      //     cellRenderer: (e: ListarArquivoRemessaItensTypes) =>
      //       MenuGridPopover(e, MenuGrid),
      //     pinned: "right",
      //     colId: "action",
      //     editable: false,
      //     maxWidth: 60,
      //   },
      // },
      {
        Campo: "id",
        Titulo: "Mobile",
        Tipo: "Mobile",
        att: {
          flex: 4,
        },
        Composta: [
          {
            valor: "selecionado",
            titulo: "",
          },
          {
            valor: "dataVencimento",
            titulo: "Data Venc.",
          },
          {
            valor: "documento",
            titulo: "Documento",
          },
          {
            valor: "nomeCliente",
            titulo: "Cliente",
          },
          {
            valor: "valor",
            titulo: "Valor(R$)",
          },
        ],
      },
    ];

    const GridExcluidos: Array<
      CGICol<AlterarArquivoRemessaItensExcluidosTypes>
    > = [
      {
        Campo: "dataVencimento",
        Titulo: "Data Venc.",
        Tipo: "Data",
        att: { maxWidth: 110 },
      },
      {
        Campo: "documento",
        Titulo: "Documento",
        Tipo: "Texto",
        att: { maxWidth: 150 },
      },
      {
        Campo: "nomeCliente",
        Titulo: "Cliente",
        Tipo: "Texto",
        att: { maxWidth: 130 },
      },
      {
        Campo: "valor",
        Titulo: "Valor(R$)",
        Tipo: "Moeda",
        att: { maxWidth: 130 },
      },
      // {
      //   Campo: "id",
      //   Titulo: "",
      //   Tipo: "Menu",
      //   att: {
      //     cellRenderer: (e: ListarArquivoRemessaItensTypes) =>
      //       MenuGridPopover(e, MenuGrid),
      //     pinned: "right",
      //     colId: "action",
      //     editable: false,
      //     maxWidth: 60,
      //   },
      // },
      {
        Campo: "id",
        Titulo: "Mobile",
        Tipo: "Mobile",
        att: {
          flex: 4,
        },
        Composta: [
          {
            valor: "dataVencimento",
            titulo: "Data Venc.",
          },
          {
            valor: "documento",
            titulo: "Documento",
          },
          {
            valor: "nomeCliente",
            titulo: "Cliente",
          },
          {
            valor: "valor",
            titulo: "Valor(R$)",
          },
        ],
      },
    ];

    //FINAL  HOOKS

    //INICIO CONST
    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Dados Arquivo Remessa",
        Component: AreaDadosArquivoRemessa(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosArquivoRemessa() {
      return (
        <CGIBox>
          <CGIBoxItem Col={5}>
            <CGIInput<FormArquivoRemessaType>
              id="Form.DadosRegistro.empresaId"
              label="Empresa"
              placeholder=""
              // att={{
              //   isReadOnly: true,
              // }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={5}>
            <CGIInput<FormArquivoRemessaType>
              id="Form.DadosRegistro.localPagamento"
              label="Local Pagto."
              placeholder=""
              // att={{
              //   isReadOnly: true,
              // }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={5}>
            <CGIInput<FormArquivoRemessaType>
              id="Form.DadosRegistro.convenioPortadorId"
              label="Convênio Portador"
              placeholder=""
              // att={{
              //   isReadOnly: true,
              // }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={5}>
            <CGIInput<FormArquivoRemessaType>
              id="Form.DadosRegistro.numeroSequencialDoArquivo"
              label="Número"
              placeholder=""
              // att={{
              //   isReadOnly: true,
              // }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={5}>
            <CGIInput<FormArquivoRemessaType>
              id="Form.DadosRegistro.dataCadastro"
              label="Data"
              placeholder=""
              // att={{
              //   isReadOnly: true,
              // }}
            />
          </CGIBoxItem>
          {/* <CGIBoxItem Col={12}>
          <CGIDataTableSimples
            DisableGetId
            ref={CGIGridPrincipalRef}
            Colunas={GridColunas}
            Data={GridPrincipal}
            Altura={"400px"}
            Pag={10}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIDataTableSimples
            DisableGetId
            ref={CGIGridPrincipalRef}
            Colunas={GridExcluidos}
            Data={GridPrincipal}
            Altura={"400px"}
            Pag={10}
          />
        </CGIBoxItem> */}
        </CGIBox>
      );
    }

    const Confirmar = async (data: FormArquivoRemessaType, event: any) => {
      setLoadingState(true);

      HttpArquivoRemessa()
        .Alterar(data)
        .then((data: any) => {
          ToastSucesso("Registro Alterado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
        })
        .catch((e) => {
          return;
        })
        .finally(() => {
          setLoadingState(false);
        });
    };
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="financeiro/arquivoremessa/alterar"
        ref={CGIModalRef}
        Titulo="Alterar Arquivo Remessa"
        TelaCheia={"full"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
