/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { FormLabel, Box } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useState, useEffect } from "react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { HttpCombo } from "./HttpCombo";
import { ErrorMessage } from "@hookform/error-message";

import React from "react";
export interface ComboTypes<T extends Record<string, unknown>> {
  id: NestedKey<T>;
  Multipla?: boolean;
  Placeholder?: string;
  Option?: string;
  Link: string;
  label: string;
  validadores?: RegisterOptions | undefined;
  disabled?: boolean;
}
/**
 * @Componente CGIComboSimples - ComboSimples  padrão do Sistema
 * @Ref  FormContext
 * @Form  Necessita ser Controlado Por Formulário
 */
export function CGIComboSimples<T extends Record<string, unknown>>(
  props: ComboTypes<T>
) {
  const [Dados, setDados] = useState<Array<any>>([]);

  const {
    control,
    formState: { errors },
  } = useFormContext();

  async function RetornarDadosCombo() {
    var data = await HttpCombo().RetornarDados(props.Link);
    // var a = data.reduce(
    //   (acc: any, item: any) => ({
    //     ...acc,
    //     [item["grupo"]]: [...(acc[item["grupo"]] ?? []), item],
    //   }),
    //   {}
    // );
    // var map = Object.keys(a);
    setDados(data);
    // if (map[0] == "null" || map[0] == null) {
    //   setDados(data);
    // } else {
    //   var b: Array<any> = map.map(e => ({
    //     label: e,
    //     options: a[e],
    //   }));
    //   setDados(b as any);
    // }
  }

  useEffect(() => {
    RetornarDadosCombo();
  }, [props.Link]);
  return (
    <Box w={"100%"}>
      <FormLabel>
        {props.label}
        {props.validadores?.required ? (
          <span style={{ color: "red" }}> *</span>
        ) : (
          ""
        )}
      </FormLabel>
      <Controller
        render={({ field, fieldState, formState }) => {
          return (
            <Select
              formatOptionLabel={(data: any) => {
                return (
                  <span dangerouslySetInnerHTML={{ __html: data.label }} />
                );
              }}
              defaultValue={null}
              isDisabled={props.disabled}
              isClearable={true}
              ref={field.ref}
              isMulti={props.Multipla ? true : undefined}
              options={Dados}
              loadingMessage={e => (e.inputValue = "Carregando...")}
              noOptionsMessage={e => (e.inputValue = "Não Encontrado")}
              placeholder={"Selecione..."}
              closeMenuOnSelect={props.Multipla ? false : true}
              value={
                props.Multipla
                  ? Object.keys(field.value ? field.value : {}).length === 0
                    ? null
                    : Dados.filter((o: any) => {
                        return field.value.split(",").some((s: any) => {
                          return o.value == s;
                        });
                      })
                  : Dados.find((c: any) => c.value == field.value)
                  ? Dados.find((c: any) => c.value == field.value)
                  : null
              }
              onChange={(val: any) => {
                if (!props.Multipla) {
                  return field.onChange(val ? val.value : null);
                }
                return field.onChange(
                  val.map((c: any) => c.value)
                    ? val.map((c: any) => c.value).toString()
                    : null
                );
              }}
            />
          );
        }}
        rules={props.validadores}
        name={String(props.id)}
        control={control}
      />
      <ErrorMessage
        errors={errors}
        name={String(props.id)}
        render={({ message }) => (
          <span style={{ color: "red", fontSize: 12 }}>
            * {message === "" ? message : "Campo obrigatório"}
          </span>
        )}
      />
    </Box>
  );
}
