import React, { useEffect } from "react";
import { Routes, Route, useParams, useLocation } from "react-router-dom";
import { Stimulsoft } from "stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor";
import "stimulsoft-reports-js/Css/stimulsoft.designer.office2013.whiteblue.css";
import "stimulsoft-reports-js/Css/stimulsoft.viewer.office2013.whiteblue.css";
import { HttpRelatorio } from "./Http";

type MontagemRelatorioType = {
  area: string;
  arquivoRelatorio: string;
  link: string;
};
export function AlterarRelatorio() {
  let { id } = useParams();
  const location = useLocation();

  const Render = async (DadosMontagem: MontagemRelatorioType) => {
    try {
      Stimulsoft.Base.StiLicense.Key =
        "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHm4CTJvEYSNYtHZUDxtMJ4tGYX6ab+Qa6YuzWZOkehfzb8JD1" +
        "VUdwCy/DTfZ/hHCQcZ3hdVILw4GOo1fwoJMtAzvFilcNVFwvmCAcC68F9LviITz6sTBBwcLJve1vWh28IzWajbCdlG" +
        "BZf/mbDVi2981N9SQg0i8O84WHIsdaBu04j8XFpYju6MXPKwNWjAP/yRJte7GDEyRc63T73qAreW0EkZjL6svYX/44" +
        "QQ0d4dQysgnlU+exBTq7Uq/4xxi3NFWM1g6KkHOaoFSMLfksP9JAJVP0iiMIHNFUw8eYd0mwyuafUXYO362Q4d09Ul" +
        "o/NukR1XR6eOpM/X8t3eUJ71+sU2d8Q91LN9yxLM0rtI1KmCn4H1Dsgq7e5RB4x8ysgqHaFuR1RLq+qh23EVsr43Ry" +
        "NRQ2oJBXrc2W9toqmrI91Z6LexX8PS0/bu+iTVIVHdaJA62apM5mUxfli8sLneO3ppVtv9sNBhcNnz0e5ir7UayxNC" +
        "hWSWjOfWJbhe44cfTvCvTGtcG5zgWJ3MSAUb9r+BFAT0le9lS+dYc11GWU+WzNn7cxZrefXifqli3i6aidsT3lLEE4" +
        "Ywn7Fnrjk1WDKj+DH9oAa0ocjCtIagCH0CIvNAs9KZCIbQW1wYM8meCYhGJxaQbdrHiZvw3bMB";
    } catch (error) {}

    var options: any;
    const designer = new Stimulsoft.Designer.StiDesigner(
      options,
      "StiDesigner",
      false
    );
    const report = new Stimulsoft.Report.StiReport();

    options = new Stimulsoft.Designer.StiDesignerOptions();
    options.appearance.fullScreenMode = true;

    var caminho =
      "/Reports/" + DadosMontagem.area + "/" + DadosMontagem.arquivoRelatorio;

    var queryString = location.search;
    const params = Object.fromEntries(new URLSearchParams(queryString));
    var Dados = await HttpRelatorio().RetornarDadosExibicao(id, params);
    report.loadFile(caminho);

    report.dictionary.databases.clear();
    report.dictionary.dataSources.clear();
    report.dictionary.variables.clear();
    report.dictionary.dataStore.clear();
    report.dictionary.businessObjects.clear();
    report.dictionary.clear();

    report.regData("Dados", "", {
      Dados: Dados.dados,
      DadosCabecalho: Dados.dadosCabecalho,
    });

    report.dictionary.synchronize();

    designer.report = report;
    designer.renderHtml("designer");
  };
  const RetornarArea = async () => {
    if (id) {
      var Dados =
        await HttpRelatorio().RetornarAreaRelatorio<MontagemRelatorioType>(id);
      Render(Dados);
    }
  };

  useEffect(() => {
    RetornarArea();
  }, []);

  return <div id="designer"></div>;
}
