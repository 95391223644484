import { Link, useCallbackRef } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FormContext } from "../../Context/FormConxtext";
import { ConsultarMovimentacaoEstoqueProduto } from "../../Modules/Almoxarifado/MovEstoque/_ConsultarMovProduto";
import { AlterarRelacaoEntrega } from "../../Modules/Faturamento/RelacaoEntrega/_AlteraRelacaoEntrega";
import { AlterarPedidoLoja } from "../../Modules/Vendas/Pedido/PedidoLoja/_AlterarPedidoLoja";
import { CopiarPedido } from "../../Modules/Vendas/Pedido/_CopiarPedido";
import { Itens } from "../../Types/Home/Login/LoginType";

type ModalControllerTypes = {
  Item: Itens;
  children: React.ReactNode;
};
export function ModalController(props: ModalControllerTypes) {
  const Modal = props.Item.link.split("|");
  const ModalRef = useRef<any>();
  const [Componente, setComponente] = useState<JSX.Element>(<></>);

  useEffect(() => {
    switch (Modal[1]) {
      case "almoxarifado/movestoque/consultar-mov-estoque-produto":
        setComponente(
          <FormContext>
            <ConsultarMovimentacaoEstoqueProduto
              ref={ModalRef}
              CarregarDados={() => {}}
            />
          </FormContext>
        );
        break;

      case "vendas/pedido/incluir":
        setComponente(
          <FormContext>
            <AlterarPedidoLoja ref={ModalRef} CarregarDados={() => {}} />
          </FormContext>
        );
        break;
      case "almoxarifado/pedidocompra/consultar-pedidocompra":
        setComponente(
          <FormContext>
            <ConsultarMovimentacaoEstoqueProduto
              ref={ModalRef}
              CarregarDados={() => {}}
            />
          </FormContext>
        );
        break;
      case "faturamento/relacaoentrega/incluir":
        setComponente(
          <FormContext>
            <AlterarRelacaoEntrega ref={ModalRef} CarregarDados={() => {}} />
          </FormContext>
        );
        break;

      default:
        break;
    }
  }, []);

  return (
    <Link onClick={() => ModalRef.current.Abrir()}>
      {props.children}
      {Componente}
    </Link>
  );
}
