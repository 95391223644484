import { SearchIcon } from "@chakra-ui/icons";
import { IconButton, Box } from "@chakra-ui/react";
import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import LoadingContext from "../../Context/LoadingContext";
import PedLojaContext from "../../Modules/Vendas/Pedido/PedidoLoja/_AlterarPedidoLoja";
import { Temas } from "../../Themes/Temas";
import { PedidoLojaType } from "../../Types/Vendas/Pedido/PedidoLojaType";
import { CGIBox } from "../Box/CGIBox";
import { CGIBoxItem } from "../Box/CGIBoxItens";
import { CGICol } from "../DataTable/DataTable/DataTableTypes";
import { CGIInput } from "../Input/CGIInput";
import { HttpSearchDetail } from "./Http";
import { PesquisarGrid } from "./_PesquisaGrid";

type CGISearchDetailType = {
  link: string;
  placeholder: string;
  AdicionarItensGrid: Function;
  AdicionarGridFormProduto: Function;
};
const CGISearchDetail = forwardRef((props: CGISearchDetailType, ref) => {
  const [dados] = useState<any>();
  const [ColConvert] = useState<Array<CGICol<any>>>([
    {
      Titulo: "id",
      Campo: "id",
      Tipo: "Texto",
      ocultar: true,
    },

    {
      Titulo: "multiplo",
      Campo: "multiplo",
      Tipo: "Texto",
      ocultar: true,
    },

    {
      Titulo: "obsVenda",
      Campo: "obsVenda",
      Tipo: "Texto",
      ocultar: true,
    },
    {
      Titulo: "gradeProdutoId",
      Campo: "gradeProdutoId",
      Tipo: "Texto",
      ocultar: true,
    },

    {
      Titulo: "unidadesConversao",
      Campo: "unidadesConversao",
      Tipo: "Texto",
      ocultar: true,
    },
    {
      Titulo: "ultimaAlteracaoPrecoProduto",
      Campo: "ultimaAlteracaoPrecoProduto",
      Tipo: "Texto",
      ocultar: true,
    },
    {
      Titulo: "ehPrecoPromocional",
      Campo: "ehPrecoPromocional",
      Tipo: "Texto",
      ocultar: true,
    },
    {
      Titulo: "ehPrecoCampanhaVendas",
      Campo: "ehPrecoCampanhaVendas",
      Tipo: "Texto",
      ocultar: true,
    },
    {
      Titulo: "codigoProdutoFabricante",
      Campo: "codigoProdutoFabricante",
      Tipo: "Texto",
      ocultar: true,
    },
    {
      Titulo: "ncm",
      Campo: "ncm",
      Tipo: "Texto",
      ocultar: true,
    },

    {
      Titulo: "campanhaVendasId",
      Campo: "campanhaVendasId",
      Tipo: "Texto",
      ocultar: true,
    },
    {
      Titulo: "nomeCampanhaVendas",
      Campo: "nomeCampanhaVendas",
      Tipo: "Texto",
      ocultar: true,
    },
    {
      Titulo: "Vlr. Unitario",
      Campo: "vlrUnitario",
      Tipo: "Moeda",
      ocultar: true,
    },
    {
      Campo: "codigoProduto",
      Titulo: "Cód. Produto",
      Tipo: "Texto",
      att: { maxWidth: 125 },
    },
    {
      Campo: "descricao",
      Titulo: "Descrição do Produto",
      Tipo: "Texto",
    },
    {
      Titulo: "Un.",
      Campo: "unidade",
      Tipo: "Texto",
      att: { maxWidth: 60 },
    },
    {
      Titulo: "Qtd. Estoque",
      Campo: "estoque",
      ExibirCoresConformeValor: true,
      Tipo: "Numero",
      att: { maxWidth: 125 },
    },
    {
      Titulo: "Pr Camp.",
      Campo: "precoCampanhaVendas",
      Tipo: "Moeda",
      att: { maxWidth: 125 },
    },
    {
      Titulo: "Pr Promo.",
      Campo: "precoPromocional",
      Tipo: "Moeda",
      att: { maxWidth: 125 },
    },
    {
      Titulo: "Pr. Venda",
      Campo: "precoVenda",
      Tipo: "Moeda",
      att: { maxWidth: 125 },
    },

    {
      Titulo: "Qtd.",
      Campo: "quantidade",
      Tipo: "Numero",

      att: {
        editable: true,
        maxWidth: 125,
        cellStyle: {
          backgroundColor: "#e7fff3",
        },
      },
    },
  ]);
  let { setLoadingState } = useContext(LoadingContext);
  const { form } = useContext(PedLojaContext);
  const PesquisarProdutoGridRef = useRef<any>(null);
  useImperativeHandle(ref, () => ({
    RetornarDados() {
      return dados;
    },
    SetFocus: () => {
      form.setFocus("DadosProdutoSelecionado.PesquisaDetalhada");
      form.setValue("DadosProdutoSelecionado.PesquisaDetalhada", "");
    },
  }));

  async function Buscar() {
    let PesquisaDetalhada = form.getValues(
      "DadosProdutoSelecionado.PesquisaDetalhada"
    );
    var arrPesquisa = String(PesquisaDetalhada).split("*");
    if (!PesquisaDetalhada) {
      return;
    }
    let pesquisa = arrPesquisa.length > 1 ? arrPesquisa[1] : arrPesquisa[0];

    setLoadingState(true);

    var Dados = await HttpSearchDetail()
      .RetornarItens(props.link, pesquisa)
      .finally(() => {
        setLoadingState(false);
      });
    if (Dados.length === 0) {
      form.setValue("DadosProdutoSelecionado.PesquisaDetalhada", "");
    } else if (1 >= Dados.length) {
      if (arrPesquisa.length === 2) {
        Dados[0].quantidade = Number(arrPesquisa[0]);
      } else {
        Dados[0].quantidade = 1;
      }
      await props.AdicionarGridFormProduto(Dados[0]);
    } else {
      PesquisarProdutoGridRef.current?.Abrir(Dados);
    }
  }

  return (
    <Box ml={2} py={2}>
      <CGIBox>
        <PesquisarGrid
          ref={PesquisarProdutoGridRef}
          ColunasGrid={ColConvert}
          RetornarDados={async (e: any) => {
            await props.AdicionarItensGrid(e);
            setTimeout(() => {
              form.setFocus("DadosProdutoSelecionado.PesquisaDetalhada");
              form.setValue("DadosProdutoSelecionado.PesquisaDetalhada", "");
            }, 10);
          }}
        />
        <CGIBoxItem Col={12}>
          <CGIBox>
            <CGIBoxItem Col={11}>
              <CGIInput<PedidoLojaType>
                id={"DadosProdutoSelecionado.PesquisaDetalhada"}
                placeholder={props.placeholder}
                att={{
                  onKeyDown: e => {
                    if (e.key === "Enter") {
                      Buscar();
                    }
                  },
                }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={1}>
              <IconButton
                color={Temas.Branco}
                bgColor={Temas.Primaria}
                aria-label="Search database"
                mt={2}
                w={"100%"}
                icon={<SearchIcon />}
                onClick={() => {
                  Buscar();
                }}
                _hover={{
                  cursor: "pointer",
                }}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    </Box>
  );
});
export default CGISearchDetail;
