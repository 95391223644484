export const Temas = {
  Primaria: "blue.700",
  Segundaria: "gray.400",
  Branco: "white",
  BackGround: "#F4F7FE",
  BackGroundLogin: "blackAlpha.200",
  Resumos: "green.600",
};

export const GRIDCONFIG = {
  TamanhoPaginas: 50,
  BufferPaginas: 1,
};

export const TamanhoTelaMobile = "(max-width: 768px)";
