/* eslint-disable react-hooks/exhaustive-deps */
import { ListItem, UnorderedList } from "@chakra-ui/react";
import { useCallback, useContext } from "react";
import { CGIBox } from "../../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../../Components/Box/CGIBoxItens";
import PedLojaContext from "../_AlterarPedidoLoja";

export function PEDLojaAlterta() {
  const { form } = useContext(PedLojaContext);
  const ListaAlerta = useCallback(() => {
    var Observacoes = form.getValues("InformacoesColaborador.observacoes");
    return (
      <UnorderedList>
        {Observacoes ? (
          Observacoes.map((item, index) => {
            return (
              <ListItem key={index}>
                <span style={{ color: item.cor, fontWeight: "bold" }}>
                  {item.mensagem}
                </span>
              </ListItem>
            );
          })
        ) : (
          <></>
        )}
      </UnorderedList>
    );
  }, [form.watch("InformacoesColaborador")]);

  return (
    <CGIBox spacing="0px" titulo="Alertas" popover="Alertas">
      <CGIBoxItem Col={12}>{ListaAlerta()}</CGIBoxItem>
    </CGIBox>
  );
}
