import moment from "moment";
import { CGIHttp } from "../../../Services/Axios";
import { AnaliseCreditoTypes } from "../../../Types/Financeiro/Colaborador/AnaliseCreditoTypes";
import {
  ColaboradorTypes,
  FormColaboradorType,
  ListarColaboradorItensTypes,
  ListarPesquisaColaboradorTypes,
} from "../../../Types/Financeiro/Colaborador/ColaboradorTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpColaborador() {
  const url = "/colaborador";

  async function RetornarDadosGridAnaliseCredito<T>(
    pColaboradorId: number,
    Tipo: string,
    DataIncial?: string,
    DataFinal?: string
  ) {
    return await new CGIHttp().conn
      .get(
        url +
          "/obter-analise-credito-dados/" +
          pColaboradorId +
          "/" +
          Tipo +
          (DataIncial && DataFinal
            ? "/" +
              moment(DataIncial).format("YYYY-MM-DD") +
              "/" +
              moment(DataFinal).format("YYYY-MM-DD")
            : "")
      )
      .then((e) => {
        return e.data.data as T;
      });
  }
  async function RetornarDadosResumoAnaliseCredito<T>(pColaboradorId: number) {
    return await new CGIHttp().conn
      .get(url + "/obter-analise-credito/" + pColaboradorId)
      .then((e) => e.data.data as T);
  }
  async function RetornarDadosExclusao(
    DadosEmpresa: ListarColaboradorItensTypes
  ) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + DadosEmpresa.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }
  async function RetornarColaboradores(
    DadosFiltros: ListarPesquisaColaboradorTypes
  ): Promise<ListarColaboradorItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(url + "/obter-colaboradores", DadosFiltros)
    ).data.data;
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir/" + Dados.id, Dados);
  }
  async function Visualizar(Dados: ColaboradorTypes) {
    return await new CGIHttp().conn.get(url + "/visualizar/" + Dados);
  }
  async function RetornarDadosColaborador(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + `/alterar/${id}`)
    ).data.data) as FormColaboradorType;
  }
  async function Incluir(DadosColaborador: ColaboradorTypes) {
    return await new CGIHttp().conn.post(url + "/incluir", DadosColaborador);
  }
  async function Alterar(DadosColaborador: ColaboradorTypes) {
    return await new CGIHttp().conn.post(url + "/alterar", DadosColaborador);
  }
  return {
    RetornarDadosGridAnaliseCredito,
    RetornarDadosResumoAnaliseCredito,
    RetornarDadosExclusao,
    RetornarColaboradores,
    Excluir,
    Visualizar,
    RetornarDadosColaborador,
    Incluir,
    Alterar,
  };
}
