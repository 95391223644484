//------ TIPO ALTERAR
export const ObjAbastecimentoPadrao: FormAbastecimentoType = {
  Form: {
    DadosRegistro: {
      dataAbastecimentoFinal: "",
      dataAbastecimentoInicial: "",
      dataCadastroInicial: "",
      dataCadastroFinal: "",
      postoId: 0,
      postoExclusaoId: 0,
      statusId: 0,
      numeroNF: "",
      empresaId: 0,
      centroDeCustoId: 0,
      combustivelId: 0,
      equipamentoId: 0,
      colaboradorId: 0,
      estornado: false,
      pendente: false,
      dataAbastecimento: "",
      km: "",
      qtdLitros: 0,
      outrasDespesas: "",
      obs: "",
      obsOutrasDespesas: "",
      planoDeContasId: 0,
      incluirNoCtsPagar: false,
      valorUnitario: 0,
      valorTotal: 0,
    },
  },
};
export type AbastecimentoTypes = {
  dataCadastroInicial: string;
  dataCadastroFinal: string;
  dataAbastecimentoInicial: string;
  dataAbastecimentoFinal: string;
  postoId: number;
  postoExclusaoId: number;
  statusId: number;
  numeroNF: string;
  empresaId: number;
  centroDeCustoId: number;
  combustivelId: number;
  equipamentoId: number;
  colaboradorId: number;
  pendente: boolean;
  estornado: boolean;
  dataAbastecimento: string;
  km: string;
  qtdLitros: number;
  outrasDespesas: string;
  obs: string;
  obsOutrasDespesas: string;
  planoDeContasId: number;
  incluirNoCtsPagar: boolean;
  valorUnitario: number;
  valorTotal: number;
};

export type FormAbastecimentoType = {
  Form: {
    DadosRegistro: AbastecimentoTypes;
  };
};

//------ TIPO ALTERAR

export const ObjListarAbastecimentoPadrao: ListarAbastecimentoTypes = {
  Pesquisa: {
    dataCadastroInicial: "",
    dataCadastroFinal: "",
    dataAbastecimentoInicial: "",
    dataAbastecimentoFinal: "",
    listaPostosId: "",
    listaPostosExclusaoId: "",
    listaStatusId: "",
    numeroNF: "",
    listaEmpresasId: "",
    listaCentrosDeCustoId: "",
    listaCombustiveisId: "",
    listaEquipamentosId: "",
    listaColaboradoresId: "",
    pendentes: false,
    estornados: false,
  },
  Itens: [],
};

export type AbastecimentoPesquisaGridTypes = {
  nomeColaborador?: string;
  dataCadastroInicial?: string;
  dataCadastroFinal?: string;
};

export type ListarAbastecimentoTypes = {
  Pesquisa: ListarPesquisaAbastecimentoTypes;
  Itens: ListarAbastecimentoGridItensTypes[];
};

export type ListarPesquisaAbastecimentoTypes = {
  dataCadastroInicial: string;
  dataCadastroFinal: string;
  dataAbastecimentoInicial: string;
  dataAbastecimentoFinal: string;
  listaPostosId: string;
  listaPostosExclusaoId: string;
  listaStatusId: string;
  numeroNF: string;
  listaCombustiveisId: string;
  pendentes: boolean;
  listaEmpresasId: string;
  listaCentrosDeCustoId: string;
  listaEquipamentosId: string;
  listaColaboradoresId: string;
  estornados: boolean;
};

export type ListarAbastecimentoGridItensTypes = {
  postoId: number;
  nomePosto: string;
  nomeStatus: string;
  numeroNF: number;
  empresaId: number;
  nomeEmpresa: string;
  centroDeCustoId: number;
  nomeCentroDeCusto: string;
  combustivelId: number;
  nomeCombustivel: string;
  equipamentoId: number;
  nomeEquipamento: string;
  colaboradorId: number;
  nomeColaborador: string;
  nomeFrota: string;
  nomeUsuario: string;
  dataAbastecimento: string;
  id: number;
  tipoEstoque: string;
  vlrTotal: number;
  km: number;
  frota: string;
  usuarioId: number;
};

export type AbastecimentoVisualizarTypes = {
  id: number;
  dataCadastro: string;
  dataAbastecimento: string;
  nomePosto: string;
  numeroNF: string;
  nomeEmpresa: string;
  nomeCentroDeCusto: string;
  combustivel: string;
  nomeEquipamento: string;
  nomeColaborador: number;
  qtdLitros: number;
  obs: string;
  valorUnitario: number;
  valorTotal: number;
};
