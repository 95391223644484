import { useEffect, useState } from "react";
import {
  FieldValues,
  FormProvider,
  useForm,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import { CGIBox } from "../../Box/CGIBox";
import { CGIBoxItem } from "../../Box/CGIBoxItens";
import { CGIInput } from "../../Input/CGIInput";
import { HttpCGIResumosComposto } from "../HttpResumo";

type CGIResumoSugestaoPedidoCompratype = {
  watchSugestaoPedidoCompraId: string;
};

function CGIResumoSugestaoPedidoCompra(
  watchSugestaoPedidoCompraId: string,
  formPrincipal: UseFormReturn<FieldValues, any>,
  formResumo: UseFormReturn<FieldValues, any>
) {
  const Tipo = "Sugestão Pedido de Compra";
  const [valueOld, setValueOld] = useState();
  useEffect(() => {
    var value =
      formPrincipal.getValues(watchSugestaoPedidoCompraId) ||
      formResumo.getValues(watchSugestaoPedidoCompraId);

    if (value == null || value === undefined || value === 0 || value === "") {
      return;
    }
    if (valueOld === value) {
      return;
    } else {
      setValueOld(value);
    }
    RetornarDados(value);
  }, [
    formPrincipal.watch(watchSugestaoPedidoCompraId),
    formResumo.watch(watchSugestaoPedidoCompraId),
  ]);

  async function RetornarDados(id: any) {
    var Dados = await HttpCGIResumosComposto().RetornarDados(id, Tipo);

    var key = Object.keys(Dados.registros[0]);
    key.map((e) => {
      if (e === "Id" || e === "id") return "";
      var value = Dados.registros[0][e];
      return formResumo.setValue(e, value);
    });
  }
  return (
    <CGIBox>
      <CGIBoxItem Col={4}>
        <CGIInput
          ocultar={true}
          id={watchSugestaoPedidoCompraId}
          placeholder={""}
        />
        <CGIInput
          id={"SUGId"}
          label={"Id"}
          placeholder={""}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIInput
          id={"SUGNomeUsuario"}
          label={"Usuário"}
          placeholder={""}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIInput
          id={"SUGDataCadastro"}
          label={"Data de Cadastro"}
          placeholder={""}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={6}>
        <CGIInput
          id={"SUGTipoSugestao"}
          label={"Tipo Sugestão"}
          placeholder={""}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"SUGIdentificacao"}
          label={"Identificação"}
          placeholder={""}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"SUGNomeEmpresa"}
          label={"Empresa"}
          placeholder={""}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={2}>
        <CGIInput
          id={"SUGNomeFornecedor"}
          label={"Fornecedor"}
          placeholder={""}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
    </CGIBox>
  );
}

export const ResumoSugestaoPedidoCompra = {
  id: "ResumoSugestaoPedidoCompra",
  titulo: "Dados Sugestão Pedido de Compra",
  componente: CGIResumoSugestaoPedidoCompra,
};
