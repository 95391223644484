import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIRadioButton } from "../../../Components/RadioButton/CGIRadioButton";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../Context/LoadingContext";
import {
  FormMovPortadorType,
  MovPortadorTypes,
} from "../../../Types/Financeiro/MovPortador/MovPortadorTypes";
import { HttpMovPortador } from "./Http";

type IncluirMovPortadorType = {
  CarregarDados: Function;
};

export const IncluirMovPortador = forwardRef(
  (props: IncluirMovPortadorType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        form.reset();
        CGIModalRef.current.Abrir();
      },
      async Incluir(pMovPortador: any) {
        CGIModalRef.current.Abrir();
      },
    }));

    //FINAL  REF

    // const RetornarSaldoPortador = useMemo(() => {

    //   await RetornarSaldoPortador(Portador)

    //   let ValorSaldo =

    //   form.setValue(
    //     "dadosRegistro.saldoAtual",
    //     ObjMovPortadorPadrao.Form.dadosRegistro.saldoAtual
    //   );

    //   return;
    // }, [ObjMovPortadorPadrao.Form.dadosRegistro.saldoAtual]);

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<MovPortadorTypes>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    //FINAL  HOOKS

    //INICIO CONST
    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Dados Mov. Portador",
        Component: AreaDadosMovPortador(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosMovPortador() {
      return (
        <CGIBox>
          <CGIBoxItem Col={6}>
            <CGIComboSimples<FormMovPortadorType>
              id="Form.dadosRegistro.empresaId"
              label="Empresa"
              Link={"empresa"}
              validadores={{
                required: "Empresa obrigatória",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIComboSimples<FormMovPortadorType>
              id="Form.dadosRegistro.portadorId"
              label="Portador"
              Link={"portador"}
              // att={{
              //   onChange: e => RetornarSaldoPortador(),
              // }}
            />
          </CGIBoxItem>
          {/* <>{AlterarValores}</> */}
          <CGIBoxItem Col={3}>
            <CGIRadioButton
              id={"Form.dadosRegistro."}
              row={"row"}
              label={"Tipo de Movimentação"}
              values={[
                {
                  label: "Entrada",
                  valor: "E",
                },
                {
                  label: "Saída",
                  valor: "S",
                },
              ]}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormMovPortadorType>
              id="Form.dadosRegistro.valorMovimento"
              label="Valor Movimentação"
              placeholder={"0"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormMovPortadorType>
              id="Form.dadosRegistro.saldoAtual"
              label="Saldo"
              placeholder={"0"}
              // mascara="Real"
              // att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIDatePicker<FormMovPortadorType>
              id="Form.dadosRegistro.dataCadastro"
              label="Data Mov."
            />
          </CGIBoxItem>
          <CGIBoxItem Col={8}>
            <CGIComboSimples<FormMovPortadorType>
              id="Form.dadosRegistro.formaPagamentoId"
              label="Forma de Pagamento"
              Link={"formapagamento"}
              validadores={{
                required: "Forma de pagamento obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIInput<FormMovPortadorType>
              id="Form.dadosRegistro.obs"
              label="obs"
              placeholder={""}
              att={{ height: 150 }}
              validadores={{
                required: "Observação obrigatória",
              }}
            />
          </CGIBoxItem>
        </CGIBox>
      );
    }

    const Confirmar = async (data: FormMovPortadorType, event: any) => {
      setLoadingState(true);

      HttpMovPortador()
        .Incluir(data)
        .then((response: any) => {
          ToastSucesso("Registro gravado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
          console.log(data);
        })
        .catch((e) => {
          return ToastErro(e);
        })
        .finally(() => {
          setLoadingState(false);
        });
    };
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="financeiro/movportador/incluir"
        ref={CGIModalRef}
        Titulo="Cadastro de Mov. Portador"
        TelaCheia={"4xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
