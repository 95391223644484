import { CGIHttp } from "../../../Services/Axios";
import {
  FormNFSaidaType,
  ListarPesquisaNFSaidaTypes,
  ListarNFSaidaItensTypes,
  NFSaidaPesquisaGridTypes,
  NFSaidaTypes,
} from "../../../Types/Faturamento/NFSaida/NFSaidaTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpNFSaida() {
  const url = "/NFSaida";

  async function RetornarDadosGridPesquisa(
    dados: NFSaidaPesquisaGridTypes
  ): Promise<ListarNFSaidaItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-nf-saida", dados)
      .then((e) => e.data.data);
  }

  async function RetornarNFSaidas(
    DadosFiltros: ListarPesquisaNFSaidaTypes
  ): Promise<ListarNFSaidaItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(url + "/obter-nf-saida", DadosFiltros)
    ).data.data;
  }

  async function RetornarDadosNFSaida(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + `/alterar/${id}`)
    ).data.data) as FormNFSaidaType;
  }

  async function RetornarDadosExclusao(Dados: ListarNFSaidaItensTypes) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + Dados.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }
  async function RetornarDadosVisualizarNFSaida(DadosNFSaida: FormNFSaidaType) {
    return (await (
      await new CGIHttp().conn.get(url + "/visualizar/" + DadosNFSaida.Form)
    ).data.data) as NFSaidaTypes;
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir/" + Dados.id);
  }
  async function Alterar(DadosNFSaida: FormNFSaidaType) {
    return await new CGIHttp().conn.post(url + "/alterar", DadosNFSaida.Form);
  }
  async function Visualizar(DadosNFSaida: FormNFSaidaType) {
    return await new CGIHttp().conn.post(
      url + "/visualizar",
      DadosNFSaida.Form
    );
  }
  async function Incluir(DadosNFSaida: FormNFSaidaType) {
    return await new CGIHttp().conn.post(url + "/incluir", DadosNFSaida.Form);
  }
  async function AlterarNumeroNFSaida(pDados: FormNFSaidaType) {
    return await new CGIHttp().conn.post(
      url + "/alterar-numero-nf-e-serie/",
      pDados.Form.DadosRegistro
    );
  }
  async function AlterarTecnologiaNFSaida(pDados: FormNFSaidaType) {
    return await new CGIHttp().conn.post(
      url + "/alterar-tecnologia-nfsaida",
      pDados.Form.DadosRegistro
    );
  }
  async function AlterarStatusNFSaida(pDados: FormNFSaidaType) {
    return await new CGIHttp().conn.post(
      url + "/alterar-status-nf/",
      pDados.Form.DadosRegistro
    );
  }
  return {
    RetornarNFSaidas,
    RetornarDadosGridPesquisa,
    RetornarDadosExclusao,
    RetornarDadosVisualizarNFSaida,
    RetornarDadosNFSaida,
    Visualizar,
    Excluir,
    Alterar,
    Incluir,
    AlterarNumeroNFSaida,
    AlterarTecnologiaNFSaida,
    AlterarStatusNFSaida,
  };
}
