import { AddIcon } from "@chakra-ui/icons";
import React, { useRef, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarArquivoRetornoItensTypes,
  ListarArquivoRetornoTypes,
  ObjListarArquivoRetornoPadrao,
  VisualizarArquivoRetornoTypes,
} from "../../../Types/Financeiro/ArquivoRetorno/ArquivoRetornoTypes";
import {
  ModalBaseType,
  LegendaTypes,
} from "../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpArquivoRetorno } from "./Http";
import { VisualizarArquivoRetorno } from "./_VisualizarArquivoRetorno";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { ImportarArquivoRetorno } from "./_ImportarArquivoRetorno";

export function ListarArquivoRetorno() {
  const ImportarArquivoRetornoRef = useRef<ModalBaseType>();
  const VisualizarArquivoRetornoRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarArquivoRetornoTypes>({
    defaultValues: ObjListarArquivoRetornoPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarArquivosRetorno = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpArquivoRetorno().RetornarArquivosRetorno(
        DadosFiltros
      );
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarArquivoRetornoItensTypes) => {
      return await HttpArquivoRetorno().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpArquivoRetorno()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Importar Arquivo Retorno",
      Permissao: "financeiro/arquivoretorno/importararquivoretorno",
      OnClick: () => {
        ImportarArquivoRetornoRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "financeiro/arquivoretorno/visualizar",
      OnClick: (e: ListarArquivoRetornoItensTypes) => {
        VisualizarArquivoRetornoRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Imprimir Relatório",
      Permissao: "financeiro/arquivoretorno/alterar",
      OnClick: (e: ListarArquivoRetornoItensTypes) => {
        ImportarArquivoRetornoRef.current?.Alterar(e);
      },
    },
  ];

  const Legendas: LegendaTypes = [
    { nomeStatus: "Baixa Completa", cor: "blue" },
    { nomeStatus: "Baixa Parcial", cor: "LightGray" },
    { nomeStatus: "Nenhum Título", cor: "orange" },
  ];

  const GridColunas: Array<CGICol<ListarArquivoRetornoItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      Status: Legendas,
      att: { maxWidth: 80 },
    },
    {
      Campo: "dataCadastro",
      Titulo: "Data Importação",
      Tipo: "Data",
      att: { maxWidth: 150 },
    },
    {
      Campo: "nomeConvenio",
      Titulo: "Convênio",
      Tipo: "Texto",
      att: { maxWidth: 110 },
    },
    {
      Campo: "nomePortador",
      Titulo: "Portador",
      Tipo: "Texto",
      att: { maxWidth: 130 },
    },
    {
      Campo: "dataCredito",
      Titulo: "Data Crédito",
      Tipo: "Data",
      att: { maxWidth: 130 },
    },
    {
      Campo: "qtdTitulos",
      Titulo: "Qtd. Títulos",
      Tipo: "Numero",
      att: { maxWidth: 150 },
    },
    {
      Campo: "valorRetorno",
      Titulo: "Valor Retorno",
      Tipo: "Moeda",
    },
    {
      Campo: "valorBaixado",
      Titulo: "Valor Baixado",
      Tipo: "Texto",
      att: { maxWidth: 200 },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarArquivoRetornoItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          valor: "nomeStatus",
          titulo: "",
        },
        {
          valor: "dataCadastro",
          titulo: "Data Importação",
        },
        {
          valor: "nomeConvenio",
          titulo: "Convênio",
        },
        {
          valor: "nomePortador",
          titulo: "Portador",
        },
        {
          valor: "dataCredito",
          titulo: "Data Crédito",
        },
        {
          valor: "qtdTitulos",
          titulo: "Qtd. Títulos",
        },
        {
          valor: "valorRetorno",
          titulo: "Valor Retorno",
        },
        {
          valor: "valorBaixado",
          titulo: "Valor Baixado",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Arquivo Retorno"
          CarregarDados={RetornarArquivosRetorno}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <ImportarArquivoRetorno
          CarregarDados={methods.handleSubmit(RetornarArquivosRetorno)}
          ref={ImportarArquivoRetornoRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarArquivoRetorno
          CarregarDados={methods.handleSubmit(RetornarArquivosRetorno)}
          ref={VisualizarArquivoRetornoRef}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIDatePicker<ListarArquivoRetornoTypes>
            id="Pesquisa.dataCadastroInicial"
            label="Data Cadastro - Inicial"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIDatePicker<ListarArquivoRetornoTypes>
            id="Pesquisa.dataCadastroFinal"
            label="Data Cadastro - Final"
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="financeiro/arquivoretorno/listar"
      Titulo="Arquivo Retorno"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarArquivosRetorno)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
