import { Tag, TagLabel, Box, Text } from "@chakra-ui/react";
import { useCallback } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { CGIBox } from "../../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../../Components/Box/CGIBoxItens";
import { CGIDataTableSimples } from "../../../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../../../Components/DataTable/DataTable/DataTableTypes";
import {
  AnaliseCreditoPedidoItensType,
  AnaliseCreditoTypes,
} from "../../../../../Types/Financeiro/Colaborador/AnaliseCreditoTypes";
import { LegendaTypes } from "../../../../../Types/Modal/ModalBaseType";

export const GridPedido = (form: UseFormReturn<AnaliseCreditoTypes, any>) => {
  const GridItens = useWatch({
    control: form.control,
    name: "Grids.Pedido.Itens",
  });
  const Legendas: LegendaTypes = [
    { nomeStatus: "Aberto", cor: "green" },
    { nomeStatus: "Separado", cor: "lightBlue" },
    { nomeStatus: "Conferido", cor: "wheat" },
    { nomeStatus: "Faturado", cor: "darkblue" },
    { nomeStatus: "Entregue", cor: "cadetBlue" },
    { nomeStatus: "Romaneio", cor: "gray" },
    { nomeStatus: "Devolução Romaneio", cor: "lightGray" },
  ];
  const GridCol: Array<CGICol<AnaliseCreditoPedidoItensType>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      Status: Legendas,
      att: {
        maxWidth: 60,
        filter: false,
      },
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Numero",
      ocultar: true,
    },
    {
      Campo: "identificacao",
      Titulo: "Pedido",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 160,
      },
    },
    {
      Campo: "id",
      Titulo: "Vendedor/ Transp.",
      Tipo: "Composta",
      Composta: [
        {
          valor: "nomeVendedor",
          titulo: "Vendedor",
        },
      ],
    },
    {
      Campo: "qtdItens",
      Titulo: "Qtd Itens",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 250,
      },
    },
    {
      Campo: "id",
      Titulo: "Nat. Op. /Cond. Pag.",
      Tipo: "Composta",
      Composta: [
        {
          valor: "nomeNaturezaOperacao",
          titulo: "Nat. Op.",
        },
        {
          valor: "nomeCondicaoPagamento",
          titulo: "Cond. Pag.",
        },
      ],
      att: {
        maxWidth: 250,
      },
    },
    {
      Campo: "totalPedidoFinal",
      Titulo: "Total Pedido",
      Tipo: "Moeda",
      Ordernar: true,
      att: {
        maxWidth: 150,
      },
    },
  ];

  return (
    <CGIBox>
      <CGIBoxItem Col={12}>
        <Text fontWeight={"bold"} color={"green"}>
          Últimos 10 Pedidos
        </Text>
      </CGIBoxItem>
      <CGIBoxItem Col={12}>
        <CGIDataTableSimples
          DisableGetId
          Altura="400px"
          Colunas={GridCol}
          Data={GridItens}
        />
        <Box p={2}>
          {Legendas.map((e, index) => {
            return (
              <Tag
                mx={2}
                size={"md"}
                key={index}
                borderRadius="full"
                variant="solid"
                bgColor={e.cor}
              >
                <TagLabel color={"gray.100"}>{e.nomeStatus}</TagLabel>
              </Tag>
            );
          })}
        </Box>
      </CGIBoxItem>
    </CGIBox>
  );
};
