import { forwardRef, useContext, useImperativeHandle, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIAlertDialog } from "../AlertDialog/CGIAlertDialog";
import { CGIBoxItem } from "../Box/CGIBoxItens";
import { CGIInput } from "../Input/CGIInput";
import { CGITextArea } from "../TextArea/CGITextArea";
import { HttpHistorico } from "./HttpHistorico";

type FormIncluirHistorico = {
  id: number;
  origem: string;
  Titulo: string;
  Obs: string;
};
type CGIIncluirHistoricoProps = {
  registroId: number;
  origem: string;
};

export const CGIIncluirHistorico = forwardRef(
  (props: CGIIncluirHistoricoProps, ref) => {
    const CGIAltertDiologRef = useRef() as any;
    const Form = useFormContext<FormIncluirHistorico>();
    useImperativeHandle(ref, () => ({
      Abrir(e: any) {
        console.log("CGIIncluirHistorico", e, props);
        Form.reset({
          id: props.registroId,
          origem: props.origem,
          Titulo: "",
          Obs: "",
        });
        CGIAltertDiologRef.current.Abrir();
      },
      Fechar(e: any) {
        CGIAltertDiologRef.current.Fechar();
      },
    }));

    const Confirmar = async () => {
      var data = Form.getValues();
      console.log("Confirmar", data);
      await HttpHistorico().Incluir(data);
      CGIAltertDiologRef.current.Fechar();
    };

    return (
      <CGIAlertDialog
        ref={CGIAltertDiologRef}
        confirmar={Confirmar}
        titulo={"Inlcuir Novo Historico - " + props.origem.toUpperCase()}
      >
        <CGIBoxItem Col={12}>
          <CGIInput<FormIncluirHistorico>
            validadores={{
              required: "Campo Obrigatório",
            }}
            label="Título"
            id={"Titulo"}
            placeholder={"Título"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGITextArea<FormIncluirHistorico> id={"Obs"} label={"Observação"} />
        </CGIBoxItem>
      </CGIAlertDialog>
    );
  }
);
