import { Flex } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useForm } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import {
  FormConfigCFOPType,
  ObjConfigCFOPPadrao,
} from "../../../Types/Sistema/ConfigCFOP/ConfigCFOPTypes";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { HttpConfigCFOP } from "./Http";
import { CGINumberInput } from "../../../Components/NumberInput/CGINumberInput";

export const AlterarConfigCFOP = forwardRef(
  (
    props: {
      CarregarDados: () => void;
    },
    ref
  ) => {
    //INICIO REF
    const form = useFormContext<FormConfigCFOPType>();
    const CGIModalRef = useRef<ModalBaseType>();
    useImperativeHandle(ref, () => ({
      Abrir() {
        form.reset(ObjConfigCFOPPadrao);
        CGIModalRef.current?.Abrir();
      },
      Alterar(pConfigCFOPId: number) {
        CGIModalRef.current?.Abrir();
      },
    }));

    return (
      <CGIModal
        id="sistema/classificacao/alterar"
        ref={CGIModalRef}
        Titulo="ConfigCFOP"
        TelaCheia={"6xl"}
        CarregarDados={() => {}}
        Confirmar={(e: any) => {}}
        Fechar={() => {
          form.reset(ObjConfigCFOPPadrao);
        }}
      >
        <CGIBox>
          <CGIBoxItem Col={3}>
            <CGIInput<FormConfigCFOPType>
              id="Form.DadosRegistro.nome"
              label="Nome"
              placeholder=""
              validadores={{
                required: "Campo obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormConfigCFOPType>
              id="Form.DadosRegistro.empresaId"
              label="Empresa"
              Link={"empresa"}
              validadores={{
                required: "Campo obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormConfigCFOPType>
              id="Form.DadosRegistro.cstId"
              label="CST Produto"
              Link={"cst"}
              validadores={{
                required: "Campo obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormConfigCFOPType>
              id="Form.DadosRegistro.percReducaoICMS"
              label="% Redução ICMS"
              placeholder=""
              validadores={{
                required: "Campo obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormConfigCFOPType>
              label="Perc. ICMS"
              id="Form.DadosRegistro.alterarPercICMS"
              placeholder="0"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIInput<FormConfigCFOPType>
              label="Perc. Dif. ICMS"
              id="Form.DadosRegistro.percDiferimentoICMS"
              placeholder="0,00"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIInput<FormConfigCFOPType>
              label="Perc. Dif. FCP"
              id="Form.DadosRegistro.percDiferimentoFCP"
              placeholder="0,00"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormConfigCFOPType>
              id="Form.DadosRegistro.ativo"
              label="Ativo"
              tooltip=""
              att={{
                style: { marginTop: 30 },
              }}
              attLabel={{
                style: { marginTop: 30 },
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIInput<FormConfigCFOPType>
              label="Texto Padrão"
              id="Form.DadosRegistro.textoPadrao"
              placeholder=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormConfigCFOPType>
              label="Calcular ICMS"
              id="Form.DadosRegistro.calcularICMS"
              tooltip=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormConfigCFOPType>
              label="Calcular ICMS ST"
              id="Form.DadosRegistro.calcularICMSST"
              tooltip=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormConfigCFOPType>
              label="Calcular FCP"
              id="Form.DadosRegistro.calcularFCP"
              tooltip=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormConfigCFOPType>
              label="Calcular FCP ST"
              id="Form.DadosRegistro.calcularFCPST"
              tooltip=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormConfigCFOPType>
              label="Calcular FCP ST Ret"
              id="Form.DadosRegistro.calcularFCPSTRet"
              tooltip=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormConfigCFOPType>
              label="Calcular IPI"
              id="Form.DadosRegistro.calcularIPI"
              tooltip=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormConfigCFOPType>
              label="Calcular PIS"
              id="Form.DadosRegistro.calcularPIS"
              tooltip=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormConfigCFOPType>
              label="Calcular COFINS"
              id="Form.DadosRegistro.calcularCOFINS"
              tooltip=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormConfigCFOPType>
              label="Movimentar Estoque"
              id="Form.DadosRegistro.movimentarEstoque"
              tooltip=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormConfigCFOPType>
              label="Gerir Financeiro"
              id="Form.DadosRegistro.gerarFinanceiro"
              tooltip=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormConfigCFOPType>
              label="Mov. Pedido(ROMANEIO)"
              id="Form.DadosRegistro.efetuarMovNoPedido"
              tooltip=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormConfigCFOPType>
              label="Calcular Crédito ICMS (SN)"
              id="Form.DadosRegistro.calcularICMSSN"
              tooltip=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormConfigCFOPType>
              label="Calcular Difal"
              id="Form.DadosRegistro.calcularDifal"
              tooltip=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormConfigCFOPType>
              label="Alterar Perc. ICMS"
              id="Form.DadosRegistro.alterarPercICMS"
              tooltip=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormConfigCFOPType>
              label="Remover IPI Base de Calculo ICMS"
              id="Form.DadosRegistro.removerIPIBaseCalculoICMS"
              tooltip=""
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
    //FINAL JSX
  }
);
