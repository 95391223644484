import axios from "axios";
import { CGIHttp } from "../../../Services/Axios";
import {
  OrdemProducaoVisualizarTypes,
  ListarPesquisaOrdemProducaoTypes,
  ListarOrdemProducaoItensTypes,
  OrdemProducaoPesquisaGridTypes,
} from "../../../Types/Producao/OrdemProducao/OrdemProducao";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

const url = "/OrdemProducao";

export function HttpOrdemProducao() {
  // async function RetornarOrdemProducao(DadosFiltros: any) {
  //   return await new CGIHttp().conn.post(
  //     url + "/obter-ordens-producao",
  //     DadosFiltros
  //   );
  // }

  async function RetornarDadosGridPesquisa(
    dados: OrdemProducaoPesquisaGridTypes
  ): Promise<ListarOrdemProducaoItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-ordens-producao", dados)
      .then((e) => e.data.data);
  }

  async function RetornarOrdemProducao(
    DadosFiltros: ListarPesquisaOrdemProducaoTypes
  ): Promise<ListarOrdemProducaoItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(
        url + "/obter-ordens-producao",
        DadosFiltros
      )
    ).data.data;
  }

  async function Listar() {
    return await (
      await new CGIHttp().conn.get(url + "/listar")
    ).data.data;
  }
  async function Visualizar() {
    return await (
      await new CGIHttp().conn.get(url + "/visualizar")
    ).data.data;
  }

  async function RetornarDadosVisualizarOrdemProducao(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + "/visualizar/" + id)
    ).data.data) as OrdemProducaoVisualizarTypes;
  }
  async function RetornarDadosExclusao(
    DadosEmpresa: ListarOrdemProducaoItensTypes
  ) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + DadosEmpresa.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(
      url + "/excluir-ordem-producao/" + Dados.id,
      Dados
    );
  }

  return {
    RetornarDadosVisualizarOrdemProducao,
    RetornarDadosGridPesquisa,
    RetornarOrdemProducao,
    RetornarDadosExclusao,
    Listar,
    Excluir,
    Visualizar,
  };
}
