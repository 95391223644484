import { CGIHttp } from "../../../../Services/Axios";
import {
  FormAssinaturaEletronicaZapSignType,
  ListarAssinaturaEletronicaZapSignItensTypes,
  ListarPesquisaAssinaturaEletronicaZapSignTypes,
} from "../../../../Types/Sistema/Classificacao/AssinaturaEletronicaZapSign/AssinaturaEletronicaZapSignTypes";

const url = "/classificacao";

export function HttpAssinaturaEletronicaZapSign() {
  async function RetornarAssinaturaEletronicaZapSign(
    DadosFiltros: ListarPesquisaAssinaturaEletronicaZapSignTypes
  ) {
    return (
      await new CGIHttp().conn.post(
        url + "/obter-assinatura-eletronica-zapsign/",
        DadosFiltros
      )
    ).data.data;
  }

  async function Listar() {
    return await (
      await new CGIHttp().conn.get(url + "/listar/211")
    ).data.data;
  }

  async function RetornarDadosAssinaturaEletronicaZapSign(id: number) {
    return await new CGIHttp().conn.get(
      url + `/alterar-assinatura-eletronica-zapsign/${id}`
    );
  }

  async function Incluir(Dados: FormAssinaturaEletronicaZapSignType) {
    return await new CGIHttp().conn.post(
      url + "/incluir-assinatura-eletronica-zapsign",
      Dados.Form
    );
  }
  async function Alterar(Dados: FormAssinaturaEletronicaZapSignType) {
    return await new CGIHttp().conn.post(
      url + "/alterar-assinatura-eletronica-zapsign",
      Dados.Form
    );
  }
  async function RetornarDadosExclusao(
    Dados: ListarAssinaturaEletronicaZapSignItensTypes
  ) {
    return await new CGIHttp().conn.get(
      url + "/excluir-assinatura-eletronica-zapsign/" + Dados.id
    );
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(
      url + "/excluir-assinatura-eletronica-zapsign",
      Dados
    );
  }

  return {
    RetornarAssinaturaEletronicaZapSign,
    RetornarDadosAssinaturaEletronicaZapSign,
    RetornarDadosExclusao,
    Incluir,
    Listar,
    Alterar,
    Excluir,
  };
}
