import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIFileInput } from "../../../Components/FileInput/CGIFileInput";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../Context/LoadingContext";
import {
  FormArquivoRetornoType,
  ArquivoRetornoTypes,
  ImportarArquivoRetornoTypes,
} from "../../../Types/Financeiro/ArquivoRetorno/ArquivoRetornoTypes";
import { HttpArquivoRetorno } from "./Http";

type ImportarArquivoRetornoType = {
  CarregarDados: Function;
};

export const ImportarArquivoRetorno = forwardRef(
  (props: ImportarArquivoRetornoType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current.Abrir();
      },
      async Importar(pArquivoRetorno: any) {
        CGIModalRef.current.Abrir();
        console.log(pArquivoRetorno);
        await HttpArquivoRetorno()
          .RetornarDadosArquivoRetorno(pArquivoRetorno.id)
          .then((e) => {
            console.log(e);
            form.reset(e);
          });
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<ArquivoRetornoTypes>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    //FINAL  HOOKS

    //INICIO CONST
    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Dados Arquivo Retorno",
        Component: AreaDadosArquivoRetorno(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosArquivoRetorno() {
      return (
        <CGIBox>
          <CGIBoxItem Col={6}>
            <CGIFileInput<ImportarArquivoRetornoTypes>
              id="arquivoId"
              label="Arquivo"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIComboSimples<ImportarArquivoRetornoTypes>
              id="convenioPortadorId"
              label="Convênio Portador"
              Link={"convenioportador"}
            />
          </CGIBoxItem>
        </CGIBox>
      );
    }

    const Confirmar = async (data: FormArquivoRetornoType, event: any) => {
      setLoadingState(true);

      HttpArquivoRetorno()
        .Importar(data)
        .then((data: any) => {
          ToastSucesso("Registro Alterado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
        })
        .catch((e) => {
          return;
        })
        .finally(() => {
          setLoadingState(false);
        });
    };
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="financeiro/arquivoretorno/importararquivoretorno"
        ref={CGIModalRef}
        Titulo="Arquivo Retorno"
        TelaCheia={"5xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
