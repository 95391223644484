import { NumericFormat } from "react-number-format";

import {
  Box,
  Checkbox,
  CheckboxProps,
  Flex,
  FormLabel,
  FormLabelProps,
  Input,
  InputProps,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { Temas } from "../../Themes/Temas";

interface CGINumberInputType<T extends Record<string, unknown>> {
  id: NestedKey<T>;
  label: string;
  prefix?: true | string;
  qtdCasasDecimais?: number;
  permitirNegativo?: boolean;
  validadores?: RegisterOptions | undefined;
  att?: InputProps;
  attLabel?: FormLabelProps;
  alignment?: "column" | "row";
}

export function CGINumberInput<T extends Record<string, unknown>>(
  props: CGINumberInputType<T>
) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const currencyFormatter = (formatted_value: any) => {
    if (!Number(formatted_value)) return "R$ 0,00";
    const br = { style: "currency", currency: "BRL" };
    return new Intl.NumberFormat("pt-BR", br).format(formatted_value);
  };

  const InputAtt = { ...props.att } as any;
  return (
    <>
      <Flex flexDir={props.alignment === "row" ? "row" : "column"}>
        <Flex flex={1}>
          <FormLabel {...props.attLabel} htmlFor={props.id}>
            <Flex>
              <Text
                dangerouslySetInnerHTML={{
                  __html: props.label ? props.label : "",
                }}
              />

              {props.validadores?.required ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </Flex>
          </FormLabel>
        </Flex>
        <Flex flex={1}>
          <Controller
            render={({ field }) => {
              return (
                <NumericFormat
                  onValueChange={e => {
                    field.onChange(e.floatValue);
                  }}
                  getInputRef={field.ref}
                  value={field.value}
                  format={currencyFormatter}
                  prefix={
                    typeof props.prefix === "string"
                      ? `${props.prefix} `
                      : "R$ "
                  }
                  decimalSeparator=","
                  thousandSeparator="."
                  decimalScale={
                    props.qtdCasasDecimais ? props.qtdCasasDecimais : 2
                  }
                  customInput={Input}
                  allowNegative={props.permitirNegativo}
                  {...InputAtt}
                />
              );
            }}
            rules={props.validadores}
            name={props.id}
            control={control}
          />
        </Flex>
      </Flex>
      <ErrorMessage
        errors={errors}
        name={props.id}
        render={error => {
          return (
            <span style={{ color: "red", fontSize: 12 }}>
              {error.message ? `* ${error.message}` : "* Campo obrigatório"}
            </span>
          );
        }}
      />
    </>
  );
}
