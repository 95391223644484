import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../../Components/CheckBox/CGICheckBox";
import { useCGIToast } from "../../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIModal } from "../../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../../Context/LoadingContext";
import { FormTipoProdutoType } from "../../../../Types/Sistema/Classificacao/TipoProduto/TipoProdutoTypes";
import { HttpTipoProduto } from "./Http";

type AlterarTipoProdutoType = {
  CarregarDados: Function;
};

export const AlterarTipoProduto = forwardRef(
  (props: AlterarTipoProdutoType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current.Abrir();
      },
      Alterar(pEmpresa: any) {
        CGIModalRef.current.Abrir();

        HttpTipoProduto()
          .RetornarDadosTipoProduto(pEmpresa.id)
          .then((e) => {
            form.reset(e.data.data);
          });
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<FormTipoProdutoType>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    //FINAL  HOOKS

    //INICIO CONST

    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Tipo Produto",
        Component: AreaDadosTipoProduto(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosTipoProduto() {
      return (
        <CGIBox>
          <CGIBoxItem Col={4}>
            <CGIInput<FormTipoProdutoType>
              id="Form.DadosRegistro.nome"
              label="Nome"
              placeholder=""
              validadores={{
                required: "Nome obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormTipoProdutoType>
              id="Form.DadosRegistro.CFOPForaEstado"
              label="CFOPs Fora do Estado"
              placeholder=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormTipoProdutoType>
              id="Form.DadosRegistro.CFOPDentroEstado"
              label="CFOPs Dentro do Estado"
              placeholder=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormTipoProdutoType>
              id="Form.DadosRegistro.ativo"
              label="Ativo"
              tooltip=""
              validadores={{
                required: "Nome obrigatório",
              }}
              att={{
                style: { marginTop: 30 },
              }}
              attLabel={{
                style: { marginTop: 30 },
              }}
            />
          </CGIBoxItem>
        </CGIBox>
      );
    }

    const Confirmar = async (data: FormTipoProdutoType, event: any) => {
      setLoadingState(true);
      let Dados = form.getValues();

      HttpTipoProduto()
        .Alterar(data)
        .then((data: any) => {
          ToastSucesso("Registro Alterado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
        })
        .catch((e) => {
          return;
        })
        .finally(() => {
          setLoadingState(false);
        });
    };
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="sistema/classificacao/alterartipoproduto"
        ref={CGIModalRef}
        Titulo="Tipo Produto"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
