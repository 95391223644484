export const ObjPedidoEntradaPadrao: FormPedidoEntradaType = {
  Form: {
    DadosRegistro: {
      id: 0,
      usuarioId: 0,
      empresaId: 0,
      dataCadastro: "",
      fornecedorId: 0,
      dataEmissao: "",
      condicaoPagamentoId: 0,
      movimentarEstoque: false,
      gerarFinanceiro: false,
      qtdItens: 0,
      totalPedido: 0,
      statusId: 0,
      centroDeCustoId: 0,
      planoDeContasId: 0,
      frete: 0,
      identificacao: "",
      numeroDocumento: "",
    },
  },
};
export type PedidoEntradaTypes = {
  DadosRegistro: {
    id: number;
    usuarioId: number;
    empresaId: number;
    dataCadastro: string | null;
    fornecedorId: number;
    dataEmissao: string | null;
    condicaoPagamentoId: number;
    movimentarEstoque: boolean;
    gerarFinanceiro: boolean;
    qtdItens: number;
    totalPedido: number;
    statusId: number;
    centroDeCustoId: number;
    planoDeContasId: number;
    frete: number;
    identificacao: string;
    numeroDocumento: string;
  };
};
export type FormPedidoEntradaType = {
  Form: PedidoEntradaTypes;
};

export const ObjListarPedidoEntradaPadrao: ListarPedidoEntradaTypes = {
  Pesquisa: {
    dataInicialInclusao: "",
    dataFinalInclusao: "",
    valorDe: 0,
    valorAte: 0,
    listaEmpresasId: "",
    listaFornecedoresId: "",
    listaCondicaoPagamentoId: "",
    listaStatusId: "",
    numeroDocumento: "",
    identificacao: "",
  },
  Itens: [],
};

export type PedidoEntradaPesquisaGridTypes = {
  ncm?: string;
};

export type ListarPedidoEntradaTypes = {
  Pesquisa: ListarPesquisaPedidoEntradaTypes;
  Itens: ListarPedidoEntradaItensTypes[];
};

export type ListarPesquisaPedidoEntradaTypes = {
  dataInicialInclusao: string;
  dataFinalInclusao: string;
  valorDe: number;
  valorAte: number;
  listaEmpresasId: string;
  listaFornecedoresId: string;
  listaCondicaoPagamentoId: string;
  listaStatusId: string;
  numeroDocumento: string;
  identificacao: string;
};

export type ListarPedidoEntradaItensTypes = {
  id: number;
  dataCadastro: string;
  identificacao: string;
  qtdItens: number;
  qtdProdutos: number;
  valorTotal: number;
  nomeEmpresa: string;
  nomeCondicaoPagamento: string;
  nomeFornecedor: string;
  nomeStatus: string;
  totalPedido: number;
  frete: number;
  quantidade: number;
  numeroDocumento: string;
  valorTotalComFrete: number;
};
