/* eslint-disable react-hooks/exhaustive-deps */
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  TableContainer,
  FormLabel,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { HttpCGITableResumo } from "./HttpCGITableResumo";
import { FormatarReal } from "../Prototypes/Formatter";
import { Temas } from "../../Themes/Temas";

export type CGITableResumoColunasItensTypes = {
  nome: string;
  valor: number;
  nomeCor: string;
  legenda: string;
  corFonte: string;
};
export type CGITableResumoDadosTypes = {
  titulo: string;
  itens: Array<CGITableResumoColunasItensTypes>;
};
type CGITableResumoCabecalhoTypes = {
  Titulo: string;
  ehNumerico: boolean;
};

type CGITableResumoTypes = {
  cabecalho: Array<CGITableResumoCabecalhoTypes>;
  Dados: CGITableResumoDadosTypes;
};

const Obj: CGITableResumoTypes = {
  cabecalho: [],
  Dados: {
    titulo: "",
    itens: [],
  },
};

/**
 * @Componente CGITableResumo - Componente responsavel  pelos Tabela de Resumos
 * @Ref  CGITableResumo
 */
export function CGITableResumo(props: { link: string; titulo: string }) {
  const [Dados, setDados] = useState<CGITableResumoTypes>(Obj);

  useEffect(() => {
    RetornarDados().then(e => {
      setDados({
        cabecalho: [
          { Titulo: "Título", ehNumerico: false },
          { Titulo: "Valores", ehNumerico: true },
        ],
        Dados: e,
      });
    });
  }, []);

  async function RetornarDados() {
    var data = await HttpCGITableResumo().RetornarDados(props.link);
    console.log("Resumo", data);
    return data;
  }

  return (
    <Box bg={Temas.Branco} p={2} borderRadius={"md"} shadow={"lg"}>
      <FormLabel>{Dados.Dados.titulo}</FormLabel>
      <TableContainer>
        <Table size="sm" variant="striped">
          <Thead>
            <Tr>
              {Dados.cabecalho.map((e, index) => (
                <Th key={index} isNumeric={e.ehNumerico}>
                  {e.Titulo}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {Dados.Dados.itens.map((e, index) => (
              <Tr key={index}>
                <Td>{e.nome}</Td>
                <Td isNumeric>{FormatarReal(e.valor)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
