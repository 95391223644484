import { CGIHttp } from "../../Services/Axios";
const url = "home";
export function HttpGrafico() {
  async function RetornarDados<T>(Tipo: string, Chave: string) {
    let Obj = await new CGIHttp().conn.post(url + "/consultar-dados-grafico/", {
      tipo: Tipo,
      chave: Chave,
    });
    return Obj?.data.data as T;
  }
  return {
    RetornarDados,
  };
}
