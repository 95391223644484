import { Flex, Tooltip, useMediaQuery } from "@chakra-ui/react";
import { useState, useContext, useRef, useEffect } from "react";
import { FormContext } from "../../Context/FormConxtext";
import { TamanhoTelaMobile, Temas } from "../../Themes/Temas";
import { CGIAlertDialog } from "../AlertDialog/CGIAlertDialog";
import { CGIBoxItem } from "../Box/CGIBoxItens";
import { useCGIToast } from "../Hook/useCGIToast";
import { CGIInput } from "../Input/CGIInput";
import { ConvertIcones } from "../Util/ConvertIcons";
import { RetornarSeTelaEhFavoritaPeloLink } from "../Util/Permissoes";
import { HttpForm } from "./HttpForm";

/**
 * @Componente CGIFormFavorito - Componente usado no CGITituloJanela
 * @Ref  CGITituloJanela
 */
export function CGIFormFavorito(props: { id: string }) {
  const [marcado, setMarcado] = useState(false);
  const [Titulo, setTitulo] = useState("");
  const [isMobile] = useMediaQuery(TamanhoTelaMobile);

  const CGIAlertDialogRef = useRef() as any;
  const { ToastSucesso } = useCGIToast();

  useEffect(() => {
    var EhFavorito = RetornarSeTelaEhFavoritaPeloLink(props.id);
    setMarcado(EhFavorito);
    if (EhFavorito) {
      setTitulo("Deseja excluir esta tela como favorito ?");
    } else {
      setTitulo("Deseja incluir esta tela Como favorito ?");
    }
  }, [props.id]);

  async function Confirmar(data: any) {
    await HttpForm()
      .DefinirMenuFavorito(data)
      .then(e => {
        CGIAlertDialogRef.current.Fechar();
        if (marcado) {
          ToastSucesso("Tela foi excluida dos Favoritos", "Favoritos");
        } else {
          ToastSucesso("Tela foi incluida nos Favoritos", "Favoritos");
        }
      });
  }

  return (
    <>
      <Flex
        _hover={{
          cursor: "pointer",
        }}
        onClick={() => {
          CGIAlertDialogRef.current.Abrir();
        }}
        px={2}
        justify={"end"}
        align={"center"}
        color={marcado ? "yellow.500" : "white"}
      >
        <Tooltip
          isDisabled={isMobile}
          hasArrow
          label="Adiconar Tela aos Favoritos"
          shouldWrapChildren
          mt="3"
          color={Temas.Branco}
        >
          {ConvertIcones({
            icones: marcado ? "favorito marcado" : "favorito desmarcado",
          })}
        </Tooltip>
      </Flex>
      <FormContext>
        <CGIAlertDialog
          confirmar={(e: any) => {
            Confirmar({ ...e, link: props.id });
          }}
          titulo={Titulo}
          ref={CGIAlertDialogRef}
        >
          <CGIBoxItem Col={12}>
            {marcado ? (
              <></>
            ) : (
              <CGIInput
                placeholder="Favorito"
                id="nome"
                label="Favorito"
                validadores={{
                  required: "Campo obrigatório",
                  minLength: {
                    message: "mínimo de [ 5 ] caracteres",
                    value: 5,
                  },
                  maxLength: {
                    message: "máximo de [ 30 ] caracteres",
                    value: 30,
                  },
                }}
              />
            )}
          </CGIBoxItem>
        </CGIAlertDialog>
      </FormContext>
    </>
  );
}
