import { AddIcon } from "@chakra-ui/icons";
import { Box } from "framer-motion";
import { useState, useRef, useCallback, useEffect, useContext } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import { HttpCtsPagar } from "./Http";
import { CopiarCtsPagar } from "./_CopiarCtsPagar";
import { CGIHistorico } from "../../../Components/Historico/CGIHistorico";
import { FormContext } from "../../../Context/FormConxtext";
import {
  ModalBaseType,
  LegendaTypes,
} from "../../../Types/Modal/ModalBaseType";
import {
  ListarCtsPagarTypes,
  ObjListarCtsPagarPadrao,
  ListarCtsPagarItensTypes,
  ListarPesquisaCtsPagarTypes,
} from "../../../Types/Financeiro/ContasPagar/CtsPagar";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { DesbloquearCtsPagar } from "./DesbloquearCtsPagar";
import { AlterarDataCadastroCtsPagar } from "./AlterarDataCadastro";
import { BaixarCtsPagar } from "./_BaixarCtsPagar";

export function ListarCtsPagar() {
  //Inicio Hooks
  const AlterarCtsPagarRef = useRef<ModalBaseType>();
  const BaixarCtsPagarRef = useRef<ModalBaseType>();

  const AlterarDataCadastroRef = useRef<ModalBaseType>();
  const CopiarCtsPagarRef = useRef<ModalBaseType>();
  const DesbloquearCtsPagarRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const CGIHistoricoRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);
  const DataTableRef = useRef<any>(null);
  const methods = useForm<ListarCtsPagarTypes>({
    defaultValues: ObjListarCtsPagarPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarDadosExclusao = useCallback(
    async (data: ListarCtsPagarItensTypes) => {
      return await HttpCtsPagar().RetornarDadosExclusao(data);
    },
    []
  );

  async function Excluir(data: DeleteTypes) {
    await HttpCtsPagar()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }
  //Final Hooks

  //------------------------

  //Inicio Consts
  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo Título - Simples",
      Permissao: "financeiro/ctspagar/incluir",
      OnClick: () => {
        AlterarCtsPagarRef.current?.Abrir();
      },
    },
    {
      Nome: "Novo Título - Super Simples",
      Permissao: "financeiro/ctspagar/incluir",
      OnClick: () => {
        AlterarCtsPagarRef.current?.Abrir();
      },
    },
    {
      Nome: "Novo Título - Em Lote",
      Permissao: "financeiro/ctspagar/incluir",
      OnClick: () => {
        AlterarCtsPagarRef.current?.Abrir();
      },
    },
    {
      Nome: "Copiar",
      Permissao: "financeiro/ctspagar/copiarctspagar",
      OnClick: () => {
        CopiarCtsPagarRef.current?.Abrir();
      },
    },
    {
      Nome: "Importar Via Arquivo",
      Permissao: "financeiro/ctspagar/incluir",
      OnClick: () => {
        AlterarCtsPagarRef.current?.Abrir();
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/ctspagar/excluir",
      OnClick: (e: any) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "financeiro/ctspagar/incluir",
      OnClick: (e: ListarCtsPagarItensTypes) => {
        AlterarCtsPagarRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Alterar",
      Permissao: "financeiro/ctspagar/incluir",
      OnClick: (e: ListarCtsPagarItensTypes) => {
        AlterarCtsPagarRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Baixar",
      Permissao: "financeiro/ctspagar/baixar",
      OnClick: (e: ListarCtsPagarItensTypes) => {
        BaixarCtsPagarRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/ctspagar/excluir",
      OnClick: (e: ListarCtsPagarItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Outras Opções",
      Permissao: "financeiro/ctspagar/incluir",
      OnClick: () => {},
      SubMenus: [
        {
          Nome: "Alterar Data Cadastro",
          Permissao: "financeiro/ctspagar/alterardatacadastro",
          OnClick: (e: ListarCtsPagarItensTypes) => {
            AlterarDataCadastroRef.current?.Abrir(e);
          },
        },
        {
          Nome: "Copiar",
          Permissao: "sistema/ctspagar/copiar",
          OnClick: (e: ListarCtsPagarItensTypes) => {
            CopiarCtsPagarRef.current?.Abrir(e);
          },
        },
        {
          Nome: "Desbloquear",
          Permissao: "financeiro/ctspagar/desbloquearctspagar",
          OnClick: (e: ListarCtsPagarItensTypes) => {
            DesbloquearCtsPagarRef.current?.Abrir(e);
          },
        },
      ],
    },
    {
      Nome: "Histórico",
      Permissao: "financeiro/ctspagar/historico",
      OnClick: (e: ListarCtsPagarItensTypes) => {
        CGIHistoricoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Lancar Histórico",
      Permissao: "sistema/ctspagar/excluir",
      OnClick: (e: any) => {
        //CGIModalLancarHistoricoRef.current.Abrir(e);
      },
    },
    {
      Nome: "Lancar Histórico Colaborador",
      Permissao: "sistema/ctspagar/lancarhistorico",
      OnClick: (e: ListarCtsPagarItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Vizualizar NF Entrada",
      Permissao: "sistema/ctspagar/excluir",
      OnClick: (e: ListarCtsPagarItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Anexos",
      Permissao: "sistema/ctspagar/excluir",
      OnClick: (e: ListarCtsPagarItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Emissao Ordem Pagamento",
      Permissao: "sistema/ctspagar/excluir",
      OnClick: (e: ListarCtsPagarItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Aberto", cor: "green" },
    { nomeStatus: "Pago Parcial", cor: "lightBlue" },
    { nomeStatus: "Pago Total", cor: "blue" },
    { nomeStatus: "Estornado", cor: "orange" },
    { nomeStatus: "Excluído", cor: "red" },
    { nomeStatus: "Renegociado", cor: "yellow" },
    { nomeStatus: "Enviado na Remessa", cor: "lightorange" },
  ];

  const GridColunas: Array<CGICol<ListarCtsPagarItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "nomeEmpresa",
      Titulo: "Empresa",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 160,
      },
    },
    {
      Campo: "documento",
      Titulo: "DOC/OC",
      Tipo: "Texto",
      att: {
        maxWidth: 120,
      },
    },
    {
      Campo: "nomeFornecedor",
      Titulo: "Fornecedor",
      Tipo: "Texto",
      att: {
        minWidth: 500,
      },
    },
    {
      Campo: "nomePlanoDeContas",
      Titulo: "Plano de Contas",
      Tipo: "Texto",
      att: {
        minWidth: 180,
      },
    },
    {
      Campo: "valorAtual",
      Titulo: "Valor",
      Tipo: "Moeda",
      att: {
        maxWidth: 120,
      },
    },
    {
      Campo: "dataVencimento",
      Titulo: "Dt. Vencto.",
      Tipo: "Data",
      att: {
        maxWidth: 180,
      },
    },
    {
      Campo: "dataPagamento",
      Titulo: "Dt. Pagto.",
      Tipo: "Data",
      att: {
        maxWidth: 180,
      },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarCtsPagarItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          titulo: "Forn.",
          valor: "nomeFornecedor",
        },
        {
          titulo: "Vlr.",
          valor: "valor",
        },
        {
          titulo: "DOC",
          valor: "documento",
        },
        {
          titulo: "DOC",
          valor: "documento",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Empresa"
          CarregarDados={methods.handleSubmit(RetornarCtsPagar)}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <CopiarCtsPagar
          CarregarDados={methods.handleSubmit(RetornarCtsPagar)}
          ref={CopiarCtsPagarRef}
        />
      ),
    },
    {
      Componente: (
        <DesbloquearCtsPagar
          CarregarDados={methods.handleSubmit(RetornarCtsPagar)}
          ref={DesbloquearCtsPagarRef}
        />
      ),
    },
    {
      Componente: (
        <AlterarDataCadastroCtsPagar
          CarregarDados={methods.handleSubmit(RetornarCtsPagar)}
          ref={AlterarDataCadastroRef}
        />
      ),
    },
    {
      Componente: (
        <BaixarCtsPagar
          CarregarDados={methods.handleSubmit(RetornarCtsPagar)}
          ref={BaixarCtsPagarRef}
        />
      ),
    },
  ];

  //Final Consts

  //------------------------

  //Inicio Function
  async function RetornarCtsPagar() {
    setLoadingState(true);
    var DadosFiltro = methods.getValues("Pesquisa");
    var data = await HttpCtsPagar()
      .RetornarCtsPagar(DadosFiltro)
      .finally(() => {
        setLoadingState(false);
      });
    methods.setValue("Itens", data);
  }

  //Final Function

  //------------------------

  // Inicio JSX

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIBox titulo="Filtros de Período" popover="Filtros de Período">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsPagarTypes>
                id={"Pesquisa.dataEmissaoInicial"}
                label={"Data Emissão Inicial"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsPagarTypes>
                id={"Pesquisa.dataEmissaoFinal"}
                label={"Data Emissão Final"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsPagarTypes>
                id={"Pesquisa.dataInclusaoInicial"}
                label={"Data Inclusão Inicial"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsPagarTypes>
                id={"Pesquisa.dataInclusaoFinal"}
                label={"Data Inclusão Final"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsPagarTypes>
                id={"Pesquisa.dataVencimentoInicial"}
                label={"Data Vencimento Inicial"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsPagarTypes>
                id={"Pesquisa.dataVencimentoFinal"}
                label={"Data Vencimento Final"}
              />
            </CGIBoxItem>

            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsPagarTypes>
                id={"Pesquisa.dataPrevisaoInicial"}
                label={"Data Previsão Inicial"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsPagarTypes>
                id={"Pesquisa.dataPrevisaoFinal"}
                label={"Data Previsão Final"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsPagarTypes>
                id={"Pesquisa.dataPagamentoInicial"}
                label={"Data Pagamento Inicial"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsPagarTypes>
                id={"Pesquisa.dataPagamentoFinal"}
                label={"Data Pagamento Final"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsPagarTypes>
                id={"Pesquisa.dataBaixaInicial"}
                label={"Data Baixa Inicial"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsPagarTypes>
                id={"Pesquisa.dataBaixaFinal"}
                label={"Data Baixa Final"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsPagarTypes>
                id={"Pesquisa.dataProgramacaoInicial"}
                label={"Data Programação Inicial"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsPagarTypes>
                id={"Pesquisa.dataProgramacaoFinal"}
                label={"Data Programação Final"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={8}>
          <CGIBox>
            <CGIBoxItem Col={12}>
              <CGIComboSimples<ListarCtsPagarTypes>
                Multipla
                Link={"empresa"}
                label={"Empresas"}
                id={"Pesquisa.listaEmpresasId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarCtsPagarTypes>
                Multipla
                Link={"statusctspagarreceber"}
                label={"Status do Titulo"}
                id={"Pesquisa.listaStatusId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={8}>
              <CGIComboAjax<ListarCtsPagarTypes>
                Multipla
                Link={"fornecedor"}
                label={"Fornecedor"}
                id={"Pesquisa.listaFornecedoresId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIInput<ListarCtsPagarTypes>
                id={"Pesquisa.documento"}
                placeholder={"Documento"}
                label={"Documento"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={8}>
              <CGIComboSimples<ListarCtsPagarTypes>
                Multipla
                Link={"planodecontas-despesas-conta"}
                label={"Plano de Contas"}
                id={"Pesquisa.listaPlanosDeContasId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarCtsPagarTypes>
                Link={"simnaoambos"}
                label={"Cobranca Na Empresa"}
                id={"Pesquisa.cobrancaNaEmpresaId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={8}>
              <CGIInput<ListarCtsPagarTypes>
                id={"Pesquisa.codigoBarras"}
                placeholder={"Código de Barras"}
                label={"Código de Barras"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarCtsPagarTypes>
                Multipla
                Link={"simnaoambos"}
                label={"Mes Ref."}
                id={"Pesquisa.mesReferenciaId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={8}>
              <CGIComboSimples<ListarCtsPagarTypes>
                Multipla
                Link={"centrodecusto"}
                label={"Centro de Custo"}
                id={"Pesquisa.listaCentrosCustoId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarCtsPagarTypes>
                Multipla
                Link={"portador"}
                label={"Portador"}
                id={"Pesquisa.listaPortadoresId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIInput<ListarCtsPagarTypes>
                id={"Pesquisa.numeroOrdemCompra"}
                placeholder={"Nº Ordem Compra"}
                label={"Nº Ordem Compra"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIInput<ListarCtsPagarTypes>
                id={"Pesquisa.cNPJFornecedor"}
                placeholder={"CNPJ Fornecedor"}
                label={"CNPJ Fornecedor"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarCtsPagarTypes>
                Link={"simnaoambos"}
                label={"Documento Contábil"}
                id={"Pesquisa.documentoContabilId"}
              />
            </CGIBoxItem>

            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarCtsPagarTypes>
                Link={"simnaoambos"}
                label={"Pagamento Bloqueado"}
                id={"Pesquisa.pagamentoBloqueadoId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarCtsPagarTypes>
                Multipla
                Link={"equipamento"}
                label={"Equipamento"}
                id={"Pesquisa.listaEquipamentosId"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIBox>
            <CGIBoxItem Col={3}>
              <CGIComboSimples<ListarCtsPagarTypes>
                Multipla
                Link={"simnaoambos"}
                label={"Pagamento Aprovado"}
                id={"Pesquisa.pagamentoAprovadoId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGIComboSimples<ListarCtsPagarTypes>
                Multipla
                Link={"sispag"}
                label={"SISPAGP - Pagto Através Dê"}
                id={"Pesquisa.listaSISPAGId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGIComboSimples<ListarCtsPagarTypes>
                Multipla
                Link={"simnaoambos"}
                label={"Tem Adiantamento Fornecedor"}
                id={"Pesquisa.adiantamentoFornecedorId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGIInput id={"titulo"} placeholder={"Título"} label={"Título"} />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGIComboSimples<ListarCtsPagarTypes>
                Multipla
                Link={"tipodocumento"}
                label={"Tipo de Documento"}
                id={"Pesquisa.listaTiposDocumentoId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGICheckBox
                id={"Pesquisa.somenteTitulosPrevisao"}
                label={"Somente Títulos de Previsão"}
                tooltip={"Somente Títulos de Previsão"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGICheckBox
                id={"Pesquisa.ocultarExcluidos"}
                label={"Ocultar Excluídos"}
                tooltip={"Ocultar Excluídos"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="financeiro/ctspagar/listar"
      tipoGET={{
        url: "CTSPAGAR-LISTAR",
        setRetorno: e => {
          methods.setValue("Pesquisa", e);
        },
      }}
      GetQueryString={{
        setRetorno: e => {
          methods.setValue("Pesquisa.documento", e.DOC);
          DataTableRef.current?.Pesquisar();
        },
      }}
      Titulo="Cts. Pagar"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          ref={DataTableRef}
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarCtsPagar)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
      <FormContext>
        <CGIHistorico
          tipo="TimeLine"
          titulo="Histórico do Título"
          ref={CGIHistoricoRef}
          link={"financeiro/ctspagar/historico"}
          origem={"ctspagar"}
        />
      </FormContext>
    </CGIListar>
  );
  // Final JSX
}
