import { Box, Center, Divider, Flex, forwardRef, Text } from "@chakra-ui/react";
import {
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ModalBaseType } from "../../Types/Modal/ModalBaseType";
import { CGIBox } from "../Box/CGIBox";
import { CGIBoxItem } from "../Box/CGIBoxItens";
import { CGIDataTableSimples } from "../DataTable/CGIDataTableSimples";
import { CGICol } from "../DataTable/DataTable/DataTableTypes";
import { CGIModal } from "../Modal/CGIModal";
import { CGIAnexo } from "./CGIAnexo";
import { HttpAnexo } from "./HttpAnexo";
import { MdFileDownload, MdDelete } from "react-icons/md";
type CGIVisualizarAnexoTypes = {
  link: string;
  origem: string;
  titulo: string;
};
type FormAnexosTypes = {
  Resumo: AnexoResumoTypes[];
  Itens: AnexoGridItensTypes[];
};
type AnexoGridItensTypes = {
  id: number;
  dataCadastro: string;
  documento: string;
  titulo: string;
  descricao: string;
  arquivo: string;
  caminhoCompleto: string;
  nomeUsuario: string;
  nomeTipoAnexo: string;
};
type AnexoResumoTypes = {
  chave: string;
  valor: string;
};
/**
 * @Componente CGIVisualizarAnexo - VisualizarAnexo Principal do Sistema
 * @Ref  CGIVisualizarAnexo
 */
export const CGIVisualizarAnexo = forwardRef(
  (props: CGIVisualizarAnexoTypes, ref) => {
    const CGIModalRef = useRef<ModalBaseType>(null);

    const GridVisualizarAnexo: Array<CGICol<AnexoGridItensTypes>> = [
      {
        Campo: "id",
        Titulo: "Código",
        Tipo: "Texto",
        Ordernar: true,
        att: {
          hide: true,
        },
      },
      {
        Campo: "documento",
        Titulo: "Documento",
        Tipo: "Texto",
      },
      {
        Campo: "titulo",
        Titulo: "Título",
        Tipo: "Texto",
      },
      {
        Campo: "descricao",
        Titulo: "Descrição",
        Tipo: "Texto",
      },
      {
        Campo: "nomeUsuario",
        Titulo: "Usuário",
        Tipo: "Texto",
      },
      {
        Campo: "nomeTipoAnexo",
        Titulo: "Tipo de Anexo",
        Tipo: "Texto",
      },
      {
        Campo: "dataCadastro",
        Titulo: "Data Cadastro",
        Tipo: "Data",
      },
      {
        Campo: "id",
        Titulo: "",
        Tipo: "HTML",
        att: {
          cellRenderer: (e: any) => {
            return (
              <Flex gap={2}>
                <MdFileDownload
                  size={20}
                  onClick={() => {
                    window.open(e?.data?.caminhoCompleto);
                  }}
                />
                <MdDelete size={20} />
              </Flex>
            );
          },
          pinned: "right",
          maxWidth: 100,
          filter: false,
        },
      },
    ];
    const form = useFormContext<FormAnexosTypes>();

    const GridItens = useWatch({
      control: form.control,
      name: "Itens",
    });
    const Resumos = useWatch({
      control: form.control,
      name: "Resumo",
    });

    useImperativeHandle(ref, () => ({
      async Abrir(e: any) {
        var Dados = await HttpAnexo().RetornarAnexos(props.origem, e.id);
        var Resumos = await HttpAnexo().RetornarResumosAnexo(
          props.origem,
          e.id
        );
        form.setValue("Itens", Dados);
        form.setValue("Resumo", Resumos);

        CGIModalRef.current?.Abrir();
      },
    }));

    const RenderDadosRegistro = useMemo(() => {
      return (
        <CGIBox spacing="0px">
          <CGIBoxItem Col={12}>
            <Text fontWeight={"black"}>Dados do Registro</Text>
            <Divider borderColor={"black"} />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            {Resumos &&
              Resumos.map((e, i) => {
                return (
                  <>
                    <Flex key={i} justifyContent={"space-between"}>
                      <Text fontSize={15} fontWeight={"bold"}>
                        {e.chave}
                      </Text>
                      <Text fontSize={15}>{e.valor}</Text>
                    </Flex>
                    <Divider borderColor={"black"} />
                  </>
                );
              })}
          </CGIBoxItem>
        </CGIBox>
      );
    }, [Resumos]);
    return (
      <CGIModal
        ref={CGIModalRef}
        id={props.link}
        Titulo={`${props.titulo}`}
        Confirmar={() => {}}
        TelaCheia={"7xl"}
        ocultarConfirmar
        ocultarCancelar
      >
        <CGIBox>
          <CGIBoxItem Col={4}>
            <>{RenderDadosRegistro}</>
          </CGIBoxItem>
          <CGIBoxItem Col={8}>
            <CGIAnexo tipoAnexo="simnaoambos" />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIDataTableSimples
              DisableGetId
              Att={{
                defaultColDef: {
                  filter: false,
                  flex: 1,
                },
              }}
              Altura="300px"
              Pag={5}
              Colunas={GridVisualizarAnexo}
              Data={GridItens}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
  }
);
