export const TraducaoGrafico: Highcharts.LangOptions = {
  contextButtonTitle: "Opções Avançadas",
  decimalPoint: ",",
  downloadJPEG: "Salvar como JPEG",
  downloadPDF: "Salvar como PDF",
  downloadPNG: "Salvar como PNG",
  downloadSVG: "Salvar como SVG",
  loading: "Aguarde...",
  noData: "Sem dados a exibir para este gráfico!",
  //numericSymbols: null,
  printChart: "Imprimir",
  resetZoom: "Desfazer zoom",
  resetZoomTitle: "Voltar zoom 1:1",
  thousandsSep: ".",
  viewFullscreen: "Tela cheia",
  viewData: "Ver tabela de dados",
  months: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  shortMonths: [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ],
  weekdays: [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
  ],
  exitFullscreen: "Sair da tela cheia",
  rangeSelectorFrom: "De",
  rangeSelectorTo: "Até",
  rangeSelectorZoom: "Período",
  downloadCSV: "Salvar como CSV",
  downloadXLS: "Salvar como XLS",
  numericSymbols: [" mil", " milhões", " bilhões", " trilhões", " quadrilhões"],
  shortWeekdays: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
  hideData: "Ocultar dados",
  invalidDate: "Data inválida",
  mainBreadcrumb: "Principal",
  numericSymbolMagnitude: 1000,
  zoomIn: "Aumentar zoom",
  zoomOut: "Diminuir zoom",
};
