import { Flex } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useForm } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import {
  FormConfigCFOPAutoType,
  ObjConfigCFOPAutoPadrao,
} from "../../../Types/Sistema/ConfigCFOPAuto/ConfigCFOPAutoTypes";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { HttpConfigCFOPAuto } from "./Http";
import { CGINumberInput } from "../../../Components/NumberInput/CGINumberInput";

export const AlterarConfigCFOPAuto = forwardRef(
  (
    props: {
      CarregarDados: () => void;
    },
    ref
  ) => {
    //INICIO REF
    const form = useFormContext<FormConfigCFOPAutoType>();
    const CGIModalRef = useRef<ModalBaseType>();
    useImperativeHandle(ref, () => ({
      Abrir() {
        form.reset(ObjConfigCFOPAutoPadrao);
        CGIModalRef.current?.Abrir();
      },
      Alterar(pConfigCFOPAutoId: number) {
        CGIModalRef.current?.Abrir();
      },
    }));

    return (
      <CGIModal
        id="sistema/classificacao/alterar"
        ref={CGIModalRef}
        Titulo="ConfigCFOPAuto"
        TelaCheia={"6xl"}
        CarregarDados={() => {}}
        Confirmar={(e: any) => {}}
        Fechar={() => {
          form.reset(ObjConfigCFOPAutoPadrao);
        }}
      >
        <CGIBox>
          <CGIBoxItem Col={3}>
            <CGIInput<FormConfigCFOPAutoType>
              id="Form.DadosRegistro.nome"
              label="Nome"
              placeholder=""
              validadores={{
                required: "Campo obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormConfigCFOPAutoType>
              id="Form.DadosRegistro.estado"
              label="Estado"
              placeholder="*"
              validadores={{
                required: "Campo obrigatório",
              }}
              att={{
                maxLength: 2,
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormConfigCFOPAutoType>
              id="Form.DadosRegistro.nCM"
              label="NCM"
              placeholder="*"
              validadores={{
                required: "Campo obrigatório",
              }}
              att={{
                maxLength: 8,
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormConfigCFOPAutoType>
              id="Form.DadosRegistro.empresaId"
              label="Empresa"
              Link={"empresa"}
              validadores={{
                required: "Campo obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormConfigCFOPAutoType>
              id="Form.DadosRegistro.codigoColaborador"
              label="Cód. Colaborador"
              placeholder=""
              validadores={{
                required: "Campo obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormConfigCFOPAutoType>
              id="Form.DadosRegistro.codigoProduto"
              label="Cód. Produto"
              placeholder=""
              validadores={{
                required: "Campo obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormConfigCFOPAutoType>
              id="Form.DadosRegistro.listaClassesProdutosId"
              label="Classe Produto"
              Link="classe"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormConfigCFOPAutoType>
              id="Form.DadosRegistro.listaTiposProdutosId"
              label="Tipos de Produto"
              Link="tipodeproduto"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormConfigCFOPAutoType>
              id="Form.DadosRegistro.listaGruposProdutosId"
              label="Grupo de Produto"
              Link="tipoproduto"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormConfigCFOPAutoType>
              id="Form.DadosRegistro.listaNaturezasOperacaoId"
              label="Nat. Operação"
              Link="naturezadeoperacao"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIComboSimples<FormConfigCFOPAutoType>
              id="Form.DadosRegistro.listaConfigCFOPsId"
              label="Config. CFOP"
              Link="cfop-entrada-saida"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIInput<FormConfigCFOPAutoType>
              id="Form.DadosRegistro.grupoColaboradores"
              label="Grupo Colaboradores"
              placeholder=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormConfigCFOPAutoType>
              id="Form.DadosRegistro.contribuinteICMS"
              label="Contribuinte ICMS"
              tooltip=""
              att={{
                style: { marginTop: 30 },
              }}
              attLabel={{
                style: { marginTop: 30 },
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormConfigCFOPAutoType>
              id="Form.DadosRegistro.ativo"
              label="Ativo"
              tooltip=""
              att={{
                style: { marginTop: 30 },
              }}
              attLabel={{
                style: { marginTop: 30 },
              }}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
    //FINAL JSX
  }
);
