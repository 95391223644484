/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider } from "@chakra-ui/react";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  RetornarSeUsuarioTemPemissaoPeloLink,
  RetornarSeUsuarioTemPemissaoPeloId,
} from "../Util/Permissoes";
import { CGIForm } from "../Form/CGIForm";
import { CGITituloJanela } from "../Form/CGITituloJanela";
import ErrorContext from "../../Context/ErrorContext";
import { FormContext } from "../../Context/FormConxtext";
import { HttpUtil } from "../Util/Http";
import { FormProvider, useForm } from "react-hook-form";

type CGIListarType = {
  id: string;
  tipoGET?: {
    url: string;
    setRetorno: (data: any) => void;
  };
  GetQueryString?: {
    setRetorno: (data: any) => void;
  };
  Titulo: string;
  children: React.ReactNode;
  NaoAlterarPermissoes?: boolean;
  Janelas?: Array<{ Componente: React.ReactNode }>;
};
/**
 * @Componente CGIListar - Componente usado Para o Listar padrão do Sistema
 * @Ref  CGIListar
 */

export const CGIListarContext = createContext<CGIListarType>(
  {} as CGIListarType
);

export function CGIListar(props: CGIListarType) {
  const [AlterarPermissoesUsuarios, setAlterarPermissoesUsuarios] =
    useState(false);

  const [ListarDados] = useState<CGIListarType>(props);
  const location = useLocation();
  var queryString = location.search;
  const params = Object.fromEntries(new URLSearchParams(queryString));
  const { setErrorState } = useContext(ErrorContext);
  let navigate = useNavigate();
  useEffect(() => {
    var TemPermissao = RetornarSeUsuarioTemPemissaoPeloLink(props.id);
    var PodeAlterarPermissao = RetornarSeUsuarioTemPemissaoPeloId(1865);
    if (props.NaoAlterarPermissoes === false) {
      setAlterarPermissoesUsuarios(false);
    } else {
      setAlterarPermissoesUsuarios(PodeAlterarPermissao);
    }
    if (!TemPermissao) {
      setErrorState(true);
      navigate("/", { replace: true });
    }
    if (!params.DOC) {
      RetornarTipoGet();
    } else {
      RetornarGetQueryString();
    }
  }, []);

  function RetornarGetQueryString() {
    setTimeout(() => {
      if (props.GetQueryString) {
        props.GetQueryString.setRetorno(params);
      }
    }, 100);
  }

  const RetornarTipoGet = useCallback(async () => {
    if (props.tipoGET) {
      var Dados = await HttpUtil().RetornarGETTelas(props.tipoGET.url);
      props.tipoGET.setRetorno(Dados);
    }
  }, []);
  return (
    <CGIListarContext.Provider value={ListarDados}>
      <Box>
        <Box padding={2} margin={2} borderRadius={10}>
          <CGITituloJanela
            temPermissao={AlterarPermissoesUsuarios}
            id={props.id}
            Titulo={props.Titulo}
            Janela={false}
          />
        </Box>
        <Box>
          <Divider borderWidth={2} />
          {props.children}
        </Box>
      </Box>
      {props.Janelas?.map((e, index) => {
        return <FormContext key={index}>{e.Componente}</FormContext>;
      })}
    </CGIListarContext.Provider>
  );
}

export default CGIListarContext;
