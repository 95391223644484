import { useEffect, useState } from "react";
import { RetornarDadosUsuario } from "../../Services/Util/Util";
import { CGIBox } from "../Box/CGIBox";
import { CGIBoxItem } from "../Box/CGIBoxItens";
import { HttpGrafico } from "./Http";
import { CGIGraficoBAR } from "./TipoGraficos/GraficoBAR";
import { CGIGraficoLINE } from "./TipoGraficos/GraficoLINE";
import { CGIGraficoPIE } from "./TipoGraficos/GraficoPIE";

type CGIGraficoType = {
  tipo: string;
  chave: string;
};
export type CGIGraficoDadosType = {
  tipo: string;
  chave: string;
  categorias: Array<string>;
  series: Array<any>;
  seriesDataValor: Array<any>;
  seriesChaveValor: Array<any>;
  registros: Array<any>;
  config: {
    titulo: string;
    subtitulo: string;
    exibirLegenda: boolean;
    exibirTooltip: boolean;
    exibirLabel: boolean;
  };
};
const ObjPadrao: CGIGraficoDadosType = {
  tipo: "",
  chave: "",
  categorias: [],
  series: [],
  seriesDataValor: [],
  seriesChaveValor: [],
  registros: [],
  config: {
    titulo: "",
    subtitulo: "",
    exibirLegenda: false,
    exibirTooltip: false,
    exibirLabel: false,
  },
};

export function CGIGrafico(props: CGIGraficoType) {
  const [Dados, setDados] = useState<CGIGraficoDadosType>(ObjPadrao);
  useEffect(() => {
    async function RetornarDadosGraficos() {
      var DadosGraficos =
        await HttpGrafico().RetornarDados<CGIGraficoDadosType>(
          props.tipo,
          props.chave
        );

      if (RetornarDadosUsuario().usuarioId === 1) {
        DadosGraficos.config.titulo = `${DadosGraficos.config.titulo} <br> <b>[${DadosGraficos.tipo} - ${DadosGraficos.chave}]</b>`;
      }

      setDados(DadosGraficos);
    }
    RetornarDadosGraficos();
  }, [props.chave, props.tipo]);

  const GraficoAtual = () => {
    let Grafico: JSX.Element = <></>;
    switch (props.tipo) {
      case "BAR":
        Grafico = CGIGraficoBAR({
          Dados: Dados,
        });
        break;
      case "PIE":
        Grafico = CGIGraficoPIE({
          Dados: Dados,
        });
        break;
      case "LINE":
        Grafico = CGIGraficoLINE({
          Dados: Dados,
        });
        break;
      default:
        Grafico = <></>;
    }
    return Grafico;
  };

  return (
    <CGIBox>
      <CGIBoxItem Col={12}>
        <GraficoAtual />
      </CGIBoxItem>
    </CGIBox>
  );
}
