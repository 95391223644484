import { CGIHttp } from "../../../../Services/Axios";
import {
  FormNotasPendentesEntregaType,
  ListarPesquisaNotasPendentesEntregaTypes,
  ListarNotasPendentesEntregaItensTypes,
  NotasPendentesEntregaPesquisaGridTypes,
  NotasPendentesEntregaTypes,
} from "../../../../Types/Faturamento/NFSaida/NotasPendentesEntrega/NotasPendentesEntregaTypes";
import { DeleteTypes } from "../../../../Types/Util/DeleteTypes";

export function HttpNotasPendentesEntrega() {
  const url = "/nfsaida";

  async function RetornarDadosGridPesquisa(
    dados: NotasPendentesEntregaPesquisaGridTypes
  ): Promise<ListarNotasPendentesEntregaItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-NotasPendentesEntrega", dados)
      .then((e) => e.data.data);
  }

  async function RetornarNotasPendentesEntregas(
    DadosFiltros: ListarPesquisaNotasPendentesEntregaTypes
  ): Promise<ListarNotasPendentesEntregaItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(
        url + "/obter-nf-pendentes-entrega",
        DadosFiltros
      )
    ).data.data;
  }

  async function RetornarDadosNotasPendentesEntrega(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + `/alterar/${id}`)
    ).data.data) as FormNotasPendentesEntregaType;
  }

  async function RetornarDadosExclusao(
    Dados: ListarNotasPendentesEntregaItensTypes
  ) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + Dados.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }
  async function RetornarDadosVisualizarNotasPendentesEntrega(
    DadosNotasPendentesEntrega: FormNotasPendentesEntregaType
  ) {
    return (await (
      await new CGIHttp().conn.get(
        url + "/visualizar/" + DadosNotasPendentesEntrega.Form
      )
    ).data.data) as NotasPendentesEntregaTypes;
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir/" + Dados.id);
  }
  async function Alterar(
    DadosNotasPendentesEntrega: FormNotasPendentesEntregaType
  ) {
    return await new CGIHttp().conn.post(
      url + "/alterar",
      DadosNotasPendentesEntrega.Form
    );
  }
  async function Visualizar(
    DadosNotasPendentesEntrega: FormNotasPendentesEntregaType
  ) {
    return await new CGIHttp().conn.post(
      url + "/visualizar",
      DadosNotasPendentesEntrega.Form
    );
  }
  async function Incluir(
    DadosNotasPendentesEntrega: FormNotasPendentesEntregaType
  ) {
    return await new CGIHttp().conn.post(
      url + "/incluir",
      DadosNotasPendentesEntrega.Form
    );
  }
  return {
    RetornarNotasPendentesEntregas,
    RetornarDadosGridPesquisa,
    RetornarDadosExclusao,
    RetornarDadosVisualizarNotasPendentesEntrega,
    RetornarDadosNotasPendentesEntrega,
    Visualizar,
    Excluir,
    Alterar,
    Incluir,
  };
}
