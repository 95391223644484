import { CGIHttp } from "../../../../Services/Axios";
import {
  FormTipoContratoType,
  ListarTipoContratoItensTypes,
  ListarPesquisaTipoContratoTypes,
} from "../../../../Types/Sistema/Classificacao/TipoContrato/TipoContratoTypes";

const url = "/classificacao";

export function HttpTipoContrato() {
  async function RetornarTipoContratos(
    DadosFiltros: ListarPesquisaTipoContratoTypes
  ) {
    return (
      await new CGIHttp().conn.post(url + "/obter-tiposcontrato", DadosFiltros)
    ).data.data;
  }

  async function Listar() {
    return await (
      await new CGIHttp().conn.get(url + "/listar-167")
    ).data.data;
  }

  async function RetornarDadosTipoContrato(id: number) {
    return await new CGIHttp().conn.get(
      url + `/retornar-dados-tipocontrato/${id}`
    );
  }

  async function Incluir(Dados: FormTipoContratoType) {
    return await new CGIHttp().conn.post(
      url + "/incluir-tipocontrato",
      Dados.Form
    );
  }
  async function Alterar(Dados: FormTipoContratoType) {
    return await new CGIHttp().conn.post(
      url + "/alterar-tipocontrato",
      Dados.Form
    );
  }
  async function RetornarDadosExclusao(Dados: ListarTipoContratoItensTypes) {
    return await new CGIHttp().conn.get(
      url + "/excluir-tipocontrato/" + Dados.id
    );
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir-tipocontrato", Dados);
  }

  return {
    RetornarTipoContratos,
    RetornarDadosTipoContrato,
    RetornarDadosExclusao,
    Incluir,
    Listar,
    Alterar,
    Excluir,
  };
}
