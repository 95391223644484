import { CheckIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Text,
  Flex,
  Box,
  IconButton,
  Divider,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Center,
} from "@chakra-ui/react";
import moment from "moment";
import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useContext,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import LoadingContext from "../../Context/LoadingContext";
import { CGIBox } from "../Box/CGIBox";
import { CGIBoxItem } from "../Box/CGIBoxItens";
import { CGITituloJanela } from "../Form/CGITituloJanela";
import { CGIModal } from "../Modal/CGIModal";
import { CGIResumo } from "../Resumo/CGIResumo";
import { HttpCGINotificacao } from "./HttpCGINotificacao";

type CGIModalNotificacaoItemType = {
  id: number;
  titulo: string;
  descricao: string;
  dataCadastro: string;
  nomeTipo: string;
  nomeUsuario: string;
  grupo: string;
  nomeGrupo: string;
  permitirAcao: boolean;
};

type CGIModalNotificacaoItemFormatadoType = {
  [key: string]: CGIModalNotificacaoItemType[];
};

export const CGIModalNotificacao = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async Abrir() {
      await RetornarDados();
      onOpen();
    },
  }));

  const RetornarDados = async () => {
    setLoadingState(true);
    var Dados = await HttpCGINotificacao().RetornarNotificacoesModal<
      CGIModalNotificacaoItemType[]
    >();

    const notificacaoPeloGrupo: CGIModalNotificacaoItemFormatadoType[] =
      Dados.reduce((acc: any, item) => {
        const { nomeGrupo } = item;
        acc[nomeGrupo] = acc[nomeGrupo] ?? [];
        acc[nomeGrupo].push(item);
        return acc;
      }, {});
    setDados(notificacaoPeloGrupo);
    onOpen();
    setLoadingState(false);
  };

  const DefinirAcao = async (id: number) => {
    setLoadingState(true);

    await HttpCGINotificacao().DefinirAcaoNotificacaoSistema(id, true);
    await RetornarDados();

    setLoadingState(false);
  };

  const ConcluirNotificacao = async (id: number) => {
    setLoadingState(true);

    await HttpCGINotificacao().DefinirAcaoNotificacaoSistema(id, false);

    await RetornarDados();

    setLoadingState(false);
  };
  const { setLoadingState, Loadingstate } = useContext(LoadingContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dados, setDados] = useState<CGIModalNotificacaoItemFormatadoType[]>(
    []
  );

  return (
    <Modal
      scrollBehavior="inside"
      size={"6xl"}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={"center"}>
          <CGITituloJanela
            ExibirFav={false}
            Titulo={"Notificações"}
            Janela={false}
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex h={"500"}>
            <CGIBox
              Att={{
                flex: 1,
              }}
            >
              {Object.keys(dados).map(e => {
                var item: CGIModalNotificacaoItemType[] = dados[
                  e as any
                ] as any;
                return (
                  <CGIBoxItem
                    Att={{
                      borderRadius: "md",
                      borderWidth: "1px",
                      borderColor: "blackAlpha.500",
                      p: 2,
                    }}
                    Col={4}
                  >
                    <CGIBoxItem Col={12}>
                      <Box
                        bgColor={"steelblue"}
                        color={"white"}
                        borderRadius={"30"}
                        borderWidth="1"
                        textAlign={"center"}
                        px={2}
                        my={1}
                      >
                        <Text fontWeight="bold">
                          {e} ({item.length})
                        </Text>
                      </Box>
                    </CGIBoxItem>
                    <CGIBox
                      Att={{
                        overflowY: "auto",
                        maxH: "450",
                      }}
                    >
                      {item.map(item => {
                        return (
                          <CGIBoxItem Col={12}>
                            <Box
                              borderRadius={"md"}
                              borderWidth={"1px"}
                              borderColor={"blackAlpha.800"}
                              p={2}
                            >
                              <Box w={"100%"}>
                                <Box>
                                  {item.nomeTipo} {" - "}
                                  {moment(item.dataCadastro).format(
                                    "DD/MM/YYYY"
                                  )}
                                </Box>
                                <Box>
                                  <Text fontWeight={"bold"}>{item.titulo}</Text>
                                </Box>
                                <Box>
                                  <Text
                                    textAlign={"center"}
                                    fontSize={"12"}
                                    fontWeight={"light"}
                                  >
                                    {item.nomeGrupo}
                                  </Text>
                                </Box>
                              </Box>
                              <Accordion size={"sm"} allowToggle>
                                <AccordionItem>
                                  <AccordionButton
                                    as={Box}
                                    p="0"
                                    borderRadius={0}
                                  >
                                    <Text fontWeight="light">Ver mais +</Text>
                                  </AccordionButton>
                                  <AccordionPanel pb={4}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item.descricao,
                                      }}
                                    />
                                  </AccordionPanel>
                                </AccordionItem>
                              </Accordion>
                              <Divider my={1} />
                              <Box>
                                <Flex justifyContent={"space-around"} gap={2}>
                                  <IconButton
                                    isLoading={Loadingstate}
                                    size={"sm"}
                                    aria-label={""}
                                    icon={<CheckIcon />}
                                    colorScheme="green"
                                    onClick={() => ConcluirNotificacao(item.id)}
                                  />
                                  {item.permitirAcao && (
                                    <IconButton
                                      isLoading={Loadingstate}
                                      size={"sm"}
                                      aria-label={""}
                                      icon={<ArrowForwardIcon />}
                                      colorScheme="blue"
                                      onClick={() => DefinirAcao(item.id)}
                                    />
                                  )}
                                </Flex>
                              </Box>
                            </Box>
                          </CGIBoxItem>
                        );
                      })}
                    </CGIBox>
                  </CGIBoxItem>
                );
              })}
            </CGIBox>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
  //FINAL JSX
});
