import {
  Box,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
  Stack,
  StackDirection,
} from "@chakra-ui/react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import React from "react";

interface CGIRadioButtonTypes<T extends Record<string, unknown>> {
  id: NestedKey<T>;
  row: StackDirection;
  label: string;
  values: Array<{
    label: string;
    valor: string;
  }>;
  att?: RadioProps;
  validadores?: RegisterOptions | undefined;
}

/**
 * @Componente CGIRadioButton - Radio Button padrão do Sistema
 * @Ref  FormContext
 * @Form  Necessita ser Controlado Por Formulário
 */
export function CGIRadioButton<T extends Record<string, unknown>>(
  props: CGIRadioButtonTypes<T>
) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const att = { ...props.att } as any;
  return (
    <Box>
      <FormLabel>
        {props.label}
        {props.validadores?.required ? (
          <span style={{ color: "red" }}> *</span>
        ) : (
          ""
        )}
      </FormLabel>
      <Controller
        render={({ field }) => {
          return (
            <RadioGroup {...att} onChange={field.onChange} value={field.value}>
              <Stack direction={props.row}>
                {props.values.map((e, index) => (
                  <Radio key={index} value={e.valor}>
                    {e.label}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          );
        }}
        rules={props.validadores}
        name={props.id}
        control={control}
      />
      <ErrorMessage
        errors={errors}
        name={props.id}
        render={({ message }) => (
          <span style={{ color: "red", fontSize: 12 }}> * {message}</span>
        )}
      />
    </Box>
  );
}
