import React from "react";
import {
  Box,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Text,
  SimpleGridProps,
  useDisclosure,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { Temas } from "../../Themes/Temas";

type CGIBoxTypes = {
  titulo?: string;
  popover?: string | JSX.Element;
  children: React.ReactNode;
  Att?: SimpleGridProps;
  spacing?: string;
};
/**
 * @Componente CGIBox - Componente usado para Criar Grids de Layout.
 * @Util Usado Em Conjunto do CGIBoxItens
 *
 */
export function CGIBox(props: CGIBoxTypes) {
  const { isOpen, onToggle, onClose } = useDisclosure();

  if (props.titulo) {
    return (
      <Box
        borderRadius={"md"}
        borderWidth={0.5}
        shadow={"md"}
        bgColor={"green.600"}
      >
        <Popover
          isOpen={isOpen}
          onClose={onClose}
          placement="top"
          closeOnBlur={true}
        >
          <PopoverTrigger>
            <Text gap={4} px={2} fontWeight={"bold"} color={"#FFF"}>
              {props.titulo}
              <Icon mx={2} onClick={onToggle} as={SearchIcon} />
            </Text>
          </PopoverTrigger>

          <PopoverArrow />
          <PopoverContent>
            <PopoverCloseButton />
            <PopoverBody w={"100%"}>
              {typeof props.popover == "string" ? (
                <Text fontWeight={"bold"}>{props.popover}</Text>
              ) : (
                props.popover
              )}
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <Box p={2} bgColor={Temas.Branco}>
          <SimpleGrid
            columns={{ base: 1, md: 12 }}
            spacing={props.spacing ? props.spacing : "20px"}
          >
            {props.children}
          </SimpleGrid>
        </Box>
      </Box>
    );
  } else {
    return (
      <SimpleGrid
        columns={{ base: 1, md: 12 }}
        spacing={props.spacing ? props.spacing : "20px"}
        {...props.Att}
      >
        {props.children}
      </SimpleGrid>
    );
  }
}
