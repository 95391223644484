export type ConfigComissaoVisualizarTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  dataCadastro: string;
};

export const ObjConfigComissaoPadrao: FormConfigComissaoType = {
  Form: {
    dadosRegistro: {
      id: 0,
      nome: "",
      dataCadastro: "",
      usuarioId: 0,
      percComissao: 0,
      empresaId: 0,
      vendedorId: 0,
      clienteId: 0,
      produtoId: 0,
      grupoId: 0,
      subGrupoId: 0,
      classeId: 0,
      cidadeId: 0,
      siglaEstado: "",
      ativo: false,
      rotaId: 0,
      pracaId: 0,
      tipoClienteId: 0,
      valorIndividual: 0,
    },
  },
};
export type ConfigComissaoTypes = {
  id: number;
  nome: string;
  dataCadastro: string;
  usuarioId: number;
  percComissao: number;
  empresaId: number;
  vendedorId: number;
  clienteId: number;
  produtoId: number;
  grupoId: number;
  subGrupoId: number;
  classeId: number;
  cidadeId: number;
  siglaEstado: string;
  ativo: boolean;
  rotaId: number;
  pracaId: number;
  tipoClienteId: number;
  valorIndividual: number;
};

export type FormConfigComissaoType = {
  Form: {
    dadosRegistro: ConfigComissaoTypes;
  };
};

//------ TIPO ALTERAR

export const ObjListarConfigComissaoPadrao: ListarConfigComissaoTypes = {
  Pesquisa: {
    nome: "",
    listaEmpresasId: "",
    listaVendedoresId: "",
    ativoId: 0,
    listaClientesId: "",
    listaProdutosId: "",
    listaGruposId: "",
    listaSubGruposId: "",
    listaClassesId: "",
    listaCidadesId: "",
    listaPracasId: "",
    listaRotasId: "",
    listaTipoClientesId: "",
  },
  Itens: [],
};

export type ListarConfigComissaoTypes = {
  Pesquisa: ListarPesquisaConfigComissaoTypes;
  Itens: ListarConfigComissaoGridItensTypes[];
};

export type ListarPesquisaConfigComissaoTypes = {
  nome: string;
  listaEmpresasId: string;
  listaVendedoresId: string;
  ativoId: number;
  listaClientesId: string;
  listaProdutosId: string;
  listaGruposId: string;
  listaSubGruposId: string;
  listaClassesId: string;
  listaCidadesId: string;
  listaPracasId: string;
  listaRotasId: string;
  listaTipoClientesId: string;
};

export type ListarConfigComissaoGridItensTypes = {
  id: number;
  nome: string;
  dataCadastro: string;
  percComissao: number;
  valorIndividual: number;
  ativo: boolean;
  nomeEmpresa: string;
  nomeVendedor: string;
  nomeCliente: string;
  nomeProduto: string;
  nomeGrupo: string;
  nomeSubGrupo: string;
  nomeClasse: string;
  nomeCidade: string;
  nomePraca: string;
  nomeRota: string;
  nomeTipoCliente: string;
  nomeStatus: string;
};
