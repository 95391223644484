import { AddIcon } from "@chakra-ui/icons";
import { useState, useRef, useContext } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGIComboSimples } from "../../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIListar } from "../../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../../Context/LoadingContext";
import {
  FormFormaPagamentoTypes,
  ListarFormaPagamentoItensTypes,
  ListarFormaPagamentoTypes,
  ObjListarFormaPagamentoPadrao,
} from "../../../../Types/Sistema/Classificacao/FormaPagamento/FormaPagamentoTypes";
import {
  ModalBaseType,
  LegendaTypes,
} from "../../../../Types/Modal/ModalBaseType";
import { HttpFormaPagamento } from "./Http";
import { AlterarFormaPagamento } from "./_AlterarFormaPagamento";
import { VisualizarFormaPagamento } from "./_VisualizarFormaPagamento";
import { DeleteTypes } from "../../../../Types/Util/DeleteTypes";

export function ListarFormaPagamento() {
  //Inicio Hooks
  const AlterarFormaPagamentoRef = useRef<ModalBaseType>();
  const VisualizarFormaPagamentoRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarFormaPagamentoTypes>({
    defaultValues: ObjListarFormaPagamentoPadrao,
  });
  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });
  //Final Hooks

  //------------------------

  //Inicio Consts
  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Nova Forma de Pagamento",
      Permissao: "sistema/classificacao/incluir",
      OnClick: () => {
        AlterarFormaPagamentoRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "sistema/classificacao/visualizar",
      OnClick: (e: ListarFormaPagamentoItensTypes) => {
        AlterarFormaPagamentoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Alterar",
      Permissao: "sistema/classificacao/alterar",
      OnClick: (e: ListarFormaPagamentoItensTypes) => {
        AlterarFormaPagamentoRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/classificacao/excluir",
      OnClick: (e: ListarFormaPagamentoItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarFormaPagamentoItensTypes>> = [
    {
      Campo: "ativo",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: [
        {
          nomeStatus: "true",
          cor: "green",
        },
        {
          nomeStatus: "false",
          cor: "red",
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 80,
      },
    },
    {
      Campo: "nome",
      Titulo: "Nome",
      Tipo: "Texto",
    },
    {
      Campo: "nomeTipoDocumento",
      Titulo: "Tipo Doc.",
      Tipo: "Texto",
    },
    {
      Campo: "nomePlanoDeContas",
      Titulo: "Plano De Contas",
      Tipo: "Texto",
    },

    {
      Campo: "baixarTitulo",
      Titulo: "Baixar Título",
      Tipo: "CheckBoxDisable",
    },
    {
      Campo: "exibirNaBaixaCtsReceber",
      Titulo: "Exibir Baixa",
      Tipo: "CheckBoxDisable",
    },
    {
      Campo: "exibirEmPedidoLoja",
      Titulo: "Exibir",
      Tipo: "CheckBoxDisable",
    },
    {
      Campo: "bloquearPedidoAguardandoConfirmacaoFinanceiro",
      Titulo: "Bloquear Pedido",
      Tipo: "CheckBoxDisable",
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarFormaPagamentoItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          titulo: "Id",
          valor: "id",
        },
        {
          titulo: "Nome",
          valor: "nome",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Forma de Pagamento"
          CarregarDados={methods.handleSubmit(RetornarFormasPagamento)}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarFormaPagamento
          CarregarDados={methods.handleSubmit(RetornarFormasPagamento)}
          ref={AlterarFormaPagamentoRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarFormaPagamento
          CarregarDados={methods.handleSubmit(RetornarFormasPagamento)}
          ref={VisualizarFormaPagamentoRef}
        />
      ),
    },
  ];

  //Final Consts

  //------------------------

  //Inicio Function

  async function RetornarFormasPagamento() {
    setLoadingState(true);
    var DadosFiltro = methods.getValues("Pesquisa");
    var data = await HttpFormaPagamento()
      .RetornarFormasPagamento(DadosFiltro)
      .finally(() => {
        setLoadingState(false);
      });
    methods.setValue("Itens", data);
  }
  async function RetornarDadosExclusao(data: FormFormaPagamentoTypes) {
    return await HttpFormaPagamento().RetornarDadosExclusao(
      data.Form.DadosRegistro
    );
  }
  async function Excluir(data: DeleteTypes) {
    await HttpFormaPagamento()
      .Excluir(data)
      .then(e => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  //Final Function

  //------------------------

  // Inicio JSX

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarFormaPagamentoTypes>
            id="Pesquisa.nome"
            label="Nome"
            placeholder="Nome"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarFormaPagamentoTypes>
            Link={"simnaoambos"}
            label={"Ativo"}
            id={"Pesquisa.ativoId"}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="sistema/classificacao/listar"
      Titulo="Forma de Pagamento"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarFormasPagamento)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
