import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../../Components/CheckBox/CGICheckBox";
import { useCGIToast } from "../../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIModal } from "../../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../../Context/LoadingContext";
import { FormTipoClienteType } from "../../../../Types/Sistema/Classificacao/TipoCliente/TipoClienteTypes";
import { HttpTipoCliente } from "./Http";

type AlterarTipoClienteType = {
  CarregarDados: Function;
};

export const AlterarTipoCliente = forwardRef(
  (props: AlterarTipoClienteType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current.Abrir();
      },
      Alterar(pEmpresa: any) {
        CGIModalRef.current.Abrir();

        HttpTipoCliente()
          .RetornarDadosTipoCliente(pEmpresa.id)
          .then((e) => {
            form.reset(e.data.data);
          });
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<FormTipoClienteType>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    //FINAL  HOOKS

    //INICIO CONST

    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Tipos de Cliente",
        Component: AreaDadosTipoCliente(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosTipoCliente() {
      return (
        <CGIBox>
          <CGIBoxItem Col={3}>
            <CGIInput<FormTipoClienteType>
              id="Form.DadosRegistro.nome"
              label="Nome"
              placeholder=""
              validadores={{
                required: "Nome obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormTipoClienteType>
              id="Form.DadosRegistro.clienteBloqNaoPodeGravarPedido"
              label="Clientes BLOQ, não pode gravar pedidos"
              tooltip=""
              att={{
                style: { marginTop: 30 },
              }}
              attLabel={{
                style: { marginTop: 30 },
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormTipoClienteType>
              id="Form.DadosRegistro.ativo"
              label="Ativo"
              tooltip=""
              att={{
                style: { marginTop: 30 },
              }}
              attLabel={{
                style: { marginTop: 30 },
              }}
            />
          </CGIBoxItem>
        </CGIBox>
      );
    }

    const Confirmar = async (data: FormTipoClienteType, event: any) => {
      setLoadingState(true);
      let Dados = form.getValues();

      if (
        Dados.Form.DadosRegistro.id == null ||
        Dados.Form.DadosRegistro.id === undefined ||
        Dados.Form.DadosRegistro.id === 0
      ) {
        HttpTipoCliente()
          .Incluir(Dados)
          .then((response: any) => {
            ToastSucesso("Registro gravado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return ToastErro(e);
          })
          .finally(() => {
            setLoadingState(false);
          });
      } else {
        HttpTipoCliente()
          .Alterar(data)
          .then((data: any) => {
            ToastSucesso("Registro Alterado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return;
          })
          .finally(() => {
            setLoadingState(false);
          });
      }
    };
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="sistema/classificacao/alterartipocliente"
        ref={CGIModalRef}
        Titulo="Tipo Cliente"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
