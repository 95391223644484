export const ObjConsultarCustoMedioPadrao: FormConsultarCustoMedioType = {
  Form: {
    DadosRegistro: {
      dataInicial: "",
      dataFinal: "",
      empresaId: 0,
      fornecedorId: 0,
      produtoId: 0,
    },
  },
  ItensNFEntrada: [],
  ItensPedidoEntrada: [],
  ItensMovEstoque: [],
};
export type ConsultarCustoMedioType = {
  DadosRegistro: {
    dataInicial: string;
    dataFinal: string;
    empresaId: Number;
    fornecedorId: Number;
    produtoId: Number;
  };
};
export type FormConsultarCustoMedioType = {
  Form: ConsultarCustoMedioType;
  ItensNFEntrada: ConsultarCustoMedioItensNFEntradaTypes[];
  ItensPedidoEntrada: ConsultarCustoMedioItensPedidoEntradaTypes[];
  ItensMovEstoque: ConsultarCustoMedioItensMovEstoqueTypes[];
};

export const ObjListarConsultarCustoMedioPadrao: ModalConsultarCustoMedioTypes =
  {
    Pesquisa: {
      dataInicial: "",
      dataFinal: "",
      empresaId: 0,
      produtoId: 0,
      fornecedorId: 0,
    },
    ItensNFEntrada: [],
    ItensPedidoEntrada: [],
    ItensMovEstoque: [],
  };

export type ModalConsultarCustoMedioTypes = {
  Pesquisa: PesquisaConsultarCustoMedioTypes;
  ItensNFEntrada: ConsultarCustoMedioItensNFEntradaTypes[];
  ItensPedidoEntrada: ConsultarCustoMedioItensPedidoEntradaTypes[];
  ItensMovEstoque: ConsultarCustoMedioItensMovEstoqueTypes[];
};

export type PesquisaConsultarCustoMedioTypes = {
  dataInicial: string;
  dataFinal: string;
  empresaId: Number;
  produtoId: Number;
  fornecedorId: Number;
};

export type ConsultarCustoMedioItensNFEntradaTypes = {
  id: Number;
  dataCadastro: string;
  nomeEmpresa: string;
  nomeFornecedor: string;
  numeroNF: Number;
  quantidade: Number;
  valorUnitario: Number;
  custoMedio: Number;
  nomeColaborador: string;
};

export type ConsultarCustoMedioItensMovEstoqueTypes = {
  id: Number;
  dataCadastro: string;
  quantidade: Number;
  valorUnitario: Number;
  custoMedio: Number;
  nomeEmpresa: string;
};

export type ConsultarCustoMedioItensPedidoEntradaTypes = {
  id: Number;
  dataCadastro: string;
  nomeEmpresa: string;
  nomeFornecedor: string;
  quantidade: Number;
  valorUnitario: Number;
  custoMedio: Number;
  nomeColaborador: string;
};
