import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../Context/LoadingContext";
import {
  FormBoletoBancarioType,
  BoletoBancarioTypes,
} from "../../../Types/Financeiro/BoletoBancario/BoletoBancarioTypes";
import { HttpBoletoBancario } from "./Http";

type VisualizarBoletoBancarioType = {
  CarregarDados: Function;
};

export const VisualizarBoletoBancario = forwardRef(
  (props: VisualizarBoletoBancarioType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir(pBoletoBancarioId: number) {
        CGIModalRef.current?.Abrir();
        //CarregarDados(pBoletoBancarioId);
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<BoletoBancarioTypes>();

    //FINAL  HOOKS

    //INICIO CONST
    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Dados BoletoBancario",
        Component: AreaDadosBoletoBancario(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosBoletoBancario() {
      return (
        <CGIBox>
          <CGIBoxItem Col={8}>
            <CGIInput<FormBoletoBancarioType>
              id="Form.DadosRegistro.colaboradorId"
              label="NCM"
              placeholder=""
              att={{
                isReadOnly: true,
              }}
            />
          </CGIBoxItem>
        </CGIBox>
      );
    }

    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="sistema/BoletoBancario/visualizar"
        ref={CGIModalRef}
        Titulo="Cadastro de BoletoBancario"
        TelaCheia={"3xl"}
        //CarregarDados={CarregarDados}
        Confirmar={() => {}}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
