import React from "react";

import { Button, ButtonProps, Tooltip } from "@chakra-ui/react";
import { ReactElement, useContext } from "react";
import { FiCheck, FiX } from "react-icons/fi";
import LoadingContext from "../../Context/LoadingContext";

interface ButtonType extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  icon?: ReactElement;
  att?: ButtonProps;
  tooltip?: React.ReactNode | string;
}
/**
 * @Componente CGIButtonCancelar - Botão padrão do Sistema para Cancelar
 * @Ref  CGIButton
 */
export function CGIButtonCancelar(props: ButtonType) {
  const { Loadingstate } = useContext(LoadingContext);

  return (
    <Button
      {...props}
      isLoading={Loadingstate}
      w={240}
      p="6"
      type="submit"
      bg="red.600"
      color="white"
      fontWeight="bold"
      fontSize="xl"
      mt="2"
      _hover={{ bg: "red.800" }}
      leftIcon={<FiX />}
    >
      Cancelar
    </Button>
  );
}
