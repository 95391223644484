export const ObjTipoContrato: FormTipoContratoType = {
  Form: {
    DadosRegistro: {
      id: 0,
      nome: "",
      ativo: true,
      planoDeContasId: 0,
      formaPagamentoId: 0,
      permiteAlterar: false,
      vencimentoProximoPeriodo: false,
      tokenZapSign: "",
      pastaZapSign: "",
      tipoRelatorioId: 0,
      geraFaturaMesRetirada: false,
      descricaoAuxiliar: "",
    },
  },
};

export type FormTipoContratoType = {
  Form: {
    DadosRegistro: TipoContratoTypes;
  };
};

type TipoContratoTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  permiteAlterar: boolean;
  vencimentoProximoPeriodo: boolean;
  formaPagamentoId: number;
  planoDeContasId: number;
  geraFaturaMesRetirada: boolean;
  tipoRelatorioId: number;
  descricaoAuxiliar: string;
  tokenZapSign: string;
  pastaZapSign: string;
};

export const ObjListarTipoContratoPadrao: ListarTipoContratoTypes = {
  Pesquisa: {
    titulo: "",
    nome: "",
    ativoId: 0,
  },
  Itens: [],
};

export type ListarTipoContratoTypes = {
  Pesquisa: ListarPesquisaTipoContratoTypes;
  Itens: ListarTipoContratoItensTypes[];
};

export type ListarPesquisaTipoContratoTypes = {
  titulo: string;
  nome: string;
  ativoId: number;
};

export type ListarTipoContratoItensTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  nomePlanoDeContas: string;
  nomeFormaDePagamento: string;
  permiteAlterar: boolean;
  vencimentoProximoPeriodo: boolean;
  formaPagamentoId: number;
  planoDeContasId: number;
  geraFaturaMesRetirada: boolean;
  tipoRelatorioId: number;
  descricaoAuxiliar: string;
};
