export const ObjAlterarFormaPagamentoPadrao: FormFormaPagamentoTypes = {
  Form: {
    DadosRegistro: {
      id: 0,
      nome: "",
      ativo: false,
      meioPagamentoId: 0,
      tipoDocumentoId: 0,
      planoDeContasId: 0,
      baixarTitulo: false,
      qtdDias: 0,
      exibirNaBaixaCtsReceber: false,
      exibirEmPedidoLoja: false,
      bloquearPedidoAguardandoConfirmacaoFinanceiro: false,
      registrarCodigoAutorizacao: false,
      lancarHistoricoLancamentosDoCaixa: false,
      gerarParcelamentoVencimento: false,
      taxaOperadora: 0,
    },
  },
};

export type FormFormaPagamentoTypes = {
  Form: {
    DadosRegistro: DadosRegistroTypes;
  };
};

export type DadosRegistroTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  meioPagamentoId: number;
  tipoDocumentoId: number;
  planoDeContasId: number;
  baixarTitulo: boolean;
  qtdDias: number;
  exibirNaBaixaCtsReceber: boolean;
  exibirEmPedidoLoja: boolean;
  bloquearPedidoAguardandoConfirmacaoFinanceiro: boolean;
  registrarCodigoAutorizacao: boolean;
  lancarHistoricoLancamentosDoCaixa: boolean;
  gerarParcelamentoVencimento: boolean;
  taxaOperadora: number;
};

export type FormaPagamentoVisualizarTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  nomeTipoDocumento: string;
  nomePlanoDeContas: string;
  nomeMeioPagamento: string;
  baixarTitulo: boolean;
  exibirNaBaixaCtsReceber: boolean;
  exibirEmPedidoLoja: boolean;
  bloquearPedidoAguardandoConfirmacaoFinanceiro: boolean;
};

export const ObjListarFormaPagamentoPadrao: ListarFormaPagamentoTypes = {
  Pesquisa: {
    nome: "",
    ativoId: 0,
  },
  Itens: [],
};
export type ListarFormaPagamentoTypes = {
  Pesquisa: ListarPesquisaFormaPagamentoTypes;
  Itens: ListarFormaPagamentoItensTypes[];
};

export type ListarPesquisaFormaPagamentoTypes = {
  nome: string;
  ativoId: number;
};

export type ListarFormaPagamentoItensTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  nomeTipoDocumento: string;
  nomePlanoDeContas: string;
  baixarTitulo: boolean;
  exibirNaBaixaCtsReceber: boolean;
  exibirEmPedidoLoja: boolean;
  bloquearPedidoAguardandoConfirmacaoFinanceiro: boolean;
};
