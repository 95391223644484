import { CGIHttp } from "../../../Services/Axios";
import {
  FormTIPIType,
  ListarPesquisaTIPITypes,
  ListarTIPIItensTypes,
  TIPIPesquisaGridTypes,
  TIPITypes,
  TIPIVisualizarTypes,
} from "../../../Types/Sistema/TIPI/TIPITypes";
//import { TIPIGridTypes } from "../../../Types/Sistema/TIPI/TIPITypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpTIPI() {
  const url = "/tipi";

  async function RetornarDadosGridPesquisa(
    dados: TIPIPesquisaGridTypes
  ): Promise<ListarTIPIItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-tipi", dados)
      .then(e => e.data.data);
  }

  async function RetornarTIPIs(
    DadosFiltros: ListarPesquisaTIPITypes
  ): Promise<ListarTIPIItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(url + "/obter-tipis", DadosFiltros)
    ).data.data;
  }

  async function RetornarDadosTIPI(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + `/alterar/${id}`)
    ).data.data) as TIPITypes;
  }

  async function RetornarDadosExclusao(Dados: ListarTIPIItensTypes) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + Dados.id)
      .then(res => res.data.data)) as DeleteTypes;
  }
  async function RetornarDadosVisualizarTIPI(DadosTIPI: FormTIPIType) {
    return (await (
      await new CGIHttp().conn.get(url + "/visualizar/" + DadosTIPI.Form)
    ).data.data) as TIPITypes;
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir/" + Dados.id);
  }
  async function Alterar(DadosTIPI: FormTIPIType) {
    return await new CGIHttp().conn.post(url + "/alterar", DadosTIPI.Form);
  }
  async function Visualizar(DadosTIPI: FormTIPIType) {
    return await new CGIHttp().conn.post(url + "/visualizar", DadosTIPI.Form);
  }
  async function Incluir(DadosTIPI: FormTIPIType) {
    return await new CGIHttp().conn.post(url + "/incluir", DadosTIPI.Form);
  }
  return {
    RetornarTIPIs,
    RetornarDadosGridPesquisa,
    RetornarDadosExclusao,
    RetornarDadosVisualizarTIPI,
    RetornarDadosTIPI,
    Visualizar,
    Excluir,
    Alterar,
    Incluir,
  };
}
