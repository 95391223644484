import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import {
  ColaboradorTypes,
  ListarColaboradorGridItensTypes,
  ListarColaboradorItensTypes,
  ListarColaboradorTypes,
  ListarPesquisaColaboradorTypes,
  ObjListarColaboradorPadrao,
  FormColaboradorType,
  ColaboradorVisualizarTypes,
} from "../../../Types/Financeiro/Colaborador/ColaboradorTypes";
import { HttpColaborador } from "./Http";

type VisualizarColaboradorType = {
  CarregarDados: Function;
};
export const VisualizarColaborador = forwardRef(
  (props: VisualizarColaboradorType, ref) => {
    // INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current?.Abrir();
      },
    }));
    // FINAL REF

    const CGIModalRef = useRef<ModalBaseType>(null);
    const { reset } = useFormContext();

    async function CarregarDados(params: FormColaboradorType) {
      var data = await HttpColaborador().Visualizar(params.Form.DadosRegistro);
      reset(data.data.data);
    }

    return (
      <CGIModal
        id="financeiro/colaborador/visualizar"
        ref={CGIModalRef}
        Titulo="Visualizar Equipamento"
        TelaCheia={"6xl"}
        Confirmar={() => {}}
        Fechar={() => {}}
        ocultarCancelar={true}
        ocultarConfirmar={true}
      >
        <CGIBox>
          <CGIBoxItem Col={3}>
            <CGIInput<ColaboradorVisualizarTypes>
              id="id"
              label="Id"
              placeholder="Id"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<ColaboradorVisualizarTypes>
              id="nomeCompleto"
              label="Nome Completo"
              placeholder="Nome Completo"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<ColaboradorVisualizarTypes>
              id="nomeFantasia"
              label="Nome Fantasia"
              placeholder="Nome Fantasia"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<ColaboradorVisualizarTypes>
              id="nomeTipoTributacao"
              label="Tipo Tributação"
              placeholder="Tipo Tributação"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<ColaboradorVisualizarTypes>
              id="cpfcnpj"
              label="CPF \ CNPJ"
              placeholder="CPF \ CNPJ"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<ColaboradorVisualizarTypes>
              id="nomeEmpresa"
              label="Empresa"
              placeholder="Empresa"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
  }
);
