import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIGrafico } from "../../../Components/Graficos/CGIGrafico";
import { CGIMeta } from "../../../Components/Meta/CGIMeta";
import { CGITableResumo } from "../../../Components/TableResumo/CGITableResumo";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import { BITabsTypes, BITypes } from "../../../Types/Sistema/BI/BITypes";
import { HttpBI } from "./Http";

export function VisualizarBI() {
  const BIId = useParams().id;
  const [DadosBI, setDadosBI] = useState<BITypes>();
  const [Tabs, setTabs] = useState<CGITabItensTypes[]>([]);
  useEffect(() => {
    RetornarDadosBI();
  }, []);

  const RenderElementos = (tab: BITabsTypes) => {
    return (
      <CGIBox>
        {tab.itens
          .sort((a, b) => a.sequencia - b.sequencia)
          .map((e, index) => {
            console.log(e);
            if (e.tipoItem === "GRAFICO") {
              return (
                <CGIBoxItem key={index} Col={e.coluna as any}>
                  <CGIGrafico tipo={e.tipo} chave={e.chave} />
                </CGIBoxItem>
              );
            } else if (e.tipoItem === "META") {
              return (
                <CGIBoxItem key={index} Col={e.coluna as any}>
                  <CGIMeta link={e.chave} />
                </CGIBoxItem>
              );
            } else if (e.tipoItem === "RESUMO") {
              return (
                <CGIBoxItem key={index} Col={e.coluna as any}>
                  <CGITableResumo titulo={e.chave} link={e.chave} />
                </CGIBoxItem>
              );
            } else {
              return <></>;
            }
          })}
      </CGIBox>
    );
  };

  const RetornarDadosBI = async () => {
    var Dados = await HttpBI().RetornarDadosBI(BIId as string);
    setDadosBI(Dados);
    setTabs(
      Dados.tabs.map(tab => {
        return {
          Titulo: tab.titulo,
          Component: RenderElementos(tab),
        };
      })
    );
  };

  return (
    <CGIBox titulo={DadosBI?.titulo}>
      <CGIBoxItem Col={12}>
        <CGITabs Tabs={Tabs} />
      </CGIBoxItem>
    </CGIBox>
  );
}
