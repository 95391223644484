import { AddIcon } from "@chakra-ui/icons";
import { useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";

import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarSaldoGeralItensTypes,
  ListarSaldoGeralTypes,
} from "../../../Types/Financeiro/MovPortador/MovPortadorTypes";
import { HttpMovPortador } from "./Http";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";

export function SaldoGeral() {
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarSaldoGeralTypes>();

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarSaldoGeral = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpMovPortador().RetornarSaldoGeral(DadosFiltros);
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const ListarMenu: Array<MenuTypes> = [];

  const GridColunas: Array<CGICol<ListarSaldoGeralItensTypes>> = [
    {
      Campo: "nomePortador",
      Titulo: "Portador",
      Tipo: "Texto",
      att: { maxWidth: 400 },
    },
    {
      Campo: "nomeEmpresa",
      Titulo: "Empresa",
      Tipo: "Texto",
    },
    {
      Campo: "nomeTipoPortador",
      Titulo: "Tipo",
      Tipo: "Texto",
    },
    {
      Campo: "saldo",
      Titulo: "Saldo (R$)",
      Tipo: "Numero",
      att: { maxWidth: 150 },
      ExibirCoresConformeValor: true,
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          valor: "nomePortador",
          titulo: "Portador",
        },
        {
          valor: "nomeEmpresa",
          titulo: "Empresa",
        },
        {
          valor: "nomeTipoPortador",
          titulo: "Tipo",
        },
        {
          valor: "saldo",
          titulo: "Saldo (R$)",
        },
      ],
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIComboSimples<ListarSaldoGeralTypes>
            id="Pesquisa.listaEmpresasId"
            label="Empresa"
            Link={"empresa"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Tipo" popover="Tipo">
            <CGIBoxItem Col={2}>
              <CGICheckBox<ListarSaldoGeralTypes>
                id="Pesquisa.ehCaixaBancoCaixa"
                label="Caixa"
                tooltip="Caixa"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={2}>
              <CGICheckBox<ListarSaldoGeralTypes>
                id="Pesquisa.ehCaixaBancoBanco"
                label="Banco"
                tooltip="Banco"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={2}>
              <CGICheckBox<ListarSaldoGeralTypes>
                id="Pesquisa.ehCaixaBancoCliente"
                label="Cliente"
                tooltip="Cliente"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={2}>
              <CGICheckBox<ListarSaldoGeralTypes>
                id="Pesquisa.ehCaixaBancoVendedor"
                label="Vendedor"
                tooltip="Vendedor"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={2}>
              <CGICheckBox<ListarSaldoGeralTypes>
                id="Pesquisa.ehCaixaBancoFornecedor"
                label="Fornecedor"
                tooltip="Fornecedor"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="financeiro/movportador/saldogeral"
      Titulo="Consultar Saldo Geral"
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarSaldoGeral)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
