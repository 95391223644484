import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../../Components/CheckBox/CGICheckBox";
import { useCGIToast } from "../../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIModal } from "../../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../../Context/LoadingContext";
import { FormTipoOrcamentoPedidoType } from "../../../../Types/Sistema/Classificacao/TipoOrcamentoPedido/TipoOrcamentoPedidoTypes";
import { HttpTipoOrcamentoPedido } from "./Http";

type AlterarTipoOrcamentoPedidoType = {
  CarregarDados: Function;
};

export const AlterarTipoOrcamentoPedido = forwardRef(
  (props: AlterarTipoOrcamentoPedidoType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current.Abrir();
      },
      Alterar(pEmpresa: any) {
        CGIModalRef.current.Abrir();

        HttpTipoOrcamentoPedido()
          .RetornarDadosTipoOrcamentoPedido(pEmpresa.id)
          .then((e) => {
            form.reset(e.data.data);
          });
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<FormTipoOrcamentoPedidoType>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    //FINAL  HOOKS

    //INICIO CONST

    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Tipo Orçamento Pedidos",
        Component: AreaDadosTipoOrcamentoPedido(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosTipoOrcamentoPedido() {
      return (
        <CGIBox>
          <CGIBoxItem Col={3}>
            <CGIBox>
              <CGIBoxItem Col={12}>
                <CGIInput<FormTipoOrcamentoPedidoType>
                  id="Form.DadosRegistro.nome"
                  label="Nome"
                  placeholder=""
                  validadores={{
                    required: "Nome obrigatório",
                  }}
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={9}>
            <CGIBox>
              <CGIBoxItem Col={3}>
                <CGICheckBox<FormTipoOrcamentoPedidoType>
                  id="Form.DadosRegistro.exibirCalendarios"
                  label="Exibir Calendários / Controlar Como Pedido"
                  tooltip=""
                  att={{
                    style: { marginTop: 30 },
                  }}
                  attLabel={{
                    style: { marginTop: 30 },
                  }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGICheckBox<FormTipoOrcamentoPedidoType>
                  id="Form.DadosRegistro.enviarAutomaticamenteExpedicao"
                  label="Enviar Automatic. Expedição"
                  tooltip=""
                  att={{
                    style: { marginTop: 30 },
                  }}
                  attLabel={{
                    style: { marginTop: 30 },
                  }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGICheckBox<FormTipoOrcamentoPedidoType>
                  id="Form.DadosRegistro.permitirEnviarExpedicao"
                  label="Permitir Enviar Expedição"
                  tooltip=""
                  att={{
                    style: { marginTop: 30 },
                  }}
                  attLabel={{
                    style: { marginTop: 30 },
                  }}
                />
              </CGIBoxItem>

              <CGIBoxItem Col={3}>
                <CGICheckBox<FormTipoOrcamentoPedidoType>
                  id="Form.DadosRegistro.permitirEnviarFaturamento"
                  label="Permitir Enviar Faturamento"
                  tooltip=""
                  att={{
                    style: { marginTop: 30 },
                  }}
                  attLabel={{
                    style: { marginTop: 30 },
                  }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGICheckBox<FormTipoOrcamentoPedidoType>
                  id="Form.DadosRegistro.ativo"
                  label="Ativo"
                  tooltip=""
                  att={{
                    style: { marginTop: 30 },
                  }}
                  attLabel={{
                    style: { marginTop: 30 },
                  }}
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
        </CGIBox>
      );
    }

    const Confirmar = async (data: FormTipoOrcamentoPedidoType, event: any) => {
      setLoadingState(true);
      let Dados = form.getValues();

      if (
        Dados.Form.DadosRegistro.id == null ||
        Dados.Form.DadosRegistro.id === undefined ||
        Dados.Form.DadosRegistro.id === 0
      ) {
        HttpTipoOrcamentoPedido()
          .Incluir(Dados)
          .then((response: any) => {
            ToastSucesso("Registro gravado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return ToastErro(e);
          })
          .finally(() => {
            setLoadingState(false);
          });
      } else {
        HttpTipoOrcamentoPedido()
          .Alterar(data)
          .then((data: any) => {
            ToastSucesso("Registro Alterado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return;
          })
          .finally(() => {
            setLoadingState(false);
          });
      }
    };
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="sistema/classificacao/alterartipoorcamentopedido"
        ref={CGIModalRef}
        Titulo="Tipo Orçamento Pedido"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
