import { Divider, Flex, Tag, TagLabel, Box, Text } from "@chakra-ui/react";
import { useContext, useCallback, useEffect } from "react";
import { useForm, useWatch, FormProvider } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGIComboAjax } from "../../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../../Components/Combos/CGIComboSimples";
import { CGIDataTableSimples } from "../../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../../Components/Listar/CGIListar";
import { CGIResumo } from "../../../../Components/Resumo/CGIResumo";
import { CGITabs } from "../../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../../Context/LoadingContext";
import {
  AnaliseCreditoTypes,
  ObjAnaliseCreditoPadrao,
  AnaliseCreditoOrcamentoItensType,
  AnaliseCreditoItensOrcamentoItensType,
  AnaliseCreditoPedidoItensType,
  AnaliseCreditoItensPedidoItensType,
  AnaliseCreditoNotasFiscaisItensType,
  AnaliseCreditoHistoricoFinanceiroItensType,
  AnaliseCreditoContasReceberEmAtraso,
  AnaliseCreditoResumoType,
} from "../../../../Types/Financeiro/Colaborador/AnaliseCreditoTypes";
import { LegendaTypes } from "../../../../Types/Modal/ModalBaseType";
import { HttpColaborador } from "../Http";
import { AreaDadosCliente } from "./Areas/Cliente";
import { AreaDetalhes } from "./Areas/Detalhes";
import { AreaResumo } from "./Areas/Resumo";

export function AnaliseCredito() {
  //Inicio Hooks
  const { setLoadingState } = useContext(LoadingContext);
  const form = useForm<AnaliseCreditoTypes>({
    defaultValues: ObjAnaliseCreditoPadrao,
  });
  const ClienteId = useWatch({
    control: form.control,
    name: "Form.clienteId",
  });

  useEffect(() => {
    if (ClienteId === 0) return;
    try {
      setLoadingState(true);
      CarregarGrids();
    } finally {
      setLoadingState(false);
    }
  }, [ClienteId]);

  function CarregarGrids() {
    Promise.all([
      CarregarGridOrcamento,
      CarregarGridItensOrcamento,
      CarregarGridPedido,
      CarregarGridItensPedido,
      CarregarGridNotasFiscais,
      CarregarGridHistorico,
      CarregarContasReceberEmAtraso,
      CarregarResumos,
    ]).then(e => {
      form.setValue("Grids.Orcamento.Itens", e[0]);
      form.setValue("Grids.ItensOrcamento.Itens", e[1]);
      form.setValue("Grids.Pedido.Itens", e[2]);
      form.setValue("Grids.ItensPedido.Itens", e[3]);
      form.setValue("Grids.NotasFiscais.Itens", e[4]);
      form.setValue("Grids.HistoricoFinanceiro.Itens", e[5]);
      form.setValue("Grids.ContasReceberEmAtraso.Itens", e[6]);
      form.setValue("Resumos", e[7]);
    });
  }
  const CarregarResumos = new Promise<AnaliseCreditoResumoType>(
    async (resolve, reject) => {
      try {
        var pColaboradorId = form.getValues("Form.clienteId");
        var Dados =
          await HttpColaborador().RetornarDadosResumoAnaliseCredito<AnaliseCreditoResumoType>(
            pColaboradorId
          );
        resolve(Dados);
      } catch (e) {
        reject(ObjAnaliseCreditoPadrao.Resumos);
      }
    }
  );

  const CarregarGridOrcamento = new Promise<AnaliseCreditoOrcamentoItensType[]>(
    async (resolve, reject) => {
      try {
        var pColaboradorId = form.getValues("Form.clienteId");
        var Tipo = "ORCAMENTO";
        var Dados = await HttpColaborador().RetornarDadosGridAnaliseCredito<
          AnaliseCreditoOrcamentoItensType[]
        >(pColaboradorId, Tipo);

        resolve(Dados);
      } catch (error) {
        reject([]);
      }
    }
  );
  const CarregarGridItensOrcamento = new Promise<
    AnaliseCreditoItensOrcamentoItensType[]
  >(async (resolve, reject) => {
    try {
      var pColaboradorId = form.getValues("Form.clienteId");
      var Tipo = "ORCAMENTO-ITENS";
      var Dados = await HttpColaborador().RetornarDadosGridAnaliseCredito<
        AnaliseCreditoItensOrcamentoItensType[]
      >(pColaboradorId, Tipo);
      resolve(Dados);
    } catch (error) {
      reject([]);
    }
  });
  const CarregarGridPedido = new Promise<AnaliseCreditoPedidoItensType[]>(
    async (resolve, reject) => {
      try {
        var pColaboradorId = form.getValues("Form.clienteId");
        var Tipo = "PEDIDO";
        var Dados = await HttpColaborador().RetornarDadosGridAnaliseCredito<
          AnaliseCreditoPedidoItensType[]
        >(pColaboradorId, Tipo);
        resolve(Dados);
      } catch (error) {
        reject([]);
      }
    }
  );
  const CarregarGridItensPedido = new Promise<
    AnaliseCreditoItensPedidoItensType[]
  >(async (resolve, reject) => {
    try {
      var pColaboradorId = form.getValues("Form.clienteId");
      var Tipo = "PEDIDO-ITENS";
      var Dados = await HttpColaborador().RetornarDadosGridAnaliseCredito<
        AnaliseCreditoItensPedidoItensType[]
      >(pColaboradorId, Tipo);
      resolve(Dados);
    } catch (error) {
      reject([]);
    }
  });
  const CarregarGridNotasFiscais = new Promise<
    AnaliseCreditoNotasFiscaisItensType[]
  >(async (resolve, reject) => {
    try {
      var pColaboradorId = form.getValues("Form.clienteId");
      var Tipo = "NFSAIDA";
      var Dados = await HttpColaborador().RetornarDadosGridAnaliseCredito<
        AnaliseCreditoNotasFiscaisItensType[]
      >(pColaboradorId, Tipo);
      resolve(Dados);
    } catch (error) {
      reject([]);
    }
  });
  const CarregarGridHistorico = new Promise<
    AnaliseCreditoHistoricoFinanceiroItensType[]
  >(async (resolve, reject) => {
    try {
      var pColaboradorId = form.getValues("Form.clienteId");
      var { DataVencimentoFinal, DataVencimentoInical } = form.getValues(
        "Grids.HistoricoFinanceiro.Pesquisa"
      );
      if (!DataVencimentoFinal || !DataVencimentoInical) return resolve([]);
      var Tipo = "CTSRECEBER-HISTORICO";
      var Dados = await HttpColaborador().RetornarDadosGridAnaliseCredito<
        AnaliseCreditoHistoricoFinanceiroItensType[]
      >(pColaboradorId, Tipo, DataVencimentoFinal, DataVencimentoInical);
      resolve(Dados);
    } catch (error) {
      reject([]);
    }
  });
  const CarregarContasReceberEmAtraso = new Promise<
    AnaliseCreditoContasReceberEmAtraso[]
  >(async (resolve, reject) => {
    try {
      var pColaboradorId = form.getValues("Form.clienteId");
      var Tipo = "CTSRECEBER";
      var Dados = await HttpColaborador().RetornarDadosGridAnaliseCredito<
        AnaliseCreditoContasReceberEmAtraso[]
      >(pColaboradorId, Tipo);
      resolve(Dados);
    } catch (error) {
      reject([]);
    }
  });
  //Final Hooks

  //------------------------

  //Inicio Consts

  return (
    <CGIListar
      id="financeiro/colaborador/analisecredito"
      Titulo="Análise de Crédito"
      Janelas={[]}
    >
      <FormProvider {...form}>
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIComboAjax<AnaliseCreditoTypes>
              id={"Form.clienteId"}
              Link={"cliente"}
              label={"Cliente"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGITabs
              Tabs={[
                {
                  Titulo: "Dados Cliente",
                  Component: AreaDadosCliente(),
                },
                {
                  Titulo: "Resumos",
                  Component: AreaResumo(form),
                },
                {
                  Titulo: "Detalhamentos",
                  Component: AreaDetalhes(form),
                },
              ]}
            />
          </CGIBoxItem>
        </CGIBox>
      </FormProvider>
    </CGIListar>
  );
}
