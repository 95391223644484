import { useRef, useContext, useCallback, useState } from "react";
import {
  useForm,
  FormProvider,
  useWatch,
  useFormContext,
} from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import {
  CGICol,
  ConvertCol,
} from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import LoadingContext from "../../../Context/LoadingContext";

import {
  ModalBaseType,
  LegendaTypes,
} from "../../../Types/Modal/ModalBaseType";

import { HttpMovEstoque } from "./Http";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import {
  AlterarAtualizarSaldoItensTypes,
  AlterarAtualizarSaldoTypes,
  ObjAlterarAtualizarSaldoPadrao,
} from "../../../Types/Almoxarifado/MovEstoque/AtualizarSaldoType";
import { Button, Text } from "@chakra-ui/react";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { TotaisInternos } from "../../Sistema/Produto/_TotaisInternos";

export function AtualizarSaldo() {
  const ExibirPedidosRef = useRef<ModalBaseType>();
  const ExibirOrcamentoRef = useRef<ModalBaseType>();
  const ConsultarMovEstoqueRef = useRef<ModalBaseType>();
  const AlterarPrecoNasEmpresasRef = useRef<ModalBaseType>();
  const AlterarEstoqueNasEmpresasRef = useRef<ModalBaseType>();
  const VisualizarProdutoRef = useRef<ModalBaseType>();
  const ConsultarTotaisInternosRef = useRef<ModalBaseType>();

  const AlterarProdutoRef = useRef<ModalBaseType>();
  const ConsultarPedidosRef = useRef<ModalBaseType>();
  const ConsultarEstoqueRef = useRef<ModalBaseType>();
  const ConsultarMovProdutoRef = useRef<ModalBaseType>();
  const NovaMovEstoqueRef = useRef<ModalBaseType>();

  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<AlterarAtualizarSaldoTypes>({
    defaultValues: ObjAlterarAtualizarSaldoPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarAtualizarSaldo = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpMovEstoque().RetornarAtualizarSaldo(DadosFiltros);
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const AtualizarSaldo = async () => {
    var DadosGrid = methods.getValues("Itens");
    await HttpMovEstoque().AtualizarSaldo(DadosGrid);
    RetornarAtualizarSaldo();
  };

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar Produto",
      Permissao: "sistema/produto/visualizar",
      OnClick: (e: AlterarAtualizarSaldoItensTypes) => {
        VisualizarProdutoRef.current?.Abrir(e.produtoId);
      },
    },
    {
      Nome: "Alterar Produto",
      Permissao: "sistema/produto/alterar",
      OnClick: (e: AlterarAtualizarSaldoItensTypes) => {
        AlterarProdutoRef.current?.Abrir(e.produtoId);
      },
    },
    {
      Nome: "Consultar Pedidos",
      Permissao: "vendas/pedido/listar",
      OnClick: (e: AlterarAtualizarSaldoItensTypes) => {
        ConsultarPedidosRef.current?.Abrir(e.produtoId);
      },
    },
    {
      Nome: "Consultar Orçamentos",
      Permissao: "vendas/orcamento/listar",
      OnClick: (e: AlterarAtualizarSaldoItensTypes) => {
        ConsultarPedidosRef.current?.Abrir(e.produtoId);
      },
    },
    {
      Nome: "Consultar Estoque nas Empresas",
      Permissao: "almoxarifado/movestoque/estoquegeral",
      OnClick: (e: AlterarAtualizarSaldoItensTypes) => {
        ConsultarEstoqueRef.current?.Abrir(e.produtoId);
      },
    },
    {
      Nome: "Consultar Mov. do Produto",
      Permissao: "almoxarifado/movestoque/estoquegeral",
      OnClick: (e: AlterarAtualizarSaldoItensTypes) => {
        ConsultarMovProdutoRef.current?.Abrir(e.produtoId);
      },
    },
    {
      Nome: "Consultar Totais Internos",
      Permissao: "sistema/produto/consultartotaisinterno",
      OnClick: (e: AlterarAtualizarSaldoItensTypes) => {
        ConsultarTotaisInternosRef.current?.Abrir(e.produtoId);
      },
    },
  ];

  const GridColunas: Array<CGICol<AlterarAtualizarSaldoItensTypes>> = [
    {
      Campo: "produtoId",
      Titulo: "",
      Tipo: "Numero",
      ocultar: true,
    },
    {
      Campo: "empresaId",
      Titulo: "",
      Tipo: "Numero",
      ocultar: true,
    },
    {
      Campo: "codigoProduto",
      Titulo: "Código",
      Tipo: "Texto",
      att: { maxWidth: 150 },
    },
    {
      Campo: "id",
      Titulo: "Cod. Produto/Descrição/Unidade",
      Tipo: "Composta",
      att: { maxWidth: 900 },
      Composta: [
        {
          titulo: "Código Produto",
          valor: "codigoProduto",
          exibirLabel: true,
          negrito: true,
        },
        {
          titulo: "Descrição",
          valor: "descricao",
          exibirLabel: true,
        },
        {
          titulo: "UND",
          valor: "nomeUnidade",
          exibirLabel: true,
        },
      ],
    },
    {
      Campo: "estoque",
      Titulo: "Saldo",
      Tipo: "Numero",
      att: { maxWidth: 100 },
    },
    {
      Campo: "novoSaldoInformado",
      Titulo: "Novo Saldo",
      Tipo: "Numero",
      att: {
        editable: true,
        maxWidth: 150,
      },
    },
    {
      Campo: "id",
      Titulo: "Fornecedor/Grupo/SubGrupo",
      Tipo: "Composta",
      att: { maxWidth: 400 },
      Composta: [
        {
          titulo: "Fornecedor",
          valor: "nomeFornecedor",
          exibirLabel: true,
          negrito: true,
        },
        {
          titulo: "Grupo",
          valor: "nomeGrupo",
          exibirLabel: true,
        },
        {
          titulo: "SubGrupo",
          valor: "nomeSubGrupo",
          exibirLabel: true,
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: any) => MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];
  const Janelas: Array<{ Componente: React.ReactNode }> = [
    {
      Componente: (
        <TotaisInternos
          CarregarDados={() => {}}
          ref={ConsultarTotaisInternosRef}
        />
      ),
    },
  ];

  return (
    <CGIListar
      id="almoxarifado/movestoque/atualizarsaldo"
      Titulo="Atualizar Saldo"
      Janelas={Janelas}
      tipoGET={{
        url: "ATUALIZARSALDO-LISTAR",
        setRetorno: e => {
          methods.setValue("Pesquisa", e);
        },
      }}
    >
      <FormProvider {...methods}>
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIBox titulo="Filtro">
              <CGIBoxItem Col={4}>
                <CGIComboSimples<AlterarAtualizarSaldoTypes>
                  id={"Pesquisa.empresaId"}
                  Link={"empresa"}
                  label={"Empresa"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<AlterarAtualizarSaldoTypes>
                  id={"Pesquisa.codigoProduto"}
                  placeholder={"Código Produto"}
                  label={"Código Produto"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={5}>
                <CGIInput<AlterarAtualizarSaldoTypes>
                  id={"Pesquisa.descricao"}
                  placeholder={"Descrição"}
                  label={"Descrição"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<AlterarAtualizarSaldoTypes>
                  id={"Pesquisa.codigoBarras"}
                  placeholder={"Código Barras"}
                  label={"Código Barras"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIComboSimples<AlterarAtualizarSaldoTypes>
                  id={"Pesquisa.listaGruposId"}
                  label={"Grupo"}
                  Link={"grupo"}
                  Multipla
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIComboSimples<AlterarAtualizarSaldoTypes>
                  id={"Pesquisa.listaSubGruposId"}
                  label={"Sub Grupos"}
                  Link={"subgrupo"}
                  Multipla
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIComboSimples<AlterarAtualizarSaldoTypes>
                  id={"Pesquisa.listaTiposProdutoId"}
                  Link={"tipodeproduto"}
                  label={"Tipo Produto"}
                  Multipla
                />
              </CGIBoxItem>
              <CGIBoxItem Col={6}>
                <CGIComboAjax<AlterarAtualizarSaldoTypes>
                  id={"Pesquisa.listaFornecedoresId"}
                  label={"Fornecedores"}
                  Link={"fornecedor"}
                  Multipla
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIDatePicker<AlterarAtualizarSaldoTypes>
                  id={"Pesquisa.dataMovimentacao"}
                  label={"Data Movimentação"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIButton
                  att={{
                    mt: 30,
                  }}
                  label="Pesquisar"
                  onClick={RetornarAtualizarSaldo}
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIDataTableSimples
              DisableGetId
              Altura="400px"
              Colunas={GridColunas}
              Data={GridItens}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <Text fontWeight={"bold"} color="red">
              * Essa tela não movimentará Produtos que tem o controle de Lote.
            </Text>
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIButton
              att={{
                marginLeft: "1400px",
              }}
              label="Confirmar"
              onClick={AtualizarSaldo}
            />
          </CGIBoxItem>
        </CGIBox>
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
