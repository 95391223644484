import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../Context/LoadingContext";
import {
  FormAlocacaoType,
  AlocacaoTypes,
} from "../../../Types/Almoxarifado/Alocacao/AlocacaoTypes";
import { HttpAlocacao } from "./Http";

type VisualizarAlocacaoType = {
  CarregarDados: Function;
};

export const VisualizarAlocacao = forwardRef(
  (props: VisualizarAlocacaoType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir(pAlocacaoId: number) {
        CGIModalRef.current?.Abrir();
        //CarregarDados(pAlocacaoId);
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<AlocacaoTypes>();

    //FINAL  HOOKS

    //INICIO CONST
    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Dados Alocacao",
        Component: AreaDadosAlocacao(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosAlocacao() {
      return (
        <CGIBox>
          <CGIBoxItem Col={8}>
            <CGIInput<FormAlocacaoType>
              id="Form.dadosRegistro.ncm"
              label="NCM"
              placeholder=""
              att={{
                isReadOnly: true,
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={8}>
            <CGIInput<FormAlocacaoType>
              id="Form.dadosRegistro.percIPI"
              label="Perc. IPI"
              placeholder=""
              att={{
                isReadOnly: true,
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={8}>
            <CGIInput<FormAlocacaoType>
              id="Form.dadosRegistro.ex"
              label="Ex"
              placeholder=""
              att={{
                isReadOnly: true,
              }}
            />
          </CGIBoxItem>
        </CGIBox>
      );
    }

    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="sistema/Alocacao/visualizar"
        ref={CGIModalRef}
        Titulo="Cadastro de Alocacao"
        TelaCheia={"3xl"}
        //CarregarDados={CarregarDados}
        Confirmar={() => {}}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
