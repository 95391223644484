import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../Context/LoadingContext";
import { FormAlocacaoType } from "../../../Types/Almoxarifado/Alocacao/AlocacaoTypes";
import { HttpAlocacao } from "./Http";

type AlterarAlocacaoType = {
  CarregarDados: Function;
};

export const AlterarAlocacao = forwardRef((props: AlterarAlocacaoType, ref) => {
  //INICIO REF
  useImperativeHandle(ref, () => ({
    Abrir() {
      CGIModalRef.current.Abrir();
    },
    async Alterar(pAlocacao: any) {
      CGIModalRef.current.Abrir();
      var Dados = await HttpAlocacao().RetornarDadosAlocacao(pAlocacao.id);
      form.setValue("Form", Dados);
    },
  }));
  //FINAL  REF

  //INICIO  HOOKS
  const CGIModalRef = useRef() as any;
  const form = useFormContext<FormAlocacaoType>();
  const { ToastErro, ToastSucesso } = useCGIToast();
  const { setLoadingState } = useContext(LoadingContext);

  //FINAL  HOOKS

  //INICIO CONST
  const RenderTabs: Array<CGITabItensTypes> = [
    {
      Titulo: "Dados Alocacao",
      Component: AreaDadosAlocacao(),
    },
  ];
  //FINAL  CONST

  //INICO FUNC
  function AreaDadosAlocacao() {
    return (
      <CGIBox>
        <CGIBoxItem Col={8}>
          <CGIInput<FormAlocacaoType>
            id="Form.dadosRegistro.ncm"
            label="NCM"
            placeholder=""
            att={{
              maxLength: 8,
            }}
            validadores={{
              required: "NCM Alocacao obrigatório",
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={8}>
          <CGIInput<FormAlocacaoType>
            id="Form.dadosRegistro.percIPI"
            label="Perc. IPI"
            placeholder=""
          />
        </CGIBoxItem>
        <CGIBoxItem Col={8}>
          <CGIInput<FormAlocacaoType>
            id="Form.dadosRegistro.ex"
            label="Ex"
            placeholder=""
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  const Confirmar = async (data: FormAlocacaoType, event: any) => {
    setLoadingState(true);

    if (
      data.Form.dadosRegistro.id == null ||
      data.Form.dadosRegistro.id === undefined ||
      data.Form.dadosRegistro.id === 0
    ) {
      HttpAlocacao()
        .Incluir(data)
        .then((response: any) => {
          ToastSucesso("Registro gravado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
          console.log(data);
        })
        .catch((e) => {
          return ToastErro(e);
        })
        .finally(() => {
          setLoadingState(false);
        });
    } else {
      HttpAlocacao()
        .Alterar(data)
        .then((data: any) => {
          ToastSucesso("Registro Alterado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
        })
        .catch((e) => {
          return;
        })
        .finally(() => {
          setLoadingState(false);
        });
    }
  };
  //FINAL FUNC

  //INICO JSX
  return (
    <CGIModal
      id="sistema/Alocacao/alterar"
      ref={CGIModalRef}
      Titulo="Cadastro de Alocacao"
      TelaCheia={"3xl"}
      Confirmar={Confirmar}
      Fechar={() => {
        form.reset({});
      }}
    >
      <CGITabs Tabs={RenderTabs} />
    </CGIModal>
  );
  //FINAL JSX
});
