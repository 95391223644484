import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarRelacaoEntregaItensTypes,
  ListarRelacaoEntregaTypes,
  ObjListarRelacaoEntregaPadrao,
} from "../../../Types/Faturamento/RelacaoEntrega/RelacaoEntrega";
import {
  ModalBaseType,
  LegendaTypes,
} from "../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpTipoNFEntrada } from "../../Sistema/Classificacao/TipoNFEntrada/Http";
import { HttpRelacaoEntrega } from "./Http";
import { AlterarRelacaoEntrega } from "./_AlteraRelacaoEntrega";

export function ListarRelacaoEntrega() {
  const AlterarRelacaoEntregaRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarRelacaoEntregaTypes>({
    defaultValues: ObjListarRelacaoEntregaPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarRelacoesEntregas = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpRelacaoEntrega().RetornarRelacoesEntrega(
        DadosFiltros
      );
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarRelacaoEntregaItensTypes) => {
      return await HttpRelacaoEntrega().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpTipoNFEntrada()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Nova Relacao Entrega",
      Permissao: "sistema/classificacao/incluir",
      OnClick: () => {
        AlterarRelacaoEntregaRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Alterar",
      Permissao: "sistema/classificacao/alterar",
      OnClick: (e: ListarRelacaoEntregaItensTypes) => {
        AlterarRelacaoEntregaRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/classificacao/excluir",
      OnClick: (e: ListarRelacaoEntregaItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas: LegendaTypes = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarRelacaoEntregaItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      ocultar: true,
      att: {
        maxWidth: 80,
      },
    },
    {
      Campo: "identificacaoPedido",
      Titulo: "Identificação ",
      Tipo: "Texto",
    },
    {
      Campo: "dataSaida",
      Titulo: "Saída",
      Tipo: "Data",
    },
    {
      Campo: "horaSaida",
      Titulo: "Hora Saída",
      Tipo: "CheckBox",
    },
    {
      Campo: "qtdNotasFiscais",
      Titulo: "Qtd. NFs",
      Tipo: "Numero",
    },
    {
      Campo: "qtdVolumes",
      Titulo: "Qtd. Volumes",
      Tipo: "Numero",
    },
    {
      Campo: "valorTotal",
      Titulo: "Valor Total",
      Tipo: "Moeda",
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarRelacaoEntregaItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];

  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Relação Entrega"
          CarregarDados={RetornarRelacoesEntregas}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarRelacaoEntrega
          CarregarDados={methods.handleSubmit(RetornarRelacoesEntregas)}
          ref={AlterarRelacaoEntregaRef}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Filtros de Período" popover="Filtros de Período">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarRelacaoEntregaTypes>
                label="Data Saída Inicial"
                id="Pesquisa.dataSaidaInicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarRelacaoEntregaTypes>
                label="Data Saída Final"
                id="Pesquisa.dataSaidaFinal"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarRelacaoEntregaTypes>
                label="Data Entrega Inicial"
                id="Pesquisa.dataEntregaInicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarRelacaoEntregaTypes>
                label="Data Entrega Final"
                id="Pesquisa.dataEntregaFinal"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBoxItem Col={12}>
            <CGIInput<ListarRelacaoEntregaTypes>
              label="Pedido"
              id="Pesquisa.identificacaoPedido"
              placeholder="Pedido"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<ListarRelacaoEntregaTypes>
              label="Status"
              id="Pesquisa.listaStatusId"
              Link={"simnaoambos"}
            />
          </CGIBoxItem>
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGICheckBox<ListarRelacaoEntregaTypes>
            id={"Pesquisa.exibirExcluidos"}
            label={"Exibir Excluídos"}
            tooltip={"Exibir Excluídos"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGICheckBox<ListarRelacaoEntregaTypes>
            id={"Pesquisa.entregueComAtraso"}
            label={"Entregue com Atraso"}
            tooltip={"Entregue com Atraso"}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="faturamento/relacaoentrega/listarrelacaoentrega"
      tipoGET={{
        url: "RELACAOENTREGA-LISTAR",
        setRetorno: e => {
          methods.setValue("Pesquisa", e);
        },
      }}
      Titulo="Relação de Entrega"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarRelacoesEntregas)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
