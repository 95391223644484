import { useMemo, useState } from "react";
import Highcharts, { SeriesOptionsType } from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { CGIGraficoDadosType } from "../CGIGrafico";
import { TraducaoGrafico } from "./PTBR";

type CGIGraficoPIEType = {
  Dados: CGIGraficoDadosType;
};

export function CGIGraficoPIE(props: CGIGraficoPIEType) {
  const { Dados } = props;
  const {
    config: { exibirTooltip, exibirLegenda, exibirLabel },
  } = Dados;
  return useMemo(() => {
    if (Dados.tipo === undefined) return <></>;
    const options: Highcharts.Options = {
      lang: TraducaoGrafico,
      accessibility: {
        enabled: false,
      },
      chart: {
        type: "pie",
      },
      title: {
        text: Dados.config.titulo,
      },
      subtitle: {
        text: Dados.config.subtitulo,
      },
      tooltip: {
        enabled: exibirTooltip ? true : false,
        formatter: function () {
          var Titulo = this.series.data[this.point.index].name;
          var Valor = Highcharts.numberFormat(this.percentage, 2) + "%";
          return `${Titulo}: <b>${Valor}</b>`;
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: exibirLabel ? true : false,
          },
          showInLegend: exibirLegenda ? true : false,
        },
      },
      series: [
        {
          name: "Grafico",
          colorByPoint: true,
          data: Dados.seriesChaveValor,
        } as SeriesOptionsType,
      ],
    };

    return (
      <>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </>
    );
  }, [Dados]);
}
