import { useEffect, useState } from "react";
import {
  FieldValues,
  FormProvider,
  useForm,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import { CGIBox } from "../../Box/CGIBox";
import { CGIBoxItem } from "../../Box/CGIBoxItens";
import { CGIInput } from "../../Input/CGIInput";
import { HttpCGIResumosComposto } from "../HttpResumo";

type CGIResumoPedidotype = {
  watchPedidoId: string;
};

function CGIResumoPedido(
  watchPedidoId: string,
  formPrincipal: UseFormReturn<FieldValues, any>,
  formResumo: UseFormReturn<FieldValues, any>
) {
  const Tipo = "Pedido";
  const [valueOld, setValueOld] = useState();
  useEffect(() => {
    var value =
      formPrincipal.getValues(watchPedidoId) ||
      formResumo.getValues(watchPedidoId);

    if (value == null || value === undefined || value === 0 || value === "") {
      return;
    }
    if (valueOld === value) {
      return;
    } else {
      setValueOld(value);
    }
    RetornarDados(value);
  }, [formPrincipal.watch(watchPedidoId), formResumo.watch(watchPedidoId)]);

  async function RetornarDados(id: any) {
    var Dados = await HttpCGIResumosComposto().RetornarDados(id, Tipo);

    var key = Object.keys(Dados.registros[0]);
    key.map((e) => {
      if (e === "Id" || e === "id") return "";
      var value = Dados.registros[0][e];
      return formResumo.setValue(e, value);
    });
  }
  return (
    <CGIBox>
      <CGIBoxItem Col={4}>
        <CGIInput ocultar={true} id={watchPedidoId} placeholder={""} />
        <CGIInput
          id={"PEDIdentificacao"}
          label={"Pedido"}
          placeholder={"Pedido"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIInput
          id={"PEDNomeExibicao"}
          label={"Empresa"}
          placeholder={"Empresa"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIInput
          id={"PEDDataCadastro"}
          label={"Data"}
          placeholder={"Data"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={6}>
        <CGIInput
          id={"PEDNomeCliente"}
          label={"Cliente"}
          placeholder={"Cliente"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"PEDCPFCNPJ"}
          label={"CNPJ"}
          placeholder={"CNPJ"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"PEDNomeCondicaoPagamento"}
          label={"Cond. Pagto."}
          placeholder={"Cond. Pagto."}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={2}>
        <CGIInput
          id={"PEDTotalPedidoFinal"}
          label={"Valor do Pedido"}
          placeholder={"Valor do Pedido"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
    </CGIBox>
  );
}

export const ResumoPedido = {
  id: "ResumoPedido",
  titulo: "Dados Pedido",
  componente: CGIResumoPedido,
};
