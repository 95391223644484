import React from "react";
import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { Box, FormLabel, Input, InputProps } from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import moment from "moment";

interface CGIPicker<T extends Record<string, unknown>> {
  id: NestedKey<T>;
  label: string;
  validadores?: RegisterOptions | undefined;
  DataInicial?:
    | "hoje"
    | "primeiro-dia-do-mes"
    | "ultimo-dia-do-mes"
    | "primeiro-dia-da-semana"
    | "ultimo-dia-da-semana"
    | "15-dias-antes"
    | "15-dias-depois"
    | "30-dias-antes"
    | "30-dias-depois"
    | "45-dias-antes"
    | "45-dias-depois"
    | "60-dias-depois"
    | "60-dias-antes";
  att?: InputProps;
}

/**
 * @Componente CGIDatePicker - Date Picker padrão do Sistema
 * @Ref  FormContext
 * @Form  Necessita ser Controlado Por Formulário
 */

export function CGIDatePicker<T extends Record<string, unknown>>(
  props: CGIPicker<T>
) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <FormLabel>{props.label}</FormLabel>
      <Controller
        render={({ field }) => {
          console.log(field.value);
          return (
            <DatePicker
              selected={
                field.value
                  ? typeof field.value === "string"
                    ? String(field.value).FormatarData()
                    : moment(field.value).toDate()
                  : null
              }
              onChange={date => {
                field.onChange(date);
              }}
              disabled={props.att?.isDisabled}
              isClearable={!props.att?.isDisabled}
              dateFormat="dd/MM/yyyy"
              customInput={<Input {...props.att} />}
              locale={ptBR}
            />
          );
        }}
        rules={props.validadores}
        name={props.id}
        control={control}
      />
      <ErrorMessage
        errors={errors}
        name={props.id}
        render={({ message }) => (
          <span style={{ color: "red", fontSize: 12 }}> * {message}</span>
        )}
      />
    </Box>
  );
}
