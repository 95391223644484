import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import {
  ListarProdutosCompradosENaoEntreguesItensTypes,
  ListarProdutosCompradosENaoEntreguesTypes,
  ObjListarProdutosCompradosENaoEntreguesPadrao,
  ProdutosCompradosENaoEntreguesTypes,
} from "../../../Types/Almoxarifado/ProdutosCompradosENaoEntregues/ProdutosCompradosENaoEntreguesTypes";
import { HttpProdutosCompradosENaoEntregues } from "./Http";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
// import { AlterarProdutosCompradosENaoEntregues } from "./_AlterarProdutosCompradosENaoEntregues";
// import { VisualizarProdutosCompradosENaoEntregues } from "./_VisualizarProdutosCompradosENaoEntregues";

export function ListarProdutosCompradosENaoEntregues() {
  //Inicio Hooks
  const CGIModalDeleteRef = useRef<ModalBaseType>(null);
  const { setLoadingState } = useContext(LoadingContext);
  const methods = useForm<ListarProdutosCompradosENaoEntreguesTypes>({
    defaultValues: ObjListarProdutosCompradosENaoEntreguesPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const ListarMenu: Array<MenuTypes> = [];

  const MenuGrid: Array<MenuTypes> = [];

  const Legendas = [
    { nomeStatus: "Sem data de previsão definida", cor: "blue" },
    { nomeStatus: "Vence acima de 10 dias", cor: "green" },
    { nomeStatus: "Vence nos próximos 10 dias", cor: "orange" },
    { nomeStatus: "Atrasado", cor: "red" },
  ];

  const GridColunas: Array<
    CGICol<ListarProdutosCompradosENaoEntreguesItensTypes>
  > = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "identificacao",
      Titulo: "Identificação",
      Tipo: "Texto",
      Ordernar: true,
    },
    {
      Campo: "nomeFornecedor",
      Titulo: "Fornecedor",
      Tipo: "Texto",
      att: {
        minWidth: 220,
      },
    },
    {
      Campo: "dataPedido",
      Titulo: "Data Pedido",
      Tipo: "Data",
    },
    {
      Campo: "dataPrevisao",
      Titulo: "Data Previsão",
      Tipo: "Data",
    },
    {
      Campo: "novaDataPrevisao",
      Titulo: "Nova Dt. Prev.",
      Tipo: "Data",
    },
    {
      Campo: "codigoProduto",
      Titulo: "Código Produto",
      Tipo: "Texto",
    },
    {
      Campo: "nomeProduto",
      Titulo: "Produto",
      Tipo: "Texto",
    },
    {
      Campo: "qtdDiasAtraso",
      Titulo: "Qtd. Dias Atraso",
      Tipo: "Texto",
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarProdutosCompradosENaoEntreguesItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          titulo: "Id",
          valor: "id",
        },
        {
          titulo: "Identificação",
          valor: "identificacao",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Produtos Comprados E Não Entregues"
          CarregarDados={methods.handleSubmit(
            RetornarProdutosCompradosENaoEntreges
          )}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
  ];

  async function RetornarProdutosCompradosENaoEntreges() {
    setLoadingState(true);
    try {
      var DadosFiltros = methods.getValues("Pesquisa");
      var data =
        await HttpProdutosCompradosENaoEntregues().RetornarProdutosCompradosENaoEntregues(
          DadosFiltros
        );
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }
  async function RetornarDadosExclusao(
    data: ProdutosCompradosENaoEntreguesTypes
  ) {
    return await HttpProdutosCompradosENaoEntregues().RetornarDadosExclusao(
      data
    );
  }
  async function Excluir(data: DeleteTypes) {
    await HttpProdutosCompradosENaoEntregues()
      .Excluir(data)
      .then((e) => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  //Final Function

  //------------------------

  // Inicio JSX

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Filtros de Período" popover="Filtros de Período">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarProdutosCompradosENaoEntreguesTypes>
                id={"Pesquisa.dataInicialInclusao"}
                label="Data Cadastro Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarProdutosCompradosENaoEntreguesTypes>
                id="Pesquisa.dataFinalInclusao"
                label="Data Cadastro Final"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarProdutosCompradosENaoEntreguesTypes>
                id="Pesquisa.dataInicialPrevisao"
                label="Data Previsão Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarProdutosCompradosENaoEntreguesTypes>
                id="Pesquisa.dataFinalPrevisao"
                label="Data Previsão Final"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBox>
            <CGIBoxItem Col={12}>
              <CGIComboAjax<ListarProdutosCompradosENaoEntreguesTypes>
                id="Pesquisa.listaFornecedoresId"
                label="Fornecedor"
                Link="fornecedor"
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIInput<ListarProdutosCompradosENaoEntreguesTypes>
                id="Pesquisa.identificacaoPedidoCompra"
                label="Pedido de Compra"
                placeholder="Pedido de Compra"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIInput<ListarProdutosCompradosENaoEntreguesTypes>
                id="Pesquisa.identificacaoPedido"
                label="Pedido de Venda"
                placeholder="Pedido de Venda"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarProdutosCompradosENaoEntreguesTypes>
            Multipla
            id="Pesquisa.statusId"
            label="Status"
            Link="statuspedidocompra"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={5}>
          <CGIInput<ListarProdutosCompradosENaoEntreguesTypes>
            id="Pesquisa.codigoProduto"
            label="Cód. Produto"
            placeholder="Cód. Produto"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarProdutosCompradosENaoEntreguesTypes>
            id="Pesquisa.ehSomenteComPrazoDefinidosId"
            label="Somente com novos prazos definidos"
            Link="simnaoambos"
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="almoxarifado/pedidocompra/produtoscompradosenaoentregues"
      Titulo="Produtos Comprados E Não Entregues"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(
            RetornarProdutosCompradosENaoEntreges
          )}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
