import {
  forwardRef,
  useImperativeHandle,
  useState,
  useContext,
  useRef,
} from "react";
import { CGIAlertDialog } from "../../../../../Components/AlertDialog/CGIAlertDialog";
import { HttpCGIAlertDiolog } from "../../../../../Components/AlertDialog/HttpAlertDiolog";
import { CGIBoxItem } from "../../../../../Components/Box/CGIBoxItens";
import { CGIComboAjax } from "../../../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../../../Components/Combos/CGIComboSimples";
import { CGIInput } from "../../../../../Components/Input/CGIInput";
import { CGINumberInput } from "../../../../../Components/NumberInput/CGINumberInput";
import { FormContext } from "../../../../../Context/FormConxtext";
import LoadingContext from "../../../../../Context/LoadingContext";
import {
  RetornarDadosUsuario,
  GuardarDadosUsuario,
} from "../../../../../Services/Util/Util";
import { Empresa } from "../../../../../Types/Home/Login/LoginType";

type AdicionarProdutoNaoCadastradoType = {
  AddItem: Function;
};

export const AdicionarProdutoNaoCadastrado = forwardRef(
  (props: AdicionarProdutoNaoCadastradoType, ref) => {
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIAlertDialogRef.current.Abrir();
      },
      Fechar() {
        CGIAlertDialogRef.current.Fechar();
      },
    }));
    const { setLoadingState } = useContext(LoadingContext);

    const CGIAlertDialogRef = useRef() as any;

    function Confirmar(e: any) {
      props.AddItem(e);
      CGIAlertDialogRef.current.Fechar();
    }

    return (
      <CGIAlertDialog
        ref={CGIAlertDialogRef}
        titulo={`Adicionar Produto não Cadastrado`}
        confirmar={Confirmar}
        size={"6xl"}
      >
        <CGIBoxItem Col={4}>
          <CGIInput
            id={"descricao"}
            placeholder={"Descrição"}
            label={"Descrição"}
            validadores={{
              required: "Campo Obrigatório",
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGINumberInput
            id={"quantidade"}
            label={"Quantidade"}
            validadores={{
              required: "Campo Obrigatório",
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGINumberInput
            id={"precoVenda"}
            label={"Preço Unit. (Venda)"}
            validadores={{
              required: "Campo Obrigatório",
            }}
          />
        </CGIBoxItem>

        <CGIBoxItem Col={2}>
          <CGINumberInput id={"vlrCusto"} label={"Preço Custo"} />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGINumberInput id={"vlrDesconto"} label={"Vlr. Desconto"} />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIInput
            id={"codigoProduto"}
            placeholder={"Cod.Produto"}
            label={"Cod.Produto"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples
            Link={"unidade"}
            label={"Unidade"}
            id={"unidadeId"}
            validadores={{
              required: "Campo Obrigatório",
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboAjax
            Link={"fornecedor"}
            label={"Fornecedor"}
            id={"fornecedorId"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIInput id={"prazo"} placeholder={"Prazo"} label={"Prazo"} />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIInput id={"ncm"} placeholder={"NCM"} label={"NCM"} />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIInput id={"percICMS"} placeholder={"% ICMS"} label={"% ICMS"} />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples Link={"marca"} id={"marcaId"} label={"Marca"} />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIInput
            id={"Número CA"}
            placeholder={"Número CA"}
            label={"Número CA"}
          />
        </CGIBoxItem>

        <CGIBoxItem Col={2}>
          <CGIInput
            id={"Código Fabricante"}
            placeholder={"Código Fabricante"}
            label={"Código Fabricante"}
          />
        </CGIBoxItem>
      </CGIAlertDialog>
    );
  }
);
