import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIFileInput } from "../../../Components/FileInput/CGIFileInput";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGINumberInput } from "../../../Components/NumberInput/CGINumberInput";
import { CGITextArea } from "../../../Components/TextArea/CGITextArea";
import LoadingContext from "../../../Context/LoadingContext";

type ImportarPedidoType = {
  CarregarDados: Function;
};

export const ImportarPedido = forwardRef((props: ImportarPedidoType, ref) => {
  //INICIO REF
  useImperativeHandle(ref, () => ({
    Abrir() {
      CGIModalRef.current.Abrir();
    },
    Alterar(pEmpresa: any) {
      CarregarDados(pEmpresa.Id);
      CGIModalRef.current.Abrir();
    },
  }));
  //FINAL  REF

  //INICIO  HOOKS
  const CGIModalRef = useRef() as any;
  const form = useFormContext();
  const { ToastErro, ToastSucesso } = useCGIToast();
  const { setLoadingState } = useContext(LoadingContext);

  //FINAL  HOOKS

  //INICIO CONST

  //FINAL  CONST

  //INICO FUNC
  async function CarregarDados(id: number) {
    // HttpPedido()
    //   .RetornarDadosPedido(id)
    //   .then((e) => {
    //     form.reset(e.data.data);
    //   });
  }

  const Confirmar = async (data: any, event: any) => {
    // setLoadingState(true);
    // HttpPedido()
    //   .Alterar(data)
    //   .then((data: any) => {
    //     ToastSucesso("Registro Alterado com sucesso", "OK!");
    //     props.CarregarDados();
    //     CGIModalRef.current.Fechar();
    //   })
    //   .catch(e => {
    //     return;
    //   })
    //   .finally(() => {
    //     setLoadingState(false);
    //   });
  };
  //FINAL FUNC

  //INICO JSX
  return (
    <CGIModal
      id="vendas/pedido/copiar"
      ref={CGIModalRef}
      Titulo="Importar Pedido"
      TelaCheia={"6xl"}
      Confirmar={Confirmar}
      Fechar={() => {
        form.reset({});
        setLoadingState(false);
      }}
    >
      <CGIBox>
        <CGIBoxItem Col={12}>
          <Text align={"center"} fontWeight={"bold"} color={"red"}>
            * Atenção. A primeira linha da planilha é sempre ignorada pois é a
            linha dos títulos das colunas *
          </Text>
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples
            Link={"simnaoambos"}
            label={"Tipo de Pedido"}
            id={"tipoPedidoId"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples
            Link={"simnaoambos"}
            label={"Padrão"}
            id={"padraoId"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples
            Link={"simnaoambos"}
            label={"Tabela de Preços"}
            id={"tabelaPrecosId"}
          />
        </CGIBoxItem>{" "}
        <CGIBoxItem Col={4}>
          <CGIComboSimples
            Link={"empresa"}
            label={"Empresas"}
            id={"emrpesaId"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput
            id={"pedidoCliente"}
            placeholder={"Pedido Cliente"}
            label={"Pedido Cliente"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGINumberInput id={"desconto"} label={"Desconto"} prefix />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples
            Link={"simnaoambos"}
            label={"Pag. Origem"}
            id={"paginaOrigemId"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboAjax
            Link={"fornecedor"}
            label={"Cliente"}
            id={"clienteId"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples
            Link={"vendedor"}
            label={"Vendedores"}
            id={"vendedorId"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples
            Link={"simnaoambos"}
            label={"Condição de Pagamento"}
            id={"Condição de Pagamento"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples
            Link={"simnaoambos"}
            label={"Natureza de Operação"}
            id={"Natureza de Operação"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples
            Link={"simnaoambos"}
            label={"Tipo de Frete"}
            id={"Tipo de Frete"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples
            Link={"simnaoambos"}
            label={"Transportadora"}
            id={"Transportadora"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples
            Link={"simnaoambos"}
            label={"Redespacho"}
            id={"Redespacho"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGITextArea id={"obsPedido"} label={"Obs. do Pedido"} />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGITextArea id={"obsNFSaida"} label={"Obs. da Nota Fiscal"} />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGICheckBox
            id={"CancelarSeProdutoEstiverSemPreco"}
            label={
              "Cancelar caso algum produto da planilha não esteja na tabela de preços"
            }
            tooltip={
              "Cancelar caso algum produto da planilha não esteja na tabela de preços"
            }
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGICheckBox
            id={"ValidarDuplicadadeCliente"}
            label={"Validar duplicidade no campo Pedido Cliente"}
            tooltip={"Validar duplicidade no campo Pedido Cliente"}
          />
          <Text>
            * Serão verificados apenas pedidos EM ABERTO no momento da
            importação
          </Text>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGICheckBox
            id={"utilizarMultiploCadastroProduto"}
            label={"Utilizar Múltiplo do cadastro do produto"}
            tooltip={"Utilizar Múltiplo do cadastro do produto"}
          />
          <Text>
            * O valor da planilha será ignorado e o Múltiplo utilizado será do
            cadastro do produto
          </Text>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGICheckBox
            id={"utilizarValorUnitarioCadastroProduto"}
            label={"Utilizar Valor Unitário do cadastro do produto"}
            tooltip={"Utilizar Valor Unitário do cadastro do produto"}
          />
          <Text>
            * O valor da planilha será ignorado e o Múltiplo utilizado será do
            cadastro do produto
          </Text>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGICheckBox
            id={"lancarEntradaEstoqueConformeQuantidadePedido"}
            label={"Lançar entrada de estoque conforme quantidades no pedido"}
            tooltip={"Lançar entrada de estoque conforme quantidades no pedido"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGICheckBox
            id={"utilizarPrecoTabelaCadastroCliente"}
            label={"Utilizar o preço da tabela cadastrada no cliente"}
            tooltip={"Utilizar o preço da tabela cadastrada no cliente"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGICheckBox
            id={"financeiroSeparadoVP"}
            label={" Financeiro Separado (VP)"}
            tooltip={" Financeiro Separado (VP)"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <Text color={"red"} fontWeight={"bold"}>
            * Atenção para pedidos SUFRAMA: Esta rotina ainda não está preparada
            para SUFRAMA *
          </Text>
          <Text color={"red"} fontWeight={"bold"}>
            * Financeiro Separado VP: Haverá apenas conversão no código. O preço
            não será alterado *
          </Text>
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIBox titulo="Importar Arquivos" popover="Importar Arquivos">
            <CGIBoxItem Col={4}>
              <CGIButton label="Download do arquivo" att={{ mt: 4 }} />
            </CGIBoxItem>
            <CGIBoxItem Col={8}>
              <CGIFileInput id={"arquivos"} label={"Arquivos"} />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIBox
            titulo="Detalhamento do Arquivo"
            popover="Detalhamento do Arquivo"
          >
            <CGIBoxItem Col={12}>
              <TableContainer>
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <Th>Sequencia</Th>
                      <Th>Nome</Th>
                      <Th>Observação</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>1</Td>
                      <Td>Código do Produto </Td>
                      <Td> </Td>
                    </Tr>
                    <Tr>
                      <Td>2</Td>
                      <Td>Descricao </Td>
                      <Td></Td>
                    </Tr>
                    <Tr>
                      <Td>3</Td>
                      <Td>Unidade </Td>
                      <Td>Unidade de Medida</Td>
                    </Tr>
                    <Tr>
                      <Td>4</Td>
                      <Td>Preço de Venda</Td>
                      <Td></Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    </CGIModal>
  );
  //FINAL JSX
});
