import {
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { GridReadyEvent, NavigateToNextCellParams } from "ag-grid-community";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { CGIBoxItem } from "../Box/CGIBoxItens";
import { CGIButton } from "../Button/CGIButton";
import { CGIDataTableSimples } from "../DataTable/CGIDataTableSimples";
import { CGICol } from "../DataTable/DataTable/DataTableTypes";

type CGISearchAlertType = {
  RetornarDados: Function;
};

export const CGISearchAlert = forwardRef((props: CGISearchAlertType, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useImperativeHandle(ref, () => ({
    Abrir(Colunas: any, Dados: any) {
      try {
        if (Colunas) {
          let ConvertCol = Colunas.map((e: any) => {
            return {
              Campo: e.nomeTabela,
              Titulo: e.nomeExibicao,
              Tipo: "Texto",
            } as CGICol<any>;
          });
          setColConvert(ConvertCol);
          setDados(Dados);
          onOpen();
        }
      } catch (error) {}
    },
  }));
  const [ColConvert, setColConvert] = useState<Array<CGICol<any>>>([]);
  const [Dados, setDados] = useState<Array<any>>([]);
  const [Grid, setGrid] = useState<GridReadyEvent>({} as GridReadyEvent);

  const TableRef = React.useRef() as any;

  function OnGridRead(grid: GridReadyEvent) {
    setGrid(grid);
  }
  function SelecionarCell() {
    var cellSelecionada = Grid.api.getFocusedCell();

    if (cellSelecionada) {
      var row = Grid.api.getDisplayedRowAtIndex(cellSelecionada.rowIndex);
      props.RetornarDados(row?.data);
      return onClose();
    }

    Grid.api.ensureIndexVisible(0);

    var firstCol = Grid.columnApi.getAllDisplayedColumns()[0];

    Grid.api.ensureColumnVisible(firstCol);

    Grid.api.setFocusedCell(0, firstCol);
  }

  function keyBord(event: React.KeyboardEvent<HTMLDivElement>) {
    event.preventDefault();

    if (event.key !== "Enter") return;
    else SelecionarCell();
  }
  function navigateToNextCell(params: NavigateToNextCellParams) {
    var previousCell = params.previousCellPosition;
    var suggestedNextCell = params.nextCellPosition;

    var KEY_UP = "ArrowUp";
    var KEY_DOWN = "ArrowDown";
    var KEY_LEFT = "ArrowLeft";
    var KEY_RIGHT = "ArrowRight";
    switch (params.key) {
      case KEY_DOWN:
        previousCell = params.previousCellPosition;
        // set selected cell on current cell + 1
        Grid.api.forEachNode(node => {
          if (previousCell.rowIndex + 1 === node.rowIndex) {
            node.setSelected(true);
          }
        });
        return suggestedNextCell;
      case KEY_UP:
        previousCell = params.previousCellPosition;
        // set selected cell on current cell - 1
        Grid.api.forEachNode(node => {
          if (previousCell.rowIndex - 1 === node.rowIndex) {
            node.setSelected(true);
          }
        });
        return suggestedNextCell;
      case KEY_LEFT:
      case KEY_RIGHT:
        return suggestedNextCell;
      default:
        throw "this will never happen, navigation is always one of the 4 keys above";
    }
  }
  return (
    <Modal initialFocusRef={TableRef} isOpen={isOpen} onClose={onClose}>
      <Box ref={TableRef} onKeyDown={keyBord}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Selecione uma opção</ModalHeader>
          <ModalCloseButton autoFocus={false} />
          <ModalBody pb={6}>
            <CGIBoxItem Col={12}>
              <CGIDataTableSimples
                Altura="50vh"
                Colunas={ColConvert}
                Data={Dados}
                Att={{
                  onGridReady: OnGridRead,
                  navigateToNextCell: e => navigateToNextCell(e),
                  defaultColDef: {
                    filter: false,
                    onCellClicked: SelecionarCell,
                  },
                }}
              />
            </CGIBoxItem>
          </ModalBody>
          <ModalFooter>
            <CGIButton
              onClick={onClose}
              label="Cancelar"
              att={{
                ml: 3,
                colorScheme: "red",
              }}
            >
              Cancelar
            </CGIButton>
          </ModalFooter>
        </ModalContent>
      </Box>
    </Modal>
  );
});
