import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
} from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from "react";
import { CGIBoxItem } from "../Box/CGIBoxItens";
import { CGIButton } from "../Button/CGIButton";
import { CGIDataTableSimples } from "../DataTable/CGIDataTableSimples";
import { CGICol } from "../DataTable/DataTable/DataTableTypes";

type CGISearchAlertType = {
  RetornarDados: Function;
  ColunasGrid: Array<CGICol<any>>;
};

export const PesquisarGrid = forwardRef((props: CGISearchAlertType, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useImperativeHandle(ref, () => ({
    Abrir(Dados: any) {
      try {
        setTimeout(() => {
          GridRef.current?.api?.setRowData(Dados);
        }, 50);

        setTimeout(() => {
          var firstCol = GridRef.current?.columnApi.getAllDisplayedColumns()[7];
          if (firstCol) {
            GridRef.current?.api.ensureColumnVisible(firstCol);
            GridRef.current?.api.setFocusedCell(0, firstCol);
          }
        }, 500);
        onOpen();
      } catch (error) {}
    },
  }));
  const GridRef = useRef<AgGridReact>(null);

  const SelecionarCelula = useCallback(() => {
    let rowData: Array<any> = [];
    GridRef.current?.api.forEachNode(node => rowData.push(node.data));
    props.RetornarDados(rowData);
    onClose();
  }, [onClose, props]);

  const mapaTeclado = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter") {
        SelecionarCelula();
      }
    },
    [SelecionarCelula]
  );

  return (
    <Modal size={"7xl"} isOpen={isOpen} onClose={onClose}>
      <Box onKeyDown={mapaTeclado}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Selecione uma opção</ModalHeader>
          <ModalCloseButton autoFocus={false} />
          <ModalBody pb={6}>
            <CGIBoxItem Col={12}>
              <CGIDataTableSimples
                ref={GridRef}
                Altura="50vh"
                Colunas={props.ColunasGrid}
                Data={[]}
                Pag={20}
                Att={{
                  defaultColDef: {
                    flex: 1,
                    filter: false,
                  },
                }}
              />
            </CGIBoxItem>
          </ModalBody>
          <ModalFooter>
            <CGIButton
              onClick={onClose}
              label="Cancelar"
              att={{
                ml: 3,
                colorScheme: "red",
              }}
            >
              Cancelar
            </CGIButton>
          </ModalFooter>
        </ModalContent>
      </Box>
    </Modal>
  );
});
