import { Flex } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import {
  FormRelacaoEntregaType,
  ObjPadrao,
  RelacaoEntregaItensTypes,
} from "../../../Types/Faturamento/RelacaoEntrega/RelacaoEntrega";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { HttpRelacaoEntrega } from "./Http";

export const AlterarRelacaoEntrega = forwardRef(
  (
    props: {
      CarregarDados: () => void;
    },
    ref
  ) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        form.reset(ObjPadrao);
        CGIModalRef.current?.Abrir();
      },
      Alterar(pRelacaoEntragaId: number) {
        CGIModalRef.current?.Abrir();
      },
    }));

    const ColGridPesquisa: Array<CGICol<RelacaoEntregaItensTypes>> = [
      {
        Titulo: "",
        Campo: "id",
        Tipo: "Texto",
        ocultar: true,
      },
      {
        Titulo: "",
        Campo: "selecionado",
        Tipo: "CheckBox",
      },
      {
        Titulo: "Dt. Pedido",
        Campo: "dataPedido",
        Tipo: "Texto",
      },
      {
        Titulo: "Dt. Baixa",
        Campo: "dataBaixa",
        Tipo: "Texto",
      },
      {
        Titulo: "Dt. Fat.",
        Campo: "dataFaturamento",
        Tipo: "Texto",
      },
      {
        Titulo: "Nº Pedido",
        Campo: "identificacaoPedido",
        Tipo: "Texto",
      },
      {
        Titulo: "NF Saída",
        Campo: "numeroNF",
        Tipo: "Texto",
      },
      {
        Titulo: "Vlr. Pedido",
        Campo: "valor",
        Tipo: "Texto",
      },
      {
        Titulo: "Cliente",
        Campo: "nomeCliente",
        Tipo: "Texto",
      },
    ];
    const ColGridPrincipal: Array<CGICol<RelacaoEntregaItensTypes>> = [
      {
        Titulo: "",
        Campo: "id",
        Tipo: "Texto",
        ocultar: true,
      },
      {
        Titulo: "",
        Campo: "selecionado",
        Tipo: "CheckBox",
      },
      {
        Titulo: "Seq.",
        Campo: "sequencia",
        Tipo: "Texto",
      },
      {
        Titulo: "Ident. Pedido",
        Campo: "identificacaoPedido",
        Tipo: "Texto",
      },
      {
        Titulo: "Cliente",
        Campo: "nomeCliente",
        Tipo: "Texto",
      },
      {
        Titulo: "Qtd. Volumes",
        Campo: "qtdVolumes",
        Tipo: "Texto",
        att: {
          editable: true,
        },
      },
      {
        Titulo: "Vlr. Pedido",
        Campo: "valor",
        Tipo: "Texto",
      },
    ];

    const form = useFormContext<FormRelacaoEntregaType>();
    const GridPesquisa = useWatch({
      control: form.control,
      name: "GridPesquisa.Itens",
    });

    const GridPrincipal = useWatch({
      control: form.control,
      name: "Form.Itens",
    });
    const CGIModalRef = useRef<ModalBaseType>();

    const CGIGridPesquisaRef =
      useRef<AgGridReact<RelacaoEntregaItensTypes>>(null);
    const CGIGridPrincipalRef =
      useRef<AgGridReact<RelacaoEntregaItensTypes>>(null);

    const RetornarDadosGridPesquisa = useCallback(async () => {
      const Pesquisa = form.getValues("GridPesquisa.Pesquisa");

      var Dados = await HttpRelacaoEntrega().RetornarDadosGridPesquisa({
        nomeCliente: Pesquisa.nomeCliente,
      });
      if (Dados.length) {
      }
      form.setValue("GridPesquisa.Itens", Dados);
    }, [form]);

    const AdicionarItens = useCallback(async () => {
      const Itens = form
        .getValues("GridPesquisa.Itens")
        .filter((e) => e.selecionado === true);

      form.setValue("Form.Itens", [...GridPrincipal, ...Itens]);
      form.setValue("GridPesquisa.Itens", []);
      form.setValue("GridPesquisa.Pesquisa", ObjPadrao.GridPesquisa.Pesquisa);
    }, [form]);

    return (
      <CGIModal
        id="faturamento/relacaoentrega/alterar"
        ref={CGIModalRef}
        Titulo="Relação de Entrega"
        TelaCheia={"6xl"}
        CarregarDados={() => {}}
        Confirmar={(e: any) => {}}
        Fechar={() => {
          form.reset(ObjPadrao);
        }}
      >
        <CGIBox>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormRelacaoEntregaType>
              id="Form.DadosRegistro.empresaId"
              label="Empresa"
              Link={"empresa"}
              validadores={{
                required: "Campo obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormRelacaoEntregaType>
              id="Form.DadosRegistro.motoristaId"
              label="Motorista"
              Link={"empresa"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormRelacaoEntregaType>
              id="Form.DadosRegistro.transportadoraId"
              label="Transportadora"
              Link={"transportadora"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormRelacaoEntregaType>
              id="Form.DadosRegistro.equipamentoId"
              label="Equipamento"
              Link={"equipamento"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormRelacaoEntregaType>
              id="Form.DadosRegistro.separadorPorId"
              label="Separador Por"
              Link={"usuario"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIComboSimples<FormRelacaoEntregaType>
              id="Form.DadosRegistro.conferidoPorId"
              label="Conferido Por"
              Link={"usuario"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIDatePicker<FormRelacaoEntregaType>
              id="Form.DadosRegistro.dataSaida"
              label="Data Saída"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIDatePicker<FormRelacaoEntregaType>
              id="Form.DadosRegistro.horaSaida"
              label="Hora Saída"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIBox titulo="Adicionar novo Item">
              <CGIBoxItem Col={6}>
                <CGIInput<FormRelacaoEntregaType>
                  id="GridPesquisa.Pesquisa.nomeCliente"
                  placeholder="Cliente"
                  label="Cliente"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGIDatePicker<FormRelacaoEntregaType>
                  id="GridPesquisa.Pesquisa.dataInicial"
                  label="Data Inicial"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGIDatePicker<FormRelacaoEntregaType>
                  id="GridPesquisa.Pesquisa.dataFinal"
                  label="Data Final"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGIButton
                  att={{
                    marginTop: "30px",
                  }}
                  label="Pesquisar"
                  onClick={() => RetornarDadosGridPesquisa()}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={12}>
                <CGIDataTableSimples
                  DisableGetId
                  ref={CGIGridPesquisaRef}
                  Altura={"300px"}
                  Colunas={ColGridPesquisa}
                  Data={GridPesquisa}
                  Pag={10}
                  Att={{
                    defaultColDef: {
                      filter: false,
                      autoHeight: true,
                    },
                  }}
                />
                <span style={{ color: "green", fontWeight: "bold" }}>
                  Pedidos conferidos (romaneio) ou pedidos faturados
                </span>
                <Flex justify={"flex-end"}>
                  <CGIButton
                    onClick={() => AdicionarItens()}
                    label="Adicionar Pedido"
                  />
                </Flex>
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIDataTableSimples
              DisableGetId
              ref={CGIGridPrincipalRef}
              Colunas={ColGridPrincipal}
              Data={GridPrincipal}
              Altura={"400px"}
              Pag={10}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
    //FINAL JSX
  }
);
