/* eslint-disable react-hooks/exhaustive-deps */
import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Icon,
  Progress,
  Stat,
  StatArrow,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useCallback, useEffect, useState } from "react";
import { HttpCGIMeta } from "./HttpCGIMeta";
import { FormatarReal } from "../Prototypes/Formatter";
import { Temas } from "../../Themes/Temas";

export type CGIMetaTypes = {
  titulo: string;
  valorAtual: number;
  valorMeta: number;
  icone: string;
  percentualAlcancado: number;
};
/**
 * @Componente CGIMeta - Componente responsavel  pelas Metas
 * @Ref  CGIMeta
 */
export function CGIMeta(props: { link: string }) {
  const [Dados, setDados] = useState<CGIMetaTypes>({
    icone: "",
    percentualAlcancado: 0,
    titulo: "",
    valorAtual: 0,
    valorMeta: 0,
  });

  const RetornarDados = useCallback(async () => {
    var Dados = await HttpCGIMeta().RetornarDados(props.link);

    setDados(Dados);
  }, [props.link]);

  useEffect(() => {
    RetornarDados();
  }, []);

  return (
    <Box p={2} borderRadius="md" shadow={"lg"} bg={Temas.Branco}>
      <Stat w={"100%"}>
        <StatLabel>
          <Flex gap={2} flexDir={"row"}>
            <Box>
              <Icon as={CheckIcon} />
            </Box>
            <Box>
              <Text>{Dados.titulo}</Text>
            </Box>
            <Box>
              <StatHelpText as={Text}>
                {Dados.percentualAlcancado}%
              </StatHelpText>
            </Box>
          </Flex>
        </StatLabel>
        <StatNumber>{FormatarReal(Number(Dados.valorAtual))}</StatNumber>

        <Progress
          outlineColor={Temas.Primaria}
          ringColor={Temas.Primaria}
          borderRadius={"md"}
          value={Dados.percentualAlcancado}
        />
      </Stat>
    </Box>
  );
}
