import { Tag, TagLabel, Box, Text } from "@chakra-ui/react";
import { useContext } from "react";
import {
  FormProvider,
  useForm,
  UseFormReturn,
  useWatch,
} from "react-hook-form";
import { CGIBox } from "../../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../../../Components/Button/CGIButton";
import { CGIDataTableSimples } from "../../../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../../../Components/DatePicker/CGIDatePicker";
import LoadingContext from "../../../../../Context/LoadingContext";
import {
  AnaliseCreditoHistoricoFinanceiroItensType,
  AnaliseCreditoOrcamentoItensType,
  AnaliseCreditoTypes,
} from "../../../../../Types/Financeiro/Colaborador/AnaliseCreditoTypes";
import { LegendaTypes } from "../../../../../Types/Modal/ModalBaseType";
import { HttpColaborador } from "../../Http";

export const GridHistoricoFinanceiro = (
  form: UseFormReturn<AnaliseCreditoTypes, any>
) => {
  const { setLoadingState } = useContext(LoadingContext);
  const GridItens = useWatch({
    control: form.control,
    name: "Grids.HistoricoFinanceiro.Itens",
  });
  const Legendas: LegendaTypes = [
    { nomeStatus: "Aberto", cor: "green" },
    { nomeStatus: "Pago Parcial", cor: "lightblue" },
    { nomeStatus: "Pago Total", cor: "blue" },
    { nomeStatus: "Estornado", cor: "orange" },
    { nomeStatus: "Excluído", cor: "red" },
    { nomeStatus: "Renegociado", cor: "yellow" },
  ];
  const GridCol: Array<CGICol<AnaliseCreditoHistoricoFinanceiroItensType>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      Status: Legendas,
      att: {
        maxWidth: 60,
        filter: false,
      },
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Numero",
      ocultar: true,
    },
    {
      Campo: "titulo",
      Titulo: "Título",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 250,
      },
    },
    {
      Campo: "documento",
      Titulo: "Documento",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 200,
      },
    },
    {
      Campo: "numero",
      Titulo: "Nº",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 120,
      },
    },
    {
      Campo: "nomePortador",
      Titulo: "Portador",
      Tipo: "Numero",
      Ordernar: true,
      att: {
        maxWidth: 180,
      },
    },
    {
      Campo: "dataVencimento",
      Titulo: "Data Vencimento",
      Tipo: "Data",
      Ordernar: true,
      att: {
        maxWidth: 180,
      },
    },
    {
      Campo: "dataPagamento",
      Titulo: "Data Pagamento",
      Tipo: "Data",
      Ordernar: true,
      att: {
        maxWidth: 180,
      },
    },
    {
      Campo: "diasAtraso",
      Titulo: "Dias Atraso",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 150,
      },
    },
    {
      Campo: "valorTitulo",
      Titulo: "Valor",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 180,
      },
    },
  ];
  async function CarregarGridHistorico() {
    try {
      setLoadingState(true);
      var pColaboradorId = form.getValues("Form.clienteId");
      var { DataVencimentoFinal, DataVencimentoInical } = form.getValues(
        "Grids.HistoricoFinanceiro.Pesquisa"
      );
      var Tipo = "CTSRECEBER-HISTORICO";
      var Dados = await HttpColaborador().RetornarDadosGridAnaliseCredito<
        AnaliseCreditoHistoricoFinanceiroItensType[]
      >(pColaboradorId, Tipo, DataVencimentoInical, DataVencimentoFinal);
      form.setValue("Grids.HistoricoFinanceiro.Itens", Dados);
    } catch (error) {
    } finally {
      setLoadingState(false);
    }
  }

  return (
    <CGIBox spacing="0px">
      <CGIBoxItem Col={12}>
        <FormProvider {...form}>
          <CGIBox>
            <CGIBoxItem Col={4}>
              <CGIDatePicker<AnaliseCreditoTypes>
                id={"Grids.HistoricoFinanceiro.Pesquisa.DataVencimentoInical"}
                label={"Data de Vencimento Inicial"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIDatePicker<AnaliseCreditoTypes>
                id={"Grids.HistoricoFinanceiro.Pesquisa.DataVencimentoFinal"}
                label={"Data de Vencimento Final"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIButton
                onClick={() => {
                  CarregarGridHistorico();
                }}
                label="Pesquisar"
                att={{ mt: 30 }}
              />
            </CGIBoxItem>
          </CGIBox>
        </FormProvider>
      </CGIBoxItem>
      <CGIBoxItem Col={12}>
        <Text fontWeight={"bold"} color={"green"}>
          Últimos 10 Contas a Receber
        </Text>
      </CGIBoxItem>
      <CGIBoxItem Col={12}>
        <CGIDataTableSimples
          DisableGetId
          Altura="400px"
          Colunas={GridCol}
          Data={GridItens}
        />
        <Box p={2}>
          {Legendas.map((e, index) => {
            return (
              <Tag
                mx={2}
                size={"md"}
                key={index}
                borderRadius="full"
                variant="solid"
                bgColor={e.cor}
              >
                <TagLabel color={"gray.100"}>{e.nomeStatus}</TagLabel>
              </Tag>
            );
          })}
        </Box>
      </CGIBoxItem>
    </CGIBox>
  );
};
