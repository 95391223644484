import { CGIHttp } from "../../Services/Axios";

const url = "/home";

export function HttpCGIResumosComposto() {
  async function RetornarDados(id: any, tipo: any) {
    let Obj = await new CGIHttp().conn.post(url + "/consultar-resumos-dados/", {
      Id: id,
      Tipo: tipo,
    });

    return Obj.data.data;
  }

  return {
    RetornarDados,
  };
}
