import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import React, { Children, createContext, useState } from "react";
import { CGIButtonCancelar } from "../Components/Button/CGIButtonCancelar";

type ErrorContextTypes = {
  Errorstate: boolean;
  setErrorState: React.Dispatch<React.SetStateAction<boolean>>;
};

const DefaultValue: ErrorContextTypes = {
  setErrorState: () => {},
  Errorstate: false,
};
/**
 * @Componente ErrorContext - Context responsável por controlar o Modal de Error
 * @Ref  Context
 * @Util Modal Erro Principal
 */
export const ErrorContext = createContext<ErrorContextTypes>(DefaultValue);

/**
 * @Componente ErrorProvider - Provider responsável por controlar o Modal de Error
 * @Ref  Provider
 */
function ErrorProvider(props: { children: React.ReactNode }) {
  const [Errorstate, setErrorState] = useState(DefaultValue.Errorstate);

  return (
    <ErrorContext.Provider value={{ Errorstate, setErrorState }}>
      <Box>
        <Modal
          closeOnEsc={false}
          closeOnOverlayClick={false}
          isOpen={Errorstate}
          onClose={() => setErrorState(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Error</ModalHeader>
            <ModalCloseButton />
            <ModalBody>Usuario Sem Permissão</ModalBody>
            <ModalFooter>
              <CGIButtonCancelar onClick={() => setErrorState(false)}>
                Fechar
              </CGIButtonCancelar>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>

      {props.children}
    </ErrorContext.Provider>
  );
}

export { ErrorProvider };
export default ErrorContext;
