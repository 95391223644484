export const ObjPedidoSeparacaoPadrao: FormPedidoSeparacaoType = {
  Form: {
    DadosRegistro: {
      dataCadastro: "",
      clienteId: 0,
      pedidoImpressoId: 0,
      naturezaOperacaoId: 0,
      id: 0,
    },
  },
};
export type PedidoSeparacaoTypes = {
  DadosRegistro: {
    dataCadastro: string;
    clienteId: number;
    pedidoImpressoId: number;
    naturezaOperacaoId: number;
    id: number;
  };
};
export type FormPedidoSeparacaoType = {
  Form: PedidoSeparacaoTypes;
};

export const ObjListarPedidoSeparacaoPadrao: ListarPedidoSeparacaoTypes = {
  Pesquisa: {
    dataInicialInclusao: "",
    dataFinalInclusao: "",
    listaClientesId: "",
    listaNaturezaOperacaoId: "",
    listaVendedoresId: "",
    pedidoImpressoId: 0,
  },
  Itens: [],
};

export type PedidoSeparacaoPesquisaGridTypes = {
  ncm?: string;
};

export type ListarPedidoSeparacaoTypes = {
  Pesquisa: ListarPesquisaPedidoSeparacaoTypes;
  Itens: ListarPedidoSeparacaoItensTypes[];
};

export type ListarPesquisaPedidoSeparacaoTypes = {
  dataInicialInclusao: string;
  dataFinalInclusao: string;
  listaClientesId: string;
  listaNaturezaOperacaoId: string;
  listaVendedoresId: string;
  pedidoImpressoId: number;
};

export type ListarPedidoSeparacaoItensTypes = {
  dataCadastro: string;
  pedidoId: number;
  id: number;
  ordemAlocacao: number;
  dataPrevisaoEntrega: string;
  identificacao: string;
  pedidoCliente: string;
  nomeCliente: string;
  nomeVendedor: string;
  qtdItens: number;
  totalPedido: number;
  nomeStatus: string;
  separado: boolean;
  usuarioSeparacaoId: number;
};
