export const ObjTIPIPadrao: FormTIPIType = {
  Form: {
    dadosRegistro: {
      id: 0,
      ncm: "",
      percIPI: 0,
      ex: 0,
    },
  },
};
export type TIPITypes = {
  dadosRegistro: {
    id: number;
    ncm: string;
    percIPI: number;
    ex: number;
  };
};
export type FormTIPIType = {
  Form: TIPITypes;
};

export const ObjListarTIPIPadrao: ListarTIPITypes = {
  Pesquisa: {
    ncm: "",
  },
  Itens: [],
};

export type TIPIPesquisaGridTypes = {
  ncm?: string;
};

export type ListarTIPITypes = {
  Pesquisa: ListarPesquisaTIPITypes;
  Itens: ListarTIPIItensTypes[];
};

export type ListarPesquisaTIPITypes = {
  ncm: string;
};

export type TIPIVisualizarTypes = {
  id: number;
  ncm: string;
  percIPI: number;
  ex: number;
};

export type ListarTIPIItensTypes = {
  id: number;
  ncm: string;
  percIPI: number;
  ex: number;
};

export type TIPIAlterarTypes = {
  id: number;
  ncm: string;
  percIPI: number;
  ex: number;
};
