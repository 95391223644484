export const ObjPortadorPadrao: FormPortadorType = {
  Form: {
    DadosRegistro: {
      id: 0,
      empresaId: 0,
      nome: "",
      ativo: false,
      portadorGeral: false,
      saldo: 0,
      efetuarLancamento: false,
      vendedorId: 0,
      colaboradorId: 0,
      proprietarioId: 0,
      condominioId: 0,
      clienteId: 0,
      fornecedorId: 0,
      tipoPortadorId: "",
      privado: false,
      bloquearSaldoNegativo: false,
      ocultarBaixasRelatoriosComissao: false,
      historico: "",
      bloquearEstornos: false,
      obsAbertura: "",
      obsFechamento: "",
      banco: "",
      agencia: "",
      contaCorrente: "",
      caixaBanco: "",
      utilizarNoPDVWeb: false,
      serieNFCPDVWeb: 0,
    },
  },
};
export type PortadorTypes = {
  id: number;
  empresaId: number;
  nome: string;
  ativo: boolean;
  portadorGeral: boolean;
  saldo: number;
  efetuarLancamento: boolean;
  vendedorId: number;
  colaboradorId: number;
  proprietarioId: number;
  condominioId: number;
  clienteId: number;
  fornecedorId: number;
  tipoPortadorId: string;
  privado: boolean;
  bloquearSaldoNegativo: boolean;
  ocultarBaixasRelatoriosComissao: boolean;
  historico: string;
  bloquearEstornos: boolean;
  obsAbertura: string;
  obsFechamento: string;
  banco: string;
  agencia: string;
  contaCorrente: string;
  caixaBanco: string;
  utilizarNoPDVWeb: boolean;
  serieNFCPDVWeb: number;
};

export type FormPortadorType = {
  Form: {
    DadosRegistro: PortadorTypes;
  };
};

export const ObjListarPortadorPadrao: ListarPortadorTypes = {
  Pesquisa: {
    ativoId: 0,
    nome: "",
    listaVendedoresId: "",
    listaTiposId: "",
    agencia: "",
    listaAtivosId: 0,
    contaCorrente: "",
    listaEmpresasId: "",
  },
  Itens: [],
};

export type PortadorPesquisaGridTypes = {
  nomeColaborador?: string;
};

export type ListarPortadorTypes = {
  Pesquisa: ListarPesquisaPortadorTypes;
  Itens: ListarPortadorItensTypes[];
};

export type ListarPesquisaPortadorTypes = {
  ativoId: number;
  nome: string;
  listaVendedoresId: string;
  listaTiposId: string;
  agencia: string;
  listaAtivosId: number;
  contaCorrente: string;
  listaEmpresasId: string;
};

export type ListarPortadorGridItensTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  efetuarLancamentos: boolean;
  bloquearEstornos: boolean;
  tipoPortadorId: string;
  nomeTipoPortador: string;
  nomeColaborador: string;
  nomeEmpresa: string;
  nomeProprietario: string;
  nomeCondominio: string;
  nomeVendedor: string;
  nomeCliente: string;
  nomeStatus: string;
  nomeTipo: string;
  caixaBanco: string;
};

export type PortadorVisualizarTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  empresaId: number;
  portadorGeral: boolean;
  efetuarLancamento: boolean;
  tipoPortadorId: string;
  nomeTipoPortador: string;
  nomeColaborador: string;
  nomeEmpresa: string;
  nomeProprietario: string;
  nomeCondominio: string;
  nomeVendedor: string;
  nomeCliente: string;
};

export type ListarPortadorItensTypes = {
  id: number;
  pedidoId: number;
  nomeStatus: string;
  identificacaoPedido: string;
  dataSaida: string;
  horaSaida: string;
  qtdNotasFiscais: number;
  qtdVolumes: number;
  valorTotal: number;
};

export type PortadorAlterarTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  empresaId: number;
  portadorGeral: boolean;
  efetuarLancamento: boolean;
  tipoPortadorId: string;
  nomeTipoPortador: string;
  nomeColaborador: string;
  nomeEmpresa: string;
  nomeProprietario: string;
  nomeCondominio: string;
  nomeVendedor: string;
  nomeCliente: string;
};
