export const ObjListarAlocacaoPadrao: ListarAlocacaoPedidoTypes = {
  Pesquisa: {
    dataInicial: "",
    dataFinal: "",
    somentePedidosComAlocacao: false,
    somentePedidosBloqueados: false,
    empresaId: 0,
    listaNaturezasOperacaoId: "",
    pedidoCliente: "",
    listaClientesId: "",
    listaVendedoresId: "",
    grupoColaborador: "",
    grupoColaboradorExclusao: "",
    listaClassesId: "",
    pedidosNovosSaldosId: 0,
    listaCondicoesPagamentoId: "",
    situacaoEstoquePedidoId: 0,
    identificacao: "",
    codigoProduto: "",
  },
  Itens: [],
};

export type ListarAlocacaoPedidoTypes = {
  Pesquisa: ListarAlocacaoPedidoPesquisaTypes;
  Itens: ListarAlocacaoPedidoItensTypes[];
};

export type ListarAlocacaoPedidoPesquisaTypes = {
  dataInicial: string;
  dataFinal: string;
  somentePedidosComAlocacao: boolean;
  somentePedidosBloqueados: boolean;
  empresaId: number;
  listaNaturezasOperacaoId: string;
  pedidoCliente: string;
  listaClientesId: string;
  listaVendedoresId: string;
  grupoColaborador: string;
  grupoColaboradorExclusao: string;
  listaClassesId: string;
  pedidosNovosSaldosId: number;
  listaCondicoesPagamentoId: string;
  situacaoEstoquePedidoId: number;
  identificacao: string;
  codigoProduto: string;
};

export type ListarAlocacaoPedidoItensTypes = {
  id: string;
  ordemAlocacao: number;
  identificacaoPedido: string;
  pedidoCliente: string;
  clienteId: number;
  numeroReferencia: string;
  nomePedido: string;
  nomeCliente: string;
  pedidoImpresso: boolean;
  pedidoBloqueado: boolean;
  faturamentoBloqueado: boolean;
  qtdAnexos: number;
  nomeVendedor: string;
  nomeCondicaoPagamento: string;
  nomeNaturezaOperacao: string;
  qtdItens: number;
  totalParaFaturamento: number;
  totalPedido: number;
  totalPercAlocQtd: number;
  totalPercAlocQtdEstoque: number;
  totalPercAlocValor: number;
  totalPercAlocValorTrunc: number;
  nomeStatus: string;
};
