import { AddIcon, StarIcon } from "@chakra-ui/icons";
import {
  FormLabel,
  Switch,
  Center,
  Icon,
  Text,
  Tooltip,
  Badge,
  Flex,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";
import {
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useWatch } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { CGIBox } from "../../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../../../Components/Button/CGIButton";
import { useCGIToast } from "../../../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../../../Components/Input/CGIInput";
import { CGINumberInput } from "../../../../../Components/NumberInput/CGINumberInput";
import { CGIResumo } from "../../../../../Components/Resumo/CGIResumo";
import CGISearchDetail from "../../../../../Components/SeachDetail/CGISearchDetail";
import { Temas } from "../../../../../Themes/Temas";
import {
  DadosProdutoSelecionadoType,
  PedidoLojaType,
} from "../../../../../Types/Vendas/Pedido/PedidoLojaType";
import { AdicionarProdutoNaoCadastrado } from "../Modal/_AdicionarProdutonaoCadastrado";
import PedLojaContext from "../_AlterarPedidoLoja";
const produtoDefault: DadosProdutoSelecionadoType = {
  PesquisaDetalhada: "",
  vlrDesconto: 0,
  id: 0,
  codigoProduto: "",
  estoque: 0,
  multiplo: 0,
  precoVenda: 0,
  precoPromocional: 0,
  precoCampanhaVendas: 0,
  descricao: "",
  quantidade: 0,
  obsVenda: "",
  gradeProdutoId: 0,
  unidade: "",
  unidadesConversao: "",
  pesoBruto: 0,
  percICMS: 0,
  percIPI: 0,
  percFCP: 0,
  percPIS: 0,
  percCOFINS: 0,
  ultimaAlteracaoPrecoProduto: "",
  ehPrecoPromocional: false,
  ehPrecoCampanhaVendas: false,
  codigoProdutoFabricante: "",
  ncm: "",
  tipoProdutoId: 0,
  percDesconto: 0,
  usuarioDescontoId: "",
  descontoMaximo: 0,
  defeito: false,
  vlrMinimo: 0,
  vlrMinimoCadastro: 0,
  vlrUnitario: 0,
  campanhaVendasId: 0,
  nomeCampanhaVendas: 0,
};

export const AreaProduto = forwardRef((props: {}, ref) => {
  useImperativeHandle(ref, () => ({}));
  const { AdicionarItensGridPrincipal, form } = useContext(PedLojaContext);
  const AdicionarProdutoNaoCadastradoRef = useRef<any>(null);
  const PesquisaRef = useRef<any>(null);
  const [CodBar, setCodBar] = useState(false);
  const { ToastErrorId } = useCGIToast();
  const ConfigPedLoja = useWatch({
    control: form.control,
    name: "config",
  });

  const ValidarDesconto = useCallback(() => {
    let { precoVenda, vlrDesconto } = form.getValues("DadosProdutoSelecionado");
    vlrDesconto = vlrDesconto ?? 0;
    var percDesconto = (vlrDesconto / precoVenda) * 100;
    if (percDesconto > ConfigPedLoja.descontoMaximoPercentual) {
      ToastErrorId(
        "PercDesconto",
        "Desconto máximo permitido é de " +
          ConfigPedLoja.descontoMaximoPercentual +
          "%"
      );
      form.setValue("DadosProdutoSelecionado.vlrDesconto", 0);
      form.setFocus("DadosProdutoSelecionado.vlrDesconto");
      return;
    }
  }, [ConfigPedLoja.descontoMaximoPercentual, ToastErrorId, form]);

  const InserirProdutoNaoCadastrado = useCallback(
    (Dados: DadosProdutoSelecionadoType) => {
      AdicionarItensGridPrincipal([{ ...Dados }]);

      setTimeout(() => {
        form.setValue("DadosProdutoSelecionado", produtoDefault);
        form.setFocus("DadosProdutoSelecionado.PesquisaDetalhada");
      }, 100);
    },
    [AdicionarItensGridPrincipal, form]
  );

  const InserirItemCodBarGridProdutos = useCallback(
    (Dados: DadosProdutoSelecionadoType) => {
      AdicionarItensGridPrincipal([{ ...Dados }]);

      setTimeout(() => {
        form.setValue("DadosProdutoSelecionado", produtoDefault);
        form.setFocus("DadosProdutoSelecionado.PesquisaDetalhada");
      }, 100);
    },
    [AdicionarItensGridPrincipal, form]
  );

  const AdicionarItem = useCallback(
    (dados: DadosProdutoSelecionadoType) => {
      if (CodBar) {
        InserirItemCodBarGridProdutos(dados);
      }
      form.setValue("DadosProdutoSelecionado", dados);
      form.setFocus("DadosProdutoSelecionado.quantidade");
    },
    [CodBar, InserirItemCodBarGridProdutos, form]
  );

  const InserirItemGridProdutos = useCallback(() => {
    const Dados = form.getValues("DadosProdutoSelecionado");
    AdicionarItensGridPrincipal([{ ...Dados }]);
    setTimeout(() => {
      form.setValue("DadosProdutoSelecionado", produtoDefault);
      form.setFocus("DadosProdutoSelecionado.PesquisaDetalhada");
    }, 100);
  }, [AdicionarItensGridPrincipal, form]);

  const MapTeclado = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        InserirItemGridProdutos();
      }
    },
    [InserirItemGridProdutos]
  );

  useHotkeys(
    "Control+F1",
    () => {
      setCodBar(!CodBar);
    },
    { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] }
  );
  useHotkeys(
    "Control+f",
    () => {
      PesquisaRef.current?.Abrir();
    },
    { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] }
  );
  return (
    <>
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIBox spacing="0px" titulo="Pesquisar Produto">
            <CGIBoxItem Col={4}>
              <Tooltip
                placement="top"
                hasArrow
                label={
                  <Center>
                    <Badge>Ctrl + F1</Badge>
                  </Center>
                }
                bgColor={Temas.Primaria}
              >
                <FormLabel>
                  <Text>
                    Cód. de Barras
                    <Switch
                      isChecked={CodBar}
                      onChange={() => {
                        setCodBar(!CodBar);
                      }}
                      px={2}
                      size="md"
                      id="LeitorDeBarras"
                    />
                  </Text>
                </FormLabel>
              </Tooltip>
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <Tooltip
                placement="top"
                hasArrow
                label={
                  <Center>
                    <Badge>Ctrl + F</Badge>
                  </Center>
                }
                bgColor={Temas.Primaria}
              >
                <Center
                  _hover={{
                    cursor: "pointer",
                  }}
                  p={0}
                  borderRadius={"md"}
                  borderColor={Temas.Primaria}
                  borderWidth={1}
                  color={Temas.Primaria}
                  onClick={() => {}}
                  fontWeight={"semibold"}
                >
                  <Icon mx={2} as={StarIcon} />
                  <Text>Ver Favoritos</Text>
                </Center>
              </Tooltip>
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <Tooltip
                placement="top"
                hasArrow
                label={
                  <Center>
                    <Badge>Ctrl + F</Badge>
                  </Center>
                }
                bgColor={Temas.Primaria}
              >
                <Center
                  _hover={{
                    cursor: "pointer",
                  }}
                  p={0}
                  mx={2}
                  borderRadius={"md"}
                  borderColor={Temas.Primaria}
                  borderWidth={1}
                  color={Temas.Primaria}
                  onClick={() => {
                    AdicionarProdutoNaoCadastradoRef.current.Abrir();
                  }}
                  fontWeight={"semibold"}
                >
                  <Icon mx={2} as={AddIcon} />
                  <Text>Produto</Text>
                </Center>
              </Tooltip>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGISearchDetail
                ref={PesquisaRef}
                placeholder="Pesquisar por Código, Descrição  ou GTIN"
                AdicionarItensGrid={AdicionarItensGridPrincipal}
                AdicionarGridFormProduto={AdicionarItem}
                link={"produto/obter-produtos-para-pedido"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIBox titulo="Adicionar Novo Item" popover={"Adicionar Novo Item"}>
            <CGIBoxItem hidden Col={2}>
              <CGIInput<PedidoLojaType>
                id={"DadosProdutoSelecionado.id"}
                label={"Id"}
                placeholder={""}
                att={{
                  isDisabled: true,
                }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={2}>
              <CGIInput<PedidoLojaType>
                id={"DadosProdutoSelecionado.codigoProduto"}
                label={"Produto"}
                placeholder={""}
                att={{
                  isDisabled: true,
                }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={8}>
              <CGIInput<PedidoLojaType>
                id={"DadosProdutoSelecionado.descricao"}
                label={"Descrição"}
                placeholder={""}
                att={{
                  isDisabled: true,
                }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={2}>
              <CGIInput<PedidoLojaType>
                id={"DadosProdutoSelecionado.estoque"}
                label={"Estoque"}
                placeholder={""}
                att={{
                  isDisabled: true,
                }}
              />
            </CGIBoxItem>

            <CGIBoxItem Col={2}>
              <CGINumberInput<PedidoLojaType>
                qtdCasasDecimais={ConfigPedLoja.qtdCasasDecimaisQtd}
                id={"DadosProdutoSelecionado.quantidade"}
                label={"Quant."}
                att={{
                  onKeyDown: MapTeclado,
                }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={2}>
              <CGINumberInput<PedidoLojaType>
                qtdCasasDecimais={ConfigPedLoja.qtdCasasDecimaisVlrUnitario}
                prefix
                id={"DadosProdutoSelecionado.precoVenda"}
                label={"Pr. Venda"}
                att={{
                  isDisabled: !ConfigPedLoja.permitirUsuarioAlterarVlrUnitario!,
                  onKeyDown: MapTeclado,
                }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={8}>
              <CGIBox spacing="0px" titulo="Desconto">
                <CGIBoxItem Col={12}>
                  <RadioGroup size={"sm"}>
                    <Stack direction="row">
                      <Radio value="1">R$</Radio>
                      <Radio value="2">%</Radio>
                    </Stack>
                  </RadioGroup>
                  <CGINumberInput<PedidoLojaType>
                    qtdCasasDecimais={ConfigPedLoja.qtdCasasDecimaisVlrUnitario}
                    prefix
                    id={"DadosProdutoSelecionado.vlrDesconto"}
                    label={""}
                    att={{
                      onKeyDown: MapTeclado,
                      onChange: e => ValidarDesconto(),
                    }}
                  />
                </CGIBoxItem>
              </CGIBox>
            </CGIBoxItem>

            <CGIBoxItem Col={2}>
              <CGIButton
                att={{ mt: 8, colorScheme: "red" }}
                label="Cancelar"
                onClick={() => {
                  form.setValue("DadosProdutoSelecionado", produtoDefault);
                  form.setFocus("DadosProdutoSelecionado.PesquisaDetalhada");
                }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIButton
                att={{ mt: 8 }}
                label="Adicionar Produto"
                onClick={() => InserirItemGridProdutos()}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIResumo
            ProdutoId="DadosProdutoSelecionado.id"
            ResumoProduto
            ResumoProdutoEstoqueEmpresa
            ProdutoEstoqueEmpresaId="produtoId"
          />
        </CGIBoxItem>
      </CGIBox>
      <AdicionarProdutoNaoCadastrado
        AddItem={InserirProdutoNaoCadastrado}
        ref={AdicionarProdutoNaoCadastradoRef}
      />
    </>
  );
});
