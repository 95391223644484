import { AddIcon } from "@chakra-ui/icons";
import { useState, useRef, useContext, useCallback, useEffect } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIHistorico } from "../../../Components/Historico/CGIHistorico";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import {
  ListarUsuarioTypes,
  ListarUsuarioItensTypes,
  ObjListarUsuarioPadrao,
  FormUsuarioTypes,
} from "../../../Types/Sistema/Usuario/UsuarioTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpUsuario } from "./Http";
import { AlterarUsuario } from "./_AlterarUsuario";
import { CopiarPermissao } from "./_CopiarPermissao";
import { TrocarSenhaUsuario } from "./_TrocarSenha";
import { VisualizarUsuario } from "./_VisualizarUsuario";

export function ListarUsuario() {
  //Inicio Hooks

  let [Dados, setDados] = useState([]);
  const AlterarUsuarioRef = useRef<ModalBaseType>();
  const VisualizarUsuarioRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const CGIHistoricoRef = useRef<ModalBaseType>();
  const TrocarSenhaUsuarioRef = useRef<ModalBaseType>();
  const CopiarPermissaoRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarUsuarioTypes>({
    defaultValues: ObjListarUsuarioPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });
  //Final Hooks

  //------------------------

  //Inicio Consts
  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo Usuário",
      Permissao: "sistema/usuario/incluir",
      OnClick: () => {
        AlterarUsuarioRef.current?.Abrir();
      },
    },
    {
      Nome: "Copiar Permissões de Usuário",
      Permissao: "sistema/usuario/copiarpermissao",
      OnClick: () => {
        CopiarPermissaoRef.current?.Abrir();
      },
    },
    {
      Nome: "Forçar todos usuários a trocar a senha",
      Permissao: "sistema/usuario/listar",
      OnClick: () => {
        TrocarSenhaUsuarioRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "sistema/usuario/visualizar",
      OnClick: (e: ListarUsuarioItensTypes) => {
        VisualizarUsuarioRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Alterar",
      Permissao: "sistema/usuario/alterar",
      OnClick: (e: ListarUsuarioItensTypes) => {
        AlterarUsuarioRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/empresa/excluir",
      OnClick: (e: ListarUsuarioItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Historico",
      Permissao: "sistema/usuario/visualizarhistorico",
      OnClick: (e: ListarUsuarioItensTypes) => {
        CGIHistoricoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Trocar Senha",
      Permissao: "sistema/usuario/listar",
      OnClick: (e: ListarUsuarioItensTypes) => {
        TrocarSenhaUsuarioRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarUsuarioItensTypes>> = [
    {
      Campo: "ativo",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: [
        {
          nomeStatus: "true",
          cor: "green",
        },
        {
          nomeStatus: "false",
          cor: "red",
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 80,
      },
    },
    {
      Campo: "login",
      Titulo: "Login",
      Tipo: "Texto",
      att: {
        maxWidth: 200,
      },
    },
    {
      Campo: "nome",
      Titulo: "Nome",
      Tipo: "Texto",
    },
    {
      Campo: "email",
      Titulo: "E-mail",
      Tipo: "Texto",
    },
    {
      Campo: "nomeEmpresa",
      Titulo: "Empresa",
      Tipo: "Texto",
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarUsuarioItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];

  const Janelas = [
    {
      Componente: (
        <AlterarUsuario
          CarregarDados={methods.handleSubmit(RetornarUsuarios)}
          ref={AlterarUsuarioRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarUsuario
          CarregarDados={methods.handleSubmit(RetornarUsuarios)}
          ref={VisualizarUsuarioRef}
        />
      ),
    },
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Usuário"
          CarregarDados={methods.handleSubmit(RetornarUsuarios)}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <CGIHistorico
          tipo="Tabela"
          titulo="Historico Usuário"
          ref={CGIHistoricoRef}
          link={"sistema/usuario/visualizarhistorico"}
          origem={"usuario"}
        />
      ),
    },
    {
      Componente: (
        <TrocarSenhaUsuario titulo="Trocar Senha" ref={TrocarSenhaUsuarioRef} />
      ),
    },
    {
      Componente: <CopiarPermissao ref={CopiarPermissaoRef} />,
    },
  ];

  //Final Consts

  //------------------------

  //Inicio Function
  const RetornarGetListar = useCallback(async () => {
    var data = await HttpUsuario().Listar();
    methods.reset(data);
  }, [methods]);

  useEffect(() => {
    RetornarGetListar();
  }, [RetornarGetListar]);

  async function RetornarUsuarios() {
    setLoadingState(true);
    var DadosFiltro = methods.getValues("Pesquisa");
    var data = await HttpUsuario()
      .RetornarUsuarios(DadosFiltro)
      .finally(() => {
        setLoadingState(false);
      });
    methods.setValue("Itens", data);
  }

  async function RetornarDadosExclusao(data: FormUsuarioTypes) {
    return await HttpUsuario().RetornarDadosExclusao(data.Form.DadosRegistro);
  }
  async function Excluir(data: DeleteTypes) {
    await HttpUsuario()
      .Excluir(data)
      .then(e => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  //Final Function

  //------------------------

  // Inicio JSX

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIInput id="login" label="Login" placeholder="Login" />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput id="nome" label="Nome" placeholder="Nome" />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples
            Link={"simnaoambos"}
            label={"Acesso Total"}
            id={"AcessoTotalId"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples
            Link={"simnaoambos"}
            label={"Ativo"}
            id={"ativoId"}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar id="sistema/usuario/listar" Titulo="Usuários" Janelas={Janelas}>
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarUsuarios)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
