import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useStatStyles,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  FieldValues,
  FormProvider,
  useForm,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import { Temas } from "../../Themes/Temas";
import { HttpCGIResumosComposto } from "./HttpResumo";
import { ResumoColaborador } from "./Resumos/ResumoColaborador";
import { ResumoCtsPagar } from "./Resumos/ResumoCtsPagar";
import { ResumoCtsReceber } from "./Resumos/ResumoCtsReceber";
import { ResumoItensPedido } from "./Resumos/ResumoItensPedido";
import { ResumoPedido } from "./Resumos/ResumoPedido";
import { ResumoProduto } from "./Resumos/ResumoProduto";
import { ResumoProdutoEstoqueEmpresas } from "./Resumos/ResumoUltimasCompras";
import { ResumoProdutoSimples } from "./Resumos/ResumoProdutoSimples";
import { ResumoProdutoCodigoEDescricao } from "./Resumos/ResumoProdutoCodigoEDescricao";
import { ResumoNFSaida } from "./Resumos/ResumoNFSaida";
import { ResumoSugestaoPedidoCompra } from "./Resumos/ResumoSugestaoPedidoCompra";
type CGIResumoType = {
  Expandir?: boolean;
  PedidoId?: string;
  ResumoPedido?: boolean;

  ColaboradorId?: string;
  ResumoColaborador?: boolean;

  PedidoItensId?: string;
  ResumoPedidoItens?: boolean;

  CtsPagarId?: string;
  ResumoCtsPagar?: boolean;

  SugestaoPedidoCompraId?: string;
  ResumoSugestaoPedidoCompra?: boolean;

  CtsReceberId?: string;
  ResumoCtsReceber?: boolean;

  MovPortadorId?: string;
  ResumoMovPortador?: boolean;

  NFSaidaId?: string;
  ResumoNFSaida?: boolean;

  ProdutoId?: string;
  ResumoProduto?: boolean;
  ResumoProdutoSimples?: boolean;
  ResumoProdutoCodigoEDescricao?: boolean;

  ProdutoEstoqueEmpresaId?: string;
  ResumoProdutoEstoqueEmpresa?: boolean;

  Principal?: "PEDIDO" | "COLABORADOR" | "PRODUTO" | "PRODUTOESTOQUEEMPRESA";
};

export function CGIResumo(props: CGIResumoType) {
  var Form = useFormContext();

  var FormResumo = useForm();
  const [Resumos] = useState<
    Array<{
      id: string;
      titulo: string;
      componente: any;
      Exibir?: boolean;
      watchId?: string;
    }>
  >(() => {
    var ColaboradorId = props.ColaboradorId
      ? props.ColaboradorId
      : "COLABColaboradorId";
    var PedidoId = props.PedidoId ? props.PedidoId : "PEDId";
    var PedidoItensId = props.PedidoItensId ? props.PedidoItensId : "PEDId";
    var SugestaoPedidoCompraId = props.SugestaoPedidoCompraId
      ? props.SugestaoPedidoCompraId
      : "SUGId";
    var ProdutoId = props.ProdutoId ? props.ProdutoId : "PRODId";
    var CtsPagarId = props.CtsPagarId ? props.CtsPagarId : "CTSPAGARId";
    var CtsReceberId = props.CtsReceberId ? props.CtsReceberId : "CTSRECEBERId";
    var MovPortadorId = props.MovPortadorId
      ? props.MovPortadorId
      : "MOVPORTADORId";
    var NFSaidaId = props.NFSaidaId ? props.NFSaidaId : "NFSAIDAId";
    var ProdutoEstoqueEmpresaId = props.ProdutoEstoqueEmpresaId
      ? props.ProdutoEstoqueEmpresaId
      : "PRODId";

    switch (props.Principal) {
      case "COLABORADOR":
        ColaboradorId = "id";
        break;
      case "PEDIDO":
        PedidoId = "id";
        break;
      case "PRODUTO":
        ProdutoId = "id";
        break;

      default:
        break;
    }

    return [
      {
        ...ResumoPedido,
        Exibir: props.ResumoPedido ? true : false,
        watchId: PedidoId,
      },
      {
        ...ResumoColaborador,
        Exibir: props.ResumoColaborador ? true : false,
        watchId: ColaboradorId,
      },
      {
        ...ResumoItensPedido,
        Exibir: props.ResumoPedidoItens ? true : false,
        watchId: PedidoItensId,
      },
      {
        ...ResumoProduto,
        Exibir: props.ResumoProduto ? true : false,
        watchId: ProdutoId,
      },
      {
        ...ResumoProdutoEstoqueEmpresas,
        Exibir: props.ResumoProdutoEstoqueEmpresa ? true : false,
        watchId: ProdutoEstoqueEmpresaId,
      },
      {
        ...ResumoCtsPagar,
        Exibir: props.ResumoCtsPagar ? true : false,
        watchId: CtsPagarId,
      },
      {
        ...ResumoCtsReceber,
        Exibir: props.ResumoCtsReceber ? true : false,
        watchId: CtsReceberId,
      },
      {
        ...ResumoProdutoSimples,
        Exibir: props.ResumoProdutoSimples ? true : false,
        watchId: ProdutoId,
      },
      {
        ...ResumoProdutoCodigoEDescricao,
        Exibir: props.ResumoProdutoCodigoEDescricao ? true : false,
        watchId: ProdutoId,
      },
      // {
      //   ...ResumoMovPortador,
      //   Exibir: props.ResumoMovPortador ? true : false,
      //   watchId: MovPortadorId,
      // },
      {
        ...ResumoNFSaida,
        Exibir: props.ResumoNFSaida ? true : false,
        watchId: NFSaidaId,
      },
      {
        ...ResumoSugestaoPedidoCompra,
        Exibir: props.ResumoSugestaoPedidoCompra ? true : false,
        watchId: SugestaoPedidoCompraId,
      },
    ];
  });

  return (
    <Box
      borderRadius={"md"}
      borderWidth={0.5}
      shadow={"md"}
      bgColor={Temas.Resumos}
    >
      <Accordion defaultIndex={props.Expandir ? 0 : undefined} allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Text
                color={Temas.Branco}
                fontWeight={"bold"}
                flex="1"
                textAlign="left"
              >
                Resumos
              </Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel bgColor={Temas.Branco} pb={4}>
            <Tabs isFitted variant="enclosed">
              <TabList>
                {Resumos.map((e, index) => {
                  return (
                    e.Exibir && (
                      <Tab key={index} fontWeight={"bold"}>
                        {e.titulo}
                      </Tab>
                    )
                  );
                })}
              </TabList>
              <FormProvider {...FormResumo}>
                <TabPanels>
                  {Resumos.map((e, index) => {
                    return (
                      e.Exibir && (
                        <TabPanel key={index}>
                          {e.componente(e.watchId, Form, FormResumo)}
                        </TabPanel>
                      )
                    );
                  })}
                </TabPanels>
              </FormProvider>
            </Tabs>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
}
