import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  FormControl,
  ModalBody,
  Box,
  ModalFooter,
} from "@chakra-ui/react";
import {
  forwardRef,
  useState,
  useRef,
  useContext,
  useImperativeHandle,
  useEffect,
  useCallback,
} from "react";
import { FormState, FieldValues, useFormContext } from "react-hook-form";
import ErrorContext from "../../Context/ErrorContext";
import { ModalBaseType } from "../../Types/Modal/ModalBaseType";
import { CGIButtonCancelar } from "../Button/CGIButtonCancelar";
import { CGIButtonConfirmar } from "../Button/CGIButtonConfirmar";
import { CGITituloJanela } from "../Form/CGITituloJanela";
import { useCGIToast } from "../Hook/useCGIToast";
import { CGIInput } from "../Input/CGIInput";
import { HttpUtil } from "../Util/Http";
import {
  RetornarSeUsuarioTemPemissaoPeloLink,
  RetornarSeUsuarioTemPemissaoPeloId,
} from "../Util/Permissoes";
import { CGISearch } from "./CGISearch";

interface CGIModalType {
  id: string;
  tipoGET?: {
    url: string;
    setRetorno: (data: any) => void;
  };
  OcultarToastErro?: boolean;
  OcultarPermissoes?: boolean;
  children: React.ReactNode;
  Titulo: string;
  Confirmar: any;
  CarregarDados?: Function;
  Fechar?: Function;
  ocultarConfirmar?: boolean;
  ocultarCancelar?: boolean;
  IgnorarForm?: boolean;
  TelaCheia:
    | "full"
    | "lg"
    | "md"
    | "sm"
    | "xl"
    | "xs"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "7xl";
}
/**
 * @Componente CGIModal - Componente Base para o Modal.
 */
export const CGIModal = forwardRef((props: CGIModalType, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [AlterarPermissoesUsuarios, setAlterarPermissoesUsuarios] =
    useState(false);

  const CGIAlterarPermissaoTelaRef = useRef<ModalBaseType>(null);
  const { setErrorState } = useContext(ErrorContext);
  useImperativeHandle(ref, () => ({
    async Abrir() {
      var TemPermissao = RetornarSeUsuarioTemPemissaoPeloLink(props.id);
      var PodeAlterarPermissao = RetornarSeUsuarioTemPemissaoPeloId(1865);
      if (props.OcultarPermissoes === true) {
        setAlterarPermissoesUsuarios(false);
      } else {
        setAlterarPermissoesUsuarios(PodeAlterarPermissao);
      }
      if (TemPermissao) {
        RetornarTipoGet();

        onOpen();
      } else {
        setErrorState(true);
      }
    },
    Fechar() {
      onClose();
    },
  }));
  const { ToastErrorId } = useCGIToast();

  const RetornarTipoGet = useCallback(async () => {
    if (props.tipoGET) {
      var Dados = await HttpUtil().RetornarGETTelas(props.tipoGET.url);
      props.tipoGET.setRetorno(Dados);
    }
  }, [props.tipoGET]);

  function ErrorForm(formState: FormState<FieldValues>) {
    if (!props.OcultarToastErro) {
      var Arr = Object.keys(formState.errors);
      if (Arr.length !== 0) {
        var str = "";
        Arr.map(e => {
          var Er = formState.errors[e] as any;
          var Field = Object.keys(Er as any)[0];
          console.log(Er, Field);

          if (Er) {
            if (Er.message !== "") {
              return (str += `${Er.message} </br>`);
            } else {
              return (str += `${e
                .replace(/([A-Z])/g, " $1")
                .replace("Id", "")
                .toUpperCase()} obrigatório </br>`);
            }
          }
        });
        ToastErrorId("Error", str, "Erro");
      }
    }
    return <></>;
  }

  const { reset, formState, clearErrors, handleSubmit, getValues, watch } =
    useFormContext();

  const [ValorId, setValorId] = useState(0);
  useEffect(() => {
    var Id = getValues("id");
    if (Id == 0 || Id == null || Id == undefined) {
      return;
    }
    if (Id === ValorId) {
      return;
    } else {
      if (props.CarregarDados) {
        setValorId(Id);
        props.CarregarDados(Id);
      }
    }
  }, [watch(["id"])]);

  return (
    <>
      <Box>
        <Modal
          closeOnEsc={false}
          size={props.TelaCheia}
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={() => {
            clearErrors();
            reset();
            onClose();
          }}
          onCloseComplete={() => props.Fechar && props.Fechar()}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <CGITituloJanela
                id={props.id}
                Titulo={props.Titulo}
                Janela={true}
                temPermissao={AlterarPermissoesUsuarios}
                AlterarPermissao={() => {
                  CGIAlterarPermissaoTelaRef.current?.Abrir();
                }}
              />
              {props.CarregarDados ? (
                <Box>
                  <CGISearch link={props.id} />
                </Box>
              ) : (
                <></>
              )}
            </ModalHeader>
            <ModalCloseButton />
            <FormControl>
              <ModalBody>
                {props.CarregarDados ? (
                  <CGIInput
                    id="id"
                    label="Id"
                    placeholder="Id"
                    ocultar
                    att={{ type: "number", isReadOnly: true }}
                  />
                ) : (
                  <></>
                )}
                {props.children}
              </ModalBody>
            </FormControl>
            <ModalFooter gap={4}>
              <CGIButtonCancelar
                hidden={props.ocultarCancelar}
                onClick={onClose}
              >
                Fechar
              </CGIButtonCancelar>
              <CGIButtonConfirmar
                hidden={props.ocultarConfirmar}
                onClick={
                  props.IgnorarForm
                    ? props.Confirmar
                    : handleSubmit(props.Confirmar)
                }
              >
                Confirmar
              </CGIButtonConfirmar>
            </ModalFooter>
          </ModalContent>
        </Modal>
        {formState.errors && ErrorForm(formState)}
      </Box>
    </>
  );
});
