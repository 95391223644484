import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import {
  DadosRegistroTypes,
  FormEquipamentoTypes,
  EquipamentoVisualizarTypes,
} from "../../../Types/Sistema/Equipamento/EquipamentoTypes";
import { HttpEquipamento } from "./Http";

type VisualizarEquipamentoType = {
  CarregarDados: Function;
};
export const VisualizarEquipamento = forwardRef(
  (props: VisualizarEquipamentoType, ref) => {
    // INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current?.Abrir();
      },
    }));
    // FINAL REF

    const CGIModalRef = useRef<ModalBaseType>(null);
    const { reset } = useFormContext();

    async function CarregarDados(params: FormEquipamentoTypes) {
      var data = await HttpEquipamento().Visualizar(params.Form.DadosRegistro);
      reset(data.data.data);
    }

    return (
      <CGIModal
        id="sistema/equipamento/visualizar"
        ref={CGIModalRef}
        Titulo="Visualizar Equipamento"
        TelaCheia={"6xl"}
        Confirmar={() => {}}
        Fechar={() => {}}
        ocultarCancelar={true}
        ocultarConfirmar={true}
      >
        <CGIBox>
          <CGIBoxItem Col={3}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="id"
              label="Id"
              placeholder="Id"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="identificacao"
              label="Identificação"
              placeholder="Identificação"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="frota"
              label="Frota"
              placeholder="Frota"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="modelo"
              label="Modelo"
              placeholder="Modelo"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGICheckBox<EquipamentoVisualizarTypes>
              id="ativo"
              label="Ativo"
              tooltip=""
              att={{
                disabled: true,
                style: {
                  marginTop: 30,
                },
              }}
              attLabel={{
                marginTop: 30,
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="nomeOperador"
              label="Operador"
              placeholder="Operador"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="nomeEmpresa"
              label="Empresa"
              placeholder="Empresa"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="nomeCentroDeCusto"
              label="Centro De Custo"
              placeholder="Centro De Custo"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="capacidadeM3"
              label="Capacidade M3"
              placeholder="Capacidade M3"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="capacidadeKG"
              label="Capacidade KG"
              placeholder="Capacidade KG"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="tara"
              label="Tara"
              placeholder="Tara"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="tipoKM"
              label="Tipo KM"
              placeholder="Tipo KM"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="valorAquisicao"
              label="Valor Aquisição"
              placeholder="Valor Aquisição"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="valorAtual"
              label="Valor Atual"
              placeholder="Valor Atual"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="dataAquisicao"
              label="Data Aquisição"
              placeholder="Data Aquisição"
              mascara="Data"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="renavan"
              label="Renavam"
              placeholder="Renavam"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="chassi"
              label="Chassi"
              placeholder="Chassi"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="dataValidadeTacografo"
              label="Data Validade Tacógrafo"
              placeholder="Data Validade Tacógrafo"
              mascara="Data"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="dataVencimentoIPVA"
              label="Vencimento IPVA"
              placeholder="Vencimento IPVA"
              mascara="Data"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="nomeTransportadora"
              label="Transportadora"
              placeholder="Transportadora"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<EquipamentoVisualizarTypes>
              id="obs"
              label="Observação"
              placeholder="Observação"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
  }
);
