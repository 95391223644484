import { CGIHttp } from "../../../Services/Axios";
import {
  FormPedidoEntradaType,
  ListarPesquisaPedidoEntradaTypes,
  ListarPedidoEntradaItensTypes,
  PedidoEntradaPesquisaGridTypes,
  PedidoEntradaTypes,
} from "../../../Types/Almoxarifado/PedidoEntrada/PedidoEntradaTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpPedidoEntrada() {
  const url = "/PedidoEntrada";

  async function RetornarDadosGridPesquisa(
    dados: PedidoEntradaPesquisaGridTypes
  ): Promise<ListarPedidoEntradaItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-pedido-entrada", dados)
      .then((e) => e.data.data);
  }

  async function RetornarPedidoEntradas(
    DadosFiltros: ListarPesquisaPedidoEntradaTypes
  ): Promise<ListarPedidoEntradaItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(url + "/obter-pedido-entrada", DadosFiltros)
    ).data.data;
  }

  async function RetornarDadosPedidoEntrada(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + `/alterar/${id}`)
    ).data.data) as FormPedidoEntradaType;
  }

  async function RetornarDadosExclusao(Dados: ListarPedidoEntradaItensTypes) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + Dados.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }
  async function RetornarDadosVisualizarPedidoEntrada(
    DadosPedidoEntrada: FormPedidoEntradaType
  ) {
    return (await (
      await new CGIHttp().conn.get(
        url + "/visualizar/" + DadosPedidoEntrada.Form
      )
    ).data.data) as PedidoEntradaTypes;
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir/" + Dados.id);
  }
  async function Alterar(DadosPedidoEntrada: FormPedidoEntradaType) {
    return await new CGIHttp().conn.post(
      url + "/alterar",
      DadosPedidoEntrada.Form
    );
  }
  async function Visualizar(DadosPedidoEntrada: FormPedidoEntradaType) {
    return await new CGIHttp().conn.post(
      url + "/visualizar",
      DadosPedidoEntrada.Form
    );
  }
  async function Incluir(DadosPedidoEntrada: FormPedidoEntradaType) {
    return await new CGIHttp().conn.post(
      url + "/incluir",
      DadosPedidoEntrada.Form
    );
  }
  return {
    RetornarPedidoEntradas,
    RetornarDadosGridPesquisa,
    RetornarDadosExclusao,
    RetornarDadosVisualizarPedidoEntrada,
    RetornarDadosPedidoEntrada,
    Visualizar,
    Excluir,
    Alterar,
    Incluir,
  };
}
