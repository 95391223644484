import { Icon, IconProps } from "@chakra-ui/icons";
import { DadosUsuario } from "../../Types/Home/Login/LoginType";
import { MdTrendingUp, MdTrendingDown, MdAddBox } from "react-icons/md";
import {
  RiGasStationFill,
  RiBookletFill,
  RiArrowLeftCircleFill,
  RiEraserFill,
  RiDeleteBack2Fill,
  RiArchiveLine,
  RiDownload2Fill,
  RiBankFill,
  RiLockFill,
  RiMoneyDollarCircleFill,
  RiCalendar2Line,
  RiChat1Line,
  RiArrowUpDownFill,
  RiGroupFill,
  RiBarcodeLine,
  RiShoppingCartFill,
  RiSettings3Fill,
  RiCheckboxCircleFill,
  RiFileCopyLine,
  RiLockUnlockFill,
  RiTaskLine,
  RiEdit2Line,
  RiBuilding2Fill,
  RiCloseCircleFill,
  RiArrowGoBackLine,
  RiFileExcel2Fill,
  RiDeleteBin5Fill,
  RiBookmarkLine,
  RiBookmarkFill,
  RiFilter2Fill,
  RiCoinsFill,
  RiPieChart2Line,
  RiUploadCloudFill,
  RiPrinterLine,
  RiZoomInFill,
  RiTaskFill,
  RiZoomOutFill,
  RiSmartphoneFill,
  RiNotification3Fill,
  RiEyeOffFill,
  RiHome3Fill,
  RiFilePdfFill,
  RiSearch2Fill,
  RiArrowRightCircleFill,
  RiFileList3Fill,
  RiSave3Fill,
  RiKeyFill,
  RiAccountCircleFill,
  RiEyeFill,
  RiErrorWarningFill,
  RiListSettingsLine,
} from "react-icons/ri";
import { IconType } from "react-icons";

export function RetornarDadosUsuario() {
  var DadosUsuario: DadosUsuario = {} as DadosUsuario;
  var Dados = localStorage.getItem("dadosUsuario");
  if (Dados) {
    DadosUsuario = JSON.parse(Dados) as DadosUsuario;
  }
  return DadosUsuario;
}
export function GuardarDadosUsuario(DadosUsuario: DadosUsuario) {
  localStorage.setItem("dadosUsuario", JSON.stringify(DadosUsuario));
}
export function DeletarDadosUsuario() {
  return localStorage.removeItem("dadosUsuario");
}

export function RetornarSeUsuarioTemAcessoTotal() {
  var DadosUsuario = RetornarDadosUsuario();
  return DadosUsuario.acessoTotal;
}

export function RetornarSeUsuarioTemPemissaoPeloId(id: number) {
  var Permissoes = RetornarDadosUsuario().permissoes;

  var Permissao = Permissoes.find(e => e.id === id);
  if (Permissao) {
    var AcessoTotal = RetornarSeUsuarioTemAcessoTotal();

    if (AcessoTotal) {
      return true;
    }
    return Permissao.temPermissao;
  } else {
    return false;
  }
}
export function RetornarSeTelaEhFavoritaPeloLink(id: string) {
  var Favoritos = RetornarDadosUsuario().menuFavoritos;

  var Favorito = Favoritos.menuprincipal.menu[0].itens.find(e => e.link === id);
  if (Favorito) {
    return true; //Permissao.temPermissao;
  } else {
    return false;
  }
}
export function RetornarSeUsuarioTemPemissaoPeloLink(link: string) {
  var Permissoes = RetornarDadosUsuario().permissoes;

  var Permissao = Permissoes.find(e => e.link === link);
  if (Permissao === undefined) {
    return false;
  }

  var AcessoTotal = RetornarSeUsuarioTemAcessoTotal();

  if (AcessoTotal) {
    return true;
  }

  if (Permissao) {
    return Permissao.temPermissao;
  } else {
    return false;
  }
}
export function RetornarPemissaoPeloLink(link: string) {
  var Permissoes = RetornarDadosUsuario().permissoes;

  var Permissao = Permissoes.find(e => e.link === link);
  return Permissao ? Permissao : null;
}

export function RetornarParamentroBool(id: number): boolean {
  var Paramentros = RetornarDadosUsuario().parametros;

  var Paramentro = Paramentros.find(e => e.id === id);
  return Paramentro?.valor === "S";
}
type IconsConvert = {
  icones: string;
  propsIcons?: IconProps;
};

export function ConvertIcones(Icone: IconsConvert) {
  var icon: IconType;
  switch (Icone.icones) {
    case "abastecimento":
      icon = RiGasStationFill;
      break;
    case "agenda":
      icon = RiBookletFill;
      break;
    case "anterior":
      icon = RiArrowLeftCircleFill;
      break;
    case "apagar":
      icon = RiEraserFill;
      break;
    case "apagar mensagem":
      icon = RiDeleteBack2Fill;
      break;
    case "arquivar":
      icon = RiArchiveLine;
      break;
    case "baixar":
      icon = RiDownload2Fill;
      break;
    case "bancos":
      icon = RiBankFill;
      break;
    case "bloquear":
      icon = RiLockFill;
      break;
    case "caixa":
      icon = RiMoneyDollarCircleFill;
      break;
    case "calendário":
      icon = RiCalendar2Line;
      break;
    case "chat":
      icon = RiChat1Line;
      break;
    case "classificar":
      icon = RiArrowUpDownFill;
      break;
    case "clientes":
      icon = RiGroupFill;
      break;
    case "código de barras":
      icon = RiBarcodeLine;
      break;
    case "compras":
      icon = RiShoppingCartFill;
      break;
    case "configurações":
      icon = RiSettings3Fill;
      break;
    case "confirmar":
      icon = RiCheckboxCircleFill;
      break;
    case "copiar":
      icon = RiFileCopyLine;
      break;
    case "desbloquear":
      icon = RiLockUnlockFill;
      break;
    case "desmarcado":
      icon = RiTaskLine;
      break;
    case "editar":
      icon = RiEdit2Line;
      break;
    case "empresas":
      icon = RiBuilding2Fill;
      break;
    case "erro":
      icon = RiCloseCircleFill;
      break;
    case "estornar":
      icon = RiArrowGoBackLine;
      break;
    case "excel":
      icon = RiFileExcel2Fill;
      break;
    case "excluir":
      icon = RiDeleteBin5Fill;
      break;
    case "favorito desmarcado":
      icon = RiBookmarkLine;
      break;
    case "favorito marcado":
      icon = RiBookmarkFill;
      break;
    case "filtros":
      icon = RiFilter2Fill;
      break;
    case "financeiro":
      icon = RiCoinsFill;
      break;
    case "graficos":
      icon = RiPieChart2Line;
      break;
    case "importar":
      icon = RiUploadCloudFill;
      break;
    case "imprimir":
      icon = RiPrinterLine;
      break;
    case "mais zoom":
      icon = RiZoomInFill;
      break;
    case "marcado":
      icon = RiTaskFill;
      break;
    case "menos zoom":
      icon = RiZoomOutFill;
      break;
    case "mobile":
      icon = RiSmartphoneFill;
      break;
    case "notificação":
      icon = RiNotification3Fill;
      break;
    case "ocultar":
      icon = RiEyeOffFill;
      break;
    case "pagina inicial":
      icon = RiHome3Fill;
      break;
    case "pDF":
      icon = RiFilePdfFill;
      break;
    case "pesquisar":
      icon = RiSearch2Fill;
      break;
    case "próximo":
      icon = RiArrowRightCircleFill;
      break;
    case "relatório":
      icon = RiFileList3Fill;
      break;
    case "salvar":
      icon = RiSave3Fill;
      break;
    case "senha":
      icon = RiKeyFill;
      break;
    case "usuarios":
      icon = RiAccountCircleFill;
      break;
    case "visualizar":
      icon = RiEyeFill;
      break;
    case "sistema":
      icon = RiListSettingsLine;
      break;
    case "ctsreceber":
      icon = MdTrendingUp;
      break;
    case "ctspagar":
      icon = MdTrendingDown;
      break;
    case "cadastro":
      icon = MdAddBox;
      break;
    default:
      icon = RiErrorWarningFill;

      break;
  }
  return <Icon as={icon} {...Icone.propsIcons} />;
}
export function MontarTituloJanela(titulo: string) {
  var DadosUsuario = RetornarDadosUsuario();
  return `${titulo} [${DadosUsuario.nomeEmpresa}] - ${DadosUsuario.nomeUsuario}`;
}
