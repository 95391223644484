import { FormLabel, Box } from "@chakra-ui/react";
import FullCalendar, { EventClickArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import moment from "moment";
import { useRef } from "react";
import { Temas } from "../../Themes/Temas";
import { CalendarioOpcoes } from "./CGICalendarioOpcoes";
import { HttpCGICalendario } from "./HttpCalendario";
import { CGIModalCalendarioCtsPagar } from "./Modal/CGIModalCalendarioCtsPagar";
import { CGIModalCalendarioCtsReceber } from "./Modal/CGIModalCalendarioCtsReceber";
import { CGIModalCalendarioCtsReceberPagar } from "./Modal/CGIModalCalendarioCtsReceberPagar";
import { FormContext } from "../../Context/FormConxtext";

type CGICalendarioTypes = {
  titulo: string;
  link: string;
  local: string;
};

export function CGICalendario(props: CGICalendarioTypes) {
  const CGIModalCalendarioCtsReceberRef = useRef() as any;
  const CGIModalCalendarioCtsPagarRef = useRef() as any;
  const CGIModalCalendarioCtsReceberPagarRef = useRef() as any;

  function ChamarModalCalendario(event: EventClickArg) {
    switch (props.local) {
      case "ResumoFinanceiroCtsReceber":
        CGIModalCalendarioCtsReceberRef.current.Abrir(
          moment(event.event.start).format("yyyy-MM-DD"),
          props.link,
          props.titulo
        );
        break;
      case "ResumoFinanceiroCtsPagar":
        CGIModalCalendarioCtsPagarRef.current.Abrir(
          moment(event.event.start).format("yyyy-MM-DD"),
          props.link,
          props.titulo
        );
        break;
      case "ResumoFinanceiroCtsPagarReceber":
        CGIModalCalendarioCtsReceberPagarRef.current.Abrir(
          moment(event.event.start).format("yyyy-MM-DD"),
          props.link,
          props.titulo
        );
        break;

      default:
        break;
    }
  }
  const getCalendarEvents =
    () =>
    (
      info: { start: any; end: any },
      successCallback: (arg0: any) => void,
      failureCallback: (arg0: Error) => void
    ) => {
      HttpCGICalendario()
        .RetornarDados(info.start, info.end, props.link)
        .then(e => {
          successCallback(e);
        })
        .catch(e =>
          failureCallback(new Error("" || "Algo de Errado Aconteceu"))
        );
    };

  return (
    <Box bg={Temas.Branco} p={2} borderRadius="md" shadow={"lg"}>
      <FormLabel>{props.titulo}</FormLabel>
      <FullCalendar
        {...CalendarioOpcoes}
        aspectRatio={2}
        contentHeight={300}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        selectable={true}
        eventClick={ChamarModalCalendario}
        events={getCalendarEvents()}
      />
      <FormContext>
        <CGIModalCalendarioCtsReceber ref={CGIModalCalendarioCtsReceberRef} />
      </FormContext>
      <FormContext>
        <CGIModalCalendarioCtsPagar ref={CGIModalCalendarioCtsPagarRef} />
      </FormContext>
      <FormContext>
        <CGIModalCalendarioCtsReceberPagar
          ref={CGIModalCalendarioCtsReceberPagarRef}
        />
      </FormContext>
    </Box>
  );
}
