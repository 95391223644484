import { CGIHttp } from "../../../Services/Axios";
import { VisualizarOrcamentoPorProdutoPesquisaTypes } from "../../../Types/Vendas/Orcamento/VisualizarOrcamentoPorProduto";

export function HttpOrcamento() {
  const url = "/orcamento";

  async function RetornarOrcamentoPorProduto(
    Dados: VisualizarOrcamentoPorProdutoPesquisaTypes
  ) {
    return await (
      await new CGIHttp().conn.post(
        url + "/obter-orcamentos-por-produto",
        Dados
      )
    ).data.data;
  }
  return {
    RetornarOrcamentoPorProduto,
  };
}
