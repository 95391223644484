import { Box } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { Chrono } from "react-chrono";
import { TimelineItemModel } from "react-chrono/dist/models/TimelineItemModel";
import { Temas } from "../../Themes/Temas";

export type CGITimeLineItensTypes = {
  dataCadastro: Date;
  id: number;
  nomeUsuario: string;
  obs: string;
  titulo: string;
  usuarioId: string;
};
type CGITimeLineTypes = {
  Itens: Array<CGITimeLineItensTypes>;
};

/**
 * @Componente CGITimeLine - Componente usado no CGIHistorico
 * @Ref  CGIHistorico
 */

export function CGITimeLine(props: CGITimeLineTypes) {
  function RenderItens() {
    var DadosTime: Array<TimelineItemModel> = [];

    DadosTime = props.Itens.map((e) => ({
      title: moment(e.dataCadastro).format("DD/MM/yyyy hh:mm "),
      cardTitle: e.titulo,
      cardDetailedText: e.obs,
    }));

    return DadosTime;
  }
  return (
    <Box
      width="100%"
      height="50vh"
      bg={Temas.Branco}
      borderRadius="md"
      shadow={"lg"}
    >
      <Chrono
        hideControls
        cardHeight={20}
        useReadMore
        theme={{
          primary: "#2C5282",
          secondary: "#A0AEC0",
          titleColorActive: "#FFFF",
        }}
        items={RenderItens()}
        mode="VERTICAL"
        scrollable={{ scrollbar: false }}
      />
    </Box>
  );
}
