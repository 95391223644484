import { AddIcon } from "@chakra-ui/icons";
import React, { useRef, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarArquivoRemessaItensTypes,
  ListarArquivoRemessaTypes,
  ObjListarArquivoRemessaPadrao,
  VisualizarArquivoRemessaTypes,
} from "../../../Types/Financeiro/ArquivoRemessa/ArquivoRemessaTypes";
import {
  ModalBaseType,
  LegendaTypes,
} from "../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpArquivoRemessa } from "./Http";
import { AlterarArquivoRemessa } from "./_AlterarArquivoRemessa";
import { VisualizarArquivoRemessa } from "./_VisualizarArquivoRemessa";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { IncluirArquivoRemessa } from "./_IncluirArquivoRemessa";

export function ListarArquivoRemessa() {
  const AlterarArquivoRemessaRef = useRef<ModalBaseType>();
  const VisualizarArquivoRemessaRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarArquivoRemessaTypes>({
    defaultValues: ObjListarArquivoRemessaPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarArquivosRemessa = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpArquivoRemessa().RetornarArquivosRemessa(
        DadosFiltros
      );
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarArquivoRemessaItensTypes) => {
      return await HttpArquivoRemessa().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpArquivoRemessa()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo Arquivo Remessa",
      Permissao: "financeiro/arquivoremessa/incluir",
      OnClick: () => {
        AlterarArquivoRemessaRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "financeiro/arquivoremessa/visualizar",
      OnClick: (e: VisualizarArquivoRemessaTypes) => {
        VisualizarArquivoRemessaRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Alterar",
      Permissao: "financeiro/arquivoremessa/alterar",
      OnClick: (e: ListarArquivoRemessaItensTypes) => {
        AlterarArquivoRemessaRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "financeiro/arquivoremessa/excluir",
      OnClick: (e: ListarArquivoRemessaItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas: LegendaTypes = [
    { nomeStatus: "Incluído", cor: "green" },
    { nomeStatus: "Enviado", cor: "blue" },
    { nomeStatus: "Excluído", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarArquivoRemessaItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      Status: Legendas,
      att: { maxWidth: 80 },
    },
    {
      Campo: "dataCadastro",
      Titulo: "Data",
      Tipo: "Data",
      att: { maxWidth: 150 },
    },
    {
      Campo: "numero",
      Titulo: "Número",
      Tipo: "Numero",
      att: { maxWidth: 110 },
    },
    {
      Campo: "nomeEmpresa",
      Titulo: "Empresa",
      Tipo: "Texto",
      att: { maxWidth: 130 },
    },
    {
      Campo: "qtdTitulos",
      Titulo: "Qtd. Títulos",
      Tipo: "Numero",
      att: { maxWidth: 130 },
    },
    {
      Campo: "valorRemessa",
      Titulo: "Valor",
      Tipo: "Moeda",
      att: { maxWidth: 150 },
    },
    {
      Campo: "nomeConvenio",
      Titulo: "Convênio",
      Tipo: "Texto",
    },
    {
      Campo: "nomePortador",
      Titulo: "Portador",
      Tipo: "Texto",
      att: { maxWidth: 200 },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarArquivoRemessaItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          valor: "numero",
          titulo: "Número",
        },
        {
          valor: "dataCadastro",
          titulo: "Data",
        },
        {
          valor: "nomeEmpresa",
          titulo: "Empresa",
        },
        {
          valor: "qtdTitulos",
          titulo: "Qtd. Títulos",
        },
        {
          valor: "valorRemessa",
          titulo: "Valor",
        },
        {
          valor: "nomeConvenio",
          titulo: "Convênio",
        },
        {
          valor: "nomePortador",
          titulo: "Portador",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Arquivo Remessa"
          CarregarDados={RetornarArquivosRemessa}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarArquivoRemessa
          CarregarDados={methods.handleSubmit(RetornarArquivosRemessa)}
          ref={AlterarArquivoRemessaRef}
        />
      ),
    },
    {
      Componente: (
        <IncluirArquivoRemessa
          CarregarDados={methods.handleSubmit(RetornarArquivosRemessa)}
          ref={AlterarArquivoRemessaRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarArquivoRemessa
          CarregarDados={methods.handleSubmit(RetornarArquivosRemessa)}
          ref={VisualizarArquivoRemessaRef}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarArquivoRemessaTypes>
            id="Pesquisa.convenioPortadorId"
            label="Convênio Portador"
            Link={"convenioportador"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIDatePicker<ListarArquivoRemessaTypes>
            id="Pesquisa.dataCadastroInicial"
            label="Data Cadastro - Inicial"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIDatePicker<ListarArquivoRemessaTypes>
            id="Pesquisa.dataCadastroFinal"
            label="Data Cadastro - Final"
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="financeiro/arquivoremessa/listar"
      Titulo="Arquivo Remessa"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarArquivosRemessa)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
