import { useState, useEffect, useCallback, useRef } from "react";
import { BellIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Avatar,
  AvatarBadge,
  Badge,
  Box,
  Button,
  Center,
  Flex,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Temas } from "../../Themes/Temas";
import { CGIBox } from "../Box/CGIBox";
import { CGIBoxItem } from "../Box/CGIBoxItens";
import { useLocation } from "react-router-dom";
import { setInterval } from "timers";
import { CGIModalNotificacao } from "./CGIModalNotificacao";
import { HttpCGINotificacao } from "./HttpCGINotificacao";

type CGISinoNotificacaoTypes = {
  nome: number;
  resumo: string;
  qtd: number;
};

/**
 * @Componente CGISinoNotificacao - Componente responsavel  pelos Sino de Resumos
 * @Ref  CGISinoNotificacao
 */
export function CGISinoNotificacao() {
  const [visible, setVisible] = useState<boolean>(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const modalRef = useRef() as any;
  const location = useLocation();

  const [Dados, setDados] = useState<Array<CGISinoNotificacaoTypes>>([]);

  const Qtd = () => {
    return Dados.reduce((acc, item) => {
      return acc + item.qtd;
    }, 0);
  };
  useEffect(() => {
    RetornarDados();
  }, [location.pathname]);

  const RetornarDados = async () => {
    const dados = await HttpCGINotificacao().RetornarNotificacoes<
      Array<CGISinoNotificacaoTypes>
    >();
    setDados(dados);
  };

  return (
    <>
      <Popover placement="top-start">
        <PopoverTrigger>
          <Center h={"100%"} onClick={visible ? hide : show}>
            <RenderAvatar show={show} Qtd={Qtd()} />
          </Center>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader fontWeight="semibold">Notificações</PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody overflowX={"auto"} maxH="75vh">
            <CGIBox>
              {Dados.map((item, index) => {
                return (
                  <CGIBoxItem
                    Att={{
                      _hover: {
                        bg: "gray.100",
                        shadow: "md",
                        cursor: "pointer",
                      },
                      onClick: () => {
                        modalRef.current.Abrir();
                      },
                    }}
                    Col={12}
                    key={index}
                  >
                    <Flex p={1} gap={5}>
                      <Badge
                        textAlign={"center"}
                        h={"1.5rem"}
                        w={"1.5rem"}
                        borderRadius="full"
                        colorScheme={
                          item.qtd >= 10
                            ? "red"
                            : item.qtd >= 5
                            ? "yellow"
                            : "green"
                        }
                      >
                        {item.qtd}
                      </Badge>
                      <Text fontWeight={"bold"}>{item.nome}</Text>
                    </Flex>
                    <Text
                      textAlign={"center"}
                      fontWeight={"light"}
                      fontSize="14"
                    >
                      {item.resumo}
                    </Text>
                  </CGIBoxItem>
                );
              })}
            </CGIBox>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <CGIModalNotificacao ref={modalRef} />
    </>
  );
}

const RenderAvatar = ({ show, Qtd }: any) => {
  return (
    <Avatar
      onClick={show}
      color={Temas.Branco}
      icon={<BellIcon fontSize="1.5rem" />}
      size={"sm"}
    >
      <AvatarBadge borderColor={"red.500"} boxSize="1.25em" bg="red.500">
        {Qtd}
      </AvatarBadge>
    </Avatar>
  );
};
