import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarPortadorGridItensTypes,
  ListarPortadorTypes,
  ObjListarPortadorPadrao,
  PortadorVisualizarTypes,
} from "../../../Types/Sistema/Portador/PortadorTypes";
import {
  ModalBaseType,
  LegendaTypes,
} from "../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpPortador } from "./Http";
import { AlterarPortador } from "./_AlterarPortador";
import { VisualizarPortador } from "./_VisualizarPortador";

export function ListarPortador() {
  const AlterarPortadorRef = useRef<ModalBaseType>();
  const VisualizarPortadorRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarPortadorTypes>({
    defaultValues: ObjListarPortadorPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarPortadores = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpPortador().RetornarPortadores(DadosFiltros);
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarPortadorGridItensTypes) => {
      return await HttpPortador().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpPortador()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo Portador",
      Permissao: "sistema/portador/incluir",
      OnClick: () => {
        AlterarPortadorRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Alterar",
      Permissao: "sistema/portador/alterar",
      OnClick: (e: ListarPortadorGridItensTypes) => {
        AlterarPortadorRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/portador/excluir",
      OnClick: (e: ListarPortadorGridItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Visualizar",
      Permissao: "sistema/classificacao/visualizar",
      OnClick: (e: PortadorVisualizarTypes) => {
        VisualizarPortadorRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas: LegendaTypes = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarPortadorGridItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 90,
      },
    },
    {
      Campo: "nome",
      Titulo: "Nome",
      Tipo: "Texto",
    },
    {
      Campo: "efetuarLancamentos",
      Titulo: "Efetuar Lançamento",
      Tipo: "CheckBoxDisable",
    },
    {
      Campo: "nomeEmpresa",
      Titulo: "Empresa",
      Tipo: "Texto",
    },
    {
      Campo: "bloquearEstornos",
      Titulo: "Bloquear Estornos",
      Tipo: "CheckBoxDisable",
    },
    {
      Campo: "nomeTipo",
      Titulo: "Tipo Portador",
      Tipo: "Texto",
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarPortadorGridItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          titulo: "Id",
          valor: "id",
        },
        {
          titulo: "Nome",
          valor: "nome",
        },
        {
          titulo: "Tipo",
          valor: "nomeTipoPortador",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Portador"
          CarregarDados={RetornarPortadores}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarPortador
          CarregarDados={methods.handleSubmit(RetornarPortadores)}
          ref={AlterarPortadorRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarPortador
          CarregarDados={methods.handleSubmit(RetornarPortadores)}
          ref={VisualizarPortadorRef}
        />
      ),
    },
  ];

  //   async function Pesquisar(DadosFiltros: ListarPortadorTypes) {
  //     setLoadingState(true);
  //     var data = await HttpPortador()
  //       .RetornarPortadores(DadosFiltros)
  //       .finally(() => {
  //         setLoadingState(false);
  //       });
  //     setDados(data);
  //   }

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarPortadorTypes>
            Multipla
            id="Pesquisa.listaEmpresasId"
            label="Empresa"
            Link={"empresa"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarPortadorTypes>
            id={"Pesquisa.nome"}
            label="Nome"
            placeholder="Nome"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarPortadorTypes>
            id="Pesquisa.listaTiposId"
            label="Tipo"
            Link={"tipoportador"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarPortadorTypes>
            Multipla
            id="Pesquisa.listaVendedoresId"
            label="Vendedor"
            Link={"vendedor"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples<ListarPortadorTypes>
            Multipla
            id="Pesquisa.ativoId"
            Link={"simnaoambos"}
            label={"Ativo"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIInput<ListarPortadorTypes>
            id="Pesquisa.agencia"
            label="Agência"
            placeholder="Agência"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIInput<ListarPortadorTypes>
            id="Pesquisa.contaCorrente"
            label="Conta Corrente"
            placeholder="Conta Corrente"
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar id="sistema/portador/listar" Titulo="Portador" Janelas={Janelas}>
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarPortadores)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
