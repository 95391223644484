import { CGIHttp } from "../../../Services/Axios";
import {
  ListarPesquisaAbastecimentoTypes,
  ListarAbastecimentoGridItensTypes,
  AbastecimentoPesquisaGridTypes,
  AbastecimentoVisualizarTypes,
} from "../../../Types/Manutencao/Abastecimento/AbastecimentoTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpAbastecimento() {
  const url = "/abastecimento";

  async function RetornarDadosGridPesquisa(
    dados: AbastecimentoPesquisaGridTypes
  ): Promise<ListarAbastecimentoGridItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-abastecimentos", dados)
      .then((e) => e.data.data);
  }
  async function RetornarAbastecimento(
    DadosFiltros: ListarPesquisaAbastecimentoTypes
  ): Promise<ListarAbastecimentoGridItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(url + "/obter-abastecimentos", DadosFiltros)
    ).data.data;
  }
  async function RetornarDadosVisualizarAbastecimento(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + "/visualizar/" + id)
    ).data.data) as AbastecimentoVisualizarTypes;
  }
  async function RetornarDadosExclusao(
    DadosEmpresa: ListarAbastecimentoGridItensTypes
  ) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + DadosEmpresa.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }
  return {
    RetornarDadosGridPesquisa,
    RetornarAbastecimento,
    RetornarDadosExclusao,
    RetornarDadosVisualizarAbastecimento,
  };
}
