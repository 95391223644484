import {
  Box,
  Flex,
  FormLabel,
  Input,
  InputProps,
  Text,
} from "@chakra-ui/react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

interface CGIFileInputTypes<T extends Record<string, unknown>> {
  id: string; //NestedKey<T>;
  label?: string;
  validadores?: RegisterOptions | undefined;
  ocultar?: boolean;
  att?: InputProps;
  multiple?: boolean;
}

/**
 * @Componente CGIInput -Input padrão do Sistema
 * @Ref  FormContext
 * @Form  Necessita ser Controlado Por Formulário
 */
export function CGIFileInput<T extends Record<string, unknown>>(
  props: CGIFileInputTypes<T>
) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box hidden={props.ocultar}>
      <FormLabel htmlFor={props.id}>
        <Flex>
          <Text
            dangerouslySetInnerHTML={{ __html: props.label ? props.label : "" }}
          />

          {props.validadores?.required ? (
            <span style={{ color: "red" }}> *</span>
          ) : (
            ""
          )}
        </Flex>
      </FormLabel>
      <Controller
        render={({ field }) => {
          return (
            <Input
              type="file"
              pt={1}
              multiple={true}
              {...field}
              name={props.id}
              value={field.value.filename}
              onChange={event => {
                return field.onChange(event.target.files);
              }}
            />
          );
        }}
        defaultValue={[]}
        rules={props.validadores}
        name={props.id}
        control={control}
      />
      <ErrorMessage
        errors={errors}
        name={props.id}
        render={({ message }) => (
          <span style={{ color: "red", fontSize: 12 }}>
            * {message ? message : "Campo obrigatório"}
          </span>
        )}
      />
    </Box>
  );
}
