export const ObjMovPortadorPadrao: FormMovPortadorType = {
  Form: {
    dadosRegistro: {
      id: 0,
      portadorId: 0,
      ehTransferencia: false,
      tipoMovimentacao: "",
      tipo: "",
      subTipo: "",
      nomePortador: "",
      nomeFormaPagamento: "",
      nomeUsuario: "",
      nomeEmpresa: "",
      saldo: 0,
      historicoId: 0,
      caixaBanco: "",
      empresaId: 0,
      bloquearSaldoNegativo: false,
      obsAbertura: "",
      obsFechamento: "",
      dataFechamentoPeriodo: "",
      dtFechamentoPeriodo: "",
      valorMovimento: 0,
      saldoAtual: 0,
      dataCadastro: "",
      formaPagamentoId: 0,
      obs: "",
    },
  },
};
export type MovPortadorTypes = {
  dadosRegistro: {
    id: number;
    portadorId: number;
    ehTransferencia: boolean;
    tipoMovimentacao: string;
    tipo: string;
    subTipo: string;
    nomePortador: string;
    nomeFormaPagamento: string;
    nomeUsuario: string;
    nomeEmpresa: string;
    saldo: number;
    historicoId?: number;
    caixaBanco: string;
    empresaId: number;
    bloquearSaldoNegativo: boolean;
    obsAbertura: string;
    obsFechamento: string;
    dataFechamentoPeriodo?: string;
    dtFechamentoPeriodo: string;
    valorMovimento: number;
    saldoAtual: number;
    dataCadastro: string;
    formaPagamentoId: number;
    obs: string;
  };
};
export type FormMovPortadorType = {
  Form: MovPortadorTypes;
};

export const ObjListarMovPortadorPadrao: ListarMovPortadorTypes = {
  Pesquisa: {
    listaPortadoresId: "",
    dataFinal: "",
    dataInicial: "",
  },
  Itens: [],
};

export type MovPortadorPesquisaGridTypes = {
  id: number;
  dataCadastro: string;
  obs: string;
  documento: string;
  nomeColaborador: string;
  nomePlanoDeContas: string;
  operacao: string;
  nomeUsuario: string;
  valorMovimento: number;
};

export type ListarMovPortadorTypes = {
  Pesquisa: ListarPesquisaMovPortadorTypes;
  Itens: ListarMovPortadorItensTypes[];
};

export type ListarPesquisaMovPortadorTypes = {
  listaPortadoresId: string;
  dataInicial: string;
  dataFinal: string;
};

export type ListarMovPortadorItensTypes = {
  id: number;
  dataCadastro: string;
  obs: string;
  documento: string;
  nomeColaborador: string;
  nomePlanoDeContas: string;
  operacao: string;
  nomeUsuario: string;
  valorMovimento: number;
};

export type AlterarLancamentoTypes = {
  id: number;
  obs: string;
};

export type ListarSaldoGeralTypes = {
  Pesquisa: ListarPesquisaSaldoGeralTypes;
  Itens: ListarSaldoGeralItensTypes[];
};

export type ListarSaldoGeralItensTypes = {
  id: number;
  nomePortador: string;
  nomeEmpresa: string;
  nomeTipoPortador: string;
  saldo: number;
};

export type ListarPesquisaSaldoGeralTypes = {
  id: number;
  listaEmpresasId: string;
  ehCaixaBancoCaixa: boolean;
  ehCaixaBancoBanco: boolean;
  ehCaixaBancoCliente: boolean;
  ehCaixaBancoVendedor: boolean;
  ehCaixaBancoFornecedor: boolean;
};

// export type MovPortadorAlterarTypes = {
//   id: number;
//   portadorId: number;
//   ehTransferencia: boolean;
//   tipoMovimentacao: string;
//   tipo: string;
//   subTipo: string;
//   nomePortador: string;
//   nomeFormaPagamento: string;
//   nomeUsuario: string;
//   nomeEmpresa: string;
//   saldo: number;
//   historicoId?: number;
//   caixaBanco: string;
//   empresaId: number;
//   bloquearSaldoNegativo: boolean;
//   obsAbertura: string;
//   obsFechamento: string;
//   dataFechamentoPeriodo?: any;
//   dtFechamentoPeriodo: string;
//   valorMovimento: number;
//   saldoAtual: number;
//   dataCadastro: string;
//   formaPagamentoId: number;
//   obs: string;
// };
