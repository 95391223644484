import { AxiosInstance } from "axios";
import { CGIHttp } from "../../Services/Axios";
import { CGINotificacaoType } from "./CGINotificacao";
const url = "home";
export function HttpCGINotificacao() {
  async function RetornarDados() {
    let Obj = await new CGIHttp().conn.get(
      url + "//obter-gruponotificacao-home/"
    );
    return Obj?.data.data as Array<CGINotificacaoType>;
  }
  async function VisualizarNotificacao(notificacao: CGINotificacaoType) {
    await new CGIHttp().conn.post(url + "/definir-como-lida/" + notificacao.id);
  }
  return {
    RetornarDados,
    VisualizarNotificacao,
  };
}
