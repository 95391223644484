import { useState } from "react";
import Tippy from "@tippyjs/react";
import { AddIcon } from "@chakra-ui/icons";
import {
  Center,
  Popover,
  PopoverArrow,
  PopoverContent,
  Portal,
  PopoverTrigger,
} from "@chakra-ui/react";
import { MenuTypes } from "./CGIMenuButton";
import { CGIMenuItens } from "./CGIMenuItens";

export const RenderMenuGrid = (props: any, menu: Array<MenuTypes>) => {
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  return (
    <Tippy
      content={
        <CGIMenuItens Hidden={hide} Acoes={menu} Informacoes={props.data} />
      }
      ignoreAttributes
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={true}
      appendTo={document.body}
      interactive={true}
      placement="left"
    >
      <Center mt={2} h={"100%"} onClick={visible ? hide : show}>
        <AddIcon />
      </Center>
    </Tippy>
  );
};

export const MenuGridPopover = (props: any, menu: Array<MenuTypes>) => {
  return (
    <Popover
      closeOnEsc
      closeOnBlur
      lazyBehavior="unmount"
      isLazy
      placement="auto-end"
      strategy="fixed"
    >
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <AddIcon />
          </PopoverTrigger>
          <PopoverContent
            onMouseLeave={e => {
              onClose();
            }}
            position={"relative"}
            left={"65px"}
            top={"-15px"}
            zIndex={-1}
            border={"0px"}
          >
            <CGIMenuItens
              Hidden={() => {}}
              Acoes={menu}
              Informacoes={props.data}
            />
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
