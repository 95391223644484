import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIDataTable } from "../../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../../Context/LoadingContext";
import { ModalBaseType } from "../../../../Types/Modal/ModalBaseType";
import {
  ListarMarcaItensTypes,
  ListarMarcaTypes,
  ObjListarMarcaPadrao,
} from "../../../../Types/Sistema/Classificacao/Marca/MarcaTypes";
import { DeleteTypes } from "../../../../Types/Util/DeleteTypes";

import { HttpMarca } from "./Http";
import { AlterarMarca } from "./_AlterarMarca";
import { ImportarMarca } from "./_ImportarMarca";

export function ListarMarca() {
  //Inicio Hooks
  const AlterarMarcaRef = useRef<ModalBaseType>();
  const ImportarMarcaRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarMarcaTypes>({
    defaultValues: ObjListarMarcaPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  async function Excluir(data: DeleteTypes) {
    await HttpMarca()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }
  //Final Hooks

  //------------------------

  //Inicio Consts
  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Nova Marca",
      Permissao: "sistema/classificacao/incluirmarca",
      OnClick: () => {
        AlterarMarcaRef.current?.Abrir();
      },
    },
    {
      Nome: "Importar Marcas de Produtos",
      Permissao: "sistema/classificacao/importarmarca",
      OnClick: () => {
        ImportarMarcaRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Alterar",
      Permissao: "sistema/classificacao/alterarmarca",
      OnClick: (e: ListarMarcaItensTypes) => {
        AlterarMarcaRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/classificacao/excluirmarca",
      OnClick: (e: ListarMarcaItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarMarcaItensTypes>> = [
    {
      Campo: "ativo",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: [
        {
          nomeStatus: "true",
          cor: "green",
        },
        {
          nomeStatus: "false",
          cor: "red",
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      att: {
        maxWidth: 100,
      },
    },
    {
      Campo: "nome",
      Titulo: "Nome",
      Tipo: "Texto",
    },
    {
      Campo: "margemLucroECommerce",
      Titulo: "% Lucro E-Commerce",
      Tipo: "Texto",
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarMarcaItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Marca"
          CarregarDados={methods.handleSubmit(RetornarMarcas)}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarMarca
          CarregarDados={methods.handleSubmit(RetornarMarcas)}
          ref={AlterarMarcaRef}
        />
      ),
    },
    {
      Componente: (
        <ImportarMarca
          CarregarDados={methods.handleSubmit(RetornarMarcas)}
          ref={ImportarMarcaRef}
        />
      ),
    },
  ];

  //Final Consts

  //------------------------

  //Inicio Function

  async function RetornarMarcas() {
    setLoadingState(true);
    const DadosFiltro = methods.getValues("Pesquisa");
    var data = await HttpMarca()
      .RetornarMarcas(DadosFiltro)
      .finally(() => {
        setLoadingState(false);
      });
    methods.setValue("Itens", data);
  }

  async function RetornarDadosExclusao(data: ListarMarcaItensTypes) {
    return await HttpMarca().RetornarDadosExclusao(data);
  }

  //Final Function

  //------------------------

  // Inicio JSX

  function Filtros() {
    return <CGIBox children={undefined}></CGIBox>;
  }

  return (
    <>
      <CGIListar
        id="sistema/classificacao/listar"
        Titulo="Listar Marcas de Produtos"
        Janelas={Janelas}
      >
        <FormProvider {...methods}>
          <CGIDataTable
            LimparFiltros={() => methods.reset()}
            Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
            Pesquisar={methods.handleSubmit(RetornarMarcas)}
            Filtro={Filtros()}
            Data={GridItens}
            Colunas={GridColunas}
            Legenda={Legendas}
          />
        </FormProvider>
      </CGIListar>
    </>
  );
  // Final JSX
}
