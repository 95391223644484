import { forwardRef, useImperativeHandle, useContext, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import LoadingContext from "../../../Context/LoadingContext";

type CopiarPermissaoType = {
  CarregarDados: Function;
};
export const CopiarCtsPagar = forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    Abrir() {
      CGIModalRef.current.Abrir();
    },
  }));
  const { setLoadingState } = useContext(LoadingContext);
  const { ToastSucesso } = useCGIToast();
  const CGIModalRef = useRef() as any;
  const form = useFormContext();

  async function Confirmar(Dados: any) {
   /* await HttpUsuario()
      .CopiarPermissao(Dados)
      .then(() => {
        ToastSucesso("Permissões foram Copiadas");
      })
      .catch(() => {
        return;
      });*/
  }
  return (
    <CGIModal
      id="financeiro/ctspagar/copiarctspagar"
      ref={CGIModalRef}
      Titulo="Copiar Cts. a Pagar"
      TelaCheia={"6xl"}
      Confirmar={Confirmar}
      Fechar={() => {
        form.reset();
      }}
    >
      <CGIBox>      
      <CGIBoxItem Col={12}>
        <CGIResumo Expandir CtsPagarId="id" ResumoCtsPagar />
      </CGIBoxItem>
      </CGIBox>
    </CGIModal>
  );
});
