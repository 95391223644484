import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import {
  DadosRegistroTypes,
  ListarSugestaoPedidoCompraItensTypes,
} from "../../../Types/Almoxarifado/SugestaoPedidoCompra/SugestaoPedidoCompraTypes";
import { HttpSugestaoPedidoCompra } from "./Http";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";

type VisualizarSugestaoPedidoCompraType = {
  CarregarDados: Function;
};
export const VisualizarSugestaoPedidoCompra = forwardRef(
  (props: VisualizarSugestaoPedidoCompraType, ref) => {
    useImperativeHandle(ref, () => ({
      Abrir(pSugestaoPedidoCompraId: number) {
        CGIModalRef.current?.Abrir();
        CarregarDados(pSugestaoPedidoCompraId);
      },
    }));
    // FINAL REF

    const CGIModalRef = useRef<ModalBaseType>(null);
    const { reset } = useFormContext<DadosRegistroTypes>();

    async function CarregarDados(id: number) {
      var data = await HttpSugestaoPedidoCompra().Visualizar(id);
      reset(data);
    }

    const [
      GridDadosProdutoIndicacaoCompra,
      setGridDadosProdutoIndicacaoCompra,
    ] = useState<Array<ListarSugestaoPedidoCompraItensTypes>>([]);
    const [GridItensSugestaoPedidoCompra, setGridItensSugestaoPedidoCompra] =
      useState<Array<ListarSugestaoPedidoCompraItensTypes>>([]);

    const GridColunasIndicacaoPedidoCompra: Array<
      CGICol<ListarSugestaoPedidoCompraItensTypes>
    > = [
      {
        Campo: "identificacao",
        Titulo: "Identificação",
        Tipo: "Texto",
        Ordernar: true,
        att: {
          minWidth: 200,
        },
      },
      {
        Campo: "qtdItemIndicacao",
        Titulo: "Qtd.",
        Tipo: "Texto",
        att: {
          minWidth: 160,
        },
      },
    ];
    const GridItensColunasSugestaoPedidoCompra: Array<
      CGICol<ListarSugestaoPedidoCompraItensTypes>
    > = [
      {
        Campo: "codigoProduto",
        Titulo: "Código Produto",
        Tipo: "Texto",
        Ordernar: true,
        att: {
          minWidth: 200,
        },
      },
      {
        Campo: "codigoFabricante",
        Titulo: "Cód. Fabricante.",
        Tipo: "Texto",
      },
      {
        Campo: "descricao",
        Titulo: "Descrição",
        Tipo: "Texto",
      },
      {
        Campo: "vlrUnit",
        Titulo: "Vlr. Unit.",
        Tipo: "Texto",
        att: {
          maxWidth: 160,
        },
      },
      {
        Campo: "qtdItens",
        Titulo: "Qtd.",
        Tipo: "Texto",
        att: {
          maxWidth: 160,
        },
      },
      {
        Campo: "vlrTotal",
        Titulo: "Valor Total",
        Tipo: "Texto",
        att: {
          maxWidth: 160,
        },
      },
    ];

    return (
      <CGIModal
        id="almoxarifado/sugestaopedidocompra/visualizar"
        ref={CGIModalRef}
        Titulo="Visualizar Sugestão Pedido de Compra"
        TelaCheia={"6xl"}
        Confirmar={() => {}}
        Fechar={() => {}}
        CarregarDados={CarregarDados}
        ocultarCancelar={true}
        ocultarConfirmar={true}
      >
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIResumo
              Expandir
              SugestaoPedidoCompraId="Form.DadosRegistro.id"
              ResumoSugestaoPedidoCompra
            />
            <CGIBox>
              <CGIBoxItem Col={12}>
                <CGIBox>
                  <CGIBoxItem Col={12}>
                    <CGIDataTableSimples
                      Data={GridItensSugestaoPedidoCompra}
                      Colunas={GridItensColunasSugestaoPedidoCompra}
                      Altura="250px"
                      Att={{
                        defaultColDef: {
                          filter: false,
                        },
                      }}
                    />
                  </CGIBoxItem>
                </CGIBox>
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
        </CGIBox>
        <CGIBox>
          <CGIBoxItem Col={6}>
            <CGIBox titulo="Indicações">
              <CGIBoxItem Col={12}>
                <CGIDataTableSimples
                  Data={GridDadosProdutoIndicacaoCompra}
                  Colunas={GridColunasIndicacaoPedidoCompra}
                  Altura="250px"
                  Att={{
                    defaultColDef: {
                      filter: false,
                    },
                  }}
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBox>
            <CGIBoxItem Col={6}>
              <text>Qtde. Produtos</text>
              <text>Total</text>
            </CGIBoxItem>
          </CGIBox>
        </CGIBox>
      </CGIModal>
    );
  }
);
