import { color } from "highcharts";
import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGINumberInput } from "../../../Components/NumberInput/CGINumberInput";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../Context/LoadingContext";
import {
  AlterarNCMEmLoteTypes,
  FormAlterarNCMEmLoteType,
} from "../../../Types/Sistema/Produto/AlterarNCMEmLoteTypes";
import { HttpProduto } from "./Http";

async function CarregarDados(id: number) {
  // HttpPedido()
  //   .RetornarDadosPedido(id)
  //   .then((e) => {
  //     form.reset(e.data.data);
  //   });
}

type AlterarNCMEmLoteType = {
  CarregarDados: Function;
};

export const AlterarNCMEmLote = forwardRef(
  (props: AlterarNCMEmLoteType, ref) => {
    //INICIO  HOOKS
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current.Abrir();
      },
      Alterar(pProduto: any) {
        CarregarDados(pProduto.Id);
        CGIModalRef.current.Abrir();
      },
    }));

    const CGIModalRef = useRef() as any;
    const form = useFormContext<AlterarNCMEmLoteTypes>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    //FINAL  HOOKS

    //INICIO CONST
    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Dados NCM",
        Component: AreaDadosproduto(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosproduto() {
      return (
        <CGIBox>
          <CGIBoxItem Col={6}>
            <CGIInput<FormAlterarNCMEmLoteType>
              id="Form.DadosRegistro.nCMOrigem"
              label="NCM Origem"
              placeholder="NCM Origem"
              validadores={{
                required: "Campo obrigatório",
                maxLength: 8,
                minLength: 8,
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIInput<FormAlterarNCMEmLoteType>
              id="Form.DadosRegistro.nCMDestino"
              label="NCM Destino"
              placeholder="NCM Destino"
              validadores={{
                required: "Campo obrigatório",
                maxLength: 8,
                minLength: 8,
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <span style={{ color: "red" }}>
              * Esta operação não tem como ser desfeita *
            </span>
          </CGIBoxItem>
        </CGIBox>
      );
    }

    const Confirmar = async (data: FormAlterarNCMEmLoteType, event: any) => {
      setLoadingState(true);

      HttpProduto()
        .AlterarNCMEmLote(data)
        .then((data: any) => {
          ToastSucesso("Registro Alterado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
        })
        .catch((e) => {
          return;
        })
        .finally(() => {
          setLoadingState(false);
        });
    };
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="sistema/produto/alterarncmemlote"
        ref={CGIModalRef}
        Titulo="Alterar NCM em Lote"
        TelaCheia={"3xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
