import { ErrorProvider } from "./ErrorContext";
import { FrameProvider } from "./FrameConxtext";
import { LoadingProvider } from "./LoadingContext";
import { TrocarSenhaProvider } from "./TrocarSenhaContext";
import { DadosUsuarioProvider } from "./UsuarioConxtext";

//11/08/2023 -- Lucas Oliveira
//Frame provider adicionado ao contexto global  para que o sistema possa fazer o controle do layoute
export function GlobalContext(props: { children: React.ReactNode }) {
  return (
    <DadosUsuarioProvider>
      <FrameProvider>
        <LoadingProvider>
          <ErrorProvider>
            <TrocarSenhaProvider>{props.children}</TrocarSenhaProvider>
          </ErrorProvider>
        </LoadingProvider>
      </FrameProvider>
    </DadosUsuarioProvider>
  );
}
