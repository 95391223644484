import React, { createContext, useState } from "react";
//11/08/2023 --  Lucas Oliveira
//
//Contexto Criado para fazer o controle do layoute do sistema
//Controle feito pelo isFrame , se isFrame for true as barras do sistema e o tawlk to iram ficar desabilitados 
type FrameTypes = {
  isFrame: Boolean;
  setFrame: React.Dispatch<React.SetStateAction<Boolean>>;
};


const DefaultValue: FrameTypes = {
  setFrame: () => {},
  isFrame: false,
};

export const FrameContext =
  createContext<FrameTypes>(DefaultValue);

function FrameProvider(props: { children: React.ReactNode }) {
  const [isFrame, setFrame] = useState(DefaultValue.isFrame);
 
  return (
    <FrameContext.Provider value={{ isFrame, setFrame }}>
      {props.children}
    </FrameContext.Provider>
  );
}

export { FrameProvider };
export default FrameContext;
