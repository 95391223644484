import {
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact, AgGridReactProps, AgReactUiProps } from "ag-grid-react"; // the AG Grid React Component

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css"; // Core grid CSS, always needed
import { CGICol, ConvertCol } from "./DataTable/DataTableTypes";
import { Ag_Grid_PT_BR } from "./DataTable/TraducaoGrid";
import React from "react";
import { Temas } from "../../Themes/Temas";

interface DataTableSimplesTypes {
  //Pesquisar: Function;
  //Menu: CGIMenuButtonTypes;
  Altura?: string;
  Colunas: CGICol<any>[];
  Pag?: number;
  Data: any[];
  Att?: AgGridReactProps<any> | AgReactUiProps<any>;
  DisableGetId?: boolean;
}
/**
 * @Componente CGIDataTableSimples - Data Table simples para os componentes
 * @Ref DataTableSimples
 */

export const CGIDataTableSimples = forwardRef(
  (props: DataTableSimplesTypes, ref) => {
    const defaultColDef = useMemo<ColDef>(() => {
      return {
        flex: 1,
        wrapText: true,
        editable: false,
        minWidth: 100,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cellStyle: function (params) {
          if (params.colDef.editable) {
            return { backgroundColor: "#e7fff3" };
          } else {
            return null;
          }
        },
      };
    }, []);

    const getRowStyle = (params: any) => {
      if (params.node.rowIndex % 2 === 0) {
        return { background: "#EFEFEF" };
      }
    };
    const rowStyle = { background: Temas.Branco };

    return (
      <div
        style={{ height: props.Altura ? props.Altura : "100vh", width: "100%" }}
        className="ag-theme-alpine"
      >
        <AgGridReact
          ref={ref as any}
          columnDefs={ConvertCol(props.Colunas)}
          defaultColDef={defaultColDef}
          rowData={props.Data}
          getRowStyle={getRowStyle}
          rowStyle={rowStyle}
          loadingCellRenderer
          localeText={Ag_Grid_PT_BR}
          pagination
          getRowId={
            !props.DisableGetId ? (params: any) => params.data.id : undefined
          }
          paginationPageSize={props.Pag}
          {...props.Att}
        />
      </div>
    );
  }
);
