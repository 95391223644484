export const ObjAlterarProdutosCompradoENaoCadastradosPadrao: FormProdutosCompradosENaoEntreguesType =
  {
    Form: {
      DadosRegistro: {
        id: 0,
      },
    },
  };

export type FormProdutosCompradosENaoEntreguesType = {
  Form: {
    DadosRegistro: ProdutosCompradosENaoEntreguesTypes;
  };
};
export type ProdutosCompradosENaoEntreguesTypes = {
  id: number;
};

/////Types Padrao Listar com 1 Grid e filtros Pesquisa
export const ObjListarProdutosCompradosENaoEntreguesPadrao: ListarProdutosCompradosENaoEntreguesTypes =
  {
    Pesquisa: {
      dataInicialInclusao: "",
      dataFinalInclusao: "",
      dataInicialPrevisao: "",
      dataFinalPrevisao: "",
      identificacaoPedidoCompra: "",
      codigoProduto: "",
      identificacaoPedido: "",
      listaFornecedoresId: "",
      statusId: 0,
      ehSomenteComPrazoDefinidosId: 0,
    },
    Itens: [],
  };

export type ListarProdutosCompradosENaoEntreguesTypes = {
  Pesquisa: ListarPesquisaProdutosCompradosENaoEntreguesTypes;
  Itens: ListarProdutosCompradosENaoEntreguesItensTypes[];
};

export type ListarPesquisaProdutosCompradosENaoEntreguesTypes = {
  dataInicialInclusao: string;
  dataFinalInclusao: string;
  dataInicialPrevisao: string;
  dataFinalPrevisao: string;
  identificacaoPedidoCompra: string;
  codigoProduto: string;
  identificacaoPedido: string;
  listaFornecedoresId: string;
  statusId: number;
  ehSomenteComPrazoDefinidosId: number;
};

export type ListarProdutosCompradosENaoEntreguesItensTypes = {
  id: number;
  identificacao: string;
  nomeFornecedor: string;
  dataPedido: string;
  dataPrevisao: string;
  novaDataPrevisao: string;
  codigoProduto: string;
  qtdDiasAtraso: number;
  nomeStatus: string;
  nomeProduto: string;
};
