import { GridReadyEvent } from "ag-grid-community";
import {
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useFormContext, UseFormReturn, useWatch } from "react-hook-form";
import { CGIBox } from "../../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../../../Components/Button/CGIButton";
import { CGICheckBox } from "../../../../../Components/CheckBox/CGICheckBox";
import { CGIComboSimples } from "../../../../../Components/Combos/CGIComboSimples";
import { CGIDataTableSimples } from "../../../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../../../Components/DataTable/DataTable/DataTableTypes";
import { useCGIToast } from "../../../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../../../Components/Input/CGIInput";
import { CGINumberInput } from "../../../../../Components/NumberInput/CGINumberInput";
import { PedidoLojaType } from "../../../../../Types/Vendas/Pedido/PedidoLojaType";
import { HttpPedido } from "../../Http";
import PedLojaContext from "../_AlterarPedidoLoja";

export const AreaPagamento = forwardRef(
  (props: { refGrid: React.ForwardedRef<unknown> }, ref) => {
    const [exibirParcelas, setExibirParcelas] = useState(false);
    const { GridIPagamentoRef, form } = useContext(PedLojaContext);

    useImperativeHandle(ref, () => ({}));

    const FormaPagamentoId = useWatch({
      control: form.control,
      name: "DadosRegistro.formaPagamentoId",
    });
    const { ToastErro } = useCGIToast();
    const Colunas: Array<CGICol<any>> = [
      {
        Campo: "selecionado",
        Tipo: "CheckBox",
        Titulo: "",
        att: {
          maxWidth: 60,
        },
      },
      {
        Campo: "dataVencimento",
        Tipo: "Data",
        Titulo: "Dt.Vencimento",
        att: {
          editable: true,
        },
      },
      {
        Campo: "nomeFormaPagamento",
        Tipo: "Texto",
        Titulo: "Form.Pag.",
        att: {
          editable: false,
        },
      },
      {
        Campo: "nomeOperacao",
        Tipo: "Texto",
        Titulo: "Operacao",
        att: {
          editable: false,
        },
      },
      {
        Campo: "valor",
        Tipo: "Moeda",
        Titulo: "Valor",
        att: {
          editable: true,
        },
      },
    ];
    const AlterarFormaPag = useMemo(() => {
      if (
        FormaPagamentoId === null ||
        FormaPagamentoId === 0 ||
        FormaPagamentoId === undefined
      ) {
        return;
      }
      ValidarFormaPagamento();
      //Retornar Dados FormaPagamento
      // var Dados = await HttpPedido().RetornarDadosFormaPagamento(
      //   FormaPagamentoId
      // );
    }, [FormaPagamentoId]);

    async function ValidarFormaPagamento() {
      //Retornar Dados FormaPagamento
      var Dados = await HttpPedido().RetornarDadosFormaPagamento(
        FormaPagamentoId
      );

      setExibirParcelas(Dados.gerarParcelamentoVencimento);
    }
    const AddVencimento = useCallback(() => {
      var Dif: any;
      var vencimentos: Array<any> = [];

      var { parcelas, calcParcelas, valorVencimento, totalRestante } =
        form.getValues("DadosPagamento");
      var { totalPedido } = form.getValues("DadosRegistro");
      totalRestante = Number(totalRestante ?? totalPedido);

      var vencimentos: Array<any> = [];
      parcelas = parcelas ? parcelas : 1;

      if (calcParcelas) {
        valorVencimento = Number(Number(totalRestante / parcelas).toFixed(2));
      }

      var vlrParcelas = Number(parcelas) * Number(valorVencimento);

      if (vlrParcelas > totalPedido) {
        form.setValue("DadosPagamento.parcelas", 0);
        form.setValue("DadosPagamento.valorVencimento", 0);
        form.setFocus("DadosPagamento.parcelas");
        return ToastErro("Total de Parcelas maior que total de Pedido");
      }
      totalRestante = totalRestante - valorVencimento;

      Dif = Number(totalRestante - Number(valorVencimento * parcelas)).toFixed(
        2
      );

      if (valorVencimento === 0) {
        return ToastErro("Valor de Vencimento não pode ser 0");
      }

      if (totalRestante < 0) {
        form.setValue("DadosPagamento.parcelas", 0);
        form.setValue("DadosPagamento.valorVencimento", 0);
        form.setFocus("DadosPagamento.parcelas");
        ToastErro("Valor de parcelas maior que o valor do pedido");
        return;
      }

      for (let index = 0; index < parcelas; index++) {
        var vencimento = {
          selecionado: true,
          dataVencimento: new Date().toString(),
          nomeFormaPagamento: "A vista",
          nomeOperacao: "Teste",
          valor: valorVencimento,
        };
        //const element = array[index];

        vencimentos.push(vencimento);
      }

      // vencimentos[parcelas - 1].valor =
      //   Number(vencimentos[parcelas - 1].valor) + Number(Dif ? Dif : 0);

      GridIPagamentoRef.current?.api.applyTransaction({
        add: vencimentos,
      });
      form.setValue("DadosPagamento.totalRestante", totalRestante);
    }, [GridIPagamentoRef, ToastErro, form]);

    return (
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIBox titulo="Vencimentos" popover="Vencimentos">
            <CGIBoxItem Col={12}>
              <>{AlterarFormaPag}</>
              <CGIComboSimples<PedidoLojaType>
                Link={"formapagamento"}
                label={"Forma de Pagamento"}
                id={"DadosRegistro.formaPagamentoId"}
              />
            </CGIBoxItem>
            <CGIBoxItem hidden={!exibirParcelas} Col={2}>
              <CGINumberInput<PedidoLojaType>
                id={"DadosPagamento.parcelas"}
                label={"Parcelas"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGINumberInput<PedidoLojaType>
                id={"DadosPagamento.valorVencimento"}
                label={"Valor"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGICheckBox<PedidoLojaType>
                id={"DadosPagamento.calcParcelas"}
                label={"Calcular Parcelas"}
                tooltip={"Calcular Parcelas"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIButton
                onClick={() => AddVencimento()}
                label="Adicionar Vencimento"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIDataTableSimples
            ref={props.refGrid}
            Altura="30vh"
            Colunas={Colunas}
            Data={[]}
            Pag={10}
            Att={{
              defaultColDef: {
                flex: 1,
                filter: false,
                autoHeight: true,
              },
              //onCellEditingStopped: () => CalcTotalizadores(),
              //onCellClicked: e =>
              // e.column.getColId() === "selecionado" && CalcTotalizadores(),
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIBox titulo="Totais">
            <CGIBoxItem Col={4}>
              <CGIButton
                att={{ mt: 4, colorScheme: "red" }}
                label="Limpar Vencimentos"
                onClick={() => {
                  let rowData: Array<any> = [];
                  GridIPagamentoRef.current?.api.forEachNode(node =>
                    rowData.push(node.data)
                  );
                  GridIPagamentoRef.current?.api.applyTransaction({
                    remove: rowData,
                  });
                  form.setValue("DadosPagamento.totalRestante", 0);
                }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGINumberInput<PedidoLojaType>
                id={"DadosPagamento.totalLancado"}
                prefix
                label={"Total Lançado"}
                att={{
                  isDisabled: true,
                }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGINumberInput<PedidoLojaType>
                id={"DadosPagamento.totalRestante"}
                prefix
                label={"Total Restante"}
                att={{
                  isDisabled: true,
                }}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    );
  }
);
