import { CGIHttp } from "../../Services/Axios";
import { DadosUsuario } from "../../Types/Home/Login/LoginType";

const url = "/home";

export function HttpCGIAlertDiolog() {
  async function TrocarEmpresa(empresaId: number) {
    let Obj = await new CGIHttp().conn.post(
      url + "/mudar-empresa/" + empresaId
    );

    return Obj.data.data as DadosUsuario;
  }

  return {
    TrocarEmpresa,
  };
}
