export const ObjPadrao: FormEmpresaType = {
  Form: {
    DadosRegistro: {
      ativoId: 0,
      id: 0,
      razaoSocial: "",
      cnpj: "",
      nomeExibicao: "",
      ativo: false,
      nomeFantasia: "",
      endereco: "",
      numeroEndereco: "",
      complemento: "",
      bairro: "",
      cidade: "",
      cidadeIBGE: "",
      cep: "",
      fone: "",
      ie: "",
      crt: "",
      estado: "",
      estadoIBGE: "",
      serieNF: 0,
      serieMDF: 0,
      percPIS: 0,
      percCOFINS: 0,
      percCredSN: 0,
      serieNFC: 0,
      logo: "",
      serieNFS: 0,
      serieCT: 0,
      inscricaoMunicipal: "",
      chaveAPI: "",
      regimeTributacao: 0,
      dataVencimentoCertificado: "",
      telefoneFixo: "",
      telefoneCelular: "",
      utilizaPDVWeb: false,
      codigoCnae: "",
      rntrc: "",
      menuSistemaNovo: "",
      sigla: "",
      empresaEstoqueGeral: false,
      permitirImportacaoNFSaida: false,
      copiarPrecosDaEmpresa: false,
      email: "",
      maximoNSU: "",
      ultimoNSU: "",
      senhaCertificado: "",
      certificado: "",
      codigoCSC: "",
      idCSC: 0,
      ativarTodosProdutos: false,
    },
    Itens: [],
    Usuarios: [],
  },
};

export const ObjListarEmpresaPadrao: ListarEmpresaTypes = {
  Pesquisa: {
    ativoId: 0,
    nomeExibicao: "",
    razaoSocial: "",
    codigoRelatorio: 0,
    pesquisa: 0,
  },
  Itens: [],
};

export type ListarEmpresaTypes = {
  Pesquisa: ListarPesquisaEmpresaTypes;
  Itens: ListarEmpresaItensTypes[];
};

export type FormEmpresaType = {
  Form: {
    DadosRegistro: EmpresaTypes;
    Itens: EmpresaItensTypes[];
    Usuarios: UsuariosEmpresaTypes[];
  };
};

export type EmpresaTypes = {
  id: number;
  razaoSocial: string;
  cnpj: string;
  nomeExibicao: string;
  ativo: boolean;
  nomeFantasia: string;
  endereco: string;
  numeroEndereco: string;
  complemento: string;
  bairro: string;
  cidade: string;
  cidadeIBGE: string;
  cep: string;
  fone: string;
  ie: string;
  crt: string;
  estado: string;
  estadoIBGE: string;
  serieNF: number;
  serieMDF: number;
  percPIS: number;
  percCOFINS: number;
  percCredSN: number;
  serieNFC: number;
  logo: string;
  serieNFS: number;
  serieCT: number;
  inscricaoMunicipal: string;
  chaveAPI: string;
  regimeTributacao: number;
  dataVencimentoCertificado: string;
  telefoneFixo: string;
  telefoneCelular: string;
  utilizaPDVWeb: boolean;
  codigoCnae: string;
  rntrc: string;
  menuSistemaNovo: string;
  sigla: string;
  empresaEstoqueGeral: boolean;
  permitirImportacaoNFSaida: boolean;
  copiarPrecosDaEmpresa: boolean;
  email: string;
  maximoNSU: string;
  ultimoNSU: string;
  senhaCertificado: string;
  certificado: string;
  codigoCSC: string;
  idCSC: number;
  ativarTodosProdutos: boolean;
  ativoId: number;
};

export type EmpresaItensTypes = {
  id: number;
  nomeExibicao: string;
  razaoSocial: string;
  cnpj: string;
  ativo: boolean;
  dataVencimentoCertificado: string;
  serieNF: number;
  serieNFC: number;
  serieNFS: number;
  serieMDF: number;
  crt: string;
  serieCT: number;
  nomeStatus: string;
};

export type EmpresaPesquisaGridTypes = {
  nomeExibicao?: string;
};

export type ListarRelacaoEntregaTypes = {
  Pesquisa: ListarPesquisaEmpresaTypes;
  Itens: ListarEmpresaItensTypes[];
};

export type ListarPesquisaEmpresaTypes = {
  ativoId: number;
  nomeExibicao: string;
  razaoSocial: string;
  codigoRelatorio: number;
  pesquisa: number;
};

export type ListarEmpresaItensTypes = {
  id: number;
  nomeExibicao: string;
  razaoSocial: string;
  cnpj: string;
  ativo: boolean;
  dataVencimentoCertificado: string;
  serieNF: number;
  serieNFC: number;
  serieNFS: number;
  serieMDF: number;
  crt: string;
  serieCT: number;
  nomeStatus: string;
};

export type UsuariosEmpresaTypes = {
  id: number;
  selecionado: boolean;
  nome: string;
  grupo: string;
  aux1: string;
};

// export type ListarEmpresaTypes = {
//   nomeExibicao: string;
//   razaoSocial: string;
//   ativoId: number;
//   codigoRelatorio: number;
//   pesquisa: number;
// };

export type EmpresaVisualizarTypes = {
  id: number;
  nomeExibicao: string;
  razaoSocial: string;
  ativoId: number;
  codigoRelatorio: number;
  nomeFantasia: string;
  cnpj: string;
  ie: string;
  crt: string;
  endereco: string;
  numeroEndereco: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  cep: string;
  email: string;
  serieNF: number;
  serieNFC: number;
  serieNFS: number;
  serieMDF: number;
  serieCT: number;
  rntrc: string;
  cidadeIBGE: string;
  estadoIBGE: string;
};
