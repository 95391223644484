import { CGIHttp } from "../../../../Services/Axios";
import {
  FormMarcaType,
  ListarMarcaItensTypes,
  ListarPesquisaMarcaTypes,
} from "../../../../Types/Sistema/Classificacao/Marca/MarcaTypes";

const url = "/classificacao";

export function HttpMarca() {
  async function RetornarMarcas(DadosFiltros: ListarPesquisaMarcaTypes) {
    return (await new CGIHttp().conn.post(url + "/obter-marca", DadosFiltros))
      .data.data;
  }

  async function Listar() {
    return await (
      await new CGIHttp().conn.get(url + "/listar/141")
    ).data.data;
  }

  async function RetornarDadosMarca(id: number) {
    return await new CGIHttp().conn.get(url + `/alterar-marca/${id}`);
  }

  async function Incluir(Dados: FormMarcaType) {
    return await new CGIHttp().conn.post(url + "/incluir-marca", Dados.Form);
  }
  async function Alterar(Dados: FormMarcaType) {
    return await new CGIHttp().conn.post(url + "/alterar-marca", Dados.Form);
  }
  async function RetornarDadosExclusao(Dados: ListarMarcaItensTypes) {
    return await new CGIHttp().conn.get(url + "/excluir-marca/" + Dados.id);
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir-marca", Dados);
  }
  async function Importar(Dados: FormMarcaType) {
    return await new CGIHttp().conn.post(url + "/incluir-marca", Dados.Form);
  }
  return {
    RetornarMarcas,
    RetornarDadosMarca,
    RetornarDadosExclusao,
    Incluir,
    Listar,
    Alterar,
    Excluir,
    Importar,
  };
}
