import { RetornarDadosUsuario } from "../../Services/Util/Util";

export function useAuth() {
  try {
    let user = RetornarDadosUsuario();
    if (user.token) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}
