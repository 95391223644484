export const ObjPadrao: FormRelacaoEntregaType = {
  Form: {
    DadosRegistro: {
      empresaId: 0,
      motoristaId: 0,
      transportadoraId: 0,
      equipamentoId: 0,
      separadorPorId: 0,
      conferidoPorId: 0,
      dataSaida: "",
      horaSaida: "",
    },
    Itens: [],
  },
  GridPesquisa: {
    Pesquisa: {
      nomeCliente: "",
      dataInicial: "",
      dataFinal: "",
    },
    Itens: [],
  },
};

export const ObjListarRelacaoEntregaPadrao: ListarRelacaoEntregaTypes = {
  Pesquisa: {
    identificacaoPedido: "",
    clienteId: 0,
    listaStatusId: 0,
    dataSaidaInicial: "",
    dataSaidaFinal: "",
    dataEntregaInicial: "",
    dataEntregaFinal: "",
    exibirExcluidos: false,
    entregueComAtraso: false,
  },
  Itens: [],
};
export type FormRelacaoEntregaType = {
  Form: {
    DadosRegistro: RelacaoEntregaTypes;
    Itens: RelacaoEntregaItensTypes[];
  };
  GridPesquisa: {
    Pesquisa: RelacaoEntregaPesquisaGridTypes;
    Itens: RelacaoEntregaItensTypes[];
  };
};
export type RelacaoEntregaTypes = {
  empresaId: number;
  motoristaId: number;
  transportadoraId: number;
  equipamentoId: number;
  separadorPorId: number;
  conferidoPorId: number;
  dataSaida: string;
  horaSaida: string;
};

export type RelacaoEntregaItensTypes = {
  selecionado: boolean;
  id: number;
  sequencia: number;
  dataSaida: string;
  dataPedido: string;
  dataBaixa: string;
  dataFaturamento: string;
  horaSaida: string;
  qtdVolumes: number;
  nomeStatus: string;
  identificacaoPedido: string;
  numeroNF: number;
  valor: number;
  pedidoId: number;
  nfSaidaId: number;
  qtdItens: number;
  nomeCliente: string;
  cpfcnpj: string;
  relacoesEntregaQueContemORegistro: string;
  obsPedido: string;
};

export type RelacaoEntregaPesquisaGridTypes = {
  nomeCliente?: string;
  dataInicial?: string;
  dataFinal?: string;
};

export type ListarRelacaoEntregaTypes = {
  Pesquisa: ListarPesquisaRelacaoEntregaTypes;
  Itens: ListarRelacaoEntregaItensTypes[];
};

export type ListarPesquisaRelacaoEntregaTypes = {
  identificacaoPedido: string;
  clienteId: number;
  listaStatusId: number;
  dataSaidaInicial: string;
  dataSaidaFinal: string;
  dataEntregaInicial: string;
  dataEntregaFinal: string;
  exibirExcluidos: boolean;
  entregueComAtraso: boolean;
};

export type ListarRelacaoEntregaItensTypes = {
  id: number;
  pedidoId: number;
  nomeStatus: string;
  identificacaoPedido: string;
  dataSaida: string;
  horaSaida: string;
  qtdNotasFiscais: number;
  qtdVolumes: number;
  valorTotal: number;
};
