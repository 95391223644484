import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import {
  FormUsuarioTypes,
  ListarUsuarioItensTypes,
  ListarUsuarioTypes,
  ObjListarUsuarioPadrao,
  UsuarioVisualizarTypes,
} from "../../../Types/Sistema/Usuario/UsuarioTypes";
import { HttpUsuario } from "./Http";

type VisualizarUsuarioType = {
  CarregarDados: Function;
};
export const VisualizarUsuario = forwardRef(
  (props: VisualizarUsuarioType, ref) => {
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current?.Abrir();
      },
    }));

    const CGIModalRef = useRef<ModalBaseType>(null);
    const form = useFormContext();

    async function CarregarDados(params: FormUsuarioTypes) {
      var data = await HttpUsuario().Visualizar(params.Form.DadosRegistro);
      form.reset(data.data.data);
    }

    return (
      <CGIModal
        id="sistema/usuario/visualizar"
        ref={CGIModalRef}
        Titulo="Visualizar Usuario"
        TelaCheia={"6xl"}
        Confirmar={() => {}}
        Fechar={() => {}}
        ocultarCancelar={true}
        ocultarConfirmar={true}
      >
        <CGIBox>
          <CGIBoxItem Col={1}>
            <CGIInput<UsuarioVisualizarTypes>
              id="id"
              label="Id"
              placeholder="Id"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<UsuarioVisualizarTypes>
              id="login"
              label="Login"
              placeholder="Login"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={7}>
            <CGIInput<UsuarioVisualizarTypes>
              id="nome"
              label="Nome"
              placeholder="Nome"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<UsuarioVisualizarTypes>
              id="email"
              label="E-Mail"
              placeholder="E-Mail"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<UsuarioVisualizarTypes>
              id="nomeEmpresa"
              label="Empresa"
              placeholder="Empresa"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>

          <CGIBoxItem Col={4}>
            <CGIInput<UsuarioVisualizarTypes>
              id="nomeVendedor"
              label="Vendedor"
              placeholder="Vendedor"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<UsuarioVisualizarTypes>
              id="nomeMotorista"
              label="Motorista"
              placeholder="Motorista"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<UsuarioVisualizarTypes>
              id="nomeLinkInicial"
              label="Link Inicial"
              placeholder="Link Inicial"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <></>
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <></>
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<UsuarioVisualizarTypes>
              tooltip="Ativo"
              id="ativo"
              label="Ativo"
              att={{ disabled: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<UsuarioVisualizarTypes>
              tooltip="Mobile"
              id="ehMobile"
              label="Mobile"
              att={{ disabled: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<UsuarioVisualizarTypes>
              tooltip="Sempre Exibir Chat"
              id="sempreExibirChat"
              label="Sempre Exibir Chat"
              att={{ disabled: true }}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
  }
);
