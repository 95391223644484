import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../../Components/CheckBox/CGICheckBox";
import { useCGIToast } from "../../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIModal } from "../../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../../Context/LoadingContext";
import { ModalBaseType } from "../../../../Types/Modal/ModalBaseType";
import { FormTipoNFEntradaType } from "../../../../Types/Sistema/Classificacao/TipoNFEntrada/TipoNFEntradaTypes";
import { HttpTipoNFEntrada } from "./Http";

type AlterarTipoNFEntradaType = {
  CarregarDados: Function;
};

export const AlterarTipoNFEntrada = forwardRef(
  (props: AlterarTipoNFEntradaType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current?.Abrir();
      },
      Alterar(pEmpresa: any) {
        CGIModalRef.current?.Abrir();

        HttpTipoNFEntrada()
          .RetornarDadosTipoNFEntrada(pEmpresa.id)
          .then((e) => {
            form.reset(e.data.data);
          });
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef<ModalBaseType>(null);
    const form = useFormContext<FormTipoNFEntradaType>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    //FINAL  HOOKS

    //INICIO CONST

    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Dados do Tipo de NF",
        Component: AreaDadosTipoNFEntrada(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosTipoNFEntrada() {
      return (
        <CGIBox>
          <CGIBoxItem Col={6}>
            <CGIInput
              id="nome"
              label="Nome"
              placeholder="Nome do Tipo de NF Entrada"
              validadores={{ required: "Nome obrigatório" }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput
              id="numeroDeDiasParaNFRetorno"
              label="Nº de Dias para NF Retorno"
              placeholder="Nº de Dias para NF Retorno"
              att={{ type: "number" }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGICheckBox
              id="permitirCadastrarProdutos"
              label="Permitir Cadastrar Produtos"
              tooltip="Informa se permite cadastrar produtos (NF Entrada - Confirmar Produtos)"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGICheckBox
              id="buscarItemNocadastroDeProdutos"
              label="Buscar Item no Cadastrar Produtos"
              tooltip="Informa se o sistema buscará o item no cadastro de produtos (NF Entrada - Confirmar Produtos)"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGICheckBox
              id="atualizarCustoNoLancamentoNFEntrada"
              label="Atualizar Custo em NF Entrada"
              tooltip="Informa se o custo será atualizado quando uma NF for lançada"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGICheckBox
              att={{
                checked: true,
              }}
              id="ativo"
              label="Ativo"
              tooltip="Informa se o registro está ativo"
            />
          </CGIBoxItem>
        </CGIBox>
      );
    }

    const Confirmar = async () => {
      const Dados = form.getValues();
      setLoadingState(true);

      if (
        Dados.Form.DadosRegistro.id == null ||
        Dados.Form.DadosRegistro.id === undefined ||
        Dados.Form.DadosRegistro.id === 0
      ) {
        HttpTipoNFEntrada()
          .Incluir(Dados)
          .then((response: any) => {
            ToastSucesso("Registro gravado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current?.Fechar();
          })
          .catch((e) => {
            return ToastErro(e);
          })
          .finally(() => {
            setLoadingState(false);
          });
      } else {
        HttpTipoNFEntrada()
          .Alterar(Dados)
          .then((data: any) => {
            ToastSucesso("Registro Alterado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current?.Fechar();
          })
          .catch((e) => {
            return;
          })
          .finally(() => {
            setLoadingState(false);
          });
      }
    };
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="sistema/classificacao/alterar"
        ref={CGIModalRef}
        Titulo="Tipo de NF Entrada"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
