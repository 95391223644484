/* eslint-disable array-callback-return */
import { Box, Center } from "@chakra-ui/react";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import {
  FormatarData,
  FormatarDataHora,
  FormatarHora,
  FormatarMoeda,
} from "../../Prototypes/Formatter";
import { isMobile } from "react-device-detect";
import React from "react";
import { TextMatcherParams } from "ag-grid-community/dist/lib/filter/provided/text/textFilter";

export interface CGICol<T extends Record<string, unknown>> {
  Campo: NestedKey<T>;
  Titulo: string;
  Tipo:
    | "Moeda"
    | "Numero"
    | "Data"
    | "DataHora"
    | "Hora"
    | "Texto"
    | "CheckBox"
    | "CheckBoxDisable"
    | "HTML"
    | "Composta"
    | "Menu"
    | "Mobile"
    | "Status"
    | "Percentual";
  Ordernar?: boolean;
  Legenda?: any;
  ExibirCoresConformeValor?: boolean;
  att?: ColDef;
  ocultar?: boolean;
  Composta?: Array<{
    titulo: string;
    valor?: NestedKey<T>;
    ocultar?: boolean;
    cor?: string;
    negrito?: boolean;
    exibirLabel?: boolean;
    FuncCondicional?: (e: any) => string;
    CorCondicional?: (e: any) => string;
  }>;
  Status?: Array<{
    nomeStatus: string;
    cor: "green" | "red" | "orange" | "yellow" | "gray" | "blue" | string;
  }>;
}

function RenderHtml(params: ValueFormatterParams) {
  return <div dangerouslySetInnerHTML={{ __html: params.value }} />;
}
function RenderCheckBoxs(params: ValueFormatterParams) {
  function checkedHandler(event: any) {
    let checked = event.target.checked;
    let colId = params.column.getColId();
    params.node?.setDataValue(colId, checked);
  }
  return (
    <input
      key={params.data.id}
      type="checkbox"
      defaultValue={params.value}
      onClick={checkedHandler}
      checked={params.value}
    />
  );
}
function RenderCheckBoxsDisable(params: ValueFormatterParams) {
  return (
    <input
      key={params.data.id}
      type="checkbox"
      defaultValue={params.value}
      checked={params.value}
    />
  );
}
const FormaterPercentual = (params: ValueFormatterParams) => {
  return String(params.value).replace(".", ".") + "%";
};

export function ConvertCol<T extends Record<string, unknown>>(
  dados: Array<CGICol<T>>
) {
  var ObjColDef: Array<ColDef> = [];
  dados.map(e => {
    if (isMobile) {
      if (e.Tipo !== "Menu" && e.Tipo !== "Mobile") {
        return;
      }
    } else if (e.Tipo === "Mobile") {
      return;
    }

    var FuncFormater: any = undefined;
    var CellClass: any = undefined;
    var RenderItem: any = undefined;
    var FilterClass: any = undefined;

    function RenderComposta(params: ValueFormatterParams) {
      var Dados = e.Composta;
      var str = "";
      if (Dados) {
        Dados.map(e => {
          let cor = e.cor ? e.cor : "black";
          let negrito = e.negrito ? "bold" : "normal";
          let exibirLabel = e.exibirLabel ? e.exibirLabel : false;
          let valor = params.data[e.valor];
          if (e.CorCondicional) {
            cor = e.CorCondicional(valor);
          }
          if (e.FuncCondicional) {
            valor = e.FuncCondicional(valor);
          }

          if (!e.ocultar && valor != null && valor !== "null" && valor !== "") {
            if (exibirLabel) {
              str += `<div><span style="font-weight:bold;font-size:12">${e.titulo}: </span><span style="color:${cor}"> ${valor}</span></div>`;
            } else {
              str += `<span style="font-weight:${negrito};font-size:12;color:${cor}">${valor}</span><br/>`;
            }
          }
        });
      }
      return <div dangerouslySetInnerHTML={{ __html: str }} />;
    }
    function RenderStatus(params: ValueFormatterParams) {
      // eslint-disable-next-line eqeqeq
      var obj = e.Status?.find(e => e.nomeStatus == String(params.value));

      if (obj) {
        return (
          <Center>
            <Box w="5" h="5" borderRadius="full" bgColor={obj.cor}></Box>
          </Center>
        );
      }
      return (
        <Center>
          <Box w="5" h="5" borderRadius="full" bgColor={"black"}></Box>
        </Center>
      );
    }

    switch (e.Tipo) {
      case "Moeda":
        FuncFormater = FormatarMoeda;
        CellClass = "ag-right-aligned-cell";
        break;
      case "CheckBox":
        RenderItem = RenderCheckBoxs;
        break;
      case "CheckBoxDisable":
        RenderItem = RenderCheckBoxsDisable;
        break;
      case "Numero":
        FilterClass = "agNumberColumnFilter";
        CellClass = "ag-right-aligned-cell";
        break;
      case "Data":
        FuncFormater = FormatarData;
        FilterClass = "agDateColumnFilter";
        CellClass = "ag-center-aligned-cell";
        break;
      case "DataHora":
        FuncFormater = FormatarDataHora;
        FilterClass = "agDateColumnFilter";
        break;
      case "Hora":
        FuncFormater = FormatarHora;
        FilterClass = "agDateColumnFilter";
        break;
      case "HTML":
        RenderItem = RenderHtml;
        break;
      case "Composta":
        RenderItem = RenderComposta;
        break;
      case "Mobile":
        RenderItem = RenderComposta;
        break;
      case "Status":
        RenderItem = RenderStatus;
        break;
      case "Percentual":
        FuncFormater = FormaterPercentual;
        CellClass = "ag-right-aligned-cell";
        break;
      case "Menu":
        break;
      default:
        FilterClass = "agTextColumnFilter";
        break;
    }

    var Item: ColDef = {
      filter: FilterClass,
      headerName: e.Titulo,
      field: e.Campo,
      autoHeight: true,
      valueFormatter: FuncFormater,
      filterParams: {
        textMatcher: (params: TextMatcherParams) => {
          if (e.Tipo === "Composta") {
            var ArrStrComposta = e.Composta?.map(e => e.valor) as Array<string>;
            var ArrStr = ArrStrComposta.map(e => String(params.data[e]));

            var str = ArrStr.join(" ").toLowerCase();

            switch (params.filterOption) {
              case "contains":
                return (
                  str.indexOf(
                    params.filterText ? params.filterText.toLowerCase() : ""
                  ) >= 0
                );
              case "notContains":
                return (
                  str.indexOf(
                    params.filterText ? params.filterText.toLowerCase() : ""
                  ) === -1
                );
              case "equals":
                return str === params.filterText
                  ? params.filterText.toLowerCase()
                  : "";
              case "notEqual":
                return str !== params.filterText;
              case "startsWith":
                return (
                  str.indexOf(
                    params.filterText ? params.filterText.toLowerCase() : ""
                  ) === 0
                );
              case "endsWith":
                var index = str.lastIndexOf(
                  params.filterText ? params.filterText.toLowerCase() : ""
                );
                return (
                  index >= 0 &&
                  index ===
                    str.length -
                      (params.filterText ? params.filterText.toLowerCase() : "")
                        .length
                );
              default:
                // should never happen
                console.warn("invalid filter type " + params.filterOption);
                return false;
            }
          }
        },
      },
      sortable: e.Ordernar,
      cellClass: CellClass,
      cellRenderer: RenderItem,
      cellStyle: function (params) {
        var color = "";
        var backgroundColor = "";
        if (e.Tipo === "Numero") {
          if (e.ExibirCoresConformeValor) {
            if (params.value === 0) {
              color = "blue";
            }
            if (params.value > 0) {
              color = "green";
            } else {
              color = "red";
            }
          }
        }
        if (params.colDef.editable) {
          backgroundColor = "#e7fff3";
        }

        return {
          color,
          backgroundColor,
        };
      },
      hide: e.ocultar,
      ...e.att,
    };

    ObjColDef.push(Item);
  });
  return ObjColDef;
}
