import { CGIHttp } from "../../../Services/Axios";
import {
  ListarPesquisaProdutosCompradosENaoEntreguesTypes,
  ListarProdutosCompradosENaoEntreguesTypes,
  ProdutosCompradosENaoEntreguesTypes,
} from "../../../Types/Almoxarifado/ProdutosCompradosENaoEntregues/ProdutosCompradosENaoEntreguesTypes";
import { ListarProdutosCompradosENaoEntreguesItensTypes } from "../../../Types/Almoxarifado/ProdutosCompradosENaoEntregues/ProdutosCompradosENaoEntreguesTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpProdutosCompradosENaoEntregues() {
  const url = "/pedidocompra";

  async function RetornarProdutosCompradosENaoEntregues(
    DadosFiltros: ListarPesquisaProdutosCompradosENaoEntreguesTypes
  ) {
    return (await (
      await new CGIHttp().conn.post(
        url + "/obter-produtos-comprados-nao-entregues",
        DadosFiltros
      )
    ).data.data) as ListarProdutosCompradosENaoEntreguesItensTypes[];
  }

  async function RetornarDadosProdutosCompradosENaoEntreguesTypes(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + `/alterar/${id}`)
    ).data.data) as ProdutosCompradosENaoEntreguesTypes;
  }

  async function Incluir(
    DadosProdutosCompradosENaoEntregues: ProdutosCompradosENaoEntreguesTypes
  ) {
    return await new CGIHttp().conn.post(
      url + "/incluir",
      DadosProdutosCompradosENaoEntregues
    );
  }

  async function Alterar(
    DadosProdutosCompradosENaoEntregues: ProdutosCompradosENaoEntreguesTypes
  ) {
    return await new CGIHttp().conn.post(
      url + "/alterar",
      DadosProdutosCompradosENaoEntregues
    );
  }

  async function Visualizar(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + "/visualizar/" + id)
    ).data.data) as ProdutosCompradosENaoEntreguesTypes;
  }

  async function RetornarDadosExclusao(
    DadosProdutosCompradosENaoEntregues: ProdutosCompradosENaoEntreguesTypes
  ) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + DadosProdutosCompradosENaoEntregues.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }

  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir", Dados);
  }

  async function IncluirGET() {
    return (await (
      await new CGIHttp().conn.get(url + "/incluir")
    ).data.data) as ProdutosCompradosENaoEntreguesTypes;
  }

  return {
    RetornarProdutosCompradosENaoEntregues,
    RetornarDadosProdutosCompradosENaoEntreguesTypes,
    RetornarDadosExclusao,
    Incluir,
    Alterar,
    Visualizar,
    Excluir,
    IncluirGET,
  };
}
