export type FormCtsPagarTypes = {
  Form: {
    DadosRegistro: DadosRegistroTypes;
  };
};

export type DadosRegistroTypes = {
  id: number;
  empresaId: number;
  numero: number;
  parcela: number;
  totalParcelas: number;
  dataCadastro: string | null;
  dataVencimento: string | null;
  dataPrevisao: string | null;
  dataPagamento: string | null;
  dataBaixa: string | null;
  dataExclusao: string | null;
  dataProgramacao: string | null;
  dataEmissao: string | null;
  arquivoRemessaPagamentoTitulosId: number | null;
  mesReferencia: string;
  desconto: number;
  valor: number;
  valorPago: number;
  saldoPagar: number;
  juros: number;
  mora: number;
  codigoBarras: string;
  cobrancaNaEmpresa: boolean;
  fornecedorId: number;
  usuarioId: number;
  tipoDocumentoId: number;
  formaPagamentoId: number | null;
  portadorId: number | null;
  documento: string;
  obs: string;
  statusId: number;
  tituloPrevisao: boolean;
  sISPAGPagamentoAtravesDeId: number | null;
  numeroOrdemCompra: string;
  pagamentoBloqueado: boolean;
  docContabil: boolean;
  equipamentoId: number | null;
  statusCobrancaFinanceiroId: number | null;
  alterarDataCadastro: boolean;
  sugerirNroDoc: boolean;
  baixarTitulo: boolean;
  portadorBaixaId: number | null;
  planoDeContasId: number | null;
  centroDeCustoId: number | null;
  fazerRateio: boolean;
};

export type CtsPagarVisualizarTypes = {
  id: number;
  dataCadastro: string | null;
  dataVencimento: string | null;
  dataPrevisao: string | null;
  dataPagamento: string | null;
  dataBaixa: string | null;
  dataExclusao: string | null;
  dataProgramacao: string | null;
  dataEmissao: string | null;
  nomeEmpresa: string;
  nomeFornecedor: string;
  nomeTipoDocumento: string;
  nomeFormaPagamento: string;
  nomePortador: string;
  valor: number;
  valorPago: number;
  nomeStatus: string;
  obs: string;
  nomeUsuario: string;
  codigoBarras: string;
  documento: string;
  titulo: string;
  cobrancaNaEmpresa: boolean;
  saldoPagar: number;
  juros: number;
  tituloPrevisao: boolean;
  nomeCentroDeCusto: string;
  nomePlanoDeContas: string;
};

export const ObjListarCtsPagarPadrao: ListarCtsPagarTypes = {
  Pesquisa: {
    dataInclusaoInicial: "",
    dataInclusaoFinal: "",
    dataEmissaoInicial: "",
    dataEmissaoFinal: "",
    dataVencimentoInicial: "",
    dataVencimentoFinal: "",
    dataPrevisaoInicial: "",
    dataPrevisaoFinal: "",
    dataPagamentoInicial: "",
    dataPagamentoFinal: "",
    dataBaixaInicial: "",
    dataBaixaFinal: "",
    dataProgramacaoInicial: "",
    dataProgramacaoFinal: "",
    listaEmpresasId: "",
    listaStatusId: "",
    listaFornecedoresId: "",
    documento: "",
    listaPlanosDeContasId: "",
    cobrancaNaEmpresaId: 0,
    codigoBarras: "",
    mesReferenciaId: 0,
    listaCentrosCustoId: "",
    listaPortadoresId: "",
    numeroOrdemCompra: "",
    cNPJFornecedor: "",
    documentoContabilId: 0,
    pagamentoBloqueadoId: 0,
    listaEquipamentosId: "",
    pagamentoAprovadoId: 0,
    listaSISPAGId: "",
    adiantamentoFornecedorId: 0,
    listaTiposDocumentoId: "",
    somenteTitulosPrevisao: false,
    ocultarExcluidos: false,
  },
  Itens: [],
};

export type ListarCtsPagarTypes = {
  Pesquisa: ListarPesquisaCtsPagarTypes;
  Itens: ListarCtsPagarItensTypes[];
};

export type ListarPesquisaCtsPagarTypes = {
  dataInclusaoInicial: string | null;
  dataInclusaoFinal: string | null;
  dataEmissaoInicial: string | null;
  dataEmissaoFinal: string | null;
  dataVencimentoInicial: string | null;
  dataVencimentoFinal: string | null;
  dataPrevisaoInicial: string | null;
  dataPrevisaoFinal: string | null;
  dataPagamentoInicial: string | null;
  dataPagamentoFinal: string | null;
  dataBaixaInicial: string | null;
  dataBaixaFinal: string | null;
  dataProgramacaoInicial: string | null;
  dataProgramacaoFinal: string | null;
  listaEmpresasId: string;
  listaStatusId: string;
  listaFornecedoresId: string;
  documento: string;
  listaPlanosDeContasId: string;
  cobrancaNaEmpresaId: number | null;
  codigoBarras: string;
  mesReferenciaId: number | null;
  listaCentrosCustoId: string;
  listaPortadoresId: string;
  numeroOrdemCompra: string;
  cNPJFornecedor: string;
  documentoContabilId: number | null;
  pagamentoBloqueadoId: number | null;
  listaEquipamentosId: string;
  pagamentoAprovadoId: number | null;
  listaSISPAGId: string;
  adiantamentoFornecedorId: number | null;
  listaTiposDocumentoId: string;
  somenteTitulosPrevisao: boolean;
  ocultarExcluidos: boolean;
};

export type ListarCtsPagarItensTypes = {
  nomeStatus: string;
  id: number;
  nomeEmpresa: string;
  documento: string;
  nomeFornecedor: string;
  nomePlanoDeContas: string;
  valor: number;
  valorAtual: number;
  valorPago: number;
  saldoPagar: number;
  dataVencimento: string;
  dataPagamento: string;
  identificacao: string;
  cobrancaNaEmpresa: boolean;
  nomeMesReferencia: string;
  pagamentoBloqueado: boolean;
  nomePagamentoBloqueado: string;
  numeroOrdemCompra: string;
  nomeOrdemCompra: string;
  nomePedidoImpresso: string;
};
