import { AxiosInstance } from "axios";
import { CGIHttp } from "../../Services/Axios";
import { CGINotificacaoType } from "./CGIGrupoNotificacoes";
const url = "home";
export function HttpCGINotificacao() {
  async function RetornarDados(Link: string) {
    let Obj = await new CGIHttp().conn.get(
      url + "/obter-gruponotificacao-home"
    );
    return Obj?.data.data as Array<CGINotificacaoType>;
  }
  return {
    RetornarDados,
  };
}
