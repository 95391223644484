import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../../Components/CheckBox/CGICheckBox";
import { CGIComboSimples } from "../../../../Components/Combos/CGIComboSimples";
import { useCGIToast } from "../../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIModal } from "../../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../../Context/LoadingContext";
import { FormMarketPlaceType } from "../../../../Types/Sistema/Classificacao/MarketPlace/MarketPlaceTypes";
import { HttpMarketPlace } from "./Http";

type AlterarMarketPlaceType = {
  CarregarDados: Function;
};

export const AlterarMarketPlace = forwardRef(
  (props: AlterarMarketPlaceType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current.Abrir();
      },
      Alterar(pEmpresa: any) {
        CGIModalRef.current.Abrir();

        HttpMarketPlace()
          .RetornarDadosMarketPlace(pEmpresa.id)
          .then((e) => {
            form.reset(e.data.data);
          });
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<FormMarketPlaceType>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    //FINAL  HOOKS

    //INICIO CONST

    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Alterar Market Place",
        Component: AreaDadosMarketPlace(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosMarketPlace() {
      return (
        <CGIBox>
          <CGIBoxItem Col={6}>
            <CGIInput<FormMarketPlaceType>
              id="Form.DadosRegistro.nome"
              label="Market Place"
              placeholder=""
              validadores={{
                required: "Nome obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormMarketPlaceType>
              id="Form.DadosRegistro.cnpj"
              label="CNPJ"
              placeholder=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormMarketPlaceType>
              id="Form.DadosRegistro.listaTransportadoraId"
              label="Transportadora"
              Link="transportadora"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormMarketPlaceType>
              id="Form.DadosRegistro.listaTipoFreteId"
              label="Tipo Frete"
              Link="tipodefrete"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormMarketPlaceType>
              id="Form.DadosRegistro.calcularComissao"
              label="Calcular Comissão"
              placeholder="0"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormMarketPlaceType>
              id="Form.DadosRegistro.ativo"
              label="Ativo"
              tooltip=""
              att={{
                style: { marginTop: 30 },
              }}
              attLabel={{
                style: { marginTop: 30 },
              }}
            />
          </CGIBoxItem>
        </CGIBox>
      );
    }

    const Confirmar = async (data: FormMarketPlaceType, event: any) => {
      setLoadingState(true);
      let Dados = form.getValues();

      if (
        Dados.Form.DadosRegistro.id == null ||
        Dados.Form.DadosRegistro.id === undefined ||
        Dados.Form.DadosRegistro.id === 0
      ) {
        HttpMarketPlace()
          .Incluir(Dados)
          .then((response: any) => {
            ToastSucesso("Registro gravado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return ToastErro(e);
          })
          .finally(() => {
            setLoadingState(false);
          });
      } else {
        HttpMarketPlace()
          .Alterar(data)
          .then((data: any) => {
            ToastSucesso("Registro Alterado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return;
          })
          .finally(() => {
            setLoadingState(false);
          });
      }
    };
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="sistema/classificacao/listar"
        ref={CGIModalRef}
        Titulo="Market Place"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
