import { AxiosInstance } from "axios";
import { CGIHttp } from "../../Services/Axios";
import { CGIConsultaRegistro } from "./CGISearch";
export function HttpCGISearch() {
  async function RetornarDados(
    codigo: string,
    link: string,
    controller: string
  ) {
    let Obj = await new CGIHttp().conn.post(controller + "/consultar-codigo/", {
      codigoconsulta: codigo,
      link: link,
    });

    return Obj?.data.data as CGIConsultaRegistro;
  }

  return {
    RetornarDados,
  };
}
