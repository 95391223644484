import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarBoletoBancarioItensTypes,
  ListarBoletoBancarioTypes,
  ObjListarBoletoBancarioPadrao,
  BoletoBancarioVisualizarTypes,
} from "../../../Types/Financeiro/BoletoBancario/BoletoBancarioTypes";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpBoletoBancario } from "./Http";
import { AlterarBoletoBancario } from "./_AlterarBoletoBancario";
import { VisualizarBoletoBancario } from "./_VisualizarBoletoBancario";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGINumberInput } from "../../../Components/NumberInput/CGINumberInput";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";

export function ListarBoletoBancario() {
  const AlterarBoletoBancarioRef = useRef<ModalBaseType>();
  const VisualizarBoletoBancarioRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarBoletoBancarioTypes>({
    defaultValues: ObjListarBoletoBancarioPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarBoletoBancarioes = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpBoletoBancario().RetornarBoletoBancarios(
        DadosFiltros
      );
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarBoletoBancarioItensTypes) => {
      return await HttpBoletoBancario().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpBoletoBancario()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo Boleto Bancário",
      Permissao: "financeiro/boletobancario/incluir",
      OnClick: () => {
        AlterarBoletoBancarioRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Alterar",
      Permissao: "financeiro/boletobancario/alterar",
      OnClick: (e: ListarBoletoBancarioItensTypes) => {
        AlterarBoletoBancarioRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "financeiro/boletobancario/excluir",
      OnClick: (e: ListarBoletoBancarioItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Visualizar",
      Permissao: "financeiro/boletobancario/visualizar",
      OnClick: (e: BoletoBancarioVisualizarTypes) => {
        VisualizarBoletoBancarioRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Incluído", cor: "green" },
    { nomeStatus: "Remessa", cor: "LightBlue" },
    { nomeStatus: "Liquidado", cor: "blue" },
    { nomeStatus: "Cancelado Banco", cor: "orange" },
    { nomeStatus: "Cancelado Manual", cor: "yellow" },
    { nomeStatus: "Excluído", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarBoletoBancarioItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "numeroTitulo",
      Titulo: "N° Título",
      Tipo: "Numero",
      att: { maxWidth: 110 },
    },
    {
      Campo: "dataCadastro",
      Titulo: "Dt. Cadastro",
      Tipo: "Data",
      att: { maxWidth: 130 },
    },
    {
      Campo: "dataVencimento",
      Titulo: "Dt. Vencimento",
      Tipo: "Data",
      att: { maxWidth: 150 },
    },
    {
      Campo: "dataPagamento",
      Titulo: "Dt. Pagamento",
      Tipo: "Data",
      att: { maxWidth: 150 },
    },
    {
      Campo: "nomeConvenioPortador",
      Titulo: "Convênio",
      Tipo: "Texto",
    },
    {
      Campo: "nomeColaborador",
      Titulo: "Cliente",
      Tipo: "Texto",
    },
    {
      Campo: "nossoNumero",
      Titulo: "Nosso N°",
      Tipo: "Numero",
      att: { maxWidth: 130 },
    },
    {
      Campo: "digitoDoNossoNumero",
      Titulo: "Dig",
      Tipo: "Numero",
      att: { maxWidth: 80 },
    },
    {
      Campo: "valor",
      Titulo: "Valor (R$)",
      Tipo: "Moeda",
      att: { maxWidth: 100 },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarBoletoBancarioItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          valor: "nomeStatus",
          titulo: "",
        },
        {
          valor: "numeroTitulo",
          titulo: "N° Título",
        },
        {
          valor: "dataCadastro",
          titulo: "Dt. Cadastro",
        },
        {
          valor: "dataVencimento",
          titulo: "Dt. Vencimento",
        },
        {
          valor: "nomeConvenioPortador",
          titulo: "Convênio",
        },
        {
          valor: "dataPagamento",
          titulo: "Dt. Pagamento",
        },
        {
          valor: "nomeColaborador",
          titulo: "Cliente",
        },
        {
          valor: "nossoNumero",
          titulo: "Nosso N°",
        },
        {
          valor: "digitoDoNossoNumero",
          titulo: "Dig",
        },
        {
          valor: "valor",
          titulo: "Valor (R$)",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Boleto Bancário"
          CarregarDados={RetornarBoletoBancarioes}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarBoletoBancario
          CarregarDados={methods.handleSubmit(RetornarBoletoBancarioes)}
          ref={AlterarBoletoBancarioRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarBoletoBancario
          CarregarDados={methods.handleSubmit(RetornarBoletoBancarioes)}
          ref={VisualizarBoletoBancarioRef}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Filtros de Período">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarBoletoBancarioTypes>
                id="Pesquisa.dataCadastroInicial"
                label="Data Cadastro - Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarBoletoBancarioTypes>
                id="Pesquisa.dataCadastroFinal"
                label="Data Cadastro - Final"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarBoletoBancarioTypes>
                id="Pesquisa.dataVencimentoInicial"
                label="Data Vencimento - Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarBoletoBancarioTypes>
                id="Pesquisa.dataVencimentoFinal"
                label="Data Vencimento - Final"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarBoletoBancarioTypes>
                id="Pesquisa.dataPagamentoInicial"
                label="Data Pagamento - Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarBoletoBancarioTypes>
                id="Pesquisa.dataPagamentoFinal"
                label="Data Pagamento - Final"
              />
            </CGIBoxItem>
          </CGIBox>
          <CGIBoxItem Col={6}>
            <CGICheckBox<ListarBoletoBancarioTypes>
              id="Pesquisa.ocultarExcluido"
              label="Ocultar excluídos"
              tooltip="Não exibe itens excluídos"
            />
          </CGIBoxItem>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Filtros de Valores">
            <CGIBoxItem Col={6}>
              <CGINumberInput<ListarBoletoBancarioTypes>
                id="Pesquisa.valorDe"
                label="Valor - De"
                prefix
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGINumberInput<ListarBoletoBancarioTypes>
                id="Pesquisa.valorAte"
                label="Valor - Até"
                prefix
              />
            </CGIBoxItem>
          </CGIBox>
          <CGIBox>
            <CGIBoxItem Col={12}>
              <CGIComboAjax<ListarBoletoBancarioTypes>
                id="Pesquisa.listaColaboradoresId"
                label="Colaborador"
                Link={"cliente"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIComboSimples<ListarBoletoBancarioTypes>
                id="Pesquisa.listaConveniosId"
                label="Convênio"
                Link={"convenioportador"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIComboSimples<ListarBoletoBancarioTypes>
                id="Pesquisa.listaStatusId"
                label="Status"
                Link={"statuspedido"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIInput<ListarBoletoBancarioTypes>
                id="Pesquisa.nossoNumero"
                label="Nosso Número"
                placeholder={"Nosso Número"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIInput<ListarBoletoBancarioTypes>
                id="Pesquisa.documentoCtsReceber"
                label="Documento Cts Receber"
                placeholder={"Documento Cts Receber"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="financeiro/boletobancario/listar"
      Titulo="Listar Boleto Bancário"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarBoletoBancarioes)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
