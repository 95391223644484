export const ObjClientesBloqueadosTypesPadrao: FormClientesBloqueadosType = {
  Pesquisa: {
    id: 0,
    nomeCompleto: "",
    nomeFantasia: "",
    cpfcnpj: "",
    dataBloqueio: "",
    nomeUsuarioBloqueio: "",
    obs: "",
    dataBloqueioInicial: "",
    dataBloqueioFinal: "",
  },
  Itens: [],
};

export type FormClientesBloqueadosType = {
  Pesquisa: ClientesBloqueadosPesquisaTypes;
  Itens: ClientesBloqueadosItensTypes[];
};

export type ClientesBloqueadosPesquisaTypes = {
  id: number;
  nomeCompleto: string;
  nomeFantasia: string;
  cpfcnpj: string;
  dataBloqueio: string;
  nomeUsuarioBloqueio: string;
  obs: string;
  dataBloqueioInicial: string;
  dataBloqueioFinal: string;
};

export type ClientesBloqueadosItensTypes = {
  id: number;
  nomeCompleto: string;
  cpfcnpj: string;
  dataBloqueio?: string;
  nomeUsuarioBloqueio?: string;
  diasBloqueados: number;
};
