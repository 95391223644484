/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react";
import { UseFormReturn, FieldValues } from "react-hook-form";
import { CGIBox } from "../../Box/CGIBox";
import { CGIBoxItem } from "../../Box/CGIBoxItens";
import { CGICheckBox } from "../../CheckBox/CGICheckBox";
import { CGIInput } from "../../Input/CGIInput";
import { CGIRadioButton } from "../../RadioButton/CGIRadioButton";
import { HttpCGIResumosComposto } from "../HttpResumo";

type CGIResumoCtsRecebertype = {
  watchCtsReceberId: string;
};

function CGIResumoCtsReceber(
  watchCtsReceberId: string,
  formPrincipal: UseFormReturn<FieldValues, any>,
  formResumo: UseFormReturn<FieldValues, any>
) {
  const Tipo = "CtsReceber";
  const [valueOld, setValueOld] = useState();
  function a() {}

  useEffect(() => {});

  useEffect(() => {
    var value =
      formPrincipal.getValues(watchCtsReceberId) ||
      formResumo.getValues(watchCtsReceberId);
    if (value == null || value === undefined || value === 0 || value === "") {
      return;
    }

    if (valueOld === value) {
      return;
    } else {
      setValueOld(value);
    }

    RetornarDados(value);
  }, [
    formPrincipal.watch(watchCtsReceberId),
    formResumo.watch(watchCtsReceberId),
  ]);

  async function RetornarDados(id: any) {
    var Dados = await HttpCGIResumosComposto().RetornarDados(id, Tipo);
    var key = Object.keys(Dados.registros[0]);
    key.map((e) => {
      if (e === "Id" || e === "id") return "";
      var value = Dados.registros[0][e];

      return formResumo.setValue(e, value);
    });
  }
  return (
    <CGIBox spacing="10px">
      <CGIBoxItem Col={3}>
        <CGIInput id={watchCtsReceberId} placeholder={""} ocultar />
        <CGIInput
          id={"CTSRECEBERDocumento"}
          label={"Documento"}
          placeholder={"Nome"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"COLABNomeFantasia"}
          label={"Nome Fantasia"}
          placeholder={"Nome Fantasia"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"COLABCPFCNPJ"}
          label={"CPF / CNPJ"}
          placeholder={"CPF / CNPJ"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"COLABIe"}
          label={"Reg. Insc. Est."}
          placeholder={"Reg. Inscrição Estadual"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"COLABCEP"}
          label={"CEP"}
          placeholder={"CEP"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"COLABLogadouro"}
          label={"Logadouro"}
          placeholder={"Logadouro"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"COLABNumero"}
          label={"N.º"}
          placeholder={"N.º"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"COLABComplemento"}
          label={"Complemento"}
          placeholder={"Complemento"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"COLABBairro"}
          label={"Bairro"}
          placeholder={"Bairro"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"COLABCidade"}
          label={"Cidade"}
          placeholder={"Cidade"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"COLABPontoReferencia"}
          label={"Pontos Referência"}
          placeholder={"Pontos Referência"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"COLABTelefone1"}
          label={"Telefone 1"}
          placeholder={"Telefone 1"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"COLABTelefone2"}
          label={"Telefone 2"}
          placeholder={"Telefone 2"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput
          id={"COLABTelefone3"}
          label={"Telefone 3"}
          placeholder={"Telefone 3"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={2}>
        <CGIRadioButton
          att={{ isDisabled: true }}
          id={"COLABFisicaJuridica"}
          row={"column"}
          label={"Física / Jurídica"}
          values={[
            {
              label: "Física",
              valor: "F",
            },
            {
              label: "Jurídica",
              valor: "J",
            },
          ]}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={2}>
        <CGICheckBox
          id={"COLABEhContribuinte"}
          label={"É Contribuinte"}
          tooltip={"É Contribuinte"}
          att={{
            disabled: true,
          }}
        />
        <CGICheckBox
          id={"COLABAtivo"}
          label={"Ativo"}
          tooltip={"Ativo"}
          att={{
            disabled: true,
          }}
        />
      </CGIBoxItem>
    </CGIBox>
  );
}

export const ResumoCtsReceber = {
  id: "ResumoCtsReceber",
  titulo: "Dados do Cts. Receber",
  componente: CGIResumoCtsReceber,
};
