import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useContext,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../Context/LoadingContext";
import {
  FormUsuarioTypes,
  ListarUsuarioItensTypes,
  ListarUsuarioTypes,
  ObjListarUsuarioPadrao,
  UsuarioVisualizarTypes,
  DadosRegistroTypes,
} from "../../../Types/Sistema/Usuario/UsuarioTypes";
import { HttpUsuario } from "./Http";

type AlterarUsuarioType = {
  CarregarDados: Function;
};

export const AlterarUsuario = forwardRef((props: AlterarUsuarioType, ref) => {
  //INICO REF

  useImperativeHandle(ref, () => ({
    Abrir() {
      CGIModalRef.current.Abrir();
      // CHAMADAS PARA CARREGAR OS GRIDS
      RetornarEmpresas(0);
      RetornarCalendarios(0);
      RetornarResumos(0);
      RetornarMetas(0);
    },
    async Alterar(e: ListarUsuarioItensTypes) {
      // CHAMADAS PARA CARREGAR OS GRIDS
      CarregarDados(e.id);
    },
  }));
  //FINAL REF

  //INICO HOOKS
  const CGIModalRef = useRef() as any;
  const form = useFormContext();

  const { setLoadingState } = useContext(LoadingContext);
  const { ToastSucesso } = useCGIToast();

  const [GridDadosMetas, setGridDadosMetas] = useState<Array<any>>([]);
  const [GridDadosEmpresas, setGridDadosEmpresas] = useState<Array<any>>([]);
  const [GridDadosResumos, setGridDadosResumos] = useState<Array<any>>([]);
  const [GridDadosCalendarios, setGridDadosCalendarios] = useState<Array<any>>(
    []
  );
  //FINAL HOOKS

  //INICO CONST
  const GridColunasUsuarios: Array<CGICol<any>> = [
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        hide: true,
      },
    },
    {
      Campo: "nome",
      Titulo: "Nome",
      Tipo: "Texto",
    },
    {
      Campo: "selecionado",
      Titulo: "Sel.",
      Tipo: "CheckBox",
      att: {
        editable: false,
      },
    },
  ];
  const RenderTabs: Array<CGITabItensTypes> = [
    {
      Titulo: "Dados do Usuário",
      Component: AreaDadosUsuario(),
    },
    {
      Titulo: "Vínculos",
      Component: AreaVinculos(),
    },
    {
      Titulo: "Horários",
      Component: AreaHorarios(),
    },
    {
      Titulo: "Empresas",
      Component: AreaEmpresas(),
    },
    {
      Titulo: "Metas",
      Component: AreaMetas(),
    },
    {
      Titulo: "Resumos",
      Component: AreaResumos(),
    },
    {
      Titulo: "Calendários",
      Component: AreaCalendarios(),
    },
    {
      Titulo: "Configuração",
      Component: AreaConfiguracoes(),
    },
  ];
  //FINAL CONST

  //INICO FUNC

  async function CarregarDados(id: number) {
    var DadosUsuario = await HttpUsuario().RetornarDadosUsuario(id);
    // Antigo form.reset(DadosUsuario);
    // Novo form.setValue("Form", DadosUsuario);
    form.setValue("Form", DadosUsuario);

    RetornarEmpresas(id);
    RetornarCalendarios(id);
    RetornarResumos(id);
    RetornarMetas(id);
    CGIModalRef.current.Abrir();
  }
  async function RetornarEmpresas(UsuarioId: number) {
    var data = await HttpUsuario().RetornarEmpresasDoUsuario(UsuarioId);
    setGridDadosEmpresas(data.data.data);
    //form.setValue("Form.Empresas", []);
  }

  async function RetornarMetas(UsuarioId: number) {
    var data = await HttpUsuario().RetornarMetasDoUsuario(UsuarioId);
    setGridDadosMetas(data.data.data);
  }

  async function RetornarCalendarios(UsuarioId: number) {
    var data = await HttpUsuario().RetornarCalendariosDoUsuario(UsuarioId);
    setGridDadosCalendarios(data.data.data);
  }

  async function RetornarResumos(UsuarioId: number) {
    var data = await HttpUsuario().RetornarResumosDoUsuario(UsuarioId);
    setGridDadosResumos(data.data.data);
  }

  const Confirmar = async (data: DadosRegistroTypes, event: any) => {
    setLoadingState(true);

    data.calendarios = GridDadosCalendarios;
    data.resumos = GridDadosResumos;
    data.metas = GridDadosMetas;
    data.empresas = GridDadosEmpresas;

    if (data.id == null || data.id === undefined || data.id === 0) {
      HttpUsuario()
        .Incluir(data)
        .then((response: any) => {
          ToastSucesso("Registro gravado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
        })
        .catch(e => {
          return;
        })
        .finally(() => {
          setLoadingState(false);
        });
    } else {
      HttpUsuario()
        .Alterar(data)
        .then((data: any) => {
          ToastSucesso("Registro gravado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
        })
        .catch(e => {
          return;
        })
        .finally(() => {
          setLoadingState(false);
        });
    }
  };
  //FINAL FUNC

  //INICIO JSX
  function AreaDadosUsuario() {
    return (
      <CGIBox>
        <CGIBoxItem Col={3}>
          <CGIInput
            id="login"
            label="Login"
            placeholder="Login"
            validadores={{ required: "Login obrigatório" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={8}>
          <CGIInput
            id="nome"
            label="Nome"
            placeholder="Nome"
            validadores={{ required: "Nome obrigatório" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIInput id="eMail" label="E-mail" placeholder="E-mail" />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIComboSimples
            Link={"empresa"}
            label={"Empresa"}
            id={"empresaId"}
            validadores={{ required: "Empresa obrigatório" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIComboSimples
            Link={"perfilusuario"}
            Multipla
            label={"Perfil"}
            id={"listaPerfilId"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIInput
            id="linkAssinatura"
            label="Link Assinatura"
            placeholder="Link Assinatura"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIComboSimples
            Link={"empresa"}
            label={"Empresa Caixa"}
            id={"empresaCaixaId"}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }
  function AreaVinculos() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIComboSimples
            Link={"motorista"}
            label={"Motorista"}
            id={"motoristaId"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIComboSimples
            Link={"vendedor"}
            label={"Vendedor"}
            id={"vendedorId"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIComboSimples
            Link={"tecnico"}
            label={"Técnico"}
            id={"tecnicoId"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIComboAjax id="clienteId" label="Cliente" Link="cliente" />
        </CGIBoxItem>
      </CGIBox>
    );
  }
  function AreaHorarios() {
    return (
      <CGIBox>
        <CGIBoxItem Col={3}>
          <CGIInput id="horarioSegunda" label="Segunda" placeholder="Segunda" />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput id="horarioTerca" label="Terça" placeholder="Terça" />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput id="horarioQuarta" label="Quarta" placeholder="Quarta" />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput id="horarioQuinta" label="Quinta" placeholder="Quinta" />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput id="horarioSexta" label="Sexta" placeholder="Sexta" />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput id="horarioSabado" label="Sábado" placeholder="Sábado" />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput id="horarioDomingo" label="Domingo" placeholder="Domingo" />
        </CGIBoxItem>
      </CGIBox>
    );
  }
  function AreaEmpresas() {
    return (
      <CGIDataTableSimples
        Data={GridDadosEmpresas}
        Colunas={GridColunasUsuarios}
      />
    );
  }
  function AreaResumos() {
    return (
      <CGIDataTableSimples
        Data={GridDadosResumos}
        Colunas={GridColunasUsuarios}
      />
    );
  }
  function AreaMetas() {
    return (
      <CGIDataTableSimples
        Data={GridDadosMetas}
        Colunas={GridColunasUsuarios}
      />
    );
  }
  function AreaCalendarios() {
    return (
      <CGIDataTableSimples
        Data={GridDadosCalendarios}
        Colunas={GridColunasUsuarios}
      />
    );
  }
  function AreaConfiguracoes() {
    return (
      <CGIBox>
        <CGIBoxItem Col={3}>
          <CGICheckBox tooltip="Ativo" id="ativo" label="Ativo" />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGICheckBox
            tooltip="Acesso Total"
            id="acessoTotal"
            label="Acesso Total"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGICheckBox tooltip="Mobile" id="ehMobile" label="Mobile" />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGICheckBox
            tooltip="Sempre Exibir Chat"
            id="sempreExibirChat"
            label="Sempre Exibir Chat"
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIModal
      id="sistema/usuario/alterar"
      ref={CGIModalRef}
      Titulo="Cadastro de Usuário"
      TelaCheia={"6xl"}
      Confirmar={Confirmar}
      CarregarDados={CarregarDados}
      Fechar={() => {
        form.reset({});
      }}
    >
      <CGITabs Tabs={RenderTabs} />
    </CGIModal>
  );
  //FINAL JSX
});
