import { AddIcon } from "@chakra-ui/icons";
import { Box } from "framer-motion";
import { useState, useRef, useContext, useCallback, useEffect } from "react";
import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { Link } from "react-router-dom";
import { CGIAnexo } from "../../../Components/Anexo/CGIAnexo";
import { CGIVisualizarAnexo } from "../../../Components/Anexo/CGIVisualizarAnexo";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIHistorico } from "../../../Components/Historico/CGIHistorico";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { FormContext } from "../../../Context/FormConxtext";
import LoadingContext from "../../../Context/LoadingContext";
import { RetornarParamentroBool } from "../../../Services/Util/Util";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import {
  ListarProdutosNaoCadastradosItensTypes,
  ListarProdutosNaoCadastradosTypes,
  ObjListarProdutosNaoCadastradosPadrao,
} from "../../../Types/Sistema/Produto/ProdutosNaoCadastradosTypes";
import { HttpProduto } from "./Http";
import { AlterarProduto } from "./_AlterarProduto";

export function ListarProdutosNaoCadastrados() {
  const AlterarProdutoRef = useRef<ModalBaseType>();
  const CGIHistoricoRef = useRef<ModalBaseType>();
  const CGIAnexoRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarProdutosNaoCadastradosTypes>({
    defaultValues: ObjListarProdutosNaoCadastradosPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const ListarMenu: Array<MenuTypes> = [
    // {
    //   Nome: "Novo Produto",
    //   Permissao: "sistema/produto/incluir",
    //   OnClick: () => {
    //     AlterarProdutoRef.current?.Abrir();
    //   },
    // },
    // {
    //   Nome: "Novo Serviço",
    //   Permissao: "sistema/produto/incluirservico",
    //   OnClick: () => {
    //     AlterarProdutoRef.current?.Abrir();
    //   },
    // },
    // {
    //   Nome: "Importar via Arquivo",
    //   Permissao: "sistema/produto/importararquivo",
    //   OnClick: () => {
    //     AlterarProdutoRef.current?.Abrir();
    //   },
    // },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "sistema/produto/visualizar",
      OnClick: (e: ListarProdutosNaoCadastradosItensTypes) => {
        AlterarProdutoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Alterar",
      Permissao: "sistema/produto/alterar",
      OnClick: (e: ListarProdutosNaoCadastradosItensTypes) => {
        AlterarProdutoRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Histórico",
      Permissao: "sistema/produto/visualizarhistoricoproduto",
      OnClick: (e: ListarProdutosNaoCadastradosItensTypes) => {
        CGIHistoricoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Vincular Produto",
      Permissao: "sistema/produto/vincularprodutocadastrado",
      OnClick: (e: ListarProdutosNaoCadastradosItensTypes) => {
        CGIAnexoRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarProdutosNaoCadastradosItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "codigoProduto",
      Titulo: "Cód. Produto",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 150,
      },
    },
    {
      Campo: "id",
      Titulo: "Identificação",
      Tipo: "Composta",
      Composta: [
        {
          titulo: "Identificação Pedido",
          valor: "identificacaoPedido",
          exibirLabel: true,
        },
        {
          titulo: "Identificação Orçamento",
          valor: "identificacaoOrcamento",
          exibirLabel: true,
        },
        {
          titulo: "Identificação Pedido Compra",
          valor: "identificacaoPedidoCompra",
          exibirLabel: true,
        },
      ],
    },
    {
      Campo: "nomeColaborador",
      Titulo: "Nome Colaborador",
      Tipo: "Texto",
    },
    {
      Campo: "descricao",
      Titulo: "Descrição",
      Tipo: "Texto",
    },
    {
      Campo: "nomeFornecedor",
      Titulo: "Fornecedor",
      Tipo: "Texto",
    },
    {
      Campo: "valorInformado",
      Titulo: "Preço Venda",
      Tipo: "Moeda",
      att: {
        maxWidth: 130,
      },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarProdutosNaoCadastradosItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];
  //Menus
  //Func

  async function RetornarProdutosNaoCadastrados() {
    setLoadingState(true);
    var DadosFiltro = methods.getValues("Pesquisa");
    var data = await HttpProduto()
      .RetornarProdutosNaoCadastrados(DadosFiltro)
      .finally(() => {
        setLoadingState(false);
      });
    methods.setValue("Itens", data);
  }

  //Func

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarProdutosNaoCadastradosTypes>
            id="Pesquisa.descricao"
            label="Descrição"
            placeholder="Descrição"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarProdutosNaoCadastradosTypes>
            id="Pesquisa.identificacaoPedido"
            label="Identificação Pedido"
            placeholder="Identificação Pedido"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarProdutosNaoCadastradosTypes>
            id="Pesquisa.identificacaoOrcamento"
            label="Identificação Orçamento"
            placeholder="Identificação Orçamento"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarProdutosNaoCadastradosTypes>
            id="Pesquisa.codigoProduto"
            label="Código do Produto"
            placeholder="Código do Produto"
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="sistema/produto/listarnaocadastrados"
      Titulo="Produtos Não Cadastrados"
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => {
            methods.reset();
          }}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarProdutosNaoCadastrados)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
      <FormContext>
        <AlterarProduto
          CarregarDados={methods.handleSubmit(RetornarProdutosNaoCadastrados)}
          ref={AlterarProdutoRef}
        />
      </FormContext>
      <FormContext>
        <CGIHistorico
          tipo="TimeLine"
          titulo="Histórico do Produto"
          ref={CGIHistoricoRef}
          link={"sistema/produto/visualizarhistoricoproduto"}
          origem={"usuario"}
        />
      </FormContext>
      <FormContext>
        <CGIVisualizarAnexo
          ref={CGIAnexoRef}
          link={"sistema/anexo/visualizaranexosprodutos"}
          origem={"PRODUTO"}
          titulo={"Anexos Produtos"}
        />
      </FormContext>
    </CGIListar>
  );
}
