import { CGIHttp } from "../../../../Services/Axios";
import {
  FormIntegracaoMarketPlaceType,
  ListarIntegracaoMarketPlaceItensTypes,
  ListarPesquisaIntegracaoMarketPlaceTypes,
} from "../../../../Types/Sistema/Classificacao/IntegracaoMarketPlace/IntegracaoMarketPlaceTypes";

const url = "/classificacao";

export function HttpIntegracaoMarketPlace() {
  async function RetornarIntegracaoMarketPlaces(
    DadosFiltros: ListarPesquisaIntegracaoMarketPlaceTypes
  ) {
    return (
      await new CGIHttp().conn.post(
        url + "/obter-integracoes-marketplace",
        DadosFiltros
      )
    ).data.data;
  }

  async function Listar() {
    return await (
      await new CGIHttp().conn.get(url + "/listar-176")
    ).data.data;
  }

  async function RetornarDadosIntegracaoMarketPlace(id: number) {
    return await new CGIHttp().conn.get(
      url + `/alterar-integracao-marketplace/${id}`
    );
  }

  async function Incluir(Dados: FormIntegracaoMarketPlaceType) {
    return await new CGIHttp().conn.post(
      url + "/incluir-integracao-marketplace",
      Dados.Form
    );
  }
  async function Alterar(Dados: FormIntegracaoMarketPlaceType) {
    return await new CGIHttp().conn.post(
      url + "/alterar-integracaomarketplace",
      Dados.Form
    );
  }
  async function RetornarDadosExclusao(
    Dados: ListarIntegracaoMarketPlaceItensTypes
  ) {
    return await new CGIHttp().conn.get(
      url + "/excluir-integracao-marketplace/" + Dados.id
    );
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(
      url + "/excluir-integracao-marketplace",
      Dados
    );
  }

  return {
    RetornarIntegracaoMarketPlaces,
    RetornarDadosIntegracaoMarketPlace,
    RetornarDadosExclusao,
    Incluir,
    Listar,
    Alterar,
    Excluir,
  };
}
