import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIDataTable } from "../../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../../Context/LoadingContext";
import { ModalBaseType } from "../../../../Types/Modal/ModalBaseType";
import {
  ListarMarketPlaceItensTypes,
  ListarMarketPlaceTypes,
  ObjListarMarketPlacePadrao,
} from "../../../../Types/Sistema/Classificacao/MarketPlace/MarketPlaceTypes";
import { DeleteTypes } from "../../../../Types/Util/DeleteTypes";

import { HttpMarketPlace } from "./Http";
import { AlterarMarketPlace } from "./_AlterarMarketPlace";

export function ListarMarketPlace() {
  //Inicio Hooks
  const AlterarMarketPlaceRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarMarketPlaceTypes>({
    defaultValues: ObjListarMarketPlacePadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  async function Excluir(data: DeleteTypes) {
    await HttpMarketPlace()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }
  //Final Hooks

  //------------------------

  //Inicio Consts
  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo Market Place",
      Permissao: "sistema/classificacao/incluirmarketplacehomologado",
      OnClick: () => {
        AlterarMarketPlaceRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Alterar",
      Permissao: "sistema/classificacao/alterarmarketplacehomologado",
      OnClick: (e: ListarMarketPlaceItensTypes) => {
        AlterarMarketPlaceRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/classificacao/excluirmarketplacehomologado",
      OnClick: (e: ListarMarketPlaceItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarMarketPlaceItensTypes>> = [
    {
      Campo: "ativo",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: [
        {
          nomeStatus: "true",
          cor: "green",
        },
        {
          nomeStatus: "false",
          cor: "red",
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 100,
      },
    },
    {
      Campo: "nome",
      Titulo: "MarketPlace",
      Tipo: "Texto",
    },
    {
      Campo: "cnpj",
      Titulo: "CNPJ",
      Tipo: "CheckBox",
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarMarketPlaceItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Market Place"
          CarregarDados={methods.handleSubmit(RetornarMarketPlaces)}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarMarketPlace
          CarregarDados={methods.handleSubmit(RetornarMarketPlaces)}
          ref={AlterarMarketPlaceRef}
        />
      ),
    },
  ];

  //Final Consts

  //------------------------

  //Inicio Function

  async function RetornarMarketPlaces() {
    setLoadingState(true);
    const DadosFiltro = methods.getValues("Pesquisa");
    var data = await HttpMarketPlace()
      .RetornarMarketPlaces(DadosFiltro)
      .finally(() => {
        setLoadingState(false);
      });
    methods.setValue("Itens", data);
  }

  async function RetornarDadosExclusao(data: ListarMarketPlaceItensTypes) {
    return await HttpMarketPlace().RetornarDadosExclusao(data);
  }

  //Final Function

  //------------------------

  // Inicio JSX

  function Filtros() {
    return <CGIBox children={undefined}></CGIBox>;
  }

  return (
    <>
      <CGIListar
        id="sistema/classificacao/listar"
        Titulo="Listar Market Place"
        Janelas={Janelas}
      >
        <FormProvider {...methods}>
          <CGIDataTable
            LimparFiltros={() => methods.reset()}
            Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
            Pesquisar={methods.handleSubmit(RetornarMarketPlaces)}
            Filtro={Filtros()}
            Data={GridItens}
            Colunas={GridColunas}
            Legenda={Legendas}
          />
        </FormProvider>
      </CGIListar>
    </>
  );
  // Final JSX
}
