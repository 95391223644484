/* eslint-disable react-hooks/exhaustive-deps */
import { EditIcon } from "@chakra-ui/icons";
import { ButtonGroup, IconButton } from "@chakra-ui/react";
import { ICellRendererParams, RowNode } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, {
  createContext,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
} from "react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGIDataTableSimples } from "../../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../../Components/DataTable/DataTable/DataTableTypes";
import { useCGIToast } from "../../../../Components/Hook/useCGIToast";
import { CGIModal } from "../../../../Components/Modal/CGIModal";
import { CGITabs } from "../../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../../Context/LoadingContext";
import { ModalBaseType } from "../../../../Types/Modal/ModalBaseType";
import {
  ConfigPedLojaType,
  DadosProdutoSelecionadoType,
  ItensPedidoType,
  objPedLoja,
  PedidoLojaType,
} from "../../../../Types/Vendas/Pedido/PedidoLojaType";
import { HttpPedido } from "../Http";
import { AreaCliente } from "./Areas/AreaCliente";
import { AreaFiscal } from "./Areas/AreaFiscal";
import { AreaOutros } from "./Areas/AreaOutros";
import { AreaPagamento } from "./Areas/AreaPagamento";
import { AreaProduto } from "./Areas/AreaProduto";
import { AreaTipoPedido } from "./Areas/AreaTipoPedido";
import { AreaTransporte } from "./Areas/AreaTrasporte";
import { AreaTroca } from "./Areas/AreaTroca";
import { PEDLojaAlterta } from "./Components/Alertas";
import { PEDLojaTotalizador } from "./Components/Totalizadores";
import { AlterarProdutoGridPedidoLoja } from "./Modal/_AlterarProdutoGridPedidoLoja";
const ItemPedidoPadrao: DadosProdutoSelecionadoType = {
  PesquisaDetalhada: "",
  vlrDesconto: 0,
  id: 0,
  codigoProduto: "",
  estoque: 0,
  multiplo: 0,
  precoVenda: 0,
  precoPromocional: 0,
  precoCampanhaVendas: 0,
  descricao: "",
  quantidade: 0,
  obsVenda: "",
  gradeProdutoId: 0,
  unidade: "",
  unidadesConversao: "",
  pesoBruto: 0,
  percICMS: 0,
  percIPI: 0,
  percFCP: 0,
  percPIS: 0,
  percCOFINS: 0,
  ultimaAlteracaoPrecoProduto: "",
  ehPrecoPromocional: false,
  ehPrecoCampanhaVendas: false,
  codigoProdutoFabricante: "",
  ncm: "",
  tipoProdutoId: 0,
  percDesconto: 0,
  usuarioDescontoId: "",
  descontoMaximo: 0,
  defeito: false,
  vlrMinimo: 0,
  vlrMinimoCadastro: 0,
  vlrUnitario: 0,
  campanhaVendasId: 0,
  nomeCampanhaVendas: 0,
};
interface PedLojaContextTypes {
  form: UseFormReturn<PedidoLojaType>;
  AreaClienteRef: React.MutableRefObject<AreaRef | null>;
  AreaFiscalRef: React.MutableRefObject<AreaRef | null>;
  AreaOutrosRef: React.MutableRefObject<AreaRef | null>;
  AreaPagamentoRef: React.MutableRefObject<AreaRef | null>;
  AreaProdutoRef: React.MutableRefObject<AreaRef | null>;
  AreaTipoPedidoRef: React.MutableRefObject<AreaRef | null>;
  AreaTransporteRef: React.MutableRefObject<AreaRef | null>;
  AreaTrocaRef: React.MutableRefObject<AreaRef | null>;
  GridItensPrincipalRef: React.RefObject<AgGridReact | null>;
  GridItensTrocaRef: React.RefObject<AgGridReact | null>;
  GridIPagamentoRef: React.RefObject<AgGridReact | null>;
  TabRef: React.MutableRefObject<{ TabIndex: Function } | null>;
  AdicionarItensGridPrincipal: Function;
}

type AreaRef = {
  RetornarDados: Function;
};

const PedLojaContext = createContext<PedLojaContextTypes>(
  {} as PedLojaContextTypes
);

type AlterarPedidoLojaType = {
  CarregarDados: Function;
};
const AlterarPedidoLoja = forwardRef((props: AlterarPedidoLojaType, ref) => {
  const CGIModalRef = useRef<ModalBaseType>(null);
  useImperativeHandle(ref, () => ({
    async Abrir() {
      try {
        setLoadingState(true);
        var Dados = await HttpPedido().IncluirGET();
        form.reset(Dados);
        CGIModalRef.current?.Abrir();
      } finally {
        setLoadingState(false);
      }
    },
    Alterar(pEmpresa: any) {
      //CarregarDados(pEmpresa.Id);
      CGIModalRef.current?.Abrir();
    },
  }));
  const form = useFormContext<PedidoLojaType>();
  const ModalDetalheProdutoRef = useRef<ModalBaseType>(null);
  const AreaClienteRef = useRef<{ RetornarDados: Function }>(null);
  const AreaFiscalRef = useRef<{ RetornarDados: Function }>(null);
  const AreaOutrosRef = useRef<{ RetornarDados: Function }>(null);
  const AreaPagamentoRef = useRef<{ RetornarDados: Function }>(null);
  const AreaProdutoRef = useRef<{ RetornarDados: Function }>(null);
  const AreaTipoPedidoRef = useRef<{ RetornarDados: Function }>(null);
  const AreaTransporteRef = useRef<{ RetornarDados: Function }>(null);
  const AreaTrocaRef = useRef<{ RetornarDados: Function }>(null);
  const GridItensPrincipalRef = useRef<AgGridReact>(null);
  const GridItensTrocaRef = useRef<AgGridReact>(null);
  const GridIPagamentoRef = useRef<AgGridReact>(null);
  const TabRef = useRef(null) as any;
  const { ToastErrorId } = useCGIToast();
  const { setLoadingState } = useContext(LoadingContext);

  const TabPadrao = [
    {
      Component: <AreaTipoPedido ref={AreaTipoPedidoRef} />,
      Titulo: "Tipo Pedido",
      Tooltip: "F1",
      ocultar: false,
    },
    {
      Component: <AreaProduto ref={AreaProdutoRef} />,
      Titulo: "Produtos",
      Tooltip: "F2",
      ocultar: false,
    },
    {
      Component: <AreaTroca ref={AreaTrocaRef} refGrid={GridItensTrocaRef} />,
      Titulo: "Troca / Devolução",
      Tooltip: "F3",
      ocultar: true,
    },
    {
      Component: <AreaCliente ref={AreaClienteRef} />,
      Titulo: "Cliente",
      Tooltip: "F4",
      ocultar: false,
    },
    {
      Component: <AreaTransporte ref={AreaTransporteRef} />,
      Titulo: "Transporte",
      Tooltip: "F5",
      ocultar: false,
    },
    {
      Component: (
        <AreaPagamento refGrid={GridIPagamentoRef} ref={AreaPagamentoRef} />
      ),
      Titulo: "Pagamento",
      Tooltip: "F6",
      ocultar: false,
    },
    {
      Component: <AreaFiscal ref={AreaFiscalRef} />,
      Titulo: "Fiscal",
      Tooltip: "F7",
      ocultar: false,
    },
    {
      Component: <AreaOutros ref={AreaOutrosRef} />,
      Titulo: "Outros",
      Tooltip: "F8",
      ocultar: false,
    },
  ];
  let [Tabs, setTabs] = useState<
    Array<{
      Component: JSX.Element;
      Titulo: string;
      Tooltip: string;
      ocultar?: boolean;
    }>
  >(TabPadrao);
  const [Dados] = useState<Array<ItensPedidoType>>([]);
  const ColConvert = useMemo<Array<CGICol<ItensPedidoType>>>(
    () => [
      {
        Titulo: "",
        Campo: "selecionado",
        Tipo: "CheckBox",
        att: { maxWidth: 60 },
      },
      {
        Titulo: "id",
        Campo: "id",
        Tipo: "Texto",
        ocultar: true,
      },

      {
        Titulo: "multiplo",
        Campo: "multiplo",
        Tipo: "Texto",
        ocultar: true,
      },

      {
        Titulo: "obsVenda",
        Campo: "obsVenda",
        Tipo: "Texto",
        ocultar: true,
      },
      {
        Titulo: "gradeProdutoId",
        Campo: "gradeProdutoId",
        Tipo: "Texto",
        ocultar: true,
      },

      {
        Titulo: "unidadesConversao",
        Campo: "unidadesConversao",
        Tipo: "Texto",
        ocultar: true,
      },
      {
        Titulo: "ultimaAlteracaoPrecoProduto",
        Campo: "ultimaAlteracaoPrecoProduto",
        Tipo: "Texto",
        ocultar: true,
      },
      {
        Titulo: "ehPrecoPromocional",
        Campo: "ehPrecoPromocional",
        Tipo: "Texto",
        ocultar: true,
      },
      {
        Titulo: "ehPrecoCampanhaVendas",
        Campo: "ehPrecoCampanhaVendas",
        Tipo: "Texto",
        ocultar: true,
      },
      {
        Titulo: "codigoProdutoFabricante",
        Campo: "codigoProdutoFabricante",
        Tipo: "Texto",
        ocultar: true,
      },
      {
        Titulo: "ncm",
        Campo: "ncm",
        Tipo: "Texto",
        ocultar: true,
      },

      {
        Titulo: "campanhaVendasId",
        Campo: "campanhaVendasId",
        Tipo: "Texto",
        ocultar: true,
      },
      {
        Titulo: "nomeCampanhaVendas",
        Campo: "nomeCampanhaVendas",
        Tipo: "Texto",
        ocultar: true,
      },
      {
        Titulo: "Vlr. Unitario",
        Campo: "vlrUnitario",
        Tipo: "Moeda",
        ocultar: true,
      },
      {
        Campo: "codigoProduto",
        Titulo: "Cód.",
        Tipo: "Texto",
        att: { maxWidth: 125 },
      },

      {
        Campo: "descricao",
        Titulo: "Descrição",
        Tipo: "Texto",
        att: {},
      },
      {
        Titulo: "Un.",
        Campo: "unidade",
        Tipo: "Texto",
        att: { maxWidth: 60 },
        ocultar: true,
      },
      {
        Titulo: "Est.",
        Campo: "estoque",
        ExibirCoresConformeValor: true,
        Tipo: "Numero",
        att: { maxWidth: 75 },
        ocultar: true,
      },
      {
        Titulo: "Pr Camp.",
        Campo: "precoCampanhaVendas",
        Tipo: "Moeda",
        att: { maxWidth: 125 },
        ocultar: true,
      },
      {
        Titulo: "Pr Promo.",
        Campo: "precoPromocional",
        Tipo: "Moeda",
        att: { maxWidth: 125 },
        ocultar: true,
      },
      {
        Titulo: "Pr. Venda",
        Campo: "precoVenda",
        Tipo: "Moeda",
        att: { maxWidth: 100 },
      },
      {
        Titulo: "Desc. P/Item",
        Campo: "vlrDesconto",
        Tipo: "Moeda",
        att: { maxWidth: 100 },
      },

      {
        Titulo: "Qtd.",
        Campo: "quantidade",
        Tipo: "Numero",

        att: {
          editable: true,
          maxWidth: 75,
          cellStyle: {
            backgroundColor: "#e7fff3",
          },
        },
      },
      {
        Titulo: "Total",
        Campo: "total",
        Tipo: "Moeda",
        att: {
          maxWidth: 100,
          valueGetter: (e: any) =>
            Number(
              Number(e.data.precoVenda * e.data.quantidade) -
                Number(e.data.quantidade * e.data.vlrDesconto)
            ),
        },
      },
      {
        Titulo: "",
        Campo: "id",
        Tipo: "Texto",
        att: {
          cellRenderer: (e: ICellRendererParams) => (
            <ButtonGroup size={"20"} isAttached>
              <IconButton
                onClick={() => {
                  ModalDetalheProdutoRef.current?.Abrir(e.data);
                }}
                aria-label="Add to friends"
                icon={<EditIcon />}
              />
            </ButtonGroup>
          ),
          maxWidth: 50,
        },
      },
    ],
    []
  );
  const Radio = form?.watch("DadosRegistro.paginaOrigem");

  useEffect(() => {
    if (Radio === undefined) return;
    if (Radio === "P") {
      setTabs(TabPadrao);
    } else if (Radio === "PT") {
      var arr = TabPadrao;
      arr[2].ocultar = false;
      setTabs(arr);
    } else {
      setTabs(TabPadrao);
    }
  }, [Radio]);

  useHotkeys(
    "F1,F2,F3,F4,F5,F6,F7,F8,F9,F10",
    e => {
      e.preventDefault();
      for (let index = 0; index < 10; index++) {
        if (e.key === "F" + (index + 1)) {
          //Tipo Pedido
          TabRef?.current?.TabIndex(index);
        }
      }
      if (e.key === "F2") {
        setTimeout(() => {
          form.setFocus("DadosProdutoSelecionado.PesquisaDetalhada");
        }, 50);
      }
      if (e.key === "F4") {
        setTimeout(() => {
          form.setFocus("DadosRegistro.clienteId");
        }, 50);
      }
      return;
    },
    { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] }
  );

  const AdicionarItensGridPrincipal = useCallback(
    (NovosItens: Array<ItensPedidoType>) => {
      const MsgError: Array<string> = [];
      var DadosFiltrados = NovosItens.filter(e => e.quantidade > 0);

      DadosFiltrados.forEach(item => {
        if (Number(item.quantidade) <= 0) {
          MsgError.push(
            `Quantidade do item [${item.codigoProduto}] deve ser maior que zero`
          );
        }
        if (Number(item.precoVenda) <= 0) {
          MsgError.push(
            `Preço do item [${item.codigoProduto}] deve ser maior que zero`
          );
        }
      });
      if (MsgError.length > 0) {
        ToastErrorId("Erro ao adicionar itens", MsgError.join("<br/>"));
        return;
      }

      DadosFiltrados.map(item => {
        let rowData: Array<RowNode> = [];
        GridItensPrincipalRef.current?.api.forEachNode(node =>
          rowData.push(node)
        );
        var Filtros = rowData.filter(e => e.data.id === item.id);
        if (Filtros.length === 0) {
          return GridItensPrincipalRef.current?.api.applyTransaction({
            add: [{ ...item, selecionado: true }],
          });
        } else {
          return GridItensPrincipalRef.current?.api.applyTransaction({
            update: [
              {
                ...item,
                quantidade: Filtros[0].data.quantidade + item.quantidade,
                selecionado: true,
              },
            ],
          });
        }
      });
      form.setValue("DadosProdutoSelecionado", ItemPedidoPadrao);
    },
    [ToastErrorId, form]
  );

  return (
    <CGIModal
      id="vendas/pedido/copiar"
      ref={CGIModalRef}
      Titulo="Pedido Loja"
      TelaCheia={"full"}
      Confirmar={() => {}}
      Fechar={() => {
        form?.reset({});
      }}
    >
      <PedLojaContext.Provider
        value={{
          form,
          AreaClienteRef,
          AreaFiscalRef,
          AreaOutrosRef,
          AreaPagamentoRef,
          AreaProdutoRef,
          AreaTipoPedidoRef,
          AreaTransporteRef,
          AreaTrocaRef,
          GridItensPrincipalRef,
          GridIPagamentoRef,
          GridItensTrocaRef,
          TabRef,
          AdicionarItensGridPrincipal,
        }}
      >
        <CGIBox>
          <CGIBoxItem Col={6}>
            <CGITabs ref={TabRef} Tabs={Tabs} />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIBox>
              <CGIBoxItem Col={12}>
                <CGIDataTableSimples
                  ref={GridItensPrincipalRef}
                  Altura="50vh"
                  Colunas={ColConvert}
                  Data={Dados}
                  Pag={10}
                  Att={{
                    defaultColDef: {
                      flex: 1,
                      filter: false,
                      autoHeight: true,
                      cellStyle: {
                        "font-size": 10,
                      },
                    },
                    onCellEditingStopped: e => {
                      form?.setValue(
                        "DadosProdutoSelecionado.id",
                        e.node.data.id
                      );
                    },
                    onCellClicked: e => {
                      form?.setValue(
                        "DadosProdutoSelecionado.id",
                        e.node.data.id
                      );
                      if (e.column.getColId() === "selecionado") {
                        //CalcTotalizadores();
                      }
                    },
                  }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={6}>
                <PEDLojaAlterta />
              </CGIBoxItem>
              <CGIBoxItem Col={6}>
                <PEDLojaTotalizador />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
        </CGIBox>
        <AlterarProdutoGridPedidoLoja ref={ModalDetalheProdutoRef} />
      </PedLojaContext.Provider>
    </CGIModal>
  );
});

export { AlterarPedidoLoja };
export default PedLojaContext;
