import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useMemo } from "react";
import { CGIGraficoDadosType } from "../CGIGrafico";
import { TraducaoGrafico } from "./PTBR";

type CGIGraficoBarType = {
  Dados: CGIGraficoDadosType;
};

export function CGIGraficoBAR(props: CGIGraficoBarType) {
  const { Dados } = props;
  const {
    config: { exibirTooltip, exibirLegenda },
  } = Dados;
  return useMemo(() => {
    const options: Highcharts.Options = {
      accessibility: {
        enabled: false,
      },
      chart: {
        type: "column",
      },
      title: {
        text: Dados.config.titulo,
      },
      subtitle: {
        text: Dados.config.subtitulo,
      },
      lang: TraducaoGrafico,
      tooltip: {
        formatter: function () {
          var Titulo = this.series.name;
          var Valor = this.y?.FormatarReal();
          return `${Titulo}: <b>${Valor}</b>`;
        },
        enabled: exibirTooltip,
      },
      legend: {
        enabled: exibirLegenda,
      },

      xAxis: {
        categories: Dados.categorias,
      },
      series: Dados.series,
    };

    return (
      <>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </>
    );
  }, [Dados]);
}
