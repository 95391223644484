export function RetornarDadosUsuario<T>() {
  var DadosUsuario = {};
  var Dados = localStorage.getItem("dadosUsuario");
  if (Dados) {
    DadosUsuario = JSON.parse(Dados);
  }
  return DadosUsuario as T;
}

export function GuardarDadosUsuario(DadosUsuario: any) {
  localStorage.setItem("dadosUsuario", JSON.stringify(DadosUsuario));
}

export function DeletarDadosUsuario() {
  return localStorage.removeItem("dadosUsuario");
}
export function RetornarSeUsuarioTemAcessoTotal() {
  var DadosUsuario = RetornarDadosUsuario<any>();
  return DadosUsuario.acessoTotal;
}

export function RetornarSeUsuarioTemPemissaoPeloId(id: number) {
  var Permissoes = RetornarDadosUsuario<any>().permissoes;

  var Permissao = Permissoes.find((e: any) => e.id === id);
  if (Permissao) {
    var AcessoTotal = RetornarSeUsuarioTemAcessoTotal();

    if (AcessoTotal) {
      return true;
    }
    return Permissao.temPermissao;
  } else {
    return false;
  }
}
export function RetornarSeTelaEhFavoritaPeloLink(id: string) {
  var Favoritos = RetornarDadosUsuario<any>().menuFavoritos;

  var Favorito = Favoritos.menuprincipal.menu[0].itens.find(
    (e: any) => e.link === id
  );
  if (Favorito) {
    return true; //Permissao.temPermissao;
  } else {
    return false;
  }
}
export function RetornarSeUsuarioTemPemissaoPeloLink(link: string) {
  var Permissoes = RetornarDadosUsuario<any>().permissoes;

  var Permissao = Permissoes.find((e: any) => e.link === link);
  if (Permissao === undefined) {
    return false;
  }

  var AcessoTotal = RetornarSeUsuarioTemAcessoTotal();

  if (AcessoTotal) {
    return true;
  }

  if (Permissao) {
    return Permissao.temPermissao;
  } else {
    return false;
  }
}
export function RetornarPemissaoPeloLink(link: string) {
  var Permissoes = RetornarDadosUsuario<any>().permissoes;

  var Permissao = Permissoes.find((e: any) => e.link === link);
  return Permissao ? Permissao : null;
}

export function MontarTituloJanela(titulo: string) {
  var DadosUsuario = RetornarDadosUsuario<any>();
  return `${titulo} [${DadosUsuario.nomeEmpresa}] - ${DadosUsuario.nomeUsuario}`;
}
