import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { CGIBox } from "../Box/CGIBox";
import { CGIBoxItem } from "../Box/CGIBoxItens";
import { CGIButton } from "../Button/CGIButton";
import { CGIComboSimples } from "../Combos/CGIComboSimples";
import { CGIFileInput } from "../FileInput/CGIFileInput";
import { CGIInput } from "../Input/CGIInput";

type PropsCGIAnexoType = {
  multiple?: boolean;
  disabled?: boolean;
  hide?: boolean;
  tipoAnexo: string;
  ocultarConfirmar?: boolean;
};
type CGIAnexoType = {
  Anexos: {
    novoAnexo: any;
    tipoAnexoId: number;
    titulo: string;
    descricao: string;
    documento: number;
  };
};

export const CGIAnexo = (props: PropsCGIAnexoType) => {
  return (
    <CGIBox titulo="Anexos">
      <CGIBoxItem Col={5}>
        <CGIFileInput<CGIAnexoType>
          label="Incluir Novo Anexo"
          id={"Anexos.novoAnexo"}
          multiple={props.multiple}
          att={{
            isDisabled: props.disabled,
          }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIComboSimples<CGIAnexoType>
          label="Tipo de Anexo"
          id={"Anexos.tipoAnexoId"}
          Link={props.tipoAnexo}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={3}>
        <CGIInput<CGIAnexoType>
          label="Título"
          id={"Anexos.titulo"}
          placeholder="Título"
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIInput<CGIAnexoType>
          label="Descrição"
          id={"Anexos.descricao"}
          placeholder="Descrição"
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIInput<CGIAnexoType>
          label="Documento"
          id={"Anexos.documento"}
          placeholder="Documento"
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIButton
          label="Incluir Novo Anexo"
          att={{
            mt: 30,
          }}
        />
      </CGIBoxItem>
    </CGIBox>
  );
};
