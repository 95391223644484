import { forwardRef, useImperativeHandle, useContext, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import LoadingContext from "../../../Context/LoadingContext";
import { FormCtsReceberType } from "../../../Types/Financeiro/ContasReceber/CtsReceberTypes";
import { HttpCtsReceber } from "./Http";

export const GerarBoletoBancario = forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    Abrir() {
      CGIModalRef.current.Abrir();
    },
  }));
  const { setLoadingState } = useContext(LoadingContext);
  const { ToastSucesso } = useCGIToast();
  const CGIModalRef = useRef() as any;
  const form = useFormContext();

  const Confirmar = async (data: FormCtsReceberType, event: any) => {
    setLoadingState(true);

    HttpCtsReceber()
      .GerarBoletoBancario()
      .then((response: any) => {
        ToastSucesso("Comissão Alterada com sucesso", "OK!");
        props.CarregarDados();
        CGIModalRef.current.Fechar();
      })
      .catch((e) => {
        return ToastErro(e);
      })
      .finally(() => {
        setLoadingState(false);
      });
  };
  return (
    <CGIModal
      id="financeiro/ctsreceber/gerarboletobancario"
      ref={CGIModalRef}
      Titulo="Gerar Boleto Bancário"
      TelaCheia={"6xl"}
      Confirmar={Confirmar}
      Fechar={() => {
        form.reset();
      }}
    >
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIResumo Expandir CtsReceberId="id" ResumoCtsReceber />
        </CGIBoxItem>
      </CGIBox>
      <CGIBox>
        <CGIBoxItem Col={3}>
          <CGIInput<FormCtsReceberType>
            id={"Form.DadosRegistro.valor"}
            label={"Valor"}
            placeholder={""}
            validadores={{
              required: true,
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIDatePicker<FormCtsReceberType>
            id={"Form.DadosRegistro.dataVencimento"}
            label={"Data Vencimento"}
            validadores={{
              required: true,
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={5}>
          <CGIComboSimples<FormCtsReceberType>
            id={"Form.DadosRegistro.convenioPortadorId"}
            label={"Convênio Portador"}
            Link={"convenioportador"}
            validadores={{
              required: true,
            }}
          />
        </CGIBoxItem>
      </CGIBox>
    </CGIModal>
  );
});
function ToastErro(e: any): any {
  throw new Error("Function not implemented.");
}
