import { AxiosInstance } from "axios";
import { CGIHttp } from "../../Services/Axios";
const url = "anexo";
export function HttpAnexo() {
  async function RetornarResumosAnexo(Origem: string, Id: number) {
    return await (
      await new CGIHttp().conn.get(
        url + "/obter-resumo-anexo/" + Id + "/" + Origem
      )
    )?.data.data;
  }
  async function RetornarAnexos(Origem: string, Id: number) {
    return await (
      await new CGIHttp().conn.get(
        url + "/obter-anexos-registro/" + Id + "/" + Origem
      )
    )?.data.data;
  }
  async function ExibirConteudoAnexo(Id: number) {
    return await (
      await new CGIHttp().conn.get(url + "/exibir-conteudo-anexo/" + Id)
    )?.data.data;
  }

  return {
    RetornarResumosAnexo,
    RetornarAnexos,
    ExibirConteudoAnexo,
  };
}
