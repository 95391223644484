import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { DadosUsuario } from "../Types/Home/Login/LoginType";
import { DeletarDadosUsuario, RetornarDadosUsuario } from "./Util/Util";

//const baseURL = "https://localhost:44358";
const baseURL = process.env.REACT_APP_BASEURL; // "https://api.headthink.online";

var _DefaultAxios = null as AxiosInstance | any;
export class CGIHttp {
  conn = {} as AxiosInstance;
  constructor() {
    var Dados = RetornarDadosUsuario();
    if (_DefaultAxios === null) {
      this.conn = axios.create({
        baseURL: baseURL,
        headers: { Authorization: "Bearer " + Dados.token },
      });
      _DefaultAxios = this.conn;
    } else {
      this.conn = _DefaultAxios;
    }
  }
  instance() {
    return new CGIHttp().conn;
  }
  Force(Dados: DadosUsuario) {
    this.conn = axios.create({
      baseURL: baseURL,
      headers: { Authorization: "Bearer " + Dados.token },
    });
    _DefaultAxios = this.conn;
  }

  logout() {
    DeletarDadosUsuario();
    _DefaultAxios = null;
  }

  interceptor(navigate: any, Toast: any) {
    new CGIHttp().conn.interceptors.response.use(
      (response: AxiosResponse) => {
        return Promise.resolve(response);
      },
      (error: AxiosError) => {
        if (error.response?.status === 400) {
          let Resposta = error.response.data as any;
          var Msg = JSON.stringify(Resposta.message);
          Toast(400, Msg);
        }
        if (error.response?.status === 401) {
          navigate("/login", { replace: true });
          Toast(401, "Usuário não autorizado");
        }
        if (error.response?.status === 404) {
          let Resposta = error.response.data as any;
          Toast(error.response.status, Resposta.message);
        }
        if (error.response?.status === 412) {
          Toast(
            error.response.status,
            "Permissão vinculada não encontrada para a solicitação"
          );
        }
        if (error.response?.status === 409) {
          let Resposta = error.response.data as any;
          Toast(
            error.response.status,
            Resposta.message ? Resposta.message : Resposta
          );
        }
        if (error.response?.status === 500) {
          Toast("Rota não existe", "Rota nao existe no Banco de Dados");
        }
        if (error.code === "ERR_NETWORK") {
          navigate("/login", { replace: true });
          Toast("ERR_NETWORK", "Erro de Conexão - Sistema em Manutenção");
        }
        if (!error.response) {
          Toast("ERR_NETWORK", "verifique sua conexão com a internet");
        }
        return Promise.reject(error);
      }
    );
  }
}
