import { CGIHttp } from "../../../Services/Axios";
import {
  FormArquivoRemessaType,
  ListarPesquisaArquivoRemessaTypes,
  ListarArquivoRemessaItensTypes,
  ArquivoRemessaPesquisaGridTypes,
  ArquivoRemessaTypes,
} from "../../../Types/Financeiro/ArquivoRemessa/ArquivoRemessaTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpArquivoRemessa() {
  const url = "/arquivoremessa";

  async function RetornarDadosGridPesquisa(
    dados: ArquivoRemessaPesquisaGridTypes
  ): Promise<ListarArquivoRemessaItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-ArquivoRemessa", dados)
      .then((e) => e.data.data);
  }

  async function RetornarArquivosRemessa(
    DadosFiltros: ListarPesquisaArquivoRemessaTypes
  ): Promise<ListarArquivoRemessaItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(
        url + "/obter-arquivo-remessa",
        DadosFiltros
      )
    ).data.data;
  }

  async function RetornarDadosArquivoRemessa(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + `/alterar/${id}`)
    ).data.data) as ArquivoRemessaTypes;
  }

  async function RetornarDadosExclusao(Dados: ListarArquivoRemessaItensTypes) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + Dados.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }
  async function RetornarDadosVisualizarArquivoRemessa(
    DadosArquivoRemessa: FormArquivoRemessaType
  ) {
    return (await (
      await new CGIHttp().conn.get(
        url + "/visualizar/" + DadosArquivoRemessa.Form
      )
    ).data.data) as ArquivoRemessaTypes;
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir/" + Dados.id);
  }
  async function Alterar(DadosArquivoRemessa: FormArquivoRemessaType) {
    return await new CGIHttp().conn.post(
      url + "/alterar",
      DadosArquivoRemessa.Form
    );
  }
  async function Visualizar(DadosArquivoRemessa: FormArquivoRemessaType) {
    return await new CGIHttp().conn.post(
      url + "/visualizar",
      DadosArquivoRemessa.Form
    );
  }
  async function Incluir(DadosArquivoRemessa: FormArquivoRemessaType) {
    return await new CGIHttp().conn.post(
      url + "/incluir",
      DadosArquivoRemessa.Form
    );
  }
  return {
    RetornarDadosVisualizarArquivoRemessa,
    RetornarDadosArquivoRemessa,
    RetornarDadosGridPesquisa,
    RetornarArquivosRemessa,
    RetornarDadosExclusao,
    Visualizar,
    Excluir,
    Alterar,
    Incluir,
  };
}
