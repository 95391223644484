import { AxiosInstance } from "axios";
import { CGIHttp } from "../../Services/Axios";
import { CGIGrupoMetaTypes } from "./CGIGrupoMeta";
const url = "home";
export function HttpCGIGrupoMeta() {
  async function RetornarDados(Link: string) {
    let Obj = await new CGIHttp().conn.get(
      url + "/obter-grupometa-home/" + Link
    );

    return Obj?.data.data as Array<CGIGrupoMetaTypes>;
  }
  return {
    RetornarDados,
  };
}
