import { CGIHttp } from "../../../Services/Axios";
import {
  FormBoletoBancarioType,
  ListarPesquisaBoletoBancarioTypes,
  ListarBoletoBancarioItensTypes,
  BoletoBancarioPesquisaGridTypes,
  BoletoBancarioTypes,
  BoletoBancarioVisualizarTypes,
} from "../../../Types/Financeiro/BoletoBancario/BoletoBancarioTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpBoletoBancario() {
  const url = "/boletobancario";

  async function RetornarDadosGridPesquisa(
    dados: BoletoBancarioPesquisaGridTypes
  ): Promise<ListarBoletoBancarioItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-boleto-bancario", dados)
      .then((e) => e.data.data);
  }

  async function RetornarBoletoBancarios(
    DadosFiltros: ListarPesquisaBoletoBancarioTypes
  ): Promise<ListarBoletoBancarioItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(
        url + "/obter-boleto-bancario",
        DadosFiltros
      )
    ).data.data;
  }

  async function RetornarDadosBoletoBancario(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + `/alterar/${id}`)
    ).data.data) as FormBoletoBancarioType;
  }

  async function RetornarDadosExclusao(Dados: ListarBoletoBancarioItensTypes) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + Dados.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }
  async function RetornarDadosVisualizarBoletoBancario(
    DadosBoletoBancario: FormBoletoBancarioType
  ) {
    return (await (
      await new CGIHttp().conn.get(
        url + "/visualizar/" + DadosBoletoBancario.Form
      )
    ).data.data) as BoletoBancarioTypes;
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir/" + Dados.id);
  }
  async function Alterar(DadosBoletoBancario: FormBoletoBancarioType) {
    return await new CGIHttp().conn.post(
      url + "/alterar",
      DadosBoletoBancario.Form
    );
  }
  async function Visualizar(DadosBoletoBancario: FormBoletoBancarioType) {
    return await new CGIHttp().conn.post(
      url + "/visualizar",
      DadosBoletoBancario.Form
    );
  }
  async function Incluir(DadosBoletoBancario: FormBoletoBancarioType) {
    return await new CGIHttp().conn.post(
      url + "/incluir",
      DadosBoletoBancario.Form
    );
  }
  return {
    RetornarBoletoBancarios,
    RetornarDadosGridPesquisa,
    RetornarDadosExclusao,
    RetornarDadosVisualizarBoletoBancario,
    RetornarDadosBoletoBancario,
    Visualizar,
    Excluir,
    Alterar,
    Incluir,
  };
}
