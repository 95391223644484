import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Box, Drawer, DrawerContent, DrawerBody } from "@chakra-ui/react";
import { DadosUsuario, MenuElement } from "../../../Types/Home/Login/LoginType";
import { Temas } from "../../../Themes/Temas";
import { DrawerItens } from "./Drawer";
import { RetornarDadosUsuario } from "../../../Services/Util/Util";

export const CGIDrawer = forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    Abrir() {
      setAberto(!Aberto);
    },
    Fechar() {
      setAberto(!Aberto);
    },
  }));

  const [Aberto, setAberto] = useState<boolean>(false);
  const [LinkItems, setLinkItems] = useState<Array<MenuElement>>([]);
  const [LinkAdminItems, setLinkAdminItems] = useState<Array<MenuElement>>([]);
  const [LinkFavItems, setLinkFavItems] = useState<Array<MenuElement>>([]);
  const [LinkBIItems, setLinkBIItems] = useState<Array<MenuElement>>([]);

  const [DadosUsuario] = useState<DadosUsuario>(RetornarDadosUsuario());

  useEffect(() => {
    setLinkItems(DadosUsuario.menu.menuprincipal.menu);
    setLinkFavItems(DadosUsuario.menuFavoritos.menuprincipal.menu);
    setLinkAdminItems(DadosUsuario.menuAdmin.menuprincipal.menu);
    setLinkBIItems(DadosUsuario.menuBI.menuprincipal.menu);
  }, [
    DadosUsuario.menu.menuprincipal.menu,
    DadosUsuario.menuAdmin.menuprincipal.menu,
    DadosUsuario.menuBI.menuprincipal.menu,
    DadosUsuario.menuFavoritos.menuprincipal.menu,
  ]);

  return (
    <Box bg={Temas.Primaria}>
      <Drawer
        autoFocus={false}
        isOpen={Aberto}
        placement="left"
        onClose={() => setAberto(!Aberto)}
        returnFocusOnClose={false}
        onOverlayClick={() => setAberto(!Aberto)}
        size="sm"
      >
        <DrawerContent>
          <DrawerBody>
            <DrawerItens
              onClose={() => {
                setAberto(!Aberto);
              }}
              DadosUsuario={DadosUsuario}
              Links={LinkItems}
              Admin={LinkAdminItems}
              Favoritos={LinkFavItems}
              BI={LinkBIItems}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
});
