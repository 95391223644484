// export const ObjPedidosParaProducaoPadrao: FormPedidosParaProducaoType = {
//   Form: {
//     dadosRegistro: {
//       id: 0,
//       ncm: "",
//       percIPI: 0,
//       ex: 0,
//     },
//   },
// };
// export type PedidosParaProducaoTypes = {
//   dadosRegistro: {
//     id: number;
//     ncm: string;
//     percIPI: number;
//     ex: number;
//   };
// };
// export type FormPedidosParaProducaoType = {
//   Form: PedidosParaProducaoTypes;
// };

export const ObjListarPedidosParaProducaoPadrao: ListarPedidosParaProducaoTypes =
  {
    Pesquisa: {
      dataInicial: "",
      dataFinal: "",
      listaClientesId: "",
    },
    Itens: [],
  };

export type PedidosParaProducaoPesquisaGridTypes = {
  dataInicial?: string;
  dataFinal?: string;
  listaClientesId?: string;
};

export type ListarPedidosParaProducaoTypes = {
  Pesquisa: ListarPesquisaPedidosParaProducaoTypes;
  Itens: ListarPedidosParaProducaoItensTypes[];
};

export type ListarPesquisaPedidosParaProducaoTypes = {
  dataInicial: string;
  dataFinal: string;
  listaClientesId: string;
};

export type ListarPedidosParaProducaoItensTypes = {
  id: number;
  identificacao: string;
  dataCadastro: string;
  nomeCliente: string;
  totalPedido: number;
  dataPrevisaoEntrega: string;
  pedidoCliente: string;
  nomeVendedor: string;
  nomeStatus: string;
  qtdItens: number;
};
