export type FormSugestaoPedidoCompraTypes = {
  Form: {
    DadosRegistro: DadosRegistroTypes;
  };
};

export type DadosRegistroTypes = {
  id: number;
  empresaId: number;
  fornecedorId: number;
  tipoProdutoId: number;
  familiaId: number;
  grupoId: number;
  subgrupoId: number;
  linhaId: number;
  tipoSugestaoPedidoCompra: string;
  tipoSugestao: string;
  tipoSugestaoMediaDeVenda: boolean;
  tipoSugestaoEstoqueMinimo: boolean;
  tipoSugestaoMediaDeVendaQtdeMeses: number;
};

export type SugestaoPedidoCompraVisualizarTypes = {
  id: number;
  dataInclusao: string;
  identificacao: string;
  nomeFornecedor: string;
  nomeEmpresa: string;
  nomeUsuario: string;
  origem: string;
  qtdItens: number;
  valorTotal: number;
  tipoSugestaoPedidoCompra: string;
  tipoSugestao: string;
};

export const ObjListarSugestaoPedidoCompraPadrao: ListarSugestaoPedidoCompraTypes =
  {
    Pesquisa: {
      id: 0,
      dataInicialInclusao: "",
      dataFinalInclusao: "",
      empresaId: 0,
      fornecedorId: 0,
      ehNormal: false,
      ehIndicacaoTodos: false,
      ehIndicacaoManual: false,
      ehIndicacaoPedidos: false,
      valorDe: 0,
      valorAte: 0,
      statusId: 0,
      tipoSugestao: "",
      listaFornecedoresId: "",
      listaEmpresasId: "",
      listaStatusId: "",
    },
    Itens: [],
  };

export type ListarSugestaoPedidoCompraTypes = {
  Pesquisa: ListarPesquisaSugestaoPedidoCompraTypes;
  Itens: ListarSugestaoPedidoCompraItensTypes[];
};

export type ListarPesquisaSugestaoPedidoCompraTypes = {
  id: number;
  dataInicialInclusao: string;
  dataFinalInclusao: string;
  empresaId: number;
  fornecedorId: number;
  ehNormal: boolean;
  ehIndicacaoTodos: boolean;
  ehIndicacaoManual: boolean;
  ehIndicacaoPedidos: boolean;
  valorDe: number;
  valorAte: number;
  statusId: number;
  tipoSugestao: string;
  listaFornecedoresId: string;
  listaEmpresasId: string;
  listaStatusId: string;
};

export type ListarSugestaoPedidoCompraItensTypes = {
  produtoId: number;
  nomeStatus: string;
  id: number;
  dataCadastro: string;
  identificacao: string;
  nomeFornecedor: string;
  nomeEmpresa: string;
  estadoFornecedor: string;
  qtdItens: number;
  qtdItemIndicacao: number;
  vlrTotal: number;
  nomeTipoSugestao: string;
  codigoProduto: string;
  codigoFabricante: string;
  descricao: string;
  vlrUnit: number;
};
