import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../../Components/CheckBox/CGICheckBox";
import { CGIComboSimples } from "../../../../Components/Combos/CGIComboSimples";
import { CGIFileInput } from "../../../../Components/FileInput/CGIFileInput";
import { useCGIToast } from "../../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIModal } from "../../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../../Context/LoadingContext";
import { FormMarcaType } from "../../../../Types/Sistema/Classificacao/Marca/MarcaTypes";
import { HttpMarca } from "./Http";

type ImportarMarcaType = {
  CarregarDados: Function;
};

export const ImportarMarca = forwardRef((props: ImportarMarcaType, ref) => {
  //INICIO REF
  useImperativeHandle(ref, () => ({
    Abrir() {
      CGIModalRef.current.Abrir();
    },
    Importar(pEmpresa: any) {
      CGIModalRef.current.Abrir();

      HttpMarca()
        .RetornarDadosMarca(pEmpresa.id)
        .then((e) => {
          form.reset(e.data.data);
        });
    },
  }));
  //FINAL  REF

  //INICIO  HOOKS
  const CGIModalRef = useRef() as any;
  const form = useFormContext<FormMarcaType>();
  const { ToastErro, ToastSucesso } = useCGIToast();
  const { setLoadingState } = useContext(LoadingContext);

  //FINAL  HOOKS

  //INICIO CONST

  const RenderTabs: Array<CGITabItensTypes> = [
    {
      Titulo: "Importar Classificação",
      Component: AreaDadosMarca(),
    },
  ];
  //FINAL  CONST

  //INICO FUNC
  function AreaDadosMarca() {
    return (
      <CGIBox>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<FormMarcaType>
            id="Form.DadosRegistro.modeloImportacaoId"
            Link={""}
            label={"Modelo de Importação"}
            validadores={{
              required: "Modelo obrigatório",
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIFileInput<FormMarcaType>
            id="Form.DadosRegistro.nome"
            label="Arquivo"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIBox
            titulo="Detalhamento do Arquivo"
            popover="Detalhamento do Arquivo"
          >
            <CGIBoxItem Col={12}>
              <TableContainer>
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <Th>Sequencia</Th>
                      <Th>Nome</Th>
                      <Th>Observação</Th>
                    </Tr>
                  </Thead>
                </Table>
              </TableContainer>
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    );
  }

  const Confirmar = async (data: FormMarcaType, event: any) => {
    setLoadingState(true);
    let Dados = form.getValues();

    if (
      Dados.Form.DadosRegistro.id == null ||
      Dados.Form.DadosRegistro.id === undefined ||
      Dados.Form.DadosRegistro.id === 0
    ) {
      HttpMarca()
        .Incluir(Dados)
        .then((response: any) => {
          ToastSucesso("Registro gravado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
        })
        .catch((e) => {
          return ToastErro(e);
        })
        .finally(() => {
          setLoadingState(false);
        });
    } else {
      HttpMarca()
        .Importar(data)
        .then((data: any) => {
          ToastSucesso("Registro Alterado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
        })
        .catch((e) => {
          return;
        })
        .finally(() => {
          setLoadingState(false);
        });
    }
  };
  //FINAL FUNC

  //INICO JSX
  return (
    <CGIModal
      id="sistema/classificacao/importarmarca"
      ref={CGIModalRef}
      Titulo="Importar Classificação"
      TelaCheia={"6xl"}
      Confirmar={Confirmar}
      Fechar={() => {
        form.reset({});
      }}
    >
      <CGITabs Tabs={RenderTabs} />
    </CGIModal>
  );
  //FINAL JSX
});
