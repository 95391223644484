import { AddIcon } from "@chakra-ui/icons";
import { useCallbackRef } from "@chakra-ui/react";
import { useState, useRef, useContext, useCallback, useEffect } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGIDatePicker } from "../../../../Components/DatePicker/CGIDatePicker";
import { CGIComboAjax } from "../../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIListar } from "../../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../../Context/LoadingContext";
import { ModalBaseType } from "../../../../Types/Modal/ModalBaseType";
import {
  ListarNFEntreguesTypes,
  ListarPesquisaNFEntreguesTypes,
  ListarNFEntreguesItensTypes,
  ObjListarNFEntreguesPadrao,
} from "../../../../Types/Faturamento/NFSaida/NF Entregues/NFEntreguesTypes";
import { HttpNFEntregues } from "./Http";

export function ListarNFEntregues() {
  const AlterarNFEntreguesRef = useRef<ModalBaseType>();
  const VisualizarNFEntreguesRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarNFEntreguesTypes>({
    defaultValues: ObjListarNFEntreguesPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  //Final Hooks

  //------------------------

  //Inicio Consts
  const ListarMenu: Array<MenuTypes> = [
    // {
    //     Nome: "Novo NF Entregues",
    //     Permissao: "Faturamento/NFSaida/incluirNFEntregues",
    //     OnClick: () => {
    //         AlterarNFEntreguesRef.current?.Abrir();
    //     },
    // },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar Pedido",
      Permissao: "",
      OnClick: (e: any) => {
        VisualizarNFEntreguesRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Download XML",
      Permissao: "",
      OnClick: (e: any) => {
        AlterarNFEntreguesRef.current?.Alterar(e.id);
      },
    },
    {
      Nome: "Anexos",
      Permissao: "",
      OnClick: (e: any) => {
        CGIModalDeleteRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Imprimir",
      Permissao: "",
      OnClick: (e: any) => {
        CGIModalDeleteRef.current?.Abrir(e.id);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Incluída", cor: "green" },
    { nomeStatus: "Enviada", cor: "yellow" },
    { nomeStatus: "Autorizada", cor: "blue" },
    { nomeStatus: "Rejeitada", cor: "red" },
    { nomeStatus: "Cancelada", cor: "crimson" },
    { nomeStatus: "Inutilizada", cor: "lightblue" },
    { nomeStatus: "Devolvida", cor: "orange" },
    { nomeStatus: "Denegada", cor: "black" },
  ];

  const GridColunas: Array<CGICol<ListarNFEntreguesItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "identificacaoPedido",
      Titulo: "Pedido Interno",
      Tipo: "Texto",
      Ordernar: true,
    },
    {
      Campo: "pedidoCliente",
      Titulo: "Pedido Cliente",
      Tipo: "Texto",
      att: {
        maxWidth: 155,
      },
    },
    {
      Campo: "notaFiscal",
      Titulo: "NFe",
      Tipo: "Texto",
    },
    {
      Campo: "nomeCliente",
      Titulo: "Cliente",
      Tipo: "Texto",
      att: {
        minWidth: 250,
      },
    },
    {
      Campo: "nomeMotorista",
      Titulo: "Entregue Por",
      Tipo: "Texto",
    },
    {
      Campo: "nomeEquipamento",
      Titulo: "Equipamento",
      Tipo: "Texto",
      att: {
        maxWidth: 140,
      },
    },
    {
      Campo: "valorNF",
      Titulo: "Valor NFe",
      Tipo: "Moeda",
    },
    {
      Campo: "dataEntrega",
      Titulo: "Entrega",
      Tipo: "Data",
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: any) => MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          titulo: "Id",
          valor: "id",
        },
        {
          titulo: "Identificação",
          valor: "identificacaoPedido",
        },
        {
          titulo: "Valor NFe",
          valor: "valorNF",
        },
      ],
    },
  ];
  const Janelas = [{}];

  //Final Consts

  //------------------------

  //Inicio Function
  async function RetornarNFEntregues() {
    setLoadingState(true);
    var DadosFiltro = methods.getValues("Pesquisa");
    var data = await HttpNFEntregues()
      .RetornarNFEntregues(DadosFiltro)
      .finally(() => {
        setLoadingState(false);
      });
    methods.setValue("Itens", data);
  }

  async function Excluir(data: any) {
    await HttpNFEntregues()
      .Excluir(data)
      .then((e) => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  //Final Function

  //------------------------

  // Inicio JSX

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Filtros de Período" popover="Filtros de Período">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarNFEntreguesTypes>
                id={"Pesquisa.dataSaidaInicial"}
                label="Data Saída Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarNFEntreguesTypes>
                id={"Pesquisa.dataSaidaFinal"}
                label="Data Saída Final"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarNFEntreguesTypes>
                id={"Pesquisa.dataEntregaInicial"}
                label="Data Entrega Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarNFEntreguesTypes>
                id={"Pesquisa.dataEntregaFinal"}
                label="Data Entrega Final"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBoxItem Col={6}>
            <CGIInput<ListarNFEntreguesTypes>
              id="Pesquisa.identificacaoPedido"
              label="Pedido Interno"
              placeholder="Pedido Interno"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIInput<ListarNFEntreguesTypes>
              id="Pesquisa.pedidoCliente"
              label="Pedido Cliente"
              placeholder="Pedido Cliente"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboAjax<ListarNFEntreguesTypes>
              Multipla
              id="Pesquisa.ListaClientesId"
              label="Cliente"
              Link="cliente"
            />
          </CGIBoxItem>
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarNFEntreguesTypes>
            Multipla
            id="Pesquisa.ListaMotoristasId"
            label="Motorista"
            Link="motorista"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarNFEntreguesTypes>
            id="Pesquisa.notaFiscal"
            label="Nota Fiscal"
            placeholder="Nota Fiscal"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarNFEntreguesTypes>
            Multipla
            id="Pesquisa.ListaVeiculosId"
            label="Equipamento"
            Link="equipamento"
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="faturamento/nfsaida/listarnfentregues"
      Titulo="Listar NF Entregues"
      tipoGET={{
        url: "NFENTREGUES-LISTAR",
        setRetorno: (e) => {
          methods.setValue("Pesquisa", e);
        },
      }}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarNFEntregues)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
