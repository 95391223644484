import { AddIcon } from "@chakra-ui/icons";
import { useState, useRef, useContext } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGIComboSimples } from "../../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIListar } from "../../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../../Context/LoadingContext";
import { ModalBaseType } from "../../../../Types/Modal/ModalBaseType";
import {
  ListarCondicaoPagamentoItensTypes,
  ListarCondicaoPagamentoTypes,
  ObjListarCondicaoPagamentoPadrao,
} from "../../../../Types/Sistema/Classificacao/CondicaoPagamento/CondicaoPagamentoTypes";
import { DeleteTypes } from "../../../../Types/Util/DeleteTypes";

import { HttpCondicaoPagamento } from "./Http";
import { AlterarCondicaoPagamento } from "./_AlterarCondicaoPagamento";
import { VisualizarCondicaoPagamento } from "./_VisualizarCondicaoPagamento";

export function ListarCondicaoPagamento() {
  //Inicio Hooks
  const AlterarCondicaoPagamentoRef = useRef<ModalBaseType>();
  const VisualizarCondicaoPagamentoRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarCondicaoPagamentoTypes>({
    defaultValues: ObjListarCondicaoPagamentoPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });
  //Final Hooks

  //------------------------

  //Inicio Consts
  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Nova Condição de Pagamento",
      Permissao: "sistema/classificacao/incluir-condicao-pagamento",
      OnClick: () => {
        AlterarCondicaoPagamentoRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "sistema/classificacao/visualizar-condicao-pagamento",
      OnClick: (e: ListarCondicaoPagamentoItensTypes) => {
        VisualizarCondicaoPagamentoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Alterar",
      Permissao: "sistema/classificacao/alterar-condicao-pagamento",
      OnClick: (e: ListarCondicaoPagamentoItensTypes) => {
        AlterarCondicaoPagamentoRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/classificacao/excluir-condicao-pagamento",
      OnClick: (e: ListarCondicaoPagamentoItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarCondicaoPagamentoItensTypes>> = [
    {
      Campo: "ativo",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: [
        {
          nomeStatus: "true",
          cor: "green",
        },
        {
          nomeStatus: "false",
          cor: "red",
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 100,
      },
    },
    {
      Campo: "nome",
      Titulo: "Nome",
      Tipo: "Texto",
    },
    {
      Campo: "nomeTipoDocumento",
      Titulo: "Tipo Doc.",
      Tipo: "Texto",
    },
    {
      Campo: "nomeFormaPagamento",
      Titulo: "Form. Pag.",
      Tipo: "Texto",
    },
    {
      Campo: "nomeMeioPagamento",
      Titulo: "Meio Pagamento",
      Tipo: "Texto",
    },
    {
      Campo: "codigoExterno",
      Titulo: "Definição",
      Tipo: "Texto",
    },
    {
      Campo: "resumoCartao",
      Titulo: "Resumo C. Cred.",
      Tipo: "Texto",
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarCondicaoPagamentoItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          titulo: "Id",
          valor: "id",
        },
        {
          titulo: "Nome",
          valor: "nome",
        },
        {
          titulo: "Tipo Doc.",
          valor: "nomeTipoDocumento",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Cond. de Pagamento"
          CarregarDados={methods.handleSubmit(RetornarCondicoesPagamento)}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarCondicaoPagamento
          CarregarDados={methods.handleSubmit(RetornarCondicoesPagamento)}
          ref={AlterarCondicaoPagamentoRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarCondicaoPagamento
          CarregarDados={methods.handleSubmit(RetornarCondicoesPagamento)}
          ref={VisualizarCondicaoPagamentoRef}
        />
      ),
    },
  ];

  //Final Consts

  //------------------------

  //Inicio Function

  async function RetornarCondicoesPagamento() {
    setLoadingState(true);
    const DadosFiltro = methods.getValues("Pesquisa");
    var data = await HttpCondicaoPagamento()
      .RetornarCondicoesPagamento(DadosFiltro)
      .finally(() => {
        setLoadingState(false);
      });
    methods.setValue("Itens", data);
  }

  async function RetornarDadosExclusao(
    data: ListarCondicaoPagamentoItensTypes
  ) {
    return await HttpCondicaoPagamento().RetornarDadosExclusao(data);
  }
  async function Excluir(data: DeleteTypes) {
    await HttpCondicaoPagamento()
      .Excluir(data)
      .then((e) => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  //Final Function

  //------------------------

  // Inicio JSX

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarCondicaoPagamentoTypes>
            id="Pesquisa.nome"
            label="Nome"
            placeholder="Nome"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarCondicaoPagamentoTypes>
            Link={"simnaoambos"}
            label={"Ativo"}
            id={"Pesquisa.ativoId"}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <>
      <CGIListar
        id="sistema/classificacao/listar"
        Titulo="Condição de Pagamento"
        Janelas={Janelas}
      >
        <FormProvider {...methods}>
          <CGIDataTable
            LimparFiltros={() => methods.reset()}
            Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
            Pesquisar={methods.handleSubmit(RetornarCondicoesPagamento)}
            Filtro={Filtros()}
            Data={GridItens}
            Colunas={GridColunas}
            Legenda={Legendas}
          />
        </FormProvider>
      </CGIListar>
    </>
  );
  // Final JSX
}
