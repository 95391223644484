import { CGIHttp } from "../../Services/Axios";

const url = "/combo";
export function HttpCombo() {
  async function RetornarDadosAJAX(Link: string, Busca: string) {
    return await (
      await new CGIHttp().conn.get(url + "/" + Link + "/" + Busca)
    )?.data.data;
  }
  async function RetornarDados(Link: string) {
    return await (
      await new CGIHttp().conn.get(url + "/" + Link)
    )?.data.data;
  }
  return {
    RetornarDados,
    RetornarDadosAJAX,
  };
}
