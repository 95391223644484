import { CGIHttp } from "../../../Services/Axios";
import { VisualizarPedidoPorProdutoPesquisaTypes } from "../../../Types/Vendas/Pedido/VisualizarPedidoPorProduto";
import {
  ListarProdutoTypes,
  ProdutoTypes,
} from "../../../Types/Sistema/Produto/ProdutoTypes";
import {
  AlterarPrecoNasEmpresasItensTypes,
  AlterarPrecoNasEmpresasPesquisaTypes,
} from "../../../Types/Cadastros/Produto/AlterarPrecoNasEmpresasType";
import {
  ListarPesquisaProdutosNaoCadastradosTypes,
  ListarProdutosNaoCadastradosItensTypes,
} from "../../../Types/Sistema/Produto/ProdutosNaoCadastradosTypes";
import { FormAlterarNCMEmLoteType } from "../../../Types/Sistema/Produto/AlterarNCMEmLoteTypes";
import { FormCurvaABCType } from "../../../Types/Sistema/Produto/CurvaABCTypes";
import {
  AlterarNCMCodigoCestGeralTypes,
  FormAlterarNCMCodigoCestGeralType,
} from "../../../Types/Sistema/Produto/AlterarNCMCodigoCestGeralTypes";
import { ListarAlocacaoItensEmpresasTypes } from "../../../Types/Almoxarifado/Alocacao/AlocacaoTypes";

import {
  ConsultarCustoMedioItensMovEstoqueTypes,
  ConsultarCustoMedioItensNFEntradaTypes,
  ConsultarCustoMedioItensPedidoEntradaTypes,
  PesquisaConsultarCustoMedioTypes,
} from "../../../Types/Sistema/Produto/ConsultarCustoMedioTypes";
import {
  TotaisInternosItemTypes,
  TotaisInternosTypes,
} from "../../../Types/Sistema/Produto/TotaisInternosTypes";
import { CalculoPrecoVendaItensTypes } from "../../../Types/Sistema/Produto/CalculoPrecoVendaTypes";

const url = "/produto";

export function HttpProduto() {
  async function RetornarProdutos(DadosFiltros: ListarProdutoTypes) {
    return await new CGIHttp().conn.post(url + "/obter-produtos", {
      descricao: DadosFiltros.Descricao,
    });
  }

  async function RetornarDadosProduto(id: number) {
    return await new CGIHttp().conn.get(url + `/alterar/${id}`);
  }

  async function Incluir(DadosProduto: ProdutoTypes) {
    return await new CGIHttp().conn.post(url + "/incluir", DadosProduto);
  }

  async function Alterar(DadosProduto: ProdutoTypes) {
    return await new CGIHttp().conn.post(url + "/alterar", DadosProduto);
  }

  async function Visualizar(id: number) {
    return await new CGIHttp().conn.get(url + "/visualizar/" + id);
  }

  async function RetornarDadosExclusao(DadosProduto: ProdutoTypes) {
    return await new CGIHttp().conn.get(url + "/excluir/" + DadosProduto.id);
  }

  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir", Dados);
  }

  async function Listar() {
    return await (
      await new CGIHttp().conn.get(url + "/listar")
    ).data.data;
  }

  async function RetornarPrecoEmpresasParaAtualizar(
    pId: number
  ): Promise<AlterarPrecoNasEmpresasItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(
        url + "/obter-preco-empresas-para-atualizar/" + pId
      )
    ).data.data;
  }

  async function RetornarProdutosNaoCadastrados(
    DadosFiltros: ListarPesquisaProdutosNaoCadastradosTypes
  ) {
    return (await (
      await new CGIHttp().conn.post(
        url + "/obter-produtos-nao-cadastrados",
        DadosFiltros
      )
    ).data.data) as ListarProdutosNaoCadastradosItensTypes[];
  }

  async function AlterarPrecosNasEmpresas(
    pDadosGrid: AlterarPrecoNasEmpresasItensTypes[]
  ) {
    return await new CGIHttp().conn
      .post(url + "/alterarprecoempresas", { itens: pDadosGrid })
      .then(res => res.data.data);
  }

  async function CurvaABC(pCurvaABC: FormCurvaABCType) {
    return await new CGIHttp().conn.post(
      url + "/curvaabc",
      pCurvaABC.Form.DadosRegistro
    );
  }

  async function ObterCalculoPreco(pDadosGrid: any) {
    return await new CGIHttp().conn
      .post(url + "/obter-calculo-preco", { itens: pDadosGrid })
      .then(res => res.data);
  }

  async function AlterarNCMCodigoCestGeral(
    DadosNCM: FormAlterarNCMCodigoCestGeralType
  ) {
    return await new CGIHttp().conn.post(
      url + "/alterar-ncm-codigo-cest-geral",
      DadosNCM.Form.DadosRegistro
    );
  }
  async function AlterarNCMEmLote(pDados: FormAlterarNCMEmLoteType) {
    return await new CGIHttp().conn.post(
      url + "/alterar-ncm-lote",
      pDados.Form.DadosRegistro
    );
  }

  async function RetornarQtdEstoqueEmpresasAtivasParaAlocacao(
    pId: number
  ): Promise<ListarAlocacaoItensEmpresasTypes[]> {
    return await (
      await new CGIHttp().conn.post(
        url + "/obter-quantidade-estoque-empresas-ativas-para-alocacao/" + pId
      )
    ).data.data;
  }

  async function ConsultarCustoMedioMovEstoque(
    DadosFiltros: PesquisaConsultarCustoMedioTypes
  ) {
    return await new CGIHttp().conn
      .post(url + "/obter-produtos-custo-medio-mov-estoque", DadosFiltros)
      .then(e => e.data.data);
  }
  async function ConsultarCustoMedioPedidoEntrada(
    DadosFiltros: PesquisaConsultarCustoMedioTypes
  ) {
    return await new CGIHttp().conn
      .post(url + "/obter-produtos-custo-medio-pedido-entrada", DadosFiltros)
      .then(e => e.data.data);
  }
  async function ConsultarCustoMedioNFEntrada(
    DadosFiltros: PesquisaConsultarCustoMedioTypes
  ) {
    return await new CGIHttp().conn
      .post(url + "/obter-produtos-custo-medio-nf-entrada", DadosFiltros)
      .then(e => e.data.data);
  }
  async function RetornarTotaisInternos(
    pDados: TotaisInternosTypes
  ): Promise<TotaisInternosItemTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-totais-interno/", pDados.Pesquisa)
      .then(e => e.data.data);
  }

  return {
    RetornarProdutos,
    RetornarDadosProduto,
    Incluir,
    Alterar,
    Visualizar,
    RetornarDadosExclusao,
    Excluir,
    Listar,
    RetornarPrecoEmpresasParaAtualizar,
    RetornarProdutosNaoCadastrados,
    AlterarPrecosNasEmpresas,
    CurvaABC,
    AlterarNCMCodigoCestGeral,
    AlterarNCMEmLote,
    RetornarQtdEstoqueEmpresasAtivasParaAlocacao,
    ConsultarCustoMedioMovEstoque,
    ConsultarCustoMedioPedidoEntrada,
    ConsultarCustoMedioNFEntrada,
    ObterCalculoPreco,
    RetornarTotaisInternos,
  };
}
