import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarNFSaidaItensTypes,
  ListarNFSaidaTypes,
  ObjListarNFSaidaPadrao,
} from "../../../Types/Faturamento/NFSaida/NFSaidaTypes";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpNFSaida } from "./Http";
import { AlterarNFSaida } from "./_AlterarNFSaida";
import { VisualizarNFSaida } from "./_VisualizarNFSaida";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIRadioButton } from "../../../Components/RadioButton/CGIRadioButton";
import { CGIHistorico } from "../../../Components/Historico/CGIHistorico";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIVisualizarAnexo } from "../../../Components/Anexo/CGIVisualizarAnexo";
import { AlterarNumeroNFSaida } from "./_AlterarNumeroNFSaida";
import { AlterarTecnologiaNFSaida } from "./_AlterarTecnologiaNFSaida";
import { AlterarStatusNFSaida } from "./_AlterarStatusNFSaida";

export function ListarNFSaida() {
  const AlterarNFSaidaRef = useRef<ModalBaseType>();
  const VisualizarNFSaidaRef = useRef<ModalBaseType>();
  const CGIHistoricoRef = useRef<ModalBaseType>();
  const CGIAnexoRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const AlterarNumeroNFSaidaRef = useRef<ModalBaseType>();
  const AlterarTecnologiaNFSaidaRef = useRef<ModalBaseType>();
  const AlterarStatusNFSaidaRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarNFSaidaTypes>({
    defaultValues: ObjListarNFSaidaPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarNFSaidas = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpNFSaida().RetornarNFSaidas(DadosFiltros);
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarNFSaidaItensTypes) => {
      return await HttpNFSaida().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpNFSaida()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo NFSaida",
      Permissao: "faturamento/NFSaida/incluir",
      OnClick: () => {
        AlterarNFSaidaRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Alterar",
      Permissao: "faturamento/NFSaida/alterar",
      OnClick: (e: ListarNFSaidaItensTypes) => {
        AlterarNFSaidaRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "faturamento/NFSaida/excluir",
      OnClick: (e: ListarNFSaidaItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Histórico",
      Permissao: "faturamento/nfsaida/historiconfsaida",
      OnClick: (e: ListarNFSaidaItensTypes) => {
        CGIHistoricoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Anexo",
      Permissao: "sistema/anexo/visualizaranexosnfsaida",
      OnClick: (e: any) => {
        CGIAnexoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Alterar Número NF e Série",
      Permissao: "faturamento/nfsaida/alterarnumeronfeserie",
      OnClick: (e: any) => {
        AlterarNumeroNFSaidaRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Alterar Tecnologia",
      Permissao: "faturamento/nfsaida/alterartecnologianfsaida",
      OnClick: (e: any) => {
        AlterarTecnologiaNFSaidaRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Alterar Status",
      Permissao: "faturamento/nfsaida/alterarstatus",
      OnClick: (e: any) => {
        AlterarStatusNFSaidaRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Incluída", cor: "green" },
    { nomeStatus: "Enviada", cor: "orange" },
    { nomeStatus: "Autorizada", cor: "blue" },
    { nomeStatus: "Rejeitada", cor: "red" },
    { nomeStatus: "Cancelada", cor: "brown" },
    { nomeStatus: "Inutilizada", cor: "lightblue" },
    { nomeStatus: "Devolvida", cor: "darkorange" },
    { nomeStatus: "Denegada", cor: "black" },
  ];

  const GridColunas: Array<CGICol<ListarNFSaidaItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "nomeEmpresa",
      Titulo: "Empresa",
      Tipo: "Texto",
      att: { maxWidth: 130 },
    },
    {
      Campo: "numeroNF",
      Titulo: "N° NF",
      Tipo: "Texto",
      att: { maxWidth: 100 },
    },
    {
      Campo: "tipoDocFiscal",
      Titulo: "Tipo",
      Tipo: "Texto",
      att: { maxWidth: 100 },
    },
    {
      Campo: "dataCadastro",
      Titulo: "Data NF",
      Tipo: "Data",
      att: { maxWidth: 130 },
    },
    {
      Campo: "identificacaoPedido",
      Titulo: "Ped. Cli./ Transf.",
      Tipo: "Texto",
      att: { maxWidth: 130 },
    },
    {
      Campo: "nomeColaborador",
      Titulo: "Colaborador",
      Tipo: "Texto",
      att: { maxWidth: 120 },
    },
    {
      Campo: "nomeVendedor",
      Titulo: "Vendedor",
      Tipo: "Texto",
    },
    {
      Campo: "naturezaOperacao",
      Titulo: "Natureza Operação",
      Tipo: "Texto",
      att: { maxWidth: 150 },
    },
    {
      Campo: "valorNF",
      Titulo: "Valor",
      Tipo: "Texto",
      att: { maxWidth: 100 },
    },
    {
      Campo: "nomeUsuario",
      Titulo: "Usuário",
      Tipo: "Texto",
      att: { maxWidth: 130 },
    },
    {
      Campo: "dataSaida",
      Titulo: "Data/Hora Saída",
      Tipo: "Data",
      att: { maxWidth: 160 },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarNFSaidaItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          valor: "nomeEmpresa",
          titulo: "Empresa",
        },
        {
          valor: "tipoDocFiscal",
          titulo: "Tipo",
        },
        {
          valor: "dataCadastro",
          titulo: "Data NF",
        },
        {
          valor: "identificacaoPedido",
          titulo: "Ped. Cli./ Transf.",
        },
        {
          valor: "nomeColaborador",
          titulo: "Colaborador",
        },
        {
          valor: "nomeVendedor",
          titulo: "Vendedor",
        },
        {
          valor: "naturezaOperacao",
          titulo: "Natureza Operação",
        },
        {
          valor: "valorNF",
          titulo: "Valor",
        },
        {
          valor: "nomeUsuario",
          titulo: "Usuário",
        },
        {
          valor: "dataSaida",
          titulo: "Data/Hora Saída",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir NFSaida"
          CarregarDados={RetornarNFSaidas}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarNFSaida
          CarregarDados={methods.handleSubmit(RetornarNFSaidas)}
          ref={AlterarNFSaidaRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarNFSaida
          CarregarDados={methods.handleSubmit(RetornarNFSaidas)}
          ref={VisualizarNFSaidaRef}
        />
      ),
    },
    {
      Componente: (
        <CGIVisualizarAnexo
          ref={CGIAnexoRef}
          link={"sistema/anexo/visualizaranexosnfsaida"}
          origem={"NFSAIDA"}
          titulo={"Anexos NF. Saída"}
        />
      ),
    },
    {
      Componente: (
        <CGIHistorico
          tipo="TimeLine"
          titulo="Histórico de NFSaída"
          ref={CGIHistoricoRef}
          link={"faturamento/nfsaida/historiconfsaida"}
          origem={"nfsaida"}
        />
      ),
    },
    {
      Componente: (
        <AlterarNumeroNFSaida
          CarregarDados={methods.handleSubmit(RetornarNFSaidas)}
          ref={AlterarNumeroNFSaidaRef}
        />
      ),
    },
    {
      Componente: (
        <AlterarTecnologiaNFSaida
          CarregarDados={methods.handleSubmit(RetornarNFSaidas)}
          ref={AlterarTecnologiaNFSaidaRef}
        />
      ),
    },
    {
      Componente: (
        <AlterarStatusNFSaida
          CarregarDados={methods.handleSubmit(RetornarNFSaidas)}
          ref={AlterarStatusNFSaidaRef}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIBox titulo="Filtros de Período">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarNFSaidaTypes>
                id="Pesquisa.dataInicial"
                label="Período - Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarNFSaidaTypes>
                id="Pesquisa.dataFinal"
                label="Período - Final"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIRadioButton<ListarNFSaidaTypes>
                id="Pesquisa.tipoPeriodoData"
                label="Tipo"
                row={"row"}
                values={[
                  {
                    label: "Dt. Emissão",
                    valor: "E",
                  },
                  {
                    label: "Dt. Autorização",
                    valor: "A",
                  },
                ]}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={8}>
          <CGIBox>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarNFSaidaTypes>
                id="Pesquisa.listaEmpresasId"
                label="Empresa"
                Link={"empresa"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={8}>
              <CGIComboAjax<ListarNFSaidaTypes>
                id="Pesquisa.listaColaboradoresId"
                label="Colaborador - Destinatário"
                Link={"cliente"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarNFSaidaTypes>
                id="Pesquisa.listaStatusId"
                label="Status NF"
                Link={"statusnfsaida"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarNFSaidaTypes>
                id="Pesquisa.listaNaturezaOperacaoId"
                label="Natureza Operação"
                Link={"naturezadeoperacao"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIInput<ListarNFSaidaTypes>
                id="Pesquisa.numeroNF"
                label="Nota Fiscal"
                placeholder={"0"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples<ListarNFSaidaTypes>
            id="Pesquisa.ocultarDevolvidas"
            label="Ocultar NF's Devolvidas"
            Link={"simnaoambos"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples<ListarNFSaidaTypes>
            id="Pesquisa.entradaSaidaId"
            label="Entrada \ Saída"
            Link={"entradasaidaambos"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIComboAjax<ListarNFSaidaTypes>
            id="Pesquisa.listaProdutosId"
            label="Produto"
            Link={"produto"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples<ListarNFSaidaTypes>
            id="Pesquisa.canhotoPendente"
            label="Canhoto Pendente"
            Link={"simnaoambos"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarNFSaidaTypes>
            id="Pesquisa.codigoProduto"
            label="Código do produto"
            placeholder={"Código do produto"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarNFSaidaTypes>
            id="Pesquisa.listaEstadosId"
            label="Estado"
            Link={"estado"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarNFSaidaTypes>
            id="Pesquisa.listaVendedoresId"
            label="Vendedor"
            Link={"vendedor"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarNFSaidaTypes>
            id="Pesquisa.listaCondicaoPagamentoId"
            label="Cond. Pagamento"
            Link={"condicaodepagamento"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarNFSaidaTypes>
            id="Pesquisa.listaRetornoId"
            label="Retorno SEFAZ"
            Link={"retornosefaz"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarNFSaidaTypes>
            id="Pesquisa.listaTipoDocumentoEletronicoId"
            label="Tipo Doc. Eletrônico"
            Link={"tipodocumento"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples<ListarNFSaidaTypes>
            id="Pesquisa.entregaPendenteId"
            label="Entrega Pendente"
            Link={"simnaoambos"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarNFSaidaTypes>
            id="Pesquisa.listaClassesId"
            label="Classe"
            Link={"classe"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIComboSimples<ListarNFSaidaTypes>
            id="Pesquisa.listaTransportadorasId"
            label="Trasportadora"
            Link={"transportadora"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGICheckBox<ListarNFSaidaTypes>
            id="Pesquisa.somenteNFVenda"
            label="Somente NF's de Venda"
            tooltip=""
            att={{
              style: { marginTop: 30 },
            }}
            attLabel={{
              style: { marginTop: 30 },
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarNFSaidaTypes>
            id="Pesquisa.listaFormaPagamentoId"
            label="Forma de Pagamento"
            Link={"formapagamento"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples<ListarNFSaidaTypes>
            id="Pesquisa.notasConferidasId"
            label="Notas Conferidas"
            Link={"simnaoambos"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarNFSaidaTypes>
            id="Pesquisa.pedidoCliente"
            label="Pedido do Cliente (OC)"
            placeholder={"Pedido do Cliente (OC)"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarNFSaidaTypes>
            id="Pesquisa.cFOP"
            label="CFOP's"
            placeholder={"CFOP's"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={5}>
          <CGIComboSimples<ListarNFSaidaTypes>
            id="Pesquisa.listaMotoristasId"
            label="Motorista"
            Link={"motorista"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={5}>
          <CGIComboSimples<ListarNFSaidaTypes>
            id="Pesquisa.listaLocalEntregaId"
            label="Local Entrega"
            Link={"localentrega"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGICheckBox<ListarNFSaidaTypes>
            id="Pesquisa.somenteNFFinanceiroControlado"
            label="Somente NF Financeiro Separado (VP)"
            tooltip=""
            att={{
              style: { marginTop: 30 },
            }}
            attLabel={{
              style: { marginTop: 30 },
            }}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="faturamento/nfsaida/listar"
      Titulo="Listar Notas Fiscais Emitidas"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarNFSaidas)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
