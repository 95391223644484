import { AddIcon } from "@chakra-ui/icons";
import { Box } from "framer-motion";
import { useState, useRef, useContext, useCallback, useEffect } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { Link, useLocation, useParams } from "react-router-dom";
import { CGIAnexo } from "../../../Components/Anexo/CGIAnexo";
import { CGIVisualizarAnexo } from "../../../Components/Anexo/CGIVisualizarAnexo";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIHistorico } from "../../../Components/Historico/CGIHistorico";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { FormContext } from "../../../Context/FormConxtext";
import LoadingContext from "../../../Context/LoadingContext";
import { RetornarParamentroBool } from "../../../Services/Util/Util";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { AlterarNCMCodigoCestGeralTypes } from "../../../Types/Sistema/Produto/AlterarNCMCodigoCestGeralTypes";
import { FormCalculoPrecoVendaType } from "../../../Types/Sistema/Produto/CalculoPrecoVendaTypes";
import {
  ListarProdutoTypes,
  ProdutoTypes,
} from "../../../Types/Sistema/Produto/ProdutoTypes";
import { HttpProduto } from "./Http";
import { ConsultarCustoMedio } from "./_ConsultarCustoMedio";
import { AlterarNCMCodigoCestGeral } from "./_AlterarNCMCodigoCestGeral";
import { AlterarNCMEmLote } from "./_AlterarNCMEmLote";
import { AlterarProduto } from "./_AlterarProduto";
import { TotaisInternos } from "./_TotaisInternos";
import { CurvaABC } from "./_CurvaABC";
import { CalculoPrecoVenda } from "./_CalculoPrecoVenda";

export function ListarProduto() {
  //Consts

  let [Dados, setDados] = useState([]);
  const AlterarProdutoRef = useRef() as any;
  const AlterarNCMEmLoteRef = useRef() as any;
  const AlterarNCMCESTRef = useRef<ModalBaseType>();
  const TotaisInternosRef = useRef<ModalBaseType>();

  const ConsultarCustoMedioRef = useRef() as any;
  const methods = useForm();
  const CGIHistoricoRef = useRef() as any;
  const CGIAnexoRef = useRef<ModalBaseType>(null);
  const CurvaABCRef = useRef<ModalBaseType>(null);
  const CalculoPrecoRef = useRef<ModalBaseType>(null);
  const { setLoadingState } = useContext(LoadingContext);

  //Menus
  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo Produto",
      Permissao: "sistema/produto/incluir",
      OnClick: () => {
        AlterarProdutoRef.current.Abrir();
      },
    },
    {
      Nome: "Novo Serviço",
      Permissao: "sistema/produto/incluirservico",
      OnClick: () => {
        AlterarProdutoRef.current.Abrir();
      },
    },
    {
      Nome: "Importar via Arquivo",
      Permissao: "sistema/produto/importararquivo",
      OnClick: () => {
        AlterarProdutoRef.current.Abrir();
      },
    },
  ];

  const Janelas = [
    {
      Componente: (
        <AlterarProduto
          CarregarDados={methods.handleSubmit(Pesquisar)}
          ref={AlterarProdutoRef}
        />
      ),
    },
    {
      Componente: (
        <CGIHistorico
          tipo="TimeLine"
          titulo="Histórico do Produto"
          ref={CGIHistoricoRef}
          link={"sistema/produto/visualizarhistoricoproduto"}
          origem={"usuario"}
        />
      ),
    },
    {
      Componente: (
        <CGIVisualizarAnexo
          ref={CGIAnexoRef}
          link={"sistema/anexo/visualizaranexosprodutos"}
          origem={"PRODUTO"}
          titulo={"Anexos Produtos"}
        />
      ),
    },
    {
      Componente: (
        <AlterarNCMCodigoCestGeral
          CarregarDados={methods.handleSubmit(RetornarProdutos)}
          ref={AlterarNCMCESTRef}
        />
      ),
    },
    {
      Componente: (
        <CurvaABC
          CarregarDados={methods.handleSubmit(RetornarProdutos)}
          ref={CurvaABCRef}
        />
      ),
    },
    {
      Componente: (
        <AlterarNCMEmLote
          CarregarDados={methods.handleSubmit(Pesquisar)}
          ref={AlterarNCMEmLoteRef}
        />
      ),
    },
    {
      Componente: (
        <TotaisInternos
          CarregarDados={methods.handleSubmit(Pesquisar)}
          ref={TotaisInternosRef}
        />
      ),
    },
    {
      Componente: (
        <ConsultarCustoMedio
          CarregarDados={methods.handleSubmit(RetornarProdutos)}
          ref={ConsultarCustoMedioRef}
        />
      ),
    },
    {
      Componente: (
        <TotaisInternos
          CarregarDados={methods.handleSubmit(Pesquisar)}
          ref={TotaisInternosRef}
        />
      ),
    },
    {
      Componente: (
        <CalculoPrecoVenda
          CarregarDados={methods.handleSubmit(RetornarProdutos)}
          ref={CalculoPrecoRef}
        />
      ),
    },
    {
      Componente: (
        <CurvaABC
          CarregarDados={methods.handleSubmit(RetornarProdutos)}
          ref={CurvaABCRef}
        />
      ),
    },
    {
      Componente: (
        <AlterarNCMEmLote
          CarregarDados={methods.handleSubmit(Pesquisar)}
          ref={AlterarNCMEmLoteRef}
        />
      ),
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "sistema/produto/visualizar",
      OnClick: (e: any) => {
        AlterarProdutoRef.current.Visualizar(e);
      },
    },
    {
      Nome: "Alterar",
      Permissao: "sistema/produto/alterar",
      OnClick: (e: any) => {
        AlterarProdutoRef.current.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/produto/excluir",
      OnClick: (e: any) => {
        AlterarProdutoRef.current.excluir(e);
      },
    },
    {
      Nome: "Histórico",
      Permissao: "sistema/produto/visualizarhistoricoproduto",
      OnClick: (e: any) => {
        CGIHistoricoRef.current.Abrir(e);
      },
    },
    {
      Nome: "Anexo",
      Permissao: "sistema/produto/visualizarhistoricoproduto",
      OnClick: (e: any) => {
        CGIAnexoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Calculo Preço",
      Permissao: "sistema/produto/calculoprecovenda",
      OnClick: (e: FormCalculoPrecoVendaType) => {
        CalculoPrecoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Consultar Totais Internos",
      Permissao: "sistema/produto/consultartotaisinterno",
      OnClick: (e: any) => {
        TotaisInternosRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Curva ABC",
      Permissao: "sistema/produto/curvaabc",
      OnClick: () => {
        CurvaABCRef.current?.Abrir();
      },
    },
    {
      Nome: "Alterar NCM/CEST",
      Permissao: "sistema/produto/alterarncmcodigocestgeral",
      OnClick: () => {
        AlterarNCMCESTRef.current?.Abrir();
      },
    },
    {
      Nome: "Alterar NCM em Lote",
      Permissao: "sistema/produto/alterarncmemlote",
      OnClick: () => {
        AlterarNCMEmLoteRef.current?.Abrir();
      },
    },
    {
      Nome: "Consultar",
      Permissao: "sistema/produto/importararquivo",
      OnClick: () => {},
      SubMenus: [
        {
          Nome: "Consultar Custo Médio",
          Permissao: "sistema/produto/consultarcustomedio",
          OnClick: (e: ProdutoTypes) => {
            ConsultarCustoMedioRef.current.Abrir(e.id);
          },
        },
      ],
    },
    {
      Nome: "Calculo Preço",
      Permissao: "sistema/produto/calculoprecovenda",
      OnClick: (e: FormCalculoPrecoVendaType) => {
        CalculoPrecoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Curva ABC",
      Permissao: "sistema/produto/curvaabc",
      OnClick: () => {
        CurvaABCRef.current?.Abrir();
      },
    },
    {
      Nome: "Alterar NCM/CEST",
      Permissao: "sistema/produto/alterarncmcodigocestgeral",
      OnClick: () => {
        AlterarNCMCESTRef.current?.Abrir();
      },
    },
    {
      Nome: "Alterar NCM em Lote",
      Permissao: "sistema/produto/alterarncmemlote",
      OnClick: () => {
        AlterarNCMEmLoteRef.current?.Abrir();
      },
    },
    {
      Nome: "Curva ABC",
      Permissao: "sistema/produto/curvaabc",
      OnClick: () => {
        CurvaABCRef.current?.Abrir();
      },
    },
  ];

  const columns: Array<CGICol<any>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: [
        {
          nomeStatus: "Sim",
          cor: "green",
        },
        {
          nomeStatus: "Não",
          cor: "red",
        },
      ],
    },
    {
      Campo: "codigoProduto",
      Titulo: "Cód. Produto",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 160,
      },
    },
    {
      Campo: "descricao",
      Titulo: "Descrição",
      Tipo: "Texto",
    },
    {
      Campo: "id",
      Titulo: "NCM / CEST.",
      Tipo: "Composta",
      att: {
        maxWidth: 120,
      },
      Composta: [
        {
          titulo: "NCM",
          valor: "ncm",
        },
        {
          titulo: "CEST",
          valor: "codigoCEST",
          cor: "blue",
          negrito: true,
        },
      ],
    },
    {
      Campo: "nomeUnidade",
      Titulo: "Und",
      Tipo: "Texto",
      att: {
        maxWidth: 70,
      },
    },
    {
      Campo: "codigoBarras",
      Titulo: "EAN",
      Tipo: "Texto",
      att: {
        maxWidth: 100,
      },
    },
    {
      Campo: "nomeFornecedor",
      Titulo: "Fornecedor",
      Tipo: "Texto",
      att: {
        maxWidth: 200,
      },
    },
    {
      Campo: "precoVenda",
      Tipo: "Moeda",
      Titulo: "Pç. Venda",
      Ordernar: true,
      att: {
        maxWidth: 80,
      },
    },
    {
      Campo: "estoque",
      Titulo: "Estoque",
      Tipo: "Numero",
      ExibirCoresConformeValor: true,
      att: {
        maxWidth: 120,
      },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Texto",
      att: {
        cellRenderer: (e: any) => MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];
  //Menus
  //Func

  async function RetornarProdutos(DadosFiltros: any) {
    setLoadingState(true);
    console.log(DadosFiltros);
    var data = await HttpProduto()
      .RetornarProdutos(DadosFiltros)
      .finally(() => {
        setLoadingState(false);
      });
    setDados(data.data.data);
  }
  async function Pesquisar(data: any, e: any) {
    console.log(data);
    await RetornarProdutos(data);
  }

  //Func

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Filtros de Período" popover="Filtros de Período">
            <CGIBoxItem Col={6}>
              <CGIDatePicker id="dataInicial" label="Cadastro Data Inicial" />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker id="DataFinal" label="Cadastro Data Final" />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBox spacing="3px">
            <CGIBoxItem Col={6}>
              <CGIInput
                id="codigoProduto"
                label="Código Produto"
                placeholder="Código Produto"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIComboSimples
                Multipla
                id="listaTiposProdutoId"
                label="Tipo de Produto"
                Link="tipodeproduto"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIInput
                id="Descricao"
                label="Descrição"
                placeholder="Descrição"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboAjax
            Multipla
            id="ListaFornecedoresId"
            label="Fornecedor"
            Link="fornecedor-produtos"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIInput id="NCM" label="NCM" placeholder="NCM" />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput
            id="CodigoProdutoFabricante"
            label="Código Fabricante"
            placeholder="Código Produto (Fabricante)"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput
            id="codigoBarras"
            label="Cód. de Barras"
            placeholder="Cód. de Barras"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples
            Multipla
            id="ListaMarcasId"
            label="Marca"
            Link="marca"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples
            Multipla
            id="ListaClassesId"
            label="Classe"
            Link="classe"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples
            Multipla
            id="ListaFamiliasId"
            label="Família"
            Link="familia"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples
            Multipla
            id="ListaUnidadesId"
            label="Unidade"
            Link="unidade"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples
            Multipla
            id="ListaGruposId"
            label="Grupo"
            Link="grupo"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples
            Multipla
            id="ListaSubgruposId"
            label="SubGrupo"
            Link="subgrupo"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples
            id="ControlaLoteId"
            label="Controlar Lote"
            Link="simnaoambos"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples
            id="InativoFuturoId"
            label="Inativo Futuro"
            Link="simnaoambos"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples
            id="ProdutoECommerceId"
            label="E-Commerce"
            Link="simnaoambos"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples
            id="ProdutoInsumoId"
            label="Insumo"
            Link="simnaoambos"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples
            id="ProdutosComPrecoDiferenteNasEmpresasId"
            label="Preço Dif. nas Empresas"
            Link="simnaoambos"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples id="ativoId" label="Ativo" Link="simnaoambos" />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput id="Aplicacao" label="Aplicação" placeholder="Aplicação" />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="sistema/produto/listar"
      Titulo="Produtos"
      Janelas={Janelas}
      GetQueryString={{
        setRetorno: e => {
          methods.setValue("codigoProduto", e.DOC);
          methods.handleSubmit(Pesquisar)();
        },
      }}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => {
            methods.reset();
          }}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(Pesquisar)}
          Filtro={Filtros()}
          Data={Dados}
          Colunas={columns}
        />
      </FormProvider>
    </CGIListar>
  );
}
