import { CGIHttp } from "../../Services/Axios";

const url = "/home";

export function HttpCGICalendario() {
  async function RetornarDados(incio: any, fim: any, link: any) {
    let Obj = await new CGIHttp().conn.post(url + "/obter-calendario-home/", {
      link: link,
      dataInicial: incio,
      dataFinal: fim,
    });

    return Obj.data.data as Array<DateEvent>;
  }
  async function RetornarDadosCalendario(data: any, link: any) {
    let Obj = await new CGIHttp().conn.post(
      url + "/obter-calendario-home-dados/",
      {
        link: link,
        data: data,
      }
    );

    return Obj.data.data;
  }

  return {
    RetornarDados,
    RetornarDadosCalendario,
  };
}

type DateEvent = {
  title: string;
  allday: string;
  borderColor: string;
  color: string;
  textColor: string;
  description: string;
  start: string;
  end: string;
  url: string;
  id: string;
  valor: string;
};
