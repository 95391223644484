import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarPedidoSeparacaoItensTypes,
  ListarPedidoSeparacaoTypes,
  ObjListarPedidoSeparacaoPadrao,
} from "../../../Types/Vendas/Pedido/PedidoSeparacaoTypes";
import {
  ModalBaseType,
  LegendaTypes,
} from "../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpPedido } from "./Http";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { FormContext } from "../../../Context/FormConxtext";
import { CGIVisualizarAnexo } from "../../../Components/Anexo/CGIVisualizarAnexo";
import { CGIHistorico } from "../../../Components/Historico/CGIHistorico";
import { CGIAnexo } from "../../../Components/Anexo/CGIAnexo";
import moment from "moment";

export function ListarPedidoSeparacao() {
  const AlterarPedidoSeparacaoRef = useRef<ModalBaseType>();
  const VisualizarPedidoSeparacaoRef = useRef<ModalBaseType>();
  const CGIAnexoRef = useRef<ModalBaseType>();
  const CGIHistoricoRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarPedidoSeparacaoTypes>({
    defaultValues: ObjListarPedidoSeparacaoPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarPedidosSeparacao = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpPedido().RetornarPedidosSeparacao(DadosFiltros);
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo PedidoSeparacao",
      Permissao: "sistema/PedidoSeparacao/incluir",
      OnClick: () => {
        AlterarPedidoSeparacaoRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "sistema/PedidoSeparacao/alterar",
      OnClick: (e: ListarPedidoSeparacaoItensTypes) => {
        AlterarPedidoSeparacaoRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Iniciar Separação",
      Permissao: "sistema/PedidoSeparacao/alterar",
      OnClick: (e: ListarPedidoSeparacaoItensTypes) => {
        AlterarPedidoSeparacaoRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Finalizar Separação",
      Permissao: "sistema/PedidoSeparacao/alterar",
      OnClick: (e: ListarPedidoSeparacaoItensTypes) => {
        AlterarPedidoSeparacaoRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Enviar Para Comercial",
      Permissao: "sistema/PedidoSeparacao/alterar",
      OnClick: (e: ListarPedidoSeparacaoItensTypes) => {
        AlterarPedidoSeparacaoRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Anexos",
      Permissao: "sistema/anexo/visualizaranexospedido",
      OnClick: (e: ListarPedidoSeparacaoItensTypes) => {
        CGIAnexoRef.current?.Abrir({ id: e.pedidoId });
      },
    },
    {
      Nome: "Histórico",
      Permissao: "vendas/pedido/visualizarhistorico",
      OnClick: (e: ListarPedidoSeparacaoItensTypes) => {
        CGIHistoricoRef.current?.Abrir({ id: e.pedidoId });
      },
    },
  ];

  const Legendas: LegendaTypes = [
    { nomeStatus: "Aguardando Separação", cor: "green" },
    { nomeStatus: "Em Separação", cor: "#DBA800" },
  ];

  const GridColunas: Array<CGICol<ListarPedidoSeparacaoItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "ordemAlocacao",
      Titulo: "#",
      Tipo: "Numero",
      att: { maxWidth: 80 },
    },
    {
      Campo: "dataCadastro",
      Titulo: "Dt. Cadastro",
      Tipo: "Data",
      att: { maxWidth: 150 },
    },
    {
      Campo: "dataPrevisaoEntrega",
      Titulo: "Dt. Previsão",
      Tipo: "Data",
      att: { maxWidth: 150 },
    },
    {
      Campo: "identificacao",
      Titulo: "Pedido",
      Tipo: "Texto",
      att: { maxWidth: 130 },
    },
    {
      Campo: "id",
      Titulo: "Cliente",
      Tipo: "Composta",
      Composta: [
        {
          valor: "nomeCliente",
          titulo: "",
          exibirLabel: false,
        },
        {
          valor: "dataCadastro",
          titulo: "",
          exibirLabel: false,
          FuncCondicional(e) {
            return moment(e).format("DD/MM/yyyy");
          },
        },
      ],
    },
    {
      Campo: "nomeVendedor",
      Titulo: "Vendedor",
      Tipo: "Texto",
    },
    {
      Campo: "qtdItens",
      Titulo: "Qtd. Itens",
      Tipo: "Numero",
      att: { maxWidth: 130 },
    },
    {
      Campo: "totalPedido",
      Titulo: "Total Pedido",
      Tipo: "Moeda",
      att: { maxWidth: 130 },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarPedidoSeparacaoItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          valor: "ordemAlocacao",
          titulo: "#",
        },
        {
          valor: "dataCadastro",
          titulo: "Dt. Cadastro",
        },
        {
          valor: "dataPrevisaoEntrega",
          titulo: "Dt. Previsão",
        },
        {
          valor: "identificacao",
          titulo: "Pedido",
        },
        {
          valor: "nomeCliente",
          titulo: "Cliente",
        },
        {
          valor: "nomeVendedor",
          titulo: "Vendedor",
        },
        {
          valor: "qtdItens",
          titulo: "Qtd. Itens",
        },
        {
          valor: "totalPedido",
          titulo: "Total Pedido",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIVisualizarAnexo
          ref={CGIAnexoRef}
          link={"sistema/anexo/visualizaranexospedido"}
          origem={"pedido"}
          titulo={"Anexos Pedido"}
        />
      ),
    },
    {
      Componente: (
        <CGIHistorico
          tipo="TimeLine"
          titulo="Histórico do Pedido"
          ref={CGIHistoricoRef}
          link={"vendas/pedido/visualizarhistorico"}
          origem={"pedido"}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={5}>
          <CGIBox titulo="Filtros de Período">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarPedidoSeparacaoTypes>
                id="Pesquisa.dataInicialInclusao"
                label="Data Cadastro - Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarPedidoSeparacaoTypes>
                id="Pesquisa.dataFinalInclusao"
                label="Data Cadastro - Final"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={7}>
          <CGIBox>
            <CGIBoxItem Col={8}>
              <CGIComboAjax<ListarPedidoSeparacaoTypes>
                id="Pesquisa.listaClientesId"
                label="Cliente"
                Link={"cliente"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarPedidoSeparacaoTypes>
                id="Pesquisa.listaNaturezaOperacaoId"
                label="Natureza Operação"
                Link={"naturezadeoperacao"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIComboSimples<ListarPedidoSeparacaoTypes>
                id="Pesquisa.pedidoImpressoId"
                label="Pedido Impresso"
                Link={"simnaoambos"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="vendas/pedido/listarseparacao"
      Titulo="Listar Separação"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarPedidosSeparacao)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
