import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../Context/LoadingContext";
import { FormMovPortadorType } from "../../../Types/Financeiro/MovPortador/MovPortadorTypes";
import { HttpMovPortador } from "./Http";

type AlterarLancamentoType = {
  CarregarDados: Function;
};

export const AlterarLancamento = forwardRef(
  (props: AlterarLancamentoType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current.Abrir();
      },
      async Alterar(pMovPortador: any) {
        CGIModalRef.current.Abrir();
        var Dados = await HttpMovPortador().RetornarDadosMovPortadorLancamento(
          pMovPortador.id
        );
        form.setValue("Form", Dados);
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<FormMovPortadorType>();
    const { ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    //FINAL  HOOKS

    //INICIO CONST
    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Dados MovPortador",
        Component: AreaDadosMovPortador(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosMovPortador() {
      return (
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIResumo ProdutoId="produtoId" ResumoProdutoSimples />
          </CGIBoxItem>
          <CGIBoxItem Col={8}>
            <CGIInput<FormMovPortadorType>
              id="Form.dadosRegistro.obs"
              label="Observações"
              placeholder=""
            />
          </CGIBoxItem>
        </CGIBox>
      );
    }

    const Confirmar = async (data: FormMovPortadorType, event: any) => {
      setLoadingState(true);

      HttpMovPortador()
        .AlterarLancamento(data)
        .then((data: any) => {
          ToastSucesso("Registro Alterado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
        })
        .catch((e) => {
          return;
        })
        .finally(() => {
          setLoadingState(false);
        });
    };
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="financeiro/movportador/alterarlancamento"
        ref={CGIModalRef}
        Titulo="Alterar Movimentação de Portador"
        TelaCheia={"5xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
