import { CGIHttp } from "../../../Services/Axios";
import {
  ParametroGridItensTypes,
  ParametroPesquisaType,
  ParametroTypes,
} from "../../../Types/Sistema/Parametros/ParametroTypes";

const url = "/parametro";

export function HttpParametros() {
  async function RetornarParametros(
    pDados?: ParametroPesquisaType
  ): Promise<ParametroGridItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-parametros", {
        SomenteMultiEmpresa: false,
        ...pDados,
      })
      .then(res => {
        return res.data.data;
      });
  }
  async function RetornarParametrosMultiEmpresa(
    pDados?: ParametroPesquisaType
  ): Promise<ParametroGridItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-parametros", {
        SomenteMultiEmpresa: true,
        ...pDados,
      })
      .then(res => {
        return res.data.data;
      });
  }
  async function DefinirParametroGlobal(pId: number): Promise<void> {
    return await new CGIHttp().conn.post(
      "empresa/DefinirParametroComoGlobal/" + pId
    );
  }
  async function DefinirParametroMultiEmpresa(pId: number): Promise<void> {
    return await new CGIHttp().conn.post(
      "empresa/DefinirParametroComoMultiEmpresa/" + pId
    );
  }
  async function AlterarParamentros(
    parametrosGlobais: ParametroGridItensTypes[],
    parametrosMultiEmpresa: ParametroGridItensTypes[]
  ): Promise<void> {
    return await new CGIHttp().conn.post("empresa/parametros", {
      parametrosGlobais: parametrosGlobais,
      parametrosMultiEmpresa: parametrosMultiEmpresa,
    });
  }
  return {
    DefinirParametroGlobal,
    DefinirParametroMultiEmpresa,
    RetornarParametros,
    RetornarParametrosMultiEmpresa,
    AlterarParamentros,
  };
}
