/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react";
import { UseFormReturn, FieldValues } from "react-hook-form";
import { CGIBox } from "../../Box/CGIBox";
import { CGIBoxItem } from "../../Box/CGIBoxItens";
import { CGICheckBox } from "../../CheckBox/CGICheckBox";
import { CGIDatePicker } from "../../DatePicker/CGIDatePicker";
import { CGIInput } from "../../Input/CGIInput";
import { CGIRadioButton } from "../../RadioButton/CGIRadioButton";
import { HttpCGIResumosComposto } from "../HttpResumo";

function CGIResumoProdutoCodigoEDescricao(
  watchProdutoId: string,
  formPrincipal: UseFormReturn<FieldValues, any>,
  formResumo: UseFormReturn<FieldValues, any>
) {
  const Tipo = "Produto";
  const [valueOld, setValueOld] = useState();

  useEffect(() => {
    var value =
      formPrincipal.getValues(watchProdutoId) ||
      formResumo.getValues(watchProdutoId);
    if (value == null || value === undefined || value === "") {
      return;
    } else if (value === 0) {
      return formResumo.reset({});
    }

    if (valueOld === value) {
      return;
    } else {
      setValueOld(value);
    }

    RetornarDados(value);
  }, [formPrincipal.watch(watchProdutoId), formResumo.watch(watchProdutoId)]);

  async function RetornarDados(id: any) {
    var Dados = await HttpCGIResumosComposto().RetornarDados(id, Tipo);
    var key = Object.keys(Dados.registros[0]);
    key.map((e) => {
      if (e === "Id" || e === "id") return "";
      var value = Dados.registros[0][e];

      return formResumo.setValue(e, value);
    });
  }
  return (
    <CGIBox spacing="10px">
      <CGIBoxItem Col={4}>
        <CGIInput
          id={"PRODCodigoProduto"}
          label={"Código"}
          placeholder={"Código"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>

      <CGIBoxItem Col={7}>
        <CGIInput
          id={"PRODDescricao"}
          label={"Descrição"}
          placeholder={"Descrição"}
          att={{ isDisabled: true }}
        />
      </CGIBoxItem>
    </CGIBox>
  );
}

export const ResumoProdutoCodigoEDescricao = {
  id: "ResumoPedidoSimples",
  titulo: "Dados Produto",
  componente: CGIResumoProdutoCodigoEDescricao,
};
