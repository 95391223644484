import { AxiosInstance } from "axios";
import { CGIHttp } from "../../Services/Axios";

const url = "/home";

export function HttpCGINotificacao() {
  async function RetornarNotificacoes<T>() {
    let Obj = await new CGIHttp().conn.get(
      url + "/obter-resumo-notificacoes-home"
    );

    return Obj?.data.data as T;
  }
  async function RetornarNotificacoesModal<T>() {
    let Obj = await new CGIHttp().conn.get(url + "/obter-notificacoes-sistema");

    return Obj?.data.data as T;
  }

  async function DefinirAcaoNotificacaoSistema(pId: number, pAcao: boolean) {
    await new CGIHttp().conn.post(url + "/definir-acao-notificacao-sistema", {
      Id: pId,
      Acao: pAcao,
    });
  }

  return {
    RetornarNotificacoes,
    RetornarNotificacoesModal,
    DefinirAcaoNotificacaoSistema,
  };
}
