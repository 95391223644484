import { UseFormReturn } from "react-hook-form";
import { CGIBox } from "../../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../../Components/Box/CGIBoxItens";
import { CGITabs } from "../../../../../Components/Tabs/CGITabs";
import { AnaliseCreditoTypes } from "../../../../../Types/Financeiro/Colaborador/AnaliseCreditoTypes";
import { GridContasReceberEmAtraso } from "../Grids/ContasReceberEmAtraso";
import { GridHistoricoFinanceiro } from "../Grids/HistoricoFinanceiro";
import { GridItensOrcamento } from "../Grids/ItensOrcamento";
import { GridItensPedido } from "../Grids/ItensPedido";
import { GridNotasFiscais } from "../Grids/NotasFiscais";
import { GridOrcamento } from "../Grids/Orcamento";
import { GridPedido } from "../Grids/Pedido";

export const AreaDetalhes = (form: UseFormReturn<AnaliseCreditoTypes, any>) => {
  return (
    <CGIBox titulo="Informações">
      <CGIBoxItem Col={12}>
        <CGITabs
          Tabs={[
            { Titulo: "Orçamentos", Component: GridOrcamento(form) },
            { Titulo: "Itens Orçamento", Component: GridItensOrcamento(form) },
            { Titulo: "Pedidos", Component: GridPedido(form) },
            { Titulo: "Itens Pedidos", Component: GridItensPedido(form) },
            { Titulo: "Notas Fiscais", Component: GridNotasFiscais(form) },
            {
              Titulo: "Histórico Financeiro",
              Component: GridHistoricoFinanceiro(form),
            },
            {
              Titulo: "Contas a Receber em Atraso",
              Component: GridContasReceberEmAtraso(form),
            },
          ]}
        />
      </CGIBoxItem>
    </CGIBox>
  );
};
