import { ColDef } from "ag-grid-community";
import {
  Box,
  Divider,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Kbd,
  Tag,
  TagLabel,
  Button,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { CGIMenuButton, CGIMenuButtonTypes } from "../MenuButton/CGIMenuButton";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import Hotkeys from "react-hot-keys";
import "./DataTable.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
// Core grid CSS, always needed
import { CGICol, ConvertCol } from "./DataTable/DataTableTypes";
import { Ag_Grid_PT_BR } from "./DataTable/TraducaoGrid";
import { CGIButton } from "../Button/CGIButton";
import React from "react";
import { CGIMenuRelatorio } from "../MenuButton/CGIMenuRelatorio";
import { GRIDCONFIG, TamanhoTelaMobile, Temas } from "../../Themes/Temas";
import { CGIBox } from "../Box/CGIBox";
import { CGIBoxItem } from "../Box/CGIBoxItens";
import { SearchIcon } from "@chakra-ui/icons";
interface DataTableTypes {
  Filtro: React.ReactNode;
  //Pesquisar: Function;
  Pesquisar: () => void;
  LimparFiltros: Function;
  TituloFiltros?: string;
  TituloDados?: string;
  Menu: CGIMenuButtonTypes;
  Colunas: CGICol<any>[];
  Pag?: number;
  Data: any[];
  Totalizadores?: {
    [key: string]: string;
  };
  Legenda?: Array<{
    nomeStatus: string;
    cor: string;
  }>;
}

/**
 * @Componente CGIDataTable - Data Table principal para os Listar
 * @Ref DataTable
 */
export const CGIDataTable = forwardRef((props: DataTableTypes, ref) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isMobile] = useMediaQuery(TamanhoTelaMobile);

  const [Count, setCount] = useState(0);
  let GridRef = useRef<AgGridReact>(null);
  useImperativeHandle(ref, () => ({
    Pesquisar() {
      Pesquisar();
    },
  }));

  const TabsDados = useMemo(() => {
    return [
      {
        Tab: "Filtros",
        Titulo: props.TituloFiltros || "Filtros",
      },
      {
        Tab: "Dados",
        Titulo: props.TituloDados || "Dados",
        Linhas: "(" + Count + ")",
      },
    ];
  }, [Count, props.TituloDados, props.TituloFiltros]);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const Pesquisar = useCallback(async () => {
    setTabIndex(1);
    setCount(0);
    GridRef.current?.api.showLoadingOverlay();
    try {
      await props.Pesquisar();
    } finally {
      setTimeout(() => {
        setCount(GridRef.current?.api.getDisplayedRowCount() || 0);
      }, 500);
      GridRef.current?.api.hideOverlay();
    }
  }, [props]);

  function RenderTabs() {
    return TabsDados.map((e, index) => {
      return (
        <Tab
          key={index}
          _selected={{ bg: Temas.Primaria, color: Temas.Branco }}
          _hover={{ bg: Temas.Segundaria, color: Temas.Branco }}
          fontWeight={"semibold"}
        >
          {e.Titulo} {e.Linhas}
        </Tab>
      );
    });
  }
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      editable: false,
      minWidth: 100,
      floatingFilter: true,
      cellStyle: function (params) {
        if (params.colDef.editable) {
          return { backgroundColor: "#e7fff3" };
        } else {
          return null;
        }
      },
    };
  }, []);

  const getRowStyle = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#EFEFEF" };
    }
  };
  const rowStyle = { background: Temas.Branco };

  const PesquisarButtons = useCallback(() => {
    return (
      <CGIBox>
        <CGIBoxItem Col={12}>
          <Flex
            hidden={tabIndex === 0 ? false : true}
            width={"100%"}
            gap={4}
            align="end"
            justify="flex-end"
            direction={["column", "row"]}
          >
            <CGIButton
              att={{ colorScheme: "red" }}
              onClick={() => props.LimparFiltros()}
              label="Limpar Filtros"
            />
            <CGIMenuRelatorio />
            <CGIButton
              label="Pesquisar"
              onClick={Pesquisar}
              color={"white"}
              tooltip={
                <span style={{ color: "white" }}>
                  <Kbd color={"black"}>CRTL</Kbd>+
                  <Kbd color={"black"}>ENTER</Kbd>
                </span>
              }
            />
          </Flex>
        </CGIBoxItem>
      </CGIBox>
    );
  }, [Pesquisar, props, tabIndex]);

  const MenuPesquisaMobile = useCallback(() => {
    return (
      <Menu isLazy>
        <MenuButton as={Button} colorScheme={"green"}>
          <SearchIcon />
        </MenuButton>
        <MenuList>{PesquisarButtons()}</MenuList>
      </Menu>
    );
  }, [PesquisarButtons]);

  const Totalizadores = useCallback(() => {
    const CalcReduce = (data: any[], key: string) => {
      console.log("CalcReduce", data, key);
      return Number(
        data.reduce((a, b) => {
          return a + b[key];
        }, 0)
      );
    };

    if (props.Totalizadores) {
      console.log("Totalizador", props.Data);
      var DadosGrid = props.Data;
      return (
        <CGIBox>
          <CGIBoxItem Col={12}>
            <Flex
              width={"100%"}
              gap={4}
              align="end"
              justify="flex-end"
              direction={["column", "row"]}
            >
              {Object.keys(props.Totalizadores).map((e, index) => {
                var strTotalizadores = props.Totalizadores
                  ? props.Totalizadores[e].split("|")
                  : [];

                var Valor;
                switch (strTotalizadores[0]) {
                  case "SUM":
                    var sum = 0;
                    for (
                      let index = 0;
                      index < strTotalizadores.length;
                      index++
                    ) {
                      if (index === 0) {
                        continue;
                      }
                      const element = strTotalizadores[index];
                      sum += CalcReduce(DadosGrid, element);
                    }

                    Valor = sum.FormatarReal();
                    break;
                  case "COUNT":
                    Valor = DadosGrid.length;
                    break;

                  default:
                    Valor = 0;
                    break;
                }
                return (
                  <Text key={index} fontSize={"sm"} fontWeight={"bold"}>
                    {e}: {Valor}
                  </Text>
                );
              })}
            </Flex>
          </CGIBoxItem>
        </CGIBox>
      );
    } else {
      return <></>;
    }
  }, [Count, props.Totalizadores]);

  return (
    <Box bg={Temas.Branco} padding={2} margin={5} borderRadius={"lg"}>
      <Hotkeys
        keyName="ctrl+enter"
        filter={event => {
          return true;
        }}
        onKeyDown={() => {
          Pesquisar();
        }}
      >
        <Hotkeys
          filter={event => {
            return true;
          }}
          keyName="esc"
          onKeyDown={() => {
            setTabIndex(0);
          }}
        >
          <Tabs
            variant="enclosed"
            style={{
              height: "100%",
            }}
            index={tabIndex}
            onChange={handleTabsChange}
          >
            <TabList>
              {RenderTabs()}
              <CGIBox spacing="0px">
                <CGIBoxItem Col={12}>
                  <Flex gap={4}>
                    <CGIMenuButton
                      Icone={props.Menu.Icone}
                      Acoes={props.Menu.Acoes}
                    />
                    {isMobile ? MenuPesquisaMobile() : PesquisarButtons()}
                  </Flex>
                </CGIBoxItem>
              </CGIBox>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Box>{props.Filtro}</Box>
              </TabPanel>
              <TabPanel>
                <div
                  style={{ height: "60vh", width: "100%" }}
                  className="ag-theme-alpine"
                >
                  <AgGridReact
                    ref={GridRef}
                    columnDefs={ConvertCol(props.Colunas)}
                    defaultColDef={defaultColDef}
                    rowBuffer={GRIDCONFIG.BufferPaginas}
                    rowData={props.Data}
                    getRowStyle={getRowStyle}
                    rowStyle={rowStyle}
                    localeText={Ag_Grid_PT_BR}
                    animateRows={false}
                    loadingCellRenderer
                    pagination
                    paginationPageSize={props.Pag || GRIDCONFIG.TamanhoPaginas}
                  />
                </div>
                {props.Totalizadores && props.Data.length > 0 ? (
                  <Box my={5} borderWidth={2} w="100%">
                    <Box p={2}>
                      <Totalizadores />
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
                <Box my={5} borderWidth={2} w="100%">
                  <Box p={2}>
                    <Box p={2}>
                      {props.Legenda?.map((e, index) => {
                        return (
                          <Tag
                            mx={2}
                            size={"md"}
                            key={index}
                            borderRadius="full"
                            variant="solid"
                            bgColor={e.cor}
                          >
                            <TagLabel color={"gray.100"}>
                              {e.nomeStatus}
                            </TagLabel>
                          </Tag>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
                <Divider borderWidth={2} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Hotkeys>
      </Hotkeys>
    </Box>
  );
});
