export const ObjTipoOrcamentoPedido: FormTipoOrcamentoPedidoType = {
  Form: {
    DadosRegistro: {
      id: 0,
      nome: "",
      ativo: true,
      exibirCalendarios: false,
      permitirEnviarExpedicao: false,
      permitirEnviarFaturamento: false,
      enviarAutomaticamenteExpedicao: false,
    },
  },
};

export type FormTipoOrcamentoPedidoType = {
  Form: {
    DadosRegistro: TipoOrcamentoPedidoTypes;
  };
};

type TipoOrcamentoPedidoTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  exibirCalendarios: boolean;
  permitirEnviarExpedicao: boolean;
  permitirEnviarFaturamento: boolean;
  enviarAutomaticamenteExpedicao: boolean;
};

export const ObjListarTipoOrcamentoPedidoPadrao: ListarTipoOrcamentoPedidoTypes =
  {
    Pesquisa: {
      titulo: "",
      nome: "",
      ativoId: 0,
    },
    Itens: [],
  };

export type ListarTipoOrcamentoPedidoTypes = {
  Pesquisa: ListarPesquisaTipoOrcamentoPedidoTypes;
  Itens: ListarTipoOrcamentoPedidoItensTypes[];
};

export type ListarPesquisaTipoOrcamentoPedidoTypes = {
  titulo: string;
  nome: string;
  ativoId: number;
};

export type ListarTipoOrcamentoPedidoItensTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  exibirCalendarios: boolean;
  permitirEnviarExpedicao: boolean;
  permitirEnviarFaturamento: boolean;
  enviarAutomaticamenteExpedicao: boolean;
};
