import { forwardRef, useImperativeHandle, useContext, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import LoadingContext from "../../../Context/LoadingContext";
import { FormCtsReceberType } from "../../../Types/Financeiro/ContasReceber/CtsReceberTypes";
import { HttpCtsReceber } from "./Http";

export const AlterarComissao = forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    Abrir() {
      CGIModalRef.current.Abrir();
    },
  }));
  const { setLoadingState } = useContext(LoadingContext);
  const { ToastSucesso } = useCGIToast();
  const CGIModalRef = useRef() as any;
  const form = useFormContext();

  const Confirmar = async (data: FormCtsReceberType, event: any) => {
    setLoadingState(true);

    HttpCtsReceber()
      .AlterarComissao()
      .then((response: any) => {
        ToastSucesso("Comissão Alterada com sucesso", "OK!");
        props.CarregarDados();
        CGIModalRef.current.Fechar();
      })
      .catch((e) => {
        return ToastErro(e);
      })
      .finally(() => {
        setLoadingState(false);
      });
  };
  return (
    <CGIModal
      id="financeiro/ctsreceber/alterarcomissao"
      ref={CGIModalRef}
      Titulo="Alterar Comissão"
      TelaCheia={"6xl"}
      Confirmar={Confirmar}
      Fechar={() => {
        form.reset();
      }}
    >
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIResumo Expandir CtsReceberId="id" ResumoCtsReceber />
        </CGIBoxItem>
      </CGIBox>
      <CGIBoxItem Col={12}>
        <CGIInput<FormCtsReceberType>
          id={"Form.DadosRegistro.valorComissao"}
          label={"Valor Comissão"}
          placeholder={""}
        />
      </CGIBoxItem>
    </CGIModal>
  );
});
function ToastErro(e: any): any {
  throw new Error("Function not implemented.");
}
