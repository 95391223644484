import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../Context/LoadingContext";
import {
  FormConfigServicoType,
  ConfigServicoTypes,
} from "../../../Types/Sistema/ConfigServico/ConfigServicoTypes";
import { HttpConfigServico } from "./Http";

type AlterarConfigServicoType = {
  CarregarDados: Function;
};

export const AlterarConfigServico = forwardRef(
  (props: AlterarConfigServicoType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current.Abrir();
      },
      async Alterar(pConfigServico: any) {
        CGIModalRef.current.Abrir();
        console.log(pConfigServico);
        await HttpConfigServico()
          .RetornarDadosConfigServico(pConfigServico.id)
          .then((e) => {
            console.log(e);
            form.reset(e.Form);
          });
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<ConfigServicoTypes>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    //FINAL  HOOKS

    //INICIO CONST
    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Dados Config. Serviço",
        Component: AreaDadosConfigServico(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosConfigServico() {
      return (
        <CGIBox>
          <CGIBoxItem Col={6}>
            <CGIInput<FormConfigServicoType>
              id="Form.DadosRegistro.nome"
              label="Nome"
              placeholder=""
              validadores={{
                required: "Entre com o nome",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIComboSimples<FormConfigServicoType>
              id="Form.DadosRegistro.destaqueImpostosId"
              label="Destaque Impostos"
              Link="empresa"
              validadores={{
                required: "Entre com o nome",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIInput<FormConfigServicoType>
              id="Form.DadosRegistro.descricaoServico"
              label="Descrição do Serviço"
              placeholder=""
              att={{
                maxHeight: 100,
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIInput<FormConfigServicoType>
              id="Form.DadosRegistro.obsImpressaoPadrao"
              label="Obs. Impressão Padrão"
              placeholder=""
              att={{
                maxHeight: 100,
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIComboSimples<FormConfigServicoType>
              id="Form.DadosRegistro.empresaId"
              label="Empresa"
              Link="empresa"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIComboSimples<FormConfigServicoType>
              id="Form.DadosRegistro.clienteId"
              label="cliente"
              Link="cliente"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIBox>
              <CGIBoxItem Col={4}>
                <CGICheckBox<FormConfigServicoType>
                  id="Form.DadosRegistro.calcularISS"
                  label="Calcular ISS"
                  tooltip=""
                  att={{ style: { marginTop: 30 } }}
                  attLabel={{ style: { marginTop: 30 } }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGICheckBox<FormConfigServicoType>
                  id="Form.DadosRegistro.calcularPIS"
                  label="Calcular PIS"
                  tooltip=""
                  att={{ style: { marginTop: 30 } }}
                  attLabel={{ style: { marginTop: 30 } }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGICheckBox<FormConfigServicoType>
                  id="Form.DadosRegistro.calcularCOFINS"
                  label="Calcular COFINS"
                  tooltip=""
                  att={{ style: { marginTop: 30 } }}
                  attLabel={{ style: { marginTop: 30 } }}
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIBox>
              <CGIBoxItem Col={4}>
                <CGIInput<FormConfigServicoType>
                  id="Form.DadosRegistro.aliquotaISS"
                  label=""
                  placeholder="0"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIInput<FormConfigServicoType>
                  id="Form.DadosRegistro.aliquotaPIS"
                  label=""
                  placeholder="0"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIInput<FormConfigServicoType>
                  id="Form.DadosRegistro.aliquotaCOFINS"
                  label=""
                  placeholder="0"
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIBox>
              <CGIBoxItem Col={4}>
                <CGICheckBox<FormConfigServicoType>
                  id="Form.DadosRegistro.calcularCSLL"
                  label="Calcular CSLL"
                  tooltip=""
                  att={{ style: { marginTop: 30 } }}
                  attLabel={{ style: { marginTop: 30 } }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGICheckBox<FormConfigServicoType>
                  id="Form.DadosRegistro.calcularINSS"
                  label="Calcular INSS"
                  tooltip=""
                  att={{ style: { marginTop: 30 } }}
                  attLabel={{ style: { marginTop: 30 } }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGICheckBox<FormConfigServicoType>
                  id="Form.DadosRegistro.calcularIR"
                  label="Calcular IR"
                  tooltip=""
                  att={{ style: { marginTop: 30 } }}
                  attLabel={{ style: { marginTop: 30 } }}
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIBox>
              <CGIBoxItem Col={4}>
                <CGIInput<FormConfigServicoType>
                  id="Form.DadosRegistro.aliquotaCSLL"
                  label=""
                  placeholder="0"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIInput<FormConfigServicoType>
                  id="Form.DadosRegistro.aliquotaINSS"
                  label=""
                  placeholder="0"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIInput<FormConfigServicoType>
                  id="Form.DadosRegistro.aliquotaIR"
                  label=""
                  placeholder="0"
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIBox>
              <CGIBoxItem Col={4}>
                <CGIInput<FormConfigServicoType>
                  id="Form.DadosRegistro.prestacaoSUS"
                  label="Prestação SUS"
                  placeholder="0"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIInput<FormConfigServicoType>
                  id="Form.DadosRegistro.issRetido"
                  label="ISS Retido"
                  placeholder="0"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIInput<FormConfigServicoType>
                  id="Form.DadosRegistro.responsavelRetencao"
                  label="Responsável Retenção"
                  placeholder="0"
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIBox>
              <CGIBoxItem Col={3}>
                <CGIInput<FormConfigServicoType>
                  id="Form.DadosRegistro.itemListaServico"
                  label="Item Lista Serviço"
                  placeholder=""
                  validadores={{ required: true }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<FormConfigServicoType>
                  id="Form.DadosRegistro.codigoCnae"
                  label="Código CNAE"
                  placeholder=""
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGICheckBox<FormConfigServicoType>
                  id="Form.DadosRegistro.calcularDeducoes"
                  label="Calcular Deduções"
                  tooltip=""
                  att={{ style: { marginTop: 30 } }}
                  attLabel={{ style: { marginTop: 30 } }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<FormConfigServicoType>
                  id="Form.DadosRegistro.tipoTributacao"
                  label="Tipo Tributação"
                  placeholder=""
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIBox>
              <CGIBoxItem Col={4}>
                <CGICheckBox<FormConfigServicoType>
                  id="Form.DadosRegistro.imprimirVencimentos"
                  label="Imprimir Vencimentos"
                  tooltip=""
                  att={{ style: { marginTop: 30 } }}
                  attLabel={{ style: { marginTop: 30 } }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGICheckBox<FormConfigServicoType>
                  id="Form.DadosRegistro.imprimirReferenciaCliente"
                  label="Imprimir Referência Cliente"
                  tooltip=""
                  att={{ style: { marginTop: 30 } }}
                  attLabel={{ style: { marginTop: 30 } }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGICheckBox<FormConfigServicoType>
                  id="Form.DadosRegistro.imprimirPedidoCliente"
                  label="Imprimir Pedido Cliente"
                  tooltip=""
                  att={{ style: { marginTop: 30 } }}
                  attLabel={{ style: { marginTop: 30 } }}
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
        </CGIBox>
      );
    }

    const Confirmar = async (data: FormConfigServicoType, event: any) => {
      setLoadingState(true);

      if (
        data.Form.DadosRegistro.id == null ||
        data.Form.DadosRegistro.id === undefined ||
        data.Form.DadosRegistro.id === 0
      ) {
        HttpConfigServico()
          .Incluir(data)
          .then((response: any) => {
            ToastSucesso("Registro gravado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
            console.log(data);
          })
          .catch((e) => {
            return ToastErro(e);
          })
          .finally(() => {
            setLoadingState(false);
          });
      } else {
        HttpConfigServico()
          .Alterar(data)
          .then((data: any) => {
            ToastSucesso("Registro Alterado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return;
          })
          .finally(() => {
            setLoadingState(false);
          });
      }
    };
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="sistema/configservico/alterar"
        ref={CGIModalRef}
        Titulo="Configuração de Serviço"
        TelaCheia={"7xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
