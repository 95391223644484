import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../Context/LoadingContext";
import {
  FormPedidoEcommerceType,
  PedidoEcommerceTypes,
} from "../../../Types/Vendas/PedidoEcommerce/PedidoEcommerceTypes";
import { HttpPedidoEcommerce } from "./Http";

type AlterarPedidoEcommerceType = {
  CarregarDados: Function;
};

export const AlterarPedidoEcommerce = forwardRef(
  (props: AlterarPedidoEcommerceType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current.Abrir();
      },
      async Alterar(pPedidoEcommerce: any) {
        CGIModalRef.current.Abrir();
        console.log(pPedidoEcommerce);
        await HttpPedidoEcommerce()
          .RetornarDadosPedidoEcommerce(pPedidoEcommerce.id)
          .then((e) => {
            console.log(e);
            form.reset(e.Form);
          });
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<PedidoEcommerceTypes>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    //FINAL  HOOKS

    //INICIO CONST
    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Dados PedidoEcommerce",
        Component: AreaDadosPedidoEcommerce(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosPedidoEcommerce() {
      return (
        <CGIBox>
          {/* <CGIBoxItem Col={8}>
            <CGIInput<FormPedidoEcommerceType>
              id="Form.DadosRegistro.ncm"
              label="NCM"
              placeholder=""
              att={{
                maxLength: 8,
              }}
              validadores={{
                required: "NCM PedidoEcommerce obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={8}>
            <CGIInput<FormPedidoEcommerceType>
              id="Form.DadosRegistro.percIPI"
              label="Perc. IPI"
              placeholder=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={8}>
            <CGIInput<FormPedidoEcommerceType>
              id="Form.DadosRegistro.ex"
              label="Ex"
              placeholder=""
            />
          </CGIBoxItem> */}
        </CGIBox>
      );
    }

    const Confirmar = async (data: FormPedidoEcommerceType, event: any) => {
      setLoadingState(true);

      if (
        data.Form.DadosRegistro.id == null ||
        data.Form.DadosRegistro.id === undefined ||
        data.Form.DadosRegistro.id === 0
      ) {
        HttpPedidoEcommerce()
          .Incluir(data)
          .then((response: any) => {
            ToastSucesso("Registro gravado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
            console.log(data);
          })
          .catch((e) => {
            return ToastErro(e);
          })
          .finally(() => {
            setLoadingState(false);
          });
      } else {
        HttpPedidoEcommerce()
          .Alterar(data)
          .then((data: any) => {
            ToastSucesso("Registro Alterado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return;
          })
          .finally(() => {
            setLoadingState(false);
          });
      }
    };
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="sistema/PedidoEcommerce/alterar"
        ref={CGIModalRef}
        Titulo="Cadastro de PedidoEcommerce"
        TelaCheia={"3xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
