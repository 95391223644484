import { useTimeout } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { RetornarDadosUsuario } from "../../Services/Util/Util";
import { ModalBaseType } from "../../Types/Modal/ModalBaseType";
import { CGIBox } from "../Box/CGIBox";
import { CGIBoxItem } from "../Box/CGIBoxItens";
import { CGIButton } from "../Button/CGIButton";
import { CGIAlterarGraficos } from "./AlterarGraficos";
import { CGIGrafico } from "./CGIGrafico";

export function ResumoGraficos() {
  const CGIAlterarGraficoRef = useRef<ModalBaseType>(null);
  const [Dados, setDados] = useState(() => {
    return RetornarDadosUsuario();
  });

  const AtualizarGrafico = () => {
    setDados(RetornarDadosUsuario());
  };

  return (
    <CGIBox titulo="Resumo de Gráfico">
      <CGIBoxItem Col={12}>
        <CGIBox>
          <CGIBoxItem Col={2}>
            <CGIButton
              label="Alterar Graficos"
              onClick={() => {
                var graficos = RetornarDadosUsuario().graficos;
                CGIAlterarGraficoRef.current?.Abrir(graficos);
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIButton
              onClick={() => AtualizarGrafico()}
              label="Atualizar Graficos"
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIBoxItem>

      {Dados.graficos
        .sort((a, b) => a.sequencia - b.sequencia)
        .map((e, index) => {
          return (
            <CGIBoxItem key={index} Col={e.coluna as any}>
              <CGIGrafico tipo={e.tipo} chave={e.chave} />
            </CGIBoxItem>
          );
        })}
      <>
        <CGIAlterarGraficos
          AttGraficos={AtualizarGrafico}
          ref={CGIAlterarGraficoRef}
        />
      </>
    </CGIBox>
  );
}
