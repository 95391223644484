import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { AlterarNomePedidoType } from "../../../Types/Vendas/Pedido/PedidoTypes";

type PropsAlterarNomePedidoTypeType = {
  CarregarDados: Function;
};

export const AlterarNomePedido = forwardRef(
  (props: PropsAlterarNomePedidoTypeType, ref) => {
    useImperativeHandle(ref, () => ({
      Abrir(pId: number) {
        form.setValue("id", pId);
        CGIModalRef.current?.Abrir();
      },
    }));
    const form = useFormContext<AlterarNomePedidoType>();
    const CGIModalRef = useRef<ModalBaseType>();

    async function CarregarDados(id: number) {}

    const Confirmar = async (data: any, event: any) => {};

    return (
      <CGIModal
        id="vendas/pedido/alterarnomepedido"
        ref={CGIModalRef}
        Titulo="Alterar Nome do Pedido"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        CarregarDados={CarregarDados}
        Fechar={() => {}}
      >
        <CGIResumo
          Principal="PEDIDO"
          PedidoId="id"
          ResumoPedido
          ResumoPedidoItens
        />
        <CGIBoxItem Col={12}>
          <CGIBox>
            <CGIBoxItem Col={6}>
              <CGIInput<AlterarNomePedidoType>
                id={"nomePedido"}
                placeholder={"Nome do Pedido"}
                label={"Nome do Pedido"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIModal>
    );
    //FINAL JSX
  }
);
