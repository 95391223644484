/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  FormLabel,
  List,
  ListItem,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { HttpCGINotificacao } from "./HttpGrupoNotificacoes";
import * as CSS from "csstype";
import React, { useContext } from "react";
import { Temas } from "../../Themes/Temas";

export type CGINotificacaoType = {
  titulo: string;
  descricao: string;
  valor: number;
  msgSemPendencia: string;
};

export interface CGINotificacaoPropsType {
  link: string;
  titulo?: string;
  bgColor: CSS.Property.Color;
  color?: CSS.Property.Color;
}
/**
 * @Componente CGIGrupoMeta - Componente responsável pelo Grupo de notificações
 * @Ref  CGINotificacoes
 */
export function CGIGrupoNotificacoes(props: CGINotificacaoPropsType) {
  const [Dados, setDados] = useState<Array<CGINotificacaoType>>(
    [] as Array<CGINotificacaoType>
  );

  useEffect(() => {
    RetornarDados();
  }, []);

  async function RetornarDados() {
    var data = await HttpCGINotificacao().RetornarDados(props.link);
    setDados(data);
  }
  return (
    <Box scrollBehavior={"auto"} bg={Temas.Branco} p={4}>
      <FormLabel>Informativos ({Dados.length})</FormLabel>
      <List style={{ overflowY: "scroll", height: "100px" }}>
        {Dados.map((e, index) => {
          return (
            <ListItem key={index} mt={1} borderRadius={"md"} p={1}>
              <Alert status="warning">
                <AlertIcon />
                <AlertTitle>{e.titulo}</AlertTitle>
                <AlertDescription>{e.descricao}</AlertDescription>
              </Alert>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
