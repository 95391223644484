import { CGIHttp } from "../../../Services/Axios";
import {
  FormSugestaoPedidoCompraTypes,
  ListarSugestaoPedidoCompraItensTypes,
  ListarPesquisaSugestaoPedidoCompraTypes,
  DadosRegistroTypes,
  SugestaoPedidoCompraVisualizarTypes,
} from "../../../Types/Almoxarifado/SugestaoPedidoCompra/SugestaoPedidoCompraTypes";

export function HttpSugestaoPedidoCompra() {
  const url = "/sugestaopedidocompra";

  async function RetornarSugestoesPedidoCompra(
    DadosFiltros: ListarPesquisaSugestaoPedidoCompraTypes
  ) {
    return (await (
      await new CGIHttp().conn.post(
        url + "/obter-sugestao-compra",
        DadosFiltros
      )
    ).data.data) as ListarSugestaoPedidoCompraItensTypes[];
  }

  async function RetornarDadosSugestaoPedidoCompra(id: number) {
    return await new CGIHttp().conn.get(url + `/alterar/${id}`);
  }

  async function Incluir(Dados: DadosRegistroTypes) {
    return await new CGIHttp().conn.post(url + "/gerar", Dados);
  }

  async function Alterar(Dados: DadosRegistroTypes) {
    return await new CGIHttp().conn.post(url + "/alterar", Dados);
  }
  async function Visualizar(
    id: number
  ): Promise<SugestaoPedidoCompraVisualizarTypes> {
    return await new CGIHttp().conn.get(url + "/visualizar/" + id);
  }

  async function RetornarDadosExclusao(Dados: DadosRegistroTypes) {
    return await new CGIHttp().conn.get(url + "/excluir/" + Dados);
  }

  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir", Dados);
  }

  async function IncluirGET() {
    return (await (
      await new CGIHttp().conn.get(url + "/incluir")
    ).data.data) as FormSugestaoPedidoCompraTypes;
  }

  async function RetornarSugestaoPedidoCompraItens(
    pSugestaoPedidoCompraId: number
  ): Promise<FormSugestaoPedidoCompraTypes[]> {
    return await new CGIHttp().conn
      .get("/obter-itens/" + pSugestaoPedidoCompraId)
      .then((e) => e.data.data);
  }

  return {
    RetornarSugestoesPedidoCompra,
    RetornarDadosSugestaoPedidoCompra,
    RetornarDadosExclusao,
    Incluir,
    Alterar,
    Visualizar,
    Excluir,
    IncluirGET,
    RetornarSugestaoPedidoCompraItens,
  };
}
