import { CGIHttp } from "../../../Services/Axios";
import {
  ConfigComissaoTypes,
  ConfigComissaoVisualizarTypes,
  FormConfigComissaoType,
  ListarConfigComissaoGridItensTypes,
  ListarPesquisaConfigComissaoTypes,
} from "../../../Types/Sistema/ConfigComissao/ConfigComissaoTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpConfigComissao() {
  const url = "/ConfigComissao";

  async function Incluir(Dados: any): Promise<void> {
    return await new CGIHttp().conn.post(url + "/incluir", Dados);
  }
  async function Alterar(Dados: any): Promise<void> {
    return await new CGIHttp().conn.post(url + "/alterar", Dados);
  }
  async function retornarDadosConfigComissao(
    pId: number
  ): Promise<ConfigComissaoTypes> {
    return await new CGIHttp().conn
      .get(url + "/alterar/" + pId)
      .then(e => e.data.data.dadosRegistro);
  }
  async function Visualizar(
    pId: number
  ): Promise<ConfigComissaoVisualizarTypes> {
    return await new CGIHttp().conn
      .get(url + "/visualizar/" + pId)
      .then(e => e.data.data);
  }
  async function RetornarConfigComissoes(
    pDadosFiltros: ListarPesquisaConfigComissaoTypes
  ): Promise<ListarConfigComissaoGridItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-configcomissao ", pDadosFiltros)
      .then(e => e.data.data);
  }
  async function ConsultarComissao(pDadosFiltros: any): Promise<void> {
    return await new CGIHttp().conn
      .get(url + "/consultar-codigo /" + pDadosFiltros)
      .then(e => e.data.data);
  }
  async function RetornarDadosExclusao(pId: number) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + pId)
      .then(res => res.data.data)) as DeleteTypes;
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(
      url + "/excluir-configComissao/" + Dados.id,
      Dados
    );
  }

  return {
    Incluir,
    Excluir,
    Alterar,
    retornarDadosConfigComissao,
    Visualizar,
    RetornarConfigComissoes,
    ConsultarComissao,
    RetornarDadosExclusao,
  };
}
