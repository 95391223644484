import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIModal } from "../../../../Components/Modal/CGIModal";
import { HttpTipoNFEntrada } from "./Http";

type VisualizarTipoNFEntradaType = {
  CarregarDados: Function;
};
export const VisualizarTipoNFEntrada = forwardRef(
  (props: VisualizarTipoNFEntradaType, ref) => {
    // INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir(pId: number) {
        CGIModalRef.current.Abrir();
        CarregarDados(pId);
      },
    }));
    // FINAL REF

    const CGIModalRef = useRef() as any;
    const { reset } = useFormContext();

    async function CarregarDados(pId: number) {
      var data = await HttpTipoNFEntrada().Visualizar(pId);
      reset(data.data.data);
    }

    return (
      <CGIModal
        id="sistema/classificacao/visualizar"
        ref={CGIModalRef}
        Titulo="Visualizar Condição de Pagamento"
        TelaCheia={"6xl"}
        Confirmar={() => {}}
        Fechar={() => {}}
        ocultarCancelar={true}
        ocultarConfirmar={true}
      >
        <CGIBox>
          <CGIBoxItem Col={2}>
            <CGIInput
              id="id"
              label="Id"
              placeholder="Id"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIInput
              id="razaoSocial"
              label="Razão Social"
              placeholder="Razão Social"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput
              id="nomeExibicao"
              label="Nome Exibição"
              placeholder="Nome Exibição"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput
              id="nomeFantasia"
              label="Nome Fantasia"
              placeholder="Nome Fantasia"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput
              id="endereco"
              label="Endereço"
              placeholder="Endereço"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
  }
);
