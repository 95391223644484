import React from "react";
import {
  Button,
  ButtonProps,
  Text,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";
import { ReactElement, useContext } from "react";
import LoadingContext from "../../Context/LoadingContext";
import { TamanhoTelaMobile, Temas } from "../../Themes/Temas";

interface ButtonType extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  icon?: ReactElement;
  att?: ButtonProps;
  tooltip?: React.ReactNode | string;
}
/**
 * @Componente CGIButton - Botão padrão do Sistema
 *
 */
export function CGIButton(props: ButtonType) {
  const [isMobile] = useMediaQuery(TamanhoTelaMobile);
  const { Loadingstate } = useContext(LoadingContext);

  return (
    <Tooltip
      hidden={isMobile}
      isDisabled={props.tooltip ? false : true}
      hasArrow
      closeOnClick
      label={props.tooltip}
      color={Temas.Branco}
    >
      <Button
        colorScheme={"blue"}
        {...props}
        {...props.att}
        isLoading={Loadingstate}
        type="submit"
        leftIcon={props.icon}
        w={isMobile ? "full" : "auto"}
      >
        <Text p={2}>{props.label}</Text>
      </Button>
    </Tooltip>
  );
}
