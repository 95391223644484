import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../Context/LoadingContext";
import { FormTIPIType, TIPITypes } from "../../../Types/Sistema/TIPI/TIPITypes";
import { HttpTIPI } from "./Http";

type VisualizarTIPIType = {
  CarregarDados: Function;
};

export const VisualizarTIPI = forwardRef((props: VisualizarTIPIType, ref) => {
  //INICIO REF
  useImperativeHandle(ref, () => ({
    Abrir(pTIPIId: number) {
      CGIModalRef.current?.Abrir();
      //CarregarDados(pTIPIId);
    },
  }));
  //FINAL  REF

  //INICIO  HOOKS
  const CGIModalRef = useRef() as any;
  const form = useFormContext<TIPITypes>();

  //FINAL  HOOKS

  //INICIO CONST
  const RenderTabs: Array<CGITabItensTypes> = [
    {
      Titulo: "Dados TIPI",
      Component: AreaDadosTIPI(),
    },
  ];
  //FINAL  CONST

  //INICO FUNC
  function AreaDadosTIPI() {
    return (
      <CGIBox>
        <CGIBoxItem Col={8}>
          <CGIInput<FormTIPIType>
            id="Form.dadosRegistro.ncm"
            label="NCM"
            placeholder=""
            att={{
              isReadOnly: true,
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={8}>
          <CGIInput<FormTIPIType>
            id="Form.dadosRegistro.percIPI"
            label="Perc. IPI"
            placeholder=""
            att={{
              isReadOnly: true,
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={8}>
          <CGIInput<FormTIPIType>
            id="Form.dadosRegistro.ex"
            label="Ex"
            placeholder=""
            att={{
              isReadOnly: true,
            }}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  //FINAL FUNC

  //INICO JSX
  return (
    <CGIModal
      id="sistema/tipi/visualizar"
      ref={CGIModalRef}
      Titulo="Cadastro de TIPI"
      TelaCheia={"3xl"}
      //CarregarDados={CarregarDados}
      Confirmar={() => {}}
      Fechar={() => {
        form.reset({});
      }}
    >
      <CGITabs Tabs={RenderTabs} />
    </CGIModal>
  );
  //FINAL JSX
});
