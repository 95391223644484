import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback, useState } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import {
  CGICol,
  ConvertCol,
} from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarEstoqueGeralItensTypes,
  ListarEstoqueGeralTypes,
  ObjListarEstoqueGeralPadrao,
} from "../../../Types/Almoxarifado/MovEstoque/EstoqueGeralType";

import {
  ModalBaseType,
  LegendaTypes,
} from "../../../Types/Modal/ModalBaseType";

import { AlterarPrecoNasEmpresas } from "../../Sistema/Produto/_AlterarPrecoNasEmpresas";
import { VisualizarPedidosPorProduto } from "../../Vendas/Pedido/_VisualizarPedidosPorProduto";
import { VisualizarOrcamentosPorProduto } from "../../Vendas/Orcamento/_VisualizarOrcamentosPorProduto";
import { HttpMovEstoque } from "./Http";
import { AlterarMovEstoque } from "./_AlterarMovEstoque";
import { ConsultarMovimentacaoEstoqueProduto } from "./_ConsultarMovProduto";
import { AlterarEstoqueNasEmpresas } from "./_AlterarEstoqueNasEmpresas";
import { AgGridReact } from "ag-grid-react";
import { ConsultarEstoqueProduto } from "./_ConsultarEstoqueProduto";
import { TotaisInternos } from "../../Sistema/Produto/_TotaisInternos";

export function ListarEstoqueGeral() {
  const ExibirPedidosRef = useRef<ModalBaseType>();
  const ExibirOrcamentoRef = useRef<ModalBaseType>();
  const ConsultarMovEstoqueRef = useRef<ModalBaseType>();
  const AlterarPrecoNasEmpresasRef = useRef<ModalBaseType>();
  const AlterarEstoqueNasEmpresasRef = useRef<ModalBaseType>();
  const VisualizarProdutoRef = useRef<ModalBaseType>();
  const AlterarProdutoRef = useRef<ModalBaseType>();
  const NovaMovEstoqueRef = useRef<ModalBaseType>();
  const ConsultarTotaisInternasRef = useRef<ModalBaseType>();

  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarEstoqueGeralTypes>({
    defaultValues: ObjListarEstoqueGeralPadrao,
  });
  const DataRef = useRef<AgGridReact>(null);
  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });
  const ExibirEstoquePorLocalizacao = useWatch({
    control: methods.control,
    name: "Pesquisa.exibirEstoquePorLocalizaçãoDosPedidos",
  });

  const RetornarEstoqueGeral = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpMovEstoque().RetornarEstoqueGeral(DadosFiltros);
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const ListarMenu: Array<MenuTypes> = [];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar Produto",
      Permissao: "sistema/produto/visualizar",
      OnClick: (e: ListarEstoqueGeralItensTypes) => {
        VisualizarProdutoRef.current?.Alterar(e.produtoId);
      },
    },
    {
      Nome: "Alterar Produto",
      Permissao: "sistema/produto/alterar",
      OnClick: (e: ListarEstoqueGeralItensTypes) => {
        AlterarProdutoRef.current?.Alterar(e.produtoId);
      },
    },
    {
      Nome: "Exibir Pedidos",
      Permissao: "almoxarifado/movestoque/exibirpedidosporproduto",
      OnClick: (e: ListarEstoqueGeralItensTypes) => {
        ExibirPedidosRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Exibir Orçamentos",
      Permissao: "almoxarifado/movestoque/exibirorcamentosporproduto",
      OnClick: (e: ListarEstoqueGeralItensTypes) => {
        ExibirOrcamentoRef.current?.Abrir(e.produtoId);
      },
    },
    {
      Nome: "Consultar Mov. Estoque",
      Permissao: "sistema/classificacao/alterar",
      OnClick: (e: ListarEstoqueGeralItensTypes) => {
        ConsultarMovEstoqueRef.current?.Abrir(e.produtoId);
      },
    },
    {
      Nome: "Alterar Preço nas Empresas",
      Permissao: "sistema/produto/alterarprecoempresas",
      OnClick: (e: ListarEstoqueGeralItensTypes) => {
        AlterarPrecoNasEmpresasRef.current?.Abrir(e.produtoId);
      },
    },
    {
      Nome: "Alterar Estoque nas Empresas",
      Permissao: "sistema/classificacao/alterar",
      OnClick: (e: ListarEstoqueGeralItensTypes) => {
        AlterarEstoqueNasEmpresasRef.current?.Abrir(e.produtoId);
      },
    },
    {
      Nome: "Nova Mov. Estoque",
      Permissao: "sistema/classificacao/alterar",
      OnClick: (e: ListarEstoqueGeralItensTypes) => {
        NovaMovEstoqueRef.current?.Alterar(e.produtoId);
      },
    },
    {
      Nome: "Consultar Estoque Produto",
      Permissao: "almoxarifado/movestoque/consultarestoqueproduto",
      OnClick: (e: ListarEstoqueGeralItensTypes) => {
        AlterarEstoqueNasEmpresasRef.current?.Abrir(e.produtoId);
      },
    },
    {
      Nome: "Consultar Totais Internos",
      Permissao: "sistema/produto/consultartotaisinterno",
      OnClick: (e: ListarEstoqueGeralItensTypes) => {
        ConsultarTotaisInternasRef.current?.Abrir(e.produtoId);
      },
    },
  ];

  const Legendas: LegendaTypes = [];

  const GridColunas: Array<CGICol<ListarEstoqueGeralItensTypes>> = [
    {
      Campo: "codigoProduto",
      Titulo: "Código",
      Tipo: "Texto",
      att: {
        maxWidth: 120,
      },
    },
    {
      Campo: "descricao",
      Titulo: "Descrição",
      Tipo: "Texto",
    },
    {
      Campo: "qtdLocalComercial",
      Titulo: "Qtd. Comer.",
      Tipo: "Numero",
      ExibirCoresConformeValor: true,
      ocultar: !ExibirEstoquePorLocalizacao,
    },
    {
      Campo: "qtdLocalExpedicao",
      Titulo: "Qtd. Exped.",
      Tipo: "Numero",
      ExibirCoresConformeValor: true,
      ocultar: !ExibirEstoquePorLocalizacao,
    },
    {
      Campo: "qtdLocalProducao",
      Titulo: "Qtd. Produ.",
      Tipo: "Numero",
      ExibirCoresConformeValor: true,
      ocultar: !ExibirEstoquePorLocalizacao,
    },
    {
      Campo: "qtdLocalFaturamento",
      Titulo: "Qtd. Fatur.",
      Tipo: "Numero",
      ExibirCoresConformeValor: true,
      ocultar: !ExibirEstoquePorLocalizacao,
    },
    {
      Campo: "estoque",
      Titulo: "Geral",
      Tipo: "Numero",
      ExibirCoresConformeValor: true,
      att: {
        maxWidth: 160,
      },
    },
    {
      Campo: "pedidoVendaEmAberto",
      Titulo: "Qtd. Pedido",
      Tipo: "Numero",
      ExibirCoresConformeValor: true,
      att: {
        maxWidth: 180,
      },
    },
    {
      Campo: "qtdPedidoCompraEmAberto",
      Titulo: "Qtd. Ped. Compra",
      Tipo: "Numero",
      ExibirCoresConformeValor: true,
      att: {
        maxWidth: 180,
      },
    },
    {
      Campo: "saldoEstoque",
      Titulo: "Saldo Estoque",
      Tipo: "Numero",
      ExibirCoresConformeValor: true,
      att: {
        maxWidth: 180,
      },
    },
    {
      Campo: "produtoId",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: any) => MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];
  const Janelas: Array<{ Componente: React.ReactNode }> = [
    {
      Componente: (
        <VisualizarPedidosPorProduto
          CarregarDados={() => {}}
          ref={ExibirPedidosRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarOrcamentosPorProduto
          CarregarDados={() => {}}
          ref={ExibirOrcamentoRef}
        />
      ),
    },
    {
      Componente: (
        <ConsultarMovimentacaoEstoqueProduto
          CarregarDados={() => {}}
          ref={ConsultarMovEstoqueRef}
        />
      ),
    },
    {
      Componente: (
        <AlterarPrecoNasEmpresas
          CarregarDados={() => {}}
          ref={AlterarPrecoNasEmpresasRef}
        />
      ),
    },
    {
      Componente: (
        <AlterarEstoqueNasEmpresas
          CarregarDados={() => {}}
          ref={AlterarEstoqueNasEmpresasRef}
        />
      ),
    },
    {
      Componente: (
        <ConsultarEstoqueProduto
          CarregarDados={() => {}}
          ref={AlterarEstoqueNasEmpresasRef}
        />
      ),
    },

    {
      Componente: (
        <AlterarMovEstoque CarregarDados={() => {}} ref={NovaMovEstoqueRef} />
      ),
    },
    {
      Componente: (
        <TotaisInternos
          CarregarDados={() => {}}
          ref={ConsultarTotaisInternasRef}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarEstoqueGeralTypes>
            id={"Pesquisa.listaEmpresasId"}
            Multipla
            Link={"empresa"}
            label={"Empresa"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarEstoqueGeralTypes>
            id={"Pesquisa.codigoProduto"}
            placeholder={"Código Produto"}
            label={"Código Produto"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={5}>
          <CGIInput<ListarEstoqueGeralTypes>
            id={"Pesquisa.descricao"}
            placeholder={"Descrição"}
            label={"Descrição"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarEstoqueGeralTypes>
            id={"Pesquisa.listaGruposId"}
            Multipla
            Link={"grupo"}
            label={"Grupo"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarEstoqueGeralTypes>
            id={"Pesquisa.listaSubGruposId"}
            Multipla
            Link={"subgrupo"}
            label={"Sub-Grupo"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarEstoqueGeralTypes>
            id={"Pesquisa.listaClassesId"}
            Multipla
            Link={"classe"}
            label={"Classe"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarEstoqueGeralTypes>
            id={"Pesquisa.listaTiposProdutoId"}
            Multipla
            Link={"tipodeproduto"}
            label={"Tipo Produto"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarEstoqueGeralTypes>
            id={"Pesquisa.listaMarcasId"}
            Multipla
            Link={"marca"}
            label={"Marca"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarEstoqueGeralTypes>
            id={"Pesquisa.listaFamiliasId"}
            Multipla
            Link={"familia"}
            label={"Família"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIComboAjax<ListarEstoqueGeralTypes>
            id={"Pesquisa.listaFornecedoresId"}
            Multipla
            Link={"fornecedor"}
            label={"Fornecedor"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIBox titulo="Filtro Estoque">
            <CGIBoxItem Col={4}>
              <CGIBox spacing="0px">
                <CGIBoxItem Col={12}>
                  <CGICheckBox<ListarEstoqueGeralTypes>
                    id={"Pesquisa.estoqueNegativo"}
                    label={"Estoque Negativo"}
                    tooltip={"Estoque Negativo"}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={12}>
                  <CGICheckBox<ListarEstoqueGeralTypes>
                    id={"Pesquisa.estoquePositivo"}
                    label={"Estoque Positivo"}
                    tooltip={"Estoque Positivo"}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={12}>
                  <CGICheckBox<ListarEstoqueGeralTypes>
                    id={"Pesquisa.estoqueZero"}
                    label={"Estoque Zero"}
                    tooltip={"Estoque Zero"}
                  />
                </CGIBoxItem>
              </CGIBox>
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIBox spacing="0px">
                <CGIBoxItem Col={12}>
                  <CGICheckBox<ListarEstoqueGeralTypes>
                    id={"Pesquisa.saldoEmEstoqueNegativo"}
                    label={"Saldo em Estoque Negativo"}
                    tooltip={"Saldo em Estoque Negativo"}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={12}>
                  <CGICheckBox<ListarEstoqueGeralTypes>
                    id={"Pesquisa.saldoEmEstoquePositivo"}
                    label={"Saldo em Estoque Positivo"}
                    tooltip={"Saldo em Estoque Positivo"}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={12}>
                  <CGICheckBox<ListarEstoqueGeralTypes>
                    id={"Pesquisa.saldoEmEstoqueZero"}
                    label={"Saldo em Estoque Zero"}
                    tooltip={"Saldo em Estoque Zero"}
                  />
                </CGIBoxItem>
              </CGIBox>
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIBox spacing="0px">
                <CGIBoxItem Col={12}>
                  <CGICheckBox<ListarEstoqueGeralTypes>
                    id={"Pesquisa.pedidoEmAberto"}
                    label={"Pedido em Aberto"}
                    tooltip={"Pedido em Aberto"}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={12}>
                  <CGICheckBox<ListarEstoqueGeralTypes>
                    id={"Pesquisa.exibirEstoqueTodasEmpresas"}
                    label={"Exibir Estoque de Todas Empresas"}
                    tooltip={"Exibir Estoque de Todas Empresas"}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={12}>
                  <CGICheckBox<ListarEstoqueGeralTypes>
                    id={"Pesquisa.exibirEstoquePorLocalizaçãoDosPedidos"}
                    label={"Exibir Estoque por Localização dos Pedidos"}
                    tooltip={"Exibir Estoque por Localização dos Pedidos"}
                  />
                </CGIBoxItem>
              </CGIBox>
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="almoxarifado/movestoque/estoquegeral"
      Titulo="Estoque Geral"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          ref={DataRef}
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarEstoqueGeral)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
