import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { EmpresaTypes,
  EmpresaVisualizarTypes,
} from "../../../Types/Sistema/Empresa/EmpresaTypes";
import { HttpEmpresa } from "./Http";

type VisualizarEmpresaType = {
  CarregarDados: Function;
};
export const VisualizarEmpresa = forwardRef(
  (props: VisualizarEmpresaType, ref) => {
    // INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir(pEmpresaId: number) {
        CGIModalRef.current?.Abrir();
        CarregarDados(pEmpresaId);
      },
    }));
    // FINAL REF

    const CGIModalRef = useRef<ModalBaseType>(null);
    const { reset } = useFormContext<EmpresaTypes>();

    async function CarregarDados(id: number) {
      var data = await HttpEmpresa().Visualizar(id);
      reset(data);
    }

    return (
      <CGIModal
        id="sistema/empresa/visualizar"
        ref={CGIModalRef}
        Titulo="Visualizar Empresa"
        TelaCheia={"6xl"}
        Confirmar={() => {}}
        Fechar={() => {}}
        CarregarDados={CarregarDados}
        ocultarCancelar={true}
        ocultarConfirmar={true}
      >
        <CGIBox>
          <CGIBoxItem Col={2}>
            <CGIInput<EmpresaVisualizarTypes>
              id="id"
              label="Id"
              placeholder="Id"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIInput<EmpresaVisualizarTypes>
              id="razaoSocial"
              label="Razão Social"
              placeholder="Razão Social"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<EmpresaVisualizarTypes>
              id="nomeExibicao"
              label="Nome Exibição"
              placeholder="Nome Exibição"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<EmpresaVisualizarTypes>
              id="nomeFantasia"
              label="Nome Fantasia"
              placeholder="Nome Fantasia"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<EmpresaVisualizarTypes>
              id="cnpj"
              label="CNPJ"
              placeholder="CNPJ"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<EmpresaVisualizarTypes>
              id="ie"
              label="Inscrição Estadual"
              placeholder="Inscrição Estadual"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIInput<EmpresaVisualizarTypes>
              id="crt"
              label="CRT"
              placeholder="CRT"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<EmpresaVisualizarTypes>
              id="endereco"
              label="Endereço"
              placeholder="Endereço"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIInput<EmpresaVisualizarTypes>
              id="numeroEndereco"
              label="Nº"
              placeholder="Nº"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<EmpresaVisualizarTypes>
              id="complemento"
              label="Complemento"
              placeholder="Complemento"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<EmpresaVisualizarTypes>
              id="bairro"
              label="Bairro"
              placeholder="Bairro"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<EmpresaVisualizarTypes>
              id="cidade"
              label="Cidade"
              placeholder="Cidade"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<EmpresaVisualizarTypes>
              id="estado"
              label="Estado"
              placeholder="Estado"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<EmpresaVisualizarTypes>
              id="cep"
              label="CEP"
              placeholder="CEP"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<EmpresaVisualizarTypes>
              id="email"
              label="Email"
              placeholder="Email"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
  }
);
