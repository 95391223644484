import { AddIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Button,
} from "@chakra-ui/react";
import {
  RetornarPemissaoPeloLink,
  RetornarSeUsuarioTemPemissaoPeloLink,
} from "../Util/Permissoes";
import React, { useContext, useEffect, useState } from "react";
import CGIListarContext from "../Listar/CGIListar";
import { Link, useNavigate } from "react-router-dom";
import { HttpMenu } from "./HttpMenu";
import { useFormContext } from "react-hook-form";
import { CGIButton } from "../Button/CGIButton";

export function CGIMenuRelatorio() {
  const [Relatorios, setRelatorios] = useState<Array<any>>([]);
  const { id } = useContext(CGIListarContext);
  const form = useFormContext();
  const navigate = useNavigate();

  useEffect(() => {
    const RetornarDados = async () => {
      var Dados = await HttpMenu().RetornarDados(id);
      setRelatorios(Dados);
    };
    RetornarDados();
  }, []);

  async function RetornarListar(e: any, f: any) {
    var Query = new URLSearchParams(f).toString();
    window.open(
      "/Sistema/Relatorio/VisualizarRelatorio/" + e.id + "?" + Query,
      "_blank"
      //"location=no"
    );
  }

  return (
    <Menu isLazy>
      <MenuButton
        as={CGIButton}
        label="Relatórios"
        att={{
          colorScheme: "green",
          rightIcon: <ChevronDownIcon />,
        }}
        disabled={Relatorios.length === 0}
      ></MenuButton>
      <MenuList style={{ overflowY: "auto", maxHeight: "250px" }}>
        {Relatorios.map((e, index) => {
          return (
            <MenuItem
              onClick={form.handleSubmit(f => RetornarListar(e, f))}
              key={index}
            >
              {e.nome}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}
