import { CGIHttp } from "../../../Services/Axios";
import {
  FormEquipamentoTypes,
  ListarEquipamentoItensTypes,
  ListarPesquisaEquipamentoTypes,
  DadosRegistroTypes,
} from "../../../Types/Sistema/Equipamento/EquipamentoTypes";

const url = "/equipamento";

export function HttpEquipamento() {
  async function RetornarEquipamentos(
    DadosFiltros: ListarPesquisaEquipamentoTypes
  ) {
    return (
      await new CGIHttp().conn.post(url + "/obter-equipamentos", DadosFiltros)
    ).data.data as ListarEquipamentoItensTypes[];
  }

  async function RetornarDadosEquipamento(id: number) {
    return await new CGIHttp().conn.get(url + `/alterar/${id}`);
  }

  async function Incluir(Dados: DadosRegistroTypes) {
    return await new CGIHttp().conn.post(url + "/incluir", Dados);
  }
  async function Alterar(Dados: DadosRegistroTypes) {
    return await new CGIHttp().conn.post(url + "/alterar", Dados);
  }
  async function Visualizar(Dados: DadosRegistroTypes) {
    return await new CGIHttp().conn.get(url + "/visualizar/" + Dados);
  }
  async function RetornarDadosExclusao(Dados: DadosRegistroTypes) {
    return await new CGIHttp().conn.get(url + "/excluir/" + Dados);
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir", Dados);
  }

  async function IncluirGET() {
    return (await (
      await new CGIHttp().conn.get(url + "/incluir")
    ).data.data) as FormEquipamentoTypes;
  }
  return {
    RetornarEquipamentos,
    RetornarDadosEquipamento,
    RetornarDadosExclusao,
    Incluir,
    Alterar,
    Visualizar,
    Excluir,
    IncluirGET,
  };
}
