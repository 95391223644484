export const ObjConfigCFOPAutoPadrao: FormConfigCFOPAutoType = {
  Form: {
    DadosRegistro: {
      id: 0,
      nome: "",
      textoPadrao: "",
      cfopId: 0,
      ativo: false,
      cstId: 0,
      cfopDevolucaoId: 0,
      calcularICMS: false,
      calcularICMSST: false,
      calcularIPI: false,
      calcularPIS: false,
      calcularCOFINS: false,
      calcularFCP: false,
      calcularFCPST: false,
      calcularFCPSTRet: false,
      alterarPercICMS: false,
      percICMS: 0,
      percReducaoICMS: 0,
      percDiferimentoICMS: 0,
      percDiferimentoFCP: 0,
      removerIPIBaseCalculoICMS: false,
      movimentarEstoque: false,
      gerarFinanceiro: false,
      empresaId: 0,
      usuarioId: 0,
      estado: "",
      colaboradorId: 0,
      produtoId: 0,
      nCM: "",
      natOperacaoId: 0,
      grupoColaboradores: "",
      configCFOPId: 0,
      ehContribuinte: false,
      tipoProdutoId: 0,
      grupoProdutoId: 0,
      classeId: 0,
      codigoColaborador: "",
      codigoProduto: "",
      listaClassesProdutosId: "",
      listaTiposProdutosId: "",
      listaGruposProdutosId: "",
      listaNaturezasOperacaoId: "",
      listaConfigCFOPsId: "",
      contribuinteICMS: "",
    },
  },
};
export type ConfigCFOPAutoTypes = {
  id: number;
  textoPadrao: string;
  ativo: boolean;
  nome: string;
  empresaId: number;
  cfopId: number;
  cstId: number;
  cfopDevolucaoId: number;
  calcularICMS: boolean;
  calcularICMSST: boolean;
  calcularIPI: boolean;
  calcularPIS: boolean;
  calcularCOFINS: boolean;
  calcularFCP: boolean;
  calcularFCPST: boolean;
  calcularFCPSTRet: boolean;
  alterarPercICMS: boolean;
  percICMS: number;
  percReducaoICMS: number;
  percDiferimentoICMS: number;
  percDiferimentoFCP: number;
  removerIPIBaseCalculoICMS: boolean;
  movimentarEstoque: boolean;
  gerarFinanceiro: boolean;
  usuarioId: number;
  estado: string;
  colaboradorId?: number;
  produtoId?: number;
  nCM: string;
  natOperacaoId: number;
  grupoColaboradores: string;
  configCFOPId: number;
  ehContribuinte: boolean;
  tipoProdutoId?: number;
  grupoProdutoId?: number;
  classeId?: number;
  codigoColaborador: string;
  codigoProduto: string;
  listaClassesProdutosId: string;
  listaTiposProdutosId: string;
  listaGruposProdutosId: string;
  listaNaturezasOperacaoId: string;
  listaConfigCFOPsId: string;
  contribuinteICMS: string;
};

export type FormConfigCFOPAutoType = {
  Form: {
    DadosRegistro: ConfigCFOPAutoTypes;
  };
};

//------ TIPO ALTERAR

export const ObjListarConfigCFOPAutoPadrao: ListarConfigCFOPAutoTypes = {
  Pesquisa: {
    listaEmpresasId: "",
    listaTipoProdutosId: "",
    listaGruposProdutosId: "",
    listaClassesProdutosId: "",
    listaNaturezaDeOperacoesId: "",
    listaCFOPsId: "",
    ativoId: 0,
  },
  Itens: [],
};

export type ConfigCFOPAutoPesquisaGridTypes = {
  codigo?: number;
  nome?: string;
  nomeCFOP?: string;
};

export type ListarConfigCFOPAutoTypes = {
  Pesquisa: ListarPesquisaConfigCFOPAutoTypes;
  Itens: ListarConfigCFOPAutoGridItensTypes[];
};

export type ListarPesquisaConfigCFOPAutoTypes = {
  listaEmpresasId: string;
  listaTipoProdutosId: string;
  listaGruposProdutosId: string;
  listaClassesProdutosId: string;
  listaNaturezaDeOperacoesId: string;
  listaCFOPsId: string;
  ativoId: number;
};

export type ListarConfigCFOPAutoGridItensTypes = {
  id: number;
  ativo: boolean;
  //ativoId: number;
  nomeEmpresa: string;
  nome: string;
  nomeConfigCFOP: string;
  nomeStatus: string;
  nomeCFOP: string;
  nomeCFOPDevolucao: string;
  nomeCST: string;
  nomeNaturezaOperacao: string;
  nomeClasseProduto: string;
  nomeTipoProduto: string;
  nomeGrupoProduto: string;
  estado: string;
  cfopId: number;
  cstId: number;
  cfopDevolucaoId: number;
  calcularICMS: boolean;
  calcularICMSST: boolean;
  calcularIPI: boolean;
  calcularPIS: boolean;
  calcularCOFINS: boolean;
  calcularFCP: boolean;
  calcularFCPST: boolean;
  calcularFCPSTRet: boolean;
  alterarPercICMS: boolean;
  percICMS: number;
  percReducaoICMS: number;
  percDiferimentoICMS: number;
  percDiferimentoFCP: number;
  removerIPIBaseCalculoICMS: boolean;
  movimentarEstoque: boolean;
  gerarFinanceiro: boolean;
  ehContribuinte: boolean;
};

export type ConfigCFOPAutoVisualizarTypes = {
  id: number;
  dataCadastro: string;
  dataConfigCFOPAuto: string;
  nomePosto: string;
  numeroNF: number;
  nomeEmpresa: string;
  nomeCentroDeCusto: string;
  combustivel: string;
  nomeEquipamento: string;
  nomeColaborador: number;
  qtdLitros: number;
  obs: string;
  valorUnitario: number;
  valorTotal: number;
};
