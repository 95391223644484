export const ObjCtsReceberPadrao: FormCtsReceberType = {
  Form: {
    DadosRegistro: {
      id: 0,
      empresaId: 0,
      numero: 0,
      parcela: 0,
      totalParcelas: 0,
      dataCadastro: "",
      dataVencimento: "",
      dataPrevisao: "",
      dataPagamento: "",
      dataBaixa: "",
      dataExclusao: "",
      dataProgramacao: "",
      dataEmissao: "",
      arquivoRemessaPagamentoTitulosId: 0,
      mesReferencia: "",
      desconto: 0,
      valor: 0,
      valorPago: 0,
      saldoPagar: 0,
      juros: 0,
      mora: 0,
      codigoBarras: "",
      cobrancaNaEmpresa: false,
      fornecedorId: 0,
      usuarioId: 0,
      tipoDocumentoId: 0,
      formaPagamentoId: 0,
      portadorId: 0,
      documento: "",
      obs: "",
      statusId: 0,
      somenteTitulosPrevisao: false,
      sISPAGPagamentoAtravesDeId: 0,
      numeroOrdemCompra: "",
      pagamentoBloqueadoId: 0,
      docContabil: false,
      equipamentoId: 0,
      statusCobrancaFinanceiroId: 0,
      alterarDataCadastro: false,
      sugerirNroDoc: false,
      baixarTitulo: false,
      portadorBaixaId: 0,
      planoDeContasId: 0,
      centroDeCustoId: 0,
      fazerRateio: false,
      tituloPrevisao: false,
      valorComissao: 0,
      convenioPortadorId: 0,
      clienteId: 0,
      dadosPedido: "",
      vendedorId: 0,
    },
  },
};

export type CtsReceberTypes = {
  id: number;
  empresaId: number;
  numero: number;
  parcela: number;
  totalParcelas: number;
  dataCadastro: string;
  dataVencimento: string;
  dataPrevisao: string;
  dataPagamento: string;
  dataBaixa: string;
  dataExclusao: string;
  dataProgramacao: string;
  dataEmissao: string;
  arquivoRemessaPagamentoTitulosId: number;
  mesReferencia: string;
  desconto: number;
  valor: number;
  valorPago: number;
  saldoPagar: number;
  juros: number;
  mora: number;
  codigoBarras: string;
  cobrancaNaEmpresa: boolean;
  fornecedorId: number;
  clienteId: number;
  usuarioId: number;
  tipoDocumentoId: number;
  formaPagamentoId: number;
  portadorId: number;
  documento: string;
  obs: string;
  statusId: number;
  somenteTitulosPrevisao: boolean;
  sISPAGPagamentoAtravesDeId: number;
  numeroOrdemCompra: string;
  pagamentoBloqueadoId: number;
  docContabil: boolean;
  equipamentoId: number;
  statusCobrancaFinanceiroId: number;
  alterarDataCadastro: boolean;
  sugerirNroDoc: boolean;
  baixarTitulo: boolean;
  portadorBaixaId: number;
  planoDeContasId: number;
  centroDeCustoId: number;
  fazerRateio: boolean;
  valorComissao: number;
  convenioPortadorId: number;
  dadosPedido: string;
  tituloPrevisao: boolean;
  vendedorId: number;
};

export type AlterarCtsReceberTypes = {
  id: number;
  empresaId: number;
  numero: number;
  parcela: number;
  totalParcelas: number;
  dataCadastro: string;
  dataVencimento: string;
  dataPrevisao: string;
  dataPagamento: string;
  dataBaixa: string;
  dataExclusao: string;
  dataProgramacao: string;
  dataEmissao: string;
  arquivoRemessaPagamentoTitulosId: number;
  mesReferencia: string;
  desconto: number;
  valor: number;
  valorPago: number;
  saldoPagar: number;
  juros: number;
  mora: number;
  codigoBarras: string;
  cobrancaNaEmpresa: boolean;
  fornecedorId: number;
  usuarioId: number;
  tipoDocumentoId: number;
  formaPagamentoId: number;
  portadorId: number;
  documento: string;
  obs: string;
  statusId: number;
  somenteTitulosPrevisao: boolean;
  sISPAGPagamentoAtravesDeId: number;
  numeroOrdemCompra: string;
  pagamentoBloqueadoId: number;
  docContabil: boolean;
  equipamentoId: number;
  statusCobrancaFinanceiroId: number;
  alterarDataCadastro: boolean;
  sugerirNroDoc: boolean;
  baixarTitulo: boolean;
  portadorBaixaId: number;
  planoDeContasId: number;
  centroDeCustoId: number;
  fazerRateio: boolean;
  valorComissao: number;
  convenioPortadorId: number;
};

export type CtsReceberVisualizarTypes = {
  id: number;
  dataCadastro: string | null;
  dataVencimento: string | null;
  dataPrevisao: string | null;
  dataPagamento: string | null;
  dataBaixa: string | null;
  dataExclusao: string | null;
  dataProgramacao: string | null;
  dataEmissao: string | null;
  nomeEmpresa: string;
  nomeFornecedor: string;
  nomeTipoDocumento: string;
  nomeFormaPagamento: string;
  nomePortador: string;
  valor: number;
  valorPago: number;
  nomeStatus: string;
  obs: string;
  nomeUsuario: string;
  codigoBarras: string;
  documento: string;
  titulo: string;
  cobrancaNaEmpresa: boolean;
  saldoPagar: number;
  juros: number;
  somenteTitulosPrevisao: boolean;
  nomeCentroDeCusto: string;
  nomePlanoDeContas: string;
};

export const ObjListarCtsReceberPadrao: ListarCtsReceberTypes = {
  Pesquisa: {
    dataInclusaoInicial: "",
    dataInclusaoFinal: "",
    dataVencimentoInicial: "",
    dataVencimentoFinal: "",
    dataPagamentoInicial: "",
    dataPagamentoFinal: "",
    dataBaixaInicial: "",
    dataBaixaFinal: "",
    valorDe: 0,
    valorAte: 0,
    listaEmpresasId: "",
    listaGruposEconomicosId: "",
    listaMesesReferenciaId: "",
    listaTiposDeClientesExclusaoId: "",
    listaTiposDeClientesId: "",
    listaFormasDePagamentosId: "",
    listaVendedoresId: "",
    listaPlanoDeContasId: "",
    listaRotasId: "",
    listaPracasId: "",
    nossoNumero: "",
    listaClientesId: "",
    listaStatusId: "",
    listaFornecedoresId: "",
    listaPlanosDeContasId: "",
    listaCentrosCustoId: "",
    listaPortadoresId: "",
    listaEquipamentosId: "",
    listaSISPAGId: "",
    listaTiposDocumentoId: "",
    documento: "",
    cobrancaNaEmpresaId: 0,
    codigoBarras: "",
    mesReferenciaId: 0,
    numeroOrdemCompra: "",
    cNPJFornecedor: "",
    documentoContabilId: 0,
    pagamentoBloqueadoId: 0,
    pagamentoAprovadoId: 0,
    adiantamentoFornecedorId: 0,
    somenteTitulosPrevisao: false,
    exibirExcluidos: false,
    titulosPrevisao: false,
  },
  Itens: [],
};

export type FormCtsReceberType = {
  Form: {
    DadosRegistro: CtsReceberTypes;
  };
};

export type CtsReceberPesquisaGridTypes = {
  nomeColaborador?: string;
  dataCadastroInicial?: string;
  dataCadastroFinal?: string;
};

export type ListarCtsReceberTypes = {
  Pesquisa: ListarPesquisaCtsReceberTypes;
  Itens: ListarCtsReceberItensTypes[];
};

export type ListarPesquisaCtsReceberTypes = {
  dataInclusaoInicial: string;
  dataInclusaoFinal: string;
  dataVencimentoInicial: string;
  dataVencimentoFinal: string;
  dataPagamentoInicial: string;
  dataPagamentoFinal: string;
  dataBaixaInicial: string;
  dataBaixaFinal: string;
  valorDe: number;
  valorAte: number;
  listaClientesId: string;
  listaGruposEconomicosId: string;
  listaVendedoresId: string;
  listaPlanoDeContasId: string;
  listaMesesReferenciaId: string;
  listaTiposDeClientesExclusaoId: string;
  listaFormasDePagamentosId: string;
  listaTiposDeClientesId: string;
  listaRotasId: string;
  listaPracasId: string;
  listaEmpresasId: string;
  listaStatusId: string;
  listaFornecedoresId: string;
  listaPlanosDeContasId: string;
  listaCentrosCustoId: string;
  listaPortadoresId: string;
  listaEquipamentosId: string;
  listaSISPAGId: string;
  listaTiposDocumentoId: string;
  nossoNumero: string;
  titulosPrevisao: boolean;
  documento: string;
  cobrancaNaEmpresaId: number;
  codigoBarras: string;
  mesReferenciaId: number;
  numeroOrdemCompra: string;
  cNPJFornecedor: string;
  documentoContabilId: number;
  pagamentoBloqueadoId: number;
  pagamentoAprovadoId: number;
  adiantamentoFornecedorId: number;
  somenteTitulosPrevisao: boolean;
  exibirExcluidos: boolean;
};

export type ListarCtsReceberItensTypes = {
  nomeStatus: string;
  id: number;
  nomeEmpresa: string;
  documento: string;
  nomeFornecedor: string;
  nomePlanoDeContas: string;
  valor: number;
  valorAtual: number;
  valorPago: number;
  saldoPagar: number;
  dataVencimento: string;
  dataPagamento: string;
  identificacao: string;
  cobrancaNaEmpresa: boolean;
  nomeMesReferencia: string;
  pagamentoBloqueado: boolean;
  nomePagamentoBloqueado: string;
  numeroOrdemCompra: string;
  nomeOrdemCompra: string;
  nomePedidoImpresso: string;
  empresaNumeroParcela: string;
  portador: string;
  nomeCliente: string;
  nomeFantasia: string;
  dataCadastro: string;
  nomeFormaPagamento: string;
  saldoReceber: number;
  valorTotal: number;
};

export type ListarClientesBloqueadosItensTypes = {
  id: number;
  nomeCompleto: string;
  cPFCNPJ: string;
  usuarioBloq: string;
  dataBloqueio: string;
  diasBloqueados: string;
};

export type DesbloquearClientesBloqueadosTypes = {
  nomeRazaoSozial: number;
  cPFCNPJ: string;
  dataInicial: string;
  dataFinal: string;
};
