import { CGIHttp } from "../../../Services/Axios";
import { BITypes } from "../../../Types/Sistema/BI/BITypes";

const url = "/home";

export function HttpBI() {
  async function RetornarDadosBI(pId: string): Promise<BITypes> {
    return await (
      await new CGIHttp().conn.get(url + "/obter-dados-bi/" + pId)
    ).data.data;
  }
  return {
    RetornarDadosBI,
  };
}
