import { GridReadyEvent } from "ag-grid-community";
import { useState, useEffect } from "react";
import { UseFormReturn, FieldValues, useFormContext } from "react-hook-form";
import { CGIBox } from "../../Box/CGIBox";
import { CGIBoxItem } from "../../Box/CGIBoxItens";
import { CGIDataTableSimples } from "../../DataTable/CGIDataTableSimples";
import { CGICol } from "../../DataTable/DataTable/DataTableTypes";
import { CGIInput } from "../../Input/CGIInput";
import { HttpCGIResumosComposto } from "../HttpResumo";

type CGIResumoItensPedidotype = {
  watchitensPedidoId: string;
};

function CGIResumoItensPedido(
  watchitensPedidoId: string,
  formPrincipal: UseFormReturn<FieldValues, any>,
  formResumo: UseFormReturn<FieldValues, any>
) {
  const form = useFormContext();
  const Tipo = "ItensPedido";

  const [ColConvert, setColConvert] = useState<Array<CGICol<any>>>([]);
  const [Dados, setDados] = useState<Array<any>>([]);
  const [valueOld, setValueOld] = useState();

  const [ApiGrid, setGridApi] = useState<GridReadyEvent<any>>();

  useEffect(() => {
    var value =
      formPrincipal.getValues(watchitensPedidoId) ||
      formResumo.getValues(watchitensPedidoId);

    if (value == null || value === undefined || value === 0 || value === "") {
      return;
    }
    if (valueOld === value) {
      return;
    } else {
      setValueOld(value);
    }
    RetornarDados(value);
  }, [
    formPrincipal.watch(watchitensPedidoId),
    formResumo.watch(watchitensPedidoId),
  ]);
  async function RetornarDados(id: any) {
    var Dados = await HttpCGIResumosComposto().RetornarDados(id, Tipo);
    let ConvertCol = Dados.colunas.map((e: any) => {
      return {
        Campo: e.nomeTabela,
        Titulo: e.nomeExibicao,
        Tipo: "Texto",
      } as CGICol<any>;
    });
    setColConvert(ConvertCol);
    setDados(Dados.registros);

    //formPrincipal.reset({ ...Dados.registros[0] });
  }

  return (
    <CGIBox>
      <CGIBoxItem Col={12}>
        <CGIInput ocultar id={watchitensPedidoId} placeholder="" />
        <CGIDataTableSimples
          DisableGetId
          Att={{
            onGridReady: e => setGridApi(e),
            defaultColDef: {
              flex: 1,
              filter: false,
              autoHeight: true,
              cellStyle: {
                "font-size": 10,
              },
            },
          }}
          Altura="60vh"
          Colunas={ColConvert}
          Data={Dados}
        ></CGIDataTableSimples>
      </CGIBoxItem>
    </CGIBox>
  );
}

export const ResumoItensPedido = {
  id: "ResumoItensPedido",
  titulo: "Dados Itens do Pedido",
  componente: CGIResumoItensPedido,
};
