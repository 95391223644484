import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIRadioButton } from "../../../Components/RadioButton/CGIRadioButton";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import { CGITextArea } from "../../../Components/TextArea/CGITextArea";
import { AlterarMovEstoqueTypes } from "../../../Types/Almoxarifado/MovEstoque/MovEstoqueType";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";

type PropsAlterarMovEstoqueType = {
  CarregarDados: Function;
};

export const AlterarMovEstoque = forwardRef(
  (props: PropsAlterarMovEstoqueType, ref) => {
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current?.Abrir();
      },
      Alterar(pId: number) {
        form.setValue("produtoId", pId);
        CGIModalRef.current?.Abrir();
      },
    }));
    const form = useFormContext<AlterarMovEstoqueTypes>();
    const CGIModalRef = useRef<ModalBaseType>();

    async function CarregarDados(id: number) {}

    const Confirmar = async (data: any, event: any) => {};

    return (
      <CGIModal
        id="almoxarifado/movestoque/incluir"
        ref={CGIModalRef}
        Titulo="Mov. Estoque"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        CarregarDados={CarregarDados}
        Fechar={() => {}}
      >
        <CGIBox spacing="10px">
          <CGIBoxItem Col={12}>
            <CGIComboAjax<AlterarMovEstoqueTypes>
              id={"produtoId"}
              Link={"produto"}
              label={"Produto"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIResumo ProdutoId="produtoId" ResumoProdutoSimples />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<AlterarMovEstoqueTypes>
              id={"empresaId"}
              label={"Empresa"}
              Link="empresa"
              validadores={{
                required: "Empresa obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIRadioButton<AlterarMovEstoqueTypes>
              id={"id"}
              row={"column"}
              label={"Tipo de Operação"}
              values={[
                { label: "Entrada", valor: "E" },
                { label: "Saída", valor: "S" },
              ]}
              validadores={{
                required: "Tipo Operação obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<AlterarMovEstoqueTypes>
              label={"Quantidade"}
              id={"quantidade"}
              placeholder={"Quantidade"}
              validadores={{
                required: "Quantidade obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<AlterarMovEstoqueTypes>
              id={"tipoMovimentacaoId"}
              label={"Tipo de Movimentação"}
              Link="tipomovimentacaoestoque"
              validadores={{
                required: "Tipo Movimentação obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGITextArea<AlterarMovEstoqueTypes>
              id={"obs"}
              label={"Observação"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={8}>
            <CGIComboSimples<AlterarMovEstoqueTypes>
              id={"colaboradorId"}
              label={"Funcionario"}
              Link={"funcionario"}
              validadores={{
                required: "Funcionario obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIDatePicker<AlterarMovEstoqueTypes>
              id={"data"}
              label={"Data"}
              validadores={{
                required: "Data obrigatório",
              }}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
    //FINAL JSX
  }
);
