export const ObjConsultaProdutoXNFEntradaPadrao: FormConsultarProdutoXNFEntradaType =
  {
    form: {
      pesquisa: {
        codigoProduto: "",
        listaFornecedoresId: "",
        listaEmpresasId: "",
        itens: [],
      },
      itens: [],
    },
  };
export type FormConsultarProdutoXNFEntradaType = {
  form: {
    pesquisa: ConsultarProdutoXNFEntradaPesquisaType;
    itens: ConsultarProdutoXNFEntradaItensPrincipalType[];
  };
};

export type ConsultarProdutoXNFEntradaPesquisaType = {
  codigoProduto: string;
  listaFornecedoresId: string;
  listaEmpresasId: string;
  itens: ConsultarProdutoXNFEntradaItensPesquisaType[];
};
export type ConsultarProdutoXNFEntradaItensPesquisaType = {
  id: number;
  produtoId: number;
  codigoProduto: string;
  descricao: string;
  nomeUnidade: string;
  nomeFornecedor: string;
  codigoBarras: string;
};
export type ConsultarProdutoXNFEntradaItensPrincipalType = {
  id: number;
  produtoId: number;
  codigoProduto: string;
  nomeTipo: string;
  nomeUnidade: string;
  descricao: string;
  numeroNF: string;
  cnpjFornecedor: string;
  nomeFornecedor: string;
  dataCadastro: string;
  quantidade: number;
  vlrUnitario: number;
  numeroPedidoEntrada: string;
};
