import { Tag, TagLabel, Box, Text } from "@chakra-ui/react";
import { useCallback } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { CGIBox } from "../../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../../Components/Box/CGIBoxItens";
import { CGIDataTableSimples } from "../../../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../../../Components/DataTable/DataTable/DataTableTypes";
import {
  AnaliseCreditoNotasFiscaisItensType,
  AnaliseCreditoTypes,
} from "../../../../../Types/Financeiro/Colaborador/AnaliseCreditoTypes";
import { LegendaTypes } from "../../../../../Types/Modal/ModalBaseType";

export const GridNotasFiscais = (
  form: UseFormReturn<AnaliseCreditoTypes, any>
) => {
  const GridItens = useWatch({
    control: form.control,
    name: "Grids.NotasFiscais.Itens",
  });

  const Legendas: LegendaTypes = [
    { nomeStatus: "Incluído", cor: "green" },
    { nomeStatus: "Enviada", cor: "yellow" },
    { nomeStatus: "Autorizada", cor: "blue" },
    { nomeStatus: "Rejeitada", cor: "red" },
    { nomeStatus: "Cancelada", cor: "crimson" },
    { nomeStatus: "Inutilizada", cor: "lightblue" },
    { nomeStatus: "Devolvida", cor: "orange" },
    { nomeStatus: "Denegada", cor: "black" },
  ];
  const GridCol: Array<CGICol<AnaliseCreditoNotasFiscaisItensType>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      Status: Legendas,
      att: {
        maxWidth: 60,
        filter: false,
      },
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Numero",
      ocultar: true,
    },
    {
      Campo: "numeroNF",
      Titulo: "Nº NF",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 160,
      },
    },
    {
      Campo: "serie",
      Titulo: "Série NF",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 160,
      },
    },
    {
      Campo: "nomeTipo",
      Titulo: "Tipo",
      Tipo: "Texto",
      Ordernar: true,
    },
    {
      Campo: "pedidoCliente",
      Titulo: "Ped. Cli.",
      Tipo: "Texto",
      Ordernar: true,
    },
    {
      Campo: "nomeColaborador",
      Titulo: "Colaborador",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        minWidth: 250,
      },
    },
    {
      Campo: "nomeVendedor",
      Titulo: "Vendedor",
      Tipo: "Texto",
      Ordernar: true,
    },
    {
      Campo: "nomeNaturezaOperacao",
      Titulo: "Nat. Op.",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        minWidth: 120,
      },
    },
    {
      Campo: "dataAutorizacao",
      Titulo: "Data Saída",
      Tipo: "Data",
      Ordernar: true,
    },
    {
      Campo: "valorNF",
      Titulo: "Valor Total",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        minWidth: 60,
      },
    },
  ];

  return (
    <CGIBox>
      <CGIBoxItem Col={12}>
        <Text fontWeight={"bold"} color={"green"}>
          Últimos 10 Notas Fiscais
        </Text>
      </CGIBoxItem>
      <CGIBoxItem Col={12}>
        <CGIDataTableSimples
          DisableGetId
          Altura="400px"
          Colunas={GridCol}
          Data={GridItens}
        />
        <Box p={2}>
          {Legendas.map((e, index) => {
            return (
              <Tag
                mx={2}
                size={"md"}
                key={index}
                borderRadius="full"
                variant="solid"
                bgColor={e.cor}
              >
                <TagLabel color={"gray.100"}>{e.nomeStatus}</TagLabel>
              </Tag>
            );
          })}
        </Box>
      </CGIBoxItem>
    </CGIBox>
  );
};
