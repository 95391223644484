import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarMovPortadorItensTypes,
  ListarMovPortadorTypes,
  ObjListarMovPortadorPadrao,
  AlterarLancamentoTypes,
} from "../../../Types/Financeiro/MovPortador/MovPortadorTypes";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpMovPortador } from "./Http";
import { IncluirMovPortador } from "./_IncluirMovPortador";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { AlterarLancamento } from "./_AlterarLancamento";
import { EstornarLancamento } from "./_EstornarLancamento";

export function ListarMovPortador() {
  const IncluirMovPortadorRef = useRef<ModalBaseType>();
  const AlterarLancamentoRef = useRef<ModalBaseType>();
  const EstornarLancamentoRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarMovPortadorTypes>({
    defaultValues: ObjListarMovPortadorPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarMovPortadores = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpMovPortador().RetornarMovPortador(DadosFiltros);
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarMovPortadorItensTypes) => {
      return await HttpMovPortador().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpMovPortador()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Nova Movimentação",
      Permissao: "financeiro/movportador/incluir",
      OnClick: () => {
        IncluirMovPortadorRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Estornar Lançamento",
      Permissao: "financeiro/movportador/estornar",
      OnClick: (e: ListarMovPortadorItensTypes) => {
        EstornarLancamentoRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Alterar Lançamento",
      Permissao: "financeiro/movportador/alterarlancamento",
      OnClick: (e: ListarMovPortadorItensTypes) => {
        AlterarLancamentoRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Imprimir",
      Permissao: "financeiro/movportador/imprimir",
      OnClick: (e: ListarMovPortadorItensTypes) => {
        // CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const GridColunas: Array<CGICol<ListarMovPortadorItensTypes>> = [
    {
      Campo: "id",
      Titulo: "Mov",
      Tipo: "Texto",
      att: { maxWidth: 80 },
    },
    {
      Campo: "dataCadastro",
      Titulo: "Data Mov.",
      Tipo: "Data",
      att: { maxWidth: 130 },
    },
    {
      Campo: "obs",
      Titulo: "Obs.",
      Tipo: "Texto",
    },
    {
      Campo: "documento",
      Titulo: "Documento",
      Tipo: "Texto",
    },
    {
      Campo: "nomeColaborador",
      Titulo: "Colaborador",
      Tipo: "Texto",
    },
    {
      Campo: "nomePlanoDeContas",
      Titulo: "Plano de Contas",
      Tipo: "Texto",
    },
    {
      Campo: "operacao",
      Titulo: "Operação",
      Tipo: "Texto",
      att: { maxWidth: 120 },
    },
    {
      Campo: "valorMovimento",
      Titulo: "Vlr. Mov.",
      Tipo: "Numero",
      ExibirCoresConformeValor: true,
      att: { maxWidth: 100 },
    },
    {
      Campo: "nomeUsuario",
      Titulo: "Usuário",
      Tipo: "Texto",
      att: { maxWidth: 100 },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarMovPortadorItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          valor: "id",
          titulo: "Mov",
        },
        {
          valor: "obs",
          titulo: "Obs.",
        },
        {
          valor: "nomeColaborador",
          titulo: "Colaborador",
        },
        {
          valor: "nomePlanoDeContas",
          titulo: "Plano de Contas",
        },
        {
          valor: "operacao",
          titulo: "Operação",
        },
        {
          valor: "valorMovimento",
          titulo: "Valor Movimento",
        },
        {
          valor: "nomeUsuario",
          titulo: "Usuário",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir MovPortador"
          CarregarDados={RetornarMovPortadores}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <IncluirMovPortador
          CarregarDados={methods.handleSubmit(RetornarMovPortadores)}
          ref={IncluirMovPortadorRef}
        />
      ),
    },
    {
      Componente: (
        <AlterarLancamento
          CarregarDados={methods.handleSubmit(RetornarMovPortadores)}
          ref={AlterarLancamentoRef}
        />
      ),
    },
    {
      Componente: (
        <EstornarLancamento
          CarregarDados={methods.handleSubmit(RetornarMovPortadores)}
          ref={EstornarLancamentoRef}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarMovPortadorTypes>
            id="Pesquisa.listaPortadoresId"
            label="Portador"
            Link={"portador"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIDatePicker<ListarMovPortadorTypes>
            id="Pesquisa.dataInicial"
            label="Período - Inicial"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIDatePicker<ListarMovPortadorTypes>
            id="Pesquisa.dataFinal"
            label="Período - Final"
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="financeiro/movportador/listar"
      Titulo="Movimentação Financeira"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarMovPortadores)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
