import { CGIHttp } from "../../../Services/Axios";
import {
  FormPedidoEcommerceType,
  ListarPesquisaPedidoEcommerceTypes,
  ListarPedidoEcommerceItensTypes,
  PedidoEcommercePesquisaGridTypes,
  PedidoEcommerceTypes,
} from "../../../Types/Vendas/PedidoEcommerce/PedidoEcommerceTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpPedidoEcommerce() {
  const url = "/pedidoecommerce";

  async function RetornarDadosGridPesquisa(
    dados: PedidoEcommercePesquisaGridTypes
  ): Promise<ListarPedidoEcommerceItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-pedidos-ecommerce", dados)
      .then((e) => e.data.data);
  }

  async function RetornarPedidoEcommerces(
    DadosFiltros: ListarPesquisaPedidoEcommerceTypes
  ): Promise<ListarPedidoEcommerceItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(
        url + "/obter-pedidos-ecommerce",
        DadosFiltros
      )
    ).data.data;
  }

  async function RetornarDadosPedidoEcommerce(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + `/alterar/${id}`)
    ).data.data) as FormPedidoEcommerceType;
  }

  async function RetornarDadosExclusao(Dados: ListarPedidoEcommerceItensTypes) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + Dados.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }
  async function RetornarDadosVisualizarPedidoEcommerce(
    DadosPedidoEcommerce: FormPedidoEcommerceType
  ) {
    return (await (
      await new CGIHttp().conn.get(
        url + "/visualizar/" + DadosPedidoEcommerce.Form
      )
    ).data.data) as PedidoEcommerceTypes;
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir/" + Dados.id);
  }
  async function Alterar(DadosPedidoEcommerce: FormPedidoEcommerceType) {
    return await new CGIHttp().conn.post(
      url + "/alterar",
      DadosPedidoEcommerce.Form
    );
  }
  async function Visualizar(DadosPedidoEcommerce: FormPedidoEcommerceType) {
    return await new CGIHttp().conn.post(
      url + "/visualizar",
      DadosPedidoEcommerce.Form
    );
  }
  async function Incluir(DadosPedidoEcommerce: FormPedidoEcommerceType) {
    return await new CGIHttp().conn.post(
      url + "/incluir",
      DadosPedidoEcommerce.Form
    );
  }
  return {
    RetornarPedidoEcommerces,
    RetornarDadosGridPesquisa,
    RetornarDadosExclusao,
    RetornarDadosVisualizarPedidoEcommerce,
    RetornarDadosPedidoEcommerce,
    Visualizar,
    Excluir,
    Alterar,
    Incluir,
  };
}
