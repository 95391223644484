import { AxiosInstance } from "axios";
import { CGIHttp } from "../../Services/Axios";
const url = "home";
export function HttpHistorico() {
  async function RetornarDados(Origem: string, Id: number) {
    return await (
      await new CGIHttp().conn.get(
        url + "/obter-historico/" + Origem + "/" + Id
      )
    )?.data.data;
  }
  async function Incluir(dados: any) {
    return await new CGIHttp().conn.post(url + "/incluir-historico", dados);
  }

  return {
    RetornarDados,
    Incluir,
  };
}
