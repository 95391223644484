import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CGIHttp } from "../../../Services/Axios";

export function Logout() {
  const Navigate = useNavigate();
  useEffect(() => {
    new CGIHttp().logout();
    localStorage.removeItem("dadosUsuario");
    Navigate("/login");
  }, [Navigate]);

  return <></>;
}
