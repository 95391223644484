import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIRadioButton } from "../../../Components/RadioButton/CGIRadioButton";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import { CGITextArea } from "../../../Components/TextArea/CGITextArea";
import LoadingContext from "../../../Context/LoadingContext";
import { PortadorTypes } from "../../../Types/Sistema/Portador/PortadorTypes";
import { HttpPortador } from "./Http";

type AlterarPortadorType = {
  CarregarDados: Function;
};

export const AlterarPortador = forwardRef((props: AlterarPortadorType, ref) => {
  //INICIO REF
  useImperativeHandle(ref, () => ({
    Abrir() {
      CGIModalRef.current.Abrir();
    },
    Alterar(pEmpresa: any) {
      CGIModalRef.current.Abrir();

      HttpPortador()
        .RetornarDadosPortador(pEmpresa.id)
        .then((e) => {
          form.reset(e);
        });
    },
  }));
  //FINAL  REF

  //INICIO  HOOKS
  const CGIModalRef = useRef() as any;
  const form = useFormContext<PortadorTypes>();
  const { ToastErro, ToastSucesso } = useCGIToast();
  const { setLoadingState } = useContext(LoadingContext);
  const [ExibirCliente, setExibirCliente] = useState(false);
  const [ExibirCaixa, setExibirCaixa] = useState(false);
  const [ExibirBanco, setExibirBanco] = useState(false);
  const [ExibirConvCNAB, setExibirConvCNAB] = useState(false);
  const [ExibirVendedor, setExibirVendedor] = useState(false);
  const [ExibirFornecedor, setExibirFornecedor] = useState(false);
  const [ExibirCaixaBalcao, setExibirCaixaBalcao] = useState(false);
  const Radio = useWatch({
    control: form.control,
    name: "tipoPortadorId",
  });

  const AlterarRadio = useMemo(() => {
    if (Radio === undefined || Radio === null || Radio === "") {
      return;
    }
    if (Radio == "CL") {
      setExibirCliente(true);
      setExibirCaixa(false);
      setExibirBanco(false);
      setExibirConvCNAB(false);
      setExibirVendedor(false);
      setExibirFornecedor(false);
      setExibirCaixaBalcao(false);
    }
    if (Radio == "C") {
      setExibirCliente(false);
      setExibirCaixa(true);
      setExibirBanco(false);
      setExibirConvCNAB(false);
      setExibirVendedor(false);
      setExibirFornecedor(false);
      setExibirCaixaBalcao(false);
    }
    if (Radio == "B") {
      setExibirCliente(false);
      setExibirCaixa(false);
      setExibirBanco(true);
      setExibirConvCNAB(false);
      setExibirVendedor(false);
      setExibirFornecedor(false);
      setExibirCaixaBalcao(false);
    }
    if (Radio == "CV") {
      setExibirCliente(false);
      setExibirCaixa(false);
      setExibirBanco(false);
      setExibirConvCNAB(true);
      setExibirVendedor(false);
      setExibirFornecedor(false);
      setExibirCaixaBalcao(false);
    }
    if (Radio == "V") {
      setExibirCliente(false);
      setExibirCaixa(false);
      setExibirBanco(false);
      setExibirConvCNAB(false);
      setExibirVendedor(true);
      setExibirFornecedor(false);
      setExibirCaixaBalcao(false);
    }
    if (Radio == "F") {
      setExibirCliente(false);
      setExibirCaixa(false);
      setExibirBanco(false);
      setExibirConvCNAB(false);
      setExibirVendedor(false);
      setExibirFornecedor(true);
      setExibirCaixaBalcao(false);
    }
    if (Radio == "CB") {
      setExibirCliente(false);
      setExibirCaixa(false);
      setExibirBanco(false);
      setExibirConvCNAB(false);
      setExibirVendedor(false);
      setExibirFornecedor(false);
      setExibirCaixaBalcao(true);
    }
  }, [Radio]);
  //FINAL  HOOKS

  //INICIO CONST
  const RenderTabs: Array<CGITabItensTypes> = [
    {
      Titulo: "Dados Portador",
      Component: AreaDadosPortador(),
    },
  ];
  //FINAL  CONST

  //INICO FUNC
  function AreaDadosPortador() {
    return (
      <CGIBox>
        <CGIBoxItem Col={8}>
          <CGIInput<PortadorTypes>
            id="nome"
            label="Nome"
            placeholder="Nome"
            validadores={{
              required: "Nome Portador obrigatório",
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<PortadorTypes>
            id="empresaId"
            label="Empresa"
            Link="empresa"
            validadores={{
              required: "Empresa obrigatória",
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIBoxItem Col={12}>
            <CGIRadioButton<PortadorTypes>
              id={"tipoPortadorId"}
              row={"column"}
              label={"Tipo"}
              att={{
                fontWeight: "bold",
              }}
              values={[
                { label: "Cliente", valor: "CL" },
                { label: "Caixa", valor: "C" },
                { label: "Banco", valor: "B" },
                { label: "Convênio CNAB", valor: "CV" },
                { label: "Vendedor", valor: "V" },
                { label: "Fornecedor", valor: "F" },
                { label: "Caixa Balcão", valor: "CB" },
              ]}
            />
            <>{AlterarRadio}</>
          </CGIBoxItem>
        </CGIBoxItem>
        <CGIBoxItem hidden={!ExibirCaixa} Col={8}>
          <CGIBoxItem Col={6}>
            <CGITextArea<PortadorTypes>
              id="obsAbertura"
              label="Obs. Abertura"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGITextArea<PortadorTypes>
              id="obsFechamento"
              label="Obs. Fechamento"
            />
          </CGIBoxItem>
        </CGIBoxItem>
        <CGIBoxItem Col={8}>
          <CGIBoxItem hidden={!ExibirCliente} Col={4}>
            <CGIComboSimples<PortadorTypes>
              id="clienteId"
              label="Cliente"
              Link="cliente"
              validadores={{
                required: "Cliente obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem hidden={!ExibirVendedor} Col={4}>
            <CGIComboSimples<PortadorTypes>
              id="vendedorId"
              label="Vendedor"
              Link="vendedor"
              validadores={{
                required: "Vendedor obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem hidden={!ExibirFornecedor} Col={4}>
            <CGIComboSimples<PortadorTypes>
              id="fornecedorId"
              label="Fornecedor"
              Link="fornecedor"
              validadores={{
                required: "Fornecedor obrigatório",
              }}
            />
          </CGIBoxItem>
        </CGIBoxItem>
        <CGIBoxItem hidden={!ExibirBanco} Col={4}>
          <CGIBoxItem Col={12}>
            <CGIInput<PortadorTypes>
              id="banco"
              label="Banco"
              placeholder="Banco"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIInput<PortadorTypes>
              id="agencia"
              label="Agência"
              placeholder="Agência"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIInput<PortadorTypes>
              id="contaCorrente"
              label="Conta Corrente"
              placeholder="Conta Corrente"
            />
          </CGIBoxItem>
        </CGIBoxItem>
        <CGIBoxItem hidden={!ExibirCaixa} Col={4}>
          <CGIBoxItem Col={12}>
            <CGIInput<PortadorTypes>
              id="serieNFCPDVWeb"
              label="Série NFC PDVWeb"
              placeholder="Série NFC PDVWeb"
            />
          </CGIBoxItem>
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIBoxItem Col={12}>
            <CGICheckBox<PortadorTypes>
              id="efetuarLancamento"
              label="Efetuar Lançamentos"
              tooltip={"Informa se este Portador efetuará Lançamentos"}
            />
          </CGIBoxItem>
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIBoxItem Col={12}>
            <CGICheckBox<PortadorTypes> id="ativo" label="Ativo" tooltip="" />
          </CGIBoxItem>
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIBoxItem Col={12}>
            <CGICheckBox<PortadorTypes>
              id="portadorGeral"
              label="Portador Geral"
              tooltip=""
            />
          </CGIBoxItem>
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIBoxItem Col={12}>
            <CGICheckBox<PortadorTypes>
              id="utilizarNoPDVWeb"
              label="Utilizar no PDVWeb"
              tooltip=""
            />
          </CGIBoxItem>
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIBoxItem Col={12}>
            <CGICheckBox<PortadorTypes>
              id="privado"
              label="Privado"
              tooltip=""
            />
          </CGIBoxItem>
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIBoxItem Col={12}>
            <CGICheckBox<PortadorTypes>
              id="bloquearSaldoNegativo"
              label="Bloquear Saldo Negativo"
              tooltip=""
            />
          </CGIBoxItem>
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIBoxItem Col={12}>
            <CGICheckBox<PortadorTypes>
              id="bloquearEstornos"
              label="Bloquear Estorno"
              tooltip=""
            />
          </CGIBoxItem>
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIBoxItem Col={12}>
            <CGICheckBox<PortadorTypes>
              id="ocultarBaixasRelatoriosComissao"
              label="Ocultar baixas em relatório de comissão"
              tooltip=""
            />
          </CGIBoxItem>
        </CGIBoxItem>
      </CGIBox>
    );
  }

  const Confirmar = async (data: PortadorTypes, event: any) => {
    setLoadingState(true);

    if (data.id == null || data.id === undefined || data.id === 0) {
      HttpPortador()
        .Incluir(data)
        .then((response: any) => {
          ToastSucesso("Registro gravado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
        })
        .catch((e) => {
          return ToastErro(e);
        })
        .finally(() => {
          setLoadingState(false);
        });
    } else {
      HttpPortador()
        .Alterar(data)
        .then((data: any) => {
          ToastSucesso("Registro Alterado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
        })
        .catch((e) => {
          return;
        })
        .finally(() => {
          setLoadingState(false);
        });
    }
  };
  //FINAL FUNC

  //INICO JSX
  return (
    <CGIModal
      id="sistema/portador/alterar"
      ref={CGIModalRef}
      Titulo="Cadastro de Portador"
      TelaCheia={"6xl"}
      Confirmar={Confirmar}
      Fechar={() => {
        form.reset({});
      }}
    >
      <CGITabs Tabs={RenderTabs} />
    </CGIModal>
  );
  //FINAL JSX
});
