import { AxiosInstance } from "axios";
import { CGIHttp } from "../../Services/Axios";
const url = "home";
export function HttpMenu() {
  async function RetornarDados(link: string) {
    return await (
      await new CGIHttp().conn.post(url + "/obter-relatorios/", { link: link })
    )?.data.data;
  }

  return {
    RetornarDados,
  };
}
