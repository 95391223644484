import { CGIHttp } from "../../../../Services/Axios";
import {
  FormCondicaoPagamentoType,
  ListarCondicaoPagamentoItensTypes,
  ListarPesquisaCondicaoPagamentoTypes,
} from "../../../../Types/Sistema/Classificacao/CondicaoPagamento/CondicaoPagamentoTypes";

const url = "/classificacao";

export function HttpCondicaoPagamento() {
  async function RetornarCondicoesPagamento(
    DadosFiltros: ListarPesquisaCondicaoPagamentoTypes
  ) {
    return (
      await new CGIHttp().conn.post(
        url + "/obter-condicao-pagamento",
        DadosFiltros
      )
    ).data.data;
  }

  async function RetornarDadosCondicaoPagamento(id: number) {
    return await new CGIHttp().conn.get(
      url + `/alterar-condicao-pagamento/${id}`
    );
  }

  async function Incluir(Dados: FormCondicaoPagamentoType) {
    return await new CGIHttp().conn.post(
      url + "/incluir-condicao-pagamento",
      Dados.Form
    );
  }
  async function Alterar(Dados: FormCondicaoPagamentoType) {
    return await new CGIHttp().conn.post(
      url + "/alterar-condicao-pagamento",
      Dados.Form
    );
  }
  async function Visualizar(pId: number) {
    return await new CGIHttp().conn.get(
      url + "/visualizar-condicao-pagamento/" + pId
    );
  }
  async function RetornarDadosExclusao(
    Dados: ListarCondicaoPagamentoItensTypes
  ) {
    return await new CGIHttp().conn.get(
      url + "/excluir-condicao-pagamento/" + Dados.id
    );
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(
      url + "/excluir-condicao-pagamento",
      Dados
    );
  }
  return {
    RetornarCondicoesPagamento,
    RetornarDadosCondicaoPagamento,
    RetornarDadosExclusao,
    Incluir,
    Alterar,
    Visualizar,
    Excluir,
  };
}
