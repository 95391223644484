import { CGIHttp } from "../../../Services/Axios";
import {
  ConsultarProdutoXNFEntradaItensPesquisaType,
  ConsultarProdutoXNFEntradaItensPrincipalType,
  ConsultarProdutoXNFEntradaPesquisaType,
} from "../../../Types/Almoxarifado/NFEntrada/ConsultarProdutoXNFEntrada";

export function HttpNFEntrada() {
  const url = "/NFEntrada";

  async function RetornarProdutosXNFEntrada(
    dados: ConsultarProdutoXNFEntradaPesquisaType
  ): Promise<ConsultarProdutoXNFEntradaItensPesquisaType[]> {
    return await new CGIHttp().conn
      .post("/produto/obter-produtos-x-nfentrada", {
        codigoProduto: dados.codigoProduto,
        listaFornecedoresId: dados.listaFornecedoresId,
        listaEmpresasId: dados.listaEmpresasId,
      })
      .then(e => e.data.data);
  }
  async function RetornarProdutosXNFEntradaEspecifico(
    dados: ConsultarProdutoXNFEntradaItensPesquisaType,
    dadosFiltro: ConsultarProdutoXNFEntradaPesquisaType
  ): Promise<ConsultarProdutoXNFEntradaItensPrincipalType[]> {
    return await new CGIHttp().conn
      .post("/produto/obter-produtos-x-nfentrada-produto-especifico", {
        produtoId: dados.produtoId,
        listaFornecedoresId: dadosFiltro.listaFornecedoresId,
        listaEmpresasId: dadosFiltro.listaEmpresasId,
      })
      .then(e => e.data.data);
  }

  return {
    RetornarProdutosXNFEntrada,
    RetornarProdutosXNFEntradaEspecifico,
  };
}
