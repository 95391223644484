import { CGIHttp } from "../../Services/Axios";

export function HttpSearchDetail() {
  async function RetornarItens(link: string, busca: string) {
    return await new CGIHttp().conn
      .post(link, { buscaProduto: busca })
      .then(e => e.data.data as Array<any>);
  }
  return {
    RetornarItens,
  };
}
