import { Flex } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useForm } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import {
  FormAbastecimentoType,
  ObjAbastecimentoPadrao,
} from "../../../Types/Manutencao/Abastecimento/AbastecimentoTypes";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { HttpAbastecimento } from "./Http";
import { CGINumberInput } from "../../../Components/NumberInput/CGINumberInput";

export const AlterarAbastecimento = forwardRef(
  (
    props: {
      CarregarDados: () => void;
    },
    ref
  ) => {
    const form = useFormContext<FormAbastecimentoType>();
    const CGIModalRef = useRef<ModalBaseType>();
    const Valores = useWatch({
      control: form.control,
      name: [
        "Form.DadosRegistro.valorUnitario",
        "Form.DadosRegistro.qtdLitros",
      ],
    });

    const AlterarValores = useMemo(() => {
      let valorUnitario = Valores[0];
      let qtdLitros = Valores[1];
      let valorTotal = 0;

      if (valorUnitario !== 0 && qtdLitros !== 0) {
        valorTotal = valorUnitario * qtdLitros;
        form.setValue("Form.DadosRegistro.valorTotal", valorTotal);
      } else {
        return;
      }
    }, [Valores]);

    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        form.reset(ObjAbastecimentoPadrao);
        CGIModalRef.current?.Abrir();
      },
      Alterar(pAbastecimentoId: number) {
        CGIModalRef.current?.Abrir();
      },
    }));

    return (
      <CGIModal
        id="sistema/classificacao/alterar"
        ref={CGIModalRef}
        Titulo="Abastecimento"
        TelaCheia={"6xl"}
        CarregarDados={() => {}}
        Confirmar={(e: any) => {}}
        Fechar={() => {
          form.reset(ObjAbastecimentoPadrao);
        }}
      >
        <CGIBox>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormAbastecimentoType>
              id="Form.DadosRegistro.empresaId"
              label="Empresa"
              Link={"empresa"}
              validadores={{
                required: "Campo obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormAbastecimentoType>
              id="Form.DadosRegistro.postoId"
              label="Posto"
              Link={"postocombustivel"}
              validadores={{
                required: "Campo obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormAbastecimentoType>
              id="Form.DadosRegistro.centroDeCustoId"
              label="Centro de Custo"
              Link={"centrodeCusto"}
              validadores={{
                required: "Campo obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGINumberInput<FormAbastecimentoType>
              id="Form.DadosRegistro.km"
              label="KM / Horímetro"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormAbastecimentoType>
              id="Form.DadosRegistro.equipamentoId"
              label="Equipamento"
              Link={"equipamento"}
              validadores={{
                required: "Campo obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormAbastecimentoType>
              id="Form.DadosRegistro.colaboradorId"
              label="Colaborador Responsável"
              Link={"funcionario"}
              validadores={{
                required: "Campo obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormAbastecimentoType>
              label="Nota Fiscal \ Recibo"
              id="Form.DadosRegistro.numeroNF"
              placeholder="0"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIDatePicker<FormAbastecimentoType>
              id="Form.DadosRegistro.dataAbastecimento"
              label="Data Abastecimento"
              validadores={{
                required: "Campo",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <>{AlterarValores}</>
            <CGINumberInput<FormAbastecimentoType>
              id="Form.DadosRegistro.valorUnitario"
              label="Valor Unitário"
              //att={{}}
              validadores={{
                required: "Campo obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormAbastecimentoType>
              label="Qtd. Litros"
              id="Form.DadosRegistro.qtdLitros"
              placeholder="0"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGINumberInput<FormAbastecimentoType>
              label="Vlr. Total"
              id="Form.DadosRegistro.valorTotal"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormAbastecimentoType>
              id="Form.DadosRegistro.planoDeContasId"
              label="Plano de Contas"
              Link={"planodecontas-despesas-conta"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIComboSimples<FormAbastecimentoType>
              id="Form.DadosRegistro.combustivelId"
              label="Combustível"
              Link={"tipoestoque"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGICheckBox<FormAbastecimentoType>
              id={"Form.DadosRegistro.incluirNoCtsPagar"}
              label={"Incluir no Contas a pagar"}
              tooltip={"Inclui no Contas a Pagar"}
              att={{
                style: { marginTop: 30 },
              }}
              attLabel={{
                style: { marginTop: 30 },
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIInput<FormAbastecimentoType>
              id="Form.DadosRegistro.obsOutrasDespesas"
              label="Observações Outras Despesas"
              placeholder=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIInput<FormAbastecimentoType>
              id="Form.DadosRegistro.obs"
              label="Observações"
              placeholder=""
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
    //FINAL JSX
  }
);
