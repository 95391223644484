import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Box, Container, useMediaQuery } from "@chakra-ui/react";
import { useContext } from "react";
import { useAuth } from "../Hook/useAuth";
import { CGIHeader } from "./Header/CGIHeader";
import { TalkTo } from "../../Services/Talkto/Talkto";
import { RetornarDadosUsuario } from "../../Services/Util/Util";
import TrocarSenhaContext from "../../Context/TrocarSenhaContext";
import { CGIFooter } from "./Footer/CGIFooter";
import { TamanhoTelaMobile } from "../../Themes/Temas";
import { CGIMenuPesquisaProvider } from "../MenuPesquisa/CGIMenuPesquisaProvider";
import FrameContext from "../../Context/FrameConxtext";

//11/08/2023 --  Lucas Oliveira
//
//Adicionado o contexto para fazer o controle do layoute para que funcione em frame e em tela cheia
//Controle feito pelo isFrame , se isFrame for true as barras do sistema e o tawlk to iram ficar desabilitados

export const ProtectedLayout = () => {
  var Logado = useAuth();
  const [isMobile] = useMediaQuery(TamanhoTelaMobile);

  const { AbrirTrocarSenha } = useContext(TrocarSenhaContext);
  const { isFrame } = useContext(FrameContext);
  const location = useLocation();


  if (!Logado) {
    return <Navigate to={"/Login?pathname=" + location.pathname + ""} />;
  }
  var DadosUsuario = RetornarDadosUsuario();

  if (DadosUsuario.trocarSenhaProximoLogon) {
    AbrirTrocarSenha(true);
  }

  return (
    <Box style={{ overflowY: "hidden" }} h="100vh" bg="#F4F7FE">
      <CGIMenuPesquisaProvider>
        <Box>
          {
            !isFrame && (
              <CGIHeader />
            )
          }
        </Box>
        <Container
          style={{ overflowY: "scroll", height: !isFrame ? "calc(100vh - 75px)" : "100vh" }}
          maxW="full"
          w={"100%"}
        >
          <Outlet />
        </Container>
        <Box>
          {!isMobile && !isFrame && (
            <>
              <CGIFooter />
              <TalkTo />
            </>
          )}
        </Box>
      </CGIMenuPesquisaProvider>
    </Box>
  );
};
