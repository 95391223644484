import { forwardRef, useImperativeHandle } from "react";
import { CGIBox } from "../../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../../Components/Box/CGIBoxItens";
import { CGIComboSimples } from "../../../../../Components/Combos/CGIComboSimples";
import { CGIDatePicker } from "../../../../../Components/DatePicker/CGIDatePicker";
import { CGIInput } from "../../../../../Components/Input/CGIInput";
import { CGINumberInput } from "../../../../../Components/NumberInput/CGINumberInput";
import { PedidoLojaType } from "../../../../../Types/Vendas/Pedido/PedidoLojaType";

export const AreaTransporte = forwardRef((props: {}, ref) => {
  useImperativeHandle(ref, () => ({}));
  return (
    <CGIBox>
      <CGIBoxItem Col={6}>
        <CGIComboSimples<PedidoLojaType>
          Link={"empresa"}
          label={"Transportadora"}
          id={"DadosRegistro.transportadoraId"}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={6}>
        <CGIComboSimples<PedidoLojaType>
          Link={"empresa"}
          label={"Redespacho"}
          id={"DadosRegistro.redespachoId"}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={12}>
        <CGIInput<PedidoLojaType>
          id={"DadosRegistro.enderecoEntregaId"}
          label={"Endereço Entrega"}
          placeholder={""}
          mascara={"CPF"}
          validadores={{
            required: {
              message: "Campo obrigatório",
              value: true,
            },
            minLength: 8,
          }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIComboSimples<PedidoLojaType>
          Link={"empresa"}
          label={"Tipo de Frete"}
          id={"DadosRegistro.tipoFreteId"}
        />
      </CGIBoxItem>

      <CGIBoxItem Col={4}>
        <CGINumberInput<PedidoLojaType>
          id={"DadosRegistro.valorFrete"}
          prefix
          label={"Valor de Frete"}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGINumberInput<PedidoLojaType>
          id={"DadosRegistro.valorDescarga"}
          prefix
          label={"Valor de Descarga"}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={12}>
        <CGIBox
          titulo="Informações para a Nota Fiscal"
          popover="Informações para a Nota Fiscal"
        >
          <CGIBoxItem Col={4}>
            <CGINumberInput<PedidoLojaType>
              id={"DadosNFSaida.pesoLiquido"}
              prefix
              label={"Peso Liquido"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGINumberInput<PedidoLojaType>
              id={"DadosNFSaida.pesoBruto"}
              prefix
              label={"Peso Bruto"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIDatePicker<PedidoLojaType>
              id={"DadosNFSaida.dataSaida"}
              label={"Data da Saída"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<PedidoLojaType>
              id={"DadosNFSaida.horaSaida"}
              placeholder={"Hora da Saída"}
              label={"Hora da Saída"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGINumberInput<PedidoLojaType>
              id={"DadosNFSaida.qtdVolumes"}
              label={"Qtd. Volumes"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<PedidoLojaType>
              id={"DadosNFSaida.especie"}
              placeholder={"Espécie"}
              label={"Espécie"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<PedidoLojaType>
              id={"DadosNFSaida.marca"}
              placeholder={"Marca"}
              label={"Marca"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<PedidoLojaType>
              id={"DadosNFSaida.placaVeiculo"}
              placeholder={"Placa Veículo"}
              label={"Placa Veículo"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<PedidoLojaType>
              id={"DadosNFSaida.ufVeiculo"}
              placeholder={"UF Veículo"}
              label={"UF Veículo"}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIBoxItem>
    </CGIBox>
  );
});
