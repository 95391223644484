import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useContext,
  useState,
} from "react";
import { useForm, useFormContext, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import { CGITextArea } from "../../../Components/TextArea/CGITextArea";
import LoadingContext from "../../../Context/LoadingContext";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import {
  EmpresaTypes,
  UsuariosEmpresaTypes,
  ObjPadrao,
  FormEmpresaType,
} from "../../../Types/Sistema/Empresa/EmpresaTypes";
import { HttpEmpresa } from "./Http";

type AlterarEmpresaType = {
  CarregarDados: Function;
};

export const AlterarEmpresa = forwardRef((props: AlterarEmpresaType, ref) => {
  //INICIO REF
  useImperativeHandle(ref, () => ({
    Abrir() {
      RetornarUsuarios(0);
      CarregarDadosGET();
      CGIModalRef.current?.Abrir();
    },
    Alterar(pEmpresaId: number) {
      CarregarDados(pEmpresaId);
      CGIModalRef.current?.Abrir();
    },
  }));
  //FINAL  REF

  //INICIO  HOOKS
  const CGIModalRef = useRef<ModalBaseType>(null);
  const form = useFormContext<EmpresaTypes>();
  const { ToastErro, ToastSucesso } = useCGIToast();
  const { setLoadingState } = useContext(LoadingContext);
  const methods = useForm<FormEmpresaType>({
    defaultValues: ObjPadrao,
  });

  const GridUsuarios = useWatch({
    control: methods.control,
    name: "Form.Usuarios",
  });

  //FINAL  HOOKS

  //INICIO CONST
  const GridColunasUsuarios: Array<CGICol<UsuariosEmpresaTypes>> = [
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        hide: true,
      },
    },
    {
      Campo: "nome",
      Titulo: "Nome",
      Tipo: "Texto",
    },
    {
      Campo: "selecionado",
      Titulo: "Sel.",
      Tipo: "CheckBox",
      att: {
        editable: false,
      },
    },
  ];
  const RenderTabs: Array<CGITabItensTypes> = [
    {
      Titulo: "Dados do Empresa",
      Component: AreaDadosEmpresa(),
    },
    {
      Titulo: "Séries de DFe",
      Component: AreaSerieDFe(),
    },
    {
      Titulo: "Certificado Digital",
      Component: AreaCertificadoDigital(),
    },
    {
      Titulo: "Menu",
      Component: AreaMenu(),
    },
    {
      Titulo: "Usuários",
      Component: AreaUsuarios(),
    },
    {
      Titulo: "Configurações",
      Component: AreaConfiguracoes(),
    },
  ];
  //FINAL  CONST

  //INICO FUNC
  async function CarregarDadosGET() {
    await HttpEmpresa()
      .IncluirGET()
      .then(e => {
        form.reset(e);
      });
  }

  async function CarregarDados(id: number) {
    if (id) {
      HttpEmpresa()
        .RetornarDadosEmpresa(id)
        .then(e => {
          form.reset(e);
        });
      RetornarUsuarios(id);
    }
  }
  function AreaDadosEmpresa() {
    return (
      <CGIBox>
        <CGIBoxItem Col={5}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.razaoSocial"
            label="Razão Social"
            placeholder="Razão Social"
            validadores={{
              required: "Razão Social obrigatória",
              maxLength: {
                value: 100,
                message: "Máximo de [ 100 ] Caracteres",
              },
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.nomeExibicao"
            label="Nome Exibição"
            placeholder="Nome Exibição"
            validadores={{
              required: "Nome Exibição obrigatório",
              maxLength: { value: 30, message: "Máximo de [ 30 ] Caracteres" },
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.nomeFantasia"
            label="Nome Fantasia"
            placeholder="Nome Fantasia"
            validadores={{
              required: "Nome Fantasia obrigatório",
              maxLength: {
                value: 100,
                message: "Máximo de [ 100 ] Caracteres",
              },
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.sigla"
            label="Sigla"
            placeholder="Sigla"
            validadores={{
              required: "Sigla obrigatória",
              minLength: {
                value: 3,
                message: "A sigla deve conter [ 3 ] caracteres",
              },
              maxLength: {
                value: 3,
                message: "A sigla deve conter [ 3 ] caracteres",
              },
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.cnpj"
            label="CNPJ"
            placeholder="CNPJ"
            mascara="CNPJ"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.ie"
            label="Inscr. Estadual"
            placeholder="Inscr. Estadual"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.inscricaoMunicipal"
            label="Inscr. Municipal"
            placeholder="Inscr. Municipal"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.crt"
            label="CRT"
            placeholder="CRT"
            validadores={{
              min: { value: 1, message: "O CRT deve ser entre 1 e 3" },
              max: { value: 3, message: "O CRT deve ser entre 1 e 3" },
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.telefoneCelular"
            label="Telefone"
            placeholder="Telefone"
            mascara="Tel"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.endereco"
            label="Endereço"
            placeholder="Endereço"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.numeroEndereco"
            label="Nº"
            placeholder="Nº"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.complemento"
            label="Complemento"
            placeholder="Complemento"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.bairro"
            label="Bairro"
            placeholder="Bairro"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.cidade"
            label="Cidade"
            placeholder="Cidade"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.maximoNSU"
            label="Estado"
            placeholder="Estado"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.cep"
            label="CEP"
            placeholder="CEP"
            mascara="CEP"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.cidadeIBGE"
            label="Cidade IBGE"
            placeholder="Cidade IBGE"
            validadores={{
              required: "O código de cidade é obrigatório",
              minLength: {
                value: 7,
                message: "O código de cidade deve conter 7 caracteres",
              },
              maxLength: {
                value: 7,
                message: "O código de cidade deve conter 7 caracteres",
              },
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.estadoIBGE"
            label="Estado IBGE"
            placeholder="Estado IBGE"
            validadores={{
              required: "O código de estado é obrigatório",
              minLength: {
                value: 2,
                message: "O código de estado deve conter 2 caracteres",
              },
              maxLength: {
                value: 2,
                message: "O código de estado deve conter 2 caracteres",
              },
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.email"
            label="Email"
            placeholder="Email"
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }
  function AreaSerieDFe() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.serieNF"
            label="Série NF-e"
            placeholder="Série NF-e"
            validadores={{
              minLength: { value: 1, message: "Mínimo de [ 1 ] caractere " },
              maxLength: {
                value: 999999999,
                message: "Máximo de [ 999999999 ] caracteres ",
              },
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.serieNFC"
            label="Série NFC-e"
            placeholder="Série NFC-e"
            validadores={{
              minLength: { value: 1, message: "Mínimo de [ 1 ] caractere " },
              maxLength: {
                value: 999999999,
                message: "Máximo de [ 999999999 ] caracteres ",
              },
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.serieNFS"
            label="Série NFS-e"
            placeholder="Série NFS-e"
            validadores={{
              minLength: { value: 1, message: "Mínimo de [ 1 ] caractere " },
              maxLength: {
                value: 999999999,
                message: "Máximo de [ 999999999 ] caracteres ",
              },
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.serieCT"
            label="Série CT-e"
            placeholder="Série CT-e"
            validadores={{
              minLength: { value: 1, message: "Mínimo de [ 1 ] caractere " },
              maxLength: {
                value: 999999999,
                message: "Máximo de [ 999999999 ] caracteres ",
              },
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.serieMDF"
            label="Série MDF-e"
            placeholder="Série MDF-e"
            validadores={{
              minLength: { value: 1, message: "Mínimo de [ 1 ] caractere " },
              maxLength: {
                value: 999999999,
                message: "Máximo de [ 999999999 ] caracteres ",
              },
            }}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  function AreaCertificadoDigital() {
    return (
      <CGIBox titulo="Certificados" popover="Certificados Do Sistema">
        <CGIBoxItem Col={4}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.senhaCertificado"
            label="Senha Certificado"
            placeholder="Senha Certificado"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.certificado"
            label="String Certificado"
            placeholder="String Certificado"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.maximoNSU"
            label="Máximo NSU"
            placeholder="Máximo NSU"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.ultimoNSU"
            label="Último NSU"
            placeholder="Último NSU"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.codigoCSC"
            label="Código CSC"
            placeholder="Código CSC"
            validadores={{
              required: "Número Obrigatório",
              minLength: { value: 1, message: "Mínimo de [ 1 ] caractere" },
              maxLength: { value: 60, message: "Máximo de [ 60 ] caracteres" },
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.idCSC"
            label="Id CSC"
            placeholder="Id CSC"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<FormEmpresaType>
            id="Form.DadosRegistro.dataVencimentoCertificado"
            label="Data Vencimento Certificado"
            placeholder="Data Vencimento Certificado"
            mascara="Data"
            validadores={{
              required: "Número Obrigatório",
              minLength: { value: 1, message: "Mínimo de [ 1 ] caractere" },
              maxLength: { value: 60, message: "Máximo de [ 60 ] caracteres" },
            }}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  function AreaMenu() {
    return (
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGITextArea<FormEmpresaType>
            id="Form.DadosRegistro.menuSistemaNovo"
            label={"Menu"}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  function AreaUsuarios() {
    return (
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIDataTableSimples
            Data={GridUsuarios}
            Colunas={GridColunasUsuarios}
            Altura={"400px"}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  function AreaConfiguracoes() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGICheckBox<FormEmpresaType>
            id="Form.DadosRegistro.ativarTodosProdutos"
            label="Ativar Todos os Produtos"
            tooltip={"Ativar Todos os Produtos"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGICheckBox<FormEmpresaType>
            id={"Form.DadosRegistro.ativo"}
            label="Ativo"
            tooltip={"Ativo"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGICheckBox<FormEmpresaType>
            id="Form.DadosRegistro.copiarPrecosDaEmpresa"
            label="Copiar Preços nas Empresas"
            tooltip={"Copiar Preços nas Empresas"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGICheckBox<FormEmpresaType>
            id="Form.DadosRegistro.empresaEstoqueGeral"
            label="Contabilizar Estoque Geral"
            tooltip={"Contabilizar Estoque Geral"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGICheckBox<FormEmpresaType>
            id="Form.DadosRegistro.permitirImportacaoNFSaida"
            label="Permitir Importação de NF Saida"
            tooltip={"Permitir Importação de NF Saida"}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  async function RetornarUsuarios(EmpresaId: number) {
    var data = await HttpEmpresa().RetornarUsuariosDaEmpresa(EmpresaId);
    methods.setValue("Form.Usuarios", data);
  }

  const Confirmar = async (data: FormEmpresaType) => {
    setLoadingState(true);
    if (
      data.Form.DadosRegistro.id == null ||
      data.Form.DadosRegistro.id === undefined ||
      data.Form.DadosRegistro.id === 0
    ) {
      HttpEmpresa()
        .Incluir(data)
        .then((response: any) => {
          ToastSucesso("Registro gravado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current?.Fechar();
        })
        .catch(e => {
          return ToastErro(e);
        })
        .finally(() => {
          setLoadingState(false);
        });
    } else {
      HttpEmpresa()
        .Alterar(data)
        .then((data: any) => {
          ToastSucesso("Registro Alterado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current?.Fechar();
        })
        .catch(e => {
          return;
        })
        .finally(() => {
          setLoadingState(false);
        });
    }
  };
  //FINAL FUNC

  //INICO JSX
  return (
    <CGIModal
      id="sistema/empresa/alterar"
      ref={CGIModalRef}
      Titulo="Cadastro de Empresa"
      TelaCheia={"6xl"}
      Confirmar={(e: any) => {
        methods.handleSubmit(Confirmar)(e);
      }}
      CarregarDados={() => {}}
      Fechar={() => {
        methods.reset(ObjPadrao);
      }}
    >
      <CGITabs Tabs={RenderTabs} />
    </CGIModal>
  );
  //FINAL JSX
});
