import {
  ColDef,
  NewColumnsLoadedEvent,
  ValueFormatterParams,
} from "ag-grid-community";
import moment from "moment";
import { NumberInputToNumber } from "../../Prototypes/Formatter";

export function FormatarMoeda(params: ValueFormatterParams) {
  return FormatarReal(params.value);
}

export function FormatarReal(number: any) {
  return Number(number).toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
}

export function FormatarData(params: ValueFormatterParams) {
  if (!params.value) {
    return "";
  }
  return moment(params.value).format("DD/MM/yyyy");
}
export function FormatarDataHora(params: ValueFormatterParams) {
  if (!params.value) {
    return "";
  }
  return moment(params.value).format("DD/MM/yyyy hh:mm");
}
export function FormatarHora(params: ValueFormatterParams) {
  if (!params.value) {
    return "";
  }
  return moment(params.value).format("hh:mm");
}
