import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIDataTable } from "../../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../../Context/LoadingContext";
import { ModalBaseType } from "../../../../Types/Modal/ModalBaseType";
import {
  ListarAssinaturaEletronicaZapSignItensTypes,
  ListarAssinaturaEletronicaZapSignTypes,
  ObjListarAssinaturaEletronicaZapSignPadrao,
} from "../../../../Types/Sistema/Classificacao/AssinaturaEletronicaZapSign/AssinaturaEletronicaZapSignTypes";
import { DeleteTypes } from "../../../../Types/Util/DeleteTypes";

import { HttpAssinaturaEletronicaZapSign } from "./Http";
import { AlterarAssinaturaEletronicaZapSign } from "./_AlterarAssinaturaEletronicaZapSign";

export function ListarAssinaturaEletronicaZapSign() {
  //Inicio Hooks
  const AlterarAssinaturaEletronicaZapSignRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarAssinaturaEletronicaZapSignTypes>({
    defaultValues: ObjListarAssinaturaEletronicaZapSignPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  async function Excluir(data: DeleteTypes) {
    await HttpAssinaturaEletronicaZapSign()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }
  //Final Hooks

  //------------------------

  //Inicio Consts
  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Nova Assinatura Eletrônica ZapSign",
      Permissao: "sistema/classificacao/incluirassinaturaeletronicazapsign",
      OnClick: () => {
        AlterarAssinaturaEletronicaZapSignRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Alterar",
      Permissao: "sistema/classificacao/alterarassinaturaeletronicazapsign",
      OnClick: (e: ListarAssinaturaEletronicaZapSignItensTypes) => {
        AlterarAssinaturaEletronicaZapSignRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/classificacao/excluirassinaturaeletronicazapsign",
      OnClick: (e: ListarAssinaturaEletronicaZapSignItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<
    CGICol<ListarAssinaturaEletronicaZapSignItensTypes>
  > = [
    {
      Campo: "ativo",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: [
        {
          nomeStatus: "true",
          cor: "green",
        },
        {
          nomeStatus: "false",
          cor: "red",
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      att: {
        maxWidth: 100,
      },
    },
    {
      Campo: "nome",
      Titulo: "Nome",
      Tipo: "Texto",
      att: {
        maxWidth: 250,
      },
    },
    {
      Campo: "token",
      Titulo: "Token",
      Tipo: "Texto",
    },
    {
      Campo: "link",
      Titulo: "Link",
      Tipo: "Texto",
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarAssinaturaEletronicaZapSignItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Assinatura Eletrônica ZapSign"
          CarregarDados={methods.handleSubmit(
            RetornarAssinaturaEletronicaZapSigns
          )}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarAssinaturaEletronicaZapSign
          CarregarDados={methods.handleSubmit(
            RetornarAssinaturaEletronicaZapSigns
          )}
          ref={AlterarAssinaturaEletronicaZapSignRef}
        />
      ),
    },
  ];

  //Final Consts

  //------------------------

  //Inicio Function

  async function RetornarAssinaturaEletronicaZapSigns() {
    setLoadingState(true);
    const DadosFiltro = methods.getValues("Pesquisa");
    var data = await HttpAssinaturaEletronicaZapSign()
      .RetornarAssinaturaEletronicaZapSign(DadosFiltro)
      .finally(() => {
        setLoadingState(false);
      });
    methods.setValue("Itens", data);
  }

  async function RetornarDadosExclusao(
    data: ListarAssinaturaEletronicaZapSignItensTypes
  ) {
    return await HttpAssinaturaEletronicaZapSign().RetornarDadosExclusao(data);
  }

  //Final Function

  //------------------------

  // Inicio JSX

  function Filtros() {
    return <CGIBox children={undefined}></CGIBox>;
  }

  return (
    <>
      <CGIListar
        id="sistema/classificacao/listar"
        Titulo="Listar Assinatura Eletrônica ZapSign"
        Janelas={Janelas}
      >
        <FormProvider {...methods}>
          <CGIDataTable
            LimparFiltros={() => methods.reset()}
            Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
            Pesquisar={methods.handleSubmit(
              RetornarAssinaturaEletronicaZapSigns
            )}
            Filtro={Filtros()}
            Data={GridItens}
            Colunas={GridColunas}
            Legenda={Legendas}
          />
        </FormProvider>
      </CGIListar>
    </>
  );
  // Final JSX
}
