import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIModal } from "../../../../Components/Modal/CGIModal";
import { ModalBaseType } from "../../../../Types/Modal/ModalBaseType";
import {
  ListarNaturezaOperacaoItensTypes,
  NaturezaOperacaoVisualizarTypes,
} from "../../../../Types/Sistema/Classificacao/NaturezaOperacao/NaturezaOperacaoTypes";
import { HttpNaturezaOperacao } from "./Http";

type VisualizarNaturezaOperacaoType = {
  CarregarDados: Function;
};
export const VisualizarNaturezaOperacao = forwardRef(
  (props: VisualizarNaturezaOperacaoType, ref) => {
    // INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir(e: ListarNaturezaOperacaoItensTypes) {
        CGIModalRef.current?.Abrir();
        CarregarDados(e.id);
      },
    }));
    // FINAL REF

    const CGIModalRef = useRef<ModalBaseType>(null);
    const { reset } = useFormContext();

    async function CarregarDados(pId: Number) {
      var data = await HttpNaturezaOperacao().Visualizar(pId);
      reset(data.data.data);
    }

    return (
      <CGIModal
        id="sistema/classificacao/visualizar"
        ref={CGIModalRef}
        Titulo="Visualizar Natureza de Operação"
        TelaCheia={"6xl"}
        Confirmar={() => {}}
        Fechar={() => {}}
        ocultarCancelar={true}
        ocultarConfirmar={true}
      >
        <CGIBox>
          <CGIBoxItem Col={2}>
            <CGIInput<NaturezaOperacaoVisualizarTypes>
              id="id"
              label="Id"
              placeholder="Id"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIInput<NaturezaOperacaoVisualizarTypes>
              id="nome"
              label="Nome"
              placeholder="Nome"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<NaturezaOperacaoVisualizarTypes>
              id="descricaoAuxiliar"
              label="Descrição para NF"
              placeholder="Descrição para NF"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<NaturezaOperacaoVisualizarTypes>
              id="tipo"
              label="Tipo"
              placeholder=""
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<NaturezaOperacaoVisualizarTypes>
              id="tipoOperacaoNF"
              label="Tipo Operação NF"
              placeholder="Tipo Operação NF"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<NaturezaOperacaoVisualizarTypes>
              id="finalidadeNFId"
              label="Finalidade NF"
              placeholder="Finalidade NF"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<NaturezaOperacaoVisualizarTypes>
              id="finalidadeDevolucaoRetornoNFId"
              label="Finalidade Dev\Retorno NF"
              placeholder="Finalidade Dev\Retorno NF"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
  }
);
