import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarPedidoEntradaItensTypes,
  ListarPedidoEntradaTypes,
  ObjListarPedidoEntradaPadrao,
} from "../../../Types/Almoxarifado/PedidoEntrada/PedidoEntradaTypes";
import {
  ModalBaseType,
  LegendaTypes,
} from "../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpPedidoEntrada } from "./Http";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIHistorico } from "../../../Components/Historico/CGIHistorico";
import { CGINumberInput } from "../../../Components/NumberInput/CGINumberInput";

export function ListarPedidoEntrada() {
  const AlterarPedidoEntradaRef = useRef<ModalBaseType>();
  const CGIHistoricoRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarPedidoEntradaTypes>({
    defaultValues: ObjListarPedidoEntradaPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarPedidoEntradaes = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpPedidoEntrada().RetornarPedidoEntradas(DadosFiltros);
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarPedidoEntradaItensTypes) => {
      return await HttpPedidoEntrada().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpPedidoEntrada()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo PedidoEntrada",
      Permissao: "sistema/PedidoEntrada/incluir",
      OnClick: () => {
        AlterarPedidoEntradaRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Alterar",
      Permissao: "sistema/PedidoEntrada/alterar",
      OnClick: (e: ListarPedidoEntradaItensTypes) => {
        AlterarPedidoEntradaRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/PedidoEntrada/excluir",
      OnClick: (e: ListarPedidoEntradaItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Histórico",
      Permissao: "financeiro/ctspagar/historico",
      OnClick: (e: ListarPedidoEntradaItensTypes) => {
        CGIHistoricoRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas: LegendaTypes = [
    { nomeStatus: "Pré Pedido", cor: "wheat" },
    { nomeStatus: "Incluído", cor: "green" },
    { nomeStatus: "Baixa Total", cor: "blue" },
    { nomeStatus: "Excluído", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarPedidoEntradaItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "dataCadastro",
      Titulo: "Dt. Cadastro",
      Tipo: "Data",
      att: { maxWidth: 140 },
    },
    {
      Campo: "identificacao",
      Titulo: "Identificação",
      Tipo: "Texto",
      att: { maxWidth: 150 },
    },
    {
      Campo: "nomeEmpresa",
      Titulo: "Empresa",
      Tipo: "Texto",
      att: { maxWidth: 160 },
    },
    {
      Campo: "nomeFornecedor",
      Titulo: "Fornecedor",
      Tipo: "Texto",
      att: { minWidth: 230, wrapText: true },
    },
    {
      Campo: "nomeCondicaoPagamento",
      Titulo: "Cond. de Pagamento",
      Tipo: "Texto",
      att: { maxWidth: 180 },
    },
    {
      Campo: "numeroDocumento",
      Titulo: "Número Nota",
      Tipo: "Texto",
      att: { maxWidth: 110, wrapHeaderText: true },
    },
    {
      Campo: "qtdItens",
      Titulo: "Qtd. Itens",
      Tipo: "Numero",
      att: { maxWidth: 85, wrapHeaderText: true },
    },
    {
      Campo: "valorTotalComFrete",
      Titulo: "Valor",
      Tipo: "Moeda",
      att: { maxWidth: 140 },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarPedidoEntradaItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir PedidoEntrada"
          CarregarDados={RetornarPedidoEntradaes}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <CGIHistorico
          tipo="TimeLine"
          titulo="Histórico de Pedido Entrada"
          ref={CGIHistoricoRef}
          link={"almoxarifado/pedidoentrada/visualizarhistorico"}
          origem={"PEDIDOENTRADA"}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Filtros de Período">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarPedidoEntradaTypes>
                id="Pesquisa.dataInicialInclusao"
                label="Data Inclusão - Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarPedidoEntradaTypes>
                id="Pesquisa.dataFinalInclusao"
                label="Data Inclusão - Final"
              />
            </CGIBoxItem>
          </CGIBox>
          <CGIBox titulo="Filtros de Valor">
            <CGIBoxItem Col={6}>
              <CGINumberInput<ListarPedidoEntradaTypes>
                id="Pesquisa.valorDe"
                label="Valor do Pedido - Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGINumberInput<ListarPedidoEntradaTypes>
                id="Pesquisa.valorAte"
                label="Valor do Pedido - Final"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBox>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarPedidoEntradaTypes>
                id="Pesquisa.listaEmpresasId"
                label="Empresa"
                Link="empresa"
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={8}>
              <CGIComboSimples<ListarPedidoEntradaTypes>
                id="Pesquisa.listaFornecedoresId"
                label="Fornecedor"
                Link="fornecedor"
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarPedidoEntradaTypes>
                id="Pesquisa.listaCondicaoPagamentoId"
                label="Condição de Pagamento"
                Link="condicaodepagamento"
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={8}>
              <CGIComboSimples<ListarPedidoEntradaTypes>
                id="Pesquisa.listaStatusId"
                label="Status"
                Link="statuspedidoentrada"
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIInput<ListarPedidoEntradaTypes>
                id="Pesquisa.numeroDocumento"
                label="Número Nota"
                placeholder="Número Nota"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIInput<ListarPedidoEntradaTypes>
                id="Pesquisa.identificacao"
                label="Identificação"
                placeholder="Identificação"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <>
      <CGIListar
        id="almoxarifado/pedidoentrada/listar"
        Titulo="Listar Pedido Entrada"
        Janelas={Janelas}
      >
        <FormProvider {...methods}>
          <CGIDataTable
            LimparFiltros={() => methods.reset()}
            Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
            Pesquisar={methods.handleSubmit(RetornarPedidoEntradaes)}
            Filtro={Filtros()}
            Data={GridItens}
            Colunas={GridColunas}
            Legenda={Legendas}
          />
        </FormProvider>
      </CGIListar>
    </>
  );
  // Final JSX
}
