import { CGIHttp } from "../../../Services/Axios";
import {
  ListarImportacaoNFsaidaItensTypes,
  ListarPesquisaImportacaoNFsaidaTypes,
} from "../../../Types/Faturamento/ImportacaoNFSaida/ImportacaoNFSaidaTypes";

export function HttpImportacaoNFSaida() {
  const url = "/importacaoNFSaida";

  async function RetornarImportacoesNFSaida(
    DadosFiltros: ListarPesquisaImportacaoNFsaidaTypes
  ) {
    return (await (
      await new CGIHttp().conn.post(
        url + "/obter-importacoes-nfsaida",
        DadosFiltros
      )
    ).data.data) as ListarImportacaoNFsaidaItensTypes[];
  }

  return {
    RetornarImportacoesNFSaida,
  };
}
