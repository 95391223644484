import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import {
  ConsultarCustoMedioItensMovEstoqueTypes,
  ConsultarCustoMedioItensNFEntradaTypes,
  ConsultarCustoMedioItensPedidoEntradaTypes,
  ModalConsultarCustoMedioTypes,
  ObjListarConsultarCustoMedioPadrao,
} from "../../../Types/Sistema/Produto/ConsultarCustoMedioTypes";
import { HttpProduto } from "./Http";

type ConsultaCustoMedioType = {
  CarregarDados: Function;
};

export const ConsultarCustoMedio = forwardRef(
  (props: ConsultaCustoMedioType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir(pId: Number) {
        CGIModalRef.current.Abrir(pId);
        form.setValue("Pesquisa", ObjListarConsultarCustoMedioPadrao.Pesquisa);
        form.setValue("Pesquisa.produtoId", pId);
        form.setValue("ItensMovEstoque", []);
        form.setValue("ItensNFEntrada", []);
        form.setValue("ItensPedidoEntrada", []);
      },
    }));

    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<ModalConsultarCustoMedioTypes>();

    const ConsultarCustoMedio = async () => {
      var DadosFiltros = form.getValues("Pesquisa");

      var DadosMovEstoque = await HttpProduto().ConsultarCustoMedioMovEstoque(
        DadosFiltros
      );
      form.setValue("ItensMovEstoque", DadosMovEstoque);

      var DadosPedidoEntrada =
        await HttpProduto().ConsultarCustoMedioPedidoEntrada(DadosFiltros);
      form.setValue("ItensPedidoEntrada", DadosPedidoEntrada);

      var DadosNFEntrada = await HttpProduto().ConsultarCustoMedioNFEntrada(
        DadosFiltros
      );
      form.setValue("ItensNFEntrada", DadosNFEntrada);
    };

    //FINAL  HOOKS

    const GridItensMovEstoqueRef =
      useRef<AgGridReact<ConsultarCustoMedioItensMovEstoqueTypes>>(null);

    const GridItensNFEntradaRef =
      useRef<AgGridReact<ConsultarCustoMedioItensNFEntradaTypes>>(null);

    const GridItensPedidoEntradaRef =
      useRef<AgGridReact<ConsultarCustoMedioItensPedidoEntradaTypes>>(null);

    const GridItensMovEstoque = useWatch({
      control: form.control,
      name: "ItensMovEstoque",
    });

    const GridItensNFEntrada = useWatch({
      control: form.control,
      name: "ItensNFEntrada",
    });

    const GridItensPedidoEntrada = useWatch({
      control: form.control,
      name: "ItensPedidoEntrada",
    });

    const GridColunasNFEntrada: Array<
      CGICol<ConsultarCustoMedioItensNFEntradaTypes>
    > = [
      {
        Campo: "dataCadastro",
        Titulo: "Data",
        Tipo: "Data",
      },
      {
        Campo: "nomeEmpresa",
        Titulo: "Empresa",
        Tipo: "Texto",
      },
      {
        Campo: "nomeFornecedor",
        Titulo: "Fornecedor",
        Tipo: "Numero",
      },
      {
        Campo: "numeroNF",
        Titulo: "Número NF",
        Tipo: "Numero",
      },
      {
        Campo: "quantidade",
        Titulo: "Quantidade",
        Tipo: "Numero",
      },
      {
        Campo: "valorUnitario",
        Titulo: "Valor Unitário",
        Tipo: "Moeda",
      },
      {
        Campo: "custoMedio",
        Titulo: "Custo Médio",
        Tipo: "Moeda",
      },
    ];

    const GridColunasPedidoEntrada: Array<
      CGICol<ConsultarCustoMedioItensPedidoEntradaTypes>
    > = [
      {
        Campo: "dataCadastro",
        Titulo: "Data",
        Tipo: "Data",
      },
      {
        Campo: "nomeEmpresa",
        Titulo: "Empresa",
        Tipo: "Texto",
      },
      {
        Campo: "nomeFornecedor",
        Titulo: "Fornecedor",
        Tipo: "Texto",
      },
      {
        Campo: "quantidade",
        Titulo: "Quantidade",
        Tipo: "Numero",
      },
      {
        Campo: "valorUnitario",
        Titulo: "Valor Unitário",
        Tipo: "Moeda",
      },
      {
        Campo: "custoMedio",
        Titulo: "Custo Médio",
        Tipo: "Moeda",
      },
    ];

    const GridColunasMovEstoque: Array<
      CGICol<ConsultarCustoMedioItensMovEstoqueTypes>
    > = [
      {
        Campo: "dataCadastro",
        Titulo: "Data",
        Tipo: "Data",
      },
      {
        Campo: "nomeEmpresa",
        Titulo: "Empresa",
        Tipo: "Texto",
      },
      {
        Campo: "quantidade",
        Titulo: "Quantidade",
        Tipo: "Numero",
      },
      {
        Campo: "valorUnitario",
        Titulo: "Valor Unitário",
        Tipo: "Moeda",
      },
      {
        Campo: "custoMedio",
        Titulo: "Custo Médio",
        Tipo: "Moeda",
      },
    ];

    const TabGridMovEstoque = () => {
      return (
        <CGIBoxItem Col={12}>
          <CGIDataTableSimples
            DisableGetId
            Altura="300px"
            Colunas={GridColunasMovEstoque}
            ref={GridItensMovEstoqueRef}
            Data={GridItensMovEstoque}
          />
        </CGIBoxItem>
      );
    };

    const TabGridPedidoEntrada = () => {
      return (
        <CGIBoxItem Col={12}>
          <CGIDataTableSimples
            DisableGetId
            Altura="300px"
            Colunas={GridColunasPedidoEntrada}
            ref={GridItensPedidoEntradaRef}
            Data={GridItensPedidoEntrada}
          />
        </CGIBoxItem>
      );
    };

    const TabGridNFEntrada = () => {
      return (
        <CGIBoxItem Col={12}>
          <CGIDataTableSimples
            DisableGetId
            Altura="300px"
            Colunas={GridColunasNFEntrada}
            ref={GridItensNFEntradaRef}
            Data={GridItensNFEntrada}
          />
        </CGIBoxItem>
      );
    };
    //FINAL  CONST

    //INICO FUNC
    async function CarregarDados(id: number) {
      HttpProduto()
        .RetornarDadosProduto(id)
        .then((e) => {
          form.reset(e.data);
        });
    }

    const Tabs: CGITabItensTypes[] = [
      {
        Component: <TabGridPedidoEntrada />,
        Titulo: "Pedido Entrada",
        Tooltip: "Custo Médio Pedido Entrada",
      },
      {
        Component: <TabGridNFEntrada />,
        Titulo: "NF Entrada",
        Tooltip: "Custo Médio NF. Entrada",
      },
      {
        Component: <TabGridMovEstoque />,
        Titulo: "Mov Estoque",
        Tooltip: "Custo Médio Mov. Estoque",
      },
    ];
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="sistema/produto/consultarcustomedio"
        ref={CGIModalRef}
        Titulo="Consultar Custo Médio"
        TelaCheia={"6xl"}
        Confirmar={() => {}}
        CarregarDados={CarregarDados}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIResumo Expandir ResumoProduto ProdutoId="Pesquisa.produtoId" />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIBox titulo="Filtros">
              <CGIBoxItem Col={3}>
                <CGIDatePicker<ModalConsultarCustoMedioTypes>
                  id="Pesquisa.dataInicial"
                  label="Data Inicial"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIDatePicker<ModalConsultarCustoMedioTypes>
                  id="Pesquisa.dataFinal"
                  label="Data Final"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={5}>
                <CGIComboSimples<ModalConsultarCustoMedioTypes>
                  id="Pesquisa.empresaId"
                  label="Empresa"
                  Link="empresa"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={5}>
                <CGIComboSimples<ModalConsultarCustoMedioTypes>
                  id="Pesquisa.fornecedorId"
                  label="Fornecedor"
                  Link="fornecedor"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIButton
                  att={{
                    mt: 30,
                  }}
                  label="Pesquisar"
                  onClick={ConsultarCustoMedio}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={12}>
                <CGITabs Tabs={Tabs} />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
    //FINAL JSX
  }
);
