import { Button, Flex } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import { CGITabs } from "../../../Components/Tabs/CGITabs";
import {
  ConsultarMovimentacaoProdutoItensTypes,
  ConsultarMovimentacaoProdutoTypes,
} from "../../../Types/Almoxarifado/MovEstoque/MovEstoqueType";
import { HttpMovEstoque } from "./Http";

export const ConsultarMovimentacaoEstoqueProduto = forwardRef(
  (
    props: {
      CarregarDados: () => void;
    },
    ref
  ) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir(pId: number) {
        form.setValue("Pesquisa.produtoId", pId);
        CGIModalRef.current.Abrir();
      },
      Alterar(pEmpresa: any) {
        CGIModalRef.current.Abrir();
      },
    }));
    const ColGridPesquisa: Array<
      CGICol<ConsultarMovimentacaoProdutoItensTypes>
    > = [
      {
        Titulo: "Dt. Mov.",
        Campo: "dataCadastro",
        Tipo: "Data",
      },
      {
        Titulo: "Produto",
        Campo: "codigoProduto",
        Tipo: "Texto",
      },
      {
        Titulo: "Descricao",
        Campo: "descricao",
        Tipo: "Texto",
      },
      {
        Titulo: "obs",
        Campo: "obs",
        Tipo: "Texto",
      },
      {
        Titulo: "Operação",
        Campo: "operacao",
        Tipo: "Texto",
      },
      {
        Titulo: "Qtd.",
        Campo: "quantidade",
        Tipo: "Numero",
        ExibirCoresConformeValor: true,
      },
      {
        Titulo: "Usuário",
        Campo: "nomeUsuario",
        Tipo: "Texto",
      },
    ];

    const CGIModalRef = useRef<any>();
    const form = useFormContext<ConsultarMovimentacaoProdutoTypes>();
    const CGIGridPesquisaRef = useRef<AgGridReact>();
    const GridItens = useWatch({
      control: form.control,
      name: "Itens",
      defaultValue: [],
    });

    const CarregarDados = async () => {
      var DadosFiltros = form.getValues("Pesquisa");
      var Dados = await HttpMovEstoque().RetornarMovEstoqueProduto(
        DadosFiltros
      );
      form.setValue("Itens", Dados);
    };

    return (
      <CGIModal
        id="almoxarifado/movestoque/consultarmovproduto"
        ref={CGIModalRef}
        Titulo="Consultar Movimentação do Produto"
        TelaCheia={"6xl"}
        ocultarConfirmar
        ocultarCancelar
        CarregarDados={() => {}}
        Confirmar={() => {}}
        Fechar={() => {}}
        tipoGET={{
          url: "PRODUTO-CONSULTA-MOV-PRODUTO",
          setRetorno: (retorno: any) => {
            form.setValue("Pesquisa", retorno);
          },
        }}
      >
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIResumo
              Expandir
              ResumoProdutoSimples
              ProdutoId="Pesquisa.produtoId"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIBox titulo="Filtros">
              <CGIBoxItem Col={6}>
                <CGIComboSimples<ConsultarMovimentacaoProdutoTypes>
                  id="Pesquisa.empresaId"
                  label="Empresa"
                  Link={"empresa"}
                  validadores={{
                    required: "Campo obrigatório",
                  }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGIDatePicker<ConsultarMovimentacaoProdutoTypes>
                  id="Pesquisa.dataInicial"
                  label="Período - De"
                  validadores={{
                    required: "Campo obrigatório",
                  }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGIDatePicker<ConsultarMovimentacaoProdutoTypes>
                  id="Pesquisa.dataFinal"
                  label="Até"
                  validadores={{
                    required: "Campo obrigatório",
                  }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGIButton
                  att={{
                    marginTop: "30px",
                  }}
                  label="Pesquisar"
                  onClick={form.handleSubmit(CarregarDados)}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={12}>
                <CGIDataTableSimples
                  DisableGetId
                  ref={CGIGridPesquisaRef}
                  Altura={"300px"}
                  Colunas={ColGridPesquisa}
                  Data={GridItens}
                  Pag={10}
                  Att={{
                    defaultColDef: {
                      filter: false,
                    },
                  }}
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
    //FINAL JSX
  }
);
