import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarTIPIItensTypes,
  ListarTIPITypes,
  ObjListarTIPIPadrao,
  TIPIVisualizarTypes,
} from "../../../Types/Sistema/TIPI/TIPITypes";
import {
  ModalBaseType,
  LegendaTypes,
} from "../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpTIPI } from "./Http";
import { AlterarTIPI } from "./_AlterarTIPI";
import { VisualizarTIPI } from "./_VisualizarTIPI";
import { CGIInput } from "../../../Components/Input/CGIInput";

export function ListarTIPI() {
  const AlterarTIPIRef = useRef<ModalBaseType>();
  const VisualizarTIPIRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarTIPITypes>({
    defaultValues: ObjListarTIPIPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarTIPIes = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpTIPI().RetornarTIPIs(DadosFiltros);
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarTIPIItensTypes) => {
      return await HttpTIPI().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpTIPI()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo TIPI",
      Permissao: "sistema/tipi/incluir",
      OnClick: () => {
        AlterarTIPIRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "sistema/tipi/visualizar",
      OnClick: (e: TIPIVisualizarTypes) => {
        VisualizarTIPIRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Alterar",
      Permissao: "sistema/tipi/alterar",
      OnClick: (e: ListarTIPIItensTypes) => {
        AlterarTIPIRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/tipi/excluir",
      OnClick: (e: ListarTIPIItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas: LegendaTypes = [];

  const GridColunas: Array<CGICol<ListarTIPIItensTypes>> = [
    {
      Campo: "ncm",
      Titulo: "NCM",
      Tipo: "Texto",
    },
    {
      Campo: "percIPI",
      Titulo: "Perc. IPI",
      Tipo: "Numero",
      att: { maxWidth: 150 },
    },
    {
      Campo: "ex",
      Titulo: "Ex",
      Tipo: "Numero",
      att: { maxWidth: 100 },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarTIPIItensTypes) => MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          titulo: "NCM",
          valor: "ncm",
        },
        {
          titulo: "Perc. IPI",
          valor: "percIPI",
        },
        {
          titulo: "Ex",
          valor: "ex",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir TIPI"
          CarregarDados={RetornarTIPIes}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarTIPI
          CarregarDados={methods.handleSubmit(RetornarTIPIes)}
          ref={AlterarTIPIRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarTIPI
          CarregarDados={methods.handleSubmit(RetornarTIPIes)}
          ref={VisualizarTIPIRef}
        />
      ),
    },
  ];

  // async function Pesquisar(DadosFiltros: ListarTIPITypes) {
  //   setLoadingState(true);
  //   var data = await HttpTIPI()
  //     .RetornarTIPIes(DadosFiltros)
  //     .finally(() => {
  //       setLoadingState(false);
  //     });
  //   setDados(data);
  // }

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarTIPITypes>
            id="Pesquisa.ncm"
            label="NCM"
            placeholder={""}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar id="sistema/tipi/listar" Titulo="TIPI" Janelas={Janelas}>
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarTIPIes)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
