import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { DesbloquearPedidoType } from "../../../Types/Vendas/Pedido/PedidoTypes";

type PropsDesbloquearPedidoType = {
  CarregarDados: Function;
};

export const DesbloquearPedido = forwardRef(
  (props: PropsDesbloquearPedidoType, ref) => {
    useImperativeHandle(ref, () => ({
      Abrir(pId: number) {
        form.setValue("id", pId);
        CGIModalRef.current?.Abrir();
      },
    }));
    const form = useFormContext<DesbloquearPedidoType>();
    const CGIModalRef = useRef<ModalBaseType>();

    async function CarregarDados(id: number) {}

    const Confirmar = async (data: any, event: any) => {};

    return (
      <CGIModal
        id="vendas/pedido/desbloquear"
        ref={CGIModalRef}
        Titulo="Desbloquear Pedido"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        CarregarDados={CarregarDados}
        Fechar={() => {}}
      >
        <CGIResumo
          Principal="PEDIDO"
          PedidoId="id"
          ResumoPedido
          ResumoPedidoItens
        />
      </CGIModal>
    );
    //FINAL JSX
  }
);
