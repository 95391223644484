import { forwardRef, useContext, useImperativeHandle, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGINumberInput } from "../../../Components/NumberInput/CGINumberInput";
import LoadingContext from "../../../Context/LoadingContext";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { FormConfigCFOPType } from "../../../Types/Sistema/ConfigCFOP/ConfigCFOPTypes";
import {
  FormConfigComissaoType,
  ObjConfigComissaoPadrao,
} from "../../../Types/Sistema/ConfigComissao/ConfigComissaoTypes";
import { HttpConfigComissao } from "./Http";

export const AlterarConfigComissao = forwardRef(
  (
    props: {
      CarregarDados: () => void;
    },
    ref
  ) => {
    //INICIO REF
    const form = useFormContext<FormConfigComissaoType>();
    const CGIModalRef = useRef<ModalBaseType>();
    const { setLoadingState } = useContext(LoadingContext);
    useImperativeHandle(ref, () => ({
      Abrir() {
        form.reset(ObjConfigComissaoPadrao);
        CGIModalRef.current?.Abrir();
      },
      async Alterar(pId: number) {
        setLoadingState(true);
        form.reset(ObjConfigComissaoPadrao);

        try {
          const data = await HttpConfigComissao().retornarDadosConfigComissao(
            pId
          );
          form.setValue("Form.dadosRegistro", data);
          CGIModalRef.current?.Abrir();
        } finally {
          setLoadingState(false);
        }
      },
    }));
    const Confirmar = async () => {
      try {
        form.handleSubmit(async (data: FormConfigComissaoType) => {
          setLoadingState(true);
          if (data.Form.dadosRegistro.id === 0) {
            await HttpConfigComissao().Incluir(data.Form);
          } else {
            await HttpConfigComissao().Alterar(data.Form);
          }
          props.CarregarDados();
          CGIModalRef.current?.Fechar();
        })();
      } finally {
        setLoadingState(false);
      }
    };
    return (
      <CGIModal
        id="sistema/classificacao/alterar"
        ref={CGIModalRef}
        Titulo="Config Comissão"
        TelaCheia={"6xl"}
        CarregarDados={() => {}}
        Confirmar={(e: any) => {
          Confirmar();
        }}
        Fechar={() => {
          form.reset(ObjConfigComissaoPadrao);
        }}
      >
        <CGIBox>
          <CGIBoxItem Col={6}>
            <CGIInput<FormConfigComissaoType>
              id="Form.dadosRegistro.nome"
              label="Nome"
              placeholder="Nome"
              validadores={{
                required: "Campo obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGINumberInput<FormConfigComissaoType>
              id="Form.dadosRegistro.percComissao"
              label="Comissão %"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGINumberInput<FormConfigComissaoType>
              id="Form.dadosRegistro.valorIndividual"
              label="Valor Individual"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormConfigComissaoType>
              id="Form.dadosRegistro.empresaId"
              label="Empresa"
              Link={"empresa"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormConfigComissaoType>
              id="Form.dadosRegistro.vendedorId"
              label="Vendedor"
              Link={"vendedor"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboAjax<FormConfigComissaoType>
              id="Form.dadosRegistro.clienteId"
              label="Cliente"
              Link={"cliente"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboAjax<FormConfigComissaoType>
              id="Form.dadosRegistro.produtoId"
              label="Produto"
              Link={"produto"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormConfigComissaoType>
              id="Form.dadosRegistro.grupoId"
              label="Grupo"
              Link={"grupo"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormConfigComissaoType>
              id="Form.dadosRegistro.subGrupoId"
              label="SubGrupo"
              Link={"subgrupo"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormConfigComissaoType>
              id="Form.dadosRegistro.pracaId"
              label="Praça"
              Link={"praca"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormConfigComissaoType>
              id="Form.dadosRegistro.rotaId"
              label="Rota"
              Link={"rota"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormConfigComissaoType>
              id="Form.dadosRegistro.tipoClienteId"
              label="Tipo Cliente"
              Link={"tipoCliente"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormConfigComissaoType>
              id="Form.dadosRegistro.cidadeId"
              label="Cidade"
              Link={"cidade"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormConfigComissaoType>
              id="Form.dadosRegistro.classeId"
              label="Classe"
              Link="classe"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGICheckBox<FormConfigComissaoType>
              id="Form.dadosRegistro.ativo"
              label="Ativo"
              tooltip={"Ativo"}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
    //FINAL JSX
  }
);
