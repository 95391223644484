import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useContext,
  useState,
  useEffect,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGINumberInput } from "../../../Components/NumberInput/CGINumberInput";
import { CGIRadioButton } from "../../../Components/RadioButton/CGIRadioButton";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import { CGITextArea } from "../../../Components/TextArea/CGITextArea";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ColaboradorTypes,
  FormColaboradorType,
  ListarColaboradorItensTypes,
} from "../../../Types/Financeiro/Colaborador/ColaboradorTypes";
import { HttpColaborador } from "./Http";

type AlterarColaboradorType = {
  CarregarDados: Function;
};

export const AlterarColaborador = forwardRef(
  (props: AlterarColaboradorType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current.Abrir();
      },
      Alterar(pEmpresa: any) {
        CGIModalRef.current.Abrir();

        HttpColaborador()
          .RetornarDadosColaborador(pEmpresa.id)
          .then((e) => {
            form.reset(e);
          });
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<FormColaboradorType>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);
    const [GridDadosColaboradorContato, setGridDadosColaboradorContato] =
      useState<Array<ListarColaboradorItensTypes>>([]);
    const [ExibirJuridica, setExibirJuridica] = useState(false);

    const EhCliente = useWatch({
      control: form.control,
      name: "Form.DadosRegistro.ehCliente",
    });
    const EhFuncionario = useWatch({
      control: form.control,
      name: "Form.DadosRegistro.ehFuncionario",
    });
    const EhFornecedor = useWatch({
      control: form.control,
      name: "Form.DadosRegistro.ehFornecedor",
    });
    const EhRespFinanceiro = useWatch({
      control: form.control,
      name: "Form.DadosRegistro.ehRespFinanceiro",
    });
    const PedVendaConsumoPedLocacaoRespFinanceiro = useWatch({
      control: form.control,
      name: "Form.DadosRegistro.gerarPedVendaConsumoPedLocacaoRespFinanceiro",
    });
    const EhPosto = useWatch({
      control: form.control,
      name: "Form.DadosRegistro.ehPosto",
    });
    const EhVendedor = useWatch({
      control: form.control,
      name: "Form.DadosRegistro.ehVendedor",
    });
    const EhTransportadora = useWatch({
      control: form.control,
      name: "Form.DadosRegistro.ehTransportadora",
    });
    const EhSeguradora = useWatch({
      control: form.control,
      name: "Form.DadosRegistro.ehSeguradora",
    });
    const FisicaJuridica = useWatch({
      control: form.control,
      name: "Form.DadosRegistro.fisicaJuridicaId",
    });

    useEffect(() => {
      if (FisicaJuridica === "J") {
        setExibirJuridica(true);
      } else {
        setExibirJuridica(false);
      }
    }, [FisicaJuridica]);

    const Confirmar = async (data: ColaboradorTypes, event: any) => {
      setLoadingState(true);

      if (data.id == null || data.id === undefined || data.id === 0) {
        HttpColaborador()
          .Incluir(data)
          .then((response: any) => {
            ToastSucesso("Registro gravado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return ToastErro(e);
          })
          .finally(() => {
            setLoadingState(false);
          });
      } else {
        HttpColaborador()
          .Alterar(data)
          .then((data: any) => {
            ToastSucesso("Registro Alterado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return;
          })
          .finally(() => {
            setLoadingState(false);
          });
      }
    };

    //FINAL  HOOKS

    //INICIO CONST
    const GridColunasColaboradorContato: Array<
      CGICol<ListarColaboradorItensTypes>
    > = [
      {
        Campo: "selecionado",
        Titulo: "",
        Tipo: "CheckBox",
        att: {
          maxWidth: 70,
        },
      },
      {
        Campo: "nomeContato",
        Titulo: "Nome Contato",
        Tipo: "Texto",
        att: {
          minWidth: 400,
        },
      },
      {
        Campo: "emailContato",
        Titulo: "E-mail",
        Tipo: "Texto",
        att: {
          minWidth: 300,
        },
      },
      {
        Campo: "telefoneContato",
        Titulo: "Telefone",
        Tipo: "Texto",
        att: {
          minWidth: 200,
        },
      },
      {
        Campo: "tipoContato",
        Titulo: "Tipo Contato",
        Tipo: "Texto",
        att: {
          minWidth: 100,
        },
      },
      {
        Campo: "cargoSetor",
        Titulo: "Cargo/Setor",
        Tipo: "Texto",
        att: {
          minWidth: 100,
        },
      },
      {
        Campo: "obsContato",
        Titulo: "Cargo/Setor",
        Tipo: "Texto",
        att: {
          minWidth: 100,
        },
      },
      {
        Campo: "enviarNFeEmailContato",
        Titulo: "Enviar NFe por E-mail",
        Tipo: "CheckBox",
        att: {
          minWidth: 100,
        },
      },
    ];

    return (
      <CGIModal
        id="financeiro/colaborador/alterar"
        ref={CGIModalRef}
        Titulo="Colaborador"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGIBox>
          <CGIBoxItem Col={8}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.nomeCompleto"
              label="Nome Completo"
              placeholder="Nome Completo"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.nomeFantasia"
              label="Nome Fantasia"
              placeholder="Nome Fantasia"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIRadioButton<FormColaboradorType>
              id="Form.DadosRegistro.fisicaJuridicaId"
              row="row"
              label="Física \ Jurídica"
              att={{
                fontWeight: "bold",
              }}
              values={[
                { label: "Física", valor: "F" },
                { label: "Jurídica", valor: "J" },
              ]}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.cpfcnpj"
              label="CPF \ CNPJ"
              placeholder="CPF \ CNPJ"
            />
          </CGIBoxItem>
          <CGIBoxItem hidden={!ExibirJuridica} Col={4}>
            <CGIComboSimples<FormColaboradorType>
              id="Form.DadosRegistro.listaTiposTributacaoId"
              label="Tipo de Tributação"
              Link="tipotributacao"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.rGInscEstadual"
              label="RG\Inscr. Estadual"
              placeholder="RG\Inscr. Estadual"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.orgaoEmissor"
              label="Órgão Emissor"
              placeholder="Órgão Emissor"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGICheckBox<FormColaboradorType>
              id="Form.DadosRegistro.ativoId"
              label="Ativo"
              tooltip={""}
            />
          </CGIBoxItem>
          <CGIBoxItem hidden={!ExibirJuridica} Col={2}>
            <CGICheckBox<FormColaboradorType>
              id="Form.DadosRegistro.contICMS"
              label="Contribuinte ICMS"
              tooltip={""}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIBox titulo="Atuação" popover="Atuação">
              <CGIBoxItem Col={6}>
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.ehCliente"
                  label="Cliente"
                  tooltip="Cliente"
                />
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.ehFuncionario"
                  label="Funcionário"
                  tooltip="Funcionário"
                />
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.ehFornecedor"
                  label="Fornecedor"
                  tooltip="Fornecedor"
                />
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.ehRespFinanceiro"
                  label="Resp. Financeiro"
                  tooltip="Resp. Financeiro"
                />
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.ehPosto"
                  label="Posto de Combustível"
                  tooltip="Posto de Combustível"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={6}>
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.ehVendedor"
                  label="Vendedor"
                  tooltip="Vendedor"
                />
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.ehTransportadora"
                  label="Transportadora"
                  tooltip="Transportadora"
                />
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.ehSeguradora"
                  label="Seguradora"
                  tooltip="Seguradora"
                />
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.ehRepresentante"
                  label="Representante"
                  tooltip="Representante"
                />
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.ehPaciente"
                  label="Paciente"
                  tooltip="Paciente"
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormColaboradorType>
              id="Form.DadosRegistro.listaEmpresasId"
              label="Empresa"
              Link="empresa"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.inss"
              label="INSS"
              placeholder="INSS"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.contato"
              label="Contato"
              placeholder="Contato"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.codigoSuframa"
              label="Código Suframa"
              placeholder="Código Suframa"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.telefone"
              label="Telefone1"
              placeholder="Telefone1"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.telefone2"
              label="Telefone2"
              placeholder="Telefone2"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.telefone3"
              label="WhatsApp"
              placeholder="WhatsApp"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.cep"
              label="CEP"
              placeholder="CEP"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.endereco"
              label="Endereço"
              placeholder="Endereço"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.numero"
              label="Nº"
              placeholder="Nº"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.complemento"
              label="Complemento"
              placeholder="Nº"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.bairro"
              label="Bairro"
              placeholder="Bairro"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.cidade"
              label="Cidade"
              placeholder="Cidade"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.pontoReferencia"
              label="Ponto de Referência"
              placeholder="Ponto de Referência"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.emailBoleto"
              label="E-mail para Boleto"
              placeholder="E-mail para Boleto"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.emailPrincipal"
              label="E-mail Principal"
              placeholder="E-mail Principal"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.emailNFe"
              label="E-mail para NFe"
              placeholder="E-mail para NFe"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.grupoColaboradores"
              label="Grupo de Colaboradores (separados por vírgula)"
              placeholder="Grupo de Colaboradores (separados por vírgula)"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.diasBloqueioAtraso"
              label="Dias Bloqueio Atraso"
              placeholder="Dias Bloqueio Atraso"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormColaboradorType>
              id="Form.DadosRegistro.codigoExterno"
              label="Código Externo"
              placeholder="Código Externo"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormColaboradorType>
              id="Form.DadosRegistro.listaGruposEconomicosId"
              label="Grupo Econômico"
              Link="grupoeconomico"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGITextArea<FormColaboradorType>
              id="Form.DadosRegistro.obs"
              label="Obs. Cadastro"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGITextArea<FormColaboradorType>
              id="Form.DadosRegistro.dadosBancarios"
              label="Dados Bancários"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIBox
              titulo="Adicionar Novo Contato"
              popover="Adicionar Novo Contato"
            >
              <CGIBoxItem Col={2}>
                <CGIInput<FormColaboradorType>
                  id="Form.DadosRegistro.codigoContato"
                  label="Código"
                  placeholder="Código"
                  att={{ isReadOnly: true }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={6}>
                <CGIInput<FormColaboradorType>
                  id="Form.DadosRegistro.nomeContato"
                  label="Nome Contato"
                  placeholder="Nome Contato"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIInput<FormColaboradorType>
                  id="Form.DadosRegistro.emailContato"
                  label="E-mail"
                  placeholder="E-mail"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIComboSimples<FormColaboradorType>
                  id="Form.DadosRegistro.listaTiposContatoId"
                  label="Tipo Contato"
                  Link="tipocontato"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIInput<FormColaboradorType>
                  id="Form.DadosRegistro.telefoneContato"
                  label="Telefone"
                  placeholder="Telefone"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIInput<FormColaboradorType>
                  id="Form.DadosRegistro.cargoSetor"
                  label="Cargo/Setor"
                  placeholder="Cargo/Setor"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={12}>
                <CGITextArea<FormColaboradorType>
                  id="Form.DadosRegistro.obsContato"
                  label="Observações"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={12}>
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.enviarNFeEmail"
                  label="Enviar NFe por Email"
                  tooltip=""
                />
              </CGIBoxItem>
              <CGIBoxItem Col={12}>
                <CGIBoxItem Col={2}>
                  <CGIButton
                    //onClick={() => IncluirColaboradorContato()}
                    label="Adicionar Contato"
                  />
                </CGIBoxItem>
                <CGIBox>
                  <CGIBoxItem Col={12}>
                    <CGIDataTableSimples
                      Data={GridDadosColaboradorContato}
                      Colunas={GridColunasColaboradorContato}
                      Altura="300px"
                      Att={{
                        defaultColDef: {
                          filter: false,
                        },
                      }}
                    />
                  </CGIBoxItem>
                </CGIBox>
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem hidden={!EhCliente} Col={12}>
            <CGIBox titulo="Dados de Cliente" popover="Dados de Cliente">
              <CGIBoxItem Col={4}>
                <CGIComboSimples<FormColaboradorType>
                  id="Form.DadosRegistro.listaLimitesCreditoClienteId"
                  label="Limite de Crédito"
                  Link="limitecredito"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.limiteDisponivelCliente"}
                  label="Limite Disponível"
                  placeholder="Limite Disponível"
                  att={{ isReadOnly: true }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIComboSimples<FormColaboradorType>
                  id={"Form.DadosRegistro.listaCondicoesPagamentoClienteId"}
                  label="Condição de Pagamento"
                  Link="condicaodepagamento"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIComboSimples<FormColaboradorType>
                  id={"Form.DadosRegistro.listaNaturezasOperacaoClienteId"}
                  label="Natureza Operação"
                  Link="naturezadeoperacao"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIComboSimples<FormColaboradorType>
                  id={"Form.DadosRegistro.listaEmpresasFaturamentoClienteId"}
                  label="Empresa para Faturamento"
                  Link="empresa"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.descontoCliente"}
                  label="Desconto (%)"
                  placeholder="Desconto"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIComboSimples<FormColaboradorType>
                  id={"Form.DadosRegistro.listaTabelasPrecoClienteId"}
                  label="Tabela de Preços"
                  Link="tabelaprecos"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIComboSimples<FormColaboradorType>
                  id={"Form.DadosRegistro.listaConveniosPortadorClienteId"}
                  label="Convênio Portador"
                  Link="convenioportador"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={12}>
                <CGITextArea<FormColaboradorType>
                  id="Form.DadosRegistro.obsVendaCliente"
                  label="Obs. Venda"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIComboSimples<FormColaboradorType>
                  id={"Form.DadosRegistro.listaVendedoresClienteId"}
                  label="Vendedor"
                  Link="vendedor"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.cnaeCliente"}
                  label="CNAE"
                  placeholder="CNAE"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.inscricaoMunicipalCliente"}
                  label="Inscrição Municipal"
                  placeholder="Inscrição Municipal"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIComboSimples<FormColaboradorType>
                  id={"Form.DadosRegistro.listaPracasClienteId"}
                  label="Praça"
                  Link="praca"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIComboSimples<FormColaboradorType>
                  id={"Form.DadosRegistro.listaRotasClienteId"}
                  label="Rota"
                  Link="rota"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.nomeTipoCliente"}
                  label="Tipo Cliente"
                  placeholder="Tipo Cliente"
                  att={{ isReadOnly: true }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.usuarioBloqueioDesbloqueioClienteId"}
                  label="Usuário Bloqueio/Desbloqueio"
                  placeholder=""
                  att={{ isReadOnly: true }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.dataBloqueioDesbloqueioCliente"}
                  label="Data Bloqueio/Desbloqueio"
                  placeholder=""
                  att={{ isReadOnly: true }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={12}>
                <CGITextArea<FormColaboradorType>
                  id="Form.DadosRegistro.motivoBloqueioCliente"
                  label="Motivo Bloqueio"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.faturamentoBloqueadoCliente"
                  label="Faturamento Bloqueado"
                  tooltip="Faturamento Bloqueado"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.obrigarNumeroItemPedidoVendaCliente"
                  label="Obrigar Número Item Pedido em Pedido de Venda"
                  tooltip="Obrigar Número Item Pedido em Pedido de Venda"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.gerarPedidoSaldoCliente"
                  label="Gerar Pedido Saldo"
                  tooltip="Gerar Pedido Saldo"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIDatePicker<FormColaboradorType>
                  id="Form.DadosRegistro.dataConsultaCreditoCliente"
                  label="Data Consulta Crédito"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={12}>
                <CGITextArea<FormColaboradorType>
                  id="Form.DadosRegistro.obsConsultaCreditoCliente"
                  label="Obs. Consulta Crédito"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={12}>
                <CGITextArea<FormColaboradorType>
                  id="Form.DadosRegistro.obsNfVendaCliente"
                  label="Obs. NF Venda"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={12}>
                <span style={{ fontWeight: "bold", color: "green" }}>
                  * Esta observação será impressa em todas as notas fiscais
                  deste cliente *
                </span>
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGINumberInput<FormColaboradorType>
                  id={"Form.DadosRegistro.valorMinimoPedidoVendaCliente"}
                  label="Valor Mínimo Ped. Venda"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.bloqueioFixoVendaCliente"
                  label="Bloqueio Fixo de Vendas"
                  tooltip="Bloqueio Fixo de Vendas"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.entregaEspecialCliente"
                  label="Entrega Especial"
                  tooltip="Entrega Especial"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.emailNotificacaoPedidosCliente"}
                  label="E-Mail Notif. Pedidos"
                  placeholder="E-Mail Notif. Pedidos"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIComboSimples<FormColaboradorType>
                  id={"Form.DadosRegistro.listaRepresentantesClienteId"}
                  label="Representante"
                  Link="Representante"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIDatePicker<FormColaboradorType>
                  id="Form.DadosRegistro.dataConsultaSPCCliente"
                  label="Data Consulta SPC"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIComboSimples<FormColaboradorType>
                  id={"Form.DadosRegistro.listaRamosAtividadeCliente"}
                  label="Ramo de Atividade"
                  Link="ramodeatividade"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.permitirVendaAPrazoCliente"
                  label="Permitir Venda a Prazo"
                  tooltip="Permitir Venda a Prazo"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.gerarCreditoCliente"
                  label="Gerar Crédito"
                  tooltip="Gerar Crédito"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={12}>
                <CGITextArea<FormColaboradorType>
                  id="Form.DadosRegistro.obsSPCCliente"
                  label="Observação SPC"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={12}>
                <CGITextArea<FormColaboradorType>
                  id="Form.DadosRegistro.dadosCompradorCliente"
                  label="Dados Comprador"
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem hidden={!EhCliente} Col={12}>
            <CGIBox titulo="Pessoa Física" popover="Pessoa Física">
              <CGIBoxItem Col={4}>
                <CGIRadioButton<FormColaboradorType>
                  id="Form.DadosRegistro.sexoCliente"
                  row="row"
                  label="Sexo"
                  att={{
                    fontWeight: "bold",
                  }}
                  values={[
                    { label: "Masculino", valor: "M" },
                    { label: "Feminino", valor: "F" },
                  ]}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIDatePicker<FormColaboradorType>
                  id="Form.DadosRegistro.dataNascimentoCliente"
                  label="Data de Nascimento"
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem hidden={!EhCliente} Col={12}>
            <CGIBox titulo="Faturamento" popover="Faturamento">
              <CGIBoxItem Col={3}>
                <label>
                  <strong>Faturar Produtos</strong>
                </label>
                <CGIDatePicker<FormColaboradorType>
                  id="Form.DadosRegistro.diaFaturamentoProdutoInicialCliente"
                  label="Dia Faturamento Inicial"
                />
                <CGIDatePicker<FormColaboradorType>
                  id="Form.DadosRegistro.diaFaturamentoProdutoFinalCliente"
                  label="Dia Faturamento Final"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <label>
                  <strong>Faturar Serviços</strong>
                </label>
                <CGIDatePicker<FormColaboradorType>
                  id="Form.DadosRegistro.diaFaturamentoServicoInicialCliente"
                  label="Dia Faturamento Inicial"
                />
                <CGIDatePicker<FormColaboradorType>
                  id="Form.DadosRegistro.diaFaturamentoServicoFinalCliente"
                  label="Dia Faturamento Final"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={6}>
                <label>
                  <strong>Selecione os Dias Permitidos Para Faturamento</strong>
                </label>
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.faturamentoSegundaCliente"
                  label="Segunda"
                  tooltip="Segunda"
                />
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.faturamentoTercaCliente"
                  label="Terça"
                  tooltip="Terça"
                />
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.faturamentoQuartaCliente"
                  label="Quarta"
                  tooltip="Quarta"
                />
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.faturamentoQuintaCliente"
                  label="Quinta"
                  tooltip="Quinta"
                />
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.faturamentoSextaCliente"
                  label="Sexta"
                  tooltip="Sexta"
                />
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.faturamentoSabadoCliente"
                  label="Sabádo"
                  tooltip="Sabádo"
                />
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.faturamentoDomingoCliente"
                  label="Domingo"
                  tooltip="Domingo"
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem hidden={!EhFuncionario} Col={12}>
            <CGIBox
              titulo="Dados de Funcionário"
              popover="Dados de Funcionário"
            >
              <CGIBoxItem Col={3}>
                <CGINumberInput<FormColaboradorType>
                  id={"Form.DadosRegistro.valorHoraFuncionario"}
                  label="Valor Hora"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={5}>
                <CGIComboSimples<FormColaboradorType>
                  id={"Form.DadosRegistro.listaPerfisFuncionarioId"}
                  label="Perfil"
                  Link="perfilfuncionario"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIDatePicker<FormColaboradorType>
                  id="Form.DadosRegistro.dataAdmissaoFuncionario"
                  label="Data Admissão"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={5}>
                <CGIComboSimples<FormColaboradorType>
                  id={"Form.DadosRegistro.listaCargosFuncionarioId"}
                  label="Cargo"
                  Link="cargofuncionario"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.numeroRegistroFuncionario"}
                  label="Nº Registro"
                  placeholder="Nº Registro"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.cnhFuncionario"}
                  label="CNH"
                  placeholder="CNH"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.categoriaCNHFuncionario"}
                  label="Categoria CNH"
                  placeholder="Categoria CNH"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIDatePicker<FormColaboradorType>
                  id="Form.DadosRegistro.dataValidadeCNHFuncionario"
                  label="Data de Validade CNH"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.numeroPISFuncionario"}
                  label="Número do PIS"
                  placeholder="Número do PIS"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIDatePicker<FormColaboradorType>
                  id="Form.DadosRegistro.dataNascimentoFuncionario"
                  label="Data de Nascimento"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.carteiraDeTrabalhoFuncionario"}
                  label="Carteira de Trabalho"
                  placeholder="Carteira de Trabalho"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.serieCarteiraDeTrabalhoFuncionario"}
                  label="Série"
                  placeholder="Série"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGINumberInput<FormColaboradorType>
                  id={"Form.DadosRegistro.salarioFuncionario"}
                  label="Salário"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIComboSimples<FormColaboradorType>
                  id={"Form.DadosRegistro.listaTiposSanguineoFuncionario"}
                  label="Tipo Sanguíneo"
                  Link="tiposanguineo"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.exameToxicologicoFuncionario"}
                  label="Exame Toxicológico"
                  placeholder="Exame Toxicológico"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIDatePicker<FormColaboradorType>
                  id="Form.DadosRegistro.dataEmissaoLaudoFuncionario"
                  label="Data Emissão Laudo"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIDatePicker<FormColaboradorType>
                  id="Form.DadosRegistro.dataEmissaoRelatorioFuncionario"
                  label="Data Emissão Relatório"
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem hidden={!EhFornecedor} Col={12}>
            <CGIBox titulo="Dados de Fornecedor" popover="Dados de Fornecedor">
              <CGIBoxItem Col={3}>
                <CGIComboSimples<FormColaboradorType>
                  id="Form.DadosRegistro.listaLimitesCreditoFornecedorId"
                  label="Limite de Crédito"
                  Link="limitecredito"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.limiteDisponivelFornecedor"}
                  label="Limite Disponível"
                  placeholder="Limite Disponível"
                  att={{ isReadOnly: true }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIComboSimples<FormColaboradorType>
                  id={"Form.DadosRegistro.listaCondicoesPagamentoFornecedorId"}
                  label="Condição de Pagamento"
                  Link="condicaodepagamento"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIComboSimples<FormColaboradorType>
                  id={"Form.DadosRegistro.listaPlanosDeContasFornecedorId"}
                  label="Plano De Contas"
                  Link="planodecontas-despesas-conta"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={12}>
                <CGITextArea<FormColaboradorType>
                  id="Form.DadosRegistro.obsFornecedor"
                  label="Observação"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.fornecedorCritico"
                  label="Fornecedor Crítico"
                  tooltip={"Fornecedor Crítico"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGINumberInput<FormColaboradorType>
                  id={"Form.DadosRegistro.valorMinimoPedidoCompraFornecedor"}
                  label="Valor Mínimo Pedido de Compra"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGIComboSimples<FormColaboradorType>
                  id={"Form.DadosRegistro.listaTiposFretePadraoFornecedorId"}
                  label="Tipo de Frete Padrão"
                  Link="tipodefrete"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGINumberInput<FormColaboradorType>
                  id={"Form.DadosRegistro.porcentagemFreteFornecedor"}
                  label="% Frete"
                  prefix="%"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIComboSimples<FormColaboradorType>
                  id={"Form.DadosRegistro.listaTiposNotaEntradaFornecedor"}
                  label="Tipo Nota Entrada"
                  Link="tiponotaentrada"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.anttFornecedor"}
                  label="ANTT"
                  placeholder="ANTT"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.emailPedidoCompraFornecedor"}
                  label="E-mail para Pedido de Compra"
                  placeholder="E-mail para Pedido de Compra"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.crcFornecedor"}
                  label="CRC"
                  placeholder="CRC"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGIBox>
                  <CGIBoxItem Col={12}>
                    <CGICheckBox<FormColaboradorType>
                      id="Form.DadosRegistro.ehFabricanteFornecedor"
                      label="Fabricante"
                      tooltip={"Fabricante"}
                    />
                  </CGIBoxItem>
                  <CGIBoxItem Col={12}>
                    <CGICheckBox<FormColaboradorType>
                      id="Form.DadosRegistro.isentoIPIComprasFornecedor"
                      label="Isento de IPI nas compras"
                      tooltip={"Isento de IPI nas compras"}
                    />
                  </CGIBoxItem>
                </CGIBox>
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem hidden={!EhRespFinanceiro} Col={12}>
            <CGIBox
              titulo="Dados Resp. Financeiro"
              popover="Dados Resp. Financeiro"
            >
              <CGIBoxItem Col={4}>
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.gerarPedVendaConsumoPedLocacaoRespFinanceiro"
                  label="Gerar Pedido Venda Consumo Pedido Locação"
                  tooltip={"Gerar Pedido Venda Consumo Pedido Locação"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.gerarFaturaLocacaoRespFinanceiro"
                  label="Gerar Fatura Locação"
                  tooltip={"Gerar Fatura Locação"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.gerarNotaFiscalRespFinanceiro"
                  label="Gerar Nota Fiscal"
                  tooltip={"Gerar Nota Fiscal"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIDatePicker<FormColaboradorType>
                  id={"Form.DadosRegistro.diaFaturamentoRespFinanceiro"}
                  label="Dia Faturamento"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIComboSimples<FormColaboradorType>
                  id={"Form.DadosRegistro.listaTiposCobrancaRespFinanceiroId"}
                  label="Tipo de Cobrança"
                  Link="tipocobranca"
                />
              </CGIBoxItem>
              <CGIBoxItem
                hidden={!PedVendaConsumoPedLocacaoRespFinanceiro}
                Col={12}
              >
                <CGIBox>
                  <CGIBoxItem Col={3}>
                    <CGIComboSimples<FormColaboradorType>
                      id={"Form.DadosRegistro.listaEmpresasRespFinanceiroId"}
                      label="Empresa"
                      Link="empresa"
                    />
                  </CGIBoxItem>
                  <CGIBoxItem Col={3}>
                    <CGIComboSimples<FormColaboradorType>
                      id={
                        "Form.DadosRegistro.listaCondicoesPagamentoRespFinanceiroId"
                      }
                      label="Condição de Pagamento"
                      Link="condicaodepagamento"
                    />
                  </CGIBoxItem>
                  <CGIBoxItem Col={3}>
                    <CGIComboSimples<FormColaboradorType>
                      id={"Form.DadosRegistro.listaVendedoresRespFinanceiroId"}
                      label="Vendedor"
                      Link="vendedor"
                    />
                  </CGIBoxItem>
                  <CGIBoxItem Col={3}>
                    <CGIComboSimples<FormColaboradorType>
                      id={
                        "Form.DadosRegistro.listaNaturezasOperacaoRespFinanceiroId"
                      }
                      label="Natureza de Operação"
                      Link="naturezadeoperacao"
                    />
                  </CGIBoxItem>
                  <CGIBoxItem Col={3}>
                    <CGIComboSimples<FormColaboradorType>
                      id={"Form.DadosRegistro.listaTiposFreteRespFinanceiroId"}
                      label="Tipo Frete"
                      Link="tipodefrete"
                    />
                  </CGIBoxItem>
                  <CGIBoxItem Col={3}>
                    <CGIComboSimples<FormColaboradorType>
                      id={
                        "Form.DadosRegistro.listaTabelasPrecoRespFinanceiroId"
                      }
                      label="Tabela Preço"
                      Link="tabelaprecos"
                    />
                  </CGIBoxItem>
                </CGIBox>
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem hidden={!EhPosto} Col={12}>
            <CGIBox
              titulo="Dados de Posto de Comb."
              popover="Dados de Posto de Comb."
            >
              <CGIBoxItem Col={4}>
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.ehPostoConveniado"
                  label="É Posto Conveniado?"
                  tooltip={"É Posto Conveniado?"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.permitirDuplicidadeAbastecimento"
                  label="Permitir Duplicidade de Abastecimento"
                  tooltip={"Permitir Duplicidade de Abastecimento"}
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem hidden={!EhVendedor} Col={12}>
            <CGIBox titulo="Dados de Vendedor" popover="Dados de Vendedor">
              <CGIBoxItem Col={3}>
                <CGICheckBox<FormColaboradorType>
                  id="Form.DadosRegistro.calcularComissaoVendedor"
                  label="Calcular Comissão"
                  tooltip={"Calcular Comissão"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGINumberInput<FormColaboradorType>
                  id={"Form.DadosRegistro.porcentagemComissaoServicosVendedor"}
                  label="% Comissão Serviços"
                  prefix="%"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIComboSimples<FormColaboradorType>
                  id={
                    "Form.DadosRegistro.listaCodigosRelatorioPedVendaVendedor"
                  }
                  label="Cód. Relatório - Pedido Venda"
                  Link=""
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.codigoAutorizacaoVendedor"}
                  label="Cod. Autorização"
                  placeholder="Cod. Autorização"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGINumberInput<FormColaboradorType>
                  id={"Form.DadosRegistro.metaAnualVendedor"}
                  label="Meta Anual"
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem hidden={!EhTransportadora} Col={12}>
            <CGIBox
              titulo="Dados de Transportadora"
              popover="Dados de Transportadora"
            >
              <CGIBoxItem Col={12}>
                <CGITextArea<FormColaboradorType>
                  id="Form.DadosRegistro.obsTransportadora"
                  label="Obs. Transportadora"
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem hidden={!EhSeguradora} Col={12}>
            <CGIBox titulo="Dados de Seguradora" popover="Dados de Seguradora">
              <CGIBoxItem Col={3}>
                <CGIInput<FormColaboradorType>
                  id={"Form.DadosRegistro.numeroApoliceSeguradora"}
                  label="Número da apólice"
                  placeholder="Número da apólice"
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
  }
);
