import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../Context/LoadingContext";
import { FormCalculoPrecoVendaType } from "../../../Types/Sistema/Produto/CalculoPrecoVendaTypes";
import { CalculoPrecoVendaItensTypes } from "../../../Types/Sistema/Produto/CalculoPrecoVendaTypes";
import { HttpProduto } from "./Http";

type CalculoPrecoVendaType = {
  CarregarDados: Function;
};

export const CalculoPrecoVenda = forwardRef(
  (props: CalculoPrecoVendaType, ref) => {
    //INICIO  HOOKS
    useImperativeHandle(ref, () => ({
      Abrir(e: any) {
        CGIModalRef.current.Abrir();
        HttpProduto()
          .ObterCalculoPreco(e)
          .then((e) => {
            console.log(e.data);
            form.setValue("Form.Itens", e.data);
          });
      },
    }));

    const CGIModalRef = useRef() as any;
    const form = useFormContext<FormCalculoPrecoVendaType>();
    const CGIGridRef = useRef<AgGridReact<CalculoPrecoVendaItensTypes>>(null);
    const { setLoadingState } = useContext(LoadingContext);

    const Grid = useWatch({
      control: form.control,
      name: "Form.Itens",
    });

    const GridColunas: Array<CGICol<CalculoPrecoVendaItensTypes>> = [
      {
        Titulo: "Campo",
        Campo: "campo",
        Tipo: "Texto",
      },
      {
        Titulo: "Valor",
        Campo: "valor",
        Tipo: "Moeda",
        att: { maxWidth: 90 },
      },
    ];

    //INICO JSX
    return (
      <CGIModal
        id="sistema/produto/calculoprecovenda"
        ref={CGIModalRef}
        Titulo="Calculo Preço de Venda"
        TelaCheia={"5xl"}
        Confirmar={() => {}}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIResumo Expandir ProdutoId="produtoId" ResumoProdutoSimples />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIDataTableSimples
              DisableGetId
              ref={CGIGridRef}
              Colunas={GridColunas}
              Data={Grid}
              Altura={"400px"}
              Pag={10}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
    //FINAL JSX
  }
);
