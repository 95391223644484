import { CGIHttp } from "../../../Services/Axios";
import {
  FormPedidoCompraType,
  ListarPesquisaPedidoCompraTypes,
  ListarPedidoCompraItensTypes,
  PedidoCompraPesquisaGridTypes,
  PedidoCompraTypes,
} from "../../../Types/Almoxarifado/PedidoCompra/PedidoCompraTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpPedidoCompra() {
  const url = "/PedidoCompra";

  async function RetornarDadosGridPesquisa(
    dados: PedidoCompraPesquisaGridTypes
  ): Promise<ListarPedidoCompraItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-pedidoscompra", dados)
      .then((e) => e.data.data);
  }

  async function RetornarPedidosCompra(
    DadosFiltros: ListarPesquisaPedidoCompraTypes
  ): Promise<ListarPedidoCompraItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(url + "/obter-pedido-compra", DadosFiltros)
    ).data.data;
  }

  async function RetornarDadosPedidoCompra(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + `/alterar/${id}`)
    ).data.data) as PedidoCompraTypes;
  }

  async function RetornarDadosExclusao(Dados: ListarPedidoCompraItensTypes) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + Dados.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }
  async function RetornarDadosVisualizarPedidoCompra(
    DadosPedidoCompra: FormPedidoCompraType
  ) {
    return (await (
      await new CGIHttp().conn.get(
        url + "/visualizar/" + DadosPedidoCompra.Form
      )
    ).data.data) as PedidoCompraTypes;
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir/" + Dados.id);
  }
  async function Alterar(DadosPedidoCompra: FormPedidoCompraType) {
    return await new CGIHttp().conn.post(
      url + "/alterar",
      DadosPedidoCompra.Form
    );
  }
  async function Visualizar(DadosPedidoCompra: FormPedidoCompraType) {
    return await new CGIHttp().conn.post(
      url + "/visualizar",
      DadosPedidoCompra.Form
    );
  }
  async function Incluir(DadosPedidoCompra: FormPedidoCompraType) {
    return await new CGIHttp().conn.post(
      url + "/incluir",
      DadosPedidoCompra.Form
    );
  }
  return {
    RetornarPedidosCompra,
    RetornarDadosGridPesquisa,
    RetornarDadosExclusao,
    RetornarDadosVisualizarPedidoCompra,
    RetornarDadosPedidoCompra,
    Visualizar,
    Excluir,
    Alterar,
    Incluir,
  };
}
