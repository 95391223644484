import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useContext,
  useState,
  useMemo,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIRadioButton } from "../../../Components/RadioButton/CGIRadioButton";
import LoadingContext from "../../../Context/LoadingContext";
import { FormSugestaoPedidoCompraTypes } from "../../../Types/Almoxarifado/SugestaoPedidoCompra/SugestaoPedidoCompraTypes";
import { HttpSugestaoPedidoCompra } from "./Http";

type AlterarSugestaoPedidoCompraType = {
  CarregarDados: Function;
};

export const AlterarSugestaoPedidoCompra = forwardRef(
  (props: AlterarSugestaoPedidoCompraType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current.Abrir();
      },
      Alterar(pEmpresa: any) {
        CGIModalRef.current.Abrir();

        HttpSugestaoPedidoCompra()
          .RetornarDadosSugestaoPedidoCompra(pEmpresa.id)
          .then((e) => {
            form.reset(e.data.data);
          });
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<FormSugestaoPedidoCompraTypes>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);
    const [ExibirNormal, setExibirNormal] = useState(false);
    const [ExibirIndicacaoCompras, setExibirIndicacaoCompras] = useState(false);
    const Radio = useWatch({
      control: form.control,
      name: "Form.DadosRegistro.tipoSugestaoPedidoCompra",
    });

    const AlterarRadio = useMemo(() => {
      if (Radio === undefined || Radio === null || Radio === "") {
        return;
      }
      if (Radio === "NO") {
        setExibirNormal(true);
        setExibirIndicacaoCompras(false);
        form.setValue("Form.DadosRegistro.tipoSugestao", "NO");
      }
      if (Radio === "IC") {
        setExibirNormal(false);
        setExibirIndicacaoCompras(true);
      }
    }, [Radio]);

    const Confirmar = async (
      data: FormSugestaoPedidoCompraTypes,
      event: any
    ) => {
      setLoadingState(true);

      if (
        data.Form.DadosRegistro.id == null ||
        data.Form.DadosRegistro.id === undefined ||
        data.Form.DadosRegistro.id === 0
      ) {
        HttpSugestaoPedidoCompra()
          .Incluir(data.Form.DadosRegistro)
          .then((response: any) => {
            ToastSucesso("Registro gravado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return ToastErro(e);
          })
          .finally(() => {
            setLoadingState(false);
          });
      } else {
        HttpSugestaoPedidoCompra()
          .Alterar(data.Form.DadosRegistro)
          .then((data: any) => {
            ToastSucesso("Registro Alterado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return;
          })
          .finally(() => {
            setLoadingState(false);
          });
      }
    };

    return (
      <CGIModal
        id="sistema/classificacao/alterar"
        ref={CGIModalRef}
        Titulo="Sugestão Pedido de Compra"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGIBox>
          <CGIBoxItem Col={6}>
            <CGIComboSimples<FormSugestaoPedidoCompraTypes>
              id="Form.DadosRegistro.empresaId"
              label="Empresa"
              Link="empresa"
              validadores={{
                required: "Nome obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIComboAjax<FormSugestaoPedidoCompraTypes>
              id="Form.DadosRegistro.fornecedorId"
              label="Fornecedor"
              Link="fornecedor"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIComboAjax<FormSugestaoPedidoCompraTypes>
              id="Form.DadosRegistro.tipoProdutoId"
              label="Tipo de Produto"
              Link="tipodeproduto"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIComboAjax<FormSugestaoPedidoCompraTypes>
              id="Form.DadosRegistro.familiaId"
              label="Família"
              Link="familia"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboAjax<FormSugestaoPedidoCompraTypes>
              id="Form.DadosRegistro.grupoId"
              label="Grupo"
              Link="grupo"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboAjax<FormSugestaoPedidoCompraTypes>
              id="Form.DadosRegistro.subgrupoId"
              label="Subgrupo"
              Link="subgrupo"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIComboAjax<FormSugestaoPedidoCompraTypes>
              id="Form.DadosRegistro.linhaId"
              label="Linha"
              Link="linha"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIRadioButton<FormSugestaoPedidoCompraTypes>
              id="Form.DadosRegistro.tipoSugestaoPedidoCompra"
              row="row"
              label="Tipo Sugestão"
              att={{
                fontWeight: "bold",
              }}
              values={[
                { label: "Normal", valor: "NO" },
                { label: "Indicação de Compras", valor: "IC" },
              ]}
            />
            <>{AlterarRadio}</>
          </CGIBoxItem>
          <CGIBoxItem hidden={!ExibirNormal} Col={3}>
            <CGIBoxItem Col={3}>
              <CGICheckBox<FormSugestaoPedidoCompraTypes>
                id="Form.DadosRegistro.tipoSugestaoMediaDeVenda"
                label="Média de venda"
                tooltip=""
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGICheckBox<FormSugestaoPedidoCompraTypes>
                id="Form.DadosRegistro.tipoSugestaoEstoqueMinimo"
                label="Estoque Mínimo"
                tooltip=""
              />
            </CGIBoxItem>
          </CGIBoxItem>
          <CGIBoxItem hidden={!ExibirNormal} Col={5}>
            <CGIRadioButton<FormSugestaoPedidoCompraTypes>
              id="Form.DadosRegistro.tipoSugestaoMediaDeVendaQtdeMeses"
              row="row"
              label=""
              att={{
                fontWeight: "bold",
              }}
              values={[
                { label: "3 meses", valor: "3" },
                { label: "6 meses", valor: "6" },
                { label: "12 meses", valor: "12" },
              ]}
            />
          </CGIBoxItem>
          <CGIBoxItem hidden={!ExibirIndicacaoCompras} Col={8}>
            <CGIRadioButton<FormSugestaoPedidoCompraTypes>
              id="Form.DadosRegistro.tipoSugestao"
              row="row"
              label=""
              att={{
                fontWeight: "bold",
              }}
              values={[
                { label: "Todas", valor: "IT" },
                { label: "Somente manual", valor: "IM" },
                { label: "Somente vinculadas a pedidos", valor: "IP" },
              ]}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
  }
);
