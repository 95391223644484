import axios from "axios";
import { CGIHttp } from "../../../Services/Axios";
import { AlterarEstoqueNasEmpresasItensTypes } from "../../../Types/Almoxarifado/MovEstoque/AlterarEstoqueNasEmpresasType";
import {
  AlterarAtualizarSaldoItensTypes,
  AlterarAtualizarSaldoPesquisaTypes,
} from "../../../Types/Almoxarifado/MovEstoque/AtualizarSaldoType";
import {
  ListarEstoqueGeralItensTypes,
  ListarEstoqueGeralPesquisaTypes,
} from "../../../Types/Almoxarifado/MovEstoque/EstoqueGeralType";
import {
  ConsultarMovimentacaoProdutoItensTypes,
  ConsultarMovimentacaoProdutoPesquisaTypes,
} from "../../../Types/Almoxarifado/MovEstoque/MovEstoqueType";
import { AlterarPrecoNasEmpresasItensTypes } from "../../../Types/Cadastros/Produto/AlterarPrecoNasEmpresasType";

const url = "/movestoque";

export function HttpMovEstoque() {
  async function RetornarEstoqueGeral(
    DadosFiltros: ListarEstoqueGeralPesquisaTypes
  ): Promise<ListarEstoqueGeralItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-estoque-geral", DadosFiltros)
      .then((res) => res.data.data);
  }
  async function RetornarMovEstoqueProduto(
    DadosFiltros: ConsultarMovimentacaoProdutoPesquisaTypes
  ): Promise<ConsultarMovimentacaoProdutoItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-consulta-mov-estoque-produto", DadosFiltros)
      .then((res) => res.data.data);
  }

  async function Listar() {
    return await (
      await new CGIHttp().conn.get(url + "/listar")
    ).data.data;
  }
  async function RetornarEstoqueNasEmpresasParaAtualizar(
    pId: number
  ): Promise<AlterarEstoqueNasEmpresasItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(
        url + "/obter-estoque-empresas-para-atualizar/" + pId
      )
    ).data.data;
  }
  async function RetornarAtualizarSaldo(
    pDados: AlterarAtualizarSaldoPesquisaTypes
  ): Promise<AlterarAtualizarSaldoItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(url + "/obter-atualizar-saldo/", pDados)
    ).data.data;
  }
  async function AtualizarSaldo(pDadosGrid: AlterarAtualizarSaldoItensTypes[]) {
    return await new CGIHttp().conn
      .post(url + "/atualizar-saldo", { itens: pDadosGrid })
      .then((res) => res.data.data);
  }

  return {
    RetornarEstoqueGeral,
    Listar,
    RetornarMovEstoqueProduto,
    RetornarEstoqueNasEmpresasParaAtualizar,
    RetornarAtualizarSaldo,
    AtualizarSaldo,
  };
}
