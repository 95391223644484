import { CGIHttp } from "../../../../Services/Axios";
import {
  FormTipoClienteType,
  ListarTipoClienteItensTypes,
  ListarPesquisaTipoClienteTypes,
} from "../../../../Types/Sistema/Classificacao/TipoCliente/TipoClienteTypes";

const url = "/classificacao";

export function HttpTipoCliente() {
  async function RetornarTipoClientes(
    DadosFiltros: ListarPesquisaTipoClienteTypes
  ) {
    return (
      await new CGIHttp().conn.post(url + "/obter-tiposcliente", DadosFiltros)
    ).data.data;
  }

  async function Listar() {
    return await (
      await new CGIHttp().conn.get(url + "/listar/94")
    ).data.data;
  }

  async function RetornarDadosTipoCliente(id: number) {
    return await new CGIHttp().conn.get(url + `/alterar-tipocliente/${id}`);
  }

  async function Incluir(Dados: FormTipoClienteType) {
    return await new CGIHttp().conn.post(
      url + "/incluir-tipocliente",
      Dados.Form
    );
  }
  async function Alterar(Dados: FormTipoClienteType) {
    return await new CGIHttp().conn.post(
      url + "/alterar-tipocliente",
      Dados.Form
    );
  }
  async function RetornarDadosExclusao(Dados: ListarTipoClienteItensTypes) {
    return await new CGIHttp().conn.get(
      url + "/excluir-tipocliente/" + Dados.id
    );
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir-tipocliente", Dados);
  }
  async function Importar(Dados: FormTipoClienteType) {
    return await new CGIHttp().conn.post(
      url + "/incluir-tipocliente",
      Dados.Form
    );
  }
  return {
    RetornarTipoClientes,
    RetornarDadosTipoCliente,
    RetornarDadosExclusao,
    Incluir,
    Listar,
    Alterar,
    Excluir,
    Importar,
  };
}
