import { Tag, TagLabel, Box } from "@chakra-ui/react";
import { useCallback } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { CGIBox } from "../../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../../Components/Box/CGIBoxItens";
import { CGIDataTableSimples } from "../../../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../../../Components/DataTable/DataTable/DataTableTypes";
import {
  AnaliseCreditoContasReceberEmAtraso,
  AnaliseCreditoTypes,
} from "../../../../../Types/Financeiro/Colaborador/AnaliseCreditoTypes";
import { LegendaTypes } from "../../../../../Types/Modal/ModalBaseType";

export const GridContasReceberEmAtraso = (
  form: UseFormReturn<AnaliseCreditoTypes, any>
) => {
  const GridItens = useWatch({
    control: form.control,
    name: "Grids.ContasReceberEmAtraso.Itens",
  });
  const Legendas: LegendaTypes = [
    { nomeStatus: "Aberto", cor: "green" },
    { nomeStatus: "Pago Parcial", cor: "lightblue" },
    { nomeStatus: "Pago Total", cor: "blue" },
    { nomeStatus: "Estornado", cor: "orange" },
    { nomeStatus: "Excluído", cor: "red" },
    { nomeStatus: "Renegociado", cor: "yellow" },
  ];
  const GridCol: Array<CGICol<AnaliseCreditoContasReceberEmAtraso>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      Status: Legendas,
      att: {
        maxWidth: 60,
        filter: false,
      },
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Numero",
      ocultar: true,
    },

    {
      Campo: "documento",
      Titulo: "Documento",
      Tipo: "Texto",
      Ordernar: true,
    },

    {
      Campo: "dataVencimento",
      Titulo: "Data Vencimento",
      Tipo: "Data",
      Ordernar: true,
    },
    {
      Campo: "diasAtraso",
      Titulo: "Dias Atraso",
      Tipo: "Numero",
      Ordernar: true,
    },
    {
      Campo: "valorTitulo",
      Titulo: "Valor Título",
      Tipo: "Moeda",
      Ordernar: true,
    },
    {
      Campo: "multa",
      Titulo: "Multa",
      Tipo: "Moeda",
      Ordernar: true,
    },
    {
      Campo: "juros",
      Titulo: "Juros",
      Tipo: "Moeda",
      Ordernar: true,
    },
    {
      Campo: "saldoReceber",
      Titulo: "Valor Final",
      Tipo: "Moeda",
      Ordernar: true,
    },
  ];

  return (
    <CGIBox>
      <CGIBoxItem Col={12}>
        <CGIDataTableSimples
          DisableGetId
          Altura="400px"
          Colunas={GridCol}
          Data={GridItens}
        />
        <Box p={2}>
          {Legendas.map((e, index) => {
            return (
              <Tag
                mx={2}
                size={"md"}
                key={index}
                borderRadius="full"
                variant="solid"
                bgColor={e.cor}
              >
                <TagLabel color={"gray.100"}>{e.nomeStatus}</TagLabel>
              </Tag>
            );
          })}
        </Box>
      </CGIBoxItem>
    </CGIBox>
  );
};
