import { forwardRef, useImperativeHandle, useContext, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import LoadingContext from "../../../Context/LoadingContext";
import { HttpUsuario } from "./Http";

type CopiarPermissaoType = {
  CarregarDados: Function;
};
export const CopiarPermissao = forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    Abrir() {
      CGIModalRef.current.Abrir();
    },
  }));
  const { setLoadingState } = useContext(LoadingContext);
  const { ToastSucesso } = useCGIToast();
  const CGIModalRef = useRef() as any;
  const form = useFormContext();

  async function Confirmar(Dados: any) {
    await HttpUsuario()
      .CopiarPermissao(Dados)
      .then(() => {
        ToastSucesso("Permissões foram Copiadas");
      })
      .catch(() => {
        return;
      });
  }
  return (
    <CGIModal
      id="sistema/usuario/copiarpermissao"
      ref={CGIModalRef}
      Titulo="Copiar Permissão"
      TelaCheia={"6xl"}
      Confirmar={Confirmar}
      Fechar={() => {
        form.reset();
      }}
    >
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIComboSimples
            Link={"usuario"}
            label={"Usuário Origem"}
            id={"usuarioOrigemId"}
            //validadores={{ required: "Usuário Origem obrigatório" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIComboSimples
            Link={"usuario"}
            label={"Usuário Destino"}
            id={"usuarioDestinoId"}
            //validadores={{ required: "Usuário Destino obrigatório" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIComboSimples
            Link={"empresa"}
            label={"Empresa Origem"}
            id={"empresaOrigemId"}
            validadores={{ required: "Empresa Origem obrigatório" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIComboSimples
            Link={"empresa"}
            label={"Empresa Destino"}
            id={"empresaDestinoId"}
            validadores={{ required: "Empresa Destino obrigatório" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGICheckBox
            id={"substituirPermissoesUsuarioDestino"}
            label={"Substituir permissões do usuário destino"}
            tooltip={"Substituir permissões do usuário destino"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGICheckBox
            id={"copiarMenu"}
            label={"Copiar Menu"}
            tooltip={"Copiar Menu"}
          />
        </CGIBoxItem>
      </CGIBox>
    </CGIModal>
  );
});
