import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarPedidoEcommerceItensTypes,
  ListarPedidoEcommerceTypes,
  ObjListarPedidoEcommercePadrao,
} from "../../../Types/Vendas/PedidoEcommerce/PedidoEcommerceTypes";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpPedidoEcommerce } from "./Http";
import { AlterarPedidoEcommerce } from "./_AlterarPedidoEcommerce";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIHistorico } from "../../../Components/Historico/CGIHistorico";

export function ListarPedidoEcommerce() {
  const AlterarPedidoEcommerceRef = useRef<ModalBaseType>();
  const CGIHistoricoRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarPedidoEcommerceTypes>({
    defaultValues: ObjListarPedidoEcommercePadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarPedidosEcommerce = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpPedidoEcommerce().RetornarPedidoEcommerces(
        DadosFiltros
      );
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarPedidoEcommerceItensTypes) => {
      return await HttpPedidoEcommerce().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpPedidoEcommerce()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo PedidoEcommerce",
      Permissao: "sistema/PedidoEcommerce/incluir",
      OnClick: () => {
        AlterarPedidoEcommerceRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Alterar",
      Permissao: "sistema/PedidoEcommerce/alterar",
      OnClick: (e: ListarPedidoEcommerceItensTypes) => {
        AlterarPedidoEcommerceRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/PedidoEcommerce/excluir",
      OnClick: (e: ListarPedidoEcommerceItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Histórico",
      Permissao: "vendas/pedidoecommerce/visualizarhistoricopedidoecommerce",
      OnClick: (e: ListarPedidoEcommerceItensTypes) => {
        CGIHistoricoRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Conferido", cor: "wheat" },
    { nomeStatus: "Não definido", cor: "black" },
  ];
  const GridColunas: Array<CGICol<ListarPedidoEcommerceItensTypes>> = [
    {
      Campo: "nomeStatusPedido",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "id",
      Titulo: "Pedido",
      Tipo: "Composta",
      att: { minWidth: 180 },
      Composta: [
        {
          valor: "identificacaoPedido",
          titulo: "Pedido",
          exibirLabel: true,
          negrito: true,
        },
        {
          valor: "numeroPedido",
          titulo: "N° Pedido",
          exibirLabel: true,
          negrito: true,
        },
        {
          valor: "numeroPedidoLoja",
          titulo: "N° Ped. Loja",
          exibirLabel: true,
          negrito: true,
        },
      ],
    },
    {
      Campo: "nomeCliente",
      Titulo: "Cliente",
      Tipo: "Texto",
      att: { minWidth: 220, wrapText: true },
    },
    {
      Campo: "id",
      Titulo: "Market Place",
      Tipo: "Composta",
      att: { minWidth: 220, wrapText: true },
      Composta: [
        {
          valor: "nomeMarketPlace",
          titulo: "Market Place",
          exibirLabel: true,
          negrito: true,
        },
        {
          valor: "tipoIntegracao",
          titulo: "Integração",
          exibirLabel: true,
          negrito: true,
        },
      ],
    },
    {
      Campo: "percComissao",
      Titulo: "% Com.",
      Tipo: "Numero",
      att: { maxWidth: 130 },
    },
    {
      Campo: "nomeSituacao",
      Titulo: "Situação",
      Tipo: "Texto",
      att: { maxWidth: 130 },
    },
    {
      Campo: "totalProdutos",
      Titulo: "Tot. Produtos",
      Tipo: "Moeda",
      att: { maxWidth: 140 },
    },
    {
      Campo: "valorFrete",
      Titulo: "Tot. Frete",
      Tipo: "Moeda",
      att: { maxWidth: 130 },
    },
    {
      Campo: "totalVenda",
      Titulo: "Tot. Pedido",
      Tipo: "Moeda",
      att: { maxWidth: 130 },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarPedidoEcommerceItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir PedidoEcommerce"
          CarregarDados={RetornarPedidosEcommerce}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <CGIHistorico
          tipo="TimeLine"
          titulo="Histórico do Pedido Ecommerce"
          ref={CGIHistoricoRef}
          link={"vendas/pedidoecommerce/visualizarhistoricopedidoecommerce"}
          origem={"PEDIDOECOMMERCE"}
        />
      ),
    },
    {
      Componente: (
        <AlterarPedidoEcommerce
          CarregarDados={methods.handleSubmit(RetornarPedidosEcommerce)}
          ref={AlterarPedidoEcommerceRef}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Filtros de Período">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarPedidoEcommerceTypes>
                id="Pesquisa.dataInicialPedido"
                label="Data Pedido - Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarPedidoEcommerceTypes>
                id="Pesquisa.dataFinalPedido"
                label="Data Pedido - Final"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarPedidoEcommerceTypes>
                id="Pesquisa.dataInicialNotaFiscal"
                label="Data Nota Fiscal - Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarPedidoEcommerceTypes>
                id="Pesquisa.dataFinalNotaFiscal"
                label="Data Nota Fiscal - Final"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarPedidoEcommerceTypes>
                id="Pesquisa.dataInicialInclusao"
                label="Data Importação - Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarPedidoEcommerceTypes>
                id="Pesquisa.dataFinalInclusao"
                label="Data Importação - Final"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBox>
            <CGIBoxItem Col={8}>
              <CGIComboAjax<ListarPedidoEcommerceTypes>
                id="Pesquisa.listaClientesId"
                label="Cliente"
                Link={"cliente"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarPedidoEcommerceTypes>
                id="Pesquisa.listaSituacaoId"
                label="Situação"
                Link={"situacaoestoquepedido"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIComboSimples<ListarPedidoEcommerceTypes>
                id="Pesquisa.listaTiposIntegracoesId"
                label="Tipo Integração"
                Link={"integracaoMarketPlace"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIInput<ListarPedidoEcommerceTypes>
                id="Pesquisa.numeroPedido"
                label="N° Pedido"
                placeholder={"N° Pedido"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarPedidoEcommerceTypes>
                id="Pesquisa.listaStatusId"
                label="Status"
                Link={"statuspedido"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={8}>
              <CGIInput<ListarPedidoEcommerceTypes>
                id="Pesquisa.identificacaoPedido"
                label="Identificação Pedido"
                placeholder={"Identificação Pedido"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarPedidoEcommerceTypes>
            id="Pesquisa.exibirDevolucao"
            label="Pedidos Devolvidos"
            Link={"simnaoambos"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarPedidoEcommerceTypes>
            id="Pesquisa.numeroPedidoLoja"
            label="Número Pedido Loja"
            placeholder="Número Pedido Loja"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarPedidoEcommerceTypes>
            id="Pesquisa.listaMarketPlacesId"
            label="Market Place"
            Link={"marketplaces-homologados"}
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGICheckBox<ListarPedidoEcommerceTypes>
            id="Pesquisa.pedidoSemMarketPlace"
            label="Somente Pedidos sem Market Place"
            tooltip=""
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGICheckBox<ListarPedidoEcommerceTypes>
            id="Pesquisa.pedidoExcluido"
            label="Exibir Pedidos Excluídos"
            tooltip=""
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="vendas/pedidoecommerce/listar"
      Titulo="Listar Pedido Ecommerce"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarPedidosEcommerce)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
