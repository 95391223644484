import { Box, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { forwardRef, useRef, useState, useImperativeHandle } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../Box/CGIBox";
import { CGIBoxItem } from "../Box/CGIBoxItens";
import { CGIInput } from "../Input/CGIInput";
import { CGIModal } from "../Modal/CGIModal";
import { ModalDeleteTypes } from "./CGIModalDeleteTypes";
/**
 * @Componente CGIModalDelete - Delete Principal do Sistema
 * @Ref  CGIModalDelete
 */
export const CGIModalDelete = forwardRef(
  (
    props: {
      CarregarDados: Function;
      Titulo: string;
      CarregarDadosDeletar: any;
      Excluir: Function;
    },
    ref
  ) => {
    const CGIModalDeleteRef = useRef() as any;

    const { reset } = useFormContext();

    const [Dados, setDados] = useState<ModalDeleteTypes>(
      {} as ModalDeleteTypes
    );
    async function CarregarDados(params: any) {
      var dados = await props.CarregarDadosDeletar(params);
      setDados(dados.data.data);
      reset(dados.data.data);
    }

    useImperativeHandle(ref, () => ({
      Abrir(e: any) {
        CGIModalDeleteRef.current.Abrir();
        CarregarDados(e);
      },
      Fechar() {
        CGIModalDeleteRef.current.Fechar();
      },
    }));

    return (
      <CGIModal
        id=""
        ref={CGIModalDeleteRef}
        Titulo={props.Titulo}
        TelaCheia={"md"}
        Confirmar={() => {
          props.Excluir();
          return () => {
            props.CarregarDados();
          };
        }}
        Fechar={() => {
          reset({});
        }}
        ocultarConfirmar={Dados.ativo ? false : true}
      >
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIInput
              ocultar
              id="id"
              label="id"
              placeholder="id"
              att={{ isReadOnly: true }}
            />
            <CGIInput
              id="descricao"
              label="Descrição"
              placeholder="Descrição"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem hidden={Dados.ativo ? true : false} Col={12}>
            <Box p={2} bgColor={"#ff8a82"} borderRadius="md">
              <Text color={"#610a04"} fontWeight={"bold"}>
                Registro já Excluido , esse registro não podera ser excluído
              </Text>
            </Box>
          </CGIBoxItem>
          <CGIBoxItem hidden={Dados.campo1 ? false : true} Col={12}>
            <CGIInput
              id="valor1"
              label={Dados.campo1}
              placeholder="Valor1"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>

          <CGIBoxItem hidden={Dados.campo2 ? false : true} Col={12}>
            <CGIInput
              id="valor2"
              label={Dados.campo2}
              placeholder="Valor2"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>

          <CGIBoxItem hidden={Dados.campo3 ? false : true} Col={12}>
            <CGIInput
              id="valor3"
              label={Dados.campo3}
              placeholder="Valor3"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem hidden={Dados.campo4 ? false : true} Col={12}>
            <CGIInput
              id="valor4"
              label={Dados.campo4}
              placeholder="Valor4"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem hidden={Dados.obsExclusao ? false : true} Col={12}>
            <CGIInput
              id="obsExclusao"
              label="ObsExclusao"
              placeholder="ObsExclusao"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem hidden={Dados.msgAviso ? false : true} Col={12}>
            <CGIInput
              id="msgAviso"
              label="MsgAviso"
              placeholder="MsgAviso"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
  }
);
