import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useContext,
  useState,
  useCallback,
} from "react";
import { useForm, useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIRadioButton } from "../../../Components/RadioButton/CGIRadioButton";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import { CGITextArea } from "../../../Components/TextArea/CGITextArea";
import LoadingContext from "../../../Context/LoadingContext";
import {
  FormEquipamentoTypes,
  ListarEquipamentoItensTypes,
} from "../../../Types/Sistema/Equipamento/EquipamentoTypes";
import { HttpEquipamento } from "./Http";

type AlterarEquipamentoType = {
  CarregarDados: Function;
};

export const AlterarEquipamento = forwardRef(
  (props: AlterarEquipamentoType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current.Abrir();
      },
      Alterar(pEmpresa: any) {
        CGIModalRef.current.Abrir();

        HttpEquipamento()
          .RetornarDadosEquipamento(pEmpresa.id)
          .then((e) => {
            form.reset(e.data.data);
          });
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);
    const methods = useForm<FormEquipamentoTypes>();
    const [GridDadosEquipamentoVinculado, setGridDadosEquipamentoVinculado] =
      useState<Array<ListarEquipamentoItensTypes>>([]);

    //FINAL  HOOKS

    //INICIO CONST
    const GridColunasEquipamentoVinculado: Array<
      CGICol<ListarEquipamentoItensTypes>
    > = [
      {
        Campo: "identificacao",
        Titulo: "Identificação",
        Tipo: "Texto",
        Ordernar: true,
      },
      {
        Campo: "frota",
        Titulo: "Frota",
        Tipo: "Texto",
      },
      {
        Campo: "modelo",
        Titulo: "Modelo",
        Tipo: "Texto",
      },
    ];

    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Dados Equipamento",
        Component: AreaDadosEquipamento(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosEquipamento() {
      return (
        <CGIBox>
          <CGIBoxItem Col={3}>
            <CGIInput<FormEquipamentoTypes>
              id="Form.DadosRegistro.identificacao"
              label="Identificação"
              placeholder="Identificacao"
              validadores={{
                required: "Identificação obrigatória",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormEquipamentoTypes>
              id="Form.DadosRegistro.frota"
              label="Frota"
              placeholder="Frota"
              validadores={{
                required: "Frota obrigatória",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormEquipamentoTypes>
              id="Form.DadosRegistro.tipoEquipamentoId"
              label="Tipo de Equipamento"
              Link="fornecedor"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGICheckBox<FormEquipamentoTypes>
              id="Form.DadosRegistro.ativo"
              label="Ativo"
              tooltip={""}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormEquipamentoTypes>
              id="Form.DadosRegistro.modelo"
              label="Modelo"
              placeholder="Modelo"
              validadores={{
                required: "Modelo Obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormEquipamentoTypes>
              id="Form.DadosRegistro.empresaId"
              label="Empresa"
              Link="empresa"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={5}>
            <CGIComboSimples<FormEquipamentoTypes>
              id="Form.DadosRegistro.operadorId"
              label="Operador"
              Link="operador"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIInput<FormEquipamentoTypes>
              id="Form.DadosRegistro.rNTRC"
              label="RNTRC"
              placeholder="RNTRC"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIInput<FormEquipamentoTypes>
              id="Form.DadosRegistro.tara"
              label="Tara"
              placeholder="Tara"
              att={{ type: "number" }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIInput<FormEquipamentoTypes>
              id="Form.DadosRegistro.uF"
              label="UF"
              placeholder="UF"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormEquipamentoTypes>
              id="Form.DadosRegistro.capacidadeKG"
              label="Capacidade KG"
              placeholder="Capacidade KG"
              att={{ type: "number" }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormEquipamentoTypes>
              id="Form.DadosRegistro.capacidadeM3"
              label="Capacidade M3"
              placeholder="Capacidade M3"
              att={{ type: "number" }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIBox titulo="Tipo KM" popover="Tipo KM">
              <CGIBoxItem Col={3}>
                <CGIRadioButton<FormEquipamentoTypes>
                  id="Form.DadosRegistro.tipoKM"
                  row="row"
                  label=""
                  att={{
                    fontWeight: "bold",
                  }}
                  values={[
                    { label: "KM", valor: "K" },
                    { label: "Horímetro", valor: "H" },
                  ]}
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormEquipamentoTypes>
              id="Form.DadosRegistro.valorAquisicao"
              label="Valor Aquisição"
              placeholder="Valor Aquisição"
              att={{ type: "number" }}
              mascara="Real"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormEquipamentoTypes>
              id="Form.DadosRegistro.valorAtual"
              label="Valor Atual"
              placeholder="Valor Atual"
              att={{ type: "number" }}
              mascara="Real"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIDatePicker<FormEquipamentoTypes>
              id="Form.DadosRegistro.dataAquisicao"
              label="Data Aquisição"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples
              id="Form.DadosRegistro.tipoUnidadeTranspMDFe"
              label="Tipo de Transporte MDFe"
              Link="tipotransporte"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormEquipamentoTypes>
              id="Form.DadosRegistro.chassi"
              label="Chassi"
              placeholder="Chassi"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormEquipamentoTypes>
              id="Form.DadosRegistro.renavan"
              label="Renavam"
              placeholder="Renavam"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIDatePicker<FormEquipamentoTypes>
              id="Form.DadosRegistro.dataValidadeTacografo"
              label="Data Validade Tacógrafo"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={5}>
            <CGIComboSimples<FormEquipamentoTypes>
              id="Form.DadosRegistro.transportadoraId"
              label="Transportadora"
              Link="transportadora"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIDatePicker<FormEquipamentoTypes>
              id="Form.DadosRegistro.dataVencimentoIPVA"
              label="Data Venc. IPVA"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormEquipamentoTypes>
              id="Form.DadosRegistro.centroDeCustoId"
              label="Centro de Custo"
              Link="centrocusto"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormEquipamentoTypes>
              id="Form.DadosRegistro.locadorId"
              label="Locador/Terceiro"
              Link="locador"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormEquipamentoTypes>
              id="Form.DadosRegistro.apoliceSeguro"
              label="Apólice Seguro"
              placeholder="Apólice Seguro"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormEquipamentoTypes>
              id="Form.DadosRegistro.numeroSeguro"
              label="Número do Seguro"
              placeholder="Número do Seguro"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGITextArea<FormEquipamentoTypes>
              id="Form.DadosRegistro.informacoesSeguro"
              label="Observações do Seguro"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIBoxItem Col={2}>
              <CGICheckBox<FormEquipamentoTypes>
                id="Form.DadosRegistro.terceiro"
                label="Terceiro"
                tooltip={""}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={2}>
              <CGICheckBox<FormEquipamentoTypes>
                id="Form.DadosRegistro.locado"
                label="Locado"
                tooltip={""}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={2}>
              <CGICheckBox<FormEquipamentoTypes>
                id="Form.DadosRegistro.gerarQRCode"
                label="Gerar QR Code"
                tooltip={""}
              />
            </CGIBoxItem>
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGITextArea<FormEquipamentoTypes>
              id="Form.DadosRegistro.obs"
              label="Observações"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIBox
              titulo="Equipamentos Vinculados"
              popover="Equipamentos Vinculados"
            >
              <CGIBoxItem Col={12}>
                <CGIComboSimples<FormEquipamentoTypes>
                  id="Form.DadosRegistro.equipamentoVinculadoId"
                  label="Equipamento"
                  Link="equipamento"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGIButton
                  //onClick={() => RetornarDadosEquipamento()}
                  label="Adicionar"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={12}>
                <CGIBox>
                  <CGIBoxItem Col={12}>
                    <CGIDataTableSimples
                      Data={GridDadosEquipamentoVinculado}
                      Colunas={GridColunasEquipamentoVinculado}
                      Altura="250px"
                      Att={{
                        defaultColDef: {
                          filter: false,
                        },
                      }}
                    />
                  </CGIBoxItem>
                </CGIBox>
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
        </CGIBox>
      );
    }

    const Confirmar = async (data: FormEquipamentoTypes, event: any) => {
      setLoadingState(true);

      if (
        data.Form.DadosRegistro.id == null ||
        data.Form.DadosRegistro.id === undefined ||
        data.Form.DadosRegistro.id === 0
      ) {
        HttpEquipamento()
          .Incluir(data.Form.DadosRegistro)
          .then((response: any) => {
            ToastSucesso("Registro gravado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return ToastErro(e);
          })
          .finally(() => {
            setLoadingState(false);
          });
      } else {
        HttpEquipamento()
          .Alterar(data.Form.DadosRegistro)
          .then((data: any) => {
            ToastSucesso("Registro Alterado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return;
          })
          .finally(() => {
            setLoadingState(false);
          });
      }
    };
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="sistema/equipamento/alterar"
        ref={CGIModalRef}
        Titulo="Equipamento"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
