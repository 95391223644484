import axios from "axios";
import { CGIHttp } from "../../../Services/Axios";
import {
  BaixarCtsPagarType,
  FormBaixarCtsPagarType,
} from "../../../Types/Financeiro/ContasPagar/BaixarCtsPagarTypes";
import {
  ListarCtsPagarItensTypes,
  ListarPesquisaCtsPagarTypes,
  DadosRegistroTypes,
} from "../../../Types/Financeiro/ContasPagar/CtsPagar";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

const url = "/ctspagar";

export function HttpCtsPagar() {
  async function RetornarCtsPagar(DadosFiltros: ListarPesquisaCtsPagarTypes) {
    return (await (
      await new CGIHttp().conn.post(url + "/obter-ctspagar", DadosFiltros)
    ).data.data) as ListarCtsPagarItensTypes[];
  }

  async function RetornarDadosCtsPagar(id: number) {
    return await new CGIHttp().conn.get(url + `/alterar/${id}`);
  }

  async function Listar() {
    return await (
      await new CGIHttp().conn.get(url + "/listar")
    ).data.data;
  }

  async function DesbloquearCtsPagar(Dados: DadosRegistroTypes) {
    return await new CGIHttp().conn.post(url + " /desbloquear-ctspagar");
  }

  async function AlterarDataCadastro(Dados: DadosRegistroTypes) {
    return await new CGIHttp().conn.post(url + "/alterar-data-cadastro", Dados);
  }

  async function RetornarDadosExclusao(DadosEmpresa: ListarCtsPagarItensTypes) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + DadosEmpresa.id)
      .then(res => res.data.data)) as DeleteTypes;
  }

  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir", Dados);
  }
  async function GETBaixarCtsPagar(pId: Number): Promise<BaixarCtsPagarType> {
    return await new CGIHttp().conn
      .get(url + "/baixar-ctspagar/" + pId)
      .then(res => res.data.data);
  }
  async function RetornarDadosFormaPagamento(pId: Number): Promise<any> {
    return await new CGIHttp().conn
      .get("/classificacao/retornar-dados-forma-pagamento/" + pId)
      .then(res => res.data.data);
  }
  async function BaixarCtsPagar(Dados: FormBaixarCtsPagarType): Promise<void> {
    return await new CGIHttp().conn.post(url + "/baixar-ctspagar/", Dados.Form);
  }
  return {
    RetornarCtsPagar,
    RetornarDadosCtsPagar,
    Listar,
    RetornarDadosExclusao,
    Excluir,
    DesbloquearCtsPagar,
    AlterarDataCadastro,
    GETBaixarCtsPagar,
    RetornarDadosFormaPagamento,
    BaixarCtsPagar,
  };
}
