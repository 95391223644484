import { forwardRef, useImperativeHandle } from "react";
import { CGIBox } from "../../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../../../Components/CheckBox/CGICheckBox";
import { CGIComboSimples } from "../../../../../Components/Combos/CGIComboSimples";
import { CGIInput } from "../../../../../Components/Input/CGIInput";
import { PedidoLojaType } from "../../../../../Types/Vendas/Pedido/PedidoLojaType";

export const AreaOutros = forwardRef((props: {}, ref) => {
  useImperativeHandle(ref, () => ({}));
  return (
    <CGIBox>
      <CGIBoxItem Col={12}>
        <CGIComboSimples<PedidoLojaType>
          id={"DadosRegistro.planoDeContasId"}
          Link={"empresa"}
          label={"Plano de Contas"}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={12}>
        <CGIBox
          titulo="Pedido Locação - Vínculo"
          popover="Pedido Locação - Vínculo"
        >
          <CGIBoxItem Col={8}>
            <CGIInput<PedidoLojaType>
              id={"DadosRegistro.id"}
              placeholder={"Nome"}
              label="Nome"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGICheckBox<PedidoLojaType>
              id={"DadosNFSaida.vendaAvulsa"}
              label={"Venda Avulsa"}
              tooltip={"Venda Avulsa"}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIBoxItem>
      <CGIBoxItem Col={6}>
        <CGIBox titulo="Gerar OS. Transporte" popover="Gerar OS. Transporte">
          <CGIBoxItem Col={12}>
            <CGICheckBox<PedidoLojaType>
              id={"DadosNFSaida.gerarOsTransporte"}
              label={"Gerar OS. Transporte"}
              tooltip={" Gerar OS. Transporte"}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIBoxItem>
      <CGIBoxItem Col={6}>
        <CGIBox
          titulo="Gerar prot. de assinatura eletr."
          popover="Gerar protocolo de assinatura eletrônica"
        >
          <CGIBoxItem Col={12}>
            <CGICheckBox<PedidoLojaType>
              id={"DadosNFSaida.gerarProtocolodeAssinaturaEletronica"}
              label={"Gerar Protocolo de assinatura eletrônica"}
              tooltip={"Gerar Protocolo de assinatura eletrônica"}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIBoxItem>
    </CGIBox>
  );
});
