export const objPedLoja: PedidoLojaType = {
  DadosRegistro: {
    id: 0,
    identificacao: "",
    empresaId: 0,
    numero: 0,
    serie: "",
    dataCadastro: "",
    vendedorId: 0,
    qtdItens: 0,
    totalPedido: 0,
    pesoBruto: 0,
    pesoLiquido: 0,
    clienteId: 0,
    transportadoraId: 0,
    usuarioId: 0,
    statusId: 0,
    obsPedido: "",
    obsNF: "",
    placaVeiculo: "",
    valorFrete: 0,
    faturado: false,
    excluido: false,
    separado: false,
    conferido: false,
    entregue: false,
    baixado: false,
    freteCifFob: "",
    dataFaturamento: "",
    dataExclusao: "",
    dataSeparacao: "",
    dataConferencia: "",
    dataEntrega: "",
    dataBaixa: "",
    usuarioFaturamentoId: 0,
    usuarioExclusaoId: 0,
    usuarioSeparacaoId: 0,
    usuarioConferenciaId: 0,
    usuarioEntregaId: 0,
    usuarioBaixaId: 0,
    naturezaOperacaoId: 0,
    condicaoPagamentoId: 0,
    permitirFaturamento: false,
    problemaFaturamento: "",
    pedidoCliente: "",
    redespachoId: 0,
    desconto: 0,
    totalProdutos: 0,
    dataSaida: "",
    volumes: 0,
    especie: "",
    marca: "",
    uFVeiculo: "",
    valorDinheiro: 0,
    valorDebito: 0,
    valorCredito: 0,
    valorTicket: 0,
    valorCheque: 0,
    valorTroco: 0,
    pedidoImportado: false,
    pedidoComDevolucaoDeItens: false,
    descontoDevolucao: 0,
    totalComissao: 0,
    ordemAlocacao: 0,
    tipoEntrega: 0,
    valorMinimoFaturamento: 0,
    numeroMaximoEntregas: 0,
    dataPrevisaoEntrega: "",
    totalParaFaturamento: 0,
    totalPercAlocQtd: 0,
    totalPercAlocValor: 0,
    totalPercAlocQtdEstoque: 0,
    financeiroSeparado: false,
    tipoDesconto: "",
    paginaOrigem: "",
    condicaoPagamentoEntradaId: 0,
    pedidoBloqueado: false,
    dataDesbloqueio: "",
    usuarioDesbloqueioId: 0,
    obsDesbloqueio: "",
    autorizarNFAutomaticamente: false,
    itensComDescontoValorLiquido: false,
    alocarEstoquePedido: false,
    pedidoDevolvidoId: 0,
    devolvido: false,
    excluidoAutomaticamente: false,
    valorOutrasDespesas: 0,
    tabelaPrecosId: 0,
    localPedidoId: 0,
    totalParaBaixa: 0,
    valorDevolvido: 0,
    descontoOriginal: 0,
    convenioPortadorId: 0,
    indicadorPresentaId: 0,
    tipoFreteId: 0,
    indicadorPresencaId: 0,
    descontoGeralItens: 0,
    totalDevolvido: 0,
    nomePedido: "",
    margemLucroBruto: 0,
    valorLucroBruto: 0,
    motivoCancelamentoId: 0,
    valorICMSST: 0,
    enderecoEntregaId: 0,
    baseCalculoICMS: 0,
    valorICMS: 0,
    baseCalculoICMSST: 0,
    valorFCP: 0,
    valorFCPST: 0,
    percComissaoManual: 0,
    alterarComissaoManual: false,
    entradaSaida: "",
    pedidoImpresso: false,
    vlrCreditoColaboradorUtilizado: 0,
    formaPagamentoId: 0,
    tipoPedidoId: 0,
    numeroPreVenda: "",
    reabrirOrcamento: false,
    representanteId: 0,
    orcamentoId: 0,
    totalVlrDescontoPorItem: 0,
    clienteRetiraProdutos: false,
    obsExclusao: "",
    valorDevolucaoTroca: 0,
    totalCusto: 0,
    valorDinheiroRecebido: 0,
    pedidoLocacaoId: 0,
    planoDeContasId: 0,
    ramoAtividadeId: 0,
    comissaoExterna: 0,
    equipamentoId: 0,
    motoristaId: 0,
    nucleoId: 0,
    valorDescarga: 0,
    taxaEntregaId: 0,
    valorTaxaEntrega: 0,
    valorTaxaEntregaEntregador: 0,
    statusDeliveryId: 0,
    entregadorId: 0,
    marketPlaceId: 0,
    numeroReferencia: "",
    nomeComprador: "",
    percFrete: 0,
    valorIPI: 0,
    aguardandoConfirmacaoFinanceiro: false,
    valorEntrada: 0,
    pedidoServicoId: 0,
    pedidoAssinadoDigitalmente: false,
    oportunidadeVendaPetronectId: 0,
    formaPagamentoEntradaId: 0,
    totalPedidoFinal: 0,
    ehPedidoAgrupado: false,
    valorPIX: 0,
    dataValidade: "",
    separacaoTotalOuParcial: "",
    faturamentoBloqueado: false,
  },
  DadosProdutoSelecionado: {
    PesquisaDetalhada: "",
    vlrDesconto: 0,
    id: 0,
    codigoProduto: "",
    estoque: 0,
    multiplo: 0,
    precoVenda: 0,
    precoPromocional: 0,
    precoCampanhaVendas: 0,
    descricao: "",
    quantidade: 0,
    obsVenda: "",
    gradeProdutoId: 0,
    unidade: "",
    unidadesConversao: "",
    pesoBruto: 0,
    percICMS: 0,
    percIPI: 0,
    percFCP: 0,
    percPIS: 0,
    percCOFINS: 0,
    ultimaAlteracaoPrecoProduto: "",
    ehPrecoPromocional: false,
    ehPrecoCampanhaVendas: false,
    codigoProdutoFabricante: "",
    ncm: "",
    tipoProdutoId: 0,
    percDesconto: 0,
    usuarioDescontoId: "",
    descontoMaximo: 0,
    defeito: false,
    vlrMinimo: 0,
    vlrMinimoCadastro: 0,
    vlrUnitario: 0,
    campanhaVendasId: 0,
    nomeCampanhaVendas: 0,
  },
  config: {
    permitirInformarDataPedido: false,
    permitirUsuarioAlterarVlrUnitario: false,
    qtdCasasDecimaisQtd: 0,
    qtdCasasDecimaisVlrUnitario: 0,
    imprimirPedidoRomaneioAposInclusao: false,
    imprimirComprovanteDoCreditoNoFinalDoPedido: false,
    faturarOuBaixarPedidoAoIncluir: false,
    bloquearInclusaoDeUmItemMaisDeUmaVez: false,
    especie: "",
    marca: "",
    indicadorPresencaId: 0,
    tipoFreteId: 0,
    tipoEntregaId: 0,
    numeroMaximoEntregas: 0,
    valorMinimoFaturamento: 0,
    bloquearItensNegativos: false,
    bloquearItensNegativosApenasServidor: false,
    obrigarTipoDePedido: false,
    descontoMaximoPercentual: 0,
  },
  DadosNFSaida: {
    autorizarNFlogoAposFaturamento: false,
    financeiroVP: false,
    gerarOsTransporte: false,
    vendaAvulsa: false,
    pesoLiquido: 0,
    pesoBruto: 0,
    dataSaida: "",
    horaSaida: "",
    qtdVolumes: 0,
    especie: "",
    marca: "",
    placaVeiculo: "",
    ufVeiculo: "",
    gerarProtocolodeAssinaturaEletronica: false,
    baseCalculoICMS: 0,
    ValorICMS: 0,
    ValorIPI: 0,
    ValorFCP: 0,
    ValorFCPST: 0,
  },
  DadosProdutoTrocaSelecionado: {
    selecionado: false,
    id: 0,
    nomeTipo: "",
    vlrTotal: 0,
    codigoProduto: "",
    estoque: 0,
    multiplo: 0,
    precoVenda: 0,
    precoPromocional: 0,
    precoCampanhaVendas: 0,
    descricao: "",
    quantidade: 0,
    obsVenda: "",
    gradeProdutoId: 0,
    unidade: "",
    unidadesConversao: "",
    pesoBruto: 0,
    percICMS: 0,
    percIPI: 0,
    percFCP: 0,
    percPIS: 0,
    percCOFINS: 0,
    ultimaAlteracaoPrecoProduto: "",
    ehPrecoPromocional: false,
    ehPrecoCampanhaVendas: false,
    codigoProdutoFabricante: "",
    ncm: "",
    tipoProdutoId: 0,
    percDesconto: 0,
    vlrDesconto: 0,
    usuarioDescontoId: 0,
    descontoMaximo: 0,
    defeito: false,
    vlrMinimo: 0,
    vlrMinimoCadastro: 0,
    vlrUnitario: 0,
    campanhaVendasId: 0,
    nomeCampanhaVendas: "",
  },
  InformacoesColaborador: {
    id: 0,
    nomeCompleto: "",
    fisicaJuridica: "",
    cpfcnpj: "",
    saldoPortador: 0,
    planoDeContasId: 0,
    vendedorId: 0,
    limiteCreditoCompraId: 0,
    limiteCreditoVendaId: 0,
    limiteCreditoDisponivelCompra: 0,
    limiteCreditoDisponivelVenda: 0,
    limiteCreditoCompra: 0,
    limiteCreditoVenda: 0,
    condicaoPagamentoVendaId: 0,
    condicaoPagamentoCompraId: 0,
    tabelaPrecosId: 0,
    convenioPortadorVendaId: 0,
    naturezaOperacaoVendaId: 0,
    qtdAtraso: 0,
    possuiLimiteCreditoCompra: false,
    possuiLimiteCreditoVenda: false,
    empresaFaturamentoId: 0,
    percDescontoCliente: 0,
    suframa: "",
    obsCompra: "",
    obsVenda: "",
    nomeFantasia: "",
    valorMinimoPedidoVenda: 0,
    obrigarNumeroItemEmPedidoVenda: false,
    bloqueioVendasFixo: false,
    faturamentoBloquedo: false,
    clienteTemBloqueioDeVendas: false,
    enviarObsParaPedido: false,
    obsCadastro: "",
    clientesBloqNaoPodeGravarPedido: false,
    telefone: "",
    telefone2: "",
    telefone3: "",
    observacoes: [],
  },
  DadosPagamento: {
    formaPagamentoId: 0,
    parcelas: 0,
    valorVencimento: 0,
    calcParcelas: false,
    totalLancado: 0,
    totalRestante: 0,
  },
  totalProdutos: 0,
  totalDesconto: 0,
  totalPedido: 0,
  totalTroca: 0,
  totalIPI: 0,
  Pesquisa: {
    dataInclusaoInicial: "",
    dataInclusaoFinal: "",
    dataBaixaInicial: "",
    dataBaixaFinal: "",
    dataPrevisaoEntregaInicial: "",
    dataPrevisaoEntregaFinal: "",
    ListaEmpresasId: "",
    ListaNaturezasOperacaoId: "",
    ListaVendedoresId: "",
    pedidoCliente: "",
    identificacao: "",
    ListaStatusId: "",
    ListaTiposPedidoId: "",
    ListaClientesId: "",
    ListaTiposClienteId: "",
    ListaRotasId: "",
    ListaPracasId: "",
    ListaTransportadorasId: "",
    ListaRedespachosId: "",
    ListaProdutosId: "",
    ListaClassesProdutoId: "",
    ListaSubGruposId: "",
    ListaSubGruposExclusaoId: "",
    ListaResponsaveisFinanceirosId: "",
    ListaCategoriasPedidoLocacaoId: "",
    ListaFormasPagamentoId: "",
    ListaMotivosCancelamentoId: "",
    ListaRepresentantesId: "",
    codigoProduto: "",
    tipoPedidoPedido: false,
    tipoPedidoDevolucao: false,
    tipoPedidoPedidoComTroca: false,
    tipoFaturamento: "",
    somenteSemTransporte: false,
    somenteTroca: false,
    somentePedidoBloqueado: false,
    exibirPedidosExcluidosAutomaticamente: false,
    ocultarExcluidos: false,
    valorPedidoInicial: 0,
    valorPedidoFinal: 0,
    pedidoImpressoId: 0,
    exibirSomenteVenda: 0,
  },
  Itens: [],
};
export type PedidoLojaType = {
  DadosRegistro: DadosRegistroType;
  DadosProdutoSelecionado: DadosProdutoSelecionadoType;
  config: ConfigPedLojaType;
  DadosNFSaida: DadosNFSaidaType;
  DadosProdutoTrocaSelecionado: DadosProdutoTrocaSelecionadoType;
  InformacoesColaborador: PedidoInformacoesColaboradorType;
  DadosPagamento: DadosPagamentoType;
  totalProdutos: number;
  totalDesconto: number;
  totalPedido: number;
  totalTroca: number;
  totalIPI: number;
  Pesquisa: ListarPedidoType;
  Itens: ItensPedidoType[];
};

export type ConfigPedLojaType = {
  permitirInformarDataPedido: boolean;
  permitirUsuarioAlterarVlrUnitario: boolean;
  qtdCasasDecimaisQtd: number;
  qtdCasasDecimaisVlrUnitario: number;
  imprimirPedidoRomaneioAposInclusao: boolean;
  imprimirComprovanteDoCreditoNoFinalDoPedido: boolean;
  faturarOuBaixarPedidoAoIncluir: boolean;
  bloquearInclusaoDeUmItemMaisDeUmaVez: boolean;
  especie: string;
  marca: string;
  indicadorPresencaId: number;
  tipoFreteId: number;
  tipoEntregaId: number;
  numeroMaximoEntregas: number;
  valorMinimoFaturamento: number;
  bloquearItensNegativos: boolean;
  bloquearItensNegativosApenasServidor: boolean;
  obrigarTipoDePedido: boolean;
  descontoMaximoPercentual: number;
};
type DadosPagamentoType = {
  formaPagamentoId: number;
  parcelas: number;
  valorVencimento: number;
  calcParcelas: boolean;
  totalLancado: number;
  totalRestante: number;
};

export type DadosProdutoTrocaSelecionadoType = {
  selecionado: boolean;
  id: number;
  nomeTipo: string;
  vlrTotal: number;
  codigoProduto: string;
  estoque: number;
  multiplo: number;
  precoVenda: number;
  precoPromocional: number;
  precoCampanhaVendas: number;
  descricao: string;
  quantidade: number;
  obsVenda: string;
  gradeProdutoId: number;
  unidade: string;
  unidadesConversao: string;
  pesoBruto: number;
  percICMS: number;
  percIPI: number;
  percFCP: number;
  percPIS: number;
  percCOFINS: number;
  ultimaAlteracaoPrecoProduto: string;
  ehPrecoPromocional: boolean;
  ehPrecoCampanhaVendas: boolean;
  codigoProdutoFabricante: string;
  ncm: string;
  tipoProdutoId: number;
  percDesconto: number;
  vlrDesconto: number;
  usuarioDescontoId: number;
  descontoMaximo: number;
  defeito: boolean;
  vlrMinimo: number;
  vlrMinimoCadastro: number;
  vlrUnitario: number;
  campanhaVendasId: number;
  nomeCampanhaVendas: string;
};
type DadosNFSaidaType = {
  autorizarNFlogoAposFaturamento: boolean;
  financeiroVP: boolean;
  gerarOsTransporte: boolean;
  vendaAvulsa: boolean;
  pesoLiquido: number;
  pesoBruto: number;
  dataSaida: string;
  horaSaida: string;
  qtdVolumes: number;
  especie: string;
  marca: string;
  placaVeiculo: string;
  ufVeiculo: string;
  gerarProtocolodeAssinaturaEletronica: boolean;
  baseCalculoICMS: number;
  ValorICMS: number;
  ValorIPI: number;
  ValorFCP: number;
  ValorFCPST: number;
};

type DadosRegistroType = {
  id: number;
  identificacao: string;
  empresaId: number;
  numero: number;
  serie: string;
  dataCadastro: string;
  vendedorId: number;
  qtdItens: number;
  totalPedido: number;
  pesoBruto: number;
  pesoLiquido: number;
  clienteId: number;
  transportadoraId: number;
  usuarioId: number;
  statusId: number;
  obsPedido: string;
  obsNF: string;
  placaVeiculo: string;
  valorFrete: number;
  faturado: boolean;
  excluido: boolean;
  separado: boolean;
  conferido: boolean;
  entregue: boolean;
  baixado: boolean;
  freteCifFob: string;
  dataFaturamento: string;
  dataExclusao: string;
  dataSeparacao: string;
  dataConferencia: string;
  dataEntrega: string;
  dataBaixa: string;
  usuarioFaturamentoId: number;
  usuarioExclusaoId: number;
  usuarioSeparacaoId: number;
  usuarioConferenciaId: number;
  usuarioEntregaId: number;
  usuarioBaixaId: number;
  naturezaOperacaoId: number;
  condicaoPagamentoId: number;
  permitirFaturamento: boolean;
  problemaFaturamento: string;
  pedidoCliente: string;
  redespachoId: number;
  desconto: number;
  totalProdutos: number;
  dataSaida: string;
  volumes: number;
  especie: string;
  marca: string;
  uFVeiculo: string;
  valorDinheiro: number;
  valorDebito: number;
  valorCredito: number;
  valorTicket: number;
  valorCheque: number;
  valorTroco: number;
  pedidoImportado: boolean;
  pedidoComDevolucaoDeItens: boolean;
  descontoDevolucao: number;
  totalComissao: number;
  ordemAlocacao: number;
  tipoEntrega: number;
  valorMinimoFaturamento: number;
  numeroMaximoEntregas: number;
  dataPrevisaoEntrega: string;
  totalParaFaturamento: number;
  totalPercAlocQtd: number;
  totalPercAlocValor: number;
  totalPercAlocQtdEstoque: number;
  financeiroSeparado: boolean;
  tipoDesconto: string;
  paginaOrigem: string;
  condicaoPagamentoEntradaId: number;
  pedidoBloqueado: boolean;
  dataDesbloqueio: string;
  usuarioDesbloqueioId: number;
  obsDesbloqueio: string;
  autorizarNFAutomaticamente: boolean;
  itensComDescontoValorLiquido: boolean;
  alocarEstoquePedido: boolean;
  pedidoDevolvidoId: number;
  devolvido: boolean;
  excluidoAutomaticamente: boolean;
  valorOutrasDespesas: number;
  tabelaPrecosId: number;
  localPedidoId: number;
  totalParaBaixa: number;
  valorDevolvido: number;
  descontoOriginal: number;
  convenioPortadorId: number;
  indicadorPresentaId: number;
  tipoFreteId: number;
  indicadorPresencaId: number;
  descontoGeralItens: number;
  totalDevolvido: number;
  nomePedido: string;
  margemLucroBruto: number;
  valorLucroBruto: number;
  motivoCancelamentoId: number;
  valorICMSST: number;
  enderecoEntregaId: number;
  baseCalculoICMS: number;
  valorICMS: number;
  baseCalculoICMSST: number;
  valorFCP: number;
  valorFCPST: number;
  percComissaoManual: number;
  alterarComissaoManual: boolean;
  entradaSaida: string;
  pedidoImpresso: boolean;
  vlrCreditoColaboradorUtilizado: number;
  formaPagamentoId: number;
  tipoPedidoId: number;
  numeroPreVenda: string;
  reabrirOrcamento: boolean;
  representanteId: number;
  orcamentoId: number;
  totalVlrDescontoPorItem: number;
  clienteRetiraProdutos: boolean;
  obsExclusao: string;
  valorDevolucaoTroca: number;
  totalCusto: number;
  valorDinheiroRecebido: number;
  pedidoLocacaoId: number;
  planoDeContasId: number;
  ramoAtividadeId: number;
  comissaoExterna: number;
  equipamentoId: number;
  motoristaId: number;
  nucleoId: number;
  valorDescarga: number;
  taxaEntregaId: number;
  valorTaxaEntrega: number;
  valorTaxaEntregaEntregador: number;
  statusDeliveryId: number;
  entregadorId: number;
  marketPlaceId: number;
  numeroReferencia: string;
  nomeComprador: string;
  percFrete: number;
  valorIPI: number;
  aguardandoConfirmacaoFinanceiro: boolean;
  valorEntrada: number;
  pedidoServicoId: number;
  pedidoAssinadoDigitalmente: boolean;
  oportunidadeVendaPetronectId: number;
  formaPagamentoEntradaId: number;
  totalPedidoFinal: number;
  ehPedidoAgrupado: boolean;
  valorPIX: number;
  dataValidade: string;
  separacaoTotalOuParcial: string;
  faturamentoBloqueado: boolean;
};
export type DadosProdutoSelecionadoType = {
  PesquisaDetalhada: string;
  vlrDesconto: number;
  id: number;
  codigoProduto: string;
  estoque: number;
  multiplo: number;
  precoVenda: number;
  precoPromocional: number;
  precoCampanhaVendas: number;
  descricao: string;
  quantidade: number;
  obsVenda: string;
  gradeProdutoId: number;
  unidade: string;
  unidadesConversao: string;
  pesoBruto: number;
  percICMS: number;
  percIPI: number;
  percFCP: number;
  percPIS: number;
  percCOFINS: number;
  ultimaAlteracaoPrecoProduto: string;
  ehPrecoPromocional: boolean;
  ehPrecoCampanhaVendas: boolean;
  codigoProdutoFabricante: string;
  ncm: string;
  tipoProdutoId: number;
  percDesconto: number;
  usuarioDescontoId: string;
  descontoMaximo: number;
  defeito: boolean;
  vlrMinimo: number;
  vlrMinimoCadastro: number;
  vlrUnitario: number;
  campanhaVendasId: number;
  nomeCampanhaVendas: number;
};
export type ListarPedidoType = {
  dataInclusaoInicial: string;
  dataInclusaoFinal: string;
  dataBaixaInicial: string;
  dataBaixaFinal: string;
  dataPrevisaoEntregaInicial: string;
  dataPrevisaoEntregaFinal: string;
  ListaEmpresasId: string;
  ListaNaturezasOperacaoId: string;
  ListaVendedoresId: string;
  pedidoCliente: string;
  identificacao: string;
  ListaStatusId: string;
  ListaTiposPedidoId: string;
  ListaClientesId: string;
  ListaTiposClienteId: string;
  ListaRotasId: string;
  ListaPracasId: string;
  ListaTransportadorasId: string;
  ListaRedespachosId: string;
  ListaProdutosId: string;
  ListaClassesProdutoId: string;
  ListaSubGruposId: string;
  ListaSubGruposExclusaoId: string;
  ListaResponsaveisFinanceirosId: string;
  ListaCategoriasPedidoLocacaoId: string;
  ListaFormasPagamentoId: string;
  ListaMotivosCancelamentoId: string;
  ListaRepresentantesId: string;
  codigoProduto: string;
  tipoPedidoPedido: boolean;
  tipoPedidoDevolucao: boolean;
  tipoPedidoPedidoComTroca: boolean;
  tipoFaturamento: string;
  somenteSemTransporte: boolean;
  somenteTroca: boolean;
  somentePedidoBloqueado: boolean;
  exibirPedidosExcluidosAutomaticamente: boolean;
  ocultarExcluidos: boolean;
  valorPedidoInicial: number;
  valorPedidoFinal: number;
  pedidoImpressoId: number;
  exibirSomenteVenda: number;
};

export type ItensPedidoType = {
  selecionado: boolean;
  total: number;
  nomeStatus: string;
  campanhaVendasId: number;
  codigoProduto: string;
  codigoProdutoFabricante: string;
  descricao: string;
  ehPrecoCampanhaVendas: boolean;
  ehPrecoPromocional: boolean;
  estoque: number;
  gradeProdutoId: number;
  id: number;
  produtoId: number;
  multiplo: number;
  identificacao: string;
  ncm: string;
  nomeCampanhaVendas: string;
  nomeCliente: string;
  pedidoCliente: string;
  obsVenda: string;
  numeroNF: string;
  nomePedidoImpresso: string;
  nomeStatusFinanceiroTitulo: string;
  nomeTemObs: string;
  nomeTemAnexo: string;
  nomeVendedor: string;
  nomeTransportadora: string;
  nomeCondicaoPagamento: string;
  precoCampanhaVendas: number;
  precoPromocional: number;
  precoVenda: number;
  quantidade: number;
  ultimaAlteracaoPrecoProduto?: Date;
  unidade: string;
  unidadesConversao: string;
  vlrUnitario: number;
  vlrDesconto: number;
  qtdItens: number;
  totalPedido: number;
};

type PedidoInformacoesColaboradorType = {
  id: number;
  nomeCompleto: string;
  fisicaJuridica: string;
  cpfcnpj: string;
  saldoPortador: number;
  planoDeContasId: number;
  vendedorId: number;
  limiteCreditoCompraId: number;
  limiteCreditoVendaId: number;
  limiteCreditoDisponivelCompra: number;
  limiteCreditoDisponivelVenda: number;
  limiteCreditoCompra: number;
  limiteCreditoVenda: number;
  condicaoPagamentoVendaId: number;
  condicaoPagamentoCompraId: number;
  tabelaPrecosId: number;
  convenioPortadorVendaId: number;
  naturezaOperacaoVendaId: number;
  qtdAtraso: number;
  possuiLimiteCreditoCompra: boolean;
  possuiLimiteCreditoVenda: boolean;
  empresaFaturamentoId: number;
  percDescontoCliente: number;
  suframa: string;
  obsCompra: string;
  obsVenda: string;
  nomeFantasia: string;
  valorMinimoPedidoVenda: number;
  obrigarNumeroItemEmPedidoVenda: boolean;
  bloqueioVendasFixo: boolean;
  faturamentoBloquedo: boolean;
  clienteTemBloqueioDeVendas: boolean;
  enviarObsParaPedido: boolean;
  obsCadastro: string;
  clientesBloqNaoPodeGravarPedido: boolean;
  telefone: string;
  telefone2: string;
  telefone3: string;
  observacoes: Array<{
    mensagem: string;
    cor: string;
  }>;
};
