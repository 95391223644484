import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../../Components/CheckBox/CGICheckBox";
import { CGIComboSimples } from "../../../../Components/Combos/CGIComboSimples";
import { useCGIToast } from "../../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIModal } from "../../../../Components/Modal/CGIModal";
import { CGIRadioButton } from "../../../../Components/RadioButton/CGIRadioButton";
import { CGITabItensTypes, CGITabs } from "../../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../../Context/LoadingContext";
import { FormNaturezaOperacaoType } from "../../../../Types/Sistema/Classificacao/NaturezaOperacao/NaturezaOperacaoTypes";
import { HttpNaturezaOperacao } from "./Http";

type AlterarNaturezaOperacaoType = {
  CarregarDados: Function;
};

export const AlterarNaturezaOperacao = forwardRef(
  (props: AlterarNaturezaOperacaoType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current.Abrir();
      },
      async Alterar(pNatOp: any) {
        CGIModalRef.current.Abrir();
        var Dados = await HttpNaturezaOperacao().RetornarDadosNaturezaOperacao(
          pNatOp.id
        );
        form.setValue("Form", Dados);
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<FormNaturezaOperacaoType>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    //FINAL  HOOKS

    //INICIO CONST

    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Dados Natureza de Operação",
        Component: AreaDadosNaturezaOperacao(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosNaturezaOperacao() {
      return (
        <CGIBox>
          <CGIBoxItem Col={4}>
            <CGIInput<FormNaturezaOperacaoType>
              id="Form.DadosRegistro.nome"
              label="Nome"
              placeholder="Nome Natureza de Operação"
              validadores={{
                required: "Nome obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={8}>
            <CGIInput<FormNaturezaOperacaoType>
              id="Form.DadosRegistro.descricaoAuxiliar"
              label="Descrição para NF"
              placeholder="Descrição para NF"
              validadores={{
                required: "Descrição para NF obrigatória",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormNaturezaOperacaoType>
              id="Form.DadosRegistro.precoAutomaticoId"
              label="Preço Automático"
              Link="precoautomatico"
              validadores={{
                required: "Preço automático obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormNaturezaOperacaoType>
              id="Form.DadosRegistro.finalidadeDevolucaoRetornoNFId"
              label="Finalidade NF"
              Link="finalidadenf"
              validadores={{
                required: "Finalidade NF obrigatória",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormNaturezaOperacaoType>
              id="Form.DadosRegistro.finalidadeDevolucaoRetornoNFId"
              label="Finalidade Dev\Retorno NF"
              Link="finalidadenf"
              validadores={{
                required: "Finalidade NF Devolução/Retorno obrigatória",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormNaturezaOperacaoType>
              id="Form.DadosRegistro.condicaoPagamentoVendaId"
              label="Cond. Pagamento (Venda)"
              Link="condicaodepagamento"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIComboSimples<FormNaturezaOperacaoType>
              id="Form.DadosRegistro.empresaId"
              label="Empresa"
              Link="empresa"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormNaturezaOperacaoType>
              id="Form.DadosRegistro.numeroDeDiasParaNFRetorno"
              label="Número de Dias para NF Retorno"
              placeholder="Número de Dias para NF Retorno"
              att={{ type: "number" }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIBox titulo="Configurações Natureza de Operação" popover="">
              <CGIBoxItem Col={8}>
                <CGIRadioButton<FormNaturezaOperacaoType>
                  id={"Form.DadosRegistro.codigoExterno"}
                  row={"row"}
                  label={"Tipo"}
                  att={{
                    fontWeight: "bold",
                  }}
                  values={[
                    { label: "Venda", valor: "V" },
                    { label: "Romaneio", valor: "R" },
                    { label: "Devolução", valor: "D" },
                    { label: "Simples Remessa", valor: "S" },
                    { label: "Balcão", valor: "B" },
                    { label: "Frete", valor: "F" },
                  ]}
                  validadores={{
                    required: "Tipo obrigatório",
                  }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIBoxItem Col={2}>
                  <CGICheckBox<FormNaturezaOperacaoType>
                    id="Form.DadosRegistro.ativo"
                    label="Ativo"
                    tooltip={"Informa se o registro está ativo"}
                    attLabel={{
                      fontSize: 13,
                      fontWeight: "bold",
                    }}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={2}>
                  <CGICheckBox<FormNaturezaOperacaoType>
                    id="Form.DadosRegistro.alocarEstoque"
                    label="Alocar Estoque"
                    tooltip={
                      "Informa se esta Natureza de Operação alocará estoque"
                    }
                    attLabel={{
                      fontSize: 13,
                    }}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={2}>
                  <CGICheckBox<FormNaturezaOperacaoType>
                    id="Form.DadosRegistro.exibirEmPedidoVendaMobile"
                    label="Exibir em Ped. Mobile (Venda)"
                    tooltip={
                      "Informa se esta Natureza de Operação será exibida em Pedido Mobile"
                    }
                    attLabel={{
                      fontSize: 13,
                    }}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={2}>
                  <CGICheckBox<FormNaturezaOperacaoType>
                    id="Form.DadosRegistro.contabilizarComoVenda"
                    label="Contabilizar como Venda"
                    tooltip={
                      "Informa se esta Natureza de Operação contabilizará como venda"
                    }
                    attLabel={{
                      fontSize: 13,
                    }}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={2}>
                  <CGICheckBox<FormNaturezaOperacaoType>
                    id="Form.DadosRegistro.removerConvenioPortadorVenda"
                    label="Remover Conv. Portador (Venda)"
                    tooltip={
                      "Informa se o Portador será removido ao selecionar esta Natureza de Operação"
                    }
                    attLabel={{
                      fontSize: 13,
                    }}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={2}>
                  <CGICheckBox<FormNaturezaOperacaoType>
                    id="Form.DadosRegistro.permitirEstoqueNegativo"
                    label="Permitir Estoque Negativo"
                    tooltip={
                      "Informa se esta Natureza de Operação permitirá estoque negativo"
                    }
                    attLabel={{
                      fontSize: 13,
                    }}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={2}>
                  <CGICheckBox<FormNaturezaOperacaoType>
                    id="Form.DadosRegistro.ocultarInformacaoDeTotalTributos"
                    label="Ocultar Informação de Total de Tributos"
                    tooltip={
                      "Informa se esta Natureza de Operação ocultará a informação de Total de Tributos (NF)"
                    }
                    attLabel={{
                      fontSize: 13,
                    }}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={2}>
                  <CGICheckBox<FormNaturezaOperacaoType>
                    id="Form.DadosRegistro.calcularIPIConformeTipi"
                    label="Calcular IPI Conforme TIPI"
                    tooltip={"Calcular IPI Conforme TIPI"}
                    attLabel={{
                      fontSize: 13,
                    }}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={2}>
                  <CGICheckBox<FormNaturezaOperacaoType>
                    id="Form.DadosRegistro.internacional"
                    label="Internacional"
                    tooltip={
                      "Informa se esta Natureza de Operação será utilizada para operações internacionais"
                    }
                    attLabel={{
                      fontSize: 13,
                    }}
                  />
                </CGIBoxItem>
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIBoxItem Col={4}>
                  <CGIRadioButton<FormNaturezaOperacaoType>
                    id={"Form.DadosRegistro.tipoOperacaoNF"}
                    row={"row"}
                    label={"Tipo de Operação NF"}
                    att={{
                      fontWeight: "bold",
                    }}
                    values={[
                      { label: "Entrada", valor: "E" },
                      { label: "Saída", valor: "S" },
                    ]}
                    validadores={{
                      required: "Tipo de Operação obrigatória",
                    }}
                  />
                </CGIBoxItem>
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
        </CGIBox>
      );
    }

    const Confirmar = async (data: FormNaturezaOperacaoType, event: any) => {
      setLoadingState(true);

      if (
        data.Form.DadosRegistro.id == null ||
        data.Form.DadosRegistro.id === undefined ||
        data.Form.DadosRegistro.id === 0
      ) {
        HttpNaturezaOperacao()
          .Incluir(data)
          .then((response: any) => {
            ToastSucesso("Registro gravado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return ToastErro(e);
          })
          .finally(() => {
            setLoadingState(false);
          });
      } else {
        HttpNaturezaOperacao()
          .Alterar(data)
          .then((data: any) => {
            ToastSucesso("Registro Alterado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return;
          })
          .finally(() => {
            setLoadingState(false);
          });
      }
    };
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="sistema/classificacao/alterar"
        ref={CGIModalRef}
        Titulo="Natureza de Operação"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
