import { Box, Flex, Text, Tooltip, useMediaQuery } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { AlterarPermissao } from "../Permissoes/_AlterarPermissao";
import { ConvertIcones } from "../Util/ConvertIcons";
import { MontarTituloJanela } from "../Util/Permissoes";
import { CGIFormFavorito } from "./CGIFormFavotito";
import React from "react";
import { TamanhoTelaMobile, Temas } from "../../Themes/Temas";
import { FormContext } from "../../Context/FormConxtext";

type CGITituloJanelaType = {
  id?: string;
  Titulo: string;
  Janela: boolean;
  AlterarPermissao?: Function;
  temPermissao?: boolean;
  ExibirFav?: boolean;
};

/**
 * @Componente CGITituloJanela - Componente usado no Para Definir os Tilulos do Sistema
 * @Ref  CGIListar
 */
export function CGITituloJanela(props: CGITituloJanelaType) {
  const [isMobile] = useMediaQuery(TamanhoTelaMobile);

  useEffect(() => {
    if (!props.Janela) {
      document.title = MontarTituloJanela(props.Titulo);
    }
  }, [props.Janela, props.Titulo]);
  const CGIAlterarPermissaoTelaRef = useRef() as any;

  function RenderExibirPermissoes() {
    return (
      <Tooltip hasArrow label="Alterar Permissões" color={Temas.Branco} mt="3">
        <Flex
          pt={1}
          _hover={{
            cursor: "pointer",
          }}
          onClick={() => {
            CGIAlterarPermissaoTelaRef.current.Abrir(props.id);
          }}
          px={2}
          justify={"end"}
          align={"center"}
        >
          {ConvertIcones({ icones: "bloquear" })}
        </Flex>
      </Tooltip>
    );
  }

  return (
    <>
      <Box
        bg={Temas.Branco}
        borderRadius={"3xl"}
        pl="5"
        bgColor={"steelblue"}
        color={"white"}
        fontWeight={"semibold"}
        fontSize={28}
        w={"98%"}
      >
        {props.Janela ? (
          <Flex>
            <Flex flex={1} justify={"center"} align={"center"}>
              <Text> {props.Titulo}</Text>
            </Flex>
            {props.temPermissao ? RenderExibirPermissoes() : <></>}
          </Flex>
        ) : (
          <Flex>
            <Flex flex={1} justify={"start"} align={"center"}>
              <Text fontSize={isMobile ? 18 : 22}> {props.Titulo}</Text>
            </Flex>
            {!props.ExibirFav ? (
              <CGIFormFavorito id={props.id ? props.id : ""} />
            ) : (
              <></>
            )}
            {props.temPermissao ? RenderExibirPermissoes() : <></>}
          </Flex>
        )}
      </Box>
      <FormContext>
        <AlterarPermissao ref={CGIAlterarPermissaoTelaRef} />
      </FormContext>
    </>
  );
}
