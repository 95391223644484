import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGIDataTable } from "../../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../../Context/LoadingContext";
import {
  ListarNotasPendentesEntregaItensTypes,
  ListarNotasPendentesEntregaTypes,
  ObjListarNotasPendentesEntregaPadrao,
} from "../../../../Types/Faturamento/NFSaida/NotasPendentesEntrega/NotasPendentesEntregaTypes";
import {
  ModalBaseType,
  LegendaTypes,
} from "../../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../../Types/Util/DeleteTypes";
import { HttpNotasPendentesEntrega } from "./Http";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIDatePicker } from "../../../../Components/DatePicker/CGIDatePicker";
import { CGIComboSimples } from "../../../../Components/Combos/CGIComboSimples";
import { CGIRadioButton } from "../../../../Components/RadioButton/CGIRadioButton";
import { FormContext } from "../../../../Context/FormConxtext";
import { CGIVisualizarAnexo } from "../../../../Components/Anexo/CGIVisualizarAnexo";

export function ListarNotasPendentesEntrega() {
  const AlterarNotasPendentesEntregaRef = useRef<ModalBaseType>();
  const CGIAnexoRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarNotasPendentesEntregaTypes>({
    defaultValues: ObjListarNotasPendentesEntregaPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarNotasPendentesEntregaes = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data =
        await HttpNotasPendentesEntrega().RetornarNotasPendentesEntregas(
          DadosFiltros
        );
      console.log(data);
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarNotasPendentesEntregaItensTypes) => {
      return await HttpNotasPendentesEntrega().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpNotasPendentesEntrega()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Definir Como Entregue",
      Permissao: "sistema/notaspendentesentrega/alterar",
      OnClick: (e: ListarNotasPendentesEntregaItensTypes) => {
        AlterarNotasPendentesEntregaRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Impressões",
      Permissao: "sistema/NotasPendentesEntrega/excluir",
      OnClick: (e: ListarNotasPendentesEntregaItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Anexo",
      Permissao: "sistema/anexo/visualizaranexosnfsaida",
      OnClick: (e: any) => {
        CGIAnexoRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas: LegendaTypes = [
    { nomeStatus: "Incluída", cor: "green" },
    { nomeStatus: "Enviada", cor: "orange" },
    { nomeStatus: "Autorizada", cor: "blue" },
    { nomeStatus: "Rejeitada", cor: "red" },
    { nomeStatus: "Cancelada", cor: "darkred" },
    { nomeStatus: "Inutilizada", cor: "#C6E2FF" },
    { nomeStatus: "Devolvida", cor: "#FF7F00" },
    { nomeStatus: "Denegada", cor: "black" },
  ];

  const GridColunas: Array<CGICol<ListarNotasPendentesEntregaItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "nomeEmpresa",
      Titulo: "Empresa",
      Tipo: "Texto",
      att: { maxWidth: 150 },
    },
    {
      Campo: "numeroNF",
      Titulo: "N° NF",
      Tipo: "Numero",
      att: { maxWidth: 100 },
    },
    {
      Campo: "modelo",
      Titulo: "Tipo",
      Tipo: "Texto",
      att: { maxWidth: 80 },
    },
    {
      Campo: "dataCadastro",
      Titulo: "Data NF",
      Tipo: "Data",
      att: { maxWidth: 130 },
    },
    {
      Campo: "identificacaoPedido",
      Titulo: "Pedido",
      Tipo: "Texto",
      att: { maxWidth: 120 },
    },
    {
      Campo: "id",
      Titulo: "Colaborador",
      Tipo: "Composta",
      Composta: [
        {
          titulo: "",
          valor: "nomeColaborador",
        },
        {
          titulo: "",
          valor: "nomeFantasiaColaborador",
          negrito: true,
        },
      ],
    },
    {
      Campo: "valorNF",
      Titulo: "Valor",
      Tipo: "Moeda",
      att: { maxWidth: 100 },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarNotasPendentesEntregaItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          valor: "nomeStatus",
          titulo: "",
        },
        {
          valor: "nomeEmpresa",
          titulo: "Empresa",
        },
        {
          valor: "numeroNF",
          titulo: "N° NF",
        },
        {
          valor: "modelo",
          titulo: "Tip",
        },
        {
          valor: "dataCadastro",
          titulo: "Data NF",
        },
        {
          valor: "identificacaoPedido",
          titulo: "Pedido",
        },
        {
          titulo: "",
          valor: "nomeFantasiaColaborador",
          negrito: true,
        },
        {
          valor: "valorNF",
          titulo: "Valor",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Notas Pendentes Entrega"
          CarregarDados={RetornarNotasPendentesEntregaes}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <CGIVisualizarAnexo
          ref={CGIAnexoRef}
          link={"sistema/anexo/visualizaranexosnfsaida"}
          origem={"NFSAIDA"}
          titulo={"Anexos NF Saída"}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Filtros de Período">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarNotasPendentesEntregaTypes>
                id="Pesquisa.dataInicial"
                label="Período - Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarNotasPendentesEntregaTypes>
                id="Pesquisa.dataFinal"
                label="Período - Final"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIRadioButton<ListarNotasPendentesEntregaTypes>
                id={"Pesquisa.filtroData"}
                row={"row"}
                label={"Tipo"}
                values={[
                  {
                    label: "Dt. Emissão",
                    valor: "E",
                  },
                  {
                    label: "Dt. Autorização",
                    valor: "A",
                  },
                ]}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBox>
            <CGIBoxItem Col={4}>
              <CGIInput<ListarNotasPendentesEntregaTypes>
                id="Pesquisa.notaFiscal"
                label="Nota Fiscal"
                placeholder={"0"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIInput<ListarNotasPendentesEntregaTypes>
                id="Pesquisa.pedidoCliente"
                label="Pedido do Cliente (OC)"
                placeholder={"Pedido do Cliente (OC)"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIInput<ListarNotasPendentesEntregaTypes>
                id="Pesquisa.identificacaoPedido"
                label="Identificação Pedido"
                placeholder={"Identificação Pedido"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIComboSimples<ListarNotasPendentesEntregaTypes>
                id="Pesquisa.listaColaboradoresId"
                label="Colaborador"
                Link={"fornecedor"}
                Multipla
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="faturamento/nfsaida/notaspendentesentrega"
      Titulo="NF Saída Pendentes Entrega"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarNotasPendentesEntregaes)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
