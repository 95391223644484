export const ObjAlterarAtualizarSaldoPadrao: AlterarAtualizarSaldoTypes = {
  Pesquisa: {
    titulo: "",
    empresaId: 0,
    codigoProduto: "",
    descricao: "",
    codigoBarras: "",
    listaFornecedoresId: "",
    listaGruposId: "",
    listaSubGruposId: "",
    listaTiposProdutoId: "",
    listaFamiliasId: "",
    listaMarcasId: "",
    estoqueNegativo: true,
    estoquePositivo: true,
    estoqueZero: true,
    dataMovimentacao: "",
  },
  Itens: [],
};

export type AlterarAtualizarSaldoTypes = {
  Pesquisa: AlterarAtualizarSaldoPesquisaTypes;
  Itens: AlterarAtualizarSaldoItensTypes[];
};

export type AlterarAtualizarSaldoPesquisaTypes = {
  titulo: string;
  empresaId: number;
  codigoProduto: string;
  descricao: string;
  codigoBarras: string;
  listaFornecedoresId: string;
  listaGruposId: string;
  listaSubGruposId: string;
  listaTiposProdutoId: string;
  listaFamiliasId: string;
  listaMarcasId: string;
  estoqueNegativo: true;
  estoquePositivo: true;
  estoqueZero: true;
  dataMovimentacao: string;
};

export type AlterarAtualizarSaldoItensTypes = {
  produtoId: number;
  id: number;
  codigoProduto: string;
  codigoProdutoFabricante: string;
  empresaId: number;
  codigoBarras: string;
  descricao: string;
  nomeUnidade: string;
  estoque: number;
  novoEstoque: string;
  novoSaldoInformado: string;
  nomeFornecedor: string;
  nomeGrupo: string;
  nomeSubGrupo: string;
  dataCadastro: string;
};
