import { CGIHttp } from "../../../Services/Axios";
import {
  FormMovPortadorType,
  ListarPesquisaMovPortadorTypes,
  ListarMovPortadorItensTypes,
  MovPortadorPesquisaGridTypes,
  MovPortadorTypes,
  ListarSaldoGeralItensTypes,
  ListarPesquisaSaldoGeralTypes,
} from "../../../Types/Financeiro/MovPortador/MovPortadorTypes";
//import { MovPortadorGridTypes } from "../../../Types/Sistema/MovPortador/MovPortadorTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpMovPortador() {
  const url = "/movportador";

  async function RetornarDadosGridPesquisa(
    dados: MovPortadorPesquisaGridTypes
  ): Promise<ListarMovPortadorItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-mov-portador", dados)
      .then((e) => e.data.data);
  }

  async function RetornarMovPortador(
    DadosFiltros: ListarPesquisaMovPortadorTypes
  ): Promise<ListarMovPortadorItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(url + "/obter-mov-portador", DadosFiltros)
    ).data.data;
  }

  async function RetornarDadosExclusao(Dados: ListarMovPortadorItensTypes) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + Dados.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }

  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir/" + Dados.id);
  }
  async function AlterarLancamento(DadosMovPortador: FormMovPortadorType) {
    return await new CGIHttp().conn.post(
      url + "/alterar-lancamento",
      DadosMovPortador.Form
    );
  }
  async function RetornarDadosMovPortadorLancamento(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + `/alterar-lancamento/${id}`)
    ).data.data) as MovPortadorTypes;
  }
  async function Incluir(DadosMovPortador: FormMovPortadorType) {
    return await new CGIHttp().conn.post(
      url + "/incluir-mov-portador",
      DadosMovPortador.Form
    );
  }
  async function RetornarDadosPortador(DadosMovPortador: FormMovPortadorType) {
    return await new CGIHttp().conn.get(
      url +
        `/obter-dados-basico-portador/${DadosMovPortador.Form.dadosRegistro.portadorId}`
    );
  }
  async function RetornarSaldoGeral(
    DadosFiltros: ListarPesquisaSaldoGeralTypes
  ): Promise<ListarSaldoGeralItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(url + "/obter-saldo-geral", DadosFiltros)
    ).data.data;
  }
  return {
    RetornarDadosMovPortadorLancamento,
    RetornarDadosGridPesquisa,
    //RetornarDadosMovPortador,
    RetornarDadosExclusao,
    RetornarDadosPortador,
    RetornarMovPortador,
    AlterarLancamento,
    Excluir,
    Incluir,
    RetornarSaldoGeral,
  };
}
