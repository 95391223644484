export const ObjConfigServicoPadrao: FormConfigServicoType = {
  Form: {
    DadosRegistro: {
      id: 0,
      dataCadastro: "",
      usuarioId: 0,
      empresaId: 0,
      clienteId: 0,
      nome: "",
      itemListaServico: "",
      calcularISS: false,
      calcularDeducoes: false,
      calcularPIS: false,
      calcularCOFINS: false,
      calcularINSS: false,
      calcularCSLL: false,
      calcularIR: false,
      aliquotaISS: 0,
      aliquotaPIS: 0,
      aliquotaCOFINS: 0,
      aliquotaINSS: 0,
      aliquotaCSLL: 0,
      aliquotaIR: 0,
      prestacaoSUS: 0,
      issRetido: 0,
      responsavelRetencao: 0,
      ativo: false,
      descricaoServico: "",
      codigoCnae: "",
      tipoTributacao: "",
      destaqueImpostosId: 0,
      obsImpressaoPadrao: "",
      imprimirPedidoCliente: false,
      imprimirVencimentos: false,
      imprimirReferenciaCliente: false,
    },
  },
};
export type ConfigServicoTypes = {
  DadosRegistro: {
    id: number;
    dataCadastro: string | null;
    usuarioId: number;
    empresaId: number;
    clienteId: number;
    nome: string;
    itemListaServico: string;
    calcularISS: boolean;
    calcularDeducoes: boolean;
    calcularPIS: boolean;
    calcularCOFINS: boolean;
    calcularINSS: boolean;
    calcularCSLL: boolean;
    calcularIR: boolean;
    aliquotaISS: number;
    aliquotaPIS: number;
    aliquotaCOFINS: number;
    aliquotaINSS: number;
    aliquotaCSLL: number;
    aliquotaIR: number;
    prestacaoSUS: number;
    issRetido: number;
    responsavelRetencao: number;
    ativo: boolean;
    descricaoServico: string;
    codigoCnae: string;
    tipoTributacao: string;
    destaqueImpostosId: number;
    obsImpressaoPadrao: string;
    imprimirPedidoCliente: boolean;
    imprimirVencimentos: boolean;
    imprimirReferenciaCliente: boolean;
  };
};
export type FormConfigServicoType = {
  Form: ConfigServicoTypes;
};

export const ObjListarConfigServicoPadrao: ListarConfigServicoTypes = {
  Pesquisa: {
    listaEmpresasId: "",
    nome: "",
    ativoId: 0,
  },
  Itens: [],
};

export type ConfigServicoPesquisaGridTypes = {
  nome?: string;
  itemListaServico?: string;
  calcularISS?: boolean;
  calcularPIS?: boolean;
  calcularCOFINS?: boolean;
  ativo?: boolean;
  codigoCnae?: string;
};

export type ListarConfigServicoTypes = {
  Pesquisa: ListarPesquisaConfigServicoTypes;
  Itens: ListarConfigServicoItensTypes[];
};

export type ListarPesquisaConfigServicoTypes = {
  listaEmpresasId: string;
  nome: string;
  ativoId: number;
};

export type ConfigServicoVisualizarTypes = {
  id: number;
  ncm: string;
  percIPI: number;
  ex: number;
};

export type ListarConfigServicoItensTypes = {
  id: number;
  nome: string;
  nomeStatus: string;
  itemListaServico: string;
  calcularISS: boolean;
  calcularPIS: boolean;
  calcularCOFINS: boolean;
  ativo: boolean;
  codigoCnae: string;
};

export type ConfigServicoAlterarTypes = {
  id: number;
  ncm: string;
  percIPI: number;
  ex: number;
};
