import { CGIHttp } from "../../Services/Axios";

export function HttpUtil() {
  async function RetornarGETTelas(tipo: string) {
    const Dados = await new CGIHttp().conn.get(
      "home/obter-objeto-valores-iniciais/" + tipo
    );
    return Dados.data.data;
  }

  return {
    RetornarGETTelas,
  };
}
