export const ObjListarEstoqueGeralPadrao: ListarEstoqueGeralTypes = {
  Pesquisa: {
    codigoProduto: "",
    descricao: "",
    listaGruposId: "",
    listaSubGruposId: "",
    listaClassesId: "",
    listaTiposProdutoId: "",
    listaEmpresasId: "",
    listaFornecedoresId: "",
    listaFamiliasId: "",
    listaMarcasId: "",
    listaTamanhosId: "",
    listaCoresId: "",
    estoqueNegativo: false,
    estoquePositivo: false,
    estoqueZero: false,
    saldoEmEstoqueNegativo: false,
    saldoEmEstoquePositivo: false,
    saldoEmEstoqueZero: false,
    pedidoEmAberto: false,
    ordemProducaoEmAberto: false,
    exibirEstoqueTodasEmpresas: false,
    exibirEstoquePorLocalizaçãoDosPedidos: false,
  },
  Itens: [],
};

export type ListarEstoqueGeralTypes = {
  Pesquisa: ListarEstoqueGeralPesquisaTypes;
  Itens: ListarEstoqueGeralItensTypes[];
};

export type ListarEstoqueGeralPesquisaTypes = {
  codigoProduto: string;
  descricao: string;
  listaGruposId: string;
  listaSubGruposId: string;
  listaClassesId: string;
  listaTiposProdutoId: string;
  listaEmpresasId: string;
  listaFornecedoresId: string;
  listaFamiliasId: string;
  listaMarcasId: string;
  listaTamanhosId: string;
  listaCoresId: string;
  estoqueNegativo: boolean;
  estoquePositivo: boolean;
  estoqueZero: boolean;
  saldoEmEstoqueNegativo: boolean;
  saldoEmEstoquePositivo: boolean;
  saldoEmEstoqueZero: boolean;
  pedidoEmAberto: boolean;
  ordemProducaoEmAberto: boolean;
  exibirEstoqueTodasEmpresas: boolean;
  exibirEstoquePorLocalizaçãoDosPedidos: boolean;
};

export type ListarEstoqueGeralItensTypes = {
  produtoId: number;
  codigoProduto: string;
  codigoProdutoFabricante: null;
  codigoBarras: string;
  descricao: string;
  qtdLocalComercial: number;
  qtdLocalExpedicao: number;
  qtdLocalProducao: number;
  qtdLocalFaturamento: number;
  qtdPedidoCompraEmAberto: number;
  estoque: number;
  ordemProducaoEmAberto: number;
  pedidoVendaEmAberto: number;
  totalAlocado: number;
  totalAlocadoManual: number;
  orcamentoEmAberto: number;
  pedidoCompraEmAberto: number;
  transfInternaEmAberto: number;
  nfEntradaEmAberto: number;
  saldoEstoque: number;
  saldoComProducao: number;
};
