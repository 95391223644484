import { Stimulsoft } from "stimulsoft-reports-js";
import { CGIHttp } from "../../../Services/Axios";

const url = "/home";

export function HttpRelatorio() {
  async function RetornarAreaRelatorio<T>(id: string) {
    var data = await new CGIHttp().conn.post(
      url + "/obter-dados-montagem-relatorio/" + id
    );

    return data.data.data as T;
  }
  async function RetornarDadosExibicao(id: string | undefined, Dados: any) {
    var data = await new CGIHttp().conn.post(
      url + "/obter-dados-exibicao-relatorio/",
      { id: id, Dados: Dados }
    );

    return data.data.data as any;
  }

  return {
    RetornarAreaRelatorio,
    RetornarDadosExibicao,
  };
}
