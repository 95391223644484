import React, { useEffect, useState } from "react";
import { Routes, Route, useParams, useLocation } from "react-router-dom";
import { Stimulsoft } from "stimulsoft-reports-js/Scripts/stimulsoft.blockly.editor";
import "stimulsoft-reports-js/Css/stimulsoft.designer.office2013.whiteblue.css";
import "stimulsoft-reports-js/Css/stimulsoft.viewer.office2013.whiteblue.css";
import { HttpRelatorio } from "./Http";
import { Box, Center, Flex, Text } from "@chakra-ui/react";

type MontagemRelatorioType = {
  area: string;
  arquivoRelatorio: string;
  link: string;
};

export function VisualizarRelatorio() {
  const [RelatorioNaoEncontrado, setRelatorioNaoEncontrado] = useState(false);
  const { id } = useParams();
  try {
    Stimulsoft.Base.StiLicense.Key =
      "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHm4CTJvEYSNYtHZUDxtMJ4tGYX6ab+Qa6YuzWZOkehfzb8JD1" +
      "VUdwCy/DTfZ/hHCQcZ3hdVILw4GOo1fwoJMtAzvFilcNVFwvmCAcC68F9LviITz6sTBBwcLJve1vWh28IzWajbCdlG" +
      "BZf/mbDVi2981N9SQg0i8O84WHIsdaBu04j8XFpYju6MXPKwNWjAP/yRJte7GDEyRc63T73qAreW0EkZjL6svYX/44" +
      "QQ0d4dQysgnlU+exBTq7Uq/4xxi3NFWM1g6KkHOaoFSMLfksP9JAJVP0iiMIHNFUw8eYd0mwyuafUXYO362Q4d09Ul" +
      "o/NukR1XR6eOpM/X8t3eUJ71+sU2d8Q91LN9yxLM0rtI1KmCn4H1Dsgq7e5RB4x8ysgqHaFuR1RLq+qh23EVsr43Ry" +
      "NRQ2oJBXrc2W9toqmrI91Z6LexX8PS0/bu+iTVIVHdaJA62apM5mUxfli8sLneO3ppVtv9sNBhcNnz0e5ir7UayxNC" +
      "hWSWjOfWJbhe44cfTvCvTGtcG5zgWJ3MSAUb9r+BFAT0le9lS+dYc11GWU+WzNn7cxZrefXifqli3i6aidsT3lLEE4" +
      "Ywn7Fnrjk1WDKj+DH9oAa0ocjCtIagCH0CIvNAs9KZCIbQW1wYM8meCYhGJxaQbdrHiZvw3bMB";
  } catch (error) {}

  const options = new Stimulsoft.Viewer.StiViewerOptions();
  options.toolbar.viewMode = Stimulsoft.Viewer.StiWebViewMode.Continuous; // ESSE AQUI NÃO FUNCIONA!  Se comentar funciona
  options.appearance.pageBorderColor = Stimulsoft.System.Drawing.Color.navy;
  options.toolbar.showPrintButton = false;
  options.toolbar.showViewModeButton = false;
  options.toolbar.alignment = Stimulsoft.Viewer.StiContentAlignment.Center;

  const viewer = new Stimulsoft.Viewer.StiViewer(options, "StiViewer", false);
  const report = new Stimulsoft.Report.StiReport();

  const location = useLocation();
  const Render = async (DadosMontagem: MontagemRelatorioType) => {
    try {
      var caminho =
        "/Reports/" + DadosMontagem.area + "/" + DadosMontagem.arquivoRelatorio;

      var queryString = location.search.replaceAll("undefined", "");

      const params = Object.fromEntries(new URLSearchParams(queryString));

      report.loadFile(caminho);

      var Dados = await HttpRelatorio().RetornarDadosExibicao(id, params);

      var dataSet = new Stimulsoft.System.Data.DataSet("Dados");
      dataSet.readJson({
        Dados: Dados.dados,
        DadosCabecalho: Dados.dadosCabecalho,
      });
      report.regData("Dados", "Dados", dataSet);
      viewer.report = report;

      if (viewer.report.pages.list.length > 0) {
        viewer.renderHtml("viewer");
      } else {
        setRelatorioNaoEncontrado(true);
      }
    } catch (error) {}
  };

  const RetornarArea = async () => {
    if (id) {
      var Dados =
        await HttpRelatorio().RetornarAreaRelatorio<MontagemRelatorioType>(id);
      Render(Dados);
    }
  };

  useEffect(() => {
    RetornarArea();
  }, []);

  return (
    <>
      <Box flex={1}>
        <div id="viewer" />
      </Box>
      {RelatorioNaoEncontrado && (
        <Center h={"100%"}>
          <Box
            borderRadius={"lg"}
            borderWidth={1}
            borderColor={"gray.400"}
            shadow={"lg"}
            w={300}
            h={150}
          >
            <Center h={"100%"}>
              <Text fontSize={20} fontWeight={"bold"}>
                Relatório não encontrado
              </Text>
            </Center>
          </Box>
        </Center>
      )}
    </>
  );
}
