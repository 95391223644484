import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../../Components/CheckBox/CGICheckBox";
import { useCGIToast } from "../../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIModal } from "../../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../../Context/LoadingContext";
import { FormIntegracaoMarketPlaceType } from "../../../../Types/Sistema/Classificacao/IntegracaoMarketPlace/IntegracaoMarketPlaceTypes";
import { HttpIntegracaoMarketPlace } from "./Http";

type AlterarIntegracaoMarketPlaceType = {
  CarregarDados: Function;
};

export const AlterarIntegracaoMarketPlace = forwardRef(
  (props: AlterarIntegracaoMarketPlaceType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current.Abrir();
      },
      Alterar(pEmpresa: any) {
        CGIModalRef.current.Abrir();

        HttpIntegracaoMarketPlace()
          .RetornarDadosIntegracaoMarketPlace(pEmpresa.id)
          .then((e) => {
            form.reset(e.data.data);
          });
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<FormIntegracaoMarketPlaceType>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    //FINAL  HOOKS

    //INICIO CONST

    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Tipo Contrato",
        Component: AreaDadosIntegracaoMarketPlace(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosIntegracaoMarketPlace() {
      return (
        <CGIBox>
          <CGIBoxItem Col={4}>
            <CGIInput<FormIntegracaoMarketPlaceType>
              id="Form.DadosRegistro.nome"
              label="Integração Market Place"
              placeholder=""
              validadores={{
                required: "Nome obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormIntegracaoMarketPlaceType>
              id="Form.DadosRegistro.token"
              label="Token"
              placeholder=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormIntegracaoMarketPlaceType>
              id="Form.DadosRegistro.dadosAuxiliares"
              label="Dados Auxiliares"
              placeholder=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormIntegracaoMarketPlaceType>
              id="Form.DadosRegistro.valorMinimoProduto"
              label="Valor Mínimo do Produto"
              placeholder="0"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormIntegracaoMarketPlaceType>
              id="Form.DadosRegistro.percentualComissaoIntegracao"
              label="Percentual de Comissão Integração"
              placeholder="0"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGICheckBox<FormIntegracaoMarketPlaceType>
              id="Form.DadosRegistro.ativo"
              label="Ativo"
              tooltip=""
              att={{
                style: { marginTop: 30 },
              }}
              attLabel={{
                style: { marginTop: 30 },
              }}
            />
          </CGIBoxItem>
        </CGIBox>
      );
    }

    const Confirmar = async (
      data: FormIntegracaoMarketPlaceType,
      event: any
    ) => {
      setLoadingState(true);
      let Dados = form.getValues();

      if (
        Dados.Form.DadosRegistro.id == null ||
        Dados.Form.DadosRegistro.id === undefined ||
        Dados.Form.DadosRegistro.id === 0
      ) {
        HttpIntegracaoMarketPlace()
          .Incluir(Dados)
          .then((response: any) => {
            ToastSucesso("Registro gravado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return ToastErro(e);
          })
          .finally(() => {
            setLoadingState(false);
          });
      } else {
        HttpIntegracaoMarketPlace()
          .Alterar(data)
          .then((data: any) => {
            ToastSucesso("Registro Alterado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return;
          })
          .finally(() => {
            setLoadingState(false);
          });
      }
    };
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="sistema/classificacao/alterarintegracaomarketplace"
        ref={CGIModalRef}
        Titulo="Integração Market Place"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
