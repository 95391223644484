import { CGIHttp } from "../../../Services/Axios";
import {
  ListarPesquisaConfigCFOPAutoTypes,
  ListarConfigCFOPAutoGridItensTypes,
  ConfigCFOPAutoPesquisaGridTypes,
  ConfigCFOPAutoVisualizarTypes,
  ConfigCFOPAutoTypes,
} from "../../../Types/Sistema/ConfigCFOPAuto/ConfigCFOPAutoTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpConfigCFOPAuto() {
  const url = "/ConfigCFOPAuto";

  async function RetornarDadosGridPesquisa(
    dados: ConfigCFOPAutoPesquisaGridTypes
  ): Promise<ListarConfigCFOPAutoGridItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-configcfopauto", dados)
      .then((e) => e.data.data);
  }
  async function RetornarConfigCFOPAuto(
    DadosFiltros: ListarPesquisaConfigCFOPAutoTypes
  ): Promise<ListarConfigCFOPAutoGridItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(url + "/obter-configcfop-auto", DadosFiltros)
    ).data.data;
  }
  async function RetornarDadosVisualizarConfigCFOPAuto(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + "/visualizar/" + id)
    ).data.data) as ConfigCFOPAutoVisualizarTypes;
  }
  async function RetornarDadosExclusao(
    DadosEmpresa: ListarConfigCFOPAutoGridItensTypes
  ) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + DadosEmpresa.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir/" + Dados.id);
  }
  async function Alterar(DadosConfigCFOPAuto: ConfigCFOPAutoTypes) {
    return await new CGIHttp().conn.post(url + "/alterar", DadosConfigCFOPAuto);
  }
  async function Incluir(DadosConfigCFOPAuto: ConfigCFOPAutoTypes) {
    return await new CGIHttp().conn.post(url + "/incluir", DadosConfigCFOPAuto);
  }
  return {
    RetornarDadosGridPesquisa,
    RetornarConfigCFOPAuto,
    RetornarDadosExclusao,
    RetornarDadosVisualizarConfigCFOPAuto,
    Excluir,
    Alterar,
    Incluir,
  };
}
