import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../../Components/CheckBox/CGICheckBox";
import { CGIComboSimples } from "../../../../Components/Combos/CGIComboSimples";
import { useCGIToast } from "../../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIModal } from "../../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../../Context/LoadingContext";
import { FormCondicaoPagamentoType } from "../../../../Types/Sistema/Classificacao/CondicaoPagamento/CondicaoPagamentoTypes";
import { HttpCondicaoPagamento } from "./Http";

type AlterarCondicaoPagamentoType = {
  CarregarDados: Function;
};

export const AlterarCondicaoPagamento = forwardRef(
  (props: AlterarCondicaoPagamentoType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current.Abrir();
      },
      Alterar(pEmpresa: any) {
        CGIModalRef.current.Abrir();

        HttpCondicaoPagamento()
          .RetornarDadosCondicaoPagamento(pEmpresa.id)
          .then((e) => {
            form.reset(e.data.data);
          });
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<FormCondicaoPagamentoType>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    //FINAL  HOOKS

    //INICIO CONST

    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Dados Condição de Pagamento",
        Component: AreaDadosCondicaoPagamento(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosCondicaoPagamento() {
      return (
        <CGIBox>
          <CGIBoxItem Col={3}>
            <CGIInput<FormCondicaoPagamentoType>
              id="Form.DadosRegistro.nome"
              label="Nome"
              placeholder="Nome Condição de Pagamento"
              validadores={{
                required: "Nome obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<FormCondicaoPagamentoType>
              id="Form.DadosRegistro.grupo"
              label="Grupo Hist. Caixa"
              placeholder="Grupo para lançamento no histórico de caixa"
              validadores={{
                required: "Grupo Histórico do Caixa obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormCondicaoPagamentoType>
              id="Form.DadosRegistro.definicao"
              label="Definição"
              placeholder="Definição da condição de pagamento"
              validadores={{
                required: "Nome obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGIInput<FormCondicaoPagamentoType>
              id="Form.DadosRegistro.ordem"
              label="Ordem"
              placeholder="Ordem de exibição"
              att={{ type: "number" }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={8}>
            <CGIBox>
              <CGIBoxItem Col={6}>
                <CGIInput<FormCondicaoPagamentoType>
                  id="Form.DadosRegistro.codigoExterno"
                  label="Código Externo"
                  placeholder="Código Externo para integração com outros sistemas"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={6}>
                <CGIComboSimples<FormCondicaoPagamentoType>
                  id="Form.DadosRegistro.tipoDocumentoId"
                  label="Tipo de Documento"
                  Link="tipodocumento"
                  validadores={{
                    required: "Selecione o Tipo de Documento",
                  }}
                />
              </CGIBoxItem>
            </CGIBox>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<FormCondicaoPagamentoType>
                id="Form.DadosRegistro.meioPagamentoId"
                label="Meio de Pagamento (NF)"
                Link="meiodepagamento"
                validadores={{
                  required: "Selecione o Meio de Pagamento",
                }}
              />
            </CGIBoxItem>
          </CGIBoxItem>

          <CGIBoxItem Col={4}>
            <CGIBox>
              <CGIBoxItem Col={12}>
                <strong>DEFINIÇÃO</strong>
                <br />
                <span style={{ color: "green", fontSize: "small" }}>
                  * A VISTA: 0 <br />
                  * 30/60 DIAS: 30/60
                  <br />
                  * 3 X S/ ENTRADA: 30/60/90
                  <br />
                  * 3 X C/ ENTRADA: 0/30/60
                  <br />* APENAS IMPRESSÃO NA OBS, SEM FINANCEIRO: -1 OU *
                </span>
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormCondicaoPagamentoType>
              id="Form.DadosRegistro.formaPagamentoId"
              label="Forma de Pagamento"
              Link="formapagamento"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormCondicaoPagamentoType>
              id="Form.DadosRegistro.planoDeContasCtsReceberId"
              label="Plano de Contas (Cts. Receber)"
              Link="planodecontas-receitas-com-grupo"
              validadores={{
                required: "Selecione o Plano de Contas (Cts. a Receber)",
              }}
            />
          </CGIBoxItem>

          <CGIBoxItem Col={4}>
            <CGIComboSimples<FormCondicaoPagamentoType>
              id="Form.DadosRegistro.empresaId"
              label="Empresa"
              Link="empresa"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormCondicaoPagamentoType>
              id="Form.DadosRegistro.valorMinimo"
              label="Vlr. Mínimo Pedido"
              placeholder="Vlr. Mínimo Pedido"
              att={{ type: "number" }}
            />
          </CGIBoxItem>

          <CGIBoxItem Col={4}>
            <CGIInput<FormCondicaoPagamentoType>
              id="Form.DadosRegistro.valorMinimoMobile"
              label="Vlr. Mínimo Pedido Mobile"
              placeholder="Vlr. Mínimo Pedido Mobile"
              att={{ type: "number" }}
            />
          </CGIBoxItem>

          <CGIBoxItem Col={3}>
            <CGICheckBox<FormCondicaoPagamentoType>
              id="Form.DadosRegistro.exibirEmPedidoMobile"
              label="Exibir em Pedido Mobile"
              tooltip={"Informa se será listada em pedido mobile"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={2}>
            <CGICheckBox<FormCondicaoPagamentoType>
              id="Form.DadosRegistro.ativo"
              label="Ativo"
              tooltip={"Informa se o registro está ativo"}
            />
          </CGIBoxItem>

          <CGIBoxItem Col={12}>
            <CGIBox
              titulo="Dados da Operadora / Administradora de Cartão"
              popover="Estes valores são utilizados para redefinir informações no Contas a Receber"
            >
              <CGIBoxItem Col={12}>
                <CGIComboSimples<FormCondicaoPagamentoType>
                  id="Form.DadosRegistro.clienteId"
                  label="Cliente"
                  Link="cliente"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={8}>
                <CGIComboSimples<FormCondicaoPagamentoType>
                  id="Form.DadosRegistro.operadoraAdministradoraCartaoId"
                  label="Operadora / Administradora"
                  Link="empresa"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIInput<FormCondicaoPagamentoType>
                  id="Form.DadosRegistro.taxa"
                  label="Taxa %"
                  placeholder="Taxa %"
                  att={{ type: "number" }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={6}>
                <CGIComboSimples<FormCondicaoPagamentoType>
                  id="Form.DadosRegistro.portadorId"
                  label="Portador"
                  Link="portador"
                />
              </CGIBoxItem>
              <CGIBoxItem Col={6}>
                <CGIComboSimples<FormCondicaoPagamentoType>
                  id="Form.DadosRegistro.planoDeContasId"
                  label="Plano de Contas"
                  Link="planodecontas-receitas-com-grupo"
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
        </CGIBox>
      );
    }

    const Confirmar = async (data: FormCondicaoPagamentoType, event: any) => {
      setLoadingState(true);
      let Dados = form.getValues();

      if (
        Dados.Form.DadosRegistro.id == null ||
        Dados.Form.DadosRegistro.id === undefined ||
        Dados.Form.DadosRegistro.id === 0
      ) {
        HttpCondicaoPagamento()
          .Incluir(Dados)
          .then((response: any) => {
            ToastSucesso("Registro gravado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return ToastErro(e);
          })
          .finally(() => {
            setLoadingState(false);
          });
      } else {
        HttpCondicaoPagamento()
          .Alterar(data)
          .then((data: any) => {
            ToastSucesso("Registro Alterado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return;
          })
          .finally(() => {
            setLoadingState(false);
          });
      }
    };
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="sistema/classificacao/alterar-condicao-pagamento"
        ref={CGIModalRef}
        Titulo="Condição de Pagamento"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
