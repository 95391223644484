import { CGIHttp } from "../../../../Services/Axios";
import {
  FormNaturezaOperacaoType,
  ListarNaturezaOperacaoItensTypes,
  ListarPesquisaNaturezaOperacaoTypes,
  NaturezaOperacaoTypes,
} from "../../../../Types/Sistema/Classificacao/NaturezaOperacao/NaturezaOperacaoTypes";

const url = "/classificacao";

export function HttpNaturezaOperacao() {
  async function RetornarNaturezasOperacao(
    DadosFiltros: ListarPesquisaNaturezaOperacaoTypes
  ): Promise<ListarNaturezaOperacaoItensTypes[]> {
    return (
      await new CGIHttp().conn.post(
        url + "/obter-naturezas-operacao",
        DadosFiltros
      )
    ).data.data;
  }

  async function RetornarDadosNaturezaOperacao(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + `/alterar-natureza-operacao/${id}`)
    ).data.data) as NaturezaOperacaoTypes;
  }

  async function Incluir(Dados: FormNaturezaOperacaoType) {
    return await new CGIHttp().conn.post(
      url + "/incluir-naturezas-operacao",
      Dados
    );
  }
  async function Alterar(Dados: FormNaturezaOperacaoType) {
    return await new CGIHttp().conn.post(
      url + "/alterar-naturezas-operacao",
      Dados
    );
  }
  async function Visualizar(pId: Number) {
    return await new CGIHttp().conn.get(
      url + "/visualizar-naturezas-operacao/" + pId
    );
  }
  async function RetornarDadosExclusao(pId: Number) {
    return await new CGIHttp().conn.get(
      url + "/excluir-naturezas-operacao/" + pId
    );
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(
      url + "/excluir-naturezas-operacao",
      Dados
    );
  }
  return {
    RetornarNaturezasOperacao,
    RetornarDadosNaturezaOperacao,
    RetornarDadosExclusao,
    Incluir,
    Alterar,
    Visualizar,
    Excluir,
  };
}
