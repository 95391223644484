import { MdPrint, MdTagFaces } from "react-icons/md";
import { useContext, useCallback, useRef } from "react";
import { useForm, useWatch, FormProvider } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import LoadingContext from "../../../Context/LoadingContext";
import {
  LegendaTypes,
  ModalBaseType,
} from "../../../Types/Modal/ModalBaseType";
import {
  ObjPedidoPendenteFaturamentoPadrao,
  PedidoPendenteFaturamentoItensType,
  PedidoPendenteFaturamentoType,
} from "../../../Types/Vendas/Pedido/PedidoPendenteFaturamentoType";
import { HttpPedido } from "./Http";
import { CGIRadioButton } from "../../../Components/RadioButton/CGIRadioButton";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { Center, Flex } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { AlterarNomePedido } from "./_AlterarNomePedido";
import { DefinirTabelaPrecoItensPedido } from "./_DefinirTabelaPreco";
import { DesbloquearFaturamentoPedido } from "./_DesbloquearFaturamentoPedido";
import { DesbloquearPedido } from "./_DesbloquearPedido";
import { FaturarPedido } from "./_FaturarPedido";
import { RecalcularDescontoPedido } from "./_RecalcularDescontoPedido";
import { VisualizarPedido } from "./_VisualizarPedido";

export function PedidoPendenteFaturamento() {
  const { setLoadingState } = useContext(LoadingContext);
  const methods = useForm<PedidoPendenteFaturamentoType>({
    defaultValues: ObjPedidoPendenteFaturamentoPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarPedidoPendenteFaturamento = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpPedido().RetornarPedidoPendenteFaturamento(
        DadosFiltros
      );
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const AlterarNomePedidoRef = useRef<ModalBaseType>(null);
  const DefinirTabelaPrecoRef = useRef<ModalBaseType>(null);
  const DesbloquearFaturamentoRef = useRef<ModalBaseType>(null);
  const DesbloquearPedidoRef = useRef<ModalBaseType>(null);
  const FaturarPedidoRef = useRef<ModalBaseType>(null);
  const RecalcularDescontoPedidoRef = useRef<ModalBaseType>(null);
  const VisualizarPedidoRef = useRef<ModalBaseType>(null);
  const DefinirTabelaPrecoItensRef = useRef<ModalBaseType>(null);

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Faturamento Agrupado",
      Permissao: "faturamento/nfsaida/faturamentoagrupado",
      OnClick: () => {},
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "vendas/pedido/visualizar",
      OnClick: (e: any) => {
        VisualizarPedidoRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Faturar",
      Permissao: "vendas/pedido/faturarpedido",
      OnClick: (e: any) => {
        FaturarPedidoRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Desbloquear",
      Permissao: "vendas/pedido/desbloquear",
      OnClick: (e: any) => {
        DesbloquearPedidoRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Desbloquear Faturamento",
      Permissao: "vendas/pedido/visualizar",
      OnClick: (e: any) => {
        DesbloquearFaturamentoRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Recalcular Desconto",
      Permissao: "vendas/pedido/visualizar",
      OnClick: (e: any) => {
        RecalcularDescontoPedidoRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Alterar Nome",
      Permissao: "vendas/pedido/visualizar",
      OnClick: (e: any) => {
        AlterarNomePedidoRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Definir Tabela de Preço Itens",
      Permissao: "vendas/pedido/visualizar",
      OnClick: (e: any) => {
        DefinirTabelaPrecoItensRef.current?.Abrir(e.id);
      },
    },
  ];

  const Legendas: LegendaTypes = [
    { nomeStatus: "Pedido Bloqueado", cor: "orange" },
    { nomeStatus: "Cliente Bloqueado", cor: "#F5A9A9" },
  ];

  const GridColunas: Array<CGICol<PedidoPendenteFaturamentoItensType>> = [
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      ocultar: true,
    },
    {
      Campo: "id",
      Titulo: "Pedido",
      Tipo: "Composta",
      Composta: [
        {
          titulo: "Pedido",
          valor: "identificacaoPedido",
        },
        {
          titulo: "PedidoCliente",
          valor: "pedidoCliente",
          negrito: true,
        },
        {
          titulo: "Faturmaneto Bloqueado",
          valor: "faturamentoBloqueado",
          negrito: true,
          cor: "red",
          FuncCondicional: (e) =>
            e === true ? "FATURAMENTO BLOQUEADO !!" : "",
        },
      ],
      att: {
        maxWidth: 150,
        wrapText: true,
        cellStyle: (e) => {
          return {
            backgroundColor: e.data.faturamentoBloqueado ? "#FFBC41" : "",
          };
        },
      },
    },
    {
      Campo: "dataCadastro",
      Titulo: "Dt. Ped.",
      Tipo: "Data",
      att: {
        maxWidth: 120,
        cellStyle: { textAlign: "center" },
      },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "HTML",
      att: {
        cellRenderer: (params: any) => {
          return (
            <Center py="2" flexDir={"column"}>
              <MdPrint onClick={() => {}} color="lightBlue" size={20} />
              <MdTagFaces onClick={() => {}} color="lightBlue" size={20} />
            </Center>
          );
        },
        maxWidth: 60,
        cellStyle: { textAlign: "center" },
      },
    },
    {
      Campo: "id",
      Titulo: "Dt. Prev./Per.",
      Tipo: "Composta",
      Composta: [
        {
          titulo: "Data Prevista",
          valor: "dataHoraEnvioFaturamento",
        },
        {
          titulo: "Data Periodo",
          valor: "dataPrevisaoEntrega",
        },
      ],
      att: {
        maxWidth: 120,
      },
    },
    {
      Campo: "id",
      Titulo: "Colaborador / Transportadora",
      Tipo: "Composta",
      Composta: [
        {
          titulo: "NomeColaborador",
          valor: "nomeColaborador",
        },
        {
          titulo: "nomeFantasia",
          valor: "nomeFantasia",
          negrito: true,
        },
        {
          titulo: "CPFCNPJ",
          valor: "cpfcnpjColaborador",
          negrito: true,
        },
        {
          titulo: "NomeTransportadora",
          valor: "nomeTransportadora",
          negrito: true,
        },
        {
          titulo: "NomeCidade",
          valor: "nomeCidadeColaborador",
          negrito: true,
          cor: "blue",
        },
        {
          titulo: "qtdAnexos",
          valor: "pedidoTemAnexos",
          negrito: true,
          cor: "green",
          FuncCondicional: (e) =>
            e === true ? "(!) Pedido contém anexos" : "",
        },
        {
          titulo: "Obs. Pedido:",
          valor: "obsPedido",
          negrito: true,
          exibirLabel: true,
        },
      ],
      att: {
        wrapText: true,
        cellStyle: (e) => {
          return {
            backgroundColor: e.data.pedidoBloqueado ? "#F5AAAA" : "",
          };
        },
      },
    },
    {
      Campo: "id",
      Titulo: "Nat. Op./Cond. Pag.",
      Tipo: "Composta",
      Composta: [
        {
          titulo: "NaturezaOperacao",
          valor: "nomeNaturezaOperacao",
        },
        {
          titulo: "CondicaoPagamento",
          valor: "nomeCondicaoPagamento",
          negrito: true,
        },
      ],
      att: {
        maxWidth: 150,
      },
    },
    {
      Campo: "id",
      Titulo: "Vend / Usuário / Hora Faturamento ",
      Tipo: "Composta",
      Composta: [
        {
          titulo: "NomeVendedor",
          valor: "nomeVendedor",
        },
        {
          titulo: "nomeUsuario",
          valor: "nomeUsuario",
          negrito: true,
        },
        {
          titulo: "HoraFaturamento",
          valor: "dataHoraEnvioFaturamento",
          negrito: true,
        },
      ],
      att: {
        maxWidth: 150,
        wrapText: true,
      },
    },

    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: PedidoPendenteFaturamentoItensType) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "totalPedido",
      Titulo: "Total Pedido",
      Tipo: "Moeda",
      att: {
        maxWidth: 120,
      },
    },
  ];
  const Janelas: Array<{ Componente: React.ReactNode }> = [
    {
      Componente: (
        <AlterarNomePedido
          CarregarDados={() => {}}
          ref={AlterarNomePedidoRef}
        />
      ),
    },
    {
      Componente: (
        <DefinirTabelaPrecoItensPedido
          CarregarDados={() => {}}
          ref={DefinirTabelaPrecoRef}
        />
      ),
    },
    {
      Componente: (
        <DesbloquearFaturamentoPedido
          CarregarDados={() => {}}
          ref={DesbloquearFaturamentoRef}
        />
      ),
    },
    {
      Componente: (
        <DesbloquearPedido
          CarregarDados={() => {}}
          ref={DesbloquearPedidoRef}
        />
      ),
    },
    {
      Componente: (
        <FaturarPedido CarregarDados={() => {}} ref={FaturarPedidoRef} />
      ),
    },
    {
      Componente: (
        <RecalcularDescontoPedido
          CarregarDados={() => {}}
          ref={RecalcularDescontoPedidoRef}
        />
      ),
    },
    {
      Componente: (
        <DefinirTabelaPrecoItensPedido
          CarregarDados={() => {}}
          ref={DefinirTabelaPrecoItensRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarPedido CarregarDados={() => {}} ref={VisualizarPedidoRef} />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Filtros de Período" popover="Filtros de Período">
            <CGIBoxItem Col={12}>
              <CGIRadioButton<PedidoPendenteFaturamentoType>
                id="Pesquisa.TipoData"
                label="Filtrar por"
                row={"row"}
                values={[
                  {
                    label: "Data do Pedido",
                    valor: "dataPedido",
                  },
                  {
                    label: "Data Prev. Faturamento",
                    valor: "dataPrevFaturamento",
                  },
                ]}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<PedidoPendenteFaturamentoType>
                id={"Pesquisa.dataInclusaoInicial"}
                label={"Data Inicial"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<PedidoPendenteFaturamentoType>
                id={"Pesquisa.dataInclusaoFinal"}
                label={"Data Final"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBox>
            <CGIBoxItem Col={6}>
              <CGIComboSimples<PedidoPendenteFaturamentoType>
                id="Pesquisa.listaEmpresasId"
                label="Empresa"
                Link="empresa"
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIComboSimples<PedidoPendenteFaturamentoType>
                id="Pesquisa.listaVendedoresId"
                label="Vendedor"
                Link="vendedor"
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIComboAjax<PedidoPendenteFaturamentoType>
                id="Pesquisa.listaClientesId"
                label="Cliente"
                Link="cliente"
                Multipla
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIComboSimples<PedidoPendenteFaturamentoType>
            id="Pesquisa.listaTransportadorasId"
            label="Transportadora"
            Link="transportadora"
            Multipla
          />
        </CGIBoxItem>
        <CGIBoxItem
          Att={{
            mt: 6,
          }}
          Col={6}
        >
          <CGICheckBox<PedidoPendenteFaturamentoType>
            id={"Pesquisa.somentePedidoBloqueados"}
            label={"Somente Pedidos Bloqueados "}
            tooltip={"Somente Pedidos Bloqueados"}
          />
          <CGICheckBox<PedidoPendenteFaturamentoType>
            id={"Pesquisa.somentePedidoPendenteFaturamento"}
            label={"Somente Pedidos Pendentes de Faturamento"}
            tooltip={"Somente Pedidos Pendentes de Faturamento"}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="vendas/pedido/pedidospendentesfaturamento"
      Titulo="Pedidos Pendentes Faturamento"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarPedidoPendenteFaturamento)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
