import { CGIHttp } from "../../../Services/Axios";
import {
  UsuariosEmpresaTypes,
  EmpresaTypes,
  ListarEmpresaItensTypes,
  ListarPesquisaEmpresaTypes,
  FormEmpresaType,
} from "../../../Types/Sistema/Empresa/EmpresaTypes";

import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpEmpresa() {
  const url = "/empresa";

  async function RetornarEmpresas(DadosFiltros: ListarPesquisaEmpresaTypes) {
    return (await (
      await new CGIHttp().conn.post(url + "/obter-empresas", DadosFiltros)
    ).data.data) as ListarEmpresaItensTypes[];
  }

  async function RetornarUsuariosDaEmpresa(EmpresaId: number) {
    return (await (
      await new CGIHttp().conn.get(url + `/obter-usuarios/${EmpresaId}`)
    ).data.data) as Array<UsuariosEmpresaTypes>;
  }

  async function RetornarDadosEmpresa(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + `/alterar/${id}`)
    ).data.data) as EmpresaTypes;
  }

  async function Incluir(DadosEmpresa: FormEmpresaType) {
    return await new CGIHttp().conn.post(url + "/incluir", DadosEmpresa);
  }

  async function Alterar(DadosEmpresa: FormEmpresaType) {
    return await new CGIHttp().conn.post(url + "/alterar", DadosEmpresa);
  }
  async function Visualizar(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + "/visualizar/" + id)
    ).data.data) as EmpresaTypes;
  }
  async function RetornarDadosExclusao(DadosEmpresa: ListarEmpresaItensTypes) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + DadosEmpresa.id)
      .then(res => res.data.data)) as DeleteTypes;
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir", Dados);
  }

  async function IncluirGET() {
    return (await (
      await new CGIHttp().conn.get(url + "/incluir")
    ).data.data) as EmpresaTypes;
  }

  return {
    RetornarEmpresas,
    RetornarDadosEmpresa,
    RetornarUsuariosDaEmpresa,
    RetornarDadosExclusao,
    Incluir,
    Alterar,
    Visualizar,
    Excluir,
    IncluirGET,
  };
}
