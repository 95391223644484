/* eslint-disable react-hooks/exhaustive-deps */
import { GridReadyEvent } from "ag-grid-community";
import { useState, useEffect } from "react";
import { UseFormReturn, FieldValues, useFormContext } from "react-hook-form";
import { CGIBox } from "../../Box/CGIBox";
import { CGIBoxItem } from "../../Box/CGIBoxItens";
import { CGIDataTableSimples } from "../../DataTable/CGIDataTableSimples";
import { CGICol, ConvertCol } from "../../DataTable/DataTable/DataTableTypes";
import { CGIInput } from "../../Input/CGIInput";
import { HttpCGIResumosComposto } from "../HttpResumo";

function CGIResumoUltimasCompras(
  watchProdutoEstoqueEmpresasId: string,
  formPrincipal: UseFormReturn<FieldValues, any>,
  formResumo: UseFormReturn<FieldValues, any>
) {
  const form = useFormContext();

  const Tipo = "UltimasCompras";

  const Colunas: Array<CGICol<any>> = [
    {
      Campo: "Id",
      Titulo: "Id",
      Tipo: "Texto",
      ocultar: true,
    },
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Texto",
      ocultar: true,
    },
    {
      Campo: "identificacao",
      Titulo: "Pedido",
      Tipo: "Texto",
      ocultar: true,
    },
    {
      Campo: "dataCadastro",
      Titulo: "Data Cadastrado",
      Tipo: "Texto",
      ocultar: true,
    },
    {
      Campo: "codigoProduto",
      Titulo: "Data Cadastrado",
      Tipo: "Texto",
      ocultar: true,
    },
    {
      Campo: "descricao",
      Titulo: "Descrição",
      Tipo: "Texto",
      ocultar: true,
    },
    {
      Campo: "nomeCondicaoPagamento",
      Titulo: "Cod. Pag.",
      Tipo: "Texto",
      ocultar: true,
    },
    {
      Campo: "qtdItens",
      Titulo: "Qtd de Itens",
      Tipo: "Texto",
      ocultar: true,
    },
    {
      Campo: "vlrUnitario",
      Titulo: "Vlr. Unitário",
      Tipo: "Texto",
      ocultar: true,
    },
    {
      Campo: "vlrTotal",
      Titulo: "Vlr. Total",
      Tipo: "Texto",
      ocultar: true,
    },
  ];
  const [valueOld, setValueOld] = useState();
  const [dados, setDados] = useState<any>([]);

  useEffect(() => {
    var value =
      formPrincipal.getValues(watchProdutoEstoqueEmpresasId) ||
      formResumo.getValues(watchProdutoEstoqueEmpresasId);

    if (value == null || value === undefined || value === 0 || value === "") {
      return;
    } else if (value === 0) {
      return setDados([]);
    }
    if (valueOld === value) {
      return;
    } else {
      setValueOld(value);
    }
    RetornarDados(value);
  }, [
    formPrincipal.watch(watchProdutoEstoqueEmpresasId),
    formResumo.watch(watchProdutoEstoqueEmpresasId),
  ]);
  const [ApiGrid, setGridApi] = useState<GridReadyEvent<any>>();

  async function RetornarDados(id: any) {
    try {
      var Dados = await HttpCGIResumosComposto().RetornarDados(id, Tipo);

      //ApiGrid?.api.hideOverlay();
      setDados(Dados.registros);

      // ApiGrid?.api.applyTransaction({
      //   add: Dados.registros,
      // });
    } catch (error) {}
  }

  return (
    <CGIBox>
      <CGIBoxItem Col={12}>
        <CGIInput ocultar id={watchProdutoEstoqueEmpresasId} placeholder="" />
        <CGIDataTableSimples
          Altura="60vh"
          Colunas={Colunas}
          Data={dados}
          DisableGetId
          Att={{
            onGridReady: e => setGridApi(e),
            defaultColDef: {
              flex: 1,
              filter: false,
              autoHeight: false,

              cellStyle: {
                "font-size": 10,
              },
            },
          }}
        ></CGIDataTableSimples>
      </CGIBoxItem>
    </CGIBox>
  );
}

export const ResumoProdutoEstoqueEmpresas = {
  id: "ResumoProdutoEstoqueEmpresas",
  titulo: "Estoque nas Empresas",
  componente: CGIResumoUltimasCompras,
};
