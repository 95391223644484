import { AddIcon } from "@chakra-ui/icons";
import {
  useRef,
  useContext,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarAlocacaoItensTypes,
  ListarAlocacaoTypes,
  ObjListarAlocacaoPadrao,
  ListarAlocacaoItensEmpresasTypes,
} from "../../../Types/Almoxarifado/Alocacao/AlocacaoTypes";
import {
  ModalBaseType,
  LegendaTypes,
} from "../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpAlocacao } from "./Http";
import { AlterarAlocacao } from "./_AlterarAlocacao";
import { VisualizarAlocacao } from "./_VisualizarAlocacao";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { HttpProduto } from "../../Sistema/Produto/Http";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";

export function ListarAlocacao() {
  const AlterarAlocacaoRef = useRef<ModalBaseType>();
  const VisualizarAlocacaoRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);
  const [ExibirResumoProduto, setExibirProduto] = useState(false);
  const [ExibirGridEmpresas, setExibirGridEmpresa] = useState(false);

  const methods = useForm<ListarAlocacaoTypes>({
    defaultValues: ObjListarAlocacaoPadrao,
  });

  const setProduto = useWatch({
    control: methods.control,
    name: "Pesquisa.produtoId",
  });

  const setGrid = useWatch({
    control: methods.control,
    name: "exibirGridEmpresa",
  });

  const GridItensEmpresa = useWatch({
    control: methods.control,
    name: "ItensEmpresa",
  });

  const ResumoProduto = useMemo(() => {
    if (setProduto === undefined || setProduto === null) {
      return;
    }
    if (setProduto) {
      setExibirProduto(true);
    } else {
      setExibirProduto(false);
    }
  }, [setProduto]);

  const GridEmpresas = useMemo(() => {
    if (setGrid === undefined || setGrid === null) {
      return;
    }
    if (setGrid) {
      return setExibirGridEmpresa(true);
    } else {
      return setExibirGridEmpresa(false);
    }
  }, [setGrid]);

  function Limpar() {
    methods.setValue("exibirGridEmpresa", false);
    methods.setValue("Pesquisa.produtoId", 0);
  }

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarAlocacoes = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpAlocacao().RetornarAlocacoes(DadosFiltros);
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const ObterQtdGridEmpresas = useCallback(async () => {
    try {
      setLoadingState(true);
      const pId = methods.getValues("Pesquisa.produtoId");
      var data =
        await HttpProduto().RetornarQtdEstoqueEmpresasAtivasParaAlocacao(pId);
      methods.setValue("ItensEmpresa", data);
      methods.setValue("exibirGridEmpresa", true);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarAlocacaoItensTypes) => {
      return await HttpAlocacao().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpAlocacao()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Alterar Pedido",
      Permissao: "sistema/Alocacao/visualizar",
      OnClick: (e: any) => {
        VisualizarAlocacaoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Alterar Alocação",
      Permissao: "sistema/Alocacao/alterar",
      OnClick: (e: ListarAlocacaoItensTypes) => {
        AlterarAlocacaoRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Lançar Histórico Colaborador",
      Permissao: "sistema/Alocacao/excluir",
      OnClick: (e: ListarAlocacaoItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas: LegendaTypes = [];

  const GridColunas: Array<CGICol<ListarAlocacaoItensTypes>> = [
    {
      Campo: "dataCadastro",
      Titulo: "Data",
      Tipo: "Data",
      att: { maxWidth: 100 },
    },
    {
      Campo: "identificacao",
      Titulo: "Pedido",
      Tipo: "Texto",
      att: { maxWidth: 150 },
    },
    {
      Campo: "pedidoId",
      Titulo: "Cliente Vendedor",
      Tipo: "Composta",
      Composta: [
        {
          titulo: "Cliente",
          valor: "nomeCliente",
          exibirLabel: true,
          negrito: true,
        },
        {
          titulo: "Vendedor",
          valor: "nomeVendedor",
          exibirLabel: true,
          negrito: true,
        },
      ],
    },
    {
      Campo: "ordemAlocacaoPedido",
      Titulo: "Ord. Alocação",
      Tipo: "Texto",
      att: { maxWidth: 140 },
    },
    {
      Campo: "lancManual",
      Titulo: "Lanç. Manual",
      Tipo: "Texto",
      att: { maxWidth: 140 },
    },
    {
      Campo: "multiplo",
      Titulo: "Múltiplo",
      Tipo: "Texto",
      att: { maxWidth: 130 },
    },
    {
      Campo: "qtdPedido",
      Titulo: "Qtd. Pedido",
      Tipo: "Numero",
      att: { maxWidth: 130 },
    },
    {
      Campo: "qtdAlocada",
      Titulo: "Qtd. Alocada",
      Tipo: "Numero",
      att: { maxWidth: 140 },
    },
    {
      Campo: "valorTotalItem",
      Titulo: "Vlr. Item",
      Tipo: "Numero",
      att: { maxWidth: 130 },
    },
    {
      Campo: "valorTotalAlocado",
      Titulo: "Vlr. Total Aloc.",
      Tipo: "Numero",
      att: { maxWidth: 140 },
    },
    {
      Campo: "pedidoId",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarAlocacaoItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];

  const GridEstoqueEmpresas: Array<CGICol<ListarAlocacaoItensEmpresasTypes>> = [
    {
      Campo: "nomeEmpresa",
      Titulo: "Empresa",
      Tipo: "Texto",
    },
    {
      Campo: "qtdEstoqueEmpresa",
      Titulo: "Quantidade",
      Tipo: "Numero",
      att: {
        maxWidth: 160,
      },
    },
  ];

  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Alocacao"
          CarregarDados={() => {}}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarAlocacao
          CarregarDados={methods.handleSubmit(() => {})}
          ref={AlterarAlocacaoRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarAlocacao
          CarregarDados={methods.handleSubmit(() => {})}
          ref={VisualizarAlocacaoRef}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarAlocacaoTypes>
            id="Pesquisa.listaEmpresas"
            label="Empresa"
            Link={"empresa"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboAjax<ListarAlocacaoTypes>
            id="Pesquisa.produtoId"
            label="Produto"
            Link={"produto"}
            validadores={{ required: "Campo obrigatório" }}
          />
        </CGIBoxItem>
        <>{ResumoProduto}</>
        <>{GridEmpresas}</>
        <CGIBoxItem hidden={!ExibirResumoProduto} Col={12}>
          <CGIResumo
            Expandir
            ResumoProdutoSimples
            ProdutoId="Pesquisa.produtoId"
          />
        </CGIBoxItem>
        <CGIBoxItem hidden={!ExibirResumoProduto} Col={2}>
          <CGIButton
            id="ButtonGrid"
            onClick={() => ObterQtdGridEmpresas()}
            label="Exibir Grid Empresas"
            att={{
              mt: 8,
              marginLeft: 1265,
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem hidden={!ExibirGridEmpresas} Col={12}>
          <CGIDataTableSimples
            Altura="50vh"
            Colunas={GridEstoqueEmpresas}
            Data={GridItensEmpresa}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="almoxarifado/alocacao/listar"
      Titulo="Alocacao"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={Limpar}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarAlocacoes)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
