import { CGIHttp } from "../../../Services/Axios";
import {
  BloquearClientesEmAtrasoItensPesquisaType,
  BloquearClientesEmAtrasoPesquisaType,
} from "../../../Types/Financeiro/ContasReceber/BloquearClientesEmAtrasoTypes";
import { ClientesBloqueadosPesquisaTypes } from "../../../Types/Financeiro/ContasReceber/ClientesBloqueadosTypes";
import {
  ListarPesquisaCtsReceberTypes,
  ListarCtsReceberItensTypes,
  CtsReceberPesquisaGridTypes,
  CtsReceberVisualizarTypes,
} from "../../../Types/Financeiro/ContasReceber/CtsReceberTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpCtsReceber() {
  const url = "/ctsreceber";

  async function RetornarCtsReceber(
    DadosFiltros: ListarPesquisaCtsReceberTypes
  ) {
    return (await (
      await new CGIHttp().conn.post(url + "/obter-ctsreceber", DadosFiltros)
    ).data.data) as ListarCtsReceberItensTypes[];
  }

  async function RetornarDadosGridPesquisa(
    dados: CtsReceberPesquisaGridTypes
  ): Promise<ListarCtsReceberItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-ctsreceber", dados)
      .then((e) => e.data.data);
  }

  async function RetornarDadosVisualizarCtsReceber(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + "/visualizar/" + id)
    ).data.data) as CtsReceberVisualizarTypes;
  }

  async function RetornarDadosExclusao(
    DadosEmpresa: ListarCtsReceberItensTypes
  ) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + DadosEmpresa.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }

  async function RetornarClientesEmAtraso() {
    const Data = await new CGIHttp().conn.get(
      url + "/bloqueio-clientes-atraso/"
    );
    return Data.data.data;
  }

  async function RetornarClientesEmAtrasoEspecifico(
    dados: BloquearClientesEmAtrasoItensPesquisaType
  ): Promise<BloquearClientesEmAtrasoItensPesquisaType[]> {
    return await new CGIHttp().conn
      .get(url + `/obter-clientes-atraso-detalhamento/${dados.clienteId}`)
      .then((e) => e.data.data);
  }

  async function Alterar() {
    return await (
      await new CGIHttp().conn.get(url + "/alterar")
    ).data.data;
  }

  async function Incluir() {
    return await (
      await new CGIHttp().conn.get(url + "/incluir")
    ).data.data;
  }

  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir", Dados);
  }

  async function AlterarComissao() {
    return await (
      await new CGIHttp().conn.get(url + "/alterarcomissao")
    ).data.data;
  }

  async function GerarBoletoBancario() {
    return await (
      await new CGIHttp().conn.get(url + "/gerarboletobancario")
    ).data.data;
  }

  async function RetornarClientesBloqueados(
    Dados: ClientesBloqueadosPesquisaTypes
  ) {
    return await (
      await new CGIHttp().conn.post(
        "colaborador/obter-clientes-bloqueados",
        Dados
      )
    ).data.data;
  }

  async function BloquearClientesEmAtraso(Dados: any) {
    return await new CGIHttp().conn.post(url + "/bloqueio-clientes-atraso/", {
      motivo: null,
      itens: [],
      itensValidos: [],
    });
  }

  return {
    RetornarClientesEmAtrasoEspecifico,
    RetornarDadosVisualizarCtsReceber,
    RetornarClientesBloqueados,
    RetornarDadosGridPesquisa,
    RetornarClientesEmAtraso,
    BloquearClientesEmAtraso,
    RetornarDadosExclusao,
    GerarBoletoBancario,
    RetornarCtsReceber,
    AlterarComissao,
    Incluir,
    Alterar,
    Excluir,
  };
}
