import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ArquivoRetornoTypes,
  VisualizarArquivoRetornoTypes,
} from "../../../Types/Financeiro/ArquivoRetorno/ArquivoRetornoTypes";
import { HttpArquivoRetorno } from "./Http";

type VisualizarArquivoRetornoType = {
  CarregarDados: Function;
};

export const VisualizarArquivoRetorno = forwardRef(
  (props: VisualizarArquivoRetornoType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir(pArquivoRetornoId: number) {
        CGIModalRef.current?.Abrir();
        CarregarDados(pArquivoRetornoId);
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<ArquivoRetornoTypes>();
    const { reset } = useFormContext<ArquivoRetornoTypes>();

    async function CarregarDados(id: number) {
      var data = await HttpArquivoRetorno().Visualizar(id);
      reset(data);
    }

    //FINAL  HOOKS

    //INICIO CONST
    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Dados Arquivo Retorno",
        Component: AreaDadosArquivoRetorno(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosArquivoRetorno() {
      return (
        <CGIBox>
          <CGIBoxItem Col={6}>
            <CGIBoxItem Col={12}>
              <CGIInput<VisualizarArquivoRetornoTypes>
                id="id"
                label="id"
                placeholder=""
                att={{
                  isReadOnly: true,
                }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={8}>
              <CGIInput<VisualizarArquivoRetornoTypes>
                id="fileName"
                label="Nome do Arquivo"
                placeholder=""
                att={{
                  isReadOnly: true,
                }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIInput<VisualizarArquivoRetornoTypes>
                id="dataCadastro"
                label="DataInclusao"
                placeholder=""
                att={{
                  isReadOnly: true,
                }}
              />
            </CGIBoxItem>
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIBoxItem Col={12}>
              <CGIInput<VisualizarArquivoRetornoTypes>
                id="nomeConvenio"
                label="Convênio"
                placeholder=""
                att={{
                  isReadOnly: true,
                }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={8}>
              <CGIInput<VisualizarArquivoRetornoTypes>
                id="nomePortador"
                label="Portador"
                placeholder=""
                att={{
                  isReadOnly: true,
                }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIInput<VisualizarArquivoRetornoTypes>
                id="nomeStatus"
                label="Status"
                placeholder=""
                att={{
                  isReadOnly: true,
                }}
              />
            </CGIBoxItem>
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIBox titulo="Títulos">
              <CGIBoxItem Col={6}>
                <CGIInput<VisualizarArquivoRetornoTypes>
                  id="nomeStatus"
                  label=""
                  placeholder=""
                  att={{
                    isReadOnly: true,
                  }}
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
        </CGIBox>
      );
    }

    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="financeiro/arquivoretorno/visualizar"
        ref={CGIModalRef}
        Titulo="Visualizar Arquivo Retorno"
        TelaCheia={"7xl"}
        CarregarDados={CarregarDados}
        Confirmar={() => {}}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
