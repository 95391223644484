import { AxiosInstance } from "axios";
import { CGIHttp } from "../../Services/Axios";
import {
  AlterarPermissoesTelaTypes,
  AlterarPermissoesTelaUSuariosTypes,
} from "./PermissaoTypes";

const url = "/permissao";

export function HttpPermissao() {
  async function RetornarPermissoesTela(link: string) {
    var data = await new CGIHttp().conn.post(
      url + "/visualizar-permissao-tela",
      {
        link: link,
      }
    );

    return data?.data.data as AlterarPermissoesTelaTypes;
  }
  async function RetornarPermissoesTelaUsuarios(
    link: string,
    empresaId: number
  ) {
    var data = await new CGIHttp().conn.post(
      url + "/obter-usuarios-permissao-tela",
      {
        link: link,
        empresaId: empresaId,
      }
    );

    return data?.data.data as Array<AlterarPermissoesTelaUSuariosTypes>;
  }
  async function AlterarPermissoesTela(Dados: AlterarPermissoesTelaTypes) {
    var data = await new CGIHttp().conn.post(url + "/permissao-tela", Dados);
    return data?.data.data as Array<AlterarPermissoesTelaUSuariosTypes>;
  }
  return {
    RetornarPermissoesTela,
    RetornarPermissoesTelaUsuarios,
    AlterarPermissoesTela,
  };
}
