import { CGIHttp } from "../../../../Services/Axios";
import {
  FormMarketPlaceType,
  ListarMarketPlaceItensTypes,
  ListarPesquisaMarketPlaceTypes,
} from "../../../../Types/Sistema/Classificacao/MarketPlace/MarketPlaceTypes";

const url = "/classificacao";

export function HttpMarketPlace() {
  async function RetornarMarketPlaces(
    DadosFiltros: ListarPesquisaMarketPlaceTypes
  ) {
    return (
      await new CGIHttp().conn.post(
        url + "/obter-marketplaces-homologados",
        DadosFiltros
      )
    ).data.data;
  }

  async function Listar() {
    return await (
      await new CGIHttp().conn.get(url + "/listar-168")
    ).data.data;
  }

  async function RetornarDadosMarketPlace(id: number) {
    return await new CGIHttp().conn.get(
      url + `/alterar-marketplace-homologado/${id}`
    );
  }

  async function Incluir(Dados: FormMarketPlaceType) {
    return await new CGIHttp().conn.post(
      url + "/incluir-marketplace-homologado",
      Dados.Form
    );
  }
  async function Alterar(Dados: FormMarketPlaceType) {
    return await new CGIHttp().conn.post(
      url + "/alterar-marketplace-homologado",
      Dados.Form
    );
  }
  async function RetornarDadosExclusao(Dados: ListarMarketPlaceItensTypes) {
    return await new CGIHttp().conn.get(
      url + "/excluir-marketplace-homologado/" + Dados.id
    );
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(
      url + "/excluir-marketplace-homologado",
      Dados
    );
  }

  return {
    RetornarMarketPlaces,
    RetornarDadosMarketPlace,
    RetornarDadosExclusao,
    Incluir,
    Listar,
    Alterar,
    Excluir,
  };
}
