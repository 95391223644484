import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIDataTable } from "../../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../../Context/LoadingContext";
import { ModalBaseType } from "../../../../Types/Modal/ModalBaseType";
import {
  ListarIntegracaoMarketPlaceItensTypes,
  ListarIntegracaoMarketPlaceTypes,
  ObjListarIntegracaoMarketPlacePadrao,
} from "../../../../Types/Sistema/Classificacao/IntegracaoMarketPlace/IntegracaoMarketPlaceTypes";
import { DeleteTypes } from "../../../../Types/Util/DeleteTypes";

import { HttpIntegracaoMarketPlace } from "./Http";
import { AlterarIntegracaoMarketPlace } from "./_AlterarIntegracaoMarketPlace";

export function ListarIntegracaoMarketPlace() {
  //Inicio Hooks
  const AlterarIntegracaoMarketPlaceRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarIntegracaoMarketPlaceTypes>({
    defaultValues: ObjListarIntegracaoMarketPlacePadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  async function Excluir(data: DeleteTypes) {
    await HttpIntegracaoMarketPlace()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }
  //Final Hooks

  //------------------------

  //Inicio Consts
  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Nova Integração MarketPlace",
      Permissao: "sistema/classificacao/incluirintegracaomarketplace",
      OnClick: () => {
        AlterarIntegracaoMarketPlaceRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Alterar",
      Permissao: "sistema/classificacao/alterarintegracaomarketplace",
      OnClick: (e: ListarIntegracaoMarketPlaceItensTypes) => {
        AlterarIntegracaoMarketPlaceRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/classificacao/excluirintegracaomarketplace",
      OnClick: (e: ListarIntegracaoMarketPlaceItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarIntegracaoMarketPlaceItensTypes>> = [
    {
      Campo: "ativo",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: [
        {
          nomeStatus: "true",
          cor: "green",
        },
        {
          nomeStatus: "false",
          cor: "red",
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      att: {
        maxWidth: 100,
      },
    },
    {
      Campo: "nome",
      Titulo: "Nome",
      Tipo: "Texto",
    },
    {
      Campo: "ativo",
      Titulo: "Ativo",
      Tipo: "CheckBox",
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarIntegracaoMarketPlaceItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Integração Market Place"
          CarregarDados={methods.handleSubmit(RetornarIntegracaoMarketPlaces)}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarIntegracaoMarketPlace
          CarregarDados={methods.handleSubmit(RetornarIntegracaoMarketPlaces)}
          ref={AlterarIntegracaoMarketPlaceRef}
        />
      ),
    },
  ];

  //Final Consts

  //------------------------

  //Inicio Function

  async function RetornarIntegracaoMarketPlaces() {
    setLoadingState(true);
    const DadosFiltro = methods.getValues("Pesquisa");
    var data = await HttpIntegracaoMarketPlace()
      .RetornarIntegracaoMarketPlaces(DadosFiltro)
      .finally(() => {
        setLoadingState(false);
      });
    methods.setValue("Itens", data);
  }

  async function RetornarDadosExclusao(
    data: ListarIntegracaoMarketPlaceItensTypes
  ) {
    return await HttpIntegracaoMarketPlace().RetornarDadosExclusao(data);
  }

  //Final Function

  //------------------------

  // Inicio JSX

  function Filtros() {
    return <CGIBox children={undefined}></CGIBox>;
  }

  return (
    <>
      <CGIListar
        id="sistema/classificacao/listar"
        Titulo="Listar Integração Market Place"
        Janelas={Janelas}
      >
        <FormProvider {...methods}>
          <CGIDataTable
            LimparFiltros={() => methods.reset()}
            Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
            Pesquisar={methods.handleSubmit(RetornarIntegracaoMarketPlaces)}
            Filtro={Filtros()}
            Data={GridItens}
            Colunas={GridColunas}
            Legenda={Legendas}
          />
        </FormProvider>
      </CGIListar>
    </>
  );
  // Final JSX
}
