export const ObjListarNFEntreguesPadrao: ListarNFEntreguesTypes = {
  Pesquisa: {
    dataSaidaInicial: "",
    dataSaidaFinal: "",
    dataEntregaInicial: "",
    dataEntregaFinal: "",
    identificacaoPedido: "",
    pedidoCliente: "",
    ListaClientesId: "",
    ListaMotoristasId: "",
    notaFiscal: "",
    ListaVeiculosId: "",
  },
  Itens: [],
};

export type ListarNFEntreguesTypes = {
  Pesquisa: ListarPesquisaNFEntreguesTypes;
  Itens: ListarNFEntreguesItensTypes[];
};

export type ListarPesquisaNFEntreguesTypes = {
  dataSaidaInicial: string;
  dataSaidaFinal: string;
  dataEntregaInicial: string;
  dataEntregaFinal: string;
  identificacaoPedido: string;
  pedidoCliente: string;
  ListaClientesId: string;
  ListaMotoristasId: string;
  notaFiscal: string;
  ListaVeiculosId: string;
};

export type ListarNFEntreguesItensTypes = {
  dataSaidaInicial: string;
  dataSaidaFinal: string;
  dataEntregaInicial: string;
  dataEntregaFinal: string;
  identificacaoPedido: string;
  pedidoCliente: string;
  nomeCliente: string;
  nomeMotorista: string;
  notaFiscal: string;
  nomeEquipamento: string;
  valorNF: string;
  dataEntrega: string;
  nomeStatus: string;
  id: number;
};
