export const ObjIntegracaoMarketPlace: FormIntegracaoMarketPlaceType = {
  Form: {
    DadosRegistro: {
      id: 0,
      nome: "",
      ativo: true,
      dadosAuxiliares: "",
      valorMinimoProduto: "",
      percentualComissaoIntegracao: "",
      token: "",
    },
  },
};

export type FormIntegracaoMarketPlaceType = {
  Form: {
    DadosRegistro: IntegracaoMarketPlaceTypes;
  };
};

type IntegracaoMarketPlaceTypes = {
  id: number;
  nome: string;
  token: string;
  dadosAuxiliares: string;
  valorMinimoProduto: string;
  percentualComissaoIntegracao: string;
  ativo: boolean;
};

export const ObjListarIntegracaoMarketPlacePadrao: ListarIntegracaoMarketPlaceTypes =
  {
    Pesquisa: {
      titulo: "",
      nome: "",
      ativoId: 0,
    },
    Itens: [],
  };

export type ListarIntegracaoMarketPlaceTypes = {
  Pesquisa: ListarPesquisaIntegracaoMarketPlaceTypes;
  Itens: ListarIntegracaoMarketPlaceItensTypes[];
};

export type ListarPesquisaIntegracaoMarketPlaceTypes = {
  titulo: string;
  nome: string;
  ativoId: number;
};

export type ListarIntegracaoMarketPlaceItensTypes = {
  id: number;
  nome: string;
  ativo: boolean;
};
