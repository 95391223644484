import { CGIHttp } from "../../../Services/Axios";
import {
  FormArquivoRetornoType,
  ListarPesquisaArquivoRetornoTypes,
  ListarArquivoRetornoItensTypes,
  ArquivoRetornoPesquisaGridTypes,
  ArquivoRetornoTypes,
} from "../../../Types/Financeiro/ArquivoRetorno/ArquivoRetornoTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpArquivoRetorno() {
  const url = "/arquivoretorno";

  async function RetornarDadosGridPesquisa(
    dados: ArquivoRetornoPesquisaGridTypes
  ): Promise<ListarArquivoRetornoItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-arquivo-retorno", dados)
      .then((e) => e.data.data);
  }

  async function RetornarArquivosRetorno(
    DadosFiltros: ListarPesquisaArquivoRetornoTypes
  ): Promise<ListarArquivoRetornoItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(
        url + "/obter-arquivo-retorno",
        DadosFiltros
      )
    ).data.data;
  }

  async function RetornarDadosArquivoRetorno(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + `/alterar/${id}`)
    ).data.data) as ArquivoRetornoTypes;
  }

  async function RetornarDadosExclusao(Dados: ListarArquivoRetornoItensTypes) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + Dados.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }
  async function RetornarDadosVisualizarArquivoRetorno(
    DadosArquivoRetorno: FormArquivoRetornoType
  ) {
    return (await (
      await new CGIHttp().conn.get(
        url + "/visualizar/" + DadosArquivoRetorno.Form
      )
    ).data.data) as ArquivoRetornoTypes;
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir/" + Dados.id);
  }

  async function Visualizar(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + "/visualizar/" + id)
    ).data.data) as ArquivoRetornoTypes;
  }
  async function Importar(DadosArquivoRetorno: FormArquivoRetornoType) {
    return await new CGIHttp().conn.post(
      url + "/incluir",
      DadosArquivoRetorno.Form
    );
  }
  return {
    RetornarDadosVisualizarArquivoRetorno,
    RetornarDadosArquivoRetorno,
    RetornarDadosGridPesquisa,
    RetornarArquivosRetorno,
    RetornarDadosExclusao,
    Visualizar,
    Importar,
    Excluir,
  };
}
