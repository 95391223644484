import { AddIcon } from "@chakra-ui/icons";
import { useState, useRef, useContext } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGIComboSimples } from "../../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIListar } from "../../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../../Context/LoadingContext";
import { ModalBaseType } from "../../../../Types/Modal/ModalBaseType";
import {
  ListarNaturezaOperacaoTypes,
  ListarNaturezaOperacaoItensTypes,
} from "../../../../Types/Sistema/Classificacao/NaturezaOperacao/NaturezaOperacaoTypes";
import { DeleteTypes } from "../../../../Types/Util/DeleteTypes";
import { HttpNaturezaOperacao } from "./Http";
import { AlterarNaturezaOperacao } from "./_AlterarNaturezaOperacao";
import { VisualizarNaturezaOperacao } from "./_VisualizarNaturezaOperacao";

export function ListarNaturezaOperacao() {
  //Inicio Hooks
  const AlterarNaturezaOperacaoRef = useRef<ModalBaseType>(null);
  const VisualizarNaturezaOperacaoRef = useRef<ModalBaseType>(null);
  const CGIModalDeleteRef = useRef<ModalBaseType>(null);
  const { setLoadingState } = useContext(LoadingContext);
  const methods = useForm<ListarNaturezaOperacaoTypes>();
  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  //Final Hooks

  //------------------------

  //Inicio Consts
  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Nova Natureza de Operação",
      Permissao: "sistema/classificacao/incluir-natureza-operacao",
      OnClick: () => {
        AlterarNaturezaOperacaoRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "sistema/classificacao/visualizar-natureza-operacao",
      OnClick: (e: ListarNaturezaOperacaoItensTypes) => {
        VisualizarNaturezaOperacaoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Alterar",
      Permissao: "sistema/classificacao/alterar-natureza-operacao",
      OnClick: (e: ListarNaturezaOperacaoItensTypes) => {
        AlterarNaturezaOperacaoRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/classificacao/excluir-natureza-operacao",
      OnClick: (e: ListarNaturezaOperacaoItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarNaturezaOperacaoItensTypes>> = [
    {
      Campo: "ativo",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: [
        {
          nomeStatus: "true",
          cor: "green",
        },
        {
          nomeStatus: "false",
          cor: "red",
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 120,
      },
    },
    {
      Campo: "nome",
      Titulo: "Nome",
      Tipo: "Texto",
    },
    {
      Campo: "alocarEstoque",
      Titulo: "Alocar Estoque",
      Tipo: "CheckBoxDisable",
      att: {
        maxWidth: 130,
      },
    },
    {
      Campo: "nomeFinalidadeNF",
      Titulo: "Finalidade NF",
      Tipo: "Texto",
      att: {
        maxWidth: 150,
      },
    },

    {
      Campo: "resumoFinalidade",
      Titulo: "Finalidade",
      Tipo: "HTML",
      att: {
        maxWidth: 200,
      },
    },
    {
      Campo: "nomePrecoAutomatico",
      Titulo: "Preço Automático",
      Tipo: "Texto",
      att: {
        maxWidth: 180,
      },
    },
    {
      Campo: "tipo",
      Titulo: "Tipo",
      Tipo: "Texto",
      att: {
        maxWidth: 130,
      },
    },

    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarNaturezaOperacaoItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          titulo: "Id",
          valor: "id",
        },
        {
          titulo: "Nome",
          valor: "nome",
        },
        {
          titulo: "Tipo Doc.",
          valor: "nomeTipoDocumento",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Natureza Operacao"
          CarregarDados={methods.handleSubmit(RetornarNaturezasOperacao)}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarNaturezaOperacao
          CarregarDados={methods.handleSubmit(RetornarNaturezasOperacao)}
          ref={AlterarNaturezaOperacaoRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarNaturezaOperacao
          CarregarDados={methods.handleSubmit(RetornarNaturezasOperacao)}
          ref={VisualizarNaturezaOperacaoRef}
        />
      ),
    },
  ];

  //Final Consts

  //------------------------

  //Inicio Function

  async function RetornarNaturezasOperacao() {
    setLoadingState(true);
    var DadosFitros = methods.getValues("Pesquisa");
    var data = await HttpNaturezaOperacao()
      .RetornarNaturezasOperacao(DadosFitros)
      .finally(() => {
        setLoadingState(false);
      });

    methods.setValue("Itens", data);
  }

  async function RetornarDadosExclusao(data: ListarNaturezaOperacaoItensTypes) {
    return await HttpNaturezaOperacao().RetornarDadosExclusao(data.id);
  }
  async function Excluir(data: DeleteTypes) {
    await HttpNaturezaOperacao()
      .Excluir(data)
      .then((e) => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  //Final Function

  //------------------------

  // Inicio JSX

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarNaturezaOperacaoTypes>
            id="Pesquisa.nome"
            label="Nome"
            placeholder="Nome"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarNaturezaOperacaoTypes>
            Link={"simnaoambos"}
            label={"Ativo"}
            id={"Pesquisa.ativoId"}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="sistema/classificacao/listar"
      Titulo="Natureza de Operação"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarNaturezasOperacao)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
