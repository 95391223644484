import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import { CGITextArea } from "../../../Components/TextArea/CGITextArea";
import LoadingContext from "../../../Context/LoadingContext";
import {
  FormNFSaidaType,
  NFSaidaTypes,
} from "../../../Types/Faturamento/NFSaida/NFSaidaTypes";
import { HttpNFSaida } from "./Http";

type AlterarNumeroNFSaidaType = {
  CarregarDados: Function;
};

export const AlterarNumeroNFSaida = forwardRef(
  (props: AlterarNumeroNFSaidaType, ref) => {
    useImperativeHandle(ref, () => ({
      Abrir(pNFSaida: any) {
        form.setValue("Form.DadosRegistro.nfsaidaId", pNFSaida.id);
        CGIModalRef.current?.Abrir();
      },
      Alterar(pNFSaida: any) {
        CGIModalRef.current.Abrir();

        HttpNFSaida()
          .RetornarDadosNFSaida(pNFSaida.id)
          .then((e) => {
            form.reset(e);
          });
      },
    }));

    const CGIModalRef = useRef() as any;
    const form = useFormContext<FormNFSaidaType>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    const Confirmar = async (data: FormNFSaidaType, event: any) => {
      setLoadingState(true);

      HttpNFSaida()
        .AlterarNumeroNFSaida(data)
        .then((data: any) => {
          ToastSucesso("Registro Alterado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
        })
        .catch((e) => {
          return;
        })
        .finally(() => {
          setLoadingState(false);
        });
    };

    return (
      <CGIModal
        id="faturamento/nfsaida/alterarnumeronfeserie"
        ref={CGIModalRef}
        Titulo="Alterar Número NF e Série"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGIBox>
          <CGIBoxItem Col={8}>
            <CGIInput<FormNFSaidaType>
              id="Form.DadosRegistro.numeroNF"
              label="Nº NF"
              placeholder="Nº NF"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormNFSaidaType>
              id="Form.DadosRegistro.serie"
              label="Série"
              placeholder="Série"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGITextArea<FormNFSaidaType>
              id="Form.DadosRegistro.obs"
              label="Observação"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <span style={{ color: "red", fontWeight: "bold" }}>
              * O Sistema não fará nenhuma validação quanto ao número e série
              informados. Caso a NF tenha contas a receber vinculado, estes não
              terão o campo NUM. DOCUMENTO atualizados
            </span>
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
  }
);
