import { AddIcon } from "@chakra-ui/icons";
import { useState, useRef, useContext } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGIComboSimples } from "../../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIListar } from "../../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../../Context/LoadingContext";
import { ModalBaseType } from "../../../../Types/Modal/ModalBaseType";
import {
  FormLimiteCreditoTypes,
  ListarLimiteCreditoItensTypes,
  ListarLimiteCreditoTypes,
  ObjListarLimiteCreditoPadrao,
} from "../../../../Types/Sistema/Classificacao/LimiteCredito/LimiteCreditoTypes";
import { DeleteTypes } from "../../../../Types/Util/DeleteTypes";
import { HttpLimiteCredito } from "./Http";
import { AlterarLimiteCredito } from "./_AlterarLimiteCredito";
import { VisualizarLimiteCredito } from "./_VisualizarLimiteCredito";

export function ListarLimiteCredito() {
  //Inicio Hooks
  const AlterarLimiteCreditoRef = useRef<ModalBaseType>();
  const VisualizarLimiteCreditoRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarLimiteCreditoTypes>({
    defaultValues: ObjListarLimiteCreditoPadrao,
  });
  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });
  //Final Hooks

  //------------------------

  //Inicio Consts
  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo Limite de Crédito",
      Permissao: "sistema/classificacao/incluir",
      OnClick: () => {
        AlterarLimiteCreditoRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "sistema/classificacao/visualizar",
      OnClick: (e: ListarLimiteCreditoItensTypes) => {
        VisualizarLimiteCreditoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Alterar",
      Permissao: "sistema/classificacao/alterar",
      OnClick: (e: ListarLimiteCreditoItensTypes) => {
        AlterarLimiteCreditoRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/classificacao/excluir",
      OnClick: (e: ListarLimiteCreditoItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarLimiteCreditoItensTypes>> = [
    {
      Campo: "ativo",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: [
        {
          nomeStatus: "true",
          cor: "green",
        },
        {
          nomeStatus: "false",
          cor: "red",
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 80,
      },
    },
    {
      Campo: "nome",
      Titulo: "Nome",
      Tipo: "Texto",
    },
    {
      Campo: "limite",
      Titulo: "Valor",
      Tipo: "Moeda",
      att: {
        maxWidth: 150,
      },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarLimiteCreditoItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          titulo: "Id",
          valor: "id",
        },
        {
          titulo: "Nome",
          valor: "nome",
        },
        {
          titulo: "Valor",
          valor: "limite",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Limite de Crédito"
          CarregarDados={methods.handleSubmit(RetornarLimitesCredito)}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarLimiteCredito
          CarregarDados={methods.handleSubmit(RetornarLimitesCredito)}
          ref={AlterarLimiteCreditoRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarLimiteCredito
          CarregarDados={methods.handleSubmit(RetornarLimitesCredito)}
          ref={VisualizarLimiteCreditoRef}
        />
      ),
    },
  ];

  //Final Consts

  //------------------------

  //Inicio Function

  async function RetornarLimitesCredito() {
    setLoadingState(true);
    var DadosFiltro = methods.getValues("Pesquisa");
    var data = await HttpLimiteCredito()
      .RetornarLimitesCredito(DadosFiltro)
      .finally(() => {
        setLoadingState(false);
      });
    methods.setValue("Itens", data);
  }
  async function RetornarDadosExclusao(data: FormLimiteCreditoTypes) {
    return await HttpLimiteCredito().RetornarDadosExclusao(
      data.Form.DadosRegistro
    );
  }
  async function Excluir(data: DeleteTypes) {
    await HttpLimiteCredito()
      .Excluir(data)
      .then(e => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  //Final Function

  //------------------------

  // Inicio JSX

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarLimiteCreditoTypes>
            id="Pesquisa.nome"
            label="Nome"
            placeholder="Nome"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarLimiteCreditoTypes>
            id={"Pesquisa.ativoId"}
            Link={"simnaoambos"}
            label={"Ativo"}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <>
      <CGIListar
        id="sistema/classificacao/listar"
        Titulo="Limite de Crédito"
        Janelas={Janelas}
      >
        <FormProvider {...methods}>
          <CGIDataTable
            LimparFiltros={() => methods.reset()}
            Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
            Pesquisar={methods.handleSubmit(RetornarLimitesCredito)}
            Filtro={Filtros()}
            Data={GridItens}
            Colunas={GridColunas}
            Legenda={Legendas}
          />
        </FormProvider>
      </CGIListar>
    </>
  );
  // Final JSX
}
