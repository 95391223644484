export const CalendarioOpcoes = {
  locale: "pt-br",
  timeFormat: "HH:mm",
  editable: true,
  eventLimit: false,
  slotLabelFormat: "HH:mm",
  allDayText: "24 horas",
  columnFormat: "dddd",
  header: {
    left: "prev,next today",
    center: "title",
    right: "month,agendaWeek,agendaDay,listMonth",
  },
  buttonText: {
    today: "Hoje",
    month: "Mês",
    week: "Semana",
    day: "Hoje",
    list: "Lista",
  },
};
