import React from "react";
import { FormProvider, useForm } from "react-hook-form";

/**
 * @Componente FormContext - Context responsável por controlar todos os Metodos do Form
 * @Ref  Context
 * @Util Form Principal
 */
export function FormContext(props: { children: React.ReactNode }) {
  const methods = useForm();

  return <FormProvider {...methods}>{props.children}</FormProvider>;
}
