import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIRadioButton } from "../../../Components/RadioButton/CGIRadioButton";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import { CGITextArea } from "../../../Components/TextArea/CGITextArea";
import LoadingContext from "../../../Context/LoadingContext";
import {
  CtsPagarVisualizarTypes,
  FormCtsPagarTypes,
  ListarCtsPagarItensTypes,
  ListarCtsPagarTypes,
  ObjListarCtsPagarPadrao,
  DadosRegistroTypes,
} from "../../../Types/Financeiro/ContasPagar/CtsPagar";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { HttpCtsPagar } from "./Http";

type DesbloquearCtsPagarType = {
  CarregarDados: Function;
};

export const DesbloquearCtsPagar = forwardRef(
  (props: DesbloquearCtsPagarType, ref) => {
    useImperativeHandle(ref, () => ({
      Abrir(pId: number) {
        form.setValue("id", pId);
        CGIModalRef.current?.Abrir();
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef<ModalBaseType>();
    const form = useFormContext();

    async function CarregarDados(id: number) {}

    const Confirmar = async (data: any, event: any) => {};

    return (
      <CGIModal
        id="financeiro/ctspagar/desbloquearctspagar"
        ref={CGIModalRef}
        Titulo="Desbloquear Cts a Pagar"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        CarregarDados={CarregarDados}
        Fechar={() => {}}
      >
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIResumo Expandir ResumoCtsPagar CtsPagarId="id" />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGITextArea<FormCtsPagarTypes>
              id={"Form.DadosRegistro.obs"}
              label={"Observação"}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
  }
);
