import React, { createContext, useState } from "react";

type LoadingContextTypes = {
  Loadingstate: boolean;
  setLoadingState: React.Dispatch<React.SetStateAction<boolean>>;
};

const DefaultValue: LoadingContextTypes = {
  setLoadingState: () => {},
  Loadingstate: false,
};

/**
 * @Componente LoadingContext - Context responsável para controlar o Loading do Sistema
 * @Ref  Context
 */
export const LoadingContext = createContext<LoadingContextTypes>(DefaultValue);

/**
 * @Componente LoadingProvider - Provider responsável para controlar o Loading do Sistema
 * @Ref  Provider
 */
function LoadingProvider(props: { children: React.ReactNode }) {
  const [Loadingstate, setLoadingState] = useState(false);

  return (
    <LoadingContext.Provider value={{ Loadingstate, setLoadingState }}>
      {props.children}
    </LoadingContext.Provider>
  );
}

export { LoadingProvider };
export default LoadingContext;
