import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Flex,
  FormLabel,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { HttpCGINotificacao } from "./HttpNotificacao";
import * as CSS from "csstype";
import moment from "moment";
import React from "react";
import { Temas } from "../../Themes/Temas";

export type CGINotificacaoType = {
  id: number;
  autor: string;
  titulo: string;
  conteudo: string;
  dataCadastro: string;
};

export interface CGINotificacaoPropsType {
  link: string;
  titulo?: string;
  bgColor: CSS.Property.Color;
  color?: CSS.Property.Color;
}
/**
 * @Componente CGINotificacao - Componente responsavel  pelas Notificacoes
 * @Ref  CGINotificacao
 */
export function CGINotificacao(props: any) {
  const [Dados, setDados] = useState<Array<CGINotificacaoType>>([]);

  useEffect(() => {
    RetornarDados();
  }, []);

  async function RetornarDados() {
    var data = await HttpCGINotificacao().RetornarDados();
    setDados(data);
  }
  return (
    <Box bg={Temas.Branco} p={4}>
      <FormLabel>Notificações ({Dados.length})</FormLabel>
      <List style={{ overflowY: "scroll" }} h={{ base: "100%", md: "100px" }}>
        {Dados.map((e, index) => {
          return (
            <ListItem key={index} mt={1} borderRadius={"md"} p={1}>
              <Alert>
                <AlertIcon />
                <Flex w={"100%"} flexDir={"column"}>
                  <Flex>
                    <Flex flex={1}>
                      <Text fontSize={"sm"}>{e.autor}</Text>
                    </Flex>
                    <Flex>
                      <Text fontSize={"sm"}>
                        {moment(e.dataCadastro).format("DD/MM/yyyy")}
                      </Text>
                    </Flex>
                  </Flex>
                  <AlertTitle>{e.titulo}</AlertTitle>
                  <AlertDescription
                    dangerouslySetInnerHTML={{ __html: e.conteudo }}
                  ></AlertDescription>
                </Flex>

                <CloseButton
                  onClick={async () => {
                    await HttpCGINotificacao().VisualizarNotificacao(e);
                    RetornarDados();
                  }}
                  alignSelf="flex-start"
                  position="relative"
                  right={-1}
                  top={-1}
                />
              </Alert>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
