// @ts-ignore
import {
  Box,
  Flex,
  FormLabel,
  Input,
  InputProps,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import React from "react";

const Mascaras = {
  Tel: "(99) 9999-999999",
  CPF: "999.999.999-99",
  CNPJ: "99.999.999/9999-99",
  CEP: "99999-999",
  Data: "99/99/9999",
};

interface CGIInputTypes<T extends Record<string, unknown>> {
  id: NestedKey<T>;
  label?: string;
  placeholder: string;
  mascara?: "Tel" | "CPF" | "CNPJ" | "CEP" | "Data" | "Real" | undefined;
  validadores?: RegisterOptions | undefined;
  ocultar?: boolean;
  att?: InputProps;
  inputRight?: JSX.Element;
}
/**
 * @Componente CGIInput -Input padrão do Sistema
 * @Ref  FormContext
 * @Form  Necessita ser Controlado Por Formulário
 */

export function CGIInput<T extends Record<string, unknown>>(
  props: CGIInputTypes<T>
) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [maskInput, setMaskInput] = useState("");

  useEffect(() => {
    switch (props.mascara) {
      case "Tel":
        setMaskInput(Mascaras.Tel);
        break;
      case "CPF":
        setMaskInput(Mascaras.CPF);
        break;
      case "CNPJ":
        setMaskInput(Mascaras.CNPJ);
        break;
      case "CEP":
        setMaskInput(Mascaras.CEP);
        break;

      case "Data":
        setMaskInput(Mascaras.Data);
        break;

      default:
        setMaskInput("");
        break;
    }
  }, [props.mascara]);
  return (
    <Box hidden={props.ocultar}>
      <FormLabel htmlFor={props.id}>
        <Flex>
          <Text
            dangerouslySetInnerHTML={{ __html: props.label ? props.label : "" }}
          />

          {props.label && props.validadores?.required ? (
            <span style={{ color: "red" }}> *</span>
          ) : (
            ""
          )}
        </Flex>
      </FormLabel>
      <Controller
        render={({ field }) => {
          return (
            <Input
              {...field}
              {...props.att}
              mask={maskInput}
              // alwaysShowMask={false}
              as={maskInput ? InputMask : Input}
              placeholder={props.placeholder}
              value={(field.value as any) ? (field.value as any) : ""}
              onChange={field.onChange}
            />
          );
        }}
        defaultValue={"" as any}
        rules={props.validadores}
        name={props.id}
        control={control}
      />
      <ErrorMessage
        errors={errors}
        name={props.id}
        render={({ message }) => (
          <span style={{ color: "red", fontSize: 12 }}>
            * {message ? message : "Campo obrigatório"}
          </span>
        )}
      />
    </Box>
  );
}
