import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
} from "react";
import { CGIBox } from "../../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../../Components/Box/CGIBoxItens";
import { CGIComboAjax } from "../../../../../Components/Combos/CGIComboAjax";
import { CGIResumo } from "../../../../../Components/Resumo/CGIResumo";
import { PedidoLojaType } from "../../../../../Types/Vendas/Pedido/PedidoLojaType";
import { HttpPedido } from "../../Http";
import PedLojaContext from "../_AlterarPedidoLoja";

export const AreaCliente = forwardRef((props: {}, ref) => {
  const { form } = useContext(PedLojaContext);
  const clienteId = form.watch("DadosRegistro.clienteId");

  const RetornarInformacoes = useCallback(async () => {
    const dados = await HttpPedido().RetornarDadosColaborador(clienteId);
    form.setValue("InformacoesColaborador", dados);
  }, [clienteId]);

  const InformacoesColaborador = useMemo(() => {
    if (!clienteId) return;
    RetornarInformacoes();
  }, [RetornarInformacoes, clienteId]);

  useImperativeHandle(ref, () => ({}));
  return (
    <CGIBox>
      <>{InformacoesColaborador}</>
      <CGIBoxItem Col={12}>
        <CGIComboAjax<PedidoLojaType>
          Link={"cliente"}
          label={"Clientes"}
          id={"DadosRegistro.clienteId"}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={12}>
        <CGIResumo
          Expandir
          ColaboradorId="DadosRegistro.clienteId"
          ResumoColaborador
        />
      </CGIBoxItem>
    </CGIBox>
  );
});
