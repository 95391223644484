import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../Context/LoadingContext";
import {
  FormBoletoBancarioType,
  BoletoBancarioTypes,
} from "../../../Types/Financeiro/BoletoBancario/BoletoBancarioTypes";
import { HttpBoletoBancario } from "./Http";

type AlterarBoletoBancarioType = {
  CarregarDados: Function;
};

export const AlterarBoletoBancario = forwardRef(
  (props: AlterarBoletoBancarioType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current.Abrir();
      },
      async Alterar(pBoletoBancario: any) {
        CGIModalRef.current.Abrir();
        console.log(pBoletoBancario);
        await HttpBoletoBancario()
          .RetornarDadosBoletoBancario(pBoletoBancario.id)
          .then((e) => {
            console.log(e);
            form.reset(e.Form);
          });
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<BoletoBancarioTypes>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    //FINAL  HOOKS

    //INICIO CONST
    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Dados BoletoBancario",
        Component: AreaDadosBoletoBancario(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosBoletoBancario() {
      return (
        <CGIBox>
          <CGIBoxItem Col={8}>
            <CGIInput<FormBoletoBancarioType>
              id="Form.DadosRegistro.colaboradorId"
              label="NCM"
              placeholder=""
              att={{
                maxLength: 8,
              }}
              validadores={{
                required: "NCM BoletoBancario obrigatório",
              }}
            />
          </CGIBoxItem>
        </CGIBox>
      );
    }

    const Confirmar = async (data: FormBoletoBancarioType, event: any) => {
      setLoadingState(true);

      if (
        data.Form.DadosRegistro.id == null ||
        data.Form.DadosRegistro.id === undefined ||
        data.Form.DadosRegistro.id === 0
      ) {
        HttpBoletoBancario()
          .Incluir(data)
          .then((response: any) => {
            ToastSucesso("Registro gravado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
            console.log(data);
          })
          .catch((e) => {
            return ToastErro(e);
          })
          .finally(() => {
            setLoadingState(false);
          });
      } else {
        HttpBoletoBancario()
          .Alterar(data)
          .then((data: any) => {
            ToastSucesso("Registro Alterado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return;
          })
          .finally(() => {
            setLoadingState(false);
          });
      }
    };
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="sistema/BoletoBancario/alterar"
        ref={CGIModalRef}
        Titulo="Cadastro de BoletoBancario"
        TelaCheia={"3xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
