export const ObjAlocacaoPadrao: FormAlocacaoType = {
  Form: {
    dadosRegistro: {
      id: 0,
      ncm: "",
      percIPI: 0,
      ex: 0,
    },
  },
};
export type AlocacaoTypes = {
  dadosRegistro: {
    id: number;
    ncm: string;
    percIPI: number;
    ex: number;
  };
};
export type FormAlocacaoType = {
  Form: AlocacaoTypes;
};

export const ObjListarAlocacaoPadrao: ListarAlocacaoTypes = {
  Pesquisa: {
    dataInicial: "",
    dataFinal: "",
    listaEmpresas: "",
    produtoId: 0,
  },
  Itens: [],
  ItensEmpresa: [],
  exibirGridEmpresa: false,
};

export type AlocacaoPesquisaGridTypes = {
  ncm?: string;
};

export type ListarAlocacaoTypes = {
  Pesquisa: ListarPesquisaAlocacaoTypes;
  Itens: ListarAlocacaoItensTypes[];
  ItensEmpresa: ListarAlocacaoItensEmpresasTypes[];
  exibirGridEmpresa: boolean;
};

export type ListarPesquisaAlocacaoTypes = {
  dataInicial: string;
  dataFinal: string;
  listaEmpresas: string;
  produtoId: number;
};

export type ListarAlocacaoItensTypes = {
  qtdPedido: number;
  qtdAlocada: number;
  lancManual: boolean;
  multiplo: number;
  ordemAlocacaoPedido: number;
  dataCadastro: string;
  identificacao: string;
  nomeVendedor: string;
  valorTotalItem: number;
  valorTotalAlocado: number;
  nomeCliente: string;
  nomeProduto: string;
  nomeEmpresa: string;
  numeroOrdemAlocacao: number;
  nomeLocalPedido: string;
  pedidoId: number;
};
export type ListarAlocacaoItensEmpresasTypes = {
  nomeEmpresa: string;
  qtdEstoqueEmpresa: number;
};
