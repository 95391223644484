import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIRadioButton } from "../../../Components/RadioButton/CGIRadioButton";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../Context/LoadingContext";
import {
  CtsPagarVisualizarTypes,
  FormCtsPagarTypes,
  ListarCtsPagarItensTypes,
  ListarCtsPagarTypes,
  ObjListarCtsPagarPadrao,
} from "../../../Types/Financeiro/ContasPagar/CtsPagar";
import { HttpCtsPagar } from "./Http";

type AlterarDataCadastroCtsPagarType = {
  CarregarDados: Function;
};

export const AlterarDataCadastroCtsPagar = forwardRef(
  (props: AlterarDataCadastroCtsPagarType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current.Abrir();
      },
      Alterar(pEmpresa: any) {
        CGIModalRef.current.Abrir();

        HttpCtsPagar()
          .RetornarDadosCtsPagar(pEmpresa.id)
          .then((e) => {
            form.reset(e.data.data);
          });
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    //FINAL  HOOKS

    //INICIO CONST

    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Alterar Data Cadastro - Cts Pagar",
        Component: AreaAlterarDataCadastroCtsPagar(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaAlterarDataCadastroCtsPagar() {
      return (
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIBox titulo="Dados do Título" popover="Dados do Título">
              <CGIBoxItem Col={3}>
                <CGIInput<CtsPagarVisualizarTypes>
                  id="titulo"
                  label="Título"
                  placeholder="Título"
                  att={{ isReadOnly: true }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<CtsPagarVisualizarTypes>
                  id="nomeTipoDocumento"
                  label="Tipo de Documento"
                  placeholder="Tipo de Documento"
                  att={{ isReadOnly: true }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIInput<CtsPagarVisualizarTypes>
                  id="nomeFornecedor"
                  label="Tipo de Documento"
                  placeholder="Tipo de Documento"
                  att={{ isReadOnly: true }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGICheckBox<CtsPagarVisualizarTypes>
                  id="tituloPrevisao"
                  label="Previsão"
                  tooltip="Previsão"
                  att={{ disabled: true }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<CtsPagarVisualizarTypes>
                  id="nomeEmpresa"
                  label="Empresa"
                  placeholder="Empresa"
                  att={{ isReadOnly: true }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<CtsPagarVisualizarTypes>
                  id="valor"
                  label="Valor do Título"
                  placeholder="Valor do Título"
                  att={{ isReadOnly: true }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<CtsPagarVisualizarTypes>
                  id="saldoPagar"
                  label="Saldo a Pagar"
                  placeholder="Saldo a Pagar"
                  att={{ isReadOnly: true, style: { color: "red" } }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<CtsPagarVisualizarTypes>
                  id="documento"
                  label="Documento"
                  placeholder="Documento"
                  att={{ isReadOnly: true }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={6}>
                <CGIInput<CtsPagarVisualizarTypes>
                  id="obs"
                  label="Observações do Título"
                  placeholder="Observações do Título"
                  att={{ isReadOnly: true }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGIInput<CtsPagarVisualizarTypes>
                  id="dataVencimento"
                  label="Vencimento"
                  placeholder="Vencimento"
                  att={{ isReadOnly: true }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={2}>
                <CGIInput<CtsPagarVisualizarTypes>
                  id="dataPrevisao"
                  label="Previsao"
                  placeholder="Previsão"
                  att={{ isReadOnly: true }}
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIDatePicker<FormCtsPagarTypes>
              id="Form.DadosRegistro.dataCadastro"
              label="Data Cadastro"
              validadores={{ required: true }}
            />
          </CGIBoxItem>
        </CGIBox>
      );
    }

    const Confirmar = async (data: FormCtsPagarTypes, event: any) => {
      setLoadingState(true);

      if (
        data.Form.DadosRegistro.id == null ||
        data.Form.DadosRegistro.id === undefined ||
        data.Form.DadosRegistro.id === 0
      ) {
        HttpCtsPagar()
          .AlterarDataCadastro(data.Form.DadosRegistro)
          .then((response: any) => {
            ToastSucesso("Registro Alterado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return ToastErro(e);
          })
          .finally(() => {
            setLoadingState(false);
          });
      }
    };
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="financeiro/ctspagar/alterardatacadastro"
        ref={CGIModalRef}
        Titulo="Alterar Data Cadastro - Cts Pagar"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
