import { AddIcon } from "@chakra-ui/icons";
import { useState, useRef, useContext } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import {
  FormEquipamentoTypes,
  ListarEquipamentoItensTypes,
  ListarEquipamentoTypes,
  ObjListarEquipamentoPadrao,
} from "../../../Types/Sistema/Equipamento/EquipamentoTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpEquipamento } from "./Http";
import { AlterarEquipamento } from "./_AlterarEquipamento";
import { VisualizarEquipamento } from "./_VisualizarEquipamento";

export function ListarEquipamento() {
  //Inicio Hooks
  const AlterarEquipamentoRef = useRef<ModalBaseType>();
  const VisualizarEquipamentoRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarEquipamentoTypes>({
    defaultValues: ObjListarEquipamentoPadrao,
  });
  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });
  //Final Hooks

  //------------------------

  //Inicio Consts
  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo Equipamento",
      Permissao: "sistema/equipamento/incluir",
      OnClick: () => {
        AlterarEquipamentoRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "sistema/equipamento/visualizar",
      OnClick: (e: ListarEquipamentoItensTypes) => {
        VisualizarEquipamentoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Alterar",
      Permissao: "sistema/equipamento/alterar",
      OnClick: (e: ListarEquipamentoItensTypes) => {
        AlterarEquipamentoRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/equipamento/excluir",
      OnClick: (e: ListarEquipamentoItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarEquipamentoItensTypes>> = [
    {
      Campo: "ativo",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: [
        {
          nomeStatus: "true",
          cor: "green",
        },
        {
          nomeStatus: "false",
          cor: "red",
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 150,
      },
    },
    {
      Campo: "identificacao",
      Titulo: "Identificação",
      Tipo: "Texto",
    },
    {
      Campo: "frota",
      Titulo: "Frota",
      Tipo: "Texto",
    },
    {
      Campo: "modelo",
      Titulo: "Modelo",
      Tipo: "Texto",
    },
    {
      Campo: "terceiro",
      Titulo: "Terceiro",
      Tipo: "CheckBoxDisable",
    },
    {
      Campo: "nomeTipoEquipamento",
      Titulo: "Tipo de Equipamento",
      Tipo: "Texto",
    },
    {
      Campo: "nomeOperador",
      Titulo: "Operador",
      Tipo: "Texto",
    },
    {
      Campo: "nomeEmpresa",
      Titulo: "Empresa",
      Tipo: "Texto",
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarEquipamentoItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          titulo: "Id",
          valor: "id",
        },
        {
          titulo: "Código",
          valor: "id",
        },
        {
          titulo: "Identificacao",
          valor: "identificacao",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Equipamento"
          CarregarDados={methods.handleSubmit(RetornarEquipamentos)}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarEquipamento
          CarregarDados={methods.handleSubmit(RetornarEquipamentos)}
          ref={AlterarEquipamentoRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarEquipamento
          CarregarDados={methods.handleSubmit(RetornarEquipamentos)}
          ref={VisualizarEquipamentoRef}
        />
      ),
    },
  ];

  //Final Consts

  //------------------------

  //Inicio Function

  async function RetornarEquipamentos() {
    setLoadingState(true);
    var DadosFiltro = methods.getValues("Pesquisa");
    var data = await HttpEquipamento()
      .RetornarEquipamentos(DadosFiltro)
      .finally(() => {
        setLoadingState(false);
      });
    methods.setValue("Itens", data);
  }
  async function RetornarDadosExclusao(data: FormEquipamentoTypes) {
    return await HttpEquipamento().RetornarDadosExclusao(
      data.Form.DadosRegistro
    );
  }
  async function Excluir(data: DeleteTypes) {
    await HttpEquipamento()
      .Excluir(data)
      .then((e) => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  //Final Function

  //------------------------

  // Inicio JSX

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Filtros de Período" popover="Filtros de Período">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarEquipamentoTypes>
                id={"Pesquisa.dataInicialVencimentoIPVA"}
                label="Data Vencimento IPVA Inical"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarEquipamentoTypes>
                id="Pesquisa.dataFinalVencimentoIPVA"
                label="Data Vencimento IPVA Final"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarEquipamentoTypes>
            Multipla
            id="Pesquisa.ListaEmpresasId"
            label="Empresa"
            Link="empresa"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples<ListarEquipamentoTypes>
            id="Pesquisa.ativoId"
            Link="simnaoambos"
            label="Ativo"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarEquipamentoTypes>
            id="Pesquisa.identificacao"
            label="Identificação"
            placeholder="Identificação"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarEquipamentoTypes>
            Multipla
            id="Pesquisa.ListaTiposEquipamentoId"
            label="Tipo de Equipamento"
            Link="equipamento"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarEquipamentoTypes>
            id="Pesquisa.modelo"
            label="Modelo"
            placeholder="Modelo"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarEquipamentoTypes>
            id="Pesquisa.frota"
            label="Frota"
            placeholder="Frota"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples<ListarEquipamentoTypes>
            id="Pesquisa.locado"
            label="Locado"
            Link="simnaoambos"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples<ListarEquipamentoTypes>
            id="Pesquisa.terceiro"
            label="Terceiro"
            Link="simnaoambos"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarEquipamentoTypes>
            Multipla
            id="Pesquisa.ListaLocadoresId"
            label="Locadores"
            Link="equipamento"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarEquipamentoTypes>
            id="Pesquisa.apoliceSeguro"
            label="Apólice Seguro"
            placeholder="Apólice Seguro"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarEquipamentoTypes>
            id="Pesquisa.obs"
            label="Observação"
            placeholder="Observação"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarEquipamentoTypes>
            Multipla
            id="Pesquisa.ListaCentrosDeCustoId"
            label="Centro de Custo"
            Link="equipamento"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGICheckBox<ListarEquipamentoTypes>
            id="Pesquisa.somenteEquipamentosCadastroIncompleto"
            label="Somente equipamentos com cadastro incompleto"
            tooltip=""
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <>
      <CGIListar
        id="sistema/equipamento/listar"
        Titulo="Equipamento"
        Janelas={Janelas}
      >
        <FormProvider {...methods}>
          <CGIDataTable
            LimparFiltros={() => methods.reset()}
            Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
            Pesquisar={methods.handleSubmit(RetornarEquipamentos)}
            Filtro={Filtros()}
            Data={GridItens}
            Colunas={GridColunas}
            Legenda={Legendas}
          />
        </FormProvider>
      </CGIListar>
    </>
  );
  // Final JSX
}
