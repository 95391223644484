import { AddIcon } from "@chakra-ui/icons";
import { useContext, useRef, useState } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGIComboSimples } from "../../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIListar } from "../../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../../Context/LoadingContext";
import { ModalBaseType } from "../../../../Types/Modal/ModalBaseType";
import {
  FormTipoNFEntradaType,
  ListarTipoNFEntradaItensTypes,
  ListarTipoNFEntradaTypes,
} from "../../../../Types/Sistema/Classificacao/TipoNFEntrada/TipoNFEntradaTypes";
import { DeleteTypes } from "../../../../Types/Util/DeleteTypes";
import { HttpTipoNFEntrada } from "./Http";
import { AlterarTipoNFEntrada } from "./_AlterarTipoNFEntrada";
import { VisualizarTipoNFEntrada } from "./_VisualizarTipoNFEntrada";

export function ListarTipoNFEntrada() {
  //Inicio Hooks
  const AlterarTipoNFEntradaRef = useRef<ModalBaseType>(null);
  const VisualizarTipoNFEntradaRef = useRef<ModalBaseType>(null);
  const CGIModalDeleteRef = useRef<ModalBaseType>(null);
  const { setLoadingState } = useContext(LoadingContext);
  const methods = useForm<ListarTipoNFEntradaTypes>();
  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });
  //Final Hooks

  //------------------------

  //Inicio Consts
  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo Tipo de NF Entrada",
      Permissao: "sistema/classificacao/incluir",
      OnClick: () => {
        AlterarTipoNFEntradaRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "sistema/classificacao/visualizar",
      OnClick: (e: ListarTipoNFEntradaItensTypes) => {
        VisualizarTipoNFEntradaRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Alterar",
      Permissao: "sistema/classificacao/alterar",
      OnClick: (e: ListarTipoNFEntradaItensTypes) => {
        AlterarTipoNFEntradaRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/classificacao/excluir",
      OnClick: (e: ListarTipoNFEntradaItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarTipoNFEntradaItensTypes>> = [
    {
      Campo: "ativo",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: [
        {
          nomeStatus: "true",
          cor: "green",
        },
        {
          nomeStatus: "false",
          cor: "red",
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 80,
      },
    },
    {
      Campo: "nome",
      Titulo: "Nome",
      Tipo: "Texto",
    },
    {
      Campo: "permitirCadastrarProdutos",
      Titulo: "Permitir Cadastrar Produtos",
      Tipo: "CheckBox",
    },
    {
      Campo: "buscarItemNocadastroDeProdutos",
      Titulo: "Buscar Item no Cad. Produtos",
      Tipo: "CheckBox",
    },
    {
      Campo: "numeroDeDiasParaNFRetorno",
      Titulo: "N.º de Dias Para NF Retorno",
      Tipo: "Texto",
    },
    {
      Campo: "atualizarCustoNoLancamentoNFEntrada",
      Titulo: "Atualizar Custo No Lanc. NF. Ent.",
      Tipo: "CheckBox",
    },

    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarTipoNFEntradaItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Tipo de NF Entrada"
          CarregarDados={RetornarTipoNFEntradas}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarTipoNFEntrada
          CarregarDados={methods.handleSubmit(RetornarTipoNFEntradas)}
          ref={AlterarTipoNFEntradaRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarTipoNFEntrada
          CarregarDados={methods.handleSubmit(RetornarTipoNFEntradas)}
          ref={VisualizarTipoNFEntradaRef}
        />
      ),
    },
  ];

  //Final Consts

  //------------------------

  //Inicio Function

  async function RetornarTipoNFEntradas() {
    var DadosFiltros = methods.getValues("Pesquisa");
    setLoadingState(true);
    var data = await HttpTipoNFEntrada()
      .RetornarNaturezasOperacao(DadosFiltros)
      .finally(() => {
        setLoadingState(false);
      });
    methods.setValue("Itens", data);
  }

  async function RetornarDadosExclusao(Dados: ListarTipoNFEntradaItensTypes) {
    return await HttpTipoNFEntrada().RetornarDadosExclusao(Dados.id);
  }
  async function Excluir(data: DeleteTypes) {
    await HttpTipoNFEntrada()
      .Excluir(data)
      .then(e => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  //Final Function

  //------------------------

  // Inicio JSX

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarTipoNFEntradaTypes>
            id="Pesquisa.nome"
            label="Nome"
            placeholder="Nome"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarTipoNFEntradaTypes>
            Link={"simnaoambos"}
            label={"Ativo"}
            id={"Pesquisa.ativoId"}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="sistema/classificacao/listar"
      Titulo="Tipo NF Entrada"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarTipoNFEntradas)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
