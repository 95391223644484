import { Box, Tag, TagLabel } from "@chakra-ui/react";
import { forwardRef, useContext, useImperativeHandle, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import LoadingContext from "../../../Context/LoadingContext";

import {
  LegendaTypes,
  ModalBaseType,
} from "../../../Types/Modal/ModalBaseType";
import {
  VisualizarOrcamentoPorProdutoItensTypes,
  VisualizarOrcamentoPorProdutoTypes,
} from "../../../Types/Vendas/Orcamento/VisualizarOrcamentoPorProduto";
import { HttpProduto } from "../../Sistema/Produto/Http";
import { HttpOrcamento } from "./Http";

type PropsVisualizarOrcamentoPorProdutoType = {
  CarregarDados: Function;
};

export const VisualizarOrcamentosPorProduto = forwardRef(
  (props: PropsVisualizarOrcamentoPorProdutoType, ref) => {
    useImperativeHandle(ref, () => ({
      async Abrir(pId: number) {
        CGIModalRef.current?.Abrir();
        form.setValue("Pesquisa.produtoId", pId);
        form.setValue("Itens", []);
        //CarregarDados();
      },
    }));
    const form = useFormContext<VisualizarOrcamentoPorProdutoTypes>();
    const { setLoadingState } = useContext(LoadingContext);
    const CGIModalRef = useRef<ModalBaseType>();
    const Legendas: LegendaTypes = [
      { nomeStatus: "Incluído", cor: "green" },
      { nomeStatus: "Aprovado", cor: "blue" },
      { nomeStatus: "Excluído", cor: "red" },
      { nomeStatus: "Não Aprovado", cor: "orange" },
    ];

    async function CarregarDados() {
      try {
        setLoadingState(true);
        var DadosFiltros = form.getValues("Pesquisa");
        var Dados = await HttpOrcamento().RetornarOrcamentoPorProduto(
          DadosFiltros
        );
        form.setValue("Itens", Dados);
      } finally {
        setLoadingState(false);
      }
    }

    const Confirmar = async (data: any, event: any) => {};
    const GridColunas: Array<CGICol<VisualizarOrcamentoPorProdutoItensTypes>> =
      [
        {
          Campo: "nomeStatus",
          Titulo: "",
          Status: Legendas,
          Tipo: "Status",
          att: {
            maxWidth: 60,
            filter: false,
          },
        },
        {
          Campo: "identificacaoOrcamento",
          Titulo: "Identificação",
          Tipo: "Texto",
        },
        {
          Campo: "nomeCliente",
          Titulo: "",
          Tipo: "Texto",
        },
        {
          Campo: "nomeEmpresa",
          Titulo: "Empresa",
          Tipo: "Texto",
        },
        {
          Campo: "vlrUnitario",
          Titulo: "Valor Unitário",
          Tipo: "Moeda",
        },
        {
          Campo: "quantidade",
          Titulo: "Qtd.",
          Tipo: "Moeda",
        },
        {
          Campo: "vlrTotal",
          Titulo: "Valor Total",
          Tipo: "Moeda",
        },
        {
          Campo: "totalOrcamento",
          Titulo: "Total Pedido",
          Tipo: "Moeda",
        },
      ];
    const GridItens = useWatch({
      control: form.control,
      name: "Itens",
    });

    return (
      <CGIModal
        id="almoxarifado/movestoque/exibirorcamentosporproduto"
        ref={CGIModalRef}
        Titulo="Exibir Orçamento"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        CarregarDados={CarregarDados}
        Fechar={() => {}}
        tipoGET={{
          url: "PRODUTO-EXIBIR-ORCAMENTOS",
          setRetorno: (retorno: any) => {
            form.setValue("Pesquisa", retorno);
          },
        }}
      >
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIResumo
              ProdutoId="Pesquisa.produtoId"
              ResumoProdutoSimples
              Expandir
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIBox titulo="Filtros">
              <CGIBoxItem Col={3}>
                <CGIComboSimples<VisualizarOrcamentoPorProdutoTypes>
                  id={"Pesquisa.listaStatusId"}
                  Link={"statusorcamento"}
                  label={"Status Orçamento"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIComboSimples<VisualizarOrcamentoPorProdutoTypes>
                  id={"Pesquisa.listaEmpresasId"}
                  Link={"empresa"}
                  label={"Empresas"}
                  Multipla
                />
              </CGIBoxItem>

              <CGIBoxItem Col={3}>
                <CGIDatePicker<VisualizarOrcamentoPorProdutoTypes>
                  id={"Pesquisa.dataInicial"}
                  label={"Dt. Cadastro - De"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIDatePicker<VisualizarOrcamentoPorProdutoTypes>
                  id={"Pesquisa.dataFinal"}
                  label={"Dt. Cadastro - Até"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={12}>
                <CGIButton
                  onClick={form.handleSubmit(CarregarDados)}
                  label="Pesquisar"
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIDataTableSimples
              DisableGetId
              Altura="300px"
              Colunas={GridColunas}
              Data={GridItens}
            />
            <Box my={5} borderWidth={2} w="100%">
              <Box p={2}>
                <Box p={2}>
                  {Legendas.map((e, index) => {
                    return (
                      <Tag
                        mx={2}
                        size={"md"}
                        key={index}
                        borderRadius="full"
                        variant="solid"
                        bgColor={e.cor}
                      >
                        <TagLabel color={"gray.100"}>{e.nomeStatus}</TagLabel>
                      </Tag>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
    //FINAL JSX
  }
);
