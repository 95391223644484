import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import { useFormContext } from "react-hook-form";
import LoadingContext from "../../../Context/LoadingContext";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import {
  ListarConfigCFOPAutoGridItensTypes,
  ListarConfigCFOPAutoTypes,
  ObjListarConfigCFOPAutoPadrao,
} from "../../../Types/Sistema/ConfigCFOPAuto/ConfigCFOPAutoTypes";
import {
  ModalBaseType,
  LegendaTypes,
} from "../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpConfigCFOPAuto } from "./Http";
import { HttpTipoNFEntrada } from "../Classificacao/TipoNFEntrada/Http";
import { AlterarConfigCFOPAuto } from "./_AlterarConfigCFOPAuto";

export function ListarConfigCFOPAuto() {
  const AlterarConfigCFOPAutoRef = useRef<ModalBaseType>();
  const VisualizarConfigCFOPAutoRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarConfigCFOPAutoTypes>({
    defaultValues: ObjListarConfigCFOPAutoPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarConfigCFOPAutos = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpConfigCFOPAuto().RetornarConfigCFOPAuto(
        DadosFiltros
      );
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarConfigCFOPAutoGridItensTypes) => {
      return await HttpConfigCFOPAuto().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpTipoNFEntrada()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Nova ConfigCFOPAuto",
      Permissao: "sistema/configcfopauto/incluir",
      OnClick: () => {
        AlterarConfigCFOPAutoRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Alterar",
      Permissao: "sistema/configcfopauto/alterar",
      OnClick: (e: ListarConfigCFOPAutoGridItensTypes) => {
        AlterarConfigCFOPAutoRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/configcfopauto/excluir",
      OnClick: (e: ListarConfigCFOPAutoGridItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Sim", cor: "green" },
    { nomeStatus: "Não", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarConfigCFOPAutoGridItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      att: {
        maxWidth: 80,
      },
    },
    {
      Campo: "nome",
      Titulo: "Nome",
      Tipo: "Texto",
      att: {
        minWidth: 200,
      },
    },
    {
      Campo: "nomeEmpresa",
      Titulo: "Empresa",
      Tipo: "Texto",
      att: {
        minWidth: 150,
      },
    },
    {
      Campo: "nomeNaturezaOperacao",
      Titulo: "Nat. Operação",
      Tipo: "Texto",
      att: {
        minWidth: 200,
      },
    },
    {
      Campo: "id",
      Titulo: "Resumo",
      Tipo: "Composta",
      att: {
        minWidth: 220,
      },
      Composta: [
        {
          valor: "nomeClasseProduto",
          titulo: "Classe",
          exibirLabel: true,
        },
        {
          valor: "nomeTipoProduto",
          titulo: "Tipo de Produto",
          exibirLabel: true,
        },
        {
          valor: "nomeGrupoProduto",
          titulo: "Grupo de Produto",
          exibirLabel: true,
        },
      ],
    },
    {
      Campo: "estado",
      Titulo: "Estado",
      Tipo: "Texto",
    },
    {
      Campo: "nomeConfigCFOP",
      Titulo: "Config. CFOP",
      Tipo: "Texto",
      att: {
        minWidth: 150,
      },
    },
    {
      Campo: "cfopId",
      Titulo: "CFOP",
      Tipo: "Texto",
    },
    {
      Campo: "ehContribuinte",
      Titulo: "Contribuinte",
      Tipo: "CheckBoxDisable",
      att: {
        maxWidth: 100,
      },
    },
    {
      Titulo: "CST",
      Campo: "cstId",
      Tipo: "Texto",
      att: {
        minWidth: 110,
      },
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir ConfigCFOPAuto"
          CarregarDados={RetornarConfigCFOPAutos}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarConfigCFOPAuto
          CarregarDados={methods.handleSubmit(RetornarConfigCFOPAutos)}
          ref={AlterarConfigCFOPAutoRef}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigCFOPAutoTypes>
            label="Empresa"
            id="Pesquisa.listaEmpresasId"
            Multipla
            Link="empresa"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigCFOPAutoTypes>
            label="Tipo Produto"
            id="Pesquisa.listaTipoProdutosId"
            Multipla
            Link="tipodeproduto"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigCFOPAutoTypes>
            label="Classe Produto"
            id="Pesquisa.listaClassesProdutosId"
            Multipla
            Link="classe"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigCFOPAutoTypes>
            label="Nat. Operação"
            id="Pesquisa.listaNaturezaDeOperacoesId"
            Multipla
            Link="naturezadeoperacao"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigCFOPAutoTypes>
            label="CFOP"
            id="Pesquisa.listaCFOPsId"
            Multipla
            Link="cfop-entrada-saida"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarConfigCFOPAutoTypes>
            label="Ativo"
            id="Pesquisa.ativoId"
            Link="simnaoambos"
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="sistema/configcfopauto/listar"
      Titulo="ConfigCFOPAuto"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarConfigCFOPAutos)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
