import { CGIHttp } from "../../../../Services/Axios";
import {
  FormTipoOrcamentoPedidoType,
  ListarTipoOrcamentoPedidoItensTypes,
  ListarPesquisaTipoOrcamentoPedidoTypes,
} from "../../../../Types/Sistema/Classificacao/TipoOrcamentoPedido/TipoOrcamentoPedidoTypes";

const url = "/classificacao";

export function HttpTipoOrcamentoPedido() {
  async function RetornarTipoOrcamentoPedidos(
    DadosFiltros: ListarPesquisaTipoOrcamentoPedidoTypes
  ) {
    return (
      await new CGIHttp().conn.post(
        url + "/obter-tiposorcamentopedido",
        DadosFiltros
      )
    ).data.data;
  }

  async function Listar() {
    return await (
      await new CGIHttp().conn.get(url + "/listar/161")
    ).data.data;
  }

  async function RetornarDadosTipoOrcamentoPedido(id: number) {
    return await new CGIHttp().conn.get(
      url + `/retornar-dados-tipoorcamentopedido/${id}`
    );
  }

  async function Incluir(Dados: FormTipoOrcamentoPedidoType) {
    return await new CGIHttp().conn.post(
      url + "/incluir-tipoorcamentopedido",
      Dados.Form
    );
  }
  async function Alterar(Dados: FormTipoOrcamentoPedidoType) {
    return await new CGIHttp().conn.post(
      url + "/alterar-tipoorcamentopedido",
      Dados.Form
    );
  }
  async function RetornarDadosExclusao(
    Dados: ListarTipoOrcamentoPedidoItensTypes
  ) {
    return await new CGIHttp().conn.get(
      url + "/excluir-tipoorcamentopedido/" + Dados.id
    );
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(
      url + "/excluir-tipoorcamentopedido",
      Dados
    );
  }
  return {
    RetornarTipoOrcamentoPedidos,
    RetornarDadosTipoOrcamentoPedido,
    RetornarDadosExclusao,
    Incluir,
    Listar,
    Alterar,
    Excluir,
  };
}
