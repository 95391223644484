import { AddIcon } from "@chakra-ui/icons";
import { useCallbackRef } from "@chakra-ui/react";
import { useState, useRef, useContext, useCallback, useEffect } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import {
  FormSugestaoPedidoCompraTypes,
  ListarSugestaoPedidoCompraItensTypes,
  ListarSugestaoPedidoCompraTypes,
  ObjListarSugestaoPedidoCompraPadrao,
} from "../../../Types/Almoxarifado/SugestaoPedidoCompra/SugestaoPedidoCompraTypes";
import { HttpSugestaoPedidoCompra } from "./Http";
import { AlterarSugestaoPedidoCompra } from "./_AlterarSugestaoPedidoCompra";
import { VisualizarSugestaoPedidoCompra } from "./_VisualizarSugestaoPedidoCompra";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";

export function ListarSugestaoPedidoCompra() {
  const AlterarSugestaoPedidoCompraRef = useRef<ModalBaseType>();
  const VisualizarSugestaoPedidoCompraRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarSugestaoPedidoCompraTypes>({
    defaultValues: ObjListarSugestaoPedidoCompraPadrao,
  });
  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });
  //Final Hooks

  //------------------------

  //Inicio Consts
  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Nova Sugestão Pedido de Compra",
      Permissao: "almoxarifado/sugestaopedidocompra/incluir",
      OnClick: () => {
        AlterarSugestaoPedidoCompraRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "almoxarifado/sugestaopedidocompra/visualizar",
      OnClick: (e: ListarSugestaoPedidoCompraItensTypes) => {
        VisualizarSugestaoPedidoCompraRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "almoxarifado/sugestaopedidocompra/excluir",
      OnClick: (e: ListarSugestaoPedidoCompraItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Incluído", cor: "green" },
    { nomeStatus: "Gerado", cor: "blue" },
    { nomeStatus: "Excluído", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarSugestaoPedidoCompraItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 90,
      },
    },
    {
      Campo: "dataCadastro",
      Titulo: "Data",
      Tipo: "Data",
    },
    {
      Campo: "identificacao",
      Titulo: "Identificação",
      Tipo: "Texto",
    },
    {
      Campo: "nomeFornecedor",
      Titulo: "Fornecedor",
      Tipo: "Texto",
      att: { minWidth: 220, wrapText: true },
    },
    {
      Campo: "estadoFornecedor",
      Titulo: "Origem",
      Tipo: "Texto",
      att: { maxWidth: 100 },
    },
    {
      Campo: "qtdItens",
      Titulo: "Qtd. Itens",
      Tipo: "Numero",
      att: { maxWidth: 85, wrapHeaderText: true },
    },
    {
      Campo: "vlrTotal",
      Titulo: "Valor",
      Tipo: "Moeda",
    },
    {
      Campo: "nomeTipoSugestao",
      Titulo: "Tipo Sugestão",
      Tipo: "Texto",
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarSugestaoPedidoCompraItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          titulo: "Id",
          valor: "id",
        },
        {
          titulo: "Identificação",
          valor: "identificacao",
        },
        {
          titulo: "Fornecedor",
          valor: "nomeFornecedor",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Sugestão Pedido de Compra"
          CarregarDados={RetornarSugestoesPedidoCompra}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarSugestaoPedidoCompra
          CarregarDados={methods.handleSubmit(RetornarSugestoesPedidoCompra)}
          ref={AlterarSugestaoPedidoCompraRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarSugestaoPedidoCompra
          CarregarDados={methods.handleSubmit(RetornarSugestoesPedidoCompra)}
          ref={VisualizarSugestaoPedidoCompraRef}
        />
      ),
    },
  ];

  //Final Consts

  //------------------------

  //Inicio Function
  async function RetornarSugestoesPedidoCompra() {
    setLoadingState(true);
    var DadosFiltro = methods.getValues("Pesquisa");
    var data = await HttpSugestaoPedidoCompra()
      .RetornarSugestoesPedidoCompra(DadosFiltro)
      .finally(() => {
        setLoadingState(false);
      });
    methods.setValue("Itens", data);
  }

  async function RetornarDadosExclusao(data: FormSugestaoPedidoCompraTypes) {
    return await HttpSugestaoPedidoCompra().RetornarDadosExclusao(
      data.Form.DadosRegistro
    );
  }
  async function Excluir(data: DeleteTypes) {
    await HttpSugestaoPedidoCompra()
      .Excluir(data)
      .then((e) => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  //Final Function

  //------------------------

  // Inicio JSX

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Filtros de Período" popover="Filtros de Período">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarSugestaoPedidoCompraTypes>
                id={"Pesquisa.dataInicialInclusao"}
                label="Data Cadastro - Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarSugestaoPedidoCompraTypes>
                id="Pesquisa.dataFinalInclusao"
                label="Data Cadastro - Final"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIComboSimples<ListarSugestaoPedidoCompraTypes>
            Multipla
            id="Pesquisa.listaEmpresasId"
            label="Empresa"
            Link="empresa"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarSugestaoPedidoCompraTypes>
            id="Pesquisa.valorDe"
            label="Valor - De"
            placeholder="Valor"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarSugestaoPedidoCompraTypes>
            id="Pesquisa.valorAte"
            label="Valor - Até"
            placeholder="Valor"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIComboAjax<ListarSugestaoPedidoCompraTypes>
            Multipla
            id="Pesquisa.listaFornecedoresId"
            label="Fornecedor"
            Link="fornecedor"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Tipo Sugestão" popover="Tipo Sugestão">
            <CGIBoxItem Col={2}>
              <CGICheckBox<ListarSugestaoPedidoCompraTypes>
                id="Pesquisa.ehNormal"
                label="Normal"
                tooltip="Normal"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGICheckBox<ListarSugestaoPedidoCompraTypes>
                id="Pesquisa.ehIndicacaoTodos"
                label="Indicação - Todos"
                tooltip="Indicação - Todos"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGICheckBox<ListarSugestaoPedidoCompraTypes>
                id="Pesquisa.ehIndicacaoManual"
                label="Indicação - Manual"
                tooltip="Indicação - Manual"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGICheckBox<ListarSugestaoPedidoCompraTypes>
                id="Pesquisa.ehIndicacaoPedidos"
                label="Indicação - Pedidos"
                tooltip="Indicação - Pedidos"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarSugestaoPedidoCompraTypes>
            Multipla
            id="Pesquisa.listaStatusId"
            label="Status"
            Link="statussugestaopedidocompra"
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="almoxarifado/sugestaopedidocompra/listar"
      tipoGET={{
        url: "SUGESTAOPEDIDOCOMPRA-LISTAR",
        setRetorno: (e) => {
          methods.setValue("Pesquisa", e);
        },
      }}
      Titulo="Sugestão Pedido de Compra"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarSugestoesPedidoCompra)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
