import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import {
  AlterarPrecoNasEmpresasItensTypes,
  AlterarPrecoNasEmpresasTypes,
} from "../../../Types/Cadastros/Produto/AlterarPrecoNasEmpresasType";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { HttpProduto } from "./Http";

type PropsAlterarPrecoNasEmpresasType = {
  CarregarDados: Function;
};

export const AlterarPrecoNasEmpresas = forwardRef(
  (props: PropsAlterarPrecoNasEmpresasType, ref) => {
    useImperativeHandle(ref, () => ({
      Abrir(pId: number) {
        form.setValue("Pesquisa.produtoId", pId);
        CGIModalRef.current?.Abrir();
        CarregarDados();
      },
    }));
    const form = useFormContext<AlterarPrecoNasEmpresasTypes>();
    const CGIModalRef = useRef<ModalBaseType>();
    const GridItens = useWatch({
      control: form.control,
      name: "Itens",
    });
    const GridColunas: Array<CGICol<AlterarPrecoNasEmpresasItensTypes>> = [
      {
        Campo: "selecionado",
        Titulo: "",
        Tipo: "CheckBox",
        att: {
          maxWidth: 60,
        },
      },
      {
        Campo: "nomeEmpresa",
        Titulo: "Empresa",
        Tipo: "Texto",
      },
      {
        Campo: "precoCusto",
        Titulo: "Preço Custo",
        Tipo: "Moeda",
        att: {
          editable: true,
        },
      },
      {
        Campo: "margemLucro",
        Titulo: "Margem Lucro",
        Tipo: "Moeda",
        att: {
          editable: true,
        },
      },
      {
        Campo: "precoVenda",
        Titulo: "Preço Venda",
        Tipo: "Moeda",
        att: {
          editable: true,
        },
      },
      {
        Campo: "precoPromocional",
        Titulo: "Preço Promocional",
        Tipo: "Moeda",
        att: {
          editable: true,
        },
      },
    ];
    async function CarregarDados() {
      const DadosFiltro = form.getValues("Pesquisa");
      var Dados = await HttpProduto().RetornarPrecoEmpresasParaAtualizar(
        DadosFiltro.produtoId
      );
      form.setValue("Itens", Dados);
    }

    const AlterarPrecos = async () => {
      var DadosGrid = form.getValues("Itens");
      await HttpProduto().AlterarPrecosNasEmpresas(DadosGrid);
    };

    const Confirmar = async (data: any, event: any) => {};

    return (
      <CGIModal
        id="sistema/produto/alterarprecoempresas"
        ref={CGIModalRef}
        Titulo="Alterar Preço Nas Empresas"
        TelaCheia={"6xl"}
        Confirmar={AlterarPrecos}
        CarregarDados={CarregarDados}
        Fechar={() => {}}
      >
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIResumo
              Expandir
              ResumoProdutoSimples
              ProdutoId="Pesquisa.produtoId"
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIDataTableSimples
              DisableGetId
              Altura="300px"
              Colunas={GridColunas}
              Data={GridItens}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
    //FINAL JSX
  }
);
