export const ObjBloquearClientesEmAtrasoPadrao: FormBloquearClientesEmAtrasoType =
  {
    form: {
      pesquisa: {
        id: 0,
        nomeCompleto: "",
        nomeFantasia: "",
        cpfcnpj: "",
        dataBloqueio: "",
        nomeUsuarioBloqueio: "",
        obs: "",
        nomeCliente: "",
        vencimentoMaisAntigo: "",
        saldoReceber: 0,
        documento: "",
        dataVencimento: "",
        motivo: "",
        clienteId: 0,
        diasAtraso: 0,
        totalEmAberto: 0,
        diasMaiorAtraso: 0,
        itemValido: false,
        selecionado: false,
        itens: [],
      },
      itens: [],
    },
  };
export type FormBloquearClientesEmAtrasoType = {
  form: {
    pesquisa: BloquearClientesEmAtrasoPesquisaType;
    itens: BloquearClientesEmAtrasoItensDetalhamentoType[];
  };
};

export type BloquearClientesEmAtrasoPesquisaType = {
  id: number;
  nomeCompleto: string;
  nomeFantasia: string;
  cpfcnpj: string;
  dataBloqueio: string;
  nomeUsuarioBloqueio: string;
  obs: string;
  motivo: string;
  documento: string;
  dataVencimento: string;
  saldoReceber: number;
  nomeCliente: string;
  clienteId: number;
  diasAtraso: number;
  totalEmAberto: number;
  diasMaiorAtraso: number;
  vencimentoMaisAntigo: string;
  itemValido: boolean;
  selecionado: boolean;
  itens: BloquearClientesEmAtrasoItensDetalhamentoType[];
};
export type BloquearClientesEmAtrasoItensDetalhamentoType = {
  id: number;
  documento: string;
  dataVencimento: string;
  saldoReceber: number;
  obs: string;
  diasAtraso: number;
};
export type BloquearClientesEmAtrasoItensPesquisaType = {
  selecionado: boolean;
  clienteId: number;
  nomeCliente: string;
  totalEmAberto: number;
  diasMaiorAtraso: number;
  vencimentoMaisAntigo: string;
  itemValido: boolean;
  id: number;
  documento: string;
  dataVencimento: string;
  saldoReceber: number;
  obs: string;
  diasAtraso: number;
};
