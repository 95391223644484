export const ObjAssinaturaEletronicaZapSign: FormAssinaturaEletronicaZapSignType =
  {
    Form: {
      DadosRegistro: {
        id: 0,
        nome: "",
        titulo: "",
        ativo: 0,
        token: "",
        link: "",
      },
    },
  };

export type FormAssinaturaEletronicaZapSignType = {
  Form: {
    DadosRegistro: AssinaturaEletronicaZapSignTypes;
  };
};

type AssinaturaEletronicaZapSignTypes = {
  titulo: string;
  nome: string;
  ativo: number;
  id: number;
  token: string;
  link: string;
};

export const ObjListarAssinaturaEletronicaZapSignPadrao: ListarAssinaturaEletronicaZapSignTypes =
  {
    Pesquisa: {
      titulo: "",
      nome: "",
      ativoId: 0,
      id: 0,
      token: "",
      link: "",
    },
    Itens: [],
  };

export type ListarAssinaturaEletronicaZapSignTypes = {
  Pesquisa: ListarPesquisaAssinaturaEletronicaZapSignTypes;
  Itens: ListarAssinaturaEletronicaZapSignItensTypes[];
};

export type ListarPesquisaAssinaturaEletronicaZapSignTypes = {
  titulo: string;
  nome: string;
  ativoId: number;
  id: number;
  token: string;
  link: string;
};

export type ListarAssinaturaEletronicaZapSignItensTypes = {
  id: number;
  ativo: boolean;
  nome: string;
  token: string;
  link: string;
};
