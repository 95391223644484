import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ListarRetornoNotaFiscalItensTypes,
  ListarRetornoNotaFiscalTypes,
  ObjListarRetornoNotaFiscalPadrao,
} from "../../../Types/Almoxarifado/RetornoNotaFiscal/RetornoNotaFiscalTypes";
import {
  ModalBaseType,
  LegendaTypes,
} from "../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpRetornoNotaFiscal } from "./Http";
import { AlterarRetornoNotaFiscal } from "./_AlterarRetornoNotaFiscal";
import { VisualizarRetornoNotaFiscal } from "./_VisualizarRetornoNotaFiscal";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";

export function ListarRetornoNotaFiscal() {
  const AlterarRetornoNotaFiscalRef = useRef<ModalBaseType>();
  const VisualizarRetornoNotaFiscalRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarRetornoNotaFiscalTypes>({
    defaultValues: ObjListarRetornoNotaFiscalPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarRetornoNotaFiscales = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpRetornoNotaFiscal().RetornarRetornoNotaFiscals(
        DadosFiltros
      );
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarRetornoNotaFiscalItensTypes) => {
      return await HttpRetornoNotaFiscal().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpRetornoNotaFiscal()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo RetornoNotaFiscal",
      Permissao: "almoxarifado/RetornoNotaFiscal/incluir",
      OnClick: () => {
        AlterarRetornoNotaFiscalRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    // {
    //   Nome: "Alterar",
    //   Permissao: "almoxarifado/RetornoNotaFiscal/alterar",
    //   OnClick: (e: ListarRetornoNotaFiscalItensTypes) => {
    //     AlterarRetornoNotaFiscalRef.current?.Alterar(e);
    //   },
    // },
    {
      Nome: "Excluir",
      Permissao: "almoxarifado/RetornoNotaFiscal/excluir",
      OnClick: (e: ListarRetornoNotaFiscalItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Aguardando", cor: "green" },
    { nomeStatus: "Excluída", cor: "red" },
    { nomeStatus: "Concluída", cor: "blue" },
  ];

  const GridColunas: Array<CGICol<ListarRetornoNotaFiscalItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "dataCadastro",
      Titulo: "Datas",
      Tipo: "Composta",
      att: { maxWidth: 220 },
      Composta: [
        {
          titulo: "Dt. Cadastro",
          valor: "dataCadastro",
          exibirLabel: true,
          negrito: true,
        },
        {
          titulo: "Dt. Limite",
          valor: "dataLimiteRetorno",
          exibirLabel: true,
          negrito: true,
        },
      ],
    },
    {
      Campo: "numeroNF",
      Titulo: "Informações Retorno",
      Tipo: "Texto",
      att: { maxWidth: 200 },
    },
    {
      Campo: "nomeColaborador",
      Titulo: "Colaborador",
      Tipo: "Texto",
    },
    {
      Campo: "nomeTipoNotaFiscalEntrada",
      Titulo: "Tipo NF Entrada",
      Tipo: "Texto",
      att: { maxWidth: 180 },
    },
    {
      Campo: "valorNotaFiscal",
      Titulo: "Valor",
      Tipo: "Moeda",
      att: { maxWidth: 120 },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarRetornoNotaFiscalItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir RetornoNotaFiscal"
          CarregarDados={RetornarRetornoNotaFiscales}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarRetornoNotaFiscal
          CarregarDados={methods.handleSubmit(RetornarRetornoNotaFiscales)}
          ref={AlterarRetornoNotaFiscalRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarRetornoNotaFiscal
          CarregarDados={methods.handleSubmit(RetornarRetornoNotaFiscales)}
          ref={VisualizarRetornoNotaFiscalRef}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Filtros de Período">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarRetornoNotaFiscalTypes>
                id="Pesquisa.dataCadastroInicial"
                label="Data Cadastro - Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarRetornoNotaFiscalTypes>
                id="Pesquisa.dataCadastroFinal"
                label="Data Cadastro - Final"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarRetornoNotaFiscalTypes>
                id="Pesquisa.dataInicialLimiteRetorno"
                label="Data Limite Retorno - Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarRetornoNotaFiscalTypes>
                id="Pesquisa.dataFinalLimiteRetorno"
                label="Data Limite Retorno - Final"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIBox>
            <CGIBoxItem Col={12}>
              <CGIComboSimples<ListarRetornoNotaFiscalTypes>
                id="Pesquisa.listaColaboradoresId"
                label="Colaborador"
                Link={"cliente"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIComboSimples<ListarRetornoNotaFiscalTypes>
                id="Pesquisa.listaStatusId"
                label="Status"
                Link={"statusNFSaida"}
                Multipla
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="almoxarifado/retornonotafiscal/listar"
      Titulo="Listar Retorno Nota Fiscal"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarRetornoNotaFiscales)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
