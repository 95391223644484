import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import {
  ListarEmpresaTypes,
  ObjListarEmpresaPadrao,
  ListarEmpresaItensTypes,
} from "../../../Types/Sistema/Empresa/EmpresaTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpEmpresa } from "./Http";
import { AlterarEmpresa } from "./_AlterarEmpresa";
import { VisualizarEmpresa } from "./_VisualizarEmpresa";

//Exempo de Pagina Padrao
//
// Hooks
// Metodos
// Contantes
// JSX 

/**
 * @Hooks
 * @Metodos
 * @Contantes
 * @JSX
 */
export function ListarEmpresa() {
  //Inicio Hooks
  const AlterarEmpresaRef = useRef<ModalBaseType>();
  const VisualizarEmpresaRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarEmpresaTypes>({
    defaultValues: ObjListarEmpresaPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarEmpresas = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpEmpresa().RetornarEmpresas(DadosFiltros);
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarEmpresaItensTypes) => {
      return await HttpEmpresa().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpEmpresa()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }
  //Final Hooks

  //------------------------

  //Inicio Consts
  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Nova Empresa",
      Permissao: "sistema/empresa/incluir",
      OnClick: () => {
        AlterarEmpresaRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "sistema/empresa/visualizar",
      OnClick: (e: ListarEmpresaItensTypes) => {
        VisualizarEmpresaRef.current?.Abrir(e.id);
      },
    },
    {
      Nome: "Alterar",
      Permissao: "sistema/empresa/alterar",
      OnClick: (e: ListarEmpresaItensTypes) => {
        AlterarEmpresaRef.current?.Alterar(e.id);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/empresa/excluir",
      OnClick: (e: ListarEmpresaItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e.id);
      },
    },
    // {
    //   Nome: "Menu",
    //   Permissao: "sistema/empresa/visualizar",
    //   OnClick: (e: ListarEmpresaItensTypes) => {
    //     VisualizarEmpresaRef.current?.Abrir(e.id);
    //   },
    //   SubMenus: [
    //     {
    //       Nome: "Visualizar",
    //       Permissao: "sistema/empresa/visualizar",
    //       OnClick: (e: ListarEmpresaItensTypes) => {
    //         VisualizarEmpresaRef.current?.Abrir(e.id);
    //       },
    //     },
    //   ],
    // },
  ];


  //
  const Legendas = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarEmpresaItensTypes>> = [
    {
      Campo: "ativo",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: [
        {
          nomeStatus: "true",
          cor: "green",
        },
        {
          nomeStatus: "false",
          cor: "red",
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 90,
      },
    },
    {
      Campo: "razaoSocial",
      Titulo: "Razão Social",
      Tipo: "Texto",
    },
    {
      Campo: "cnpj",
      Titulo: "CNPJ",
      Tipo: "Texto",
      att: {
        maxWidth: 180,
      },
    },
    {
      Campo: "dataVencimentoCertificado",
      Titulo: "Venc. Certificado",
      Tipo: "Data",
      att: {
        maxWidth: 160,
      },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: any) => MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          titulo: "Id",
          valor: "id",
        },
        {
          titulo: "Nome Exibição",
          valor: "nomeExibicao",
        },
      ],
    },
    {
      Campo: "cnpj",
      Titulo: "CNPJ",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          titulo: "CNPJ",
          valor: "cnpj",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Empresa"
          CarregarDados={RetornarEmpresas}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarEmpresa
          CarregarDados={methods.handleSubmit(RetornarEmpresas)}
          ref={AlterarEmpresaRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarEmpresa
          CarregarDados={methods.handleSubmit(RetornarEmpresas)}
          ref={VisualizarEmpresaRef}
        />
      ),
    },
  ];

  //Final Consts

  //------------------------

  //------------------------

  // Inicio JSX

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarEmpresaItensTypes>
            id={"razaoSocial"}
            label="Razão Social"
            placeholder="Razão Social"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarEmpresaItensTypes>
            id="nomeExibicao"
            label="Nome Exibição"
            placeholder="Nome Exibição"
            validadores={{
              maxLength: {
                message: "Maximo",
                value: 10,
              },
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarEmpresaItensTypes>
            Link={"simnaoambos"}
            label={"Ativo"}
            id={"ativo"}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="sistema/empresa/listar"
      tipoGET={{
        url: "EMPRESA-LISTAR",
        setRetorno: e => {
          methods.setValue("Pesquisa", e);
        },
      }}
      Titulo="Empresas"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarEmpresas)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
