export const ObjNFSaidaPadrao: FormNFSaidaType = {
  Form: {
    DadosRegistro: {
      id: 0,
      nfsaidaId: 0,
      empresaId: 0,
      ncm: "",
      percIPI: 0,
      ex: 0,
      numeroNF: 0,
      serie: 0,
      modelo: 0,
      statusId: 0,
      obs: "",
      nomeEmpresa: "",
      tecnologia: "",
      cnpj: "",
      nomeCliente: "",
    },
  },
};
export type NFSaidaTypes = {
  DadosRegistro: {
    id: number;
    nfsaidaId: number;
    empresaId: number;
    ncm: string;
    percIPI: number;
    ex: number;
    numeroNF: number;
    serie: number;
    modelo: number;
    statusId: number;
    obs: string;
    nomeEmpresa: string;
    tecnologia: string;
    cnpj: string;
    nomeCliente: string;
  };
};
export type FormNFSaidaType = {
  Form: NFSaidaTypes;
};

export const ObjListarNFSaidaPadrao: ListarNFSaidaTypes = {
  Pesquisa: {
    dataInicial: "",
    dataFinal: "",
    listaEmpresasId: "",
    listaColaboradoresId: "",
    listaTransportadorasId: "",
    listaRetornoId: "",
    listaStatusId: "",
    listaLocalEntregaId: "",
    listaNaturezaOperacaoId: "",
    numeroNF: "",
    listaTipoDocumentoEletronicoId: "",
    listaClassesId: "",
    listaEstadosId: "",
    ocultarDevolvidas: 0,
    canhotoPendente: 0,
    listaVendedoresId: "",
    listaCondicaoPagamentoId: "",
    entradaSaidaId: 0,
    listaProdutosId: "",
    codigoProduto: "",
    somenteNFFinanceiroControlado: false,
    tipoPeriodoData: "",
    listaFormaPagamentoId: "",
    notasConferidasId: 0,
    pedidoCliente: "",
    cFOP: "",
    listaMotoristasId: "",
    listaNucleoId: "",
    entregaPendenteId: 0,
    somenteNFVenda: false,
    nfsaidaId: 0,
  },
  Itens: [],
};

export type NFSaidaPesquisaGridTypes = {
  ncm?: string;
};

export type ListarNFSaidaTypes = {
  Pesquisa: ListarPesquisaNFSaidaTypes;
  Itens: ListarNFSaidaItensTypes[];
};

export type ListarPesquisaNFSaidaTypes = {
  dataInicial: string;
  dataFinal: string;
  listaEmpresasId: string;
  listaColaboradoresId: string;
  listaTransportadorasId: string;
  listaRetornoId: string;
  listaStatusId: string;
  listaLocalEntregaId: string;
  listaNaturezaOperacaoId: string;
  numeroNF: string;
  listaTipoDocumentoEletronicoId: string;
  listaClassesId: string;
  listaEstadosId: string;
  ocultarDevolvidas: number;
  canhotoPendente: number;
  listaVendedoresId: string;
  listaCondicaoPagamentoId: string;
  entradaSaidaId: number;
  listaProdutosId: string;
  codigoProduto: string;
  somenteNFFinanceiroControlado: boolean;
  tipoPeriodoData: string;
  listaFormaPagamentoId: string;
  notasConferidasId: number;
  pedidoCliente: string;
  cFOP: string;
  listaMotoristasId: string;
  listaNucleoId: string;
  entregaPendenteId: number;
  somenteNFVenda: boolean;
  nfsaidaId: number;
};

export type ListarNFSaidaItensTypes = {
  id: number;
  empresaId: number;
  numeroNF: number;
  dataCadastro: string;
  dataCadastroPedido: string;
  dataConferencia: string;
  dataSaida: string;
  nomeTipoDocumento: string;
  dataEmissao: string;
  dataAutorizacao: string;
  serie: number;
  nomeStatus: string;
  tipoDocFiscal: string;
  nomeEmpresa: string;
  nomeColaborador: string;
  nomeCliente: string;
  nomeFantasia: string;
  nomeTransportadora: string;
  nomeVendedor: string;
  nomeCondicaoPagamento: string;
  nomeClasse: string;
  nomeLoginUsuario: string;
  nomeRedespacho: string;
  numeroNFAlterado: boolean;
  quantidade: number;
  valorTotal: number;
  nomeLocalEntrega: string;
  nomeMotorista: string;
  nomeEquipamento: string;
  pedidoId: number | null;
  numeroPedido: number | null;
  identificacaoPedido: string;
  qtdItens: number;
  chave: string;
  naturezaOperacao: string;
  valorNF: number;
  nFeRetorno: string;
  devolvida: boolean;
  totalQtdItens: number;
  colaboradorId: number;
  nomeUsuario: string;
  modelo: string;
  pedidoCliente: string;
  canhotoPendente: boolean;
  quantidadeDiasAnterioresParaCanhotoPendente: number;
  dataInicialNFCanhotoPendente: string;
  mostrarAvisoCanhotoPendente: boolean;
  transfInterna: number | null;
  numeroNFDevolucao: string;
};
