import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import { CGITextArea } from "../../../Components/TextArea/CGITextArea";
import LoadingContext from "../../../Context/LoadingContext";
import {
  FormNFSaidaType,
  NFSaidaTypes,
} from "../../../Types/Faturamento/NFSaida/NFSaidaTypes";
import { HttpNFSaida } from "./Http";

type AlterarTecnologiaNFSaidaType = {
  CarregarDados: Function;
};

export const AlterarTecnologiaNFSaida = forwardRef(
  (props: AlterarTecnologiaNFSaidaType, ref) => {
    useImperativeHandle(ref, () => ({
      Abrir(pNFSaida: number) {
        form.setValue("Form.DadosRegistro.id", pNFSaida);
        CGIModalRef.current?.Abrir();
      },
      Alterar(pNFSaida: any) {
        CGIModalRef.current.Abrir();

        HttpNFSaida()
          .RetornarDadosNFSaida(pNFSaida.id)
          .then((e) => {
            form.reset(e);
          });
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<FormNFSaidaType>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    const Confirmar = async (data: FormNFSaidaType, event: any) => {
      setLoadingState(true);

      HttpNFSaida()
        .AlterarTecnologiaNFSaida(data)
        .then((data: any) => {
          ToastSucesso("Registro Alterado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
        })
        .catch((e) => {
          return;
        })
        .finally(() => {
          setLoadingState(false);
        });
    };

    return (
      <CGIModal
        id="faturamento/nfsaida/alterartecnologianfsaida"
        ref={CGIModalRef}
        Titulo="Alterar Tecnologia NF Saída"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIBox titulo="Dados da NF Saída" popover="Dados da NF Saída">
              <CGIBoxItem Col={3}>
                <CGIInput<FormNFSaidaType>
                  id="Form.DadosRegistro.numeroNF"
                  label="Nº NF"
                  placeholder="Nº NF"
                  att={{
                    isReadOnly: true,
                  }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIInput<FormNFSaidaType>
                  id="Form.DadosRegistro.serie"
                  label="Série"
                  placeholder="Série"
                  att={{
                    isReadOnly: true,
                  }}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={6}>
                <CGIInput<FormNFSaidaType>
                  id="Form.DadosRegistro.nomeEmpresa"
                  label="Empresa"
                  placeholder="Empresa"
                  att={{
                    isReadOnly: true,
                  }}
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<FormNFSaidaType>
              id="Form.DadosRegistro.tecnologia"
              label="Tecnologia"
              placeholder="Tecnologia"
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
  }
);
