import { Center } from "@chakra-ui/react";
import { forwardRef, useContext, useEffect, useImperativeHandle } from "react";
import { useWatch } from "react-hook-form";
import { CGIBox } from "../../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../../Components/Box/CGIBoxItens";
import { CGIComboAjax } from "../../../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../../../Components/Combos/CGIComboSimples";
import { CGIDatePicker } from "../../../../../Components/DatePicker/CGIDatePicker";
import { CGIRadioButton } from "../../../../../Components/RadioButton/CGIRadioButton";
import { PedidoLojaType } from "../../../../../Types/Vendas/Pedido/PedidoLojaType";
import PedLojaContext from "../_AlterarPedidoLoja";

export const AreaTipoPedido = forwardRef((props: {}, ref) => {
  useImperativeHandle(ref, () => ({}));
  const { form } = useContext(PedLojaContext);
  const ConfigPedLoja = useWatch({
    control: form.control,
    name: "config",
  });

  return (
    <CGIBox>
      <CGIBoxItem Col={12}>
        <CGIBox titulo="Tipo" popover="Tipo de Pedido">
          <CGIBoxItem Col={12}>
            <Center>
              <CGIRadioButton<PedidoLojaType>
                id={"DadosRegistro.paginaOrigem"}
                row={"row"}
                label={""}
                att={{
                  fontWeight: "bold",
                }}
                values={[
                  { label: "Pedido", valor: "P" },
                  { label: "Pedido com Troca", valor: "PT" },
                  { label: "Orçamento", valor: "O" },
                  { label: "Devolução", valor: "D" },
                ]}
              />
            </Center>
          </CGIBoxItem>
        </CGIBox>
      </CGIBoxItem>
      <CGIBoxItem Col={6}>
        <CGIComboSimples<PedidoLojaType>
          Link={"empresa"}
          label={"Empresa"}
          id={"DadosRegistro.empresaId"}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={6}>
        <CGIComboSimples<PedidoLojaType>
          Link={"vendedor"}
          label={"Vendedor"}
          id={"DadosRegistro.vendedorId"}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIComboSimples<PedidoLojaType>
          Link={"simnaoambos"}
          label={"Tabela de Preço"}
          id={"DadosRegistro.tabelaPrecosId"}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIComboSimples<PedidoLojaType>
          Link={"simnaoambos"}
          label={"Natureza de Operação"}
          id={"DadosRegistro.naturezaOperacaoId"}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIComboAjax<PedidoLojaType>
          Link={"representante"}
          label={"Representante"}
          id={"DadosRegistro.representanteId"}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIDatePicker<PedidoLojaType>
          att={{
            isDisabled: ConfigPedLoja.permitirInformarDataPedido,
          }}
          id={"DadosRegistro.dataCadastro"}
          label={"Data do Pedido"}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={4}>
        <CGIDatePicker<PedidoLojaType>
          id={"DadosRegistro.dataSaida"}
          label={"Data da saída"}
        />
      </CGIBoxItem>
    </CGIBox>
  );
});
