import { Box, Center, Text } from "@chakra-ui/react";
import { Temas } from "../../../Themes/Temas";

export function CGIFooter() {
  return (
    <Box h={"200px"} w={"100%"} bgColor={Temas.Primaria}>
      <Box>
        <Center>
          <Text fontWeight={"bold"} color={"#FFFF"}>
            HeadThink © Todos os Direitos Reservados - Suporte :
          </Text>
          <Text fontWeight={"bold"} color={"yellow"}>
            (22) 9-8876-6456 * Segunda até Sexta - Horário: 08:00 às 18:00hs -
            Exceto Feriados.*
          </Text>
        </Center>
      </Box>
    </Box>
  );
}
