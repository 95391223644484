import { Text } from "@chakra-ui/react";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { CGIAlertDialog } from "../../../Components/AlertDialog/CGIAlertDialog";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { FormContext } from "../../../Context/FormConxtext";
import { CGIHttp } from "../../../Services/Axios";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";

export const DefinirIncluida = forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    Abrir(OcultarCancelar = false) {
      CGIAlertDialogRef.current?.Abrir();
    },
    Fechar() {
      CGIAlertDialogRef.current?.Fechar();
    },
  }));

  const CGIAlertDialogRef = useRef<ModalBaseType>(null);

  async function Confirmar(e: any) {
    new CGIHttp().logout();
    CGIAlertDialogRef.current?.Fechar();
    window.location.reload();
  }

  return (
    <FormContext>
      <CGIAlertDialog
        ref={CGIAlertDialogRef}
        titulo={"Definir Incluida"}
        confirmar={(e: any) => {
          Confirmar(e);
        }}
      >
        <CGIBoxItem Col={12}>
          <Text>Definir NF Saida como Incluida?</Text>
        </CGIBoxItem>
      </CGIAlertDialog>
    </FormContext>
  );
});
