import { CGIHttp } from "../../../Services/Axios";
import {
  ListarPesquisaPortadorTypes,
  ListarPortadorGridItensTypes,
  ListarPortadorItensTypes,
  PortadorPesquisaGridTypes,
  PortadorTypes,
  PortadorVisualizarTypes,
} from "../../../Types/Sistema/Portador/PortadorTypes";
//import { PortadorGridTypes } from "../../../Types/Sistema/Portador/PortadorTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpPortador() {
  const url = "/portador";

  async function RetornarDadosGridPesquisa(
    dados: PortadorPesquisaGridTypes
  ): Promise<ListarPortadorGridItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-portador", dados)
      .then((e) => e.data.data);
  }

  async function RetornarPortadores(
    DadosFiltros: ListarPesquisaPortadorTypes
  ): Promise<ListarPortadorItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(url + "/obter-portador", DadosFiltros)
    ).data.data;
  }

  async function RetornarDadosPortador(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + `/alterar/${id}`)
    ).data.data) as PortadorTypes;
  }

  async function RetornarDadosExclusao(Dados: ListarPortadorGridItensTypes) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + Dados.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }
  async function RetornarDadosVisualizarPortador(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + "/visualizar/" + id)
    ).data.data) as PortadorVisualizarTypes;
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir/" + Dados.id);
  }
  async function Alterar(DadosPortador: PortadorTypes) {
    return await new CGIHttp().conn.post(url + "/alterar", DadosPortador);
  }
  async function Incluir(DadosPortador: PortadorTypes) {
    return await new CGIHttp().conn.post(url + "/incluir", DadosPortador);
  }
  return {
    RetornarPortadores,
    RetornarDadosGridPesquisa,
    RetornarDadosExclusao,
    RetornarDadosVisualizarPortador,
    RetornarDadosPortador,
    Excluir,
    Alterar,
    Incluir,
  };
}
