import { CGIHttp } from "../../../Services/Axios";
import {
  FormConfigServicoType,
  ListarPesquisaConfigServicoTypes,
  ListarConfigServicoItensTypes,
  ConfigServicoPesquisaGridTypes,
  ConfigServicoTypes,
  ConfigServicoVisualizarTypes,
} from "../../../Types/Sistema/ConfigServico/ConfigServicoTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpConfigServico() {
  const url = "/ConfigServico";

  async function RetornarDadosGridPesquisa(
    dados: ConfigServicoPesquisaGridTypes
  ): Promise<ListarConfigServicoItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-configservico", dados)
      .then((e) => e.data.data);
  }

  async function RetornarConfigServicos(
    DadosFiltros: ListarPesquisaConfigServicoTypes
  ): Promise<ListarConfigServicoItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(url + "/obter-configservico", DadosFiltros)
    ).data.data;
  }

  async function RetornarDadosConfigServico(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + `/alterar/${id}`)
    ).data.data) as FormConfigServicoType;
  }

  async function RetornarDadosExclusao(Dados: ListarConfigServicoItensTypes) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + Dados.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }
  async function RetornarDadosVisualizarConfigServico(
    DadosConfigServico: FormConfigServicoType
  ) {
    return (await (
      await new CGIHttp().conn.get(
        url + "/visualizar/" + DadosConfigServico.Form
      )
    ).data.data) as ConfigServicoTypes;
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir/" + Dados.id);
  }
  async function Alterar(DadosConfigServico: FormConfigServicoType) {
    return await new CGIHttp().conn.post(
      url + "/alterar",
      DadosConfigServico.Form
    );
  }
  async function Visualizar(DadosConfigServico: FormConfigServicoType) {
    return await new CGIHttp().conn.post(
      url + "/visualizar",
      DadosConfigServico.Form
    );
  }
  async function Incluir(DadosConfigServico: FormConfigServicoType) {
    return await new CGIHttp().conn.post(
      url + "/incluir",
      DadosConfigServico.Form
    );
  }
  return {
    RetornarConfigServicos,
    RetornarDadosGridPesquisa,
    RetornarDadosExclusao,
    RetornarDadosVisualizarConfigServico,
    RetornarDadosConfigServico,
    Visualizar,
    Excluir,
    Alterar,
    Incluir,
  };
}
