import { CGIHttp } from "../../../Services/Axios";
import {
  ListarPesquisaRelacaoEntregaTypes,
  ListarRelacaoEntregaItensTypes,
  RelacaoEntregaItensTypes,
  RelacaoEntregaPesquisaGridTypes,
} from "../../../Types/Faturamento/RelacaoEntrega/RelacaoEntrega";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpRelacaoEntrega() {
  const url = "/relacaoentrega";

  async function RetornarDadosGridPesquisa(
    dados: RelacaoEntregaPesquisaGridTypes
  ): Promise<RelacaoEntregaItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-pedidos-para-relacao-entrega", dados)
      .then((e) => e.data.data);
  }
  async function RetornarRelacoesEntrega(
    DadosFiltros: ListarPesquisaRelacaoEntregaTypes
  ): Promise<ListarRelacaoEntregaItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(
        url + "/obter-relacao-entrega",
        DadosFiltros
      )
    ).data.data;
  }
  async function RetornarDadosExclusao(
    DadosEmpresa: ListarRelacaoEntregaItensTypes
  ) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + DadosEmpresa.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }
  return {
    RetornarDadosGridPesquisa,
    RetornarRelacoesEntrega,
    RetornarDadosExclusao,
  };
}
