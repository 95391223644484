import { Box, Button, Center, forwardRef, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useImperativeHandle, useRef, useState } from "react";
import { CGIDataTableSimples } from "../DataTable/CGIDataTableSimples";
import { CGICol } from "../DataTable/DataTable/DataTableTypes";
import { CGIModal } from "../Modal/CGIModal";
import { CGITimeLine, CGITimeLineItensTypes } from "../TimeLine/CGITimeLine";
import { CGIIncluirHistorico } from "./CGIincluirHistorico";
import { HttpHistorico } from "./HttpHistorico";

type CGIHistoricoTypes = {
  link: string;
  origem: string;
  titulo: string;
  tipo: "Tabela" | "TimeLine";
};
/**
 * @Componente CGIHistorico - Historico Principal do Sistema
 * @Ref  CGIHistorico
 */
export const CGIHistorico = forwardRef((props: CGIHistoricoTypes, ref) => {
  const CGIModalRef = useRef() as any;
  const CGIIncluirNovoHistorico = useRef() as any;

  const [Dados, setDados] = useState<Array<CGITimeLineItensTypes>>([]);
  const [id, setId] = useState(0);
  const GridHistorico: Array<CGICol<any>> = [
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        hide: true,
      },
    },
    {
      Campo: "dataCadastro",
      Titulo: "Data Cadastro",
      Tipo: "DataHora",
      att: {
        maxWidth: 150,
      },
    },
    {
      Campo: "nomeUsuario",
      Titulo: "Nome",
      Tipo: "Texto",
      att: {
        maxWidth: 150,
      },
    },
    {
      Campo: "titulo",
      Titulo: "Titulo",
      Tipo: "Texto",
      att: {
        maxWidth: 150,
      },
    },
    {
      Campo: "obs",
      Titulo: "obs",
      Tipo: "Texto",
      att: {
        autoHeight: true,
      },
    },
  ];
  useImperativeHandle(ref, () => ({
    async Abrir(e: any) {
      var Dados = await HttpHistorico().RetornarDados(props.origem, e.id);
      setId(e.id);
      setDados(Dados);
      CGIModalRef.current.Abrir();
    },
  }));

  const IncluirNovoHistorico = async () => {
    CGIIncluirNovoHistorico?.current.Abrir();
  };

  return (
    <CGIModal
      ref={CGIModalRef}
      id={props.link}
      Titulo={`${props.titulo} (${Dados.length})`}
      Confirmar={() => {}}
      TelaCheia={"6xl"}
      ocultarConfirmar
      ocultarCancelar
    >
      <Box>
        <Button onClick={IncluirNovoHistorico} m={2} colorScheme={"green"}>
          Adicionar Novo Historico
        </Button>
      </Box>
      {Dados.length === 0 ? (
        <Box borderWidth={1}>
          <Center>
            <Text fontWeight={"bold"}>Sem Históricos</Text>
          </Center>
        </Box>
      ) : props.tipo === "TimeLine" ? (
        <CGITimeLine Itens={Dados} />
      ) : (
        <CGIDataTableSimples Colunas={GridHistorico} Data={Dados} />
      )}
      <CGIIncluirHistorico
        registroId={id}
        origem={props.origem}
        ref={CGIIncluirNovoHistorico}
      />
    </CGIModal>
  );
});
