import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import { OrdemProducaoVisualizarTypes } from "../../../Types/Producao/OrdemProducao/OrdemProducao";
import { HttpOrdemProducao } from "./Http";

type OrdemProducaoVisualizarVisualizarTypes = {
  CarregarDados: Function;
};
export const VisualizarOrdemProducao = forwardRef(
  (props: OrdemProducaoVisualizarVisualizarTypes, ref) => {
    useImperativeHandle(ref, () => ({
      Abrir(pOrdemProducaoId: number) {
        CGIModalRef.current?.Abrir();
        CarregarDados(pOrdemProducaoId);
      },
    }));

    const CGIModalRef = useRef<ModalBaseType>(null);
    const { reset } = useFormContext<OrdemProducaoVisualizarTypes>();

    async function CarregarDados(id: number) {
      var data = await HttpOrdemProducao().RetornarDadosVisualizarOrdemProducao(
        id
      );
      reset(data);
    }

    return (
      <CGIModal
        id="sistema/ordemproducao/visualizar"
        ref={CGIModalRef}
        Titulo="Visualizar OrdemProducao"
        TelaCheia={"6xl"}
        Confirmar={() => {}}
        Fechar={() => {}}
        CarregarDados={CarregarDados}
        ocultarCancelar={true}
        ocultarConfirmar={true}
      >
        <CGIBox>
          <CGIBoxItem Col={4}>
            <CGIInput<OrdemProducaoVisualizarTypes>
              id="id"
              label="Id"
              placeholder="Id"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<OrdemProducaoVisualizarTypes>
              id="nomeUsuario"
              label="Usuário"
              placeholder=""
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<OrdemProducaoVisualizarTypes>
              id="descricaoProduto"
              label="Produto"
              placeholder=""
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<OrdemProducaoVisualizarTypes>
              id="nomeEmpresa"
              label="Empresa"
              placeholder=""
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<OrdemProducaoVisualizarTypes>
              id="obs"
              label="Observação"
              placeholder=""
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<OrdemProducaoVisualizarTypes>
              id="labelCampoRefugo1"
              label="REF. TC"
              placeholder="0"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<OrdemProducaoVisualizarTypes>
              id="labelCampoRefugo2"
              label="REF. TINT"
              placeholder="0"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<OrdemProducaoVisualizarTypes>
              id="labelCampoRefugo3"
              label="REF. METROS"
              placeholder="0"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<OrdemProducaoVisualizarTypes>
              id="labelCampoRefugo4"
              label="REF. KG"
              placeholder="0"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<OrdemProducaoVisualizarTypes>
              id="dataPrevEntrega"
              label="Previsão de Entrega"
              placeholder=""
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<OrdemProducaoVisualizarTypes>
              id="qtdParaProducao"
              label="Quantidade para Produção"
              placeholder="0"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<OrdemProducaoVisualizarTypes>
              id="saldoParaProducao"
              label="Saldo da Produção"
              placeholder="0"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<OrdemProducaoVisualizarTypes>
              id="qtdPerda"
              label="Quantidade de Perda"
              placeholder="0"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<OrdemProducaoVisualizarTypes>
              id="qtdJaProduzida"
              label="Quantidade Já Produzida"
              placeholder="0"
              att={{ isReadOnly: true }}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
  }
);
