import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { FormContext } from "../../Context/FormConxtext";
import { CGIHttp } from "../../Services/Axios";
import { CGIBoxItem } from "../Box/CGIBoxItens";
import { CGIInput } from "../Input/CGIInput";
import { CGIAlertDialog } from "./CGIAlertDialog";

export const CGITrocarSenha = forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    Abrir(OcultarCancelar = false) {
      setOcultarCancelar(OcultarCancelar);
      CGIAlertDialogRef.current.Abrir();
    },
    Fechar() {
      CGIAlertDialogRef.current.Fechar();
    },
  }));

  const CGIAlertDialogRef = useRef() as any;
  const [OcultarCancelar, setOcultarCancelar] = useState(false);

  async function Confirmar(e: any) {
    new CGIHttp().logout();
    CGIAlertDialogRef.current.Fechar();
    window.location.reload();
  }

  return (
    <FormContext>
      <CGIAlertDialog
        ref={CGIAlertDialogRef}
        titulo={"Trocar Senha"}
        confirmar={(e: any) => {
          Confirmar(e);
        }}
        ocultarCancelar={OcultarCancelar}
      >
        <CGIBoxItem Col={12}>
          <CGIInput
            att={{ type: "password" }}
            id={"senhaatual"}
            placeholder={"Senha Atual"}
            label="Senha Atual"
            validadores={{ required: "Campo obrigatório" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIInput
            att={{ type: "password" }}
            id={"novasenha"}
            placeholder={"Nova Senha"}
            label="Nova Senha"
            validadores={{ required: "Campo obrigatório" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIInput
            att={{ type: "password" }}
            id={"confirmarnovasenha"}
            placeholder={"Confirmar Nova Senha"}
            label="Confirmar Nova Senha"
            validadores={{ required: "Campo obrigatório" }}
          />
        </CGIBoxItem>
      </CGIAlertDialog>
    </FormContext>
  );
});
