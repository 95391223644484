import { AxiosInstance } from "axios";
import { CGIHttp } from "../../Services/Axios";
import { CGITableResumoDadosTypes } from "./CGITableResumo";

const url = "/home/obter-tabela-resumo";

export function HttpCGITableResumo() {
  async function RetornarDados(Link: string) {
    let Obj = await new CGIHttp().conn.get(url + "/" + Link);

    return Obj?.data.data as CGITableResumoDadosTypes;
  }
  return {
    RetornarDados,
  };
}
