export const ObjPadrao: FormColaboradorType = {
  Form: {
    DadosRegistro: {
      id: 0,
      nomeCompleto: "",
      nomeFantasia: "",
      fisicaJuridicaId: "",
      cpfcnpj: "",
      rGInscEstadual: "",
      orgaoEmissor: "",
      ativoId: 0,
      contICMS: false,
      inss: "",
      contato: "",
      codigoSuframa: "",
      telefone: "",
      telefone2: "",
      telefone3: "",
      cep: "",
      endereco: "",
      numero: "",
      complemento: "",
      cidade: "",
      pontoReferencia: "",
      emailBoleto: "",
      emailPrincipal: "",
      emailNFe: "",
      grupoColaboradores: "",
      diasBloqueioAtraso: "",
      listaGruposEconomicosId: "",
      obs: "",
      dadosBancarios: "",
      codigoContato: 0,
      nomeContato: "",
      emailContato: "",
      listaTiposContatoId: "",
      telefoneContato: "",
      cargoSetor: "",
      obsContato: "",
      enviarNFeEmail: false,
      listaEmpresasId: "",
      listaVendedoresId: "",
      ehCliente: false,
      ehFuncionario: false,
      ehFornecedorProdutos: false,
      ehFornecedor: false,
      ehVendedor: false,
      ehTransportadora: false,
      ehPosto: false,
      ehSeguradora: false,
      ehRepresentante: false,
      ehRespFinanceiro: false,
      ehPaciente: false,
      bairro: "",
      numeroDiasSemComprarCliente: 0,
      listaCidadesId: "",
      listaEstadosId: "",
      listaPracasId: "",
      listaRotasId: "",
      listaTiposClienteId: "",
      entregaEspecialId: 0,
      listaTiposTributacaoId: "",
      codigoExterno: "",
      exibirFornecedoresCriticos: false,
      listaLimitesCreditoClienteId: "",
      limiteDisponivelCliente: "",
      listaCondicoesPagamentoClienteId: "",
      listaNaturezasOperacaoClienteId: "",
      listaEmpresasFaturamentoClienteId: "",
      listaTabelasPrecoClienteId: "",
      listaConveniosPortadorClienteId: "",
      obsVendaCliente: "",
      listaVendedoresClienteId: "",
      cnaeCliente: "",
      inscricaoMunicipalCliente: "",
      listaPracasClienteId: "",
      listaRotasClienteId: "",
      nomeTipoCliente: "",
      usuarioBloqueioDesbloqueioClienteId: "",
      dataBloqueioDesbloqueioCliente: "",
      motivoBloqueioCliente: "",
      faturamentoBloqueadoCliente: false,
      obrigarNumeroItemPedidoVendaCliente: false,
      gerarPedidoSaldoCliente: false,
      dataConsultaCreditoCliente: "",
      obsConsultaCreditoCliente: "",
      obsNfVendaCliente: "",
      descontoCliente: 0,
      valorMinimoPedidoVendaCliente: 0,
      bloqueioFixoVendaCliente: false,
      entregaEspecialCliente: false,
      emailNotificacaoPedidosCliente: "",
      listaRepresentantesClienteId: "",
      dataConsultaSPCCliente: "",
      listaRamosAtividadeCliente: "",
      permitirVendaAPrazoCliente: false,
      gerarCreditoCliente: false,
      obsSPCCliente: "",
      dadosCompradorCliente: "",
      sexoCliente: "",
      dataNascimentoCliente: "",
      diaFaturamentoProdutoInicialCliente: "",
      diaFaturamentoProdutoFinalCliente: "",
      diaFaturamentoServicoInicialCliente: "",
      diaFaturamentoServicoFinalCliente: "",
      faturamentoSegundaCliente: false,
      faturamentoTercaCliente: false,
      faturamentoQuartaCliente: false,
      faturamentoQuintaCliente: false,
      faturamentoSextaCliente: false,
      faturamentoSabadoCliente: false,
      faturamentoDomingoCliente: false,
      valorHoraFuncionario: 0,
      listaPerfisFuncionarioId: "",
      dataAdmissaoFuncionario: "",
      listaCargosFuncionarioId: "",
      numeroRegistroFuncionario: 0,
      cnhFuncionario: "",
      categoriaCNHFuncionario: "",
      dataValidadeCNHFuncionario: "",
      numeroPISFuncionario: "",
      dataNascimentoFuncionario: "",
      carteiraDeTrabalhoFuncionario: "",
      serieCarteiraDeTrabalhoFuncionario: "",
      salarioFuncionario: 0,
      listaTiposSanguineoFuncionario: "",
      exameToxicologicoFuncionario: "",
      dataEmissaoLaudoFuncionario: "",
      dataEmissaoRelatorioFuncionario: "",
      listaLimitesCreditoFornecedorId: "",
      limiteDisponivelFornecedor: "",
      listaCondicoesPagamentoFornecedorId: "",
      listaPlanosDeContasFornecedorId: "",
      obsFornecedor: "",
      fornecedorCritico: false,
      valorMinimoPedidoCompraFornecedor: 0,
      listaTiposFretePadraoFornecedorId: "",
      porcentagemFreteFornecedor: 0,
      listaTiposNotaEntradaFornecedor: "",
      anttFornecedor: "",
      emailPedidoCompraFornecedor: "",
      crcFornecedor: "",
      ehFabricanteFornecedor: false,
      isentoIPIComprasFornecedor: false,
      gerarPedVendaConsumoPedLocacaoRespFinanceiro: false,
      gerarFaturaLocacaoRespFinanceiro: false,
      gerarNotaFiscalRespFinanceiro: false,
      diaFaturamentoRespFinanceiro: 0,
      listaTiposCobrancaRespFinanceiroId: "",
      listaEmpresasRespFinanceiroId: "",
      listaCondicoesPagamentoRespFinanceiroId: "",
      listaVendedoresRespFinanceiroId: "",
      listaNaturezasOperacaoRespFinanceiroId: "",
      listaTiposFreteRespFinanceiroId: "",
      listaTabelasPrecoRespFinanceiroId: "",
      ehPostoConveniado: false,
      permitirDuplicidadeAbastecimento: false,
      calcularComissaoVendedor: false,
      porcentagemComissaoServicosVendedor: 0,
      listaCodigosRelatorioPedVendaVendedor: "",
      codigoAutorizacaoVendedor: "",
      metaAnualVendedor: 0,
      obsTransportadora: "",
      numeroApoliceSeguradora: "",
    },
    Itens: [],
  },
};

export const ObjListarColaboradorPadrao: ListarColaboradorTypes = {
  Pesquisa: {
    nomeCompleto: "",
    nomeFantasia: "",
    fisicaJuridicaId: "",
    cpfcnpj: "",
    rGInscEstadual: "",
    orgaoEmissor: "",
    ativoId: 0,
    contICMS: false,
    inss: "",
    contato: "",
    codigoSuframa: "",
    telefone: "",
    telefone2: "",
    telefone3: "",
    cep: "",
    endereco: "",
    numero: "",
    complemento: "",
    cidade: "",
    pontoReferencia: "",
    listaEmpresasId: "",
    listaVendedoresId: "",
    ehCliente: false,
    ehFuncionario: false,
    ehFornecedorProdutos: false,
    ehFornecedor: false,
    ehVendedor: false,
    ehTransportadora: false,
    ehPosto: false,
    ehSeguradora: false,
    ehRepresentante: false,
    ehRespFinanceiro: false,
    ehPaciente: false,
    bairro: "",
    numeroDiasSemComprarCliente: 0,
    listaCidadesId: "",
    listaEstadosId: "",
    listaPracasId: "",
    listaRotasId: "",
    listaTiposClienteId: "",
    listaGruposEconomicosId: "",
    dataNascimentoInicial: "",
    dataNascimentoFinal: "",
    entregaEspecialId: 0,
    listaTiposTributacaoId: "",
    codigoExterno: "",
    exibirFornecedoresCriticos: false,
    exibirApenasClientesBloqueados: false,
  },
  Itens: [],
};

export type FormColaboradorType = {
  Form: {
    DadosRegistro: ColaboradorTypes;
    Itens: ColaboradorItensTypes[];
  };
};

export type ColaboradorTypes = {
  id: number;
  nomeCompleto: string;
  nomeFantasia: string;
  fisicaJuridicaId: string;
  cpfcnpj: string;
  rGInscEstadual: string;
  orgaoEmissor: string;
  ativoId: number | null;
  contICMS: boolean;
  inss: string;
  contato: string;
  codigoSuframa: string;
  telefone: string;
  telefone2: string;
  telefone3: string;
  cep: string;
  endereco: string;
  numero: string;
  complemento: string;
  cidade: string;
  pontoReferencia: string;
  emailBoleto: string;
  emailPrincipal: string;
  emailNFe: string;
  grupoColaboradores: string;
  diasBloqueioAtraso: string;
  listaGruposEconomicosId: string;
  obs: string;
  dadosBancarios: string;
  codigoContato: number;
  nomeContato: string;
  emailContato: string;
  listaTiposContatoId: string;
  telefoneContato: string;
  cargoSetor: string;
  obsContato: string;
  enviarNFeEmail: boolean;
  listaEmpresasId: string;
  listaVendedoresId: string;
  ehCliente: boolean;
  ehFuncionario: boolean;
  ehFornecedorProdutos: boolean;
  ehFornecedor: boolean;
  ehVendedor: boolean;
  ehTransportadora: boolean;
  ehPosto: boolean;
  ehSeguradora: boolean;
  ehRepresentante: boolean;
  ehRespFinanceiro: boolean;
  ehPaciente: boolean;
  bairro: string;
  numeroDiasSemComprarCliente: number;
  listaCidadesId: string;
  listaEstadosId: string;
  listaPracasId: string;
  listaRotasId: string;
  listaTiposClienteId: string;
  entregaEspecialId: number | null;
  listaTiposTributacaoId: string;
  codigoExterno: string;
  exibirFornecedoresCriticos: boolean;
  listaLimitesCreditoClienteId: string;
  limiteDisponivelCliente: string;
  listaCondicoesPagamentoClienteId: string;
  listaNaturezasOperacaoClienteId: string;
  listaEmpresasFaturamentoClienteId: string;
  listaTabelasPrecoClienteId: string;
  listaConveniosPortadorClienteId: string;
  obsVendaCliente: string;
  listaVendedoresClienteId: string;
  cnaeCliente: string;
  inscricaoMunicipalCliente: string;
  listaPracasClienteId: string;
  listaRotasClienteId: string;
  nomeTipoCliente: string;
  usuarioBloqueioDesbloqueioClienteId: string;
  dataBloqueioDesbloqueioCliente: string;
  motivoBloqueioCliente: string;
  faturamentoBloqueadoCliente: boolean;
  obrigarNumeroItemPedidoVendaCliente: boolean;
  gerarPedidoSaldoCliente: boolean;
  dataConsultaCreditoCliente: string;
  obsConsultaCreditoCliente: string;
  obsNfVendaCliente: string;
  descontoCliente: number;
  valorMinimoPedidoVendaCliente: number;
  bloqueioFixoVendaCliente: boolean;
  entregaEspecialCliente: boolean;
  emailNotificacaoPedidosCliente: string;
  listaRepresentantesClienteId: string;
  dataConsultaSPCCliente: string;
  listaRamosAtividadeCliente: string;
  permitirVendaAPrazoCliente: boolean;
  gerarCreditoCliente: boolean;
  obsSPCCliente: string;
  dadosCompradorCliente: string;
  sexoCliente: string;
  dataNascimentoCliente: string;
  diaFaturamentoProdutoInicialCliente: string;
  diaFaturamentoProdutoFinalCliente: string;
  diaFaturamentoServicoInicialCliente: string;
  diaFaturamentoServicoFinalCliente: string;
  faturamentoSegundaCliente: boolean;
  faturamentoTercaCliente: boolean;
  faturamentoQuartaCliente: boolean;
  faturamentoQuintaCliente: boolean;
  faturamentoSextaCliente: boolean;
  faturamentoSabadoCliente: boolean;
  faturamentoDomingoCliente: boolean;
  valorHoraFuncionario: number;
  listaPerfisFuncionarioId: string;
  dataAdmissaoFuncionario: string;
  listaCargosFuncionarioId: string;
  numeroRegistroFuncionario: number;
  cnhFuncionario: string;
  categoriaCNHFuncionario: string;
  dataValidadeCNHFuncionario: string;
  numeroPISFuncionario: string;
  dataNascimentoFuncionario: string;
  carteiraDeTrabalhoFuncionario: string;
  serieCarteiraDeTrabalhoFuncionario: string;
  salarioFuncionario: number;
  listaTiposSanguineoFuncionario: string;
  exameToxicologicoFuncionario: string;
  dataEmissaoLaudoFuncionario: string;
  dataEmissaoRelatorioFuncionario: string;
  listaLimitesCreditoFornecedorId: string;
  limiteDisponivelFornecedor: string;
  listaCondicoesPagamentoFornecedorId: string;
  listaPlanosDeContasFornecedorId: string;
  obsFornecedor: string;
  fornecedorCritico: boolean;
  valorMinimoPedidoCompraFornecedor: number;
  listaTiposFretePadraoFornecedorId: string;
  porcentagemFreteFornecedor: number;
  listaTiposNotaEntradaFornecedor: string;
  anttFornecedor: string;
  emailPedidoCompraFornecedor: string;
  crcFornecedor: string;
  ehFabricanteFornecedor: boolean;
  isentoIPIComprasFornecedor: boolean;
  gerarPedVendaConsumoPedLocacaoRespFinanceiro: boolean;
  gerarFaturaLocacaoRespFinanceiro: boolean;
  gerarNotaFiscalRespFinanceiro: boolean;
  diaFaturamentoRespFinanceiro: number;
  listaTiposCobrancaRespFinanceiroId: string;
  listaEmpresasRespFinanceiroId: string;
  listaCondicoesPagamentoRespFinanceiroId: string;
  listaVendedoresRespFinanceiroId: string;
  listaNaturezasOperacaoRespFinanceiroId: string;
  listaTiposFreteRespFinanceiroId: string;
  listaTabelasPrecoRespFinanceiroId: string;
  ehPostoConveniado: boolean;
  permitirDuplicidadeAbastecimento: boolean;
  calcularComissaoVendedor: boolean;
  porcentagemComissaoServicosVendedor: number;
  listaCodigosRelatorioPedVendaVendedor: string;
  codigoAutorizacaoVendedor: string;
  metaAnualVendedor: number;
  obsTransportadora: string;
  numeroApoliceSeguradora: string;
};

export type ColaboradorVisualizarTypes = {
  id: number;
  nomeCompleto: string;
  nomeFantasia: string;
  cpfcnpj: string;
  nomeTipoTributacao: string;
  nomeEmpresa: string;
};

export type ColaboradorItensTypes = {};

export type ColaboradorPesquisaGridTypes = {};

export type ListarColaboradorTypes = {
  Pesquisa: ListarPesquisaColaboradorTypes;
  Itens: ListarColaboradorItensTypes[];
};

export type ListarPesquisaColaboradorTypes = {
  nomeCompleto: string;
  nomeFantasia: string;
  fisicaJuridicaId: string;
  cpfcnpj: string;
  orgaoEmissor: string;
  rGInscEstadual: string;
  ativoId: number;
  contICMS: boolean;
  inss: string;
  contato: string;
  codigoSuframa: string;
  telefone: string;
  telefone2: string;
  telefone3: string;
  cep: string;
  endereco: string;
  numero: string;
  complemento: string;
  cidade: string;
  pontoReferencia: string;
  listaEmpresasId: string;
  listaVendedoresId: string;
  ehCliente: boolean;
  ehFuncionario: boolean;
  ehFornecedorProdutos: boolean;
  ehFornecedor: boolean;
  ehVendedor: boolean;
  ehTransportadora: boolean;
  ehPosto: boolean;
  ehSeguradora: boolean;
  ehRepresentante: boolean;
  ehRespFinanceiro: boolean;
  ehPaciente: boolean;
  bairro: string;
  numeroDiasSemComprarCliente: number;
  listaCidadesId: string;
  listaEstadosId: string;
  listaPracasId: string;
  listaRotasId: string;
  listaTiposClienteId: string;
  listaGruposEconomicosId: string;
  dataNascimentoInicial: string;
  dataNascimentoFinal: string;
  entregaEspecialId: number;
  listaTiposTributacaoId: string;
  codigoExterno: string;
  exibirFornecedoresCriticos: boolean;
  exibirApenasClientesBloqueados: boolean;
};

export type ListarColaboradorGridItensTypes = {
  id: number;
  nomeCompleto: string;
  cpfcnpj: string;
  nomeEmpresa: string;
  nomeCidade: string;
  nomeStatus: string;
};

export type ListarColaboradorItensTypes = {
  id: number;
  nomeStatus: string;
  nomeCompleto: string;
  cpfcnpj: string;
  nomeContato: string;
  emailContato: string;
  telefoneContato: string;
  tipoContato: string;
  cargoSetor: string;
  obsContato: string;
  selecionado: boolean;
  enviarNFeEmailContato: boolean;
};
