import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../Context/LoadingContext";
import {
  FormRetornoNotaFiscalType,
  RetornoNotaFiscalTypes,
} from "../../../Types/Almoxarifado/RetornoNotaFiscal/RetornoNotaFiscalTypes";
import { HttpRetornoNotaFiscal } from "./Http";

type AlterarRetornoNotaFiscalType = {
  CarregarDados: Function;
};

export const AlterarRetornoNotaFiscal = forwardRef(
  (props: AlterarRetornoNotaFiscalType, ref) => {
    //INICIO REF
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current.Abrir();
      },
      async Alterar(pRetornoNotaFiscal: any) {
        CGIModalRef.current.Abrir();
        console.log(pRetornoNotaFiscal);
        await HttpRetornoNotaFiscal()
          .RetornarDadosRetornoNotaFiscal(pRetornoNotaFiscal.id)
          .then((e) => {
            console.log(e);
            form.reset(e.Form);
          });
      },
    }));
    //FINAL  REF

    //INICIO  HOOKS
    const CGIModalRef = useRef() as any;
    const form = useFormContext<RetornoNotaFiscalTypes>();
    const { ToastErro, ToastSucesso } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);

    //FINAL  HOOKS

    //INICIO CONST
    const RenderTabs: Array<CGITabItensTypes> = [
      {
        Titulo: "Dados RetornoNotaFiscal",
        Component: AreaDadosRetornoNotaFiscal(),
      },
    ];
    //FINAL  CONST

    //INICO FUNC
    function AreaDadosRetornoNotaFiscal() {
      return (
        <CGIBox>
          {/* <CGIBoxItem Col={8}>
            <CGIInput<FormRetornoNotaFiscalType>
              id="Form.DadosRegistro.ncm"
              label="NCM"
              placeholder=""
              att={{
                maxLength: 8,
              }}
              validadores={{
                required: "NCM RetornoNotaFiscal obrigatório",
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={8}>
            <CGIInput<FormRetornoNotaFiscalType>
              id="Form.DadosRegistro.percIPI"
              label="Perc. IPI"
              placeholder=""
            />
          </CGIBoxItem>
          <CGIBoxItem Col={8}>
            <CGIInput<FormRetornoNotaFiscalType>
              id="Form.DadosRegistro.ex"
              label="Ex"
              placeholder=""
            />
          </CGIBoxItem> */}
        </CGIBox>
      );
    }

    const Confirmar = async (data: FormRetornoNotaFiscalType, event: any) => {
      setLoadingState(true);

      if (
        data.Form.DadosRegistro.id == null ||
        data.Form.DadosRegistro.id === undefined ||
        data.Form.DadosRegistro.id === 0
      ) {
        HttpRetornoNotaFiscal()
          .Incluir(data)
          .then((response: any) => {
            ToastSucesso("Registro gravado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
            console.log(data);
          })
          .catch((e) => {
            return ToastErro(e);
          })
          .finally(() => {
            setLoadingState(false);
          });
      } else {
        HttpRetornoNotaFiscal()
          .Alterar(data)
          .then((data: any) => {
            ToastSucesso("Registro Alterado com sucesso", "OK!");
            props.CarregarDados();
            CGIModalRef.current.Fechar();
          })
          .catch((e) => {
            return;
          })
          .finally(() => {
            setLoadingState(false);
          });
      }
    };
    //FINAL FUNC

    //INICO JSX
    return (
      <CGIModal
        id="almoxarifado/RetornoNotaFiscal/alterar"
        ref={CGIModalRef}
        Titulo="Cadastro de RetornoNotaFiscal"
        TelaCheia={"3xl"}
        Confirmar={Confirmar}
        Fechar={() => {
          form.reset({});
        }}
      >
        <CGITabs Tabs={RenderTabs} />
      </CGIModal>
    );
    //FINAL JSX
  }
);
