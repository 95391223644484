import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import axios from "axios";
import { CGIRoutes } from "./Services/Routes";
import { GlobalContext } from "./Context/GlobalContext";
import "./Prototypes/Index";
import { extendTheme } from "@chakra-ui/react";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

axios.defaults.baseURL = "https://cgiapi.headthink.info";
axios.defaults.headers.post["Content-Type"] = "application/json";

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const theme = extendTheme({ config });

root.render(
  <React.Fragment>
    <ChakraProvider>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <GlobalContext>
        <CGIRoutes />
      </GlobalContext>
    </ChakraProvider>
  </React.Fragment>
);
