export const ObjPedidoCompraPadrao: FormPedidoCompraType = {
  Form: {
    dadosRegistro: {
      id: 0,
      numero: 0,
      identificacao: "",
      dataCadastro: "",
      usuarioId: 0,
      empresaId: 0,
      fornecedorId: 0,
      condicaoPagamentoId: 0,
      compradorId: 0,
      valorTotal: 0,
      qtdItens: 0,
      obs: "",
      statusId: 0,
      tipoPedido: "",
      dataBaixa: "",
      usuarioBaixaId: 0,
      obsBaixa: "",
      dataVencimento: "",
      pedidoVendaId: 0,
      dataPrevisao: "",
      oportunidadeVendaPetronectId: 0,
      transportadoraId: 0,
      redespachoId: 0,
      tipoFreteId: 0,
      valorFrete: 0,
      valorICMS: 0,
      valorICMSST: 0,
      valorIPI: 0,
      sugestaoPedidoCompraId: 0,
      dataAprovacaoReprovacao: "",
      moedaId: 0,
      eMailEnviado: false,
      eMailsParaEnvio: "",
      centroDeCustoId: 0,
      tipoPedidoCompraId: 0,
    },
  },
};
export type PedidoCompraTypes = {
  dadosRegistro: {
    id: number;
    numero: number;
    identificacao: string;
    dataCadastro: string;
    usuarioId: number;
    empresaId: number;
    fornecedorId: number;
    condicaoPagamentoId: number;
    compradorId: number;
    valorTotal: number;
    qtdItens: number;
    obs: string;
    statusId: number;
    tipoPedido: string;
    dataBaixa: string;
    usuarioBaixaId: number;
    obsBaixa: string;
    dataVencimento: string;
    pedidoVendaId: number;
    dataPrevisao: string;
    oportunidadeVendaPetronectId: number;
    transportadoraId: number;
    redespachoId: number;
    tipoFreteId: number;
    valorFrete: number;
    valorICMS: number;
    valorICMSST: number;
    valorIPI: number;
    sugestaoPedidoCompraId: number;
    dataAprovacaoReprovacao: string;
    moedaId: number;
    eMailEnviado: boolean;
    eMailsParaEnvio: string;
    centroDeCustoId: number;
    tipoPedidoCompraId: number;
  };
};
export type FormPedidoCompraType = {
  Form: PedidoCompraTypes;
};

export const ObjListarPedidoCompraPadrao: ListarPedidoCompraTypes = {
  Pesquisa: {
    dataInicial: "",
    dataFinal: "",
    valorDe: 0,
    valorAte: 0,
    listaEmpresasId: "",
    listaFornecedoresId: "",
    listaCondicaoPagamentoId: "",
    listaCompradoresId: "",
    produtosId: 0,
    listaStatusId: "",
    listaTransportadorasId: "",
    identificacao: "",
    numeroPedidoCliente: "",
    identificacaoPedidoVenda: "",
    listaRedespachosId: "",
    listaCentroDeCustoId: "",
    listaTipoPedidoCompraId: "",
    tipoPedido: "",
  },
  Itens: [],
};

export type PedidoCompraPesquisaGridTypes = {
  ncm?: string;
};

export type ListarPedidoCompraTypes = {
  Pesquisa: ListarPesquisaPedidoCompraTypes;
  Itens: ListarPedidoCompraItensTypes[];
};

export type ListarPesquisaPedidoCompraTypes = {
  dataInicial: string;
  dataFinal: string;
  valorDe: number;
  valorAte: number;
  listaEmpresasId: string;
  listaFornecedoresId: string;
  listaCondicaoPagamentoId: string;
  listaCompradoresId: string;
  produtosId: number;
  listaStatusId: string;
  listaTransportadorasId: string;
  identificacao: string;
  numeroPedidoCliente: string;
  identificacaoPedidoVenda: string;
  listaRedespachosId: string;
  listaCentroDeCustoId: string;
  listaTipoPedidoCompraId: string;
  tipoPedido: string;
};

export type PedidoCompraVisualizarTypes = {
  id: number;
  ncm: string;
  percIPI: number;
  ex: number;
};

export type ListarPedidoCompraItensTypes = {
  id: number;
  dataCadastro: string;
  dataAprovacaoReprovacao: string;
  identificacao: string;
  nomeEmpresa: string;
  identificacaoSugestaoPedidoCompra: string;
  nomeFornecedor: string;
  nomeComprador: string;
  nomeCondicaoPagamento: string;
  dataPrevisao: string;
  qtdItens: number;
  valorTotal: number;
  nomeStatus: string;
};

export type AlterarPedidoCompraTypes = {
  id: number;
  ncm: string;
  percIPI: number;
  ex: number;
};
