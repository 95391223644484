import { CGIHttp } from "../../../Services/Axios";
import {
  ListarPesquisaConfigCFOPTypes,
  ListarConfigCFOPGridItensTypes,
  ConfigCFOPPesquisaGridTypes,
  ConfigCFOPVisualizarTypes,
  ConfigCFOPTypes,
} from "../../../Types/Sistema/ConfigCFOP/ConfigCFOPTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpConfigCFOP() {
  const url = "/ConfigCFOP";

  async function RetornarDadosGridPesquisa(
    dados: ConfigCFOPPesquisaGridTypes
  ): Promise<ListarConfigCFOPGridItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-configcfop", dados)
      .then((e) => e.data.data);
  }
  async function RetornarConfigCFOP(
    DadosFiltros: ListarPesquisaConfigCFOPTypes
  ): Promise<ListarConfigCFOPGridItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(url + "/obter-configcfop", DadosFiltros)
    ).data.data;
  }
  async function RetornarDadosVisualizarConfigCFOP(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + "/visualizar/" + id)
    ).data.data) as ConfigCFOPVisualizarTypes;
  }
  async function RetornarDadosExclusao(
    DadosEmpresa: ListarConfigCFOPGridItensTypes
  ) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + DadosEmpresa.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir/" + Dados.id);
  }
  async function Alterar(DadosConfigCFOP: ConfigCFOPTypes) {
    return await new CGIHttp().conn.post(url + "/alterar", DadosConfigCFOP);
  }
  async function Incluir(DadosConfigCFOP: ConfigCFOPTypes) {
    return await new CGIHttp().conn.post(url + "/incluir", DadosConfigCFOP);
  }
  return {
    RetornarDadosGridPesquisa,
    RetornarConfigCFOP,
    RetornarDadosExclusao,
    RetornarDadosVisualizarConfigCFOP,
    Excluir,
    Alterar,
    Incluir,
  };
}
