/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { FormLabel, Box } from "@chakra-ui/react";
import { AsyncSelect, AsyncSelectComponent } from "chakra-react-select";
import { useState, useEffect } from "react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { HttpCombo } from "./HttpCombo";
import { ErrorMessage } from "@hookform/error-message";
export interface ComboTypes<T extends Record<string, unknown>> {
  id: NestedKey<T>;
  Multipla?: boolean;
  Placeholder?: string;
  Option?: string;
  Link: string;
  label: string;
  validadores?: RegisterOptions | undefined;
}

/**
 * @Componente CGIComboAjax - Combo Ajax padrão do Sistema
 * @Ref  FormContext
 * @Form  Necessita ser Controlado Por Formulário
 */
export function CGIComboAjax<T extends Record<string, unknown>>(
  props: ComboTypes<T>
) {
  const [Dados, setDados] = useState([]);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    RetornarDadosCombo();
  }, []);

  async function RetornarDadosCombo() {
    var data = await HttpCombo().RetornarDados(props.Link);
    setDados(data);
  }

  return (
    <Box w={"100%"}>
      <FormLabel>
        {props.label}
        {props.validadores?.required ? (
          <span style={{ color: "red" }}> *</span>
        ) : (
          ""
        )}
      </FormLabel>
      <Controller
        render={({ field }) => {
          return (
            <AsyncSelect
              isClearable
              formatOptionLabel={(data: any) => {
                return (
                  <span dangerouslySetInnerHTML={{ __html: data.label }} />
                );
              }}
              ref={field.ref}
              isMulti={props.Multipla ? true : undefined}
              options={Dados}
              loadingMessage={e => (e.inputValue = "Carregando...")}
              noOptionsMessage={e =>
                (e.inputValue = "Digite Algo para Pesquisar")
              }
              placeholder={"Selecione..."}
              closeMenuOnSelect={true}
              value={
                props.Multipla
                  ? Object.keys(field.value ? field.value : {}).length === 0
                    ? null
                    : Dados.filter((o: any) => {
                        return field.value.split(",").some((s: any) => {
                          return o.value == s;
                        });
                      })
                  : Dados.find((c: any) => c.value == field.value)
                  ? Dados.find((c: any) => c.value == field.value)
                  : null
              }
              onChange={(val: any) => {
                if (!props.Multipla) {
                  return field.onChange(val ? val.value : null);
                }
                return field.onChange(
                  val.map((c: any) => c.value)
                    ? val.map((c: any) => c.value).toString()
                    : null
                );
              }}
              loadOptions={(inputValue, callback) => {
                setTimeout(async () => {
                  if (inputValue.length < 3) {
                    return callback([]);
                  }

                  var data = await HttpCombo().RetornarDadosAJAX(
                    props.Link,
                    inputValue
                  );

                  callback(data as any);
                }, 500);
              }}
            />
          );
        }}
        rules={props.validadores}
        name={props.id}
        control={control}
      />
      <ErrorMessage
        errors={errors}
        name={props.id}
        render={({ message }) => (
          <span style={{ color: "red", fontSize: 12 }}>
            {" "}
            * {message ? message : "Campo Obrigatorio"}
          </span>
        )}
      />
    </Box>
  );
}
