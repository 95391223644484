import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../../Components/CheckBox/CGICheckBox";
import { CGIComboSimples } from "../../../../Components/Combos/CGIComboSimples";
import { useCGIToast } from "../../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIModal } from "../../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../../Context/LoadingContext";
import { ModalBaseType } from "../../../../Types/Modal/ModalBaseType";
import { FormFormaPagamentoTypes } from "../../../../Types/Sistema/Classificacao/FormaPagamento/FormaPagamentoTypes";
import { HttpFormaPagamento } from "./Http";

type AlterarFormaPagamentoType = {
    CarregarDados: Function;
};

export const AlterarFormaPagamento = forwardRef(
    (props: AlterarFormaPagamentoType, ref) => {
        //INICIO REF
        useImperativeHandle(ref, () => ({
            Abrir() {
                CGIModalRef.current?.Abrir();
            },
            Alterar(pEmpresa: any) {
                CGIModalRef.current?.Abrir();

                HttpFormaPagamento()
                    .RetornarDadosFormaPagamento(pEmpresa.id)
                    .then(e => {
                        form.reset(e.data.data);
                    });
            },
        }));
        //FINAL  REF

        //INICIO  HOOKS
        const CGIModalRef = useRef<ModalBaseType>(null);
        const form = useFormContext();
        const { ToastErro, ToastSucesso } = useCGIToast();
        const { setLoadingState } = useContext(LoadingContext);

        //FINAL  HOOKS

        //INICIO CONST

        const RenderTabs: Array<CGITabItensTypes> = [
            {
                Titulo: "Dados Forma de Pagamento",
                Component: AreaDadosFormaPagamento(),
            },
        ];
        //FINAL  CONST

        //INICO FUNC
        function AreaDadosFormaPagamento() {
            return (
                <CGIBox>
                    <CGIBoxItem Col={3}>
                        <CGIInput<FormFormaPagamentoTypes>
                            id="Form.DadosRegistro.nome"
                            label="Nome"
                            placeholder="Nome Forma de Pagamento"
                            validadores={{
                                required: "Nome obrigatório"
                            }}
                        />
                    </CGIBoxItem>
                    <CGIBoxItem Col={3}>
                        <CGIComboSimples<FormFormaPagamentoTypes>
                            id="Form.DadosRegistro.tipoDocumentoId"
                            label="Tipo de Documento"
                            Link="tipodocumento"
                            validadores={{
                                required: "Tipo Doc. obrigatório"
                            }}
                        />
                    </CGIBoxItem>
                    <CGIBoxItem Col={4}>
                        <CGIComboSimples<FormFormaPagamentoTypes>
                            id="Form.DadosRegistro.planoDeContasId"
                            label="Plano de Contas (Cts. Receber)"
                            Link="planodecontas-receitas-com-grupo"
                            validadores={{
                                required: "Plano de Contas obrigatório"
                            }}
                        />
                    </CGIBoxItem>
                    <CGIBoxItem Col={2}>
                        <CGICheckBox<FormFormaPagamentoTypes>
                            id="Form.DadosRegistro.ativo"
                            label="Ativo"
                            tooltip=""
                            att={{

                            }}
                        />
                    </CGIBoxItem>
                    <CGIBoxItem Col={3}>
                        <CGIComboSimples<FormFormaPagamentoTypes>
                            id="Form.DadosRegistro.meioPagamentoId"
                            label="Meio de Pagamento (NFe)"
                            Link="meiodepagamento"
                        />
                    </CGIBoxItem>
                    <CGIBoxItem Col={3}>
                        <CGIInput<FormFormaPagamentoTypes>
                            id="Form.DadosRegistro.qtdDias"
                            label="Qtd. Dias Vencto."
                            placeholder="Qtd. Dias Vencto."
                        />
                    </CGIBoxItem>
                    <CGIBoxItem Col={3}>
                        <CGIInput<FormFormaPagamentoTypes>
                            id="Form.DadosRegistro.taxaOperadora"
                            label="Taxa Operadora %"
                            placeholder="Taxa Operadora %"
                        />
                    </CGIBoxItem>
                    <CGIBoxItem Col={6}>
                        <CGIBoxItem Col={3}>
                            <CGIBoxItem Col={3}>
                                <CGICheckBox<FormFormaPagamentoTypes>
                                    id="Form.DadosRegistro.baixarTitulo"
                                    label="Baixar Título (Cts. Receber)"
                                    tooltip="Define se o título Cts. Receber será baixado"
                                />
                            </CGIBoxItem>
                        </CGIBoxItem>
                        <CGIBoxItem Col={3}>
                            <CGICheckBox<FormFormaPagamentoTypes>
                                id="Form.DadosRegistro.exibirNaBaixaCtsReceber"
                                label="Exibir na Baixa do Cts. Receber"
                                tooltip="Define se será exibido na baixa do Cts. Receber"
                            />
                        </CGIBoxItem>
                        <CGIBoxItem Col={3}>
                            <CGICheckBox<FormFormaPagamentoTypes>
                                id="Form.DadosRegistro.registrarCodigoAutorizacao"
                                label="Registrar Cod. Autorização"
                                tooltip="Define se registrará o Código de Autorização"
                            />
                        </CGIBoxItem>
                        <CGIBoxItem Col={3}>
                            <CGICheckBox<FormFormaPagamentoTypes>
                                id="Form.DadosRegistro.gerarParcelamentoVencimento"
                                label="Gerar Parcelamento Vencimento"
                                tooltip=""
                            />
                        </CGIBoxItem>
                        <CGIBoxItem Col={3}>
                            <CGICheckBox<FormFormaPagamentoTypes>
                                id="Form.DadosRegistro.exibirEmPedidoLoja"
                                label="Exibir em Pedido Loja"
                                tooltip="Define se será exibido na tela de pedido loja"
                            />
                        </CGIBoxItem>
                        <CGIBoxItem Col={3}>
                            <CGICheckBox<FormFormaPagamentoTypes>
                                id="Form.DadosRegistro.bloquearPedidoAguardandoConfirmacaoFinanceiro"
                                label="Bloquear Pedido Aguardando Confirmação Financeiro"
                                tooltip="Define se o pedido será bloqueado aguardando a confirmação do financeiro"
                            />
                        </CGIBoxItem>
                        <CGIBoxItem Col={3}>
                            <CGICheckBox<FormFormaPagamentoTypes>
                                id="Form.DadosRegistro.lancarHistoricoLancamentosDoCaixa"
                                label="Lançar Histórico Lancamentos do Caixa"
                                tooltip="Define se lançará histórico aos lançamentos do caixa"
                            />
                        </CGIBoxItem>
                    </CGIBoxItem>
                </CGIBox>
            );
        }

        const Confirmar = async (data: FormFormaPagamentoTypes, event: any) => {
            setLoadingState(true);

            if (data.Form.DadosRegistro.id == null || data.Form.DadosRegistro.id === undefined || data.Form.DadosRegistro.id === 0) {
                HttpFormaPagamento()
                    .Incluir(data.Form.DadosRegistro)
                    .then((response: any) => {
                        ToastSucesso("Registro gravado com sucesso", "OK!");
                        props.CarregarDados();
                        CGIModalRef.current?.Fechar();
                    })
                    .catch(e => {
                        return ToastErro(e);
                    })
                    .finally(() => {
                        setLoadingState(false);
                    });
            } else {
                HttpFormaPagamento()
                    .Alterar(data.Form.DadosRegistro)
                    .then((data: any) => {
                        ToastSucesso("Registro Alterado com sucesso", "OK!");
                        props.CarregarDados();
                        CGIModalRef.current?.Fechar();
                    })
                    .catch(e => {
                        return;
                    })
                    .finally(() => {
                        setLoadingState(false);
                    });
            }
        };
        //FINAL FUNC

        //INICO JSX
        return (
            <CGIModal
                id="sistema/classificacao/alterar"
                ref={CGIModalRef}
                Titulo="Forma de Pagamento"
                TelaCheia={"6xl"}
                Confirmar={Confirmar}
                Fechar={() => {
                    form.reset({});
                }}
            >
                <CGITabs Tabs={RenderTabs} />
            </CGIModal>
        );
        //FINAL JSX
    }
);
