import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  List,
  ListItem,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React from "react";
import {
  RetornarPemissaoPeloLink,
  RetornarSeUsuarioTemPemissaoPeloLink,
} from "../Util/Permissoes";

export interface MenuTypes {
  Nome: string;
  OnClick: Function;
  Permissao: string;
  SubMenus?: Array<MenuTypes>;
}
interface CGIMenuItensType {
  Acoes: Array<MenuTypes>;
  Informacoes: any;
  Hidden: Function;
}
export function CGIMenuItens(props: CGIMenuItensType) {
  function RenderSubMenu(e: MenuTypes) {
    return (
      <Accordion key={e.Nome} allowToggle>
        <AccordionItem>
          <AccordionButton>
            <Box>
              <Text fontWeight={"semibold"}>{e.Nome}</Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel p={0}>
            <List p={0}>
              <ListItem p={0}>
                {e.SubMenus?.map(r =>
                  r.SubMenus ? RenderSubMenu(r) : RenderMenu(r)
                )}
              </ListItem>
            </List>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  }
  function RenderMenu(e: MenuTypes) {
    var Desabilitado = false;
    var NaoEncontrado = false;
    var TemPermissao = RetornarSeUsuarioTemPemissaoPeloLink(e.Permissao);
    var ExistePermissao = RetornarPemissaoPeloLink(e.Permissao);

    if (!ExistePermissao) {
      NaoEncontrado = true;
    }
    if (!TemPermissao) {
      Desabilitado = true;
    }

    return (
      <Box p={0} key={e.Nome}>
        <MenuItem
          isDisabled={Desabilitado}
          onClick={async () => {
            await props.Hidden();
            e.OnClick(props.Informacoes);
          }}
        >
          <Text color={NaoEncontrado ? "red" : ""} fontWeight={"semibold"}>
            {e.Nome}
          </Text>
        </MenuItem>
      </Box>
    );
  }

  return (
    <Menu isOpen>
      <MenuList overflowY={"auto"} height={150}>
        {props.Acoes.map((e, index) => {
          return (
            <Box p={0} key={index}>
              {e.SubMenus ? RenderSubMenu(e) : RenderMenu(e)}
            </Box>
          );
        })}
      </MenuList>
    </Menu>
  );
}
