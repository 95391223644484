import { CGIHttp } from "../../../../Services/Axios";
import {
  FormLimiteCreditoTypes,
  ListarLimiteCreditoTypes,
  ListarLimiteCreditoItensTypes,
  ObjListarLimiteCreditoPadrao,
  ListarPesquisaLimiteCreditoTypes,
  DadosRegistroTypes,
} from "../../../../Types/Sistema/Classificacao/LimiteCredito/LimiteCreditoTypes";

const url = "/classificacao";

export function HttpLimiteCredito() {
  async function RetornarLimitesCredito(
    DadosFiltros: ListarPesquisaLimiteCreditoTypes
  ) {
    return (
      await new CGIHttp().conn.post(
        url + "/obter-limites-credito",
        DadosFiltros
      )
    ).data.data as ListarLimiteCreditoItensTypes[];
  }

  async function RetornarDadosLimiteCredito(id: number) {
    return await new CGIHttp().conn.get(url + `/alterar/${id}`);
  }

  async function Incluir(Dados: DadosRegistroTypes) {
    return await new CGIHttp().conn.post(url + "/incluir", Dados);
  }
  async function Alterar(Dados: DadosRegistroTypes) {
    return await new CGIHttp().conn.post(url + "/alterar", Dados);
  }
  async function Visualizar(Dados: DadosRegistroTypes) {
    return await new CGIHttp().conn.get(url + "/visualizar/" + Dados);
  }
  async function RetornarDadosExclusao(Dados: DadosRegistroTypes) {
    return await new CGIHttp().conn.get(url + "/excluir/" + Dados);
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir", Dados);
  }
  return {
    RetornarLimitesCredito,
    RetornarDadosLimiteCredito,
    RetornarDadosExclusao,
    Incluir,
    Alterar,
    Visualizar,
    Excluir,
  };
}
