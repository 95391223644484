import { AxiosInstance } from "axios";
import { CGIHttp } from "../../Services/Axios";
import { CGIMetaTypes } from "./CGIMeta";
const url = "home";
export function HttpCGIMeta() {
  async function RetornarDados(Link: string) {
    let Obj = await new CGIHttp().conn.get(url + "/obter-meta-home/" + Link);
    return Obj?.data.data as CGIMetaTypes;
  }
  return {
    RetornarDados,
  };
}
