import { Text } from "@chakra-ui/react";
import {
  forwardRef,
  useImperativeHandle,
  useContext,
  useRef,
  useMemo,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGINumberInput } from "../../../Components/NumberInput/CGINumberInput";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import { CGITextArea } from "../../../Components/TextArea/CGITextArea";
import LoadingContext from "../../../Context/LoadingContext";
import {
  BaixarCtsPagarMultPagamentosItemType,
  FormBaixarCtsPagarType,
} from "../../../Types/Financeiro/ContasPagar/BaixarCtsPagarTypes";
import { HttpCtsPagar } from "./Http";

export const BaixarCtsPagar = forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    async Abrir(pId: Number) {
      try {
        setLoadingState(true);
        await RetornarDados(pId);
        CGIModalRef.current.Abrir();
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingState(false);
      }
    },
  }));
  const { setLoadingState } = useContext(LoadingContext);
  const { ToastInfo } = useCGIToast();
  const CGIModalRef = useRef() as any;
  const form = useFormContext<FormBaixarCtsPagarType>();
  const WatchForm = useWatch({
    control: form.control,
    name: "Form",
  });
  const GridColunas: Array<CGICol<BaixarCtsPagarMultPagamentosItemType>> = [
    {
      Campo: "formaPagamentoId",
      Titulo: "id",
      Tipo: "Numero",
      ocultar: true,
    },
    {
      Campo: "nomeFormaPagamento",
      Titulo: "Forma de Pag.",
      Tipo: "Texto",
      att: {
        flex: 2,
      },
    },
    {
      Campo: "valor",
      Titulo: "Valor",
      Tipo: "Moeda",
      att: {
        flex: 1,
        editable: true,
      },
    },
  ];

  async function RetornarDados(pId: Number) {
    var Dados = await HttpCtsPagar().GETBaixarCtsPagar(pId);
    console.log(Dados.dataPagamento);
    form.setValue("Form", Dados);
  }
  async function Confirmar(Dados: any) {
    await HttpCtsPagar().BaixarCtsPagar(Dados);

    ToastInfo("Baixa realizada com sucesso");
    CGIModalRef.current.Fechar();
  }

  async function AdicionarPagamento() {
    let { formaPagamentoId, valor } = form.getValues("multPagamentoConfig");

    let multPagamentos = form.getValues("Form.multPagamentos");

    if (!formaPagamentoId) {
      form.setFocus("multPagamentoConfig.formaPagamentoId");
      return ToastInfo("Preencha os campos corretamente");
    }
    if (!valor) {
      form.setFocus("multPagamentoConfig.valor");
      return ToastInfo("Preencha os campos corretamente");
    }

    var DadosFormaPagamento = await HttpCtsPagar().RetornarDadosFormaPagamento(
      formaPagamentoId
    );

    multPagamentos.push({
      selecionado: true,
      codigoAutorizacao: "",
      formaPagamentoId: DadosFormaPagamento.id,
      nomeFormaPagamento: DadosFormaPagamento.nome,
      valor: valor,
    });

    form.setValue("Form.multPagamentos", multPagamentos);
    form.setValue("multPagamentoConfig", {
      formaPagamentoId: 0,
      valor: 0,
    });
  }

  const Resumos = useMemo(() => {
    if (!WatchForm) return null;
    let { multPagamentos, valorFinal } = WatchForm;
    let Total = 0;
    let Restante = 0;

    multPagamentos.forEach(item => {
      Total += Number(item.valor);
    });

    Restante = valorFinal - Total;

    if (Restante < 0) Restante = 0;

    return (
      <>
        <Text fontWeight={"bold"}>Valor Lançado: {Total.FormatarReal()}</Text>
        <Text fontWeight={"bold"}>
          Valor Restante: {Restante.FormatarReal()}
        </Text>
      </>
    );
  }, [WatchForm]);

  return (
    <CGIModal
      id="financeiro/ctspagar/baixar"
      ref={CGIModalRef}
      Titulo="Baixar Cts. a Pagar"
      TelaCheia={"6xl"}
      Confirmar={Confirmar}
      OcultarToastErro
      Fechar={() => {
        form.reset();
      }}
    >
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIResumo CtsPagarId="id" ResumoCtsPagar />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<FormBaixarCtsPagarType>
            id={"Form.portadorId"}
            Link={"PORTADOR-BANCO-CAIXA"}
            label={"Portador"}
            validadores={{
              required: true,
              min: 1,
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIDatePicker<FormBaixarCtsPagarType>
            id={"Form.dataPagamento"}
            label={"Data Pagamento"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGINumberInput<FormBaixarCtsPagarType>
            id={"Form.valorPago"}
            label={"Valor Pago"}
            validadores={{
              required: true,
            }}
            prefix
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGINumberInput<FormBaixarCtsPagarType>
            id={"Form.valorFinal"}
            label={"Valor Final do Pagamento"}
            att={{
              isDisabled: true,
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<FormBaixarCtsPagarType>
            id={"Form.documento"}
            label={"Documento"}
            placeholder={"Documento"}
            validadores={{
              required: true,
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGICheckBox<FormBaixarCtsPagarType>
            id={"Form.semComprovante"}
            label={"Baixa sem comprovante"}
            tooltip={"Baixa Sem Comprovante"}
          />
          <CGIInput<FormBaixarCtsPagarType>
            id={"Form.usuarioSemComprovante"}
            placeholder={"* Informe o solicitante"}
            validadores={{
              required: WatchForm?.semComprovante ? true : false,
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGITextArea<FormBaixarCtsPagarType>
            id={"Form.obs"}
            label={"Observação"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <Text>
            * Título de previsão - O valor pago se tornará o valor do título *
          </Text>
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGICheckBox<FormBaixarCtsPagarType>
            id={"Form.exibirMultiplasFormasDePagamento"}
            label={"Exibir Múltiplas Formas De Pagamento"}
            tooltip={"Exibir Múltiplas Formas De Pagamento"}
          />
        </CGIBoxItem>
        <CGIBoxItem
          hidden={WatchForm?.exibirMultiplasFormasDePagamento ? false : true}
          Col={12}
        >
          <CGIBox spacing="5px" titulo="Pagamento">
            <CGIBoxItem Col={8}>
              <CGIComboSimples<FormBaixarCtsPagarType>
                id={"multPagamentoConfig.formaPagamentoId"}
                label={"Forma De Pagamento"}
                Link={"formapagamento"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGINumberInput<FormBaixarCtsPagarType>
                id={"multPagamentoConfig.valor"}
                label={"Valor"}
                prefix
              />
            </CGIBoxItem>
            <CGIBoxItem
              Att={{
                style: {
                  display: "flex",
                  justifyContent: "flex-end",
                },
              }}
              Col={12}
            >
              <CGIButton
                label={"Adicionar Pagto."}
                onClick={() => AdicionarPagamento()}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIDataTableSimples
                Colunas={GridColunas}
                Data={WatchForm?.multPagamentos}
                Altura="200px"
                Att={{
                  defaultColDef: {
                    filter: false,
                  },
                  onCellEditingStopped: event => {
                    let { data } = event;
                    let { multPagamentos } = WatchForm;
                    let index = multPagamentos.findIndex(
                      item => item.formaPagamentoId === data.id
                    );
                    multPagamentos[index] = data;

                    form.setValue("Form.multPagamentos", multPagamentos);
                  },
                }}
              />
            </CGIBoxItem>
            <CGIBoxItem
              Att={{
                style: {
                  display: "flex",
                  justifyContent: "flex-end",
                },
              }}
              Col={12}
            >
              <CGIButton
                label={"Limpar Pagtos."}
                onClick={() => form.setValue("Form.multPagamentos", [])}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <>{Resumos}</>
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem
          Col={6}
          hidden={WatchForm?.exibirMultiplasFormasDePagamento ? true : false}
        >
          <CGIComboSimples<FormBaixarCtsPagarType>
            id={"Form.formaPagamentoId"}
            label={"Forma De Pagamento"}
            Link={"formapagamento"}
          />
        </CGIBoxItem>
      </CGIBox>
    </CGIModal>
  );
});
