import { AddIcon } from "@chakra-ui/icons";
import { useContext } from "react";
import { useForm, useWatch, FormProvider } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import LoadingContext from "../../../Context/LoadingContext";
import { ListarEquipamentoTypes } from "../../../Types/Sistema/Equipamento/EquipamentoTypes";
import {
  ListarPermissaoItensTypes,
  ListarPermissaoTypes,
} from "../../../Types/Sistema/Permissao/PermissaoTypes";
import { HttpPermissao } from "./Http";

export function ListarPermisao() {
  const form = useForm<ListarPermissaoTypes>();
  const { setLoadingState } = useContext(LoadingContext);

  const GridItens = useWatch({
    control: form.control,
    name: "itens",
  });

  const RetornarPermissoes = async () => {
    setLoadingState(true);
    form.setValue("itens", []);
    const DadosPesquisa = form.getValues("pesquisa");
    const Dados = await HttpPermissao().RetornarPermissoes(DadosPesquisa);
    form.setValue("itens", Dados);
    setLoadingState(false);
  };

  const Janelas = [
    {
      Componente: <></>,
    },
  ];
  const GridColunas: Array<CGICol<ListarPermissaoItensTypes>> = [
    {
      Campo: "ativo",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: [
        {
          nomeStatus: "true",
          cor: "green",
        },
        {
          nomeStatus: "false",
          cor: "red",
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 150,
      },
    },
    {
      Campo: "nome",
      Titulo: "Nome",
      Tipo: "Texto",
    },
    {
      Campo: "id",
      Titulo: "Nome / Link / Grupo",
      Tipo: "Composta",
      Composta: [
        {
          titulo: "Nome",
          valor: "nome",
          exibirLabel: true,
        },
        {
          titulo: "Link",
          valor: "link",
          exibirLabel: true,
        },
        {
          titulo: "Grupo",
          valor: "grupo",
          exibirLabel: true,
        },
      ],
    },
    {
      Campo: "usuarios",
      Titulo: "Usuários",
      Tipo: "Texto",
    },
    {
      Campo: "qtdUsuarios",
      Titulo: "Qtd. Usuários",
      Tipo: "Texto",
    },

    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarPermissaoItensTypes) => MenuGridPopover(e, []),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={3}>
          <CGIDatePicker<ListarEquipamentoTypes>
            id={"Pesquisa.dataInicialVencimentoIPVA"}
            label="Data Vencimento IPVA - De"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIDatePicker<ListarEquipamentoTypes>
            id="Pesquisa.dataFinalVencimentoIPVA"
            label="Data Vencimento IPVA - Até"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarEquipamentoTypes>
            id="Pesquisa.identificacao"
            label="Identificação"
            placeholder="Identificação"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ListarEquipamentoTypes>
            id="Pesquisa.frota"
            label="Frota"
            placeholder="Frota"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarEquipamentoTypes>
            id="Pesquisa.ativoId"
            Link="simnaoambos"
            label="Ativo"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGIInput<ListarEquipamentoTypes>
            id="Pesquisa.modelo"
            label="Modelo"
            placeholder="Modelo"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples<ListarEquipamentoTypes>
            Multipla
            id="Pesquisa.ListaEmpresasId"
            label="Empresa"
            Link="empresa"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples<ListarEquipamentoTypes>
            id="Pesquisa.locado"
            label="Locado"
            Link="simnaoambos"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples<ListarEquipamentoTypes>
            id="Pesquisa.terceiro"
            label="Terceiro"
            Link="simnaoambos"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarEquipamentoTypes>
            Multipla
            id="Pesquisa.ListaTiposEquipamentoId"
            label="Tipo de Equipamento"
            Link="equipamento"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarEquipamentoTypes>
            Multipla
            id="Pesquisa.ListaLocadoresId"
            label="Locadores"
            Link="equipamento"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarEquipamentoTypes>
            id="Pesquisa.apoliceSeguro"
            label="Apólice Seguro"
            placeholder="Apólice Seguro"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput<ListarEquipamentoTypes>
            id="Pesquisa.obs"
            label="Observação"
            placeholder="Observação"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIComboSimples<ListarEquipamentoTypes>
            Multipla
            id="Pesquisa.ListaCentrosDeCustoId"
            label="Centros de Custo"
            Link="equipamento"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGICheckBox<ListarEquipamentoTypes>
            id="Pesquisa.somenteEquipamentosCadastroIncompleto"
            label="Somente equipamentos com cadastro incompleto"
            tooltip=""
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <>
      <CGIListar
        id="sistema/permissao/listar"
        Titulo="Listar Permissão"
        Janelas={Janelas}
      >
        <FormProvider {...form}>
          <CGIDataTable
            LimparFiltros={() => form.reset()}
            Menu={{ Icone: <AddIcon />, Acoes: [] }}
            Pesquisar={form.handleSubmit(RetornarPermissoes)}
            Filtro={Filtros()}
            Data={GridItens}
            Colunas={GridColunas}
            Legenda={[]}
          />
        </FormProvider>
      </CGIListar>
    </>
  );
  // Final JSX
}
