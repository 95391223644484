import { forwardRef, useContext, useImperativeHandle, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ClientesBloqueadosItensTypes,
  FormClientesBloqueadosType,
  ObjClientesBloqueadosTypesPadrao,
} from "../../../Types/Financeiro/ContasReceber/ClientesBloqueadosTypes";
import { HttpCtsReceber } from "./Http";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";

export const DesbloquearClientesBloqueados = forwardRef(
  (props: { CarregarDados: () => void }, ref) => {
    useImperativeHandle(ref, () => ({
      Abrir() {
        form.reset(ObjClientesBloqueadosTypesPadrao);
        CGIModalRef.current?.Abrir();
      },
      Alterar(pCtsReceberId: number) {
        CGIModalRef.current?.Abrir();
      },
    }));

    const form = useFormContext<FormClientesBloqueadosType>();
    const { setLoadingState } = useContext(LoadingContext);
    const CGIModalRef = useRef() as any;

    async function CarregarDados() {
      try {
        setLoadingState(true);

        var DadosFiltros = form.getValues("Pesquisa");
        var Dados = await HttpCtsReceber().RetornarClientesBloqueados(
          DadosFiltros
        );
        form.setValue("Itens", Dados);
      } finally {
        setLoadingState(false);
      }
    }

    const Confirmar = async (data: any, event: any) => {};

    const MenuGrid: Array<MenuTypes> = [
      {
        Nome: "Visualizar",
        Permissao: "financeiro/ctsreceber/incluir",
        OnClick: (e: ClientesBloqueadosItensTypes) => {
          CGIModalRef.current?.Abrir(e);
        },
      },
      {
        Nome: "Alterar",
        Permissao: "financeiro/ctsreceber/incluir",
        OnClick: (e: ClientesBloqueadosItensTypes) => {
          CGIModalRef.current?.Abrir(e);
        },
      },
      {
        Nome: "Desbloquear",
        Permissao: "financeiro/ctsreceber/incluir",
        OnClick: (e: ClientesBloqueadosItensTypes) => {
          CGIModalRef.current?.Abrir(e);
        },
      },
      {
        Nome: "Análise de Crédito",
        Permissao: "financeiro/ctsreceber/incluir",
        OnClick: (e: ClientesBloqueadosItensTypes) => {
          CGIModalRef.current?.Abrir(e);
        },
      },
    ];

    const GridColunas: Array<CGICol<ClientesBloqueadosItensTypes>> = [
      {
        Campo: "id",
        Titulo: "Código",
        Tipo: "Numero",
        att: { maxWidth: 100 },
      },
      {
        Campo: "id",
        Titulo: "Nome Completo \\ CPFCNPJ",
        Tipo: "Composta",
        Composta: [
          {
            titulo: "",
            valor: "nomeCompleto",
          },
          {
            titulo: "",
            valor: "cpfcnpj",
            negrito: true,
          },
        ],
      },
      {
        Campo: "nomeUsuarioBloqueio",
        Titulo: "Usuário Bloqueio",
        Tipo: "Texto",
      },
      {
        Campo: "dataBloqueio",
        Titulo: "Data Bloqueio",
        Tipo: "Data",
      },
      {
        Campo: "diasBloqueados",
        Titulo: "Dias Bloqueados",
        Tipo: "Numero",
        att: { maxWidth: 180 },
      },
      {
        Campo: "id",
        Titulo: "",
        Tipo: "Menu",
        att: {
          cellRenderer: (e: ClientesBloqueadosItensTypes) =>
            MenuGridPopover(e, MenuGrid),
          pinned: "right",
          colId: "action",
          editable: false,
          maxWidth: 60,
        },
      },
    ];
    const GridItens = useWatch({
      control: form.control,
      name: "Itens",
    });

    return (
      <CGIModal
        id="financeiro/colaborador/listarclientesbloqueados"
        ref={CGIModalRef}
        Titulo="Clientes Bloqueados"
        TelaCheia={"full"}
        Confirmar={() => {}}
        ocultarConfirmar={true}
        Fechar={() => {}}
      >
        <CGIBox>
          <CGIBoxItem Col={6}>
            <CGIBox titulo="Filtros" popover={"Filtros"}>
              <CGIBoxItem Col={5}>
                <CGIInput<FormClientesBloqueadosType>
                  id={"Pesquisa.nomeCompleto"}
                  label={"Nome \\ Razão Social"}
                  placeholder={""}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={5}>
                <CGIInput<FormClientesBloqueadosType>
                  id={"Pesquisa.cpfcnpj"}
                  label={"CPF \\ CNPJ"}
                  placeholder={""}
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <CGIBox titulo="Filtros de Período" popover={"Filtros de Período"}>
              <CGIBoxItem Col={5}>
                <CGIDatePicker<FormClientesBloqueadosType>
                  id={"Pesquisa.dataBloqueioInicial"}
                  label={"Data Inicial"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={5}>
                <CGIDatePicker<FormClientesBloqueadosType>
                  id={"Pesquisa.dataBloqueioFinal"}
                  label={"Data Final"}
                />
              </CGIBoxItem>
            </CGIBox>
            <CGIBoxItem Col={6}>
              <CGIButton
                onClick={form.handleSubmit(CarregarDados)}
                att={{
                  mt: 30,
                  marginLeft: 650,
                }}
                label="Pesquisar"
              />
            </CGIBoxItem>
          </CGIBoxItem>

          <CGIBoxItem Col={12}>
            <CGIDataTableSimples
              DisableGetId
              Altura="300px"
              Colunas={GridColunas}
              Data={GridItens}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
    //FINAL JSX
  }
);
