export const ObjBoletoBancarioPadrao: FormBoletoBancarioType = {
  Form: {
    DadosRegistro: {
      id: 0,
      dataCadastro: "",
      usuarioId: 0,
      colaboradorId: 0,
      numeroTitulo: 0,
      nossoNumero: "",
      reciboImovelId: 0,
      ctsReceberId: 0,
      statusId: 0,
      convenioPortadorId: 0,
      dataVencimento: "",
      dataPagamento: "",
      valor: 0,
      digitoDoNossoNumero: "",
      dataDesconto: "",
      valorDesconto: 0,
      documentoPagador: "",
      enviadoPorEMail: false,
      dataEnvio: "",
      eMail: "",
      erroEnvioEmail: "",
    },
  },
};
export type BoletoBancarioTypes = {
  DadosRegistro: {
    id: number;
    dataCadastro: string;
    usuarioId: number;
    colaboradorId: number;
    numeroTitulo: number;
    nossoNumero: string;
    reciboImovelId: number | null;
    ctsReceberId: number | null;
    statusId: number;
    convenioPortadorId: number;
    dataVencimento: string;
    dataPagamento: string;
    valor: number;
    digitoDoNossoNumero: string;
    dataDesconto: string;
    valorDesconto: number;
    documentoPagador: string;
    enviadoPorEMail: boolean;
    dataEnvio: string;
    eMail: string;
    erroEnvioEmail: string;
  };
};
export type FormBoletoBancarioType = {
  Form: BoletoBancarioTypes;
};

export const ObjListarBoletoBancarioPadrao: ListarBoletoBancarioTypes = {
  Pesquisa: {
    dataCadastroInicial: "",
    dataCadastroFinal: "",
    dataVencimentoInicial: "",
    dataVencimentoFinal: "",
    dataPagamentoInicial: "",
    dataPagamentoFinal: "",
    listaColaboradoresId: "",
    listaConveniosId: "",
    listaStatusId: "",
    valorDe: 0,
    valorAte: 0,
    nossoNumero: "",
    documentoCtsReceber: "",
    ocultarExcluido: true,
  },
  Itens: [],
};

export type BoletoBancarioPesquisaGridTypes = {
  ncm?: string;
};

export type ListarBoletoBancarioTypes = {
  Pesquisa: ListarPesquisaBoletoBancarioTypes;
  Itens: ListarBoletoBancarioItensTypes[];
};

export type ListarPesquisaBoletoBancarioTypes = {
  dataCadastroInicial: string;
  dataCadastroFinal: string;
  dataVencimentoInicial: string;
  dataVencimentoFinal: string;
  dataPagamentoInicial: string;
  dataPagamentoFinal: string;
  listaColaboradoresId: string;
  listaConveniosId: string;
  listaStatusId: string;
  valorDe: number;
  valorAte: number;
  nossoNumero: string;
  documentoCtsReceber: string;
  ocultarExcluido: boolean;
};

export type BoletoBancarioVisualizarTypes = {
  dataCadastroInicial: string;
  dataCadastroFinal: string;
  dataVencimentoInicial: string;
  dataVencimentoFinal: string;
  dataPagamentoInicial: string;
  dataPagamentoFinal: string;
  listaColaboradoresId: string;
  listaConveniosId: string;
  listaStatusId: string;
  valorDe: number;
  valorAte: number;
  nossoNumero: string;
  documentoCtsReceber: string;
  ocultarExcluido: boolean;
};

export type ListarBoletoBancarioItensTypes = {
  id: number;
  colaboradorId: number;
  numeroTitulo: string;
  documentoCtsReceber: string;
  dataCadastro: string;
  dataVencimento: string;
  dataPagamento: string;
  nomeConvenioPortador: string;
  nomeColaborador: string;
  nossoNumero: string;
  digitoDoNossoNumero: string;
  valor: number;
  nomeStatus: string;
};
