export const ObjListarImportacaoNFSaidaPadrao: ListarImportacaoNFSaidaTypes = {
  Pesquisa: {
    codigoNF: 0,
    numeroNF: 0,
    valorNF: 0,
    nomeEmitente: "",
    nomeDestinatario: "",
    dataImportacaoInicial: "",
    dataImportacaoFinal: "",
    listaStatusId: 0,
    gerouCtsReceberId: 0,
  },
  Itens: [],
};

export type ListarImportacaoNFSaidaTypes = {
  Pesquisa: ListarPesquisaImportacaoNFsaidaTypes;
  Itens: ListarImportacaoNFsaidaItensTypes[];
};

export type ListarImportacaoNFsaidaItensTypes = {
  id: number;
  nomeStatus: string;
  nomeEmpresa: string;
  nomeEmitente: string;
  nomeDestinatario: string;
  chave: string;
  numeroNF: string;
  valorNF: string;
};

export type ListarPesquisaImportacaoNFsaidaTypes = {
  codigoNF: number;
  numeroNF: number;
  valorNF: number;
  nomeEmitente: string;
  nomeDestinatario: string;
  dataImportacaoInicial: string;
  dataImportacaoFinal: string;
  listaStatusId: number;
  gerouCtsReceberId: number;
};

export type FormImportacaoNFSaidaTypes = {
  Anexo: any;
  tipoDocumentoId: number;
  GerarCtsReceberCasoEstejaPresenteNoXML: boolean;
  ImportarXMLComoNFSaida: boolean;
};
