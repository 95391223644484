import { Box, Tag, TagLabel } from "@chakra-ui/react";
import { forwardRef, useContext, useImperativeHandle, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import LoadingContext from "../../../Context/LoadingContext";
import {
  VisualizarPedidoPorProdutoItensTypes,
  VisualizarPedidoPorProdutoTypes,
} from "../../../Types/Vendas/Pedido/VisualizarPedidoPorProduto";
import {
  LegendaTypes,
  ModalBaseType,
} from "../../../Types/Modal/ModalBaseType";
import { HttpPedido } from "./Http";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";

type PropsVisualizarPedidosPorProdutoType = {
  CarregarDados: Function;
};

export const VisualizarPedidosPorProduto = forwardRef(
  (props: PropsVisualizarPedidosPorProdutoType, ref) => {
    useImperativeHandle(ref, () => ({
      async Abrir(pDados: any) {
        CGIModalRef.current?.Abrir();
        form.setValue("Pesquisa.produtoId", pDados.produtoId);
        form.setValue("Itens", []);
      },
    }));
    const form = useFormContext<VisualizarPedidoPorProdutoTypes>();
    const { setLoadingState } = useContext(LoadingContext);
    const CGIModalRef = useRef<ModalBaseType>();
    const Legendas: LegendaTypes = [
      { nomeStatus: "Aberto", cor: "green" },
      { nomeStatus: "Separado", cor: "lightBlue" },
      { nomeStatus: "Conferido", cor: "wheat" },
      { nomeStatus: "Excluído", cor: "red" },
      { nomeStatus: "Faturado", cor: "blue" },
      { nomeStatus: "Entregue", cor: "cadetBlue" },
      { nomeStatus: "Romaneio", cor: "gray" },
      { nomeStatus: "Devolução Romaneio", cor: "lightGray" },
    ];

    async function CarregarDados() {
      try {
        setLoadingState(true);
        var DadosFiltros = form.getValues("Pesquisa");
        var Dados = await HttpPedido().RetornarPedidoProProduto(DadosFiltros);
        form.setValue("Itens", Dados);
      } finally {
        setLoadingState(false);
      }
    }

    const Confirmar = async (data: any, event: any) => {};
    const GridColunas: Array<CGICol<VisualizarPedidoPorProdutoItensTypes>> = [
      {
        Campo: "nomeStatus",
        Titulo: "",
        Status: Legendas,
        Tipo: "Status",
        att: {
          maxWidth: 60,
          filter: false,
        },
      },
      {
        Campo: "identificacaoPedido",
        Titulo: "Identificação",
        Tipo: "Texto",
      },
      {
        Campo: "nomeCliente",
        Titulo: "Cliente",
        Tipo: "Texto",
      },
      {
        Campo: "nomeEmpresa",
        Titulo: "Empresa",
        Tipo: "Texto",
      },
      {
        Campo: "vlrUnitario",
        Titulo: "Valor Unitário",
        Tipo: "Texto",
      },
      {
        Campo: "quantidade",
        Titulo: "Qtd.",
        Tipo: "Texto",
        att: {
          maxWidth: 100,
        },
      },
      {
        Campo: "vlrTotal",
        Titulo: "Valor Total",
        Tipo: "Texto",
      },
      {
        Campo: "totalPedido",
        Titulo: "Total Pedido",
        Tipo: "Texto",
      },
    ];
    const GridItens = useWatch({
      control: form.control,
      name: "Itens",
    });

    return (
      <CGIModal
        id="almoxarifado/movestoque/exibirpedidosporproduto"
        ref={CGIModalRef}
        Titulo="Exibir Pedidos"
        TelaCheia={"6xl"}
        Confirmar={Confirmar}
        CarregarDados={CarregarDados}
        Fechar={() => {}}
        tipoGET={{
          url: "PRODUTO-EXIBIR-PEDIDOS",
          setRetorno: (retorno: any) => {
            form.setValue("Pesquisa", retorno);
          },
        }}
      >
        <CGIBox>
          <CGIBoxItem Col={12}>
            <CGIResumo
              ProdutoId="Pesquisa.produtoId"
              ResumoProdutoSimples
              Expandir
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIBox titulo="Filtros">
              <CGIBoxItem Col={4}>
                <CGIComboSimples<VisualizarPedidoPorProdutoTypes>
                  id={"Pesquisa.produtoId"}
                  Link={"statuspedido"}
                  label={"Status Pedido"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIComboSimples<VisualizarPedidoPorProdutoTypes>
                  id={"Pesquisa.empresaId"}
                  Link={"empresa"}
                  label={"Empresas"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={4}>
                <CGIComboSimples<VisualizarPedidoPorProdutoTypes>
                  id={"Pesquisa.tipoPedidoId"}
                  Link={"tipopedido"}
                  label={"Tipo Pedido"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIDatePicker<VisualizarPedidoPorProdutoTypes>
                  id={"Pesquisa.dataInicial"}
                  label={"Dt. Cadastro Inicial"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={3}>
                <CGIDatePicker<VisualizarPedidoPorProdutoTypes>
                  id={"Pesquisa.dataFinal"}
                  label={"Dt. Cadastro Final"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={6}>
                <CGIButton
                  onClick={form.handleSubmit(CarregarDados)}
                  att={{
                    mt: 30,
                  }}
                  label="Pesquisar"
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGIDataTableSimples
              DisableGetId
              Altura="300px"
              Colunas={GridColunas}
              Data={GridItens}
            />
            <Box my={5} borderWidth={2} w="100%">
              <Box p={2}>
                <Box p={2}>
                  {Legendas.map((e, index) => {
                    return (
                      <Tag
                        mx={2}
                        size={"md"}
                        key={index}
                        borderRadius="full"
                        variant="solid"
                        bgColor={e.cor}
                      >
                        <TagLabel color={"gray.100"}>{e.nomeStatus}</TagLabel>
                      </Tag>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </CGIBoxItem>
        </CGIBox>
      </CGIModal>
    );
    //FINAL JSX
  }
);
