export const ObjMarca: FormMarcaType = {
  Form: {
    DadosRegistro: {
      id: 0,
      nome: "",
      ativo: false,
      permitirCadastrarProdutos: false,
      buscarItemNocadastroDeProdutos: false,
      numeroDeDiasParaNFRetorno: 0,
      margemLucroECommerce: 0,
      modeloImportacaoId: 0,
    },
  },
};

export type FormMarcaType = {
  Form: {
    DadosRegistro: MarcaTypes;
  };
};

type MarcaTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  permitirCadastrarProdutos: boolean;
  buscarItemNocadastroDeProdutos: boolean;
  numeroDeDiasParaNFRetorno: number;
  margemLucroECommerce: number;
  modeloImportacaoId: number;
};

export const ObjListarMarcaPadrao: ListarMarcaTypes = {
  Pesquisa: {
    titulo: "",
    nome: "",
    ativoId: 0,
  },
  Itens: [],
};

export type ListarMarcaTypes = {
  Pesquisa: ListarPesquisaMarcaTypes;
  Itens: ListarMarcaItensTypes[];
};

export type ListarPesquisaMarcaTypes = {
  titulo: string;
  nome: string;
  ativoId: number;
};

export type ListarMarcaItensTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  permitirCadastrarProdutos: string;
  buscarItemNocadastroDeProdutos: string;
  numeroDeDiasParaNFRetorno: string;
  atualizarCustoNoLancamentoNFEntrada: number;
  tipo: string;
  nomeFinalidadeNF: string;
  nomeFinalidadeDevolucaoRetornoNF: string;
  resumoFinalidade: string;
  nomePrecoAutomatico: string;
  alocarEstoque: boolean;
  margemLucroECommerce: number;
};
