import { Navigate, Outlet } from "react-router-dom";
import { Box, Center, Container, Flex, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { useAuth } from "../Hook/useAuth";
import { CGIHeader } from "./Header/CGIHeader";
import { TalkTo } from "../../Services/Talkto/Talkto";
import { RetornarDadosUsuario } from "../../Services/Util/Util";
import TrocarSenhaContext from "../../Context/TrocarSenhaContext";
import { CGIFooter } from "./Footer/CGIFooter";
import { Temas } from "../../Themes/Temas";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { CGIButton } from "../Button/CGIButton";

export const CGILayoutRelatorio = () => {
  var Logado = useAuth();
  const { AbrirTrocarSenha } = useContext(TrocarSenhaContext);

  if (!Logado) {
    return <Navigate to={"/Login"} />;
  }
  var DadosUsuario = RetornarDadosUsuario();

  if (DadosUsuario.trocarSenhaProximoLogon) {
    AbrirTrocarSenha(true);
  }

  return (
    <Box style={{ overflow: "hidden" }} h="100vh" bg="#F4F7FE">
      <Box>
        <Center
          justifyContent={"end"}
          padding={2}
          bgColor={Temas.Primaria}
          w={"100%"}
          h={"30px"}
        >
          <CGIButton
            onClick={() => window.close()}
            icon={<CloseIcon />}
            label="Fechar Relatório"
            att={{ fontSize: 14, size: "xs" }}
          />
        </Center>
      </Box>
      <Container
        style={{ overflowY: "scroll", height: "calc(100vh - 60px)" }}
        maxW="full"
        w={"100%"}
      >
        <Outlet />
        <TalkTo />
      </Container>
      <Box>
        <Center
          justifyContent={"center"}
          padding={2}
          bgColor={Temas.Primaria}
          w={"100%"}
          h={30}
          color={Temas.Branco}
          fontWeight={"bold"}
        >
          HeadThink © Todos os Direitos Reservados
        </Center>
      </Box>
    </Box>
  );
};
