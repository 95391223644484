import { AddIcon } from "@chakra-ui/icons";
import { useRef, useCallback, useContext } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import { HttpCtsReceber } from "./Http";
import { CGIHistorico } from "../../../Components/Historico/CGIHistorico";
import { FormContext } from "../../../Context/FormConxtext";
import { ModalBaseType } from "../../../Types/Modal/ModalBaseType";
import {
  ListarCtsReceberTypes,
  ObjListarCtsReceberPadrao,
  ListarCtsReceberItensTypes,
} from "../../../Types/Financeiro/ContasReceber/CtsReceberTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
//import { AlterarCtsReceber } from "./_AlterarCtsReceber";
import { AlterarComissao } from "./_AlterarComissao";
import { GerarBoletoBancario } from "./_GerarBoletoBancario";
import { DesbloquearClientesBloqueados } from "./_DesbloquearClientesBloqueados";
import { BloquearClientesEmAtraso } from "./_BloquearClientesEmAtraso";

export function ListarCtsReceber() {
  //Inicio Hooks
  const DesbloquearClientesBloqueadosRef = useRef<ModalBaseType>();
  const BloquearClientesEmAtrasoRef = useRef<ModalBaseType>();
  const GerarBoletoBancarioRef = useRef<ModalBaseType>();
  const AlterarCtsReceberRef = useRef<ModalBaseType>();
  const AlterarComissaoRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const CGIHistoricoRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarCtsReceberTypes>({
    defaultValues: ObjListarCtsReceberPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  async function RetornarCtsReceber() {
    setLoadingState(true);
    var DadosFiltro = methods.getValues("Pesquisa");
    var data = await HttpCtsReceber()
      .RetornarCtsReceber(DadosFiltro)
      .finally(() => {
        setLoadingState(false);
      });
    methods.setValue("Itens", data);
  }

  const RetornarDadosExclusao = useCallback(
    async (data: ListarCtsReceberItensTypes) => {
      return await HttpCtsReceber().RetornarDadosExclusao(data);
    },
    []
  );

  async function Excluir(data: DeleteTypes) {
    await HttpCtsReceber()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }
  //Final Hooks

  //------------------------

  //Inicio Consts
  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo Título",
      Permissao: "financeiro/ctsreceber/incluir",
      OnClick: () => {
        AlterarCtsReceberRef.current?.Abrir();
      },
    },
    {
      Nome: "Bloquear Clientes Em Atraso",
      Permissao: "financeiro/ctsreceber/bloqueiodeclientes",
      OnClick: () => {
        BloquearClientesEmAtrasoRef.current?.Abrir();
      },
    },
    {
      Nome: "Desbloquear Clientes Em Atraso",
      Permissao: "financeiro/colaborador/desbloquearclientesparafaturamento",
      OnClick: () => {
        DesbloquearClientesBloqueadosRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Visualizar",
      Permissao: "financeiro/ctsreceber/incluir",
      OnClick: (e: ListarCtsReceberItensTypes) => {
        AlterarCtsReceberRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Alterar",
      Permissao: "financeiro/ctsreceber/altera",
      OnClick: (e: ListarCtsReceberItensTypes) => {
        AlterarCtsReceberRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Baixar",
      Permissao: "financeiro/ctsreceber/incluir",
      OnClick: (e: ListarCtsReceberItensTypes) => {
        AlterarCtsReceberRef.current?.Abrir(e);
      },
    },

    {
      Nome: "Excluir",
      Permissao: "sistema/ctsreceber/excluir",
      OnClick: (e: ListarCtsReceberItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Histórico",
      Permissao: "financeiro/ctsreceber/historico",
      OnClick: (e: ListarCtsReceberItensTypes) => {
        CGIHistoricoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Lancar Histórico",
      Permissao: "financeiro/ctsreceber/excluir",
      OnClick: (e: any) => {
        //CGIModalLancarHistoricoRef.current.Abrir(e);
      },
    },
    {
      Nome: "Lancar Histórico Colaborador",
      Permissao: "financeiro/ctsreceber/lancarhistorico",
      OnClick: (e: ListarCtsReceberItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Gerar Boleto Bancário",
      Permissao: "financeiro/ctsreceber/gerarboletobancario",
      OnClick: (e: ListarCtsReceberItensTypes) => {
        GerarBoletoBancarioRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Imprimir Recibo de Adiantamento",
      Permissao: "financeiro/ctsreceber/excluir",
      OnClick: (e: ListarCtsReceberItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Imprimir Baixas",
      Permissao: "financeiro/ctsreceber/alterarcomissao",
      OnClick: (e: ListarCtsReceberItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Alterar Comissão",
      Permissao: "financeiro/ctsreceber/alterarcomissao",
      OnClick: (e: ListarCtsReceberItensTypes) => {
        AlterarComissaoRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Anexos",
      Permissao: "financeiro/ctsreceber/alterarcomissao",
      OnClick: (e: ListarCtsReceberItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Visualizar Pedido",
      Permissao: "financeiro/ctsreceber/alterarcomissao",
      OnClick: (e: ListarCtsReceberItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Aberto", cor: "green" },
    { nomeStatus: "Pago Parcial", cor: "lightBlue" },
    { nomeStatus: "Pago Total", cor: "blue" },
    { nomeStatus: "Estornado", cor: "orange" },
    { nomeStatus: "Excluído", cor: "red" },
    { nomeStatus: "Renegociado", cor: "yellow" },
  ];

  const GridColunas: Array<CGICol<ListarCtsReceberItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "nomeEmpresa",
      Titulo: "Empresa",
      Tipo: "Texto",
      Ordernar: true,
    },
    {
      Campo: "id",
      Titulo: "Título",
      Tipo: "Composta",
      Composta: [
        {
          titulo: "",
          valor: "empresaNumeroParcela",
        },
        {
          titulo: "",
          valor: "portador",
        },
      ],
    },
    {
      Campo: "documento",
      Titulo: "Documento NF",
      Tipo: "Texto",
    },
    {
      Campo: "id",
      Titulo: "Cliente",
      Tipo: "Composta",
      att: { maxWidth: 300 },
      Composta: [
        {
          titulo: "",
          valor: "nomeCliente",
        },
        {
          titulo: "",
          valor: "nomeFantasia",
          negrito: true,
        },
      ],
    },
    {
      Campo: "dataCadastro",
      Titulo: "Data Cadastro",
      Tipo: "Data",
    },
    {
      Campo: "id",
      Titulo: "Plano de Contas/ For. Pag.",
      Tipo: "Composta",
      Composta: [
        {
          titulo: "",
          valor: "nomePlanoDeContas",
        },
        {
          titulo: "",
          valor: "nomeFormaPagamento",
          negrito: true,
        },
      ],
    },
    {
      Campo: "saldoReceber",
      Titulo: "Saldo Receber",
      Tipo: "Numero",
    },
    {
      Campo: "valorPago",
      Titulo: "Valor Pago",
      Tipo: "Numero",
      att: { maxWidth: 100 },
    },
    {
      Campo: "valorTotal",
      Titulo: "Valor Total",
      Tipo: "Numero",
    },
    {
      Campo: "dataVencimento",
      Titulo: "Data Venc.",
      Tipo: "Data",
    },
    {
      Campo: "dataPagamento",
      Titulo: "Data Pagto.",
      Tipo: "Data",
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarCtsReceberItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          titulo: "Forn.",
          valor: "nomeFornecedor",
        },
        {
          titulo: "Vlr.",
          valor: "valor",
        },
        {
          titulo: "DOC",
          valor: "documento",
        },
        {
          titulo: "DOC",
          valor: "documento",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Empresa"
          CarregarDados={methods.handleSubmit(RetornarCtsReceber)}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    // {
    //   Componente: (
    //     <AlterarCtsReceber
    //       CarregarDados={methods.handleSubmit(RetornarCtsReceber)}
    //       ref={AlterarCtsReceberRef}
    //     />
    //   ),
    // },
    {
      Componente: (
        <AlterarComissao
          CarregarDados={methods.handleSubmit(RetornarCtsReceber)}
          ref={AlterarComissaoRef}
        />
      ),
    },
    {
      Componente: (
        <GerarBoletoBancario
          CarregarDados={methods.handleSubmit(RetornarCtsReceber)}
          ref={GerarBoletoBancarioRef}
        />
      ),
    },
    {
      Componente: (
        <DesbloquearClientesBloqueados
          CarregarDados={methods.handleSubmit(RetornarCtsReceber)}
          ref={DesbloquearClientesBloqueadosRef}
        />
      ),
    },
    {
      Componente: (
        <BloquearClientesEmAtraso
          CarregarDados={methods.handleSubmit(RetornarCtsReceber)}
          ref={BloquearClientesEmAtrasoRef}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIBox titulo="Filtros de Período" popover="Filtros de Período">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsReceberTypes>
                id={"Pesquisa.dataInclusaoInicial"}
                label={"Data Inclusão Inicial"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsReceberTypes>
                id={"Pesquisa.dataInclusaoFinal"}
                label={"Data Inclusão Final"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsReceberTypes>
                id={"Pesquisa.dataVencimentoInicial"}
                label={"Data Vencimento Inicial"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsReceberTypes>
                id={"Pesquisa.dataVencimentoFinal"}
                label={"Data Vencimento Final"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsReceberTypes>
                id={"Pesquisa.dataPagamentoInicial"}
                label={"Data Pagamento Inicial"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsReceberTypes>
                id={"Pesquisa.dataPagamentoFinal"}
                label={"Data Pagamento Final"}
              />
            </CGIBoxItem>

            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsReceberTypes>
                id={"Pesquisa.dataBaixaInicial"}
                label={"Data Baixa Inicial"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<ListarCtsReceberTypes>
                id={"Pesquisa.dataBaixaFinal"}
                label={"Data Baixa Final"}
              />
            </CGIBoxItem>
          </CGIBox>
          <CGIBoxItem Col={7}>
            <CGIBox titulo="Filtros de Valores" popover="Filtros de Valores">
              <CGIBoxItem Col={6}>
                <CGIInput<ListarCtsReceberTypes>
                  id={"Pesquisa.valorDe"}
                  placeholder={""}
                  label={"Valor do Título Inicial"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={6}>
                <CGIInput<ListarCtsReceberTypes>
                  id={"Pesquisa.valorAte"}
                  placeholder={""}
                  label={"Valor do Título Final"}
                />
              </CGIBoxItem>
            </CGIBox>
            <CGIBoxItem Col={3}>
              <CGICheckBox<ListarCtsReceberTypes>
                label={"Somente Títulos de Previsão"}
                id={"Pesquisa.somenteTitulosPrevisao"}
                tooltip={""}
                att={{
                  style: { marginTop: 30 },
                }}
                attLabel={{
                  style: { marginTop: 30 },
                }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGICheckBox<ListarCtsReceberTypes>
                label={"Exibir Somente Títulos Excluídos"}
                id={"Pesquisa.exibirExcluidos"}
                tooltip={""}
                att={{
                  style: { marginTop: 30 },
                }}
                attLabel={{
                  style: { marginTop: 30 },
                }}
              />
              <CGIBoxItem Col={4}>
                <CGIComboSimples<ListarCtsReceberTypes>
                  Link={"tipocliente"}
                  label={"Grupo Econômico"}
                  id={"Pesquisa.listaGruposEconomicosId"}
                />
              </CGIBoxItem>
            </CGIBoxItem>
          </CGIBoxItem>
        </CGIBoxItem>
        <CGIBoxItem Col={8}>
          <CGIBox>
            <CGIBoxItem Col={12}>
              <CGIComboSimples<ListarCtsReceberTypes>
                Multipla
                Link={"empresa"}
                label={"Empresas"}
                id={"Pesquisa.listaEmpresasId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIComboSimples<ListarCtsReceberTypes>
                Multipla
                Link={"statusctspagarreceber"}
                label={"Status do Título"}
                id={"Pesquisa.listaStatusId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIComboAjax<ListarCtsReceberTypes>
                id={"Pesquisa.listaClientesId"}
                label={"Cliente"}
                Link={"cliente"}
                Multipla
              />
            </CGIBoxItem>
            <CGIBoxItem Col={8}>
              <CGIComboSimples<ListarCtsReceberTypes>
                Multipla
                Link={"vendedor"}
                label={"Vendedor"}
                id={"Pesquisa.listaVendedoresId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIInput<ListarCtsReceberTypes>
                label={"Documento"}
                placeholder={""}
                id={"Pesquisa.documento"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={8}>
              <CGIComboSimples<ListarCtsReceberTypes>
                Multipla
                Link={"planodecontas-receitas-conta"}
                label={"Plano de Contas"}
                id={"Pesquisa.listaPlanoDeContasId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarCtsReceberTypes>
                Multipla
                Link={"portador"}
                label={"Portadores"}
                id={"Pesquisa.listaPortadoresId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarCtsReceberTypes>
                Multipla
                Link={"colaborador"}
                Placeholder={"ALTERAR"}
                label={"Mês Ref."}
                id={"Pesquisa.listaMesesReferenciaId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={8}>
              <CGIComboSimples<ListarCtsReceberTypes>
                Multipla
                Link={"tipocliente"}
                label={"Tipo Cliente(Exclusão)"}
                id={"Pesquisa.listaTiposDeClientesExclusaoId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarCtsReceberTypes>
                id={"Pesquisa.listaPracasId"}
                Link={"praca"}
                label={"Praça"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarCtsReceberTypes>
                id={"Pesquisa.listaRotasId"}
                Link={"rota"}
                label={"Rota"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarCtsReceberTypes>
                Link={"tipocliente"}
                label={"Tipo Cliente"}
                id={"Pesquisa.listaTiposDeClientesId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarCtsReceberTypes>
                Link={"formapagamento"}
                label={"Forma de Pagamento"}
                id={"Pesquisa.listaFormasDePagamentosId"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIInput<ListarCtsReceberTypes>
                label={"Nosso Número"}
                id={"Pesquisa.nossoNumero"}
                placeholder=""
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarCtsReceberTypes>
                Link={"simnaoambos"}
                label={"Pagamento Bloqueado"}
                id={"Pesquisa.pagamentoBloqueadoId"}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="financeiro/ctsreceber/listar"
      tipoGET={{
        url: "CtsReceber-LISTAR",
        setRetorno: (e) => {
          methods.setValue("Pesquisa", e);
        },
      }}
      Titulo="Listar Contas a Receber"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarCtsReceber)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
      <FormContext>
        <CGIHistorico
          tipo="TimeLine"
          titulo="Histórico do Título"
          ref={CGIHistoricoRef}
          link={"financeiro/ctsreceber/historico"}
          origem={"CtsReceber"}
        />
      </FormContext>
    </CGIListar>
  );
  // Final JSX
}
