import { CGIHttp } from "../../../Services/Axios";
import {
  FormRetornoNotaFiscalType,
  ListarPesquisaRetornoNotaFiscalTypes,
  ListarRetornoNotaFiscalItensTypes,
  RetornoNotaFiscalPesquisaGridTypes,
  RetornoNotaFiscalTypes,
} from "../../../Types/Almoxarifado/RetornoNotaFiscal/RetornoNotaFiscalTypes";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";

export function HttpRetornoNotaFiscal() {
  const url = "/RetornoNotaFiscal";

  async function RetornarDadosGridPesquisa(
    dados: RetornoNotaFiscalPesquisaGridTypes
  ): Promise<ListarRetornoNotaFiscalItensTypes[]> {
    return await new CGIHttp().conn
      .post(url + "/obter-retorno-nota-fiscal", dados)
      .then((e) => e.data.data);
  }

  async function RetornarRetornoNotaFiscals(
    DadosFiltros: ListarPesquisaRetornoNotaFiscalTypes
  ): Promise<ListarRetornoNotaFiscalItensTypes[]> {
    return await (
      await new CGIHttp().conn.post(
        url + "/obter-retorno-nota-fiscal",
        DadosFiltros
      )
    ).data.data;
  }

  async function RetornarDadosRetornoNotaFiscal(id: number) {
    return (await (
      await new CGIHttp().conn.get(url + `/alterar/${id}`)
    ).data.data) as FormRetornoNotaFiscalType;
  }

  async function RetornarDadosExclusao(
    Dados: ListarRetornoNotaFiscalItensTypes
  ) {
    return (await new CGIHttp().conn
      .get(url + "/excluir/" + Dados.id)
      .then((res) => res.data.data)) as DeleteTypes;
  }
  async function RetornarDadosVisualizarRetornoNotaFiscal(
    DadosRetornoNotaFiscal: FormRetornoNotaFiscalType
  ) {
    return (await (
      await new CGIHttp().conn.get(
        url + "/visualizar/" + DadosRetornoNotaFiscal.Form
      )
    ).data.data) as RetornoNotaFiscalTypes;
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir/" + Dados.id);
  }
  async function Alterar(DadosRetornoNotaFiscal: FormRetornoNotaFiscalType) {
    return await new CGIHttp().conn.post(
      url + "/alterar",
      DadosRetornoNotaFiscal.Form
    );
  }
  async function Visualizar(DadosRetornoNotaFiscal: FormRetornoNotaFiscalType) {
    return await new CGIHttp().conn.post(
      url + "/visualizar",
      DadosRetornoNotaFiscal.Form
    );
  }
  async function Incluir(DadosRetornoNotaFiscal: FormRetornoNotaFiscalType) {
    return await new CGIHttp().conn.post(
      url + "/incluir",
      DadosRetornoNotaFiscal.Form
    );
  }
  return {
    RetornarRetornoNotaFiscals,
    RetornarDadosGridPesquisa,
    RetornarDadosExclusao,
    RetornarDadosVisualizarRetornoNotaFiscal,
    RetornarDadosRetornoNotaFiscal,
    Visualizar,
    Excluir,
    Alterar,
    Incluir,
  };
}
