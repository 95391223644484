import { Box } from "@chakra-ui/react";
import { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { CGIDataTableSimples } from "../../DataTable/CGIDataTableSimples";
import { CGICol } from "../../DataTable/DataTable/DataTableTypes";
import { MenuTypes } from "../../MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../MenuButton/CGIMenuGrid";
import { CGIModal } from "../../Modal/CGIModal";

import { HttpCGICalendario } from "../HttpCalendario";

export const CGIModalCalendarioCtsReceberPagar = forwardRef(
  (props: any, ref) => {
    useImperativeHandle(ref, () => ({
      Abrir(dia: string, link: string, Titulo: string) {
        CarregarDados(dia, link);
        SetTitulo(Titulo);
        CGIModalRef.current.Abrir();
      },
    }));

    const [Dados, SetDados] = useState<Array<any>>([]);
    const [Titulo, SetTitulo] = useState<string>("");

    const CGIModalRef = useRef() as any;
    const MenuGrid: Array<MenuTypes> = [
      {
        Nome: "Visualizar",
        Permissao: "financeiro/ctspagar/visualizar",
        OnClick: () => {},
      },
      {
        Nome: "Alterar",
        Permissao: "financeiro/ctspagar/alterar",
        OnClick: () => {},
      },
      {
        Nome: "Baixar",
        Permissao: "financeiro/ctspagar/baixar",
        OnClick: () => {},
      },
      {
        Nome: "Excluir",
        Permissao: "financeiro/ctspagar/excluir",
        OnClick: () => {},
      },
      {
        Nome: "Historico",
        Permissao: "financeiro/ctspagar/historico",
        OnClick: () => {},
      },
    ];

    const GridColunas: Array<CGICol<any>> = [
      {
        Campo: "id",
        Titulo: "Código",
        Tipo: "Texto",
        Ordernar: true,
      },
      {
        Campo: "nomeEmpresa",
        Titulo: "Empresa",
        Tipo: "Texto",
      },
      {
        Campo: "documento",
        Titulo: "Doc.",
        Tipo: "Texto",
      },
      {
        Campo: "valor",
        Titulo: "Valor",
        Tipo: "Moeda",
      },
      {
        Campo: "nomeRegistro",
        Titulo: "Fornecedor",
        Tipo: "Texto",
      },
      {
        Campo: "id",
        Titulo: "",
        Tipo: "Menu",
        att: {
          cellRenderer: (e: any) => MenuGridPopover(e, MenuGrid),
          pinned: "right",
          colId: "action",
          editable: false,
          maxWidth: 60,
        },
      },
    ];

    async function CarregarDados(dia: string, link: string) {
      var data = await HttpCGICalendario().RetornarDadosCalendario(dia, link);
      SetDados(data);
    }

    return (
      <>
        <CGIModal
          id=""
          ref={CGIModalRef}
          Titulo={Titulo}
          TelaCheia={"6xl"}
          Confirmar={() => {}}
          Fechar={() => {}}
          ocultarCancelar
          ocultarConfirmar
          OcultarPermissoes
        >
          <Box>
            <CGIDataTableSimples
              Altura="60vh"
              Colunas={GridColunas}
              Data={Dados}
            />
          </Box>
        </CGIModal>
      </>
    );
  }
);
