import React from "react";
import { GridItem, GridItemProps } from "@chakra-ui/react";

type CGIBoxItemType = {
  children: React.ReactNode;
  hidden?: boolean;
  Col: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  Att?: GridItemProps;
};
/**
 * @Componente CGIBoxItem - Componente usado para Organizar os itens no Grid de Layout tendo até  12 posições
 * @Util Usado Em Conjunto do CGIBox
 *
 */
export function CGIBoxItem(props: CGIBoxItemType) {
  return (
    <GridItem
      hidden={props.hidden}
      colSpan={{ base: 12, md: props.Col }}
      {...props.Att}
    >
      {props.children}
    </GridItem>
  );
}
