import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIDataTableSimples } from "../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGIResumo } from "../../../Components/Resumo/CGIResumo";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../Context/LoadingContext";
import {
  TotaisInternosItemTypes,
  TotaisInternosTypes,
} from "../../../Types/Sistema/Produto/TotaisInternosTypes";

import { HttpProduto } from "./Http";

type TotaisInternosType = {
  CarregarDados: Function;
};

export const TotaisInternos = forwardRef((props: TotaisInternosType, ref) => {
  useImperativeHandle(ref, () => ({
    Abrir(pId: number) {
      form.setValue("Pesquisa", {
        produtoId: pId,
        somenteEmpresaEstoqueGeral: false,
      });
      CarregarDados();
      CGIModalRef.current.Abrir();
    },
  }));

  async function CarregarDados() {
    setLoadingState(true);
    const Dados = form.getValues();
    console.log(Dados);
    await HttpProduto()
      .RetornarTotaisInternos(Dados)
      .then(e => {
        console.log(e);
        form.setValue("Form.Grid", e);
      })
      .catch(e => {
        form.setValue("Form.Grid", []);
      })
      .finally(() => {
        setLoadingState(false);
      });
  }

  const GridColunas: Array<CGICol<TotaisInternosItemTypes>> = [
    {
      Campo: "nomeEmpresa",
      Titulo: "Empresa",
      Tipo: "Texto",
    },
    {
      Campo: "estoque",
      Titulo: "Est.",
      Tipo: "Numero",
      att: { maxWidth: 100 },
    },
    {
      Campo: "pedidoVendaEmAberto",
      Titulo: "Ped. Venda Em Aberto",
      Tipo: "Numero",
      att: { maxWidth: 150 },
    },
    {
      Campo: "orcamentoEmAberto",
      Titulo: "Orcamento Em Aberto",
      Tipo: "Numero",
      att: { maxWidth: 150 },
    },
    {
      Campo: "ordemProducaoEmAberto",
      Titulo: "Ordem Produção Em Aberto",
      Tipo: "Numero",
      att: { maxWidth: 150 },
    },
    {
      Campo: "totalAlocado",
      Titulo: "Total Alocado",
      Tipo: "Numero",
      att: { maxWidth: 150 },
    },
    {
      Campo: "transfInternaEmAberto",
      Titulo: "Transf. Interna Em Aberto",
      Tipo: "Numero",
      att: { maxWidth: 150 },
    },
    {
      Campo: "pedidoCompraEmAberto",
      Titulo: "Ped. Compra em Aberto",
      Tipo: "Numero",
      att: { maxWidth: 150 },
    },
    {
      Campo: "nfEntradaEmAberto",
      Titulo: "NF. Ent. em Aberto",
      Tipo: "Numero",
      att: { maxWidth: 150 },
    },
    {
      Campo: "saldoFinal",
      Titulo: "Saldo Final",
      Tipo: "Numero",
      att: { maxWidth: 150 },
    },
  ];
  const CGIModalRef = useRef() as any;
  const form = useFormContext<TotaisInternosTypes>();
  const { setLoadingState } = useContext(LoadingContext);
  const DadosGrid = useWatch({
    control: form.control,
    name: "Form.Grid",
  });
  return (
    <CGIModal
      id="sistema/produto/consultartotaisinterno"
      ref={CGIModalRef}
      Titulo="Consultar Totais Internos"
      TelaCheia={"6xl"}
      Confirmar={() => {}}
      Fechar={() => {
        form.reset({});
      }}
      ocultarConfirmar
    >
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIBox titulo="Pesquisar">
            <CGIBoxItem Col={6}>
              <CGIComboAjax<TotaisInternosTypes>
                id="Pesquisa.produtoId"
                label="Produto"
                Link={"produto"}
              />
            </CGIBoxItem>
            <CGIBoxItem
              Att={{
                mt: 5,
              }}
              Col={4}
            >
              <CGICheckBox<TotaisInternosTypes>
                id="Pesquisa.somenteEmpresaEstoqueGeral"
                label="Somente Empresas Estoque Geral"
                tooltip={"Somente Empresas Estoque Geral"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={2}>
              <CGIButton
                onClick={CarregarDados}
                label="Pesquisar"
                style={{
                  marginTop: 30,
                }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIResumo ResumoProdutoSimples ProdutoId="Pesquisa.produtoId" />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIDataTableSimples
            Altura="500px"
            DisableGetId
            Colunas={GridColunas}
            Data={DadosGrid}
            Att={{
              defaultColDef: {
                filter: false,
              },
            }}
          />
        </CGIBoxItem>
      </CGIBox>
    </CGIModal>
  );
  //FINAL JSX
});
