export const ObjMarketPlace: FormMarketPlaceType = {
  Form: {
    DadosRegistro: {
      id: 0,
      nome: "",
      ativo: true,
      cnpj: "",
      calcularComissao: false,
      tipoFreteId: 0,
      transportadoraId: 0,
      nomeStatus: "",
      listaTransportadoraId: "",
      listaTipoFreteId: "",
    },
  },
};

export type FormMarketPlaceType = {
  Form: {
    DadosRegistro: MarketPlaceTypes;
  };
};

type MarketPlaceTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  cnpj: string;
  calcularComissao: boolean;
  tipoFreteId: number;
  transportadoraId: number;
  nomeStatus: string;
  listaTransportadoraId: string;
  listaTipoFreteId: string;
};

export const ObjListarMarketPlacePadrao: ListarMarketPlaceTypes = {
  Pesquisa: {
    titulo: "",
    nome: "",
    ativoId: 0,
  },
  Itens: [],
};

export type ListarMarketPlaceTypes = {
  Pesquisa: ListarPesquisaMarketPlaceTypes;
  Itens: ListarMarketPlaceItensTypes[];
};

export type ListarPesquisaMarketPlaceTypes = {
  titulo: string;
  nome: string;
  ativoId: number;
};

export type ListarMarketPlaceItensTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  cnpj: string;
  calcularComissao: boolean;
  tipoFreteId: number;
  transportadoraId: number;
  nomeStatus: string;
};
