import {
  Center,
  Flex,
  FormLabel,
  Input,
  Kbd,
  List,
  ListItem,
  RadioGroup,
  Radio,
  Stack,
  Switch,
  Text,
  Tag,
} from "@chakra-ui/react";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Link, useNavigate } from "react-router-dom";
import { FormContext } from "../../Context/FormConxtext";
import { RetornarDadosUsuario } from "../../Services/Util/Util";
import { Temas } from "../../Themes/Temas";
import { Itens } from "../../Types/Home/Login/LoginType";
import { ModalBaseType } from "../../Types/Modal/ModalBaseType";
import { CGIAlertDialog } from "../AlertDialog/CGIAlertDialog";
import { CGIBox } from "../Box/CGIBox";
import { CGIBoxItem } from "../Box/CGIBoxItens";

type CGIModalMenuPesquisaTypes = {
  pesquisa: string;
};
export const CGIModalMenuPesquisa = forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    Abrir() {
      setIndexItens(0);
      setIndexPrefixo(0);
      setPrefixo("");
      setTipoRadio("M");
      CGIAlertDialogRef.current?.Abrir();
      setMenusFiltrados(Menus);
      setLinkOutraAba(true);
      setInputTxt("");
      setTimeout(() => {
        InputRef.current?.focus();
      }, 100);
    },
    Fechar() {
      CGIAlertDialogRef.current?.Fechar();
    },
  }));

  const keydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    console.log(e.key, e.ctrlKey);
    if (e.ctrlKey) {
      if (e.key === "F1") {
        setLinkOutraAba(e => !e);
      }
      if (e.key === "F2") {
        setTipoRadio(e => (e === "M" ? "P" : "M"));
        setInputTxt("");
        InputRef.current?.focus();
      }
    }
    if (TipoRadio === "M") {
      if (e.key === "ArrowUp") {
        if (indexItens > 0) {
          setIndexItens(indexItens - 1);
        }
      }

      if (e.key === "ArrowDown") {
        if (indexItens < MenusFiltrados.length - 1) {
          setIndexItens(indexItens + 1);
        }
      }

      ListRef.current?.children[indexItens].scrollIntoView({
        block: "center",
      });
    } else {
      // let aux = 0;
      // if (e.key === "ArrowUp") {
      //   if (IndexItensPrefixo > 0) {
      //     setIndexPrefixo(IndexItensPrefixo - 1);
      //     setPrefixo(rotasPrefixo[IndexItensPrefixo].prefixo);
      //     console.log(IndexItensPrefixo, Prefixo);
      //   }
      // }
      // if (e.key === "ArrowDown") {
      //   console.log(IndexItensPrefixo, rotasPrefixo.length);
      //   if (IndexItensPrefixo < rotasPrefixo.length) {
      //     setIndexPrefixo(IndexItensPrefixo + 1);
      //     aux = IndexItensPrefixo;
      //     setPrefixo(rotasPrefixo[aux].prefixo);
      //   }
      // }
    }
    if (e.key === "Enter") {
      Confirmar();
    }
  };

  const Confirmar = (itemSelecionado?: Itens) => {
    if (TipoRadio === "M") {
      ConfirmarMenu();
    } else {
      ConfirmarPrefixo();
    }
  };
  const ConfirmarPrefixo = () => {
    try {
      const Input = InputTxt.split(" ");
      var ObjPrefixo =
        rotasPrefixo.find(item =>
          item.prefixo.includes(Input[0].toUpperCase())
        ) || null;
      if (ObjPrefixo) {
        const route = ObjPrefixo.rota;
        if (!linkOutraAba) {
          navigate(`/${route}?DOC=${Input[1]}`);
        } else {
          window.open(
            window.location.origin + "/" + route + "?DOC=" + Input[1],
            "_blank"
          );
        }
        CGIAlertDialogRef.current?.Fechar();
      } else {
        setInputTxt("");
      }
    } finally {
      CGIAlertDialogRef.current?.Fechar();
    }
  };

  const ConfirmarMenu = () => {
    const itemSelecionado = MenusFiltrados[indexItens];
    try {
      if (!linkOutraAba) {
        navigate(itemSelecionado?.link || "/");
      } else {
        window.open(
          window.location.origin + "/" + itemSelecionado?.link,
          "_blank"
        );
      }
    } finally {
      CGIAlertDialogRef.current?.Fechar();
    }
  };
  const CGIAlertDialogRef = useRef<ModalBaseType>(null);
  const ListRef = useRef<HTMLOListElement>(null);
  const ListPrefixoRef = useRef<HTMLOListElement>(null);
  const [rotasPrefixo] = useState(RetornarDadosUsuario().rotasBuscaRegistros);

  const navigate = useNavigate();
  const InputRef = useRef<HTMLInputElement>(null);
  const Menus = useMemo(() => {
    return RetornarDadosUsuario().menu.menuprincipal.menu.flatMap(x => {
      return x.itens;
    });
  }, []);
  const [InputTxt, setInputTxt] = useState("");
  const [MenusFiltrados, setMenusFiltrados] = useState(Menus);
  const [linkOutraAba, setLinkOutraAba] = useState(true);
  const [indexItens, setIndexItens] = useState(0);
  const [IndexItensPrefixo, setIndexPrefixo] = useState(0);
  const [TipoRadio, setTipoRadio] = useState("M");
  const [Prefixo, setPrefixo] = useState<string>("");
  const Pesquisar = (Pesquisa: string) => {
    const TextoSemPrefixo = Pesquisa.split(" ")[1] || Pesquisa;

    if (TextoSemPrefixo) {
      setMenusFiltrados(e => {
        return Menus.filter(submenu => {
          return submenu.label
            .toLowerCase()
            .includes(TextoSemPrefixo.toLowerCase());
        });
      });
    } else {
      setMenusFiltrados(Menus);
    }
    if (TipoRadio === "P") {
      console.log("Pesquisar", Pesquisa.split(" ")[1]);
      var TextoComPrefixo = `${Prefixo} ${Pesquisa.split(" ")[1] || ""}`;
      if (TextoSemPrefixo === "") {
        TextoComPrefixo = `${Prefixo} `;
      }
      setInputTxt(TextoComPrefixo);
    } else {
      setInputTxt(TextoSemPrefixo);
    }
  };

  useEffect(() => {
    if (TipoRadio === "P") {
      setInputTxt(`${Prefixo} ${InputTxt.split(" ")[1] || ""}`);
    }
  }, [Prefixo]);

  return (
    <FormContext>
      <CGIAlertDialog
        ref={CGIAlertDialogRef}
        titulo={"Pesquisa Rápida"}
        confirmar={(e: any) => {
          Confirmar();
        }}
        size={"3xl"}
        ocultarCancelar
        keybordDown={e => {
          keydown(e);
        }}
      >
        <CGIBoxItem Col={12}>
          <CGIBox titulo="Pesquisar">
            <CGIBoxItem Col={12}>
              <Input
                ref={InputRef}
                id="Pesquisa"
                value={InputTxt}
                placeholder="Pesquisar"
                onChange={e => Pesquisar(e.target.value)}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <Center>
                <FormLabel fontWeight={"bold"}>
                  Nova Guia <br />
                  <Kbd>CTRL</Kbd> +<Kbd>F1</Kbd>
                </FormLabel>

                <Switch
                  size={"lg"}
                  id="OutraAba"
                  isChecked={linkOutraAba}
                  onChange={() => {
                    setLinkOutraAba(e => !e);
                  }}
                />
              </Center>
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <FormLabel>
                Tipo de Pesquisa<Kbd>CTRL</Kbd> +<Kbd>F2</Kbd>
              </FormLabel>
              <RadioGroup onChange={setTipoRadio} value={TipoRadio}>
                <Stack direction="row">
                  <Radio value="M">
                    <b>Por Menu</b>
                  </Radio>
                  <Radio value="P">
                    <b>Por Prefixo</b>
                  </Radio>
                </Stack>
              </RadioGroup>
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem hidden={TipoRadio !== "M"} Col={12}>
          <List
            className="CGIListMenuPesquisa"
            tabIndex={indexItens}
            ref={ListRef}
            padding={2}
            boxShadow={"0px 0px 5px 0px rgba(0,0,0,0.5)"}
            borderRadius={5}
            style={{
              overflowY: "auto",
              maxHeight: "250px",
            }}
          >
            {MenusFiltrados.map((item, index) => (
              <ListItem
                onMouseOver={() => setIndexItens(index)}
                aria-selected={index === indexItens}
                _selected={{
                  bgColor: Temas.Primaria,
                  color: Temas.Branco,
                  borderRadius: 5,
                }}
                key={index}
              >
                <Link
                  key={item.link}
                  onClick={() => CGIAlertDialogRef.current?.Fechar()}
                  to={item.link}
                  target={linkOutraAba ? "_blank" : "_self"}
                >
                  <Flex p={2}>
                    <Text fontWeight={"bold"}>
                      {item.menuGrupo}
                      {" > "}
                    </Text>
                    <Text>{item.label}</Text>
                  </Flex>
                </Link>
              </ListItem>
            ))}
          </List>
        </CGIBoxItem>
        <CGIBoxItem hidden={TipoRadio !== "P"} Col={12}>
          <List
            ref={ListPrefixoRef}
            padding={2}
            boxShadow={"0px 0px 5px 0px rgba(0,0,0,0.5)"}
            borderRadius={5}
            style={{
              overflowY: "auto",
              maxHeight: "250px",
            }}
          >
            {rotasPrefixo.map((item, index) => (
              <ListItem
                onMouseOver={() => {
                  setPrefixo(item.prefixo);
                  setIndexPrefixo(item.index);
                }}
                aria-selected={item.index === IndexItensPrefixo}
                _selected={{
                  bgColor: Temas.Primaria,
                  color: Temas.Branco,
                  borderRadius: 5,
                }}
                key={index}
              >
                <Flex p={2}>
                  <Text fontWeight={"bold"}>
                    <Tag
                      bgColor={Temas.Primaria}
                      fontWeight="bold"
                      color={Temas.Branco}
                    >
                      {item.prefixo}
                    </Tag>
                    {"  > "} {item.titulo}
                  </Text>
                </Flex>
              </ListItem>
            ))}
          </List>
        </CGIBoxItem>
      </CGIAlertDialog>
    </FormContext>
  );
});
