import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  GuardarDadosUsuario,
  RetornarDadosUsuario,
} from "../../Services/Util/Util";
import { List, ListItem } from "@chakra-ui/react";
import { HttpCGIAlertDiolog } from "./HttpAlertDiolog";
import { Empresa } from "../../Types/Home/Login/LoginType";
import { CGIBoxItem } from "../Box/CGIBoxItens";
import { CGIButton } from "../Button/CGIButton";
import { CGIAlertDialog } from "./CGIAlertDialog";
import LoadingContext from "../../Context/LoadingContext";
import { FormContext } from "../../Context/FormConxtext";

export const CGITrocarEmpresa = forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    Abrir() {
      CGIAlertDialogRef.current.Abrir();
    },
    Fechar() {
      CGIAlertDialogRef.current.Fechar();
    },
  }));

  const [Dados] = useState(RetornarDadosUsuario());
  const { setLoadingState } = useContext(LoadingContext);

  const CGIAlertDialogRef = useRef() as any;

  async function TrocarEmpresa(e: Empresa) {
    setLoadingState(true);
    await HttpCGIAlertDiolog()
      .TrocarEmpresa(e.id)
      .then(e => {
        GuardarDadosUsuario(e);
        window.location.reload();
      })
      .finally(() => {
        setLoadingState(false);
      });
  }

  return (
    <FormContext>
      <CGIAlertDialog
        ref={CGIAlertDialogRef}
        titulo={`Trocar Empresa (${Dados.empresas.length})`}
        confirmar={() => {}}
        ocultarConfirmar
      >
        <CGIBoxItem key={Dados.usuarioId} Col={12}>
          <List style={{ overflowY: "scroll", height: "300px" }}>
            {Dados.empresas.map((e, index) => {
              return (
                <ListItem key={index} mt={1} borderRadius={"md"} p={1}>
                  <CGIButton
                    onClick={() => {
                      TrocarEmpresa(e);
                    }}
                    att={{ width: "100%" }}
                    label={e.nome}
                  />
                </ListItem>
              );
            })}
          </List>
        </CGIBoxItem>
      </CGIAlertDialog>
    </FormContext>
  );
});
