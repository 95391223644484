import React, { createContext } from "react";
import { CGITrocarSenha } from "../Components/AlertDialog/CGITrocarSenha";

type TrocarSenhaContextTypes = {
  AbrirTrocarSenha: Function;
  FecharTrocarSenha: Function;
};

const DefaultValue: TrocarSenhaContextTypes = {
  AbrirTrocarSenha: () => {},
  FecharTrocarSenha: () => {},
};

export const TrocarSenhaContext =
  createContext<TrocarSenhaContextTypes>(DefaultValue);

function TrocarSenhaProvider(props: { children: React.ReactNode }) {
  const CGITrocarSenhaRef = React.useRef<any>();

  function AbrirTrocarSenha(ocultarCancelar = false) {
    CGITrocarSenhaRef.current.Abrir(ocultarCancelar);
  }
  function FecharTrocarSenha() {
    CGITrocarSenhaRef.current.Fechar();
  }

  return (
    <TrocarSenhaContext.Provider
      value={{ AbrirTrocarSenha, FecharTrocarSenha }}
    >
      {props.children}
      <CGITrocarSenha ref={CGITrocarSenhaRef} />
    </TrocarSenhaContext.Provider>
  );
}

export { TrocarSenhaProvider };
export default TrocarSenhaContext;
