/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Text } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CGIAlertDialog } from "../../../../Components/AlertDialog/CGIAlertDialog";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../../Components/Button/CGIButton";
import { CGIComboAjax } from "../../../../Components/Combos/CGIComboAjax";
import { CGIDataTableSimples } from "../../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIModal } from "../../../../Components/Modal/CGIModal";
import { CGINumberInput } from "../../../../Components/NumberInput/CGINumberInput";
import { CGIRadioButton } from "../../../../Components/RadioButton/CGIRadioButton";
import { CGIResumo } from "../../../../Components/Resumo/CGIResumo";
import LoadingContext from "../../../../Context/LoadingContext";
import { Temas } from "../../../../Themes/Temas";
import { ModalBaseType } from "../../../../Types/Modal/ModalBaseType";
import {
  ObjPedidoBalcaoPadrao,
  FormPedidoBalcaotypes,
  PedidoBalcaoGridItemType,
} from "../../../../Types/Vendas/Pedido/PedidoBalcaotype";
import { HttpPedido } from "../Http";

type CopiarPedidoType = {
  CarregarDados: Function;
};

export const AlterarPedidoBalcao = forwardRef(
  (props: CopiarPedidoType, ref) => {
    useImperativeHandle(ref, () => ({
      Abrir() {
        CGIModalRef.current?.Abrir();
        form.reset(ObjPedidoBalcaoPadrao);
        setTimeout(() => {
          form.setFocus("produtoId");
        }, 100);
      },
      Alterar(pEmpresa: any) {
        CGIModalRef.current?.Abrir();
      },
    }));
    const CGIDataTableSimplesRef = useRef<AgGridReact>(null);
    const CGIModalRef = useRef<ModalBaseType>(null);
    const FaturarPedidoBalcaoRef = useRef<ModalBaseType>(null);

    const form = useFormContext<FormPedidoBalcaotypes>();
    const { setLoadingState } = useContext(LoadingContext);

    const RetornarDadosProdutoSelecionado = useCallback(
      async (pProdutoId: number) => {
        const ProdutoSelecionado =
          await HttpPedido().RetornarDadosProdutosParaPedidoBalcao<PedidoBalcaoGridItemType>(
            pProdutoId
          );
        console.log("ProdutoSelecionado", ProdutoSelecionado, pProdutoId);
        form.setValue("ProdutoSelecionado", ProdutoSelecionado);
      },
      [form]
    );

    const ProdutoId = useWatch({
      control: form.control,
      name: "produtoId",
    });
    const Form = useWatch({
      control: form.control,
      name: "Form",
    });
    const produtoSelecionadoId = useWatch({
      control: form.control,
      name: "produtoSelecionadoId",
    });
    useEffect(() => {
      if (ProdutoId) {
        RetornarDadosProdutoSelecionado(ProdutoId);
        form.setFocus("ProdutoSelecionado.qtd");
      }
    }, [ProdutoId, RetornarDadosProdutoSelecionado, form]);

    const RecalcularPedido = useCallback(() => {
      const Pedido = form.getValues("Form");

      if (Pedido) {
        const Pedido = form.getValues("Form");
        console.log("this", this);
        const TotalDesconto = Pedido.itens.reduce(
          (total, item) =>
            total + (item.selecionado ? Number(item.vlrDesconto) : 0),
          0
        );

        var totalPedido = Pedido.itens.reduce(
          (total, item) =>
            total + (item.selecionado ? Number(item.vlrTotal) : 0),
          0
        );
        console.log("Pedido", Pedido);
        console.log("Itens", Pedido.itens);

        console.log(
          "TotalDesconto :" + TotalDesconto,
          "TotalPedido :" + totalPedido
        );

        form.setValue("Form.totalPedido", Pedido.totalPedido - TotalDesconto);
      }
    }, []);

    async function CarregarDados(id: number) {}

    const Confirmar = async (data: any, event: any) => {
      console.log(data);
      FaturarPedidoBalcaoRef.current?.Abrir();
    };

    const AdicionarItens = () => {
      let ProdutoSelecionado = form.getValues("ProdutoSelecionado");
      console.log("ProdutoSelecionado", ProdutoSelecionado);

      if (!ProdutoSelecionado.produtoId) {
        form.setFocus("produtoId");
        alert("Selecione um produto");
        return;
      }
      if (ProdutoSelecionado.qtd <= 0) {
        form.setFocus("ProdutoSelecionado.qtd");
        alert("A quantidade deve ser maior que zero");
        return;
      }
      if (ProdutoSelecionado.vlrUnitario <= 0) {
        form.setFocus("ProdutoSelecionado.vlrUnitario");
        alert("O valor unitário deve ser maior que zero");
        return;
      }
      ProdutoSelecionado.selecionado = true;
      let ItensGrid = form.getValues("Form.itens");
      const JaExistenoGrid = ItensGrid.findIndex(
        (item: any) => item.produtoId === ProdutoSelecionado.produtoId
      );
      console.log("JaExistenoGridItensGrid", ItensGrid, JaExistenoGrid);
      if (JaExistenoGrid !== -1) {
        ItensGrid[JaExistenoGrid].qtd =
          ItensGrid[JaExistenoGrid].qtd + ProdutoSelecionado.qtd;
        ItensGrid[JaExistenoGrid].vlrUnitario = ProdutoSelecionado.vlrUnitario;
      } else {
        ItensGrid.push(ProdutoSelecionado);
      }

      CGIDataTableSimplesRef.current?.api?.setRowData(ItensGrid);
      form.setValue("produtoId", ObjPedidoBalcaoPadrao.produtoId);
      form.setValue(
        "produtoSelecionadoId",
        ObjPedidoBalcaoPadrao.produtoSelecionadoId
      );
      form.setValue(
        "ProdutoSelecionado",
        ObjPedidoBalcaoPadrao.ProdutoSelecionado
      );

      form.setFocus("produtoId");
    };

    const GridColunas: Array<CGICol<PedidoBalcaoGridItemType>> = [
      {
        Campo: "selecionado",
        Titulo: "",
        Tipo: "CheckBox",
        att: {
          maxWidth: 50,
        },
      },
      {
        Campo: "id",
        Titulo: "",
        ocultar: true,
        Tipo: "Texto",
      },
      {
        Campo: "codigoProduto",
        Titulo: "Cod.",
        Tipo: "Texto",
        att: {
          maxWidth: 110,
        },
      },
      {
        Campo: "descricao",
        Titulo: "Descrição",
        Tipo: "Texto",
      },
      {
        Campo: "vlrUnitario",
        Titulo: "Vlr. Unit.",
        Tipo: "Moeda",
        att: {
          editable: true,
          maxWidth: 125,
        },
      },
      {
        Campo: "vlrDesconto",
        Titulo: "Vlr. Desc.",
        Tipo: "Moeda",
        att: {
          editable: true,
          maxWidth: 125,
        },
      },
      {
        Campo: "qtd",
        Titulo: "Qtd.",
        Tipo: "Numero",
        att: {
          editable: true,
          maxWidth: 80,
        },
      },
      {
        Campo: "vlrTotal",
        Titulo: "Vlr. Total",
        Tipo: "Moeda",
        att: {
          maxWidth: 110,
          editable: true,
          valueGetter: (e: any) => {
            return Number(
              Number(e.data.vlrUnitario * e.data.qtd) -
                Number(e.data.qtd * e.data.vlrDesconto)
            );
          },
        },
      },
    ];

    const Totalizadores = useMemo(() => {
      var Itens = form.getValues("Form.itens");

      if (!Itens) {
        return null;
      }

      const GridTotal = Itens.reduce((total: number, item: any) => {
        if (!item.selecionado) return total;
        return (
          total +
          Number(
            Number(item.vlrUnitario * item.qtd) -
              Number(item.qtd * item.vlrDesconto)
          )
        );
      }, 0);
      return (
        <CGIBox>
          <CGIBoxItem
            Att={{
              backgroundColor: Temas.Primaria,
              color: "white",
              fontWeight: "bold",
              fontSize: "1.2rem",
              mr: 2,
            }}
            Col={12}
          >
            <Flex justify={"space-around"}>
              <Text>Total do Pedido: </Text>
              <Text>{GridTotal.FormatarReal()}</Text>
            </Flex>
          </CGIBoxItem>
        </CGIBox>
      );
    }, [form, ProdutoId, produtoSelecionadoId]);
    return (
      <CGIModal
        tipoGET={{
          url: "PEDIDOBALCAO-INCLUIR",
          setRetorno: e => {
            console.log("Teste Pedido", e);
            //form.setValue("Form", e);
          },
        }}
        id="vendas/pedido/copiar"
        ref={CGIModalRef}
        Titulo="Pedido Balcão"
        TelaCheia={"full"}
        Confirmar={Confirmar}
        CarregarDados={CarregarDados}
        Fechar={() => {
          form.reset({});
          setLoadingState(false);
        }}
      >
        <CGIBox>
          <CGIBoxItem Col={4}>
            <CGIBox titulo="Pesquisar Produto">
              <CGIBoxItem
                Att={{
                  height: "25vh",
                }}
                Col={12}
              >
                <CGIBox>
                  <CGIBoxItem Col={12}>
                    <CGIComboAjax<FormPedidoBalcaotypes>
                      id={"produtoId"}
                      Link={"produto"}
                      label={"Produtos"}
                    />
                  </CGIBoxItem>
                  <CGIBoxItem Col={4}>
                    <CGINumberInput<FormPedidoBalcaotypes>
                      id={"ProdutoSelecionado.qtd"}
                      label={"Quantidade"}
                      att={{
                        onKeyDown: (e: any) => {
                          if (e.key === "Enter") AdicionarItens();
                        },
                      }}
                    />
                  </CGIBoxItem>
                  <CGIBoxItem Col={4}>
                    <CGINumberInput<FormPedidoBalcaotypes>
                      id={"ProdutoSelecionado.vlrUnitario"}
                      label={"Valor Unitário"}
                      att={{
                        onKeyDown: (e: any) => {
                          if (e.key === "Enter") AdicionarItens();
                        },
                      }}
                    />
                  </CGIBoxItem>
                  <CGIBoxItem Col={4}>
                    <CGIButton
                      att={{
                        mt: 8,
                        p: 2,
                        size: "sm",
                      }}
                      onClick={AdicionarItens}
                      label="Adicionar Item"
                    />
                  </CGIBoxItem>
                </CGIBox>
              </CGIBoxItem>
              <CGIBoxItem
                Att={{
                  height: "6vh",
                }}
                Col={12}
              >
                <CGIResumo
                  ProdutoId="Pesquisa.produtoSelecionadoId"
                  ResumoProdutoSimples
                />
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={8}>
            <CGIDataTableSimples
              ref={CGIDataTableSimplesRef}
              DisableGetId
              Altura="50vh"
              Colunas={GridColunas}
              Data={Form?.itens}
              Att={{
                defaultColDef: {
                  flex: 1,
                  filter: true,
                  wrapText: true,
                },
                onCellEditingStarted: e => {
                  RecalcularPedido();
                  form.setValue("produtoSelecionadoId", 0);
                },
                onCellEditingStopped: e => {
                  RecalcularPedido();
                },
                onRowClicked: e => {
                  RecalcularPedido();
                  form.setValue("produtoSelecionadoId", e.data.id);
                },
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={6}>
            <></>
          </CGIBoxItem>
          <CGIBoxItem
            Att={{
              height: "10vh",
              width: "100%",
            }}
            Col={6}
          >
            {Totalizadores}
          </CGIBoxItem>
        </CGIBox>
        <CGIAlertDialog
          size={"2xl"}
          ref={FaturarPedidoBalcaoRef}
          fechar={() => {}}
          titulo={"Faturar Pedido"}
          confirmar={() => {}}
        >
          <CGIBoxItem Col={8}>
            <CGIBox>
              <CGIBoxItem Col={12}>
                <CGIComboAjax<FormPedidoBalcaotypes>
                  id={"Form.clienteId"}
                  Link={"cliente"}
                  label={"Cliente"}
                />
              </CGIBoxItem>
              <CGIBoxItem Col={12}>
                <CGIBox titulo="Tipo">
                  <CGIBoxItem Col={6}>
                    <CGIRadioButton<FormPedidoBalcaotypes>
                      id={"Form.romaneioOuFiscal"}
                      row={"column"}
                      label={""}
                      values={[
                        {
                          label: "Romaneio",
                          valor: "R",
                        },
                        {
                          label: "Faturamento",
                          valor: "F",
                        },
                      ]}
                    />
                  </CGIBoxItem>
                  <CGIBoxItem hidden={Form?.romaneioOuFiscal !== "F"} Col={4}>
                    <CGIRadioButton<FormPedidoBalcaotypes>
                      id={"Form.nFeOuNFCe"}
                      row={"row"}
                      label={""}
                      values={[
                        {
                          label: "NFCe",
                          valor: "NFC",
                        },
                        {
                          label: "NFe",
                          valor: "NF",
                        },
                      ]}
                    />
                  </CGIBoxItem>
                </CGIBox>
              </CGIBoxItem>
              <CGIBoxItem Col={12}>
                <CGIBox spacing="10px" titulo="Pagamento">
                  <CGIBoxItem Col={6}>
                    <CGIRadioButton<FormPedidoBalcaotypes>
                      id={"tipoDesconto"}
                      row={"column"}
                      label={"Tipo Desconto"}
                      values={[
                        {
                          label: "R$",
                          valor: "V",
                        },
                        {
                          label: "%",
                          valor: "P",
                        },
                      ]}
                    />
                  </CGIBoxItem>
                  <CGIBoxItem Col={6}>
                    <CGINumberInput<FormPedidoBalcaotypes>
                      id={"Form.totalDesconto"}
                      label={"Valor de Desconto"}
                    />
                  </CGIBoxItem>
                  <CGIBoxItem Col={3}>
                    <CGIButton
                      label="Dinheiro"
                      att={{ w: "100%", size: "sm" }}
                    />
                  </CGIBoxItem>
                </CGIBox>
              </CGIBoxItem>
            </CGIBox>
          </CGIBoxItem>
          <CGIBoxItem Col={4}>{Totalizadores}</CGIBoxItem>
        </CGIAlertDialog>
      </CGIModal>
    );
    //FINAL JSX
  }
);
