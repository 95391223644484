import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGINumberInput } from "../../../Components/NumberInput/CGINumberInput";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import LoadingContext from "../../../Context/LoadingContext";
import {
  CurvaABCTypes,
  FormCurvaABCType,
} from "../../../Types/Sistema/Produto/CurvaABCTypes";
import { HttpProduto } from "./Http";

async function CarregarDados(id: number) {
  // HttpPedido()
  //   .RetornarDadosPedido(id)
  //   .then((e) => {
  //     form.reset(e.data.data);
  //   });
}

type CurvaABCType = {
  CarregarDados: Function;
};

export const CurvaABC = forwardRef((props: CurvaABCType, ref) => {
  //INICIO  HOOKS
  useImperativeHandle(ref, () => ({
    Abrir() {
      CGIModalRef.current.Abrir();
    },
    Alterar(pProduto: any) {
      CarregarDados(pProduto.Id);
      CGIModalRef.current.Abrir();
    },
  }));

  const CGIModalRef = useRef() as any;
  const form = useFormContext<CurvaABCTypes>();
  const { ToastErro, ToastSucesso } = useCGIToast();
  const { setLoadingState } = useContext(LoadingContext);

  //FINAL  HOOKS

  //INICIO CONST
  const RenderTabs: Array<CGITabItensTypes> = [
    {
      Titulo: "Curva ABC",
      Component: AreaDadosABC(),
    },
  ];
  //FINAL  CONST

  //INICO FUNC
  function AreaDadosABC() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGIBox titulo="Datas">
            <CGIBoxItem Col={6}>
              <CGIDatePicker<FormCurvaABCType>
                id="Form.DadosRegistro.dataInicial"
                label="Data Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={6}>
              <CGIDatePicker<FormCurvaABCType>
                id="Form.DadosRegistro.dataFinal"
                label="Data Final"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGINumberInput<FormCurvaABCType>
            id="Form.DadosRegistro.percA"
            label="% A"
            validadores={{
              max: 100,
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGINumberInput<FormCurvaABCType>
            id="Form.DadosRegistro.percB"
            label="% B"
            qtdCasasDecimais={0}
            validadores={{
              max: 100,
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGINumberInput<FormCurvaABCType>
            id="Form.DadosRegistro.percC"
            label="% C"
            qtdCasasDecimais={0}
            validadores={{
              max: 100,
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples<FormCurvaABCType>
            id="Form.DadosRegistro.leituraPedidoId"
            label="Leitura Pedido"
            Link="leiturapedidos"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIComboSimples<FormCurvaABCType>
            id="Form.DadosRegistro.tipoVendaId"
            label="Tipo Venda"
            Link="tiposvenda"
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  const Confirmar = async (data: FormCurvaABCType, event: any) => {
    setLoadingState(true);

    HttpProduto()
      .CurvaABC(data)
      .then((data: any) => {
        ToastSucesso("Registro Alterado com sucesso", "OK!");
        props.CarregarDados();
        CGIModalRef.current.Fechar();
      })
      .catch((e) => {
        return;
      })
      .finally(() => {
        setLoadingState(false);
      });
  };
  //FINAL FUNC

  //INICO JSX
  return (
    <CGIModal
      id="sistema/produto/alterarncmcodigocestgeral"
      ref={CGIModalRef}
      Titulo="Curva ABC"
      TelaCheia={"7xl"}
      Confirmar={Confirmar}
      Fechar={() => {
        form.reset({});
      }}
    >
      <CGITabs Tabs={RenderTabs} />
    </CGIModal>
  );
  //FINAL JSX
});
