import { Center, Flex, Text } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { CGIBox } from "../../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../../Components/Box/CGIBoxItens";
import { CGINumberInput } from "../../../../../Components/NumberInput/CGINumberInput";
import {
  ItensPedidoType,
  PedidoLojaType,
} from "../../../../../Types/Vendas/Pedido/PedidoLojaType";
import PedLojaContext from "../_AlterarPedidoLoja";

export function PEDLojaTotalizador() {
  const { GridItensPrincipalRef, form } = useContext(PedLojaContext);

  const Totalizadores = useWatch({
    control: form.control,
    name: "DadosProdutoSelecionado",
  });

  const ListaTotais = useCallback(() => {
    let rowData: Array<ItensPedidoType> = [];
    GridItensPrincipalRef.current?.api.forEachNode((node) => {
      return node.data.selecionado && rowData.push(node.data);
    });

    let totalProdutos = rowData.reduce((acc, obj) => {
      return acc + obj.precoVenda * obj.quantidade;
    }, 0);

    let totalDesconto = rowData.reduce((acc, obj) => {
      return acc + obj.quantidade * obj.vlrDesconto;
    }, 0);

    let totalPedido = rowData.reduce((acc, obj) => {
      return (
        acc +
        Number(
          Number(obj.precoVenda * obj.quantidade) -
            Number(obj.quantidade * obj.vlrDesconto)
        )
      );
    }, 0);
    let totalIPI = 0;

    form.setValue("DadosRegistro.totalProdutos", totalProdutos);
    form.setValue("DadosRegistro.totalVlrDescontoPorItem", totalDesconto);
    form.setValue("DadosRegistro.totalPedido", totalPedido);

    return (
      <CGIBox spacing="opx">
        <CGIBoxItem Col={12}>
          <Flex justifyContent={"space-between"}>
            <Text fontWeight={"bold"}>Total dos Produtos</Text>
            <Text fontWeight={"bold"} color={"blue.500"}>
              {totalProdutos.FormatarReal()}
            </Text>
          </Flex>
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <Flex justifyContent={"space-between"}>
            <Text fontWeight={"bold"}>Total Desconto</Text>
            <Text fontWeight={"bold"} color={"blue.500"}>
              {totalDesconto.FormatarReal()}
            </Text>
          </Flex>
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <Flex justifyContent={"space-between"}>
            <Text fontWeight={"bold"}>Total IPI</Text>
            <Text fontWeight={"bold"} color={"blue.500"}>
              {totalIPI.FormatarReal()}
            </Text>
          </Flex>
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <Flex justifyContent={"space-between"}>
            <Text fontWeight={"bold"}>Total Pedido</Text>
            <Text fontWeight={"bold"} color={"blue.500"}>
              {totalPedido.FormatarReal()}
            </Text>
          </Flex>
        </CGIBoxItem>
      </CGIBox>
    );
  }, [Totalizadores]);

  return (
    <CGIBox spacing="0px" titulo="Totalizadores" popover="Totalizadores">
      <CGIBoxItem Col={12}>{ListaTotais()}</CGIBoxItem>
    </CGIBox>
  );
}
