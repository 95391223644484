export const ObjArquivoRemessaPadrao: FormArquivoRemessaType = {
  Form: {
    DadosRegistro: {
      id: 0,
      dataCadastro: "",
      usuarioId: 0,
      convenioPortadorId: 0,
      qtdTitulos: 0,
      valorRemessa: 0,
      statusId: 0,
      tipoOrigem: "",
      empresaId: 0,
      portadorId: 0,
      numeroSequencialDoArquivo: 0,
      localPagamento: "",
    },
  },
};

export type ArquivoRemessaTypes = {
  DadosRegistro: {
    id: number;
    dataCadastro: string;
    localPagamento: string;
    usuarioId: number;
    convenioPortadorId: number;
    qtdTitulos: number;
    valorRemessa: number;
    statusId: number;
    portadorId: number;
    tipoOrigem: string;
    empresaId: number;
    numeroSequencialDoArquivo: number;
  };
};

export type FormArquivoRemessaType = {
  Form: ArquivoRemessaTypes;
};

export const ObjListarArquivoRemessaPadrao: ListarArquivoRemessaTypes = {
  Pesquisa: {
    convenioPortadorId: 0,
    dataCadastroInicial: "",
    dataCadastroFinal: "",
  },
  Itens: [],
};

export type ArquivoRemessaPesquisaGridTypes = {
  id: number;
  dataCadastro: string;
  valorRemessa: number;
  qtdTitulos: number;
  nomeConvenio: string;
  nomePortador: string;
  nomeStatus: string;
  nomeCondominio: string;
  nomeEmpresa: string;
  numero: string;
};

export type ListarArquivoRemessaTypes = {
  Pesquisa: ListarPesquisaArquivoRemessaTypes;
  Itens: ListarArquivoRemessaItensTypes[];
};
export type IncluirArquivoRemessaTypes = {
  Pesquisa: IncluirPesquisaArquivoRemessaTypes;
  Itens: ListarArquivoRemessaItensTypes[];
};

export type ListarPesquisaArquivoRemessaTypes = {
  convenioPortadorId: number;
  dataCadastroInicial: string;
  dataCadastroFinal: string;
};

export type IncluirPesquisaArquivoRemessaTypes = {
  portadorId: number;
  dataInicialInclusao: string;
  dataFinalInclusao: string;
  dataInicialVencimento: string;
  dataFinalVencimento: string;
  empresaId: number;
  convenioPortadorId: number;
};

export type IncluirArquivoRemessaItensTypes = {
  id: number;
  dataVencimento: string;
  documento: string;
  nomeCliente: string;
  valor: number;
  selecionado: boolean;
};

export type ListarArquivoRemessaItensTypes = {
  id: number;
  dataCadastro: string;
  valorRemessa: number;
  qtdTitulos: number;
  nomeConvenio: string;
  nomePortador: string;
  nomeStatus: string;
  nomeCondominio: string;
  nomeEmpresa: string;
  numero: string;
};

export type VisualizarArquivoRemessaTypes = {
  id: number;
  dataCadastro: string;
  nomeEmpresa: string;
  nomeUsuario: string;
  nomePortador: string;
  qtdTitulos: number;
  valorRemessa: number;
};

export type VisualizarArquivoRemessaItensTypes = {
  id: number;
  dataVencimento: string;
  Documento: number;
  nomeCliente: number;
  nomeStatus: string;
  valor: string;
  excluido: boolean;
};
export type AlterarArquivoRemessaTypes = {
  id: number;
  dataCadastro: string;
  nomeEmpresa: string;
  nomeUsuario: string;
  nomePortador: string;
  qtdTitulos: number;
  valorRemessa: number;
};

export type AlterarArquivoRemessaItensTypes = {
  id: number;
  selecionado: boolean;
  dataVencimento: string;
  documento: string;
  nomeCliente: number;
  valor: string;
  excluido: boolean;
};

export type AlterarArquivoRemessaItensExcluidosTypes = {
  id: number;
  dataVencimento: string;
  documento: string;
  nomeCliente: number;
  valor: string;
  excluido: boolean;
};
