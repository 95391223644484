export const ObjConfigCFOPPadrao: FormConfigCFOPType = {
  Form: {
    DadosRegistro: {
      id: 0,
      nome: "",
      textoPadrao: "",
      cfopId: 0,
      ativo: false,
      cstId: 0,
      cfopDevolucaoId: 0,
      calcularICMS: false,
      calcularICMSST: false,
      calcularIPI: false,
      calcularPIS: false,
      calcularCOFINS: false,
      calcularFCP: false,
      calcularFCPST: false,
      calcularFCPSTRet: false,
      alterarPercICMS: false,
      percICMS: 0,
      percReducaoICMS: 0,
      percDiferimentoICMS: 0,
      percDiferimentoFCP: 0,
      removerIPIBaseCalculoICMS: false,
      movimentarEstoque: false,
      gerarFinanceiro: false,
      empresaId: 0,
      efetuarMovNoPedido: false,
      calcularICMSSN: false,
      calcularDifal: false,
    },
  },
};
export type ConfigCFOPTypes = {
  id: number;
  textoPadrao: string;
  ativo: boolean;
  nome: string;
  empresaId: number;
  cfopId: number;
  cstId: number;
  cfopDevolucaoId: number;
  calcularICMS: boolean;
  calcularICMSST: boolean;
  calcularIPI: boolean;
  calcularPIS: boolean;
  calcularCOFINS: boolean;
  calcularFCP: boolean;
  calcularFCPST: boolean;
  calcularFCPSTRet: boolean;
  alterarPercICMS: boolean;
  percICMS: number;
  percReducaoICMS: number;
  percDiferimentoICMS: number;
  percDiferimentoFCP: number;
  removerIPIBaseCalculoICMS: boolean;
  movimentarEstoque: boolean;
  gerarFinanceiro: boolean;
  efetuarMovNoPedido: boolean;
  calcularICMSSN: boolean;
  calcularDifal: boolean;
};

export type FormConfigCFOPType = {
  Form: {
    DadosRegistro: ConfigCFOPTypes;
  };
};

//------ TIPO ALTERAR

export const ObjListarConfigCFOPPadrao: ListarConfigCFOPTypes = {
  Pesquisa: {
    listaEmpresasId: "",
    listaCSTsId: "",
    listaCFOPsId: "",
    ativoId: 0,
  },
  Itens: [],
};

export type ConfigCFOPPesquisaGridTypes = {
  codigo?: number;
  nome?: string;
  nomeCFOP?: string;
};

export type ListarConfigCFOPTypes = {
  Pesquisa: ListarPesquisaConfigCFOPTypes;
  Itens: ListarConfigCFOPGridItensTypes[];
};

export type ListarPesquisaConfigCFOPTypes = {
  listaEmpresasId: string;
  listaCSTsId: string;
  listaCFOPsId: string;
  ativoId: number;
};

export type ListarConfigCFOPGridItensTypes = {
  id: number;
  ativo: boolean;
  //ativoId: number;
  nomeEmpresa: string;
  nome: string;
  nomeStatus: string;
  nomeCFOP: string;
  nomeCFOPDevolucao: string;
  nomeCST: string;
  cfopId: number;
  cstId: number;
  cfopDevolucaoId: number;
  calcularICMS: boolean;
  calcularICMSST: boolean;
  calcularIPI: boolean;
  calcularPIS: boolean;
  calcularCOFINS: boolean;
  calcularFCP: boolean;
  calcularFCPST: boolean;
  calcularFCPSTRet: boolean;
  alterarPercICMS: boolean;
  percICMS: number;
  percReducaoICMS: number;
  percDiferimentoICMS: number;
  percDiferimentoFCP: number;
  removerIPIBaseCalculoICMS: boolean;
  movimentarEstoque: boolean;
  gerarFinanceiro: boolean;
  calcularDifal: boolean;
};

export type ConfigCFOPVisualizarTypes = {
  id: number;
  dataCadastro: string;
  dataConfigCFOP: string;
  nomePosto: string;
  numeroNF: number;
  nomeEmpresa: string;
  nomeCentroDeCusto: string;
  combustivel: string;
  nomeEquipamento: string;
  nomeColaborador: number;
  qtdLitros: number;
  obs: string;
  valorUnitario: number;
  valorTotal: number;
};
