import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  Input,
  Checkbox,
  Button,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../Components/Button/CGIButton";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { CGINumberInput } from "../../../Components/NumberInput/CGINumberInput";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ParametroGridItensTypes,
  ParametroPesquisaType,
  ParametroTypes,
} from "../../../Types/Sistema/Parametros/ParametroTypes";
import { HttpParametros } from "./Http";

export function ListarParametros() {
  const form = useForm<ParametroTypes>();
  const { setLoadingState, Loadingstate } = useContext(LoadingContext);
  const ArryGridItensParametroGlobal = useFieldArray({
    control: form.control,
    name: "parametrosGlobais",
  });

  const ArrayGridParametroMultiEmpresa = useFieldArray({
    control: form.control,
    name: "parametrosMultiEmpresa",
  });

  useEffect(() => {
    //Pesquisar();
  }, []);
  const Pesquisar = async () => {
    setLoadingState(true);
    form.setValue("parametrosGlobais", []);
    form.setValue("parametrosMultiEmpresa", []);
    const DadosPesquisa = form.getValues("pesquisa");
    Promise.all([
      RetornarParametrosGlobais(DadosPesquisa),
      RetornarParametrosMultiEmpresa(DadosPesquisa),
    ]).then(values => {
      form.setValue("parametrosGlobais", values[0]);
      form.setValue("parametrosMultiEmpresa", values[1]);
      setLoadingState(false);
    });
  };
  const RetornarParametrosGlobais = async (
    pDadosFiltros?: ParametroPesquisaType
  ) => {
    const Dados = await HttpParametros().RetornarParametros(pDadosFiltros);
    return Dados;
  };
  const RetornarParametrosMultiEmpresa = async (
    pDadosFiltros?: ParametroPesquisaType
  ) => {
    const Dados = await HttpParametros().RetornarParametrosMultiEmpresa(
      pDadosFiltros
    );
    return Dados;
  };
  const DefinirParamentroMultiEmpresa = async (
    pDados: ParametroGridItensTypes
  ) => {
    try {
      setLoadingState(true);
      await HttpParametros().DefinirParametroMultiEmpresa(pDados.parametroId);
      Pesquisar();
    } finally {
      setLoadingState(false);
    }
  };
  const DefinirParamentroGlobal = async (pDados: ParametroGridItensTypes) => {
    try {
      setLoadingState(true);
      await HttpParametros().DefinirParametroGlobal(pDados.parametroId);
      Pesquisar();
    } finally {
      setLoadingState(false);
    }
  };

  const Confirmar = async () => {
    try {
      setLoadingState(true);
      let parametrosGlobais = form.getValues("parametrosGlobais");
      let parametrosMultiEmpresa = form.getValues("parametrosMultiEmpresa");

      await HttpParametros().AlterarParamentros(
        parametrosGlobais,
        parametrosMultiEmpresa
      );
    } finally {
      setLoadingState(false);
      Pesquisar();
    }
  };

  const Filtros = () => {
    return (
      <CGIBox titulo="Filtro">
        <CGIBoxItem Col={3}>
          <CGINumberInput<ParametroTypes> id={"pesquisa.id"} label={"Id"} />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput<ParametroTypes>
            id={"pesquisa.nome"}
            placeholder={"Nome"}
            label={"Nome"}
          />
        </CGIBoxItem>

        <CGIBoxItem Col={6}>
          <CGIButton
            att={{
              mt: 30,
            }}
            label="Pesquisar"
            onClick={() => {
              Pesquisar();
            }}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  };

  const GridParametrosGlobais = () => {
    return (
      <TableContainer overflowY="scroll" height={"250px"}>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>Nome</Th>
              <Th>Módulo</Th>
              <Th>Valor</Th>
              <Th>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {ArryGridItensParametroGlobal.fields.map((item, index) => {
              return (
                <Tr key={index}>
                  <Td fontWeight={"bold"}>{item.parametroId}</Td>
                  
                  <Td fontWeight={"bold"}>
                    {item.nome}
                    <Td fontWeight={"normal"}>{item.obs}</Td>
                  </Td>
                  <Td fontWeight={"bold"}>{item.modulo}</Td>
                  <Td>
                    {item.validacao === "BOOL" ? (
                      <input
                        type="checkbox"
                        disabled={Loadingstate}
                        key={item.id}
                        {...form.register(
                          `parametrosGlobais.${index}.valorBOOL`
                        )}
                      />
                    ) : (
                      <input
                        type={"text"}
                        key={item.id}
                        {...form.register(`parametrosGlobais.${index}.valor`, {
                          disabled: Loadingstate,
                        })}
                      />
                    )}
                  </Td>
                  <Td>
                    <Button
                      isLoading={Loadingstate}
                      colorScheme={"green"}
                      onClick={() => {
                        DefinirParamentroMultiEmpresa(item);
                      }}
                    >
                      Multi Empresa
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    );
  };
  const GridParametrosMultiEmpresa = () => {
    return (
      <TableContainer overflowY="scroll" height={"250px"}>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>Nome</Th>
              <Th>Módulo</Th>
              <Th>Valor</Th>
              <Th>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {ArrayGridParametroMultiEmpresa.fields.map((item, index) => {
              return (
                <Tr key={index}>
                  <Td fontWeight={"bold"}>{item.parametroId}</Td>
                  <Td fontWeight={"bold"}>
                    {item.nome}
                    <Td fontWeight={"normal"}>{item.obs}</Td>
                  </Td>
                  <Td fontWeight={"bold"}>{item.modulo}</Td>
                  <Td>
                    {item.validacao === "BOOL" ? (
                      <input
                        type="checkbox"
                        disabled={Loadingstate}
                        key={item.id}
                        {...form.register(
                          `parametrosMultiEmpresa.${index}.valorBOOL`
                        )}
                      />
                    ) : (
                      <input
                        type={"text"}
                        key={item.id}
                        {...form.register(
                          `parametrosMultiEmpresa.${index}.valor`,
                          {
                            disabled: Loadingstate,
                          }
                        )}
                      />
                    )}
                  </Td>
                  <Td>
                    <Button
                      isLoading={Loadingstate}
                      colorScheme={"green"}
                      onClick={() => {
                        DefinirParamentroGlobal(item);
                      }}
                    >
                      Global
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <CGIListar
        id="sistema/equipamento/listar"
        Titulo="Parâmetros"
        Janelas={[]}
      >
        <FormProvider {...form}>
          <CGIBox>
            <CGIBoxItem Col={12}>{Filtros()}</CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIBox titulo="Parâmetros Globais">
                <CGIBoxItem Col={12}>{GridParametrosGlobais()}</CGIBoxItem>
              </CGIBox>
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIBox titulo="Parâmetros Multi-Empresa">
                <CGIBoxItem Col={12}>{GridParametrosMultiEmpresa()}</CGIBoxItem>
              </CGIBox>
            </CGIBoxItem>

            <CGIBoxItem Att={{ justifySelf: "flex-end" }} Col={12}>
              <CGIButton
                label="Confirmar"
                onClick={() => {
                  Confirmar();
                }}
              />
            </CGIBoxItem>
          </CGIBox>
        </FormProvider>
      </CGIListar>
    </>
  );
}
