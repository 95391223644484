import { CGIBox } from "../../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../../Components/Box/CGIBoxItens";
import { CGIResumo } from "../../../../../Components/Resumo/CGIResumo";

export function AreaDadosCliente() {
  return (
    <CGIBox>
      <CGIBoxItem Col={12}>
        <CGIResumo Expandir ColaboradorId="Form.clienteId" ResumoColaborador />
      </CGIBoxItem>
    </CGIBox>
  );
}
