import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext, useCallback, useState, useMemo } from "react";
import {
  useForm,
  FormProvider,
  useWatch,
  useFormContext,
} from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboAjax } from "../../../Components/Combos/CGIComboAjax";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDataTable } from "../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIListar } from "../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../Context/LoadingContext";
import {
  ColaboradorTypes,
  ListarColaboradorGridItensTypes,
  ListarColaboradorItensTypes,
  ListarColaboradorTypes,
  ListarPesquisaColaboradorTypes,
  ObjListarColaboradorPadrao,
} from "../../../Types/Financeiro/Colaborador/ColaboradorTypes";
import {
  ModalBaseType,
  LegendaTypes,
} from "../../../Types/Modal/ModalBaseType";
import { DeleteTypes } from "../../../Types/Util/DeleteTypes";
import { HttpColaborador } from "./Http";
import { AlterarColaborador } from "./_AlterarColaborador";
import { VisualizarColaborador } from "./_VisualizarColaborador";

export function ListarColaborador() {
  const methods = useForm<ListarColaboradorTypes>({
    defaultValues: ObjListarColaboradorPadrao,
  });
  const AlterarColaboradorRef = useRef<ModalBaseType>();
  const VisualizarColaboradorRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);
  const [ExibirCliente, setExibirCliente] = useState(false);
  const EhCliente = useWatch({
    control: methods.control,
    name: "Pesquisa.ehCliente",
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  const RetornarColaboradores = useCallback(async () => {
    try {
      setLoadingState(true);
      const DadosFiltros = methods.getValues("Pesquisa");
      var data = await HttpColaborador().RetornarColaboradores(DadosFiltros);
      methods.setValue("Itens", data);
    } finally {
      setLoadingState(false);
    }
  }, [methods, setLoadingState]);

  const RetornarDadosExclusao = useCallback(
    async (data: ListarColaboradorItensTypes) => {
      return await HttpColaborador().RetornarDadosExclusao(data);
    },
    []
  );
  async function Excluir(data: DeleteTypes) {
    await HttpColaborador()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }

  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo Colaborador",
      Permissao: "financeiro/colaborador/incluir",
      OnClick: () => {
        AlterarColaboradorRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Alterar",
      Permissao: "financeiro/colaborador/alterar",
      OnClick: (e: ListarColaboradorItensTypes) => {
        AlterarColaboradorRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "financeiro/colaborador/excluir",
      OnClick: (e: ListarColaboradorItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
    {
      Nome: "Visualizar",
      Permissao: "financeiro/colaborador/visualizar",
      OnClick: (e: ListarColaboradorItensTypes) => {
        VisualizarColaboradorRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas: LegendaTypes = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarColaboradorGridItensTypes>> = [
    {
      Campo: "nomeStatus",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: Legendas,
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 90,
      },
    },
    {
      Campo: "nomeCompleto",
      Titulo: "Nome Completo",
      Tipo: "Texto",
      att: {
        minWidth: 300,
      },
    },
    {
      Campo: "cpfcnpj",
      Titulo: "CPF  CNPJ",
      Tipo: "Texto",
      att: {
        maxWidth: 220,
      },
    },
    {
      Campo: "nomeEmpresa",
      Titulo: "Empresa",
      Tipo: "Texto",
      att: {
        maxWidth: 220,
      },
    },
    {
      Campo: "nomeCidade",
      Titulo: "Cidade",
      Tipo: "Texto",
      att: {
        maxWidth: 220,
      },
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarColaboradorGridItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
    {
      Campo: "id",
      Titulo: "Mobile",
      Tipo: "Mobile",
      att: {
        flex: 4,
      },
      Composta: [
        {
          titulo: "Id",
          valor: "id",
        },
        {
          titulo: "Nome",
          valor: "nomeCompleto",
        },
        {
          titulo: "CPF  CNPJ",
          valor: "cpfcnpj",
        },
      ],
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Colaborador"
          CarregarDados={RetornarColaboradores}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarColaborador
          CarregarDados={methods.handleSubmit(RetornarColaboradores)}
          ref={AlterarColaboradorRef}
        />
      ),
    },
    {
      Componente: (
        <VisualizarColaborador
          CarregarDados={methods.handleSubmit(RetornarColaboradores)}
          ref={VisualizarColaboradorRef}
        />
      ),
    },
  ];

  function Filtros() {
    return (
      <CGIBox>
        <CGIBoxItem Col={2}>
          <CGIBox titulo="Atuação" popover="Atuação">
            <CGIBoxItem Col={12}>
              <CGICheckBox<ListarColaboradorTypes>
                id="Pesquisa.ehCliente"
                label="Cliente"
                tooltip="Cliente"
              />
              <CGICheckBox<ListarColaboradorTypes>
                id="Pesquisa.ehFuncionario"
                label="Funcionário"
                tooltip="Funcionário"
              />
              <CGICheckBox<ListarColaboradorTypes>
                id="Pesquisa.ehFornecedor"
                label="Fornecedor"
                tooltip="Fornecedor"
              />
              <CGICheckBox<ListarColaboradorTypes>
                id="Pesquisa.ehVendedor"
                label="Vendedor"
                tooltip="Vendedor"
              />
              <CGICheckBox<ListarColaboradorTypes>
                id="Pesquisa.ehTransportadora"
                label="Transportadora"
                tooltip="Transportadora"
              />
              <CGICheckBox<ListarColaboradorTypes>
                id="Pesquisa.ehSeguradora"
                label="Seguradora"
                tooltip="Seguradora"
              />
              <CGICheckBox<ListarColaboradorTypes>
                id="Pesquisa.ehRepresentante"
                label="Representante"
                tooltip="Representante"
              />
              <CGICheckBox<ListarColaboradorTypes>
                id="Pesquisa.ehRespFinanceiro"
                label="Resp. Financeiro"
                tooltip="Resp. Financeiro"
              />
              <CGICheckBox<ListarColaboradorTypes>
                id="Pesquisa.ehPaciente"
                label="Paciente"
                tooltip="Paciente"
              />
              <CGICheckBox<ListarColaboradorTypes>
                id="Pesquisa.ehPosto"
                label="Posto de Combustível"
                tooltip="Posto de Combustível"
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={10}>
          <CGIBox>
            <CGIBoxItem Col={4}>
              <CGIInput<ListarColaboradorTypes>
                id="Pesquisa.nomeCompleto"
                label="Nome \ Razão Social"
                placeholder="Nome \ Razão Social"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIInput<ListarColaboradorTypes>
                id={"Pesquisa.cpfcnpj"}
                label="CPF \ CNPJ"
                placeholder="CPF \ CNPJ"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarColaboradorTypes>
                Multipla
                id="Pesquisa.listaTiposTributacaoId"
                label="Tipo Tributação"
                Link="tipotributacao"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIBox>
                <CGIBoxItem Col={2}>
                  <CGIComboSimples<ListarColaboradorTypes>
                    Multipla
                    id="Pesquisa.ativoId"
                    label="Ativo"
                    Link={"simnaoambos"}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={4}>
                  <CGIComboSimples<ListarColaboradorTypes>
                    Multipla
                    id="Pesquisa.listaEmpresasId"
                    Link={"empresa"}
                    label={"Empresa"}
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={3}>
                  <CGIInput<ListarColaboradorTypes>
                    id="Pesquisa.codigoExterno"
                    label="Código Externo"
                    placeholder="Código Externo"
                  />
                </CGIBoxItem>
                <CGIBoxItem Col={3}>
                  <CGIComboSimples<ListarColaboradorTypes>
                    Multipla
                    id="Pesquisa.listaVendedoresId"
                    label="Vendedor"
                    Link="Vendedor"
                  />
                </CGIBoxItem>
              </CGIBox>
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIComboSimples<ListarColaboradorTypes>
                Multipla
                id="Pesquisa.listaCidadesId"
                label="Cidade"
                Link="cidade"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={2}>
              <CGIComboSimples<ListarColaboradorTypes>
                Multipla
                id="Pesquisa.listaEstadosId"
                label="Estado"
                Link="estado"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIInput<ListarColaboradorTypes>
                id="Pesquisa.bairro"
                label="Bairro"
                placeholder="Bairro"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={2}>
              <CGIComboSimples<ListarColaboradorTypes>
                id="Pesquisa.entregaEspecialId"
                label="Entrega Especial"
                Link="simnaoambos"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGICheckBox<ListarColaboradorTypes>
                id="Pesquisa.exibirFornecedoresCriticos"
                label="Exibir apenas fornecedores críticos"
                tooltip=""
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGIComboSimples<ListarColaboradorTypes>
                Multipla
                id="Pesquisa.listaGruposEconomicosId"
                label="Grupo Econômico"
                Link="grupoeconomico"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGIDatePicker<ListarColaboradorTypes>
                id="Pesquisa.dataNascimentoInicial"
                label="Data Nascimento - Inicial"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGIDatePicker<ListarColaboradorTypes>
                id="Pesquisa.dataNascimentoFinal"
                label="Data Nascimento - Final"
              />
            </CGIBoxItem>
            <CGIBoxItem hidden={!EhCliente} Col={3}>
              <CGIComboSimples<ListarColaboradorTypes>
                Multipla
                id="Pesquisa.listaPracasId"
                label="Praça"
                Link="praca"
              />
            </CGIBoxItem>
            <CGIBoxItem hidden={!EhCliente} Col={3}>
              <CGIComboSimples<ListarColaboradorTypes>
                Multipla
                id="Pesquisa.listaRotasId"
                label="Rota"
                Link="rota"
              />
            </CGIBoxItem>
            <CGIBoxItem hidden={!EhCliente} Col={3}>
              <CGIComboSimples<ListarColaboradorTypes>
                Multipla
                id="Pesquisa.listaTiposClienteId"
                label="Tipo Cliente"
                Link="tipocliente"
              />
            </CGIBoxItem>
            <CGIBoxItem hidden={!EhCliente} Col={3}>
              <CGIInput<ListarColaboradorTypes>
                id="Pesquisa.numeroDiasSemComprarCliente"
                label="Nº Dias S/ Comprar"
                placeholder="Nº Dias S/ Comprar"
              />
            </CGIBoxItem>
            <CGIBoxItem hidden={!EhCliente} Col={3}>
              <CGICheckBox<ListarColaboradorTypes>
                id="Pesquisa.exibirApenasClientesBloqueados"
                label="Exibir apenas clientes com bloqueio"
                tooltip=""
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    );
  }

  return (
    <CGIListar
      id="financeiro/colaborador/listar"
      Titulo="Colaborador"
      Janelas={Janelas}
    >
      <FormProvider {...methods}>
        <CGIDataTable
          LimparFiltros={() => methods.reset()}
          Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
          Pesquisar={methods.handleSubmit(RetornarColaboradores)}
          Filtro={Filtros()}
          Data={GridItens}
          Colunas={GridColunas}
          Legenda={Legendas}
        />
      </FormProvider>
    </CGIListar>
  );
  // Final JSX
}
