import {
  forwardRef,
  useImperativeHandle,
  useRef,
  useContext,
  useState,
} from "react";
import { CGIAlertDialog } from "../../../Components/AlertDialog/CGIAlertDialog";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGIInput } from "../../../Components/Input/CGIInput";
import LoadingContext from "../../../Context/LoadingContext";
import { HttpUsuario } from "./Http";

type TrocarSenhaType = {
  titulo: string;
};
export const TrocarSenhaUsuario = forwardRef((props: TrocarSenhaType, ref) => {
  useImperativeHandle(ref, () => ({
    individual(e: any) {
      setDados({
        id: e.id,
        Titulo: `Trocar a senha do Usuário [${e.nome}]`,
        EhIndividual: true,
      });
      CGIAltertDiologRef.current.Abrir();
    },
    todos(e: any) {
      setDados({
        id: 0,
        Titulo: `Trocar a senha de Todos os Usuários`,
        EhIndividual: false,
      });
      CGIAltertDiologRef.current.Abrir();
    },
  }));

  const CGIAltertDiologRef = useRef() as any;
  const { setLoadingState } = useContext(LoadingContext);

  const [Dados, setDados] = useState({
    id: 0,
    Titulo: "",
    EhIndividual: false,
  });

  return (
    <CGIAlertDialog
      ref={CGIAltertDiologRef}
      titulo={Dados.Titulo}
      confirmar={async (e: any) => {
        if (Dados.EhIndividual) {
          await HttpUsuario()
            .TrocarSenhaIndividual({ id: Dados.id, obs: e.obs })
            .then(() => {
              CGIAltertDiologRef.current.Fechar();
            })
            .finally(() => {
              setLoadingState(false);
            });
        } else {
          await HttpUsuario()
            .TrocarSenhaTodos()
            .then(() => {
              CGIAltertDiologRef.current.Fechar();
            })
            .finally(() => {
              setLoadingState(false);
            });
        }
      }}
    >
      <CGIBoxItem hidden={!Dados.EhIndividual} Col={12}>
        <CGIInput
          label="Observação"
          id={"obs"}
          placeholder={"Observação"}
          validadores={{
            required: "Campo Obrigatório",
            minLength: { value: 5, message: "Minimo de [ 5 ] Caracteres" },
          }}
        />
      </CGIBoxItem>
    </CGIAlertDialog>
  );
});
