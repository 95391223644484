import { forwardRef, useImperativeHandle } from "react";
import { CGIBox } from "../../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../../../Components/CheckBox/CGICheckBox";
import { CGIInput } from "../../../../../Components/Input/CGIInput";
import { PedidoLojaType } from "../../../../../Types/Vendas/Pedido/PedidoLojaType";

export const AreaFiscal = forwardRef((props: {}, ref) => {
  useImperativeHandle(ref, () => ({}));
  return (
    <CGIBox>
      <CGIBoxItem Col={12}>
        <CGICheckBox<PedidoLojaType>
          id={"DadosNFSaida.autorizarNFlogoAposFaturamento"}
          label={"Autorizar NF logo após faturamento"}
          tooltip={"Autorizar NF logo após faturamento"}
          attLabel={{
            fontSize: 12,
          }}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={12}>
        <CGICheckBox<PedidoLojaType>
          id={"DadosNFSaida.autorizarNFlogoAposFaturamento"}
          label={"Financeiro separado(VP)"}
          tooltip={"Financeiro separado(VP)"}
        />
      </CGIBoxItem>
      <CGIBoxItem Col={12}>
        <CGIBox titulo="Impostos">
          <CGIBoxItem Col={3}>
            <CGIInput<PedidoLojaType>
              id={"DadosRegistro.baseCalculoICMS"}
              placeholder={"Base Calculo ICMS"}
              label={"Base Calculo ICMS"}
              att={{
                isDisabled: true,
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<PedidoLojaType>
              id={"DadosRegistro.valorICMS"}
              placeholder={"Valor ICMS"}
              label={"Valor ICMS"}
              att={{
                isDisabled: true,
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<PedidoLojaType>
              id={"DadosRegistro.baseCalculoICMSST"}
              placeholder={"Base Calculo ICMS ST"}
              label={"Base Calculo ICMS ST"}
              att={{
                isDisabled: true,
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={3}>
            <CGIInput<PedidoLojaType>
              id={"DadosRegistro.valorICMSST"}
              placeholder={"Valor ICMS ST"}
              label={"Valor ICMS ST"}
              att={{
                isDisabled: true,
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<PedidoLojaType>
              id={"DadosRegistro.valorIPI"}
              placeholder={"Valor IPI"}
              label={"Valor IPI"}
              att={{
                isDisabled: true,
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<PedidoLojaType>
              id={"DadosRegistro.valorFCP"}
              placeholder={"Valor FCP"}
              label={"Valor FCP"}
              att={{
                isDisabled: true,
              }}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={4}>
            <CGIInput<PedidoLojaType>
              id={"DadosRegistro.valorFCPST"}
              placeholder={"Valor FCP ST"}
              label={"Valor FCP ST"}
              att={{
                isDisabled: true,
              }}
            />
          </CGIBoxItem>
        </CGIBox>
      </CGIBoxItem>
    </CGIBox>
  );
});
