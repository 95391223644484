///// Types Padrao Alterar sem Grid
export const ObjAlterarCondicaoPagamentoPadrao: FormCondicaoPagamentoType = {
  Form: {
    DadosRegistro: {
      id: 0,
      nome: "",
      ativo: false,
      codigoExterno: "",
      descricaoAuxiliar: "",
      definicao: "",
      meioPagamentoId: null,
      formaPagamentoId: null,
      tipoDocumentoId: null,
      ordem: null,
      empresaId: null,
      valorMinimo: 0,
      valorMinimoMobile: 0,
      planoDeContasCtsReceberId: 0,
      exibirEmPedidoMobile: false,
      taxa: null,
      planoDeContasId: null,
      clienteId: null,
      operadoraAdministradoraCartaoId: null,
      condicaoPagamentComEntrada: false,
      portadorId: null,
      grupo: "",
    },
  },
};

export type FormCondicaoPagamentoType = {
  Form: {
    DadosRegistro: DadosRegistrotypes;
  };
};
type DadosRegistrotypes = {
  id: number;
  nome: string;
  ativo: boolean;
  codigoExterno: string;
  descricaoAuxiliar: string;
  definicao: string;
  meioPagamentoId: number | null;
  formaPagamentoId: number | null;
  tipoDocumentoId: number | null;
  ordem: number | null;
  empresaId: number | null;
  valorMinimo: number;
  valorMinimoMobile: number;
  planoDeContasCtsReceberId: number;
  exibirEmPedidoMobile: boolean;
  taxa: number | null;
  planoDeContasId: number | null;
  clienteId: number | null;
  operadoraAdministradoraCartaoId: number | null;
  condicaoPagamentComEntrada: boolean;
  portadorId: number | null;
  grupo: string;
};

/////Types Padrao Listar com 1 Grid e filtros Pesquisa
export const ObjListarCondicaoPagamentoPadrao: ListarCondicaoPagamentoTypes = {
  Pesquisa: {
    nome: "",
    ativoId: 0,
  },
  Itens: [],
};

export type ListarCondicaoPagamentoTypes = {
  Pesquisa: ListarPesquisaCondicaoPagamentoTypes;
  Itens: ListarCondicaoPagamentoItensTypes[];
};

export type ListarPesquisaCondicaoPagamentoTypes = {
  nome: string;
  ativoId: number;
};

export type ListarCondicaoPagamentoItensTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  codigoExterno: string;
  descricaoAuxiliar: string;
  nomeCliente: string;
  nomeOperadoraAdministradoraCartao: string;
  nomePortador: string;
  nomePlanoDeContas: string;
  nomeTipoDocumento: string;
  nomeMeioPagamento: string;
  nomeFormaPagamento: string;
  nomeEmpresa: string;
  resumoCartao: string;
};
/////Types Padrao Visualizar com 1 Grid e filtros Pesquisa

export type CondicaoPagamentoVisualizarTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  codigoExterno: string;
  descricaoAuxiliar: string;
  definicao: string;
  nomeExibicao: string;
  nomeFantasia: string;
  nomeTipoDocumento: string;
  nomeFormaPagamento: string;
  nomeMeioPagamento: string;
  nomePlanoDeContas: string;
  nomeCliente: string;
  formaPagamentoId: number | null;
  meioPagamentoId: number | null;
  tipoDocumentoId: number | null;
  planoDeContasCtsReceberId: number;
  clienteId: number | null;
  endereco: string;
};
