import { AddIcon } from "@chakra-ui/icons";
import { useRef, useContext } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGIDataTable } from "../../../../Components/DataTable/CGIDataTable";
import { CGICol } from "../../../../Components/DataTable/DataTable/DataTableTypes";
import { CGIListar } from "../../../../Components/Listar/CGIListar";
import { MenuTypes } from "../../../../Components/MenuButton/CGIMenuButton";
import { MenuGridPopover } from "../../../../Components/MenuButton/CGIMenuGrid";
import { CGIModalDelete } from "../../../../Components/ModalDelete/CGIModalDelete";
import LoadingContext from "../../../../Context/LoadingContext";
import { ModalBaseType } from "../../../../Types/Modal/ModalBaseType";
import {
  ListarTipoContratoItensTypes,
  ListarTipoContratoTypes,
  ObjListarTipoContratoPadrao,
} from "../../../../Types/Sistema/Classificacao/TipoContrato/TipoContratoTypes";
import { DeleteTypes } from "../../../../Types/Util/DeleteTypes";

import { HttpTipoContrato } from "./Http";
import { AlterarTipoContrato } from "./_AlterarTipoContrato";

export function ListarTipoContrato() {
  //Inicio Hooks
  const AlterarTipoContratoRef = useRef<ModalBaseType>();
  const CGIModalDeleteRef = useRef<ModalBaseType>();
  const { setLoadingState } = useContext(LoadingContext);

  const methods = useForm<ListarTipoContratoTypes>({
    defaultValues: ObjListarTipoContratoPadrao,
  });

  const GridItens = useWatch({
    control: methods.control,
    name: "Itens",
  });

  async function Excluir(data: DeleteTypes) {
    await HttpTipoContrato()
      .Excluir(data)
      .then(() => {
        CGIModalDeleteRef.current?.Fechar();
      });
  }
  //Final Hooks

  //------------------------

  //Inicio Consts
  const ListarMenu: Array<MenuTypes> = [
    {
      Nome: "Novo Tipo de Contrato",
      Permissao: "sistema/classificacao/incluirtipocontrato",
      OnClick: () => {
        AlterarTipoContratoRef.current?.Abrir();
      },
    },
  ];

  const MenuGrid: Array<MenuTypes> = [
    {
      Nome: "Alterar",
      Permissao: "sistema/classificacao/alterartipocontrato",
      OnClick: (e: ListarTipoContratoItensTypes) => {
        AlterarTipoContratoRef.current?.Alterar(e);
      },
    },
    {
      Nome: "Excluir",
      Permissao: "sistema/classificacao/excluirtipocontrato",
      OnClick: (e: ListarTipoContratoItensTypes) => {
        CGIModalDeleteRef.current?.Abrir(e);
      },
    },
  ];

  const Legendas = [
    { nomeStatus: "Ativo", cor: "green" },
    { nomeStatus: "Inativo", cor: "red" },
  ];

  const GridColunas: Array<CGICol<ListarTipoContratoItensTypes>> = [
    {
      Campo: "ativo",
      Titulo: "",
      Tipo: "Status",
      att: {
        maxWidth: 50,
      },
      Status: [
        {
          nomeStatus: "true",
          cor: "green",
        },
        {
          nomeStatus: "false",
          cor: "red",
        },
      ],
    },
    {
      Campo: "id",
      Titulo: "Código",
      Tipo: "Texto",
      Ordernar: true,
      att: {
        maxWidth: 100,
      },
    },
    {
      Campo: "nome",
      Titulo: "Nome",
      Tipo: "Texto",
    },
    {
      Campo: "planoDeContasId",
      Titulo: "Plano de Contas",
      Tipo: "CheckBox",
    },
    {
      Campo: "formaPagamentoId",
      Titulo: "Forma de Pagamento",
      Tipo: "CheckBox",
    },
    {
      Campo: "permiteAlterar",
      Titulo: "Permitir Alterar",
      Tipo: "CheckBox",
    },
    {
      Campo: "id",
      Titulo: "",
      Tipo: "Menu",
      att: {
        cellRenderer: (e: ListarTipoContratoItensTypes) =>
          MenuGridPopover(e, MenuGrid),
        pinned: "right",
        colId: "action",
        editable: false,
        maxWidth: 60,
      },
    },
  ];
  const Janelas = [
    {
      Componente: (
        <CGIModalDelete
          Titulo="Excluir Tipo de Contrato"
          CarregarDados={methods.handleSubmit(RetornarTipoContratos)}
          ref={CGIModalDeleteRef}
          CarregarDadosDeletar={RetornarDadosExclusao}
          Excluir={Excluir}
        />
      ),
    },
    {
      Componente: (
        <AlterarTipoContrato
          CarregarDados={methods.handleSubmit(RetornarTipoContratos)}
          ref={AlterarTipoContratoRef}
        />
      ),
    },
  ];

  //Final Consts

  //------------------------

  //Inicio Function

  async function RetornarTipoContratos() {
    setLoadingState(true);
    const DadosFiltro = methods.getValues("Pesquisa");
    var data = await HttpTipoContrato()
      .RetornarTipoContratos(DadosFiltro)
      .finally(() => {
        setLoadingState(false);
      });
    methods.setValue("Itens", data);
  }

  async function RetornarDadosExclusao(data: ListarTipoContratoItensTypes) {
    return await HttpTipoContrato().RetornarDadosExclusao(data);
  }

  //Final Function

  //------------------------

  // Inicio JSX

  function Filtros() {
    return <CGIBox children={undefined}></CGIBox>;
  }

  return (
    <>
      <CGIListar
        id="sistema/classificacao/listartipocontrato"
        Titulo="Listar Tipos de Contrato"
        Janelas={Janelas}
      >
        <FormProvider {...methods}>
          <CGIDataTable
            LimparFiltros={() => methods.reset()}
            Menu={{ Icone: <AddIcon />, Acoes: ListarMenu }}
            Pesquisar={methods.handleSubmit(RetornarTipoContratos)}
            Filtro={Filtros()}
            Data={GridItens}
            Colunas={GridColunas}
            Legenda={Legendas}
          />
        </FormProvider>
      </CGIListar>
    </>
  );
  // Final JSX
}
