import { Box } from "@chakra-ui/react";
import { useNavigate, BrowserRouter, Routes, Route } from "react-router-dom";
import { ListarCtsPagar } from "../Modules/Financeiro/CtsPagar/ListarCtsPagar";
import { Home } from "../Modules/Home/Home";
import { Login } from "../Modules/Home/Login/Login";
import { ListarEmpresa } from "../Modules/Sistema/Empresa/ListarEmpresa";
import { ListarProduto } from "../Modules/Sistema/Produto/ListarProduto";
import { ListarPortador } from "../Modules/Sistema/Portador/ListarPortador";
import { ListarSugestaoPedidoCompra } from "../Modules/Almoxarifado/SugestaoPedidoCompra/ListarSugestaoPedidoCompra";
import { ListarProdutosCompradosENaoEntregues } from "../Modules/Almoxarifado/ProdutosCompradosENaoEntregues/ListarProdutosCompradosENaoEntregues";
import { AlterarRelatorio } from "../Modules/Sistema/Relatorio/AlterarRelatorio";
import { VisualizarRelatorio } from "../Modules/Sistema/Relatorio/VisualizarRelatorio";
import { VisualizarRelatorioRegistro } from "../Modules/Sistema/Relatorio/VisualizarRelatorioRegistro";
import { ListarUsuario } from "../Modules/Sistema/Usuario/ListarUsuario";
import { ProtectedLayout } from "../Components/Layout/ProtectLayout";
import { CGIHttp } from "./Axios";
import { CGILayoutRelatorio } from "../Components/Layout/CGILayoutRelatorio";
import { ListarCondicaoPagamento } from "../Modules/Sistema/Classificacao/CondicaoPagamento/ListarCondicaoPagamento";
import { ListarNaturezaOperacao } from "../Modules/Sistema/Classificacao/NaturezaOperacao/ListarNaturezaOperacao";
import { ListarTipoNFEntrada } from "../Modules/Sistema/Classificacao/TipoNFEntrada/ListarTipoNFEntrada";
import { ListarFormaPagamento } from "../Modules/Sistema/Classificacao/FormaPagamento/ListarFormaPagamento";
import { useCGIToast } from "../Components/Hook/useCGIToast";
import { ListarPedido } from "../Modules/Vendas/Pedido/ListarPedido";
import { ListarRelacaoEntrega } from "../Modules/Faturamento/RelacaoEntrega/ListarRelacaoEntrega";
import { ListarAbastecimento } from "../Modules/Manutencao/Abastecimento/ListarAbastecimento";
import { ListarEstoqueGeral } from "../Modules/Almoxarifado/MovEstoque/ListarEstoqueGeral";
import { AtualizarSaldo } from "../Modules/Almoxarifado/MovEstoque/_AtualizarSaldo";
import { ConsultarProdutoXNFEntrada } from "../Modules/Almoxarifado/NFEntrada/ListarConsultarProdutoXNFEntrada";
import { AnaliseCredito } from "../Modules/Financeiro/Colaborador/AnaliseCredito/AnaliseCredito";
import { PedidoPendenteFaturamento } from "../Modules/Vendas/Pedido/_PedidoPendenteFaturamento";
import { ListarConfigCFOP } from "../Modules/Sistema/ConfigCFOP/ListarConfigCFOP";
import { ListarConfigCFOPAuto } from "../Modules/Sistema/ConfigCFOPAuto/ListarConfigCFOPAuto";

import { ListarNFEntregues } from "../Modules/Faturamento/NFSaida/NF Entregues/ListarNFEntregues";
import { ListarEquipamento } from "../Modules/Sistema/Equipamento/ListarEquipamento";
import { ListarLimiteCredito } from "../Modules/Sistema/Classificacao/LimiteCredito/ListarLimiteCredito";
import { ListarParametros } from "../Modules/Sistema/Parametros/ListarParametros";
import { ListarPermisao } from "../Modules/Sistema/Permissao/ListarPermisao";
import { ListarImportacaoNFSaida } from "../Modules/Faturamento/ImportacaoNFSaida/ImportacaoNFSaida";
import { ListarOrdemProducao } from "../Modules/Producao/OrdemProducao/ListarOrdemProducao";
import { ListarProdutosNaoCadastrados } from "../Modules/Sistema/Produto/ListarProdutosNaoCadastrados";
import { ListarExemplos } from "../Modules/Sistema/Exemplo/ListarExemplos";
import { ListarMarca } from "../Modules/Sistema/Classificacao/Marca/ListarMarca";
import { ListarTipoProduto } from "../Modules/Sistema/Classificacao/TipoProduto/ListarTipoProduto";
import { ListarTipoOrcamentoPedido } from "../Modules/Sistema/Classificacao/TipoOrcamentoPedido/ListarTipoOrcamentoPedido";
import { ListarAssinaturaEletronicaZapSign } from "../Modules/Sistema/Classificacao/AssinaturaEletronicaZapSign/ListarAssinaturaEletronicaZapSign";
import { ListarTipoCliente } from "../Modules/Sistema/Classificacao/TipoCliente/ListarTipoCliente";
import { ListarTipoContrato } from "../Modules/Sistema/Classificacao/TipoContrato/ListarTipoContrato";
import { ListarIntegracaoMarketPlace } from "../Modules/Sistema/Classificacao/IntegracaoMarketPlace/ListarIntegracaoMarketPlace";
import { ListarMarketPlace } from "../Modules/Sistema/Classificacao/MarketPlace/ListarMarketPlace";
import { ListarConfigComissao } from "../Modules/Sistema/ConfigComissao/ListarConfigComissao";
import { ListarAlocacaoPedido } from "../Modules/Vendas/Pedido/ListarAlocacaoPedido";
import { ListarColaborador } from "../Modules/Financeiro/Colaborador/ListarColaborador";
import { Logout } from "../Modules/Home/Login/Logout";
import { VisualizarBI } from "../Modules/Sistema/BI/VisualizarBI";
import { VisualizarPedidosPorProduto } from "../Modules/Vendas/Pedido/_VisualizarPedidosPorProduto";
import { ListarTIPI } from "../Modules/Sistema/TIPI/ListarTIPI";
import { ListarCtsReceber } from "../Modules/Financeiro/CtsReceber/ListarCtsReceber";
import { ListarPedidoSeparacao } from "../Modules/Vendas/Pedido/ListarSeparacao";
import { ListarPedidoEntrada } from "../Modules/Almoxarifado/PedidoEntrada/ListarPedidoEntrada";
import { ListarPedidoEcommerce } from "../Modules/Vendas/PedidoEcommerce/ListarPedidoEcommerce";
import { ListarRetornoNotaFiscal } from "../Modules/Almoxarifado/RetornoNotaFiscal/ListarRetornoNotaFiscal";
import { ListarNFSaida } from "../Modules/Faturamento/NFSaida/ListarNFSaida";
import { ListarConfigServico } from "../Modules/Sistema/ConfigServico/ListarConfigServico";
import { ListarNotasPendentesEntrega } from "../Modules/Faturamento/NFSaida/NotasPendentesEntrega/ListarNotasPendentesEntrega";
import { ListarBoletoBancario } from "../Modules/Financeiro/BoletoBancario/ListarBoletoBancario";
import { ListarPedidoCompra } from "../Modules/Almoxarifado/PedidoCompra/ListarPedidoCompra";
import { ListarArquivoRemessa } from "../Modules/Financeiro/ArquivoRemessa/ListarArquivoRemessa";
import { ListarArquivoRetorno } from "../Modules/Financeiro/ArquivoRetorno/ListarArquivoRetorno";
import { ListarMovPortador } from "../Modules/Financeiro/MovPortador/ListarMovPortador";
import { ListarAlocacao } from "../Modules/Almoxarifado/Alocacao/ListarAlocacao";
import { SaldoGeral } from "../Modules/Financeiro/MovPortador/_SaldoGeral";
import { ListarPedidosParaProducao } from "../Modules/Vendas/Pedido/ListarPedidosParaProducao";

function AxiosInterceptorNavigate() {
  let navigate = useNavigate();
  let { ToastErrorId } = useCGIToast();
  new CGIHttp().interceptor(navigate, ToastErrorId);

  return <></>;
}

export function CGIRoutes(props: any) {
  return (
    <BrowserRouter>
      {<AxiosInterceptorNavigate />}
      <Routes>
        <Route element={<Login />} path="Login" />
        <Route element={<Login />} path="Login/:nomeDominio" />
        <Route element={<Logout />} path="Logout" />
        <Route element={<ProtectedLayout />}>
          <Route path="/">
            <Route element={<ListarPedido />} path="/Vendas/Pedido/Listar" />
            <Route
              element={<PedidoPendenteFaturamento />}
              path="/Vendas/Pedido/PedidosPendentesFaturamento"
            />
            <Route element={<ListarProduto />} path="/Sistema/Produto/Listar" />
            <Route
              element={<ListarProdutosNaoCadastrados />}
              path="/sistema/produto/listarnaocadastrados"
            />
            <Route element={<ListarEmpresa />} path="/Sistema/Empresa/Listar" />
            <Route element={<ListarUsuario />} path="/Sistema/Usuario/Listar" />
            <Route
              element={<ListarCondicaoPagamento />}
              path="/sistema/classificacao/listar-condicao-pagamento"
            />
            <Route
              element={<ListarLimiteCredito />}
              path="/Sistema/Classificacao/ListarLimitesCredito"
            />
            <Route
              element={<ListarPortador />}
              path="/Sistema/Portador/Listar"
            />
            <Route
              element={<ListarColaborador />}
              path="/financeiro/colaborador/listar"
            />
            <Route
              element={<ListarNaturezaOperacao />}
              path="/sistema/classificacao/listar-natureza-operacao"
            />
            <Route
              element={<ListarSugestaoPedidoCompra />}
              path="/Almoxarifado/SugestaoPedidoCompra/Listar"
            />
            <Route
              element={<ListarTipoNFEntrada />}
              path="/Sistema/Classificacao/ListarTipoNFEntrada"
            />
            <Route
              element={<ListarFormaPagamento />}
              path="/Sistema/Classificacao/ListarFormasPagamento"
            />
            <Route
              element={<ListarCtsPagar />}
              path="/Financeiro/CtsPagar/Listar"
            />
            <Route
              element={<ListarEquipamento />}
              path="/Sistema/Equipamento/Listar"
            />
            <Route
              element={<ListarRelacaoEntrega />}
              path="/faturamento/relacaoentrega/listarrelacaoentrega"
            />
            <Route
              element={<ListarNFEntregues />}
              path="/Faturamento/NFSaida/ListarNFEntregues"
            />
            <Route
              element={<ConsultarProdutoXNFEntrada />}
              path="/Almoxarifado/NFEntrada/ListarConsultarProdutosXNFEntrada"
            />
            <Route
              element={<ListarEstoqueGeral />}
              path="/almoxarifado/movestoque/estoquegeral"
            />
            <Route
              element={<AtualizarSaldo />}
              path="/almoxarifado/movestoque/atualizarsaldo"
            />
            <Route
              element={<AnaliseCredito />}
              path="/Financeiro/Colaborador/AnaliseCredito"
            />
            <Route
              element={<ListarProdutosCompradosENaoEntregues />}
              path="/Almoxarifado/PedidoCompra/ProdutosCompradosENaoEntregues"
            />
            <Route
              element={<ListarConfigCFOP />}
              path="/Sistema/ConfigCFOP/Listar"
            />
            <Route
              element={<ListarConfigCFOPAuto />}
              path="/Sistema/ConfigCFOPAuto/Listar"
            />
            <Route
              element={<ListarNFEntregues />}
              path="/Faturamento/NFSaida/ListarNFEntregues"
            />
            <Route
              element={<ListarAbastecimento />}
              path="/Manutencao/Abastecimento/Listar"
            />
            <Route
              element={<ListarParametros />}
              path="/sistema/parametro/listar"
            />
            <Route
              element={<ListarOrdemProducao />}
              path="/producao/ordemproducao/listar"
            />
            <Route
              element={<ListarPermisao />}
              path="sistema/permissao/listar"
            />
            <Route
              element={<ListarImportacaoNFSaida />}
              path="faturamento/importacaonfsaida/listar"
            />
            <Route
              element={<ListarMarca />}
              path="sistema/classificacao/listarmarca"
            />
            <Route
              element={<ListarTipoProduto />}
              path="sistema/classificacao/listartipoproduto"
            />
            <Route
              element={<ListarTipoOrcamentoPedido />}
              path="sistema/classificacao/listartipoorcamentopedido"
            />
            <Route
              element={<ListarAssinaturaEletronicaZapSign />}
              path="sistema/classificacao/listarassinaturaeletronicazapsign"
            />
            <Route
              element={<ListarTipoCliente />}
              path="sistema/classificacao/listartipocliente"
            />
            <Route
              element={<ListarTipoContrato />}
              path="sistema/classificacao/listartipocontrato"
            />
            <Route
              element={<ListarIntegracaoMarketPlace />}
              path="sistema/classificacao/listarintegracaomarketplace"
            />
            <Route
              element={<ListarTipoProduto />}
              path="sistema/classificacao/listartiposproduto"
            />
            <Route
              element={<ListarMarketPlace />}
              path="sistema/classificacao/listarmarketplace"
            />
            <Route
              element={<ListarConfigComissao />}
              path="sistema/configcomissao/listar"
            />
            <Route
              element={<ListarAlocacaoPedido />}
              path="vendas/pedido/listaralocacao"
            />
            <Route
              element={<ListarEstoqueGeral />}
              path="almoxarifado/movestoque/listarestoquegeral"
            />
            <Route
              element={<VisualizarBI />}
              path="sistema/bI/visualizar/:id"
            />
            <Route element={<ListarTIPI />} path="sistema/tipi/listar" />
            <Route
              element={<ListarCtsReceber />}
              path="financeiro/ctsreceber/listar"
            />
            <Route
              element={<ListarEstoqueGeral />}
              path="almoxarifado/movestoque/listarestoquegeral"
            />
            <Route
              element={<ListarPedidoSeparacao />}
              path="vendas/pedido/listarseparacao"
            />
            <Route
              element={<ListarPedidoEcommerce />}
              path="vendas/pedidoecommerce/listar"
            />
            <Route
              element={<ListarRetornoNotaFiscal />}
              path="almoxarifado/retornonotafiscal/listar"
            />
            <Route
              element={<ListarNFSaida />}
              path="faturamento/nfsaida/listar"
            />
            <Route
              element={<ListarBoletoBancario />}
              path="financeiro/boletobancario/listar"
            />
            <Route
              element={<ListarArquivoRemessa />}
              path="financeiro/arquivoremessa/listar"
            />
            <Route
              element={<ListarArquivoRetorno />}
              path="financeiro/arquivoretorno/listar"
            />
            <Route
              element={<ListarMovPortador />}
              path="financeiro/movportador/listar"
            />
            <Route
              element={<SaldoGeral />}
              path="financeiro/movportador/saldogeral"
            />
            <Route
              element={<ListarEstoqueGeral />}
              path="almoxarifado/movestoque/listarestoquegeral"
            />
            <Route
              element={<ListarPedidoCompra />}
              path="almoxarifado/pedidocompra/listar"
            />
            <Route
              element={<ListarNotasPendentesEntrega />}
              path="faturamento/nfsaida/notaspendentesentrega"
            />
            <Route
              element={<ListarConfigServico />}
              path="sistema/configservico/listar"
            />
            <Route
              element={<ListarPedidoEntrada />}
              path="almoxarifado/pedidoentrada/listar"
            />
            <Route
              element={<ListarAlocacao />}
              path="almoxarifado/alocacao/listar"
            />
            <Route
              element={<ListarPedidosParaProducao />}
              path="vendas/pedido/listarpedidosparaproducao"
            />

            <Route element={<ListarExemplos />} path="sistema/exemplos" />

            <Route element={<Home />} path="/" />
          </Route>
        </Route>
        <Route element={<CGILayoutRelatorio />}>
          <Route
            element={<VisualizarRelatorio />}
            path="/Sistema/Relatorio/VisualizarRelatorio/:id"
          />
          <Route
            element={<AlterarRelatorio />}
            path="/Sistema/Relatorio/AlterarRelatorio/:id"
          />
          <Route
            element={<VisualizarRelatorioRegistro />}
            path="/Sistema/Relatorio/VisualizarRelatorioRegistro/:id/:idRegistro"
          />
        </Route>
        <Route path="*" element={<Box>There's nothing here!</Box>} />
      </Routes>
    </BrowserRouter>
  );
}
