export type FormUsuarioTypes = {
  Form: {
    DadosRegistro: DadosRegistroTypes;
  };
};

export type DadosRegistroTypes = {
  id: number;
  login: string;
  nome: string;
  email: string;
  empresaId: number | null;
  ativo: boolean;
  acessoTotal: boolean;
  ehMobile: boolean;
  sempreExibirChat: boolean;
  vendedorId: number | null;
  linkInicialId: number | null;
  motoristaId: number | null;
  professorId: number | null;
  tecnicoId: number | null;
  clienteId: number | null;
  empresaCaixaId: number | null;
  utilizaDashboard: boolean;
  senhaNumerica: string;
  colaboradorId: number | null;
  linkAssinatura: string;
  horarioSegunda: string;
  horarioTerca: string;
  horarioQuarta: string;
  horarioQuinta: string;
  horarioSexta: string;
  horarioSabado: string;
  horarioDomingo: string;
  menu: string;
  empresas: any[];
  metas: any[];
  resumos: any[];
  calendarios: any[];
};

export type UsuarioVisualizarTypes = {
  id: number;
  login: string;
  nome: string;
  email: string;
  ativo: boolean;
  acessoTotal: boolean;
  ehMobile: boolean;
  nomeEmpresa: string;
  nomeVendedor: string;
  nomeMotorista: string;
  nomeLinkInicial: string;
  sempreExibirChat: boolean;
};

export const ObjListarUsuarioPadrao: ListarUsuarioTypes = {
  Pesquisa: {
    login: "",
    nome: "",
    acessoTotal: 0,
    ativoId: 0,
  },
  Itens: [],
};

export type ListarUsuarioTypes = {
  Pesquisa: ListarPesquisaUsuarioTypes;
  Itens: ListarUsuarioItensTypes[];
};

export type ListarPesquisaUsuarioTypes = {
  login: string;
  nome: string;
  acessoTotal: number;
  ativoId: number;
};

export type ListarUsuarioItensTypes = {
  id: number;
  login: string;
  nome: string;
  email: string;
  ehMobile: boolean;
  temMenuDiferente: boolean;
  acessoTotal: boolean;
  ativo: boolean;
  nomeEmpresa: string;
};
