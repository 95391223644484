export const ObjAlterarLimiteCreditoPadrao: FormLimiteCreditoTypes = {
  Form: {
    DadosRegistro: {
      ativo: false,
      id: 0,
      nome: "",
      valor: "",
    },
  },
};

export type FormLimiteCreditoTypes = {
  Form: {
    DadosRegistro: DadosRegistroTypes;
  };
};

export type DadosRegistroTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  valor: string;
};

export type LimiteCreditoVisualizarTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  valor: string;
};

export const ObjListarLimiteCreditoPadrao: ListarLimiteCreditoTypes = {
  Pesquisa: {
    nome: "",
    ativoId: 0,
  },
  Itens: [],
};

export type ListarLimiteCreditoTypes = {
  Pesquisa: ListarPesquisaLimiteCreditoTypes;
  Itens: ListarLimiteCreditoItensTypes[];
};

export type ListarPesquisaLimiteCreditoTypes = {
  nome: string;
  ativoId: number;
};

export type ListarLimiteCreditoItensTypes = {
  id: number;
  nome: string;
  ativo: boolean;
  limite: number;
};
