import {
  Box,
  FormLabel,
  Textarea,
  TextareaProps,
  Tooltip,
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { useFormContext, Controller, RegisterOptions } from "react-hook-form";

interface CGITextAreaTypes<T extends Record<string, unknown>> {
  id: NestedKey<T>;
  label: string;
  validadores?: RegisterOptions | undefined;
  att?: TextareaProps;
}
/**
 * @Componente CGITextArea - Text Area padrão do Sistema
 * @Ref  FormContext
 * @Form  Necessita ser Controlado Por Formulário
 */
export function CGITextArea<T extends Record<string, unknown>>(
  props: CGITextAreaTypes<T>
) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Box>
      <FormLabel>
        {props.label}
        {props.validadores?.required ? (
          <span style={{ color: "red" }}> *</span>
        ) : (
          ""
        )}
      </FormLabel>
      <Tooltip placement="auto-end">
        <Controller
          render={({ field }) => {
            return (
              <Textarea
                {...props}
                value={field.value}
                onChange={field.onChange}
              />
            );
          }}
          rules={props.validadores}
          name={props.id}
          control={control}
        />
      </Tooltip>
      <ErrorMessage
        errors={errors}
        name={props.id}
        render={({ message }) => (
          <span style={{ color: "red", fontSize: 12 }}> * {message}</span>
        )}
      />
    </Box>
  );
}
