import { CGIHttp } from "../../../../Services/Axios";
import {
  FormTipoProdutoType,
  ListarTipoProdutoItensTypes,
  ListarPesquisaTipoProdutoTypes,
} from "../../../../Types/Sistema/Classificacao/TipoProduto/TipoProdutoTypes";

const url = "/classificacao";

export function HttpTipoProduto() {
  async function RetornarTipoProdutos(
    DadosFiltros: ListarPesquisaTipoProdutoTypes
  ) {
    return (
      await new CGIHttp().conn.post(url + "/obter-tiposproduto", DadosFiltros)
    ).data.data;
  }

  async function Listar() {
    return await (
      await new CGIHttp().conn.get(url + "/listar-8")
    ).data.data;
  }

  async function RetornarDadosTipoProduto(id: number) {
    return await new CGIHttp().conn.get(url + `/alterar-tipoproduto/${id}`);
  }

  async function Alterar(Dados: FormTipoProdutoType) {
    return await new CGIHttp().conn.post(
      url + "/alterar-tipoproduto",
      Dados.Form
    );
  }
  async function RetornarDadosExclusao(Dados: ListarTipoProdutoItensTypes) {
    return await new CGIHttp().conn.get(
      url + "/excluir-tipoproduto/" + Dados.id
    );
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(url + "/excluir-tipoproduto", Dados);
  }

  return {
    RetornarTipoProdutos,
    RetornarDadosTipoProduto,
    RetornarDadosExclusao,
    Listar,
    Alterar,
    Excluir,
  };
}
