import { forwardRef, useImperativeHandle, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../Components/Box/CGIBoxItens";
import { CGICheckBox } from "../../../Components/CheckBox/CGICheckBox";
import { CGIComboSimples } from "../../../Components/Combos/CGIComboSimples";
import { CGIDatePicker } from "../../../Components/DatePicker/CGIDatePicker";
import { useCGIToast } from "../../../Components/Hook/useCGIToast";
import { CGIInput } from "../../../Components/Input/CGIInput";
import { CGIModal } from "../../../Components/Modal/CGIModal";
import { CGITabItensTypes, CGITabs } from "../../../Components/Tabs/CGITabs";
import { CGITextArea } from "../../../Components/TextArea/CGITextArea";
import LoadingContext from "../../../Context/LoadingContext";
import { ProdutoTypes } from "../../../Types/Sistema/Produto/ProdutoTypes";
import { HttpProduto } from "./Http";

type AlterarProdutoType = {
  CarregarDados: Function;
};

export const AlterarProduto = forwardRef((props: AlterarProdutoType, ref) => {
  //INICIO REF
  useImperativeHandle(ref, () => ({
    Abrir() {
      CarregarDados(0);
      CGIModalRef.current.Abrir();
    },
    Alterar(pProduto: any) {
      CarregarDados(pProduto.Id);
      CGIModalRef.current.Abrir();
    },
  }));
  //FINAL  REF

  //INICIO  HOOKS
  const CGIModalRef = useRef() as any;
  const form = useFormContext();
  const { ToastErro, ToastSucesso } = useCGIToast();
  const { setLoadingState } = useContext(LoadingContext);

  //FINAL  HOOKS

  const RenderTabs: Array<CGITabItensTypes> = [
    {
      Titulo: "Dados do Produto",
      Component: AreaDadosProduto(),
    },
    {
      Titulo: "Precificação",
      Component: AreaPrecificacao(),
    },
    {
      Titulo: "Config. Fiscais",
      Component: AreaConfiguracoesFiscais(),
    },
    {
      Titulo: "Observações",
      Component: AreaObservacoes(),
    },
    {
      Titulo: "Fornecedores",
      Component: AreaFornecedores(),
    },
    {
      Titulo: "E-commerce",
      Component: AreaEcommerce(),
    },
    {
      Titulo: "Market Places",
      Component: AreaMarketPlaces(),
    },
    {
      Titulo: "Estoque",
      Component: AreaEstoque(),
    },
    {
      Titulo: "Configurações",
      Component: AreaConfiguracoes(),
    },
  ];
  //FINAL  CONST

  //INICO FUNC
  async function CarregarDados(id: number) {
    if (id >= 0) {
      HttpProduto()
        .RetornarDadosProduto(id)
        .then((e) => {
          form.reset(e.data.data);
        });
    }
  }
  function AreaDadosProduto() {
    return (
      <CGIBox>
        <CGIBoxItem Col={3}>
          <CGIInput
            id="codigoProduto"
            label="Código Produto"
            placeholder="Código Produto"
            validadores={{ required: "Código do produto obrigatório" }}
          />
        </CGIBoxItem>

        <CGIBoxItem Col={9}>
          <CGIInput
            id="descricao"
            label="Descrição"
            placeholder="Descrição do produto"
            validadores={{ required: "Descrição obrigatória" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput
            id="codigoBarras"
            label="Cód. Barras"
            placeholder="Código de Barras"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput
            id="codigoProdutoFabricante"
            label="Código Produto (Fabricante)"
            placeholder="Código Produto (Fabricante)"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIInput
            id="pesoBruto"
            label="Peso Bruto"
            placeholder="Peso Bruto"
            att={{ type: "number" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIInput
            id="pesoLiquido"
            label="Peso Líquido"
            placeholder="Peso Líquido"
            att={{ type: "number" }}
          />
        </CGIBoxItem>

        <CGIBoxItem Col={12}>
          <CGIBox
            titulo="Classificação do Produto"
            popover="Campos para classificação do produto"
          >
            <CGIBoxItem Col={3}>
              <CGIComboSimples
                id="unidadeId"
                label="Unidade"
                Link="unidade"
                validadores={{ required: "Unidade obrigatória" }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGIComboSimples
                id="tipoProdutoId"
                label="Tipo de Produto"
                Link="tipodeproduto"
                validadores={{ required: "Tipo de Produto obrigatório" }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGIComboSimples
                id="classeId"
                label="Classe"
                Link="classe"
                validadores={{ required: "Unidade obrigatória" }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGIComboSimples
                id="subgrupoId"
                label="Sub-Grupo"
                Link="subgrupo"
                validadores={{ required: "SubGrupo obrigatório" }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGIComboSimples
                id="marcaId"
                label="Marca"
                Link="marca"
                validadores={{ required: "Marca obrigatória" }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGIComboSimples
                id="familiaId"
                label="Família"
                Link="familia"
                validadores={{ required: "Família obrigatória" }}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIBox
            titulo="Informações complementares"
            popover="Informações complementares do produto"
          >
            <CGIBoxItem Col={2}>
              <CGIInput
                id="multiplo"
                label="Múltiplo"
                placeholder="Múltiplo"
                att={{ type: "number" }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGITextArea
                id="aplicacao"
                label="Aplicação"
                att={{ placeholder: "Aplicação do Produto" }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={9}>
              <CGIInput
                id="descricaoEmIngles"
                label="Descrição em Inglês"
                placeholder="Descrição do produto em inglês"
              />
            </CGIBoxItem>

            <CGIBoxItem Col={12}>
              <CGIBox titulo="EPI" popover="Campos para controle de EPI">
                <CGIBoxItem Col={2}>
                  <CGIInput
                    id="numeroCA"
                    label="Número CA"
                    placeholder="Número de CA"
                  />
                </CGIBoxItem>

                <CGIBoxItem Col={3}>
                  <CGIDatePicker id="dataValidadeCA" label="Validade CA" />
                </CGIBoxItem>

                <CGIBoxItem Col={2}>
                  <CGIInput
                    id="diasTroca"
                    label="Dias para Troca"
                    placeholder="Dias para Troca"
                    att={{ type: "number" }}
                  />
                </CGIBoxItem>
              </CGIBox>
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    );
  }
  function AreaPrecificacao() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIInput
            id="precoCusto"
            label="Preço de Custo"
            placeholder="Preço de Custo"
            att={{ type: "number" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput
            id="margemLucro"
            label="Margem de Lucro de Venda"
            placeholder="Margem de Lucro"
            att={{ type: "number" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput
            id="precoVenda"
            label="Preço de Venda"
            placeholder="Preço de Venda"
            att={{ type: "number" }}
          />
        </CGIBoxItem>

        <CGIBoxItem Col={4}>
          <CGIInput
            id="precoVendaMinimo"
            label="Pç. Venda Mínimo"
            placeholder="Pç. Venda Mínimo"
            att={{ type: "number" }}
          />
        </CGIBoxItem>

        <CGIBoxItem Col={4}>
          <CGIInput
            id="precoVendaMaximo"
            label="Pç. Venda Máximo"
            placeholder="Pç. Venda Máximo"
            att={{ type: "number" }}
          />
        </CGIBoxItem>

        <CGIBoxItem Col={12}>
          <CGIBox
            titulo="Locação"
            popover="Dados de precificação para locação de equipamentos"
          >
            <CGIBoxItem Col={4}>
              <CGIInput
                id="precoMinimoLocacao"
                label="Pç. Mínimo Locação"
                placeholder="Pç. Mínimo Locação"
                att={{ type: "number" }}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIBox
            titulo="Preços"
            popover="Estes valores são utilizados apenas conforme parametrização"
          >
            <CGIBoxItem Col={4}>
              <CGIInput
                id="precoVenda1"
                label="Pç. Venda 1"
                placeholder="Pç. de Venda 1"
                att={{ type: "number" }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIInput
                id="precoVenda2"
                label="Pç. Venda 2"
                placeholder="Pç. de Venda 2"
                att={{ type: "number" }}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIInput
                id="precoVenda3"
                label="Pç. Venda 3"
                placeholder="Pç. de Venda 3"
                att={{ type: "number" }}
              />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
      </CGIBox>
    );
  }

  function AreaConfiguracoesFiscais() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIInput id="ncm" label="NCM" placeholder="NCM" />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput
            id="codigoCest"
            label="Código CEST"
            placeholder="Código CEST"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples
            id="procedenciaId"
            label="Procedência"
            Link="procedencia"
            validadores={{ required: "Procedência obrigatória" }}
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  function AreaObservacoes() {
    return (
      <CGIBox>
        <CGIBoxItem Col={6}>
          <CGITextArea
            id="obsCompra"
            label="Obs. de Compra"
            att={{ placeholder: "Observações de Compra" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGITextArea
            id="obsCompra"
            label="Obs. de Compra"
            att={{ placeholder: "Obs. de Compra" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGITextArea id="obsCompra" label="Obs. de Compra" />
        </CGIBoxItem>
        <CGIBoxItem Col={6}>
          <CGITextArea id="obsCompra" label="Obs. de Compra" />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  function AreaFornecedores() {
    return (
      <CGIBox>
        <CGIBoxItem Col={12}>
          <></>
        </CGIBoxItem>
      </CGIBox>
    );
  }

  function AreaEcommerce() {
    return (
      <CGIBox>
        <CGIBoxItem Col={2}>
          <CGICheckBox
            id="ehEcommerce"
            label="E-Commerce"
            tooltip={"Informa se o produto é E-Commerce"}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput id="sku" label="SKU" placeholder="SKU" />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput
            id="precoVendaEcommerce"
            label="Pç. Venda"
            placeholder="Pç. de Venda E-Commerce"
            att={{ type: "number" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={2}>
          <CGIInput
            id="margemLucroEcommerce"
            label="Margem Lucro"
            placeholder="Margem de Lucro do E-Commerce"
            att={{ type: "number" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIInput
            id="descricaoEcommerce"
            label="Descrição"
            placeholder="Descrição do E-Commerce"
          />
        </CGIBoxItem>

        <CGIBoxItem Col={4}>
          <CGIInput
            id="largura"
            label="Largura"
            placeholder="Largura"
            att={{ type: "number" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput
            id="altura"
            label="Altura"
            placeholder="Altura"
            att={{ type: "number" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={4}>
          <CGIInput
            id="comprimento"
            label="Comprimento"
            placeholder="Comprimento"
            att={{ type: "number" }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIInput
            id="descricaoCompletaECommerce"
            label="Descrição Completa E-Commerce"
            placeholder="Descrição Completa do E-Commerce"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples
            id="variacao1Id"
            label="Variação 1"
            Link="variacaoecommerce"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIComboSimples
            id="variacao2Id"
            label="Variação 2"
            Link="variacaoecommerce"
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  function AreaMarketPlaces() {
    return (
      <CGIBox>
        <CGIBoxItem Col={12}>
          <></>
        </CGIBoxItem>
      </CGIBox>
    );
  }

  function AreaEstoque() {
    return (
      <CGIBox>
        <CGIBoxItem Col={2}>
          <CGIInput
            id="estoqueAtual"
            label="Estoque Atual"
            placeholder="Estoque Atual do Produto"
            att={{ isReadOnly: true }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput
            id="estoqueMinimo"
            label="Estoque Mínimo"
            placeholder="Estoque Mínimo"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput
            id="percReposicao"
            label="% Reposição"
            placeholder="% de Reposição"
          />
        </CGIBoxItem>
        <CGIBoxItem Col={3}>
          <CGIInput
            id="localEstoque"
            label="Local Estoque"
            placeholder="Local do Estoque"
          />
        </CGIBoxItem>
      </CGIBox>
    );
  }

  function AreaConfiguracoes() {
    return (
      <CGIBox>
        <CGIBoxItem Col={4}>
          <CGIBoxItem Col={12}>
            <CGICheckBox
              id="controlarLote"
              label="Controlar Lote"
              tooltip={"Controlar lote do produto"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGICheckBox
              id="vendaFracionada"
              label="Venda Fracionada"
              tooltip={"Informa se o produto permite venda fracionada"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGICheckBox
              id="ehEPI"
              label="EPI"
              tooltip={"Informa se o produto é um EPI"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGICheckBox
              id="inativoFuturo"
              label="Inativo Futuro"
              tooltip={"Informa se o produto é um Inativo Futuro"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGICheckBox
              id="permitirEstoqueNegativo"
              label="Permitir Estoque Negativo"
              tooltip={"Informa se o produto permite estoque negativo"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGICheckBox
              id="naoMovimentarEstoque"
              label="Não Movimentar Estoque"
              tooltip={"Informa se o produto não irá movimentar estoque"}
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGICheckBox
              id="controlarPatrimonioGrade"
              label="Controlar Patrimônio/Grade"
              tooltip={
                "Informa se o produto terá controle de patrimônio / grade"
              }
            />
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <CGICheckBox
              id="ehInsumo"
              label="Insumo"
              tooltip={"Informa se o produto é um insumo"}
            />
          </CGIBoxItem>
        </CGIBoxItem>
      </CGIBox>
    );
  }

  const Confirmar = async (data: ProdutoTypes, event: any) => {
    setLoadingState(true);
    if (data.id == null || data.id === undefined || data.id === 0) {
      HttpProduto()
        .Incluir(data)
        .then((response: any) => {
          ToastSucesso("Registro gravado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
        })
        .catch((e) => {
          return ToastErro(e);
        })
        .finally(() => {
          setLoadingState(false);
        });
    } else {
      HttpProduto()
        .Alterar(data)
        .then((data: any) => {
          ToastSucesso("Registro Alterado com sucesso", "OK!");
          props.CarregarDados();
          CGIModalRef.current.Fechar();
        })
        .catch((e) => {
          return;
        })
        .finally(() => {
          setLoadingState(false);
        });
    }
  };
  //FINAL FUNC

  //INICO JSX
  return (
    <CGIModal
      id="sistema/produto/alterar"
      ref={CGIModalRef}
      Titulo="Cadastro de Produto"
      TelaCheia={"6xl"}
      Confirmar={Confirmar}
      CarregarDados={CarregarDados}
      Fechar={() => {
        form.reset({});
      }}
    >
      <CGITabs Tabs={RenderTabs} />
    </CGIModal>
  );
  //FINAL JSX
});
