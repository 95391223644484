import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { CGIBox } from "../../../../Components/Box/CGIBox";
import { CGICheckBox } from "../../../../Components/CheckBox/CGICheckBox";
import { CGIBoxItem } from "../../../../Components/Box/CGIBoxItens";
import { CGIInput } from "../../../../Components/Input/CGIInput";
import { CGIModal } from "../../../../Components/Modal/CGIModal";
import {
    FormFormaPagamentoTypes,
    FormaPagamentoVisualizarTypes,
} from "../../../../Types/Sistema/Classificacao/FormaPagamento/FormaPagamentoTypes";
import { HttpFormaPagamento } from "./Http";

type VisualizarFormaPagamentoType = {
    CarregarDados: Function;
};
export const VisualizarFormaPagamento = forwardRef(
    (props: VisualizarFormaPagamentoType, ref) => {
        // INICIO REF
        useImperativeHandle(ref, () => ({
            Abrir(e: FormFormaPagamentoTypes) {
                CGIModalRef.current.Abrir();
                CarregarDados(e);
            },
        }));
        // FINAL REF

        const CGIModalRef = useRef() as any;
        const { reset } = useFormContext();

        async function CarregarDados(params: FormFormaPagamentoTypes) {
            var data = await HttpFormaPagamento().Visualizar(params.Form.DadosRegistro);
            reset(data.data.data);
        }

        return (
            <CGIModal
                id="sistema/classificacao/visualizar"
                ref={CGIModalRef}
                Titulo="Visualizar Forma de Pagamento"
                TelaCheia={"6xl"}
                Confirmar={() => { }}
                Fechar={() => { }}
                ocultarCancelar={true}
                ocultarConfirmar={true}
            >
                <CGIBox>
                    <CGIBoxItem Col={2}>
                        <CGIInput<FormaPagamentoVisualizarTypes>
                            id="id"
                            label="Id"
                            placeholder="Id"
                            att={{ isReadOnly: true }}
                        />
                    </CGIBoxItem>
                    <CGIBoxItem Col={4}>
                        <CGIInput<FormaPagamentoVisualizarTypes>
                            id="nome"
                            label="Nome"
                            placeholder="Nome"
                            att={{ isReadOnly: true }}
                        />
                    </CGIBoxItem>
                    <CGIBoxItem Col={6}>
                        <CGIInput<FormaPagamentoVisualizarTypes>
                            id="nomeTipoDocumento"
                            label="Tipo de Documento"
                            placeholder="Tipo de Documento"
                            att={{ isReadOnly: true }}
                        />
                    </CGIBoxItem>
                    <CGIBoxItem Col={4}>
                        <CGIInput<FormaPagamentoVisualizarTypes>
                            id="nomePlanoDeContas"
                            label="Plano de Contas (Cts. Receber)"
                            placeholder="Forma de Pagamento"
                            att={{ isReadOnly: true }}
                        />
                    </CGIBoxItem>
                    <CGIBoxItem Col={4}>
                        <CGIInput<FormaPagamentoVisualizarTypes>
                            id="nomeMeioPagamento"
                            label="Meio de Pagamento (NF)"
                            placeholder="Meio de Pagamento (NF)"
                            att={{ isReadOnly: true }}
                        />
                    </CGIBoxItem>
                    <CGIBoxItem Col={4}>
                        <CGIInput<FormaPagamentoVisualizarTypes>
                            id="nomeTipoDocumento"
                            label="Tipo de Documento"
                            placeholder="Tipo de Documento"
                            att={{ isReadOnly: true }}
                        />
                    </CGIBoxItem>
                    <CGIBoxItem Col={4}>
                        <CGICheckBox<FormaPagamentoVisualizarTypes>
                            id="ativo"
                            label="Ativo"
                            tooltip=""
                            att={{
                                disabled: true,
                                style: {
                                    marginTop: 30,
                                }
                            }}
                            attLabel={{
                                marginTop: 50,
                            }}
                        />
                    </CGIBoxItem>
                </CGIBox>
            </CGIModal>
        );
    }
);
