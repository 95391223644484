import { Center, Flex, Text } from "@chakra-ui/react";
import { RowNode } from "ag-grid-community";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useWatch } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { CGIBox } from "../../../../../Components/Box/CGIBox";
import { CGIBoxItem } from "../../../../../Components/Box/CGIBoxItens";
import { CGIButton } from "../../../../../Components/Button/CGIButton";
import { CGICheckBox } from "../../../../../Components/CheckBox/CGICheckBox";
import { CGIComboAjax } from "../../../../../Components/Combos/CGIComboAjax";
import { CGIDataTableSimples } from "../../../../../Components/DataTable/CGIDataTableSimples";
import { CGICol } from "../../../../../Components/DataTable/DataTable/DataTableTypes";
import { useCGIToast } from "../../../../../Components/Hook/useCGIToast";
import { CGINumberInput } from "../../../../../Components/NumberInput/CGINumberInput";
import { CGIResumo } from "../../../../../Components/Resumo/CGIResumo";
import LoadingContext from "../../../../../Context/LoadingContext";
import {
  DadosProdutoTrocaSelecionadoType,
  PedidoLojaType,
} from "../../../../../Types/Vendas/Pedido/PedidoLojaType";
import { HttpPedido } from "../../Http";
import PedLojaContext from "../_AlterarPedidoLoja";
const ObjPadrao: DadosProdutoTrocaSelecionadoType = {
  selecionado: false,
  id: 0,
  nomeTipo: "",
  vlrTotal: 0,
  codigoProduto: "",
  estoque: 0,
  multiplo: 0,
  precoVenda: 0,
  precoPromocional: 0,
  precoCampanhaVendas: 0,
  descricao: "",
  quantidade: 0,
  obsVenda: "",
  gradeProdutoId: 0,
  unidade: "",
  unidadesConversao: "",
  pesoBruto: 0,
  percICMS: 0,
  percIPI: 0,
  percFCP: 0,
  percPIS: 0,
  percCOFINS: 0,
  ultimaAlteracaoPrecoProduto: "",
  ehPrecoPromocional: false,
  ehPrecoCampanhaVendas: false,
  codigoProdutoFabricante: "",
  ncm: "",
  tipoProdutoId: 0,
  percDesconto: 0,
  vlrDesconto: 0,
  usuarioDescontoId: 0,
  descontoMaximo: 0,
  defeito: false,
  vlrMinimo: 0,
  vlrMinimoCadastro: 0,
  vlrUnitario: 0,
  campanhaVendasId: 0,
  nomeCampanhaVendas: "",
};
export const AreaTroca = forwardRef(
  (props: { refGrid: React.ForwardedRef<unknown> }, ref) => {
    useImperativeHandle(ref, () => ({
      RetornarDados() {},
    }));
    const [Dados] = useState<Array<DadosProdutoTrocaSelecionadoType>>([]);
    const { form, GridItensTrocaRef } = useContext(PedLojaContext);
    const DadosProdutoTrocaSelecionado = useWatch({
      control: form.control,
      name: "DadosProdutoTrocaSelecionado",
    });

    const ProdutoId = useWatch({
      control: form.control,
      name: "DadosProdutoTrocaSelecionado.id",
    });

    const { ToastErro } = useCGIToast();
    const { setLoadingState } = useContext(LoadingContext);
    const Colunas: Array<CGICol<DadosProdutoTrocaSelecionadoType>> = [
      {
        Campo: "selecionado",
        Tipo: "CheckBox",
        Titulo: "",
        att: {
          maxWidth: 50,
        },
      },
      {
        Titulo: "Cod.Produto",
        Campo: "codigoProduto",
        Tipo: "Texto",
        att: {
          minWidth: 150,
        },
      },
      {
        Titulo: "Descricao",
        Campo: "descricao",
        Tipo: "Texto",
        att: {
          minWidth: 200,
        },
      },
      {
        Titulo: "Und.",
        Campo: "unidade",
        Tipo: "Texto",
        att: {
          minWidth: 75,
        },
      },
      {
        Titulo: "Def.",
        Campo: "defeito",
        Tipo: "CheckBoxDisable",
        att: {
          minWidth: 60,
        },
      },
      {
        Titulo: "Tipo",
        Campo: "nomeTipo",
        Tipo: "Texto",
        att: {
          minWidth: 75,
        },
      },
      {
        Titulo: "Qtd.",
        Campo: "quantidade",
        Tipo: "Numero",
        att: {
          minWidth: 100,
          editable: true,
        },
      },
      {
        Titulo: "Pr. Venda",
        Campo: "precoVenda",
        Tipo: "Moeda",
        att: {
          minWidth: 100,
          editable: true,
        },
      },
      {
        Titulo: "Vlr. Total",
        Campo: "vlrTotal",
        Tipo: "Moeda",
        att: {
          minWidth: 100,
          valueGetter: e => Number(e.data.precoVenda * e.data.quantidade),
        },
      },
    ];

    const RetornarInformacao = useCallback(async () => {
      try {
        setLoadingState(true);
        const dados = await HttpPedido().RetornarDadosProdutos(ProdutoId);
        form.setValue("DadosProdutoTrocaSelecionado", dados);
      } finally {
        setLoadingState(false);
      }
    }, [ProdutoId]);

    useEffect(() => {
      if (ProdutoId === null || ProdutoId === 0 || ProdutoId === undefined) {
        return;
      }
      form.setFocus("DadosProdutoTrocaSelecionado.quantidade");
      RetornarInformacao();
    }, [ProdutoId]);

    function AdicionarProduto() {
      const dados = form.getValues("DadosProdutoTrocaSelecionado");

      if (Number(dados.id) === 0) {
        ToastErro("Selecione um produto");
        form.setFocus("DadosProdutoTrocaSelecionado.id");
        return;
      }
      if (Number(dados.quantidade) === 0) {
        form.setFocus("DadosProdutoTrocaSelecionado.quantidade");
        ToastErro("Informe a quantidade");
        return;
      }
      if (Number(dados.precoVenda) === 0) {
        form.setFocus("DadosProdutoTrocaSelecionado.precoVenda");
        ToastErro("Informe o preço de venda");
        return;
      }

      let rowData: Array<RowNode> = [];
      GridItensTrocaRef.current?.api.forEachNode(node => rowData.push(node));
      var Filtros = rowData.filter(e => e.data.id === dados.id);
      if (Filtros.length === 0) {
        GridItensTrocaRef.current?.api.applyTransaction({
          add: [{ ...dados, selecionado: true }],
        });
      } else {
        GridItensTrocaRef.current?.api.applyTransaction({
          update: [
            {
              ...dados,
              quantidade: Filtros[0].data.quantidade + dados.quantidade,
              selecionado: true,
            },
          ],
        });
      }
      form.setValue("DadosProdutoTrocaSelecionado", ObjPadrao);
      form.setFocus("DadosProdutoTrocaSelecionado.id");
    }

    const TotalizadoresTroca = useCallback(() => {
      let rowData: Array<any> = [];
      GridItensTrocaRef.current?.api.forEachNode(node => {
        return node.data.selecionado && rowData.push(node.data);
      });
      let totalProdutos = rowData.reduce(
        (a, b) => a + b.precoVenda * b.quantidade,
        0
      );
      let totalQuantidade = rowData.reduce((a, b) => a + b.quantidade, 0);
      return (
        <CGIBox spacing="opx" titulo="Totalizador">
          <CGIBoxItem Col={12}>
            <Flex justifyContent={"space-between"}>
              <Text fontWeight={"bold"}>Total dos Produtos</Text>
              <Text fontWeight={"bold"} color={"blue.500"}>
                {totalProdutos.FormatarReal()}
              </Text>
            </Flex>
          </CGIBoxItem>
          <CGIBoxItem Col={12}>
            <Flex justifyContent={"space-between"}>
              <Text fontWeight={"bold"}>Qtd. Produtos</Text>
              <Text fontWeight={"bold"} color={"blue.500"}>
                {totalQuantidade}
              </Text>
            </Flex>
          </CGIBoxItem>
        </CGIBox>
      );
    }, [DadosProdutoTrocaSelecionado]);

    return (
      <CGIBox>
        <CGIBoxItem Col={12}>
          <CGIBox titulo="Adicionar Produto" popover="Adicionar Produto">
            <CGIBoxItem Col={12}>
              <CGIComboAjax<PedidoLojaType>
                Link={"produto"}
                label={"Produto"}
                id={"DadosProdutoTrocaSelecionado.id"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={2}>
              <CGINumberInput<PedidoLojaType>
                id={"DadosProdutoTrocaSelecionado.quantidade"}
                label={"Quantidade"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGINumberInput<PedidoLojaType>
                id={"DadosProdutoTrocaSelecionado.precoVenda"}
                label={"Pr. Venda"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={3}>
              <CGICheckBox<PedidoLojaType>
                id={"DadosProdutoSelecionado.defeito"}
                label={"Defeito"}
                tooltip={"Defeito"}
              />
            </CGIBoxItem>
            <CGIBoxItem Col={4}>
              <CGIButton
                onClick={() => AdicionarProduto()}
                label="Adicionar Produto"
              />
            </CGIBoxItem>
            <CGIBoxItem Col={12}>
              <CGIResumo ResumoProduto ResumoProdutoEstoqueEmpresa />
            </CGIBoxItem>
          </CGIBox>
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <CGIDataTableSimples
            ref={props.refGrid}
            Altura="30vh"
            Colunas={Colunas}
            Data={Dados}
            Pag={10}
            Att={{
              defaultColDef: {
                flex: 1,
                filter: false,
                autoHeight: true,
              },
              onCellEditingStopped: e =>
                form?.setValue("DadosProdutoSelecionado.id", e.node.data.id),
              onCellClicked: e => {
                form?.setValue("DadosProdutoSelecionado.id", e.node.data.id);
              },
            }}
          />
        </CGIBoxItem>
        <CGIBoxItem Col={12}>
          <>{TotalizadoresTroca()}</>
        </CGIBoxItem>
      </CGIBox>
    );
  }
);
