export const ObjNotasPendentesEntregaPadrao: FormNotasPendentesEntregaType = {
  Form: {
    DadosRegistro: {
      id: 0,
      nFSaidaId: 0,
      pedidoId: 0,
      identificacaoPedido: "",
      pedidoCliente: "",
      nomeColaborador: "",
      nomeFantasiaColaborador: "",
      valorNF: 0,
      nomeStatus: "",
      numeroNF: 0,
      nomeEmpresa: "",
      modelo: "",
      dataCadastro: "",
    },
  },
};
export type NotasPendentesEntregaTypes = {
  DadosRegistro: {
    id: number;
    nFSaidaId: number;
    pedidoId: number | null;
    identificacaoPedido: string;
    pedidoCliente: string;
    nomeColaborador: string;
    nomeFantasiaColaborador: string;
    valorNF: number;
    nomeStatus: string;
    numeroNF: number;
    nomeEmpresa: string;
    modelo: string;
    dataCadastro: string;
  };
};
export type FormNotasPendentesEntregaType = {
  Form: NotasPendentesEntregaTypes;
};

export const ObjListarNotasPendentesEntregaPadrao: ListarNotasPendentesEntregaTypes =
  {
    Pesquisa: {
      empresaId: 0,
      dataInicial: "",
      dataFinal: "",
      notaFiscal: "",
      filtroData: "",
      pedidoCliente: "",
      identificacaoPedido: "",
      listaColaboradoresId: "",
    },
    Itens: [],
  };

export type NotasPendentesEntregaPesquisaGridTypes = {
  nFSaidaId?: number;
  pedidoId?: number | null;
  identificacaoPedido?: string;
  pedidoCliente?: string;
  nomeColaborador?: string;
  nomeFantasiaColaborador?: string;
  valorNF?: number;
  nomeStatus?: string;
  numeroNF?: number;
  nomeEmpresa?: string;
  modelo?: string;
  dataCadastro?: string;
};

export type ListarNotasPendentesEntregaTypes = {
  Pesquisa: ListarPesquisaNotasPendentesEntregaTypes;
  Itens: ListarNotasPendentesEntregaItensTypes[];
};

export type ListarPesquisaNotasPendentesEntregaTypes = {
  empresaId: number;
  dataInicial: string;
  dataFinal: string;
  notaFiscal: string;
  filtroData: string;
  pedidoCliente: string;
  identificacaoPedido: string;
  listaColaboradoresId: string;
};

export type ListarNotasPendentesEntregaItensTypes = {
  id: number;
  nFSaidaId: number;
  pedidoId: number | null;
  identificacaoPedido: string;
  pedidoCliente: string;
  nomeColaborador: string;
  nomeFantasiaColaborador: string;
  valorNF: number;
  nomeStatus: string;
  numeroNF: number;
  nomeEmpresa: string;
  modelo: string;
  dataCadastro: string;
};

export type NotasPendentesEntregaAlterarTypes = {
  id: number;
  ncm: string;
  percIPI: number;
  ex: number;
};
