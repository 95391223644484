import { CGIHttp } from "../../../../Services/Axios";
import {
  FormTipoNFEntradaType,
  ListarPesquisaTipoNFEntradaTypes,
  ListarTipoNFEntradaItensTypes,
  ListarTipoNFEntradaTypes,
} from "../../../../Types/Sistema/Classificacao/TipoNFEntrada/TipoNFEntradaTypes";

const url = "/classificacao";

export function HttpTipoNFEntrada() {
  async function RetornarNaturezasOperacao(
    DadosFiltros: ListarPesquisaTipoNFEntradaTypes
  ): Promise<ListarTipoNFEntradaItensTypes[]> {
    return (
      await new CGIHttp().conn.post(
        url + "/obter-tipos-nf-entrada",
        DadosFiltros
      )
    ).data.data;
  }

  async function RetornarDadosTipoNFEntrada(id: number) {
    return await new CGIHttp().conn.get(url + `/alterar-tiponf-entrada/${id}`);
  }

  async function Incluir(DadosEmpresa: FormTipoNFEntradaType) {
    return await new CGIHttp().conn.post(
      url + "/incluir-tiponf-entrada",
      DadosEmpresa.Form
    );
  }
  async function Alterar(DadosEmpresa: FormTipoNFEntradaType) {
    return await new CGIHttp().conn.post(
      url + "/alterar-tiponf-entrada",
      DadosEmpresa.Form
    );
  }
  async function Visualizar(pId: number) {
    return await new CGIHttp().conn.get(
      url + "/visualizar-tiponf-entrada/" + pId
    );
  }
  async function RetornarDadosExclusao(pId: number) {
    return await new CGIHttp().conn.get(url + "/excluir-tiponf-entrada/" + pId);
  }
  async function Excluir(Dados: any) {
    return await new CGIHttp().conn.post(
      url + "/excluir-tiponf-entrada/" + Dados.id,
      Dados
    );
  }
  return {
    RetornarNaturezasOperacao,
    RetornarDadosTipoNFEntrada,
    RetornarDadosExclusao,
    Incluir,
    Alterar,
    Visualizar,
    Excluir,
  };
}
